import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppSelector } from 'redux/store';
import { useGetFeatureFlagsQuery } from 'redux/api/technical-data.api';

import { usePingMutation } from '../../redux/api/user.api';
import { profileSelector } from '../../redux/selectors/user.selectors';
import { useCheckRedirects } from '../../hooks/check-redirects.hook';
import { useModalsRedirects } from '../../hooks/modals-redirects.hook';
import { Popup } from '../popups/_logic/popup';
import { ColoredFrame } from '../colored-frame';

export function RootLayout(): JSX.Element {
  useGetFeatureFlagsQuery();
  useCheckRedirects();
  useModalsRedirects();
  const [ping] = usePingMutation();
  const user = useAppSelector(profileSelector);

  const onFocus = () => {
    if (user?.id) {
      ping(null);
    }
  };

  useEffect(() => {
    window.addEventListener('focus', onFocus);
    return () => {
      window.removeEventListener('focus', onFocus);
    };
  });

  return (
    <>
      <Outlet />
      <Popup />
      <ColoredFrame />
    </>
  );
}
