import { createRef, useState } from 'react';
import cn from 'classnames';
import { Stepper, SwagButton, Tooltip, Typography } from '@ezetech/swag-space-x';
import { useOutlet } from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { IntercomSettings } from '@intercom/messenger-js-sdk/types';

import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { STEPS } from 'constants/store-creation.constants';
import {
  currentStepSelector,
  currentStepIndexSelector,
  isNextBtnDisabledSelector,
  isOnboardingCompletedSelector,
  currentStepDataSelector,
  hasUnsavedChangesSelector,
} from 'redux/selectors/store-creation.selectors';
import { usePatchStoreSettingsMutation } from 'redux/api/store-creation.api';
import { goToPrevStep, finishOnboarding } from 'redux/slices/store-creation.slice';
import { useBeforeunload } from 'hooks/before-unload.hook';
import { IntercomChat } from 'components/intercom-chat';

import { INTERCOM_DEFAULT_PADDING, INTERCOM_VERTICAL_PADDING } from 'constants/intercom';
import { StarsIcon } from '../svg';
import ring1 from '../../assets/images/rectangle-54.png';
import ring2 from '../../assets/images/rectangle-55.png';
import ring3 from '../../assets/images/rectangle-58.png';
import { Header } from '../header';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { storeSettingsApi } from '../../redux/api/store-creation.api';

import { adminLoggedInAsResellerSelector } from '../../redux/selectors/user.selectors';
import css from './store-creation-layout.module.scss';

const animationClasses = {
  enter: css.enter,
  enterActive: css.enterActive,
  exit: css.exit,
  exitActive: css.exitActive,
};

const stepsX = STEPS.map((step) => ({ name: step.text, id: step.stepName }));

export function StoreCreationLayout(): JSX.Element {
  const dispatch = useAppDispatch();
  const currentOutlet = useOutlet();

  const adminLoggedInAsReseller = useAppSelector(adminLoggedInAsResellerSelector);
  const { prevBtnText, nextBtnText, routeRef } = useAppSelector(currentStepSelector);
  const currentStepIndex = useAppSelector(currentStepIndexSelector);
  const isNextBtnDisabled = useAppSelector(isNextBtnDisabledSelector);
  const isOnboardingCompleted = useAppSelector(isOnboardingCompletedSelector);
  const currentStepData = useAppSelector(currentStepDataSelector);
  const hasUnsavedChanges = useAppSelector(hasUnsavedChangesSelector);

  const [patchStoreSettings] = usePatchStoreSettingsMutation();

  const btnRef = createRef<HTMLDivElement>();
  const btnRefBack = createRef<HTMLDivElement>();

  const [intercomChatPadding, setIntercomChatPadding] = useState<
    IntercomSettings['vertical_padding']
  >(INTERCOM_VERTICAL_PADDING);

  useBeforeunload((event: Event) => {
    if (hasUnsavedChanges) {
      event.preventDefault();
    }
  });

  const onPrevBtnClick = () => {
    if (hasUnsavedChanges) {
      if (confirm('Reload site? Changes you made may not be saved.')) {
        dispatch(goToPrevStep({ currentStepIndex }));
      }
    } else {
      dispatch(goToPrevStep({ currentStepIndex }));
    }
  };

  const onNextBtnClick = () => {
    if (isOnboardingCompleted) {
      dispatch(finishOnboarding());
      dispatch(
        storeSettingsApi.endpoints.getIsOnboardingCompleted.initiate(undefined, {
          forceRefetch: true,
        }),
      ).unwrap();
      setIntercomChatPadding(INTERCOM_DEFAULT_PADDING);
    } else {
      patchStoreSettings({ currentStepIndex, ...currentStepData });
    }
  };

  return (
    <div className={css.root}>
      <StarsIcon className={css.stars1} />
      <StarsIcon
        className={css.stars2}
        fillColor={COLORS.colorPrimaryBlue}
        opacity="0.2"
      />
      <img src={ring1} className={css.ring1} alt="ring 1" />
      <img src={ring2} className={css.ring2} alt="ring 2" />
      <img src={ring3} className={css.ring3} alt="ring 3" />
      <Header
        headerWrapperClassName={css.headerWrapperClassName}
        headerClassName={css.headerClassName}
      />
      <div className={css.onboardContentWrapper}>
        <div className={css.onboardContent}>
          <div className={css.stepperWrapper}>
            <Stepper steps={stepsX} activeStep={currentStepIndex} />
          </div>

          <SwitchTransition>
            <CSSTransition
              classNames={animationClasses}
              key={currentStepIndex}
              timeout={300}
              nodeRef={routeRef}
              unmountOnExit
            >
              {() => (
                <div ref={routeRef} className={css.outletWrapper}>
                  {currentOutlet}
                </div>
              )}
            </CSSTransition>
          </SwitchTransition>
        </div>
      </div>

      <footer
        className={cn(css.footer, {
          [css.footerFrame]: adminLoggedInAsReseller,
        })}
      >
        <div
          className={cn(css.footerContent, {
            [css.withOneBtn]: currentStepIndex === 0,
          })}
        >
          {prevBtnText && (
            <SwitchTransition>
              <CSSTransition
                classNames={animationClasses}
                key={prevBtnText}
                nodeRef={btnRefBack}
                timeout={500}
                unmountOnExit
              >
                <SwagButton
                  size="large"
                  type="outlined"
                  onClick={onPrevBtnClick}
                  className={css.prevBtn}
                  fullWidth
                >
                  <div ref={btnRefBack}>{prevBtnText}</div>
                </SwagButton>
              </CSSTransition>
            </SwitchTransition>
          )}

          <SwitchTransition>
            <CSSTransition
              classNames={animationClasses}
              key={nextBtnText}
              nodeRef={btnRef}
              timeout={500}
              unmountOnExit
            >
              <Tooltip
                disabled={!isNextBtnDisabled}
                triggerClassName={css.nextBtnWrapper}
                content={
                  <SwagButton
                    type="primary"
                    size="large"
                    fullWidth
                    className={css.nextBtn}
                    onClick={onNextBtnClick}
                    disabled={isNextBtnDisabled}
                  >
                    <div ref={btnRef}>{nextBtnText}</div>
                  </SwagButton>
                }
              >
                <Typography
                  lineHeight="162%"
                  fontType="bodyMd"
                  color={COLORS.colorTypographyBody}
                >
                  Some of the required fields are missing
                </Typography>
              </Tooltip>
            </CSSTransition>
          </SwitchTransition>
        </div>
      </footer>
      <IntercomChat settings={{ vertical_padding: intercomChatPadding }} />
    </div>
  );
}
