import { FC, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import {
  Typography,
  WhiteBox,
  Loader,
  SwagButton,
  Divider,
  InfinityScroll,
  InfinityRowProps,
} from '@ezetech/swag-space-x';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { setTeamMemberDetails } from 'redux/slices/invitations.slice';
import { can, SUBJECTS } from 'boot/ability';

import { ITeamMember } from 'interfaces/invitation.interface';
import { ownerSelector } from '../../../redux/selectors/settings.selectors';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import {
  hasNextPageInvitationsSelector,
  isLoadingInvitationsSelector,
  pageInvitationsSelector,
  perPageInvitationsSelector,
  teamInvitationsSelector,
} from '../../../redux/selectors/invitations.selector';
import { useGetTeamMembersMutation } from '../../../redux/api/user.api';
import { UserCard } from './components/user-card';

import classes from './user-and-permissions.module.scss';

type itemRenderProps = InfinityRowProps<{
  items: (ITeamMember & { key: string })[];
}>;

export const UserAndPermissions: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [getTeamMembers] = useGetTeamMembersMutation();
  const hasNextPage = useAppSelector(hasNextPageInvitationsSelector);
  const page = useAppSelector(pageInvitationsSelector);
  const perPage = useAppSelector(perPageInvitationsSelector);
  const teamMembers = useAppSelector(teamInvitationsSelector);
  const owner = useAppSelector(ownerSelector);
  const isLoading = useAppSelector(isLoadingInvitationsSelector);
  const [listHeight, setHeight] = useState(351);
  const [rowHeight, setRowHeight] = useState(117);
  const hasNotPermissionToViewStaff = !can(
    SUBJECTS.EDIT_STAFF.actions.EDIT_STAFF_VIEW,
    SUBJECTS.EDIT_STAFF.value,
  );
  const handleResize = () => {
    const heightMultiplier = teamMembers.length >= 3 ? 3 : teamMembers.length ?? 1;

    if (teamMembers.length === 0) {
      setHeight(100);
      return;
    }

    if (window.innerWidth <= 767) {
      setRowHeight(146);
      return setHeight(heightMultiplier * 146);
    }

    setRowHeight(117);
    setHeight(heightMultiplier * 117);
  };

  useEffect(() => {
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [teamMembers.length]);

  const rowRenderer = useCallback(({ index, data, style }: itemRenderProps) => {
    if (!data.items[index]) {
      return <div>Loading...</div>;
    }
    const { key, ...teamMember } = data.items[index];
    return (
      <div style={style}>
        <UserCard
          suppressLink={hasNotPermissionToViewStaff}
          user={teamMember}
          isLast={data.items.length - 1 === index}
        />
        {index !== data.items.length - 1 && <Divider color={COLORS.colorDivider} />}
      </div>
    );
  }, []);

  const handleNewPageLoad = useCallback(() => {
    getTeamMembers({
      page: page + 1,
      perPage,
    });
  }, [page, perPage, getTeamMembers]);

  const handleAddTeamMember = () => {
    dispatch(
      setTeamMemberDetails({
        id: '',
        email: '',
        firstName: '',
        lastName: '',
      }),
    );
    navigate('/team-member-details');
  };

  const containerStyles = {
    height: listHeight,
  };

  useEffect(() => {
    getTeamMembers({
      page: 1,
      perPage,
    });
  }, []);

  return (
    <div className={classes.root}>
      <WhiteBox className={classes.container}>
        <Typography
          className={classes.header}
          fontType="HEADING5XL"
          color={COLORS.colorText900}
          lineHeight="100%"
        >
          Owner
        </Typography>
        <Divider color={COLORS.colorDivider} />
        {owner ? (
          <UserCard
            user={owner as ITeamMember}
            role="Owner"
            suppressLink={true}
            isLast={true}
          />
        ) : (
          <div className={classes.loaderOwner}>
            <Loader />
          </div>
        )}
      </WhiteBox>
      {can(SUBJECTS.VIEW_STAFF.actions.VIEW_STAFF, SUBJECTS.VIEW_STAFF.value) && (
        <WhiteBox className={classes.container}>
          <Typography
            className={classes.header}
            fontType="HEADING5XL"
            color={COLORS.colorText900}
            lineHeight="100%"
          >
            Team
          </Typography>
          <Divider color={COLORS.colorDivider} />
          <div
            className={classNames(classes.containerTeam, {
              [classes.containerTeamNoData]: teamMembers.length === 0,
            })}
            style={containerStyles}
          >
            <InfinityScroll
              containerHeight={listHeight}
              itemHeight={rowHeight}
              items={teamMembers}
              loadPage={handleNewPageLoad}
              isLoading={isLoading}
              row={rowRenderer}
              hasNextPage={hasNextPage}
            />
            {teamMembers.length === 0 && isLoading && (
              <div className={classes.loader}>
                <Loader />
              </div>
            )}
            {teamMembers.length === 0 && !isLoading && (
              <div className={classes.noTeam}>No team members</div>
            )}
          </div>
          {can(SUBJECTS.EDIT_STAFF.actions.ADD_STAFF, SUBJECTS.EDIT_STAFF.value) && (
            <>
              <Divider color={COLORS.colorDivider} />
              <SwagButton
                className={classes.button}
                type={'primary'}
                onClick={handleAddTeamMember}
              >
                Add Team Member
              </SwagButton>
            </>
          )}
        </WhiteBox>
      )}
    </div>
  );
};
