import { createSelector } from 'reselect';
import { RootState } from '../store';

export const resellerCertificatesSelector = (state: RootState) =>
  state.resellerCertificates;

export const resellerCertificatesLastPageSelector = createSelector(
  resellerCertificatesSelector,
  ({ perPage, total }) => Math.ceil(total / perPage),
);
