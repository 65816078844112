import { useAppSelector } from 'redux/store';
import { orderCardItemsWrapperComponentSelector } from 'redux/selectors/orders.selectors';
import { OrderCardItems } from '../order-card-items';

interface IProps {
  orderId: string;
}

export const OrderCardItemsWrapperComponent = ({ orderId }: IProps) => {
  const { itemsIds, inventoryItemsIdsGroupByName, showInventoryItems } = useAppSelector(
    orderCardItemsWrapperComponentSelector({ orderId }),
  );

  if (showInventoryItems) {
    return (
      <>
        {Object.entries(inventoryItemsIdsGroupByName).map(
          ([inventoryName, inventoryItemsIds], i) => {
            return (
              <>
                <OrderCardItems
                  orderId={orderId}
                  itemsIds={inventoryItemsIds}
                  inventoryName={inventoryName}
                  key={orderId + i}
                />
              </>
            );
          },
        )}
      </>
    );
  }

  return <OrderCardItems orderId={orderId} itemsIds={itemsIds} />;
};
