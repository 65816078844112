import { FC } from 'react';
import { Typography } from '@ezetech/swag-space-x';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import css from '../sign-contract.module.scss';
import { makeBold } from '../sign-contract.util';

export const ApplicationForMembership: FC = () => {
  return (
    <>
      <Typography color={COLORS.colorPrimaryText} fontType="bodyMdBold">
        2. Application for Membership and Account Creation
      </Typography>
      <br />
      <br />
      <ol className={css.listLatin}>
        <li className={css.listItem}>
          To access and use the Services, you must apply for and be accepted as a member
          or reseller ({makeBold('“Reseller”')}). Information required for your
          application will include your full legal name (individual or entity), business
          address, contact information and any other information indicated on the
          application as required. Once accepted by Swag Space as a Reseller, you will
          have a Services account ({makeBold('“Account”')}). Swag Space has the sole
          discretion to accept or reject Reseller applications or cancel an existing
          Account, for any reason, at our sole discretion.
        </li>
        <br />
        <li className={css.listItem}>
          By submitting an application and, if accepted as a Reseller, subsequently
          maintaining an Account, you represent and warrant that any information you
          provide is true, accurate, and complete, and that you will keep such information
          current. You expressly agree that you will not create an Account on behalf of
          another individual or anyone other than yourself or your company. You agree to
          take reasonable steps to keep your Account confidential. You will be held
          responsible for any unauthorized use and activities that occur under or in
          connection with your Account. You agree to notify us immediately if you become
          aware that your Account is being used without authorization.
        </li>
        <br />
        <li className={css.listItem}>
          You represent and warrant to us that you are lawfully able to enter into
          contracts. You must be the older of: (i) eighteen (18) years of age or (ii) at
          least the age of majority in the jurisdiction where you reside and from which
          you use the Site to open an Account. If you are entering into these Terms on
          behalf of a business entity, you represent and warrant that you have the legal
          authority and capacity to bind such business entity. If you are not authorized
          nor deemed by law to have such authority, you assume sole personal liability for
          the obligations set out in these Terms.
        </li>
      </ol>
      <br />
    </>
  );
};
