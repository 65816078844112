export function makeArrayForSelectOptions<T>(
  items: Record<string, string>,
  groupName?: string,
): T[] {
  const keys = Object.keys(items);
  const options = keys.map((key) => ({
    value: String(key),
    label: String(items[key]),
  })) as T[];

  if (groupName) {
    return [
      {
        options,
        label: groupName,
      },
    ] as T[];
  }

  return options;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const debounce = <F extends (...args: any[]) => any>(func: F, waitFor: number) => {
  let timeout: ReturnType<typeof setTimeout> | null = null;

  const debounced = (...args: Parameters<F>) => {
    if (timeout !== null) {
      clearTimeout(timeout);
      timeout = null;
    }
    timeout = setTimeout(() => func(...args), waitFor);
  };

  return debounced as (...args: Parameters<F>) => ReturnType<F>;
};

export function removeEmptyFieldsFromObject(
  object: Record<string, unknown>,
): Record<string, unknown> {
  return Object.entries(object).reduce<Record<string, unknown>>((acc, [key, value]) => {
    if (value === null || value === undefined || value === '') {
      return acc;
    }
    if (Array.isArray(value)) {
      acc[key] = value;
      return acc;
    }
    acc[key] =
      typeof value === 'object'
        ? removeEmptyFieldsFromObject(value as Record<string, unknown>)
        : value;
    return acc;
  }, {});
}
