import cn from 'classnames';
import { useState } from 'react';
import { Dialog, DialogContentWrapper } from '@ezetech/swag-space-x';
import { useDispatch } from 'react-redux';
import { deepEqual } from 'utils/deep-equal';

import { closePopup } from 'redux/slices/modals.slice';
import { EditWhatWeOfferPagePopupProps } from 'interfaces/popup.interface';

import {
  storeLinkSelector,
  whatWeOfferSelector,
} from 'redux/selectors/settings.selectors';
import { useAppSelector } from 'redux/store';
import { useUpdateWhatWeOfferDataMutation } from 'redux/api/settings.api';
import { IUpdateWhatWeOfferSettings } from 'interfaces/settings.interface';

import {
  PageEditorPreview,
  PageEditorHeader,
  PageEditorContent,
  PageEditorSidebar,
  PageEditorLayout,
  PageEditorMobilePreviewButton,
} from 'components/page-editor';
import { can, SUBJECTS } from 'boot/ability';

import { EditWhatWeOfferHeader } from './header';
import { EditWhatWeOfferSidebar } from './sidebar';
import { queryBuilder, isMobileDevice, isTabletDevice } from './utils';
import classes from './edit-what-we-offer-page-popup.module.scss';

export const EditWhatWeOfferPagePopup = ({
  isOpen,
}: EditWhatWeOfferPagePopupProps): JSX.Element => {
  const dispatch = useDispatch();

  const [saveLandingData] = useUpdateWhatWeOfferDataMutation();
  const whatWeOfferInfo = useAppSelector(whatWeOfferSelector);
  const storeLink = useAppSelector(storeLinkSelector);

  const [isChangedField, setIsChangedField] = useState(false);
  const [mobilePreview, setMobilePreview] = useState<boolean>(false);
  const [isPopupMounted, setPopupMounted] = useState(isOpen);

  const [whatWeOfferInfoUpdated, setWhatWeOfferInfoUpdated] = useState(whatWeOfferInfo);
  const [landingUrl, setLandingUrl] = useState<string>(
    queryBuilder({ ...whatWeOfferInfo, storeLink }),
  );

  const isEditable = can(
    SUBJECTS.PAGES_POPUPS_PDF_PRESENTATIONS.actions.WHAT_WE_OFFER_PAGE_EDIT,
    SUBJECTS.PAGES_POPUPS_PDF_PRESENTATIONS.value,
  );

  const handleOnOpenChange = () => {
    setPopupMounted(false);
    setTimeout(() => {
      dispatch(closePopup());
    }, 500);
  };

  const onSaveChanges = () => {
    saveLandingData(whatWeOfferInfoUpdated);
    handleOnOpenChange();
  };

  const getWhatWeOfferObject = (data: IUpdateWhatWeOfferSettings) => {
    return {
      formText: data.formText ?? '',
      heroImage: data.heroImage?.url ? data.heroImage : [],
      brandLogos: data.brandLogos ?? Array.from({ length: 8 }, () => null),
      imagesRaw: data.imagesRaw ?? Array.from({ length: 3 }, () => null),
      heroText: data.heroText ?? '',
    };
  };

  const rebuildQuery = (settingsToUpdated: IUpdateWhatWeOfferSettings) => {
    const query = queryBuilder({ ...settingsToUpdated, storeLink });
    setLandingUrl(query);
  };

  const handleUpdates = (settingsUpdated: IUpdateWhatWeOfferSettings) => {
    rebuildQuery(settingsUpdated);
    const isChanged = !deepEqual(
      getWhatWeOfferObject(whatWeOfferInfo) as unknown as never,
      settingsUpdated as unknown as never,
    );
    setIsChangedField(isChanged);
    setWhatWeOfferInfoUpdated(settingsUpdated);
  };

  const onClickMobilePreview = () => {
    setMobilePreview((preview) => !preview);
  };

  return (
    <Dialog open={isPopupMounted} onOpenChange={handleOnOpenChange}>
      <DialogContentWrapper
        className={classes.dialogContentWrapperClassName}
        overlayClassName={classes.dialogOverlayClassName}
        type="vertical"
      >
        <PageEditorLayout>
          <PageEditorHeader title="What we offer page">
            {isEditable && (
              <PageEditorMobilePreviewButton
                mobilePreview={mobilePreview}
                onClick={onClickMobilePreview}
              />
            )}
            <EditWhatWeOfferHeader
              onClose={handleOnOpenChange}
              onSave={onSaveChanges}
              disabledSaveButton={!isChangedField}
              isEditMode={isEditable}
            />
          </PageEditorHeader>
          <PageEditorContent
            className={cn({
              [classes.contentWithoutSidebar]: !isEditable,
            })}
          >
            <PageEditorPreview
              src={landingUrl}
              className={classes.previewIframe}
              isHidden={isEditable && !mobilePreview && isMobileDevice()}
            />
            {isEditable && (
              <PageEditorSidebar
                title="Edit Page"
                isHidden={mobilePreview && isTabletDevice()}
              >
                <EditWhatWeOfferSidebar
                  settings={whatWeOfferInfo}
                  onSettingsChanged={handleUpdates}
                />
              </PageEditorSidebar>
            )}
          </PageEditorContent>
        </PageEditorLayout>
      </DialogContentWrapper>
    </Dialog>
  );
};
