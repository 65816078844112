import { ReactElement } from 'react';
import {
  Dialog,
  DialogContentWrapper,
  DialogDescription,
  DialogFooter,
  DialogTitle,
  SwagButton,
} from '@ezetech/swag-space-x';

import { AppDispatch, useAppDispatch } from 'redux/store';
import { closePopup } from 'redux/slices/modals.slice';
import { ICommonPopupProps } from 'interfaces/popup.interface';
import { DealComparisonWidget } from 'components/deal-comparison-widget';

import css from './deal-comparison-popup.module.scss';

export function DealComparisonPopup({ isOpen }: ICommonPopupProps): ReactElement {
  const dispatch: AppDispatch = useAppDispatch();

  const onCloseClick = (): void => {
    dispatch(closePopup());
  };

  return (
    <Dialog open={isOpen} onOpenChange={onCloseClick}>
      <DialogContentWrapper
        className={css.dialogContentWrapperClassName}
        overlayClassName={css.dialogOverlayClassName}
      >
        <DialogTitle closeIconClassName={css.closeIconClassName} className={css.title}>
          See how it compares
        </DialogTitle>

        <DialogDescription className={css.textWrapper}>
          <DealComparisonWidget />
        </DialogDescription>

        <DialogFooter className={css.footer}>
          <SwagButton type="primary" onClick={onCloseClick} className={css.btn}>
            Close
          </SwagButton>
        </DialogFooter>
      </DialogContentWrapper>
    </Dialog>
  );
}
