import { useForm, SubmitHandler, FieldValues } from 'react-hook-form';
import { SwagButton } from '@ezetech/swag-space-x';
import { InputForm } from 'components/input-form';
import { CheckboxForm } from 'components/checkbox-form';
import { UserLoginType } from 'interfaces/auth-api.interface';

import { ROUTES } from 'constants/router';
import { SignUpFormProps } from '../login.interface';

import { FIELDS } from './login.constants';

import css from './login-form.module.scss';

export function LoginForm(props: SignUpFormProps<UserLoginType>) {
  const { onSubmit: onFormSubmit, loading, hideForgot = false } = props;
  const { handleSubmit, control, formState } = useForm<FieldValues>({
    mode: 'onBlur',
  });
  const { isValid } = formState;

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    onFormSubmit(data as UserLoginType);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={css.form}>
      <div className={css.formFields}>
        <InputForm
          type="text"
          label={FIELDS.email.label}
          name={FIELDS.email.name}
          placeholder={FIELDS.email.placeholder}
          rules={FIELDS.email.rules}
          control={control}
        />
        <div>
          <InputForm
            type="password"
            label={FIELDS.password.label}
            name={FIELDS.password.name}
            placeholder={FIELDS.password.placeholder}
            rules={FIELDS.password.rules}
            control={control}
          />
          {!hideForgot && (
            <div className={css.rememberBlock}>
              <CheckboxForm
                name={FIELDS.remember.name}
                control={control}
                label={FIELDS.remember.label}
                rules={FIELDS.remember.rules}
              />
              <a href={ROUTES.RESET_PASSWORD} className={css.forgotLink}>
                Forgot password?
              </a>
            </div>
          )}
        </div>
      </div>

      <SwagButton
        type="primary"
        size="xl"
        htmlType="submit"
        fullWidth
        disabled={!isValid}
        loading={loading}
      >
        Login
      </SwagButton>
    </form>
  );
}
