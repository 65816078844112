import { RainbowBox } from '@ezetech/swag-space-x';
import { ColoredContainerHeader } from 'components/colored-container-header';
import css from './rainbow-information.module.scss';

interface IProps {
  tagline: string;
  title: string;
  description: string;
}

export function RainbowInformation({ tagline, title, description }: IProps) {
  return (
    <RainbowBox borderWidth={8} innerClassName={css.block}>
      <ColoredContainerHeader tagline={tagline} title={title} description={description} />
    </RainbowBox>
  );
}
