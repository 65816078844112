import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUser } from 'interfaces/user-api.interface';

interface IAssigneeState {
  listForOrders: IUser[];
  listForInvoices: IUser[];
  listForPresentations: IUser[];
  listForCarts: IUser[];
  listForCustomers: IUser[];
  listForLeads: IUser[];
  listForAbandonedCheckouts: IUser[];
}

const initialState: IAssigneeState = {
  listForOrders: [],
  listForInvoices: [],
  listForPresentations: [],
  listForCarts: [],
  listForCustomers: [],
  listForLeads: [],
  listForAbandonedCheckouts: [],
};

export const assigneeSlice = createSlice({
  initialState,
  name: 'assigneeSlice',
  reducers: {
    getOrdersAssigneeList: (state, action: PayloadAction<IUser[]>) => {
      state.listForOrders = action.payload;
    },
    getInvoicesAssigneeList: (state, action: PayloadAction<IUser[]>) => {
      state.listForInvoices = action.payload;
    },
    getPresentationsAssigneeList: (state, action: PayloadAction<IUser[]>) => {
      state.listForPresentations = action.payload;
    },
    getCartsAssigneeList: (state, action: PayloadAction<IUser[]>) => {
      state.listForCarts = action.payload;
    },
    getCustomersAssigneeList: (state, action: PayloadAction<IUser[]>) => {
      state.listForCustomers = action.payload;
    },
    getLeadsAssigneeList: (state, action: PayloadAction<IUser[]>) => {
      state.listForLeads = action.payload;
    },
    getAbandonedCheckoutsAssigneeList: (state, action: PayloadAction<IUser[]>) => {
      state.listForAbandonedCheckouts = action.payload;
    },
  },
});

export const assigneeReducer = assigneeSlice.reducer;
export const {
  getOrdersAssigneeList,
  getInvoicesAssigneeList,
  getPresentationsAssigneeList,
  getCartsAssigneeList,
  getCustomersAssigneeList,
  getLeadsAssigneeList,
  getAbandonedCheckoutsAssigneeList,
} = assigneeSlice.actions;
