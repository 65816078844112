import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import GhostContentAPI from '@tryghost/content-api';
import { IBlog } from 'interfaces';

const api = new GhostContentAPI({
  url: process.env.REACT_APP_BLOG_URL ?? '',
  key: process.env.REACT_APP_BLOG_API_KEY ?? '',
  version: 'v5.0',
});

interface IState {
  loading: boolean;
  blogs: IBlog[];
}

const initialState: IState = {
  loading: false,
  blogs: [],
};

export const fetchBlogs = createAsyncThunk<IBlog[]>('blog/fetchPosts', async () => {
  try {
    const posts = await api.posts.browse({ limit: 2 });
    return posts.map((post) => ({
      title: post.title ?? '',
      url: post.url ?? '',
    }));
  } catch (e) {
    throw new Error('FAILED TO FETCH BLOG');
  }
});

export const blogSlice = createSlice({
  initialState,
  name: 'blogSlice',
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBlogs.fulfilled, (state, action) => {
      state.blogs = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchBlogs.rejected, (state) => {
      state.blogs = [];
      state.loading = false;
    });
    builder.addCase(fetchBlogs.pending, (state) => {
      state.loading = true;
    });
  },
});

export const blogReducer = blogSlice.reducer;
export const { reset } = blogSlice.actions;
