import { useNavigate } from 'react-router-dom';
import { SwagButton } from '@ezetech/swag-space-x';

import { ROUTES } from 'constants/router';
import { ColoredContainerHeader } from 'components/colored-container-header';

import css from './expired.module.scss';

interface IProps {
  title: string;
  description: string;
}

export function Expired({ title, description }: IProps) {
  const navigate = useNavigate();
  const onClick = () => {
    navigate(ROUTES.LOGIN);
  };

  return (
    <div className={css.expired}>
      <div className={css.mainBlock}>
        <ColoredContainerHeader title={title} description={description} />
      </div>
      <div className={css.mainBlock}>
        <SwagButton type="primary" size="xl" fullWidth onClick={onClick}>
          Back to Login
        </SwagButton>
      </div>
    </div>
  );
}
