export enum STEP_NAME {
  BRAND = 'brand',
  PAGE_LINK = 'pageLink',
  PRODUCT_SELECTION = 'productSelection',
  COMMISSION = 'commission',
  SIGN_CONTRACT = 'signContract',
}

export enum STORE_CREATION_ROUTES {
  STORE_CREATION_BRAND = '/store-creation/brand',
  STORE_CREATION_PAGE_LINK = '/store-creation/page-link',
  STORE_CREATION_PRODUCT_SELECTION = '/store-creation/product-selection',
  STORE_CREATION_COMMISSION = '/store-creation/commission',
  STORE_CREATION_CONTRACT = '/store-creation/contract',
}

export enum ONBOARDING_STATUS {
  COMPLETED = 'COMPLETED',
  UNCOMPLETED = 'UNCOMPLETED',
}
