import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const SLICE_NAME = 'withdraw';

export interface IWithdrawSlice {
  pending: number;
  available: number;
  income: number;
}

const initialState: IWithdrawSlice = {
  pending: 0,
  available: 0,
  income: 0,
};

export const withdraw = createSlice({
  initialState,
  name: SLICE_NAME,
  reducers: {
    setWithdrawData: (
      state,
      action: PayloadAction<{
        available: IWithdrawSlice['available'];
        pending: IWithdrawSlice['pending'];
        incoming: IWithdrawSlice['income'];
      }>,
    ) => {
      state.available = action.payload.available;
      state.pending = action.payload.pending;
      state.income = action.payload.incoming;
    },

    updateAvailableForWithdrawAmount: (
      state,
      action: PayloadAction<{ available: IWithdrawSlice['available'] }>,
    ) => {
      state.available = action.payload.available;
    },
  },
});

export const withdrawReducer = withdraw.reducer;
export const { updateAvailableForWithdrawAmount, setWithdrawData } = withdraw.actions;
