import { FC } from 'react';
import { SwagButton, Typography } from '@ezetech/swag-space-x';
import classNames from 'classnames';
import { ReactComponent as Eye } from 'assets/svg/eye.svg';
import { ReactComponent as Pen } from 'assets/svg/pen.svg';

import COLORS from 'constants/styles/colors-js.constant.module.scss';

import { HeaderProps } from '../interfaces/components';
import classes from '../edit-pdf-presentation-layout-popup.module.scss';

export const Header: FC<HeaderProps> = ({
  onCloseClicked,
  onSaveClicked,
  previewActive,
  togglePreviewActive,
  isEditMode,
}) => {
  return (
    <div className={classes.header}>
      <Typography fontType="HEADING5XL" color={COLORS.colorPrimaryText}>
        Edit Checkout PDF
      </Typography>
      <div className={classes.buttonContainers}>
        <SwagButton
          onClick={onCloseClicked}
          className={classNames(classes.button, classes.discardButton)}
          size="small"
          type="outlined"
        >
          Exit
        </SwagButton>
        {isEditMode && (
          <SwagButton
            onClick={onSaveClicked}
            className={classes.button}
            size="small"
            type="primary"
          >
            Save
          </SwagButton>
        )}
      </div>
      {isEditMode && (
        <SwagButton
          onClick={() => togglePreviewActive()}
          className={classes.headerMobileRoundButton}
          buttonTextClassName={classes.headerMobileRoundButtonText}
          borderRad={'round'}
          type={'outlined'}
        >
          {previewActive ? <Eye /> : <Pen />}
        </SwagButton>
      )}
    </div>
  );
};
