export const FIELDS = {
  brandName: {
    label: 'Store Link',
    placeholder: 'Your store link',
    name: 'brandName',
  },
  homepageTitle: {
    label: 'Homepage Title',
    placeholder: 'Your Brand | The ultimate Swag Platform',
    name: 'homepageTitle',
  },
  logoLinkUrl: {
    label: 'Logo URL',
    placeholder: 'https://www.your-homepage.com',
    name: 'logoLinkUrl',
  },
};
