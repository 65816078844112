import { createSelector } from 'reselect';
import { PER_PAGE_RECORDS } from 'constants/common';
import { RootState } from '../store';
import { initialState } from '../slices/filter.slice';

export const filtersSelector = (state: RootState) => state.filters;

export const searchFiltersSelector = createSelector(
  filtersSelector,
  (filters) => filters.search || '',
);
export const pageFiltersSelector = createSelector(
  filtersSelector,
  (filters) => filters.page,
);

export const perPageFiltersSelector = createSelector(
  filtersSelector,
  (filters) => filters.perPage,
);

export const lastPageFiltersSelector = createSelector(
  filtersSelector,
  ({ perPage, totalCount }) => Math.ceil(totalCount / perPage),
);

export const dropdownFiltersSelector = createSelector(
  filtersSelector,
  (filter) => filter.dropdown,
);

export const sortingFiltersSelector = createSelector(
  filtersSelector,
  (filters) => filters.sorting,
);

export const sortingOrderFiltersSelector = createSelector(
  filtersSelector,
  (filters) => filters.sortingOrder,
);

export const filterInitialRequestSelector = () => {
  return {
    page: 1,
    search: '',
    dropdown: [],
    sorting: '',
    sortingOrder: initialState.sortingOrder,
    perPage: PER_PAGE_RECORDS,
  };
};

export const filtersRequestSelector = createSelector(
  pageFiltersSelector,
  searchFiltersSelector,
  perPageFiltersSelector,
  sortingFiltersSelector,
  sortingOrderFiltersSelector,
  dropdownFiltersSelector,
  (page, search, perPage, sorting, sortingOrder, dropdown) => {
    return {
      page,
      perPage,
      search,
      sorting,
      sortingOrder,
      dropdown,
    };
  },
);
