import cn from 'classnames';
import { PropsWithChildren } from 'react';
import css from './content.module.scss';

type PageEditorContentProps = {
  title?: string;
  className?: string;
};

export const PageEditorContent = (
  props: PropsWithChildren<PageEditorContentProps>,
): JSX.Element => {
  const { children, className } = props;
  return <div className={cn(css.content, className)}>{children}</div>;
};
