import { AssignmentEntityType } from './assignee.interface';
import { GeneralPermissions } from './permission.interface';

export enum InvitationStatus {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  REVOKED = 'REVOKED',
  EXPIRED = 'EXPIRED',
}

export type AssignedEntitiesData = { [key: AssignmentEntityType]: number };

export interface ITeamMember {
  id: string;
  status?: InvitationStatus;
  firstName: string;
  lastName: string;
  email: string;
  lastActivityAt?: string;
  userId?: string;
  expiresAt?: string;
  permissions?: GeneralPermissions;
  assignedEntities?: AssignedEntitiesData;
}

export interface IInvitationBody {
  email: string;
  firstName: string;
  lastName: string;
  permissions: GeneralPermissions;
}
