import { useCallback } from 'react';
import { IntercomSettings } from '@intercom/messenger-js-sdk/types';
import { INTERCOM_DEFAULT_PADDING } from 'constants/intercom';

export function useIntercomUpdate() {
  const updateIntercom = useCallback((settings: Partial<IntercomSettings>): void => {
    if (window.Intercom) {
      window.Intercom('update', settings);
    }

    if ('vertical_padding' in settings) {
      updateLauncherPadding(settings.vertical_padding);
    }
  }, []);

  return { updateIntercom };
}

function updateLauncherPadding(padding: number = INTERCOM_DEFAULT_PADDING): void {
  const launcher = document.querySelector('.intercom-launcher') as HTMLElement | null;
  if (launcher) {
    launcher.style.bottom = `${padding}px`;
  }
}
