import { useCallback, useEffect, useState } from 'react';
import { RainbowBox } from '@ezetech/swag-space-x';
import { BoxWidgetEnum } from 'enums/box-widget-enum';
import { BoxWidget } from 'components/box-widget';
import { FadeInOut } from 'components/fade-inout';
import { ColoredContainerHeader } from 'components/colored-container-header';
import { ApplicationSignUpType } from 'interfaces/auth-api.interface';
import { useCreateMutation as createApplication } from 'redux/api/application.api';
import { useQuery } from 'hooks/use-query.hook';

import { Submitted } from './submited';
import { Review } from './review';
import { SignUpForm } from './form/signup-form';

import css from './signup.module.scss';

const REVIEW_RESULT_MESSAGE = 'Your application is under review';

enum Steps {
  signup = 'signup',
  review = 'review',
  submitted = 'submitted',
}

export function SignUp() {
  const [step, setStep] = useState<Steps>(Steps.signup);
  const [signUp, { isLoading, isSuccess, data: appData }] = createApplication();

  useEffect(() => {
    if (isSuccess) {
      if (appData?.message?.length && appData.message[0] === REVIEW_RESULT_MESSAGE) {
        setStep(Steps.review);
      } else {
        setStep(Steps.submitted);
      }
    }
  }, [isLoading]);

  const query = useQuery();
  const referralLink = query.get('referrals');

  const onSubmit = useCallback((data: ApplicationSignUpType) => {
    signUp({ ...data, referralLink });
  }, []);

  const signUpState = step === Steps.signup ? 'show' : 'fadeOut';
  const submittedState = step === Steps.submitted ? 'fadeIn' : 'hide';
  const reviewState = step === Steps.review ? 'fadeIn' : 'hide';

  return (
    <>
      <FadeInOut state={signUpState}>
        <RainbowBox borderWidth={8} innerClassName={css.rainbowBlock}>
          <div className={css.signup}>
            <ColoredContainerHeader
              title="Swag Space is the future. Be part of it today."
              description="Enter your details to apply for access!"
            />
            <BoxWidget type={BoxWidgetEnum.REGULAR} className={css.formContainer}>
              <SignUpForm onSubmit={onSubmit} loading={isLoading} />
            </BoxWidget>
          </div>
        </RainbowBox>
      </FadeInOut>
      <FadeInOut state={submittedState} delay={1}>
        <RainbowBox borderWidth={8} innerClassName={css.rainbowBlock}>
          <Submitted />
        </RainbowBox>
      </FadeInOut>
      <FadeInOut state={reviewState} delay={1}>
        <RainbowBox borderWidth={8} innerClassName={css.rainbowBlock}>
          <Review />
        </RainbowBox>
      </FadeInOut>
    </>
  );
}
