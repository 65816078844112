import {
  Dialog,
  DialogContent,
  DialogContentWrapper,
  DialogDescription,
  DialogFooter,
  DialogTitle,
  IStripePaymentFormRefType,
  StripePaymentForm,
  StripeProvider,
  SwagButton,
  Typography,
} from '@ezetech/swag-space-x';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo, useRef, useState } from 'react';
import * as yup from 'yup';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import {
  AVAILABLE_COUNTRIES_LIST,
  CountriesStatesSelect,
  getStates,
} from 'constants/settings.constants';
import { isRequired } from 'utils/yup.util';
import { CreateCreditCardPopupProps, IStripeChangeData } from 'interfaces';
import { CreditCardCreationFlow } from 'interfaces/flow.inteface';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { closePopup } from 'redux/slices/modals.slice';
import { useAddCreditCardMutation } from 'redux/api/settings.api';
import { editedCustomerCompanyDetailsSelector } from 'redux/selectors/customer-details.selector';
import { useUpdateUserMutation } from 'redux/api/user.api';
import { createCreditCardSchema } from 'validations/payments/create-credit-card.validation';
import { InputForm } from '../../input-form';
import { SelectForm } from '../../select-from';
import { FIELDS } from './create-credit-card.constants';
import classes from './create-credit-card.popup.module.scss';

const popupDescription = {
  [CreditCardCreationFlow.PaymentMethod]: '',
  [CreditCardCreationFlow.Promocode]: `Before you can create a promocode that is more than your commission (30%) or
    any $ off promocode, you must first add a credit card. Once your customers use
    the promocode and checkout, we will charge you the difference. For example, if
    the order is for $1,000 and you have 30% commission, you will make $300. If
    you give a 40% off promocode, you will make $0 and we will need to charge you
    $100.`,
};

export const CreateCreditCardPopup = ({
  isOpen,
  paymentMethod,
  setPaymentMethod,
  flow = CreditCardCreationFlow.Promocode,
}: CreateCreditCardPopupProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const [addCreditCard, { isLoading }] = useAddCreditCardMutation();
  const user = useAppSelector(editedCustomerCompanyDetailsSelector);
  const [updateUser] = useUpdateUserMutation();
  const stripeRef = useRef<IStripePaymentFormRefType>(null);
  const [stripeError, setStripeError] = useState('');
  const [stripeValues, setStripeValues] = useState<IStripeChangeData>({
    isComplete: false,
    fields: {
      cardNumber: {
        empty: true,
        error: '',
        complete: false,
      },
      cardExpiry: {
        empty: true,
        error: '',
        complete: false,
      },
      cardCvc: {
        empty: true,
        error: '',
        complete: false,
      },
    },
  });
  const { handleSubmit, control, formState, getValues, watch, setValue } =
    useForm<FieldValues>({
      mode: 'onTouched',
      resolver: yupResolver(createCreditCardSchema),
    });
  const { isValid } = formState;
  const [requiredFields, setRequiredFields] = useState<
    Record<string, yup.SchemaFieldDescription>
  >(createCreditCardSchema.describe().fields);

  const handleOpenChange = () => {
    dispatch(closePopup());
  };

  const getOptions = (name: keyof typeof FIELDS) => {
    if (name === 'country') {
      return AVAILABLE_COUNTRIES_LIST;
    } else if (name === 'state') {
      const country = getValues().country;
      return country === CountriesStatesSelect.CA || country === CountriesStatesSelect.US
        ? getStates(country)
        : [];
    } else {
      return [];
    }
  };

  const getInput = (name: keyof typeof FIELDS, additionalProps: object = {}) => {
    const { component, condition, ...fieldData } = FIELDS[name];
    const conditionalComponent = condition?.(getValues()) ?? component;
    if (conditionalComponent === 'input') {
      return (
        <InputForm
          className={classes.input}
          {...fieldData}
          control={control}
          required={isRequired(requiredFields[name])}
          {...additionalProps}
        />
      );
    }

    return (
      <SelectForm
        className={classes.input}
        {...fieldData}
        options={getOptions(name)}
        control={control}
        required={isRequired(requiredFields[name])}
        {...additionalProps}
      />
    );
  };

  const StateInput = useMemo(() => {
    setValue('state', undefined, {
      shouldValidate: false,
    });
    return getInput('state');
  }, [watch('country')]);

  const handleOnChange = () => {
    const { fields } = createCreditCardSchema.describe({
      value: {
        [FIELDS.country.name]: getValues(FIELDS.country.name),
      },
    });
    setRequiredFields(fields);
  };

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    stripeRef.current?.submit({
      address: {
        city: data.city,
        country: data.country,
        postal_code: data.zip,
        state: data.state,
        line1: data.unit ? `${data.street}, ${data.unit}` : data.street,
      },
      name: `${data.firstName} ${data.lastName}`,
    });
  };

  const onStripeResult = async (paymentMethodId: string) => {
    const result = await addCreditCard(paymentMethodId);
    if (!('error' in result)) {
      dispatch(closePopup());

      if (flow === CreditCardCreationFlow.PaymentMethod) {
        await updateUser({
          id: user.userId,
          paymentMethod,
          setPaymentMethod,
        });
      }
    }
  };

  return (
    <Dialog className={classes.dialog} open={isOpen} onOpenChange={handleOpenChange}>
      <DialogContentWrapper
        className={classes.dialogContentWrapperClassName}
        overlayClassName={classes.dialogOverlayClassName}
      >
        <DialogTitle>Add a credit card</DialogTitle>
        <div className={classes.dialogBody}>
          {popupDescription[flow] && (
            <DialogDescription>{popupDescription[flow]}</DialogDescription>
          )}
          <DialogContent className={classes.dialogContent}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={classes.inputsRow}>
                {getInput('firstName')}
                {getInput('lastName')}
              </div>
              <div className={classes.inputsRow}>
                {getInput('street')}
                {getInput('unit')}
              </div>
              <div className={classes.inputsRow}>
                {getInput('country', {
                  rules: {
                    onChange: handleOnChange,
                  },
                })}
                {getInput('city')}
              </div>
              <div className={classes.inputsRow}>
                {StateInput}
                {getInput('zip')}
              </div>
              <div>
                <StripeProvider
                  publishableKey={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ?? ''}
                >
                  <StripePaymentForm
                    className={classes.stripeForm}
                    ref={stripeRef}
                    onChange={({ isFormCompleted: isComplete, fields }) =>
                      setStripeValues({
                        isComplete,
                        fields,
                      })
                    }
                    onResult={(data) => onStripeResult(data.id)}
                    onError={setStripeError}
                  />
                  {stripeError && (
                    <Typography color={COLORS.colorErrorText}>{stripeError}</Typography>
                  )}
                </StripeProvider>
              </div>
            </form>
          </DialogContent>
        </div>
        <DialogFooter className={classes.dialogFooter}>
          <div>
            <SwagButton onClick={handleOpenChange} type="outlined">
              Cancel
            </SwagButton>
            <SwagButton
              onClick={handleSubmit(onSubmit)}
              disabled={!isValid || !stripeValues.isComplete}
              type="primary"
              loading={isLoading}
            >
              Save
            </SwagButton>
          </div>
        </DialogFooter>
      </DialogContentWrapper>
    </Dialog>
  );
};
