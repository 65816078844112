import { Typography } from '@ezetech/swag-space-x';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { useAppSelector } from 'redux/store';
import { selectedAbandonedCartProductsSelector } from 'redux/selectors/abandoned-checkout-carts.selector';
import { Price } from 'utils/price.util';
import { getDayAndMonth } from 'utils/date/date-formatter.util';
import { getRelatedItemsForAbandonedCheckouts } from 'utils/items.util';

import css from './products-tooltip-content.module.scss';

export const ProductsTooltipContent = ({
  abandonedCartId,
}: {
  abandonedCartId?: string;
}) => {
  const products = useAppSelector(selectedAbandonedCartProductsSelector(abandonedCartId));
  const relatedItemsIds = getRelatedItemsForAbandonedCheckouts(products);

  return (
    <div className={css.tooltipBody}>
      {products.map(
        (
          {
            name,
            quantity: productQuantity,
            pricePerItem,
            setupFee,
            productionTime,
            estInHand,
            variants,
            itemPublicId,
          },
          i,
        ) => (
          <div className={css.row} key={`${name}-${i}`}>
            {i !== 0 && <div className={css.divider} />}
            <Typography
              fontType="bodySm"
              lineHeight="143%"
              color={COLORS.colorTypographyHeadings}
              className={css.name}
            >
              Product {i + 1}: {name}
            </Typography>
            <div>
              {productQuantity > 0 && (
                <Typography
                  fontType="bodySm"
                  lineHeight="143%"
                  color={COLORS.colorTypographyHeadings}
                >
                  Quantity: {productQuantity}
                </Typography>
              )}
            </div>

            <div>
              {productQuantity > 0 && !!variants?.length && (
                <Typography
                  fontType="bodySm"
                  lineHeight="143%"
                  color={COLORS.colorTypographyHeadings}
                >
                  Colors:{' '}
                  {variants?.map(
                    (color, index) =>
                      `${color.name}${index + 1 < variants?.length ? ',' : ''}`,
                  )}
                </Typography>
              )}
            </div>
            <div>
              {productQuantity > 0 && !!pricePerItem && (
                <Typography
                  fontType="bodySm"
                  lineHeight="143%"
                  color={COLORS.colorTypographyHeadings}
                >
                  Cost per item: {Price.formatPrice(pricePerItem) || 'n/a'}
                </Typography>
              )}
            </div>
            <div>
              {productQuantity > 0 && !!setupFee && (
                <Typography
                  fontType="bodySm"
                  lineHeight="143%"
                  color={COLORS.colorTypographyHeadings}
                >
                  {relatedItemsIds.includes(itemPublicId)
                    ? 'Shared setup fee'
                    : 'Setup fee'}
                  : {Price.formatPrice(setupFee)}
                </Typography>
              )}
            </div>
            <div>
              {productQuantity > 0 && !!productionTime && (
                <Typography
                  fontType="bodySm"
                  lineHeight="143%"
                  color={COLORS.colorTypographyHeadings}
                >
                  Production time: {productionTime}
                </Typography>
              )}
            </div>
            <div>
              {productQuantity > 0 && !!estInHand?.from && (
                <Typography
                  fontType="bodySm"
                  lineHeight="143%"
                  color={COLORS.colorTypographyHeadings}
                >
                  Est In Hand: {getDayAndMonth(new Date(estInHand.from))} -{' '}
                  {getDayAndMonth(new Date(estInHand.to))}
                </Typography>
              )}
            </div>
          </div>
        ),
      )}
    </div>
  );
};
