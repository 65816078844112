import { Checkbox, ITab, ITrigger } from '@ezetech/swag-space-x';
import { CheckedState } from '@radix-ui/react-checkbox';

import { CategoriesStructureWithSelections } from 'interfaces/store-creation.interfaces';
import { useAppDispatch } from 'redux/store';
import { selectCategory } from 'redux/slices/store-creation.slice';
import { cannot, SUBJECTS } from 'boot/ability';

import css from './product-selection.module.scss';

interface IReturn {
  tabs: ITab[];
  triggers: ITrigger[];
}

interface IParams {
  selectedCategories: CategoriesStructureWithSelections;
  dispatchFn?: ({
    checked,
    parentIndex,
    index,
  }: {
    checked: boolean;
    parentIndex: number;
    index: number;
  }) => void;
}

export function getCategoriesTabs({ selectedCategories, dispatchFn }: IParams): IReturn {
  const dispatch = useAppDispatch();
  const isDisabled = cannot(
    SUBJECTS.AVAILABLE_PRODUCTS.actions.AVAILABLE_PRODUCTS_EDIT,
    SUBJECTS.AVAILABLE_PRODUCTS.value,
  );

  const onCheckboxClick =
    ({ parentIndex, index }: { parentIndex: number; index: number }) =>
    (checked: CheckedState): void => {
      if (dispatchFn && typeof dispatchFn === 'function') {
        dispatchFn({ checked: !!checked, parentIndex, index });
      } else {
        dispatch(selectCategory({ checked: !!checked, parentIndex, index }));
      }
    };

  return selectedCategories.reduce<IReturn>(
    (acc, category, index) => {
      const { tabs, triggers } = acc;
      const { id, name, items, isEnabled } = category;

      tabs.push({
        id,
        node: (
          <div className={css.categoriesRow}>
            {items.map((item, position) => {
              return (
                <Checkbox
                  disabled={isDisabled || !isEnabled}
                  onCheckedChange={onCheckboxClick({
                    parentIndex: index,
                    index: position,
                  })}
                  name={item.id}
                  key={item.id}
                  checked={!!item?.isSelected}
                  label={item?.name}
                />
              );
            })}
          </div>
        ),
      });

      triggers.push({
        name,
        disabled: !category?.isEnabled,
      });

      return acc;
    },
    { tabs: [], triggers: [] } as IReturn,
  );
}
