import cn from 'classnames';
import { Tabs, Toggle, TogglePlacement, ToggleWidth } from '@ezetech/swag-space-x';

import { CategoriesStructureWithSelections } from 'interfaces/store-creation.interfaces';
import { cannot, SUBJECTS } from 'boot/ability';

import { getCategoriesTabs } from './categories-tabs';

import css from './product-selection.module.scss';

export function ProductWidget({
  selectedCategories,
  onTabClick,
  activeCategoryTab,
  onToggle,
  isCurrentCategoryEnabled,
  currentCategoryName = '',
  dispatchFn,
  widgetProductsListClassName,
  disableLineClassName,
}: {
  selectedCategories: CategoriesStructureWithSelections;
  onTabClick: (index: number) => void;
  activeCategoryTab: number;
  onToggle: () => void;
  isCurrentCategoryEnabled: boolean;
  currentCategoryName: string;
  dispatchFn?: ({
    checked,
    parentIndex,
    index,
  }: {
    checked: boolean;
    parentIndex: number;
    index: number;
  }) => void;
  widgetProductsListClassName?: string;
  disableLineClassName?: string;
}): JSX.Element {
  const { tabs, triggers } = getCategoriesTabs({
    selectedCategories,
    dispatchFn,
  });

  const isDisabled = cannot(
    SUBJECTS.AVAILABLE_PRODUCTS.actions.AVAILABLE_PRODUCTS_EDIT,
    SUBJECTS.AVAILABLE_PRODUCTS.value,
  );

  return (
    <div className={cn(css.widget, css.widgetProductsList, widgetProductsListClassName)}>
      {tabs?.length ? (
        <Tabs
          onClick={onTabClick}
          activeTab={activeCategoryTab}
          tabs={tabs}
          triggers={triggers}
          tabsClassName={css.tabsClassName}
          tabsContentClassName={css.tabsContent}
          className={css.tabsWrapper}
        />
      ) : null}
      <div className={cn(css.disableLine, disableLineClassName)}>
        <Toggle
          onChange={isDisabled ? () => null : onToggle}
          value={isCurrentCategoryEnabled}
          label={`${
            isCurrentCategoryEnabled ? 'Disable' : 'Enable'
          } ${currentCategoryName}`}
          name={`Disable ${currentCategoryName}`}
          width={ToggleWidth.short}
          placement={TogglePlacement.right}
        />
      </div>
    </div>
  );
}
