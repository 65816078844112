import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { userApi } from './api/user.api';
import { authApi } from './api/auth.api';
import { applicationApi } from './api/application.api';
import { storeSettingsApi } from './api/store-creation.api';
import { withdrawApi } from './api/withdraw.api';
import { dashboardApi } from './api/dashboard.api';
import { settingsApi } from './api/settings.api';
import { ordersApi } from './api/orders.api';
import { orderApi } from './api/order.api';
import { promocodesApi } from './api/promocode.api';
import { technicalDataApi } from './api/technical-data.api';
import { productsApi } from './api/products.api';
import { abandonedCheckoutsCartsApi } from './api/abandoned-checkouts-carts.api';
import { resellerCertificatesApi } from './api/reseller-certificates.api';
import { referralsApi } from './api/referrals.api';
import { permissionApi } from './api/permissions.api';
import { assigneeApi } from './api/assignee.api';
import { customerCompanyApi } from './api/customer-company.api';

import { userReducer } from './slices/user.slice';
import { notificationReducer } from './slices/notifications.slice';
import { storeCreationReducer } from './slices/store-creation.slice';
import { dashboardReducer } from './slices/dashboard.slice';
import { settingsReducer } from './slices/settings.slice';
import { customersLeadsReducer } from './slices/customers-leads.slice';
import { filtersReducer } from './slices/filter.slice';
import { ordersReducer } from './slices/orders.slice';
import { customerDetailsReducer } from './slices/customer-details.slice';
import { modalsReducer } from './slices/modals.slice';
import { withdrawReducer } from './slices/withdraw.slice';
import { promocodesReducer } from './slices/promocodes.slice';
import { blogReducer } from './slices/blog.slice';
import { technicalDataReducer } from './slices/technical-data.slice';
import { productsReducer } from './slices/products.slice';
import { cartApi } from './api/cart.api';
import { cartsReducer } from './slices/carts.slice';
import { presentationsReducer } from './slices/pdf-presentations.slice';
import { presentationsApi } from './api/presentation.api';
import { invoicesReducer } from './slices/invoices.slice';
import { invoicesApi } from './api/invoice.api';
import { pdfPresentationsEditorReducer } from './slices/pdf-presentation-editor.slice';
import { abandonedCheckoutsCartsReducer } from './slices/abandoned-checkouts-carts.slice';
import { resellerCertificatesReducer } from './slices/reseller-certificate.slice';
import { invitationApi } from './api/invitation.api';
import { invitationsReducer } from './slices/invitations.slice';
import { dashboardMiddleware } from './middlewares/dashboard.middlewares';
import { referralsReducer } from './slices/referrals.slice';
import { assigneeReducer } from './slices/assignee.slice';
import { customerCompanyReducer } from './slices/customer-company.slice';

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [applicationApi.reducerPath]: applicationApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [storeSettingsApi.reducerPath]: storeSettingsApi.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    [settingsApi.reducerPath]: settingsApi.reducer,
    [withdrawApi.reducerPath]: withdrawApi.reducer,
    [ordersApi.reducerPath]: ordersApi.reducer,
    [orderApi.reducerPath]: orderApi.reducer,
    [promocodesApi.reducerPath]: promocodesApi.reducer,
    [technicalDataApi.reducerPath]: technicalDataApi.reducer,
    [productsApi.reducerPath]: productsApi.reducer,
    [cartApi.reducerPath]: cartApi.reducer,
    [presentationsApi.reducerPath]: presentationsApi.reducer,
    [invoicesApi.reducerPath]: invoicesApi.reducer,
    [abandonedCheckoutsCartsApi.reducerPath]: abandonedCheckoutsCartsApi.reducer,
    [resellerCertificatesApi.reducerPath]: resellerCertificatesApi.reducer,
    [invitationApi.reducerPath]: invitationApi.reducer,
    [referralsApi.reducerPath]: referralsApi.reducer,
    [permissionApi.reducerPath]: permissionApi.reducer,
    [assigneeApi.reducerPath]: assigneeApi.reducer,
    [customerCompanyApi.reducerPath]: customerCompanyApi.reducer,
    user: userReducer,
    orders: ordersReducer,
    dashboard: dashboardReducer,
    notifications: notificationReducer,
    storeCreation: storeCreationReducer,
    settings: settingsReducer,
    customersLeads: customersLeadsReducer,
    filters: filtersReducer,
    customerDetails: customerDetailsReducer,
    modals: modalsReducer,
    withdraw: withdrawReducer,
    promocodes: promocodesReducer,
    blog: blogReducer,
    carts: cartsReducer,
    abandonedCheckoutsCarts: abandonedCheckoutsCartsReducer,
    technicalData: technicalDataReducer,
    products: productsReducer,
    presentations: presentationsReducer,
    invoices: invoicesReducer,
    pdfPresentationsEditor: pdfPresentationsEditorReducer,
    resellerCertificates: resellerCertificatesReducer,
    invitations: invitationsReducer,
    referrals: referralsReducer,
    assignee: assigneeReducer,
    customerCompany: customerCompanyReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      authApi.middleware,
      applicationApi.middleware,
      userApi.middleware,
      storeSettingsApi.middleware,
      dashboardApi.middleware,
      settingsApi.middleware,
      withdrawApi.middleware,
      ordersApi.middleware,
      orderApi.middleware,
      promocodesApi.middleware,
      technicalDataApi.middleware,
      cartApi.middleware,
      productsApi.middleware,
      presentationsApi.middleware,
      invoicesApi.middleware,
      abandonedCheckoutsCartsApi.middleware,
      dashboardMiddleware.middleware,
      resellerCertificatesApi.middleware,
      invitationApi.middleware,
      referralsApi.middleware,
      permissionApi.middleware,
      assigneeApi.middleware,
      customerCompanyApi.middleware,
    ),
  devTools: process.env.NODE_ENV === 'development',
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
