import { createSelector } from 'reselect';
import { deepEqual } from 'utils/deep-equal';
import { InvitationStatus } from 'interfaces/invitation.interface';
import { RootState } from '../store';

export const invitationsSelector = (state: RootState) => state.invitations;
export const isLoadingInvitationsSelector = createSelector(
  invitationsSelector,
  (invitations) => invitations.isLoading,
);
export const teamInvitationsSelector = createSelector(
  invitationsSelector,
  (invitations) =>
    invitations.team.map((member) => {
      const isExpired =
        member.expiresAt &&
        new Date().toISOString() > new Date(member.expiresAt).toISOString() &&
        member.status === InvitationStatus.PENDING;

      return {
        ...member,
        key: member.id,
        status: isExpired ? InvitationStatus.EXPIRED : member.status,
      };
    }),
);
export const pageInvitationsSelector = createSelector(
  invitationsSelector,
  (invitations) => invitations.page,
);
export const perPageInvitationsSelector = createSelector(
  invitationsSelector,
  (invitations) => invitations.perPage,
);
export const totalInvitationsSelector = createSelector(
  invitationsSelector,
  (invitations) => invitations.total,
);
export const hasNextPageInvitationsSelector = createSelector(
  invitationsSelector,
  (invitations) => Math.ceil(invitations.total / invitations.perPage) >= invitations.page,
);
export const formInvitationsSelector = createSelector(
  invitationsSelector,
  (invitations) => invitations.form,
);
export const formErrorsInvitationsSelector = createSelector(
  invitationsSelector,
  (invitations) => invitations.formErrors,
);
export const teamMemberDetailsInvitationsSelector = createSelector(
  invitationsSelector,
  (invitations) => invitations.teamMemberDetails,
);
export const permissionsInvitationsSelector = createSelector(
  invitationsSelector,
  (invitations) => invitations.permissions,
);
export const isPermissionsChangedInvitationsSelector = createSelector(
  invitationsSelector,
  (invitations) =>
    !deepEqual(
      invitations.permissions as never,
      invitations.teamMemberDetails?.permissions as never,
    ),
);

export const assignedEntitiesSelector = createSelector(
  invitationsSelector,
  (invitations) => invitations.teamMemberDetails?.assignedEntities,
);

export const isAnyAssignedEntitiesSelector = createSelector(
  invitationsSelector,
  (invitations) =>
    Object.values(invitations.teamMemberDetails?.assignedEntities || {}).some(
      (item) => item > 0,
    ),
);

export const isAtLeastOnePermissionActiveInvitationsSelector = createSelector(
  permissionsInvitationsSelector,
  (permissions) =>
    Object.values(permissions).some((permission) => {
      return Object.values(permission.permissions).some(
        (subPermission) => subPermission.value,
      );
    }),
);
export const isFormValidInvitationsSelector = createSelector(
  formErrorsInvitationsSelector,
  (formErrors) => Object.values(formErrors).every((error) => !error),
);
export const isFormChangedInvitationsSelector = createSelector(
  formInvitationsSelector,
  teamMemberDetailsInvitationsSelector,
  (form, teamMemberDetails) =>
    !deepEqual(
      form as never,
      {
        firstName: teamMemberDetails?.firstName,
        lastName: teamMemberDetails?.lastName,
        email: teamMemberDetails?.email,
      } as never,
    ),
);
