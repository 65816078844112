import {
  Dialog,
  DialogContentWrapper,
  DialogTitle,
  DialogContent,
  DialogFooter,
  SwagButton,
  Typography,
  DialogDescription,
} from '@ezetech/swag-space-x';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { closePopup, openPopup } from 'redux/slices/modals.slice';
import { clearInvoiceError } from 'redux/slices/settings.slice';
import {
  useCheckCompanyDetailsMutation,
  usePatchInvoiceMutation,
} from 'redux/api/settings.api';
import {
  isInvoiceValidValidSelector,
  invoiceSelector,
} from 'redux/selectors/settings.selectors';
import COLORS from 'constants/styles/colors-js.constant.module.scss';

import { InvoiceForm } from 'pages/settings/plan-and-payment/forms/invoice';
import { InvoiceCreationBankDetailsProps } from 'interfaces/popup.interface';
import { mapInvoiceToDB } from 'utils/settings/mappers';
import { INVOICE_ACH_NET_TERMS_INFORMATION_POPUP } from '../_logic/popups-list';
import css from './invoice-creation-bank-details-popup.module.scss';

export const InvoiceCreationBankDetails = ({
  isOpen,
  presentationEmail,
}: InvoiceCreationBankDetailsProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const values = useAppSelector(invoiceSelector);
  const isFormValid = useAppSelector(isInvoiceValidValidSelector);
  const [patchInvoice, { isLoading: patchLoading }] = usePatchInvoiceMutation();
  const [checkCompanyDetails] = useCheckCompanyDetailsMutation();

  const onCloseClick = () => {
    dispatch(closePopup());
    dispatch(clearInvoiceError());
    dispatch(
      openPopup({
        popupName: INVOICE_ACH_NET_TERMS_INFORMATION_POPUP,
        popupProps: { backButtonName: 'Go Back' },
      }),
    );
  };
  const onSaveClick = async () => {
    if (!isFormValid) {
      return;
    }
    const {
      invoiceBankCountry,
      invoiceBankName,
      invoiceBankAccountNumber,
      invoiceAccountType,
      invoiceAccountBeneficiaryName,
      invoiceBIC,
      invoiceRoutingNumber,
    } = values;
    await patchInvoice(
      mapInvoiceToDB({
        invoiceBankCountry,
        invoiceBankName,
        invoiceAsInPayout: false,
        invoiceBankAccountNumber,
        invoiceAccountType,
        invoiceAccountBeneficiaryName,
        invoiceBIC,
        invoiceRoutingNumber,
      }),
    );
    await checkCompanyDetails({ email: presentationEmail });
  };

  return (
    <Dialog open={isOpen} onOpenChange={onCloseClick}>
      <DialogContentWrapper
        className={css.dialogContent}
        overlayClassName={css.dialogOverlay}
      >
        <DialogTitle closeIconClassName={css.closeIconClassName}>
          ADD BANK DETAILS
        </DialogTitle>
        <DialogContent className={css.dialogBody}>
          <DialogDescription className={css.subTitle}>
            <Typography color={COLORS.colorTextSubdued} fontType="bodyMd">
              Before creating your first invoice, we need you to setup your bank details
              in your settings so that they appear on your invoice to your customer.
              Please enter your bank details to continue.
            </Typography>
          </DialogDescription>
          <InvoiceForm withToggle={false} isPopupForm={true} classForm={css.noScroll} />
        </DialogContent>
        <DialogFooter className={css.footer}>
          <SwagButton className={css.button} type="outlined" onClick={onCloseClick}>
            Go Back
          </SwagButton>
          <SwagButton
            className={css.button}
            loading={patchLoading}
            disabled={!isFormValid || patchLoading}
            type="primary"
            onClick={onSaveClick}
          >
            Add Bank Details
          </SwagButton>
        </DialogFooter>
      </DialogContentWrapper>
    </Dialog>
  );
};
