import React from 'react';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { selectedHomepageProductsListSelector } from 'redux/selectors/settings.selectors';
import { removeHomepageProductSelection } from 'redux/slices/settings.slice';
import { Product } from './product';
import css from './search-products.module.scss';

export const SelectionList: React.FC = () => {
  const dispatch = useAppDispatch();
  const products = useAppSelector(selectedHomepageProductsListSelector);

  if (products.length === 0) {
    return null;
  }

  const handleRemoveClick = (publicId: string) => {
    dispatch(removeHomepageProductSelection({ publicId }));
  };

  return (
    <div className={css.selectionList}>
      {products.map((product) => (
        <Product
          key={product.publicId}
          product={product}
          onRemoveClick={handleRemoveClick}
          isSearch={false}
        />
      ))}
    </div>
  );
};
