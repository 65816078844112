import React, { FC, useCallback, useMemo } from 'react';
import {
  Divider,
  Input,
  SwagButton,
  Tooltip,
  TooltipPlacement,
  Typography,
  WhiteBox,
} from '@ezetech/swag-space-x';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { SaveTooltip } from 'components/save-tooltip';
import { ROUTES, ROUTES_NAMES } from 'constants/router';
import { can, SUBJECTS } from 'boot/ability';

import { useAppDispatch, useAppSelector } from 'redux/store';
import { profileSelector } from 'redux/selectors/user.selectors';
import {
  formErrorsInvitationsSelector,
  formInvitationsSelector,
  isAtLeastOnePermissionActiveInvitationsSelector,
  isPermissionsChangedInvitationsSelector,
  isLoadingInvitationsSelector,
  teamMemberDetailsInvitationsSelector,
  permissionsInvitationsSelector,
  isAnyAssignedEntitiesSelector,
} from 'redux/selectors/invitations.selector';
import {
  clear,
  handleFieldBlur,
  handleFieldChange,
  resetPermissions,
  validateForm,
} from 'redux/slices/invitations.slice';
import {
  useCreateInvitationMutation,
  useDeleteInvitationMutation,
  useResendInvitationMutation,
} from 'redux/api/invitation.api';
import { closePopup, openPopup } from 'redux/slices/modals.slice';
import { assignmentFlowEnabledSelector } from 'redux/selectors/settings.selectors';
import { useUpdateTeamMemberPermissionsMutation } from '../../redux/api/user.api';
import { PageContent } from '../../components/page-content';
import { CONFIRMATION_POPUP } from '../../components/popups/_logic/popups-list';
import classes from './team-member-details.module.scss';
import {
  TEAM_MEMBER_DETAILS_MANAGE_ACCESS_BUTTON_TEXT,
  TEAM_MEMBER_DETAILS_MANAGE_ACCESS_TEXT,
} from './team-member-details.constants';
import { Permissions } from './components/permissions';
import { TeamMemberDetailsProps, TeamMemberMode } from './index';

export const TeamMemberDetails: FC<TeamMemberDetailsProps> = ({
  mode = TeamMemberMode.CREATE,
}) => {
  const isLoading = useAppSelector(isLoadingInvitationsSelector);
  const [createInvitation, { isLoading: isCreateLoading }] =
    useCreateInvitationMutation();
  const [deleteInvitation] = useDeleteInvitationMutation();
  const [updateTeamMemberPermission] = useUpdateTeamMemberPermissionsMutation();
  const [resendInvitation, { isLoading: isResendLoading }] =
    useResendInvitationMutation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const teamMemberDetails = useAppSelector(teamMemberDetailsInvitationsSelector);
  const formValues = useAppSelector(formInvitationsSelector);
  const formErrors = useAppSelector(formErrorsInvitationsSelector);
  const hasErrors = Object.values(formErrors).some((error) => !!error);
  const userProfile = useAppSelector(profileSelector);
  const permissions = useAppSelector(permissionsInvitationsSelector);
  const permissionsChanged = useAppSelector(isPermissionsChangedInvitationsSelector);
  const isAnyAssignedEntities = useAppSelector(isAnyAssignedEntitiesSelector);
  const assignmentFlowEnabled = useAppSelector(assignmentFlowEnabledSelector);
  const atLeastOnePermissionActive = useAppSelector(
    isAtLeastOnePermissionActiveInvitationsSelector,
  );
  const saveTooltipShown = mode === TeamMemberMode.EDIT && permissionsChanged;
  const saveTooltipDisabled = mode === TeamMemberMode.EDIT && !atLeastOnePermissionActive;
  const isSelf = userProfile?.id === teamMemberDetails?.userId;

  const hasPermissionToEdit = can(
    SUBJECTS.EDIT_STAFF.actions.EDIT_STAFF_EDIT,
    SUBJECTS.EDIT_STAFF.value,
  );
  const hasPermissionToView = can(
    SUBJECTS.EDIT_STAFF.actions.EDIT_STAFF_VIEW,
    SUBJECTS.EDIT_STAFF.value,
  );
  const hasPermissionToAdd = can(
    SUBJECTS.EDIT_STAFF.actions.ADD_STAFF,
    SUBJECTS.EDIT_STAFF.value,
  );
  const handleFormFieldChange =
    (field: string) => (e: React.FocusEvent<HTMLInputElement>) => {
      const value = e.target.value;
      dispatch(
        handleFieldChange({
          key: field,
          value,
        }),
      );
    };

  const handleFormFieldBlur =
    (field: string) => (e: React.FocusEvent<HTMLInputElement>) => {
      const value = e.target.value;
      dispatch(
        handleFieldBlur({
          key: field,
          value,
        }),
      );
    };

  const handlePopupClose = useCallback(() => {
    if (!isLoading) {
      dispatch(closePopup());
    }
  }, [isLoading]);

  const handlePopupConfirm = useCallback(async () => {
    if (teamMemberDetails?.id && !isLoading) {
      const result = (await deleteInvitation(teamMemberDetails)) as {
        error: unknown;
      };
      if (!result.error) {
        navigate(ROUTES.SETTINGS_USER_AND_PERMISSIONS);
        dispatch(closePopup());
      }
    }
  }, [isLoading, teamMemberDetails]);

  const isButtonDisabled = useMemo(() => {
    if (mode === TeamMemberMode.CREATE) {
      return hasErrors || !atLeastOnePermissionActive;
    }
    return hasErrors || isSelf;
  }, [hasErrors, isSelf, atLeastOnePermissionActive]);

  const disabledTooltipText = useMemo(() => {
    if (hasErrors) {
      return 'There are validation errors on the page';
    }
    if (!atLeastOnePermissionActive) {
      return 'Please, select at least one permission';
    }
    return '';
  }, [hasErrors, atLeastOnePermissionActive]);

  const popupProps = {
    isConfirmLoading: isLoading,
    onConfirm: handlePopupConfirm,
    onCancel: handlePopupClose,
    title: 'Are you sure you want to remove this user from your account?',
    descriptionComponent: `${assignmentFlowEnabled && isAnyAssignedEntities ? 'Hey, you are going to remove a team member who has some assignments. Their assignments will become unassigned. ' : ''} 
    Once you confirm this user will no longer have access to this account.`,
    confirmText: 'Remove',
    confirmButtonStyle: 'danger',
  };

  const handleButtonClick = async () => {
    if (isSelf || hasErrors || isLoading) {
      return;
    }
    dispatch(clear());
    switch (mode) {
      case TeamMemberMode.CREATE: {
        const formValid = (await dispatch(validateForm())).payload;
        if (formValid) {
          const result = (await createInvitation({
            firstName: formValues.firstName,
            lastName: formValues.lastName,
            email: formValues.email,
            permissions,
          })) as {
            error: unknown;
          };
          if (!result.error) {
            navigate(ROUTES.SETTINGS_USER_AND_PERMISSIONS);
          }
        }
        break;
      }
      default: {
        dispatch(
          openPopup({
            popupName: CONFIRMATION_POPUP,
            popupProps,
          }),
        );
        break;
      }
    }
  };

  const handleResendClick = async () => {
    if (!teamMemberDetails || teamMemberDetails.userId) {
      return;
    }

    const result = await resendInvitation(teamMemberDetails);

    if (!('error' in result)) {
      navigate(ROUTES.SETTINGS_USER_AND_PERMISSIONS);
    }
  };

  const handleDiscardChanges = () => {
    dispatch(resetPermissions());
  };

  const handleSaveChanges = async () => {
    const result = await updateTeamMemberPermission({
      userId: teamMemberDetails?.userId,
      permissions: permissions,
      invitationId: teamMemberDetails?.id,
    });
    if (!('error' in result)) {
      navigate(ROUTES.SETTINGS_USER_AND_PERMISSIONS);
    }
  };

  return (
    <PageContent
      heading={
        mode === TeamMemberMode.CREATE
          ? ROUTES_NAMES.TEAM_MEMBER_DETAILS_CREATE
          : ROUTES_NAMES.TEAM_MEMBER_DETAILS_EDIT
      }
    >
      <div className={classes.pageInner}>
        <div className={classes.root}>
          <WhiteBox className={classes.container}>
            <Typography
              className={classes.header}
              fontType="HEADING5XL"
              color={COLORS.colorText900}
            >
              Team Member Details
            </Typography>
            <Divider color={COLORS.colorDivider} />
            <div className={classNames(classes.row, classes.rowFirst)}>
              <Input
                labelClassName={
                  mode === TeamMemberMode.CREATE ? classes.label : undefined
                }
                key={'firstName'}
                className={classes.input}
                label="First Name"
                name="firstName"
                errors={formErrors.firstName}
                onBlur={handleFormFieldBlur('firstName')}
                onChange={handleFormFieldChange('firstName')}
                defaultValue={formValues.firstName}
                placeholder="Enter First Name"
                required
                disabled={mode === TeamMemberMode.EDIT}
              />
              <Input
                labelClassName={
                  mode === TeamMemberMode.CREATE ? classes.label : undefined
                }
                key={'lastName'}
                className={classes.input}
                label="Last Name"
                name="lastName"
                errors={formErrors.lastName}
                onBlur={handleFormFieldBlur('lastName')}
                onChange={handleFormFieldChange('lastName')}
                defaultValue={formValues.lastName}
                placeholder="Enter Last Name"
                required
                disabled={mode === TeamMemberMode.EDIT}
              />
            </div>
            <div className={classes.row}>
              <Input
                labelClassName={
                  mode === TeamMemberMode.CREATE ? classes.label : undefined
                }
                key={'email'}
                className={classes.input}
                label="Email Address"
                name="email"
                errors={formErrors.email}
                onBlur={handleFormFieldBlur('email')}
                onChange={handleFormFieldChange('email')}
                defaultValue={formValues.email}
                placeholder="Enter Email"
                required
                disabled={mode === TeamMemberMode.EDIT}
              />
            </div>
          </WhiteBox>
          {(hasPermissionToView ||
            (hasPermissionToAdd && mode === TeamMemberMode.CREATE)) && (
            <>
              <WhiteBox className={classes.container}>
                <div className={classes.tooltipWrapper}>
                  <Typography
                    className={classes.header}
                    fontType="HEADING5XL"
                    color={COLORS.colorText900}
                  >
                    Permission Settings
                  </Typography>
                  <div className={classes.iconWrapper}>
                    <Tooltip placement={TooltipPlacement.right}>
                      <div className={classes.tooltipText}>
                        <Typography
                          fontType="bodySm"
                          lineHeight="143%"
                          color={COLORS.colorTypographyHeadings}
                        >
                          Permissions grant the ability to view and edit, unless specified
                          otherwise by the permission title.
                        </Typography>
                      </div>
                    </Tooltip>
                  </div>
                </div>
                <Divider color={COLORS.colorDivider} />

                <Permissions
                  isEditable={
                    (hasPermissionToEdit && mode === TeamMemberMode.EDIT) ||
                    (hasPermissionToAdd && mode === TeamMemberMode.CREATE)
                  }
                />
              </WhiteBox>
              {((hasPermissionToEdit && mode === TeamMemberMode.EDIT) ||
                (hasPermissionToAdd && mode === TeamMemberMode.CREATE)) && (
                <WhiteBox className={classes.container}>
                  <Typography
                    className={classes.header}
                    fontType="HEADING5XL"
                    color={COLORS.colorText900}
                  >
                    Manage Access
                  </Typography>
                  <Divider color={COLORS.colorDivider} />
                  <Typography
                    lineHeight="162.5%"
                    className={classes.bodyText}
                    fontType="bodyMd"
                    color={COLORS.colorText900}
                  >
                    {TEAM_MEMBER_DETAILS_MANAGE_ACCESS_TEXT[mode]}
                  </Typography>
                  <div className={classes.buttons}>
                    <SwagButton
                      type="primary"
                      className={classNames(classes.button, {
                        [classes.buttonDanger]: mode === TeamMemberMode.EDIT,
                        [classes.buttonDangerDisabled]:
                          mode === TeamMemberMode.EDIT && (hasErrors || isSelf),
                        [classes.buttonDangerLoading]:
                          mode === TeamMemberMode.EDIT && isCreateLoading,
                      })}
                      disabled={isButtonDisabled}
                      onClick={handleButtonClick}
                      loading={isCreateLoading}
                    >
                      {TEAM_MEMBER_DETAILS_MANAGE_ACCESS_BUTTON_TEXT[mode]}
                    </SwagButton>
                    {!teamMemberDetails?.userId && mode === TeamMemberMode.EDIT && (
                      <SwagButton
                        type="outlined"
                        className={classes.button}
                        disabled={isResendLoading}
                        onClick={handleResendClick}
                        loading={isResendLoading}
                      >
                        Resend Invite
                      </SwagButton>
                    )}
                  </div>
                </WhiteBox>
              )}
            </>
          )}
        </div>
      </div>
      <SaveTooltip
        isShown={saveTooltipShown}
        isLoading={false}
        isDisabled={saveTooltipDisabled}
        onSave={handleSaveChanges}
        onDiscard={handleDiscardChanges}
        disabledTooltipText={disabledTooltipText}
      />
    </PageContent>
  );
};
