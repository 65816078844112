import { MultiValue } from 'react-select';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OptionType } from '@ezetech/swag-space-x';
import { DEFAULT_PAGE } from 'constants/common';
import {
  IProduct,
  IHomepageProduct,
  IProductsIdsListResponse,
  IHomepageProductsListResponse,
} from 'interfaces/products.interface';

interface IProductsState {
  list: IProduct[];
  homepageList: IHomepageProduct[];
  total: number;
  allProductsTotal: number;
  hiddenTotal: number;
  availableProductsTotalToHide: number;
  selectedProducts: Record<string, boolean>;
  selectedProductsManually: Record<string, boolean>;
  search: string | null;
  perPage: number;
  page: number;
  filter: MultiValue<OptionType | null>;
  isLoading: boolean;
  selectAll: boolean;
  isSelectAllManual: boolean;
}

const initialState: IProductsState = {
  list: [],
  homepageList: [],
  total: 0,
  allProductsTotal: 0,
  availableProductsTotalToHide: 0,
  hiddenTotal: 0,
  search: null,
  perPage: 20,
  page: DEFAULT_PAGE,
  filter: [],
  selectedProducts: {},
  selectedProductsManually: {},
  isLoading: false,
  selectAll: false,
  isSelectAllManual: false,
};

export const productsSlice = createSlice({
  initialState,
  name: 'productsSlice',
  reducers: {
    reset: (state) => ({ ...initialState, hiddenTotal: state.hiddenTotal }),
    setProducts: (
      state,
      action: PayloadAction<{
        list: IProductsState['list'];
        total: IProductsState['total'];
        perPage: IProductsState['perPage'];
        page: IProductsState['page'];
        areAllSelected?: boolean;
        hiddenProductsIds?: string[];
        hiddenTotal?: IProductsState['hiddenTotal'];
        availableProductsTotalToHide?: IProductsState['availableProductsTotalToHide'];
      }>,
    ) => {
      if (!action.payload) {
        return state;
      }

      const {
        list,
        hiddenTotal,
        hiddenProductsIds,
        areAllSelected = false,
        ...rest
      } = action.payload;
      const products = rest.page === DEFAULT_PAGE ? list : [...state.list, ...list];
      const selectedProducts = { ...state.selectedProducts };
      const updatedHiddenTotal =
        hiddenTotal ?? (hiddenProductsIds?.length || state.hiddenTotal || 0);

      hiddenProductsIds?.forEach((id) => {
        const isProductExistsInSelection = Object.hasOwnProperty.call(
          selectedProducts,
          id,
        );

        if (!isProductExistsInSelection) {
          selectedProducts[id] = true;
        }
      });

      return {
        ...state,
        ...rest,
        selectedProducts,
        list: products,
        selectAll: areAllSelected,
        hiddenTotal: updatedHiddenTotal,
      };
    },
    updateSearchParams: (
      state,
      action: PayloadAction<{
        search?: IProductsState['search'];
        filter?: IProductsState['filter'];
      }>,
    ) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    checkProduct: (state, action: PayloadAction<string>) => {
      let availableProductsTotalToHide = state.availableProductsTotalToHide;
      const productId = action.payload;
      const selectedProducts = { ...state.selectedProducts };
      const selectedProductsManually = { ...state.selectedProductsManually };

      const isProductExistsInSelection = Object.hasOwnProperty.call(
        selectedProducts,
        productId,
      );
      const product = state.list.find((p) => p.id === productId);

      if (isProductExistsInSelection && !product?.isHidden) {
        delete selectedProducts[productId];
        delete selectedProductsManually[productId];
        availableProductsTotalToHide++;
      }
      if (!isProductExistsInSelection && product?.isHidden) {
        selectedProducts[productId] = false;
        selectedProductsManually[productId] = false;
        availableProductsTotalToHide++;
      }
      if (!isProductExistsInSelection && !product?.isHidden) {
        selectedProducts[productId] = true;
        selectedProductsManually[productId] = true;
        availableProductsTotalToHide--;
      }
      if (isProductExistsInSelection && product?.isHidden) {
        if (!selectedProducts[productId]) {
          availableProductsTotalToHide--;
        } else {
          availableProductsTotalToHide++;
        }
        selectedProductsManually[productId] = !selectedProducts[productId];
        selectedProducts[productId] = !selectedProducts[productId];
      }

      const totalSelected = Object.values(selectedProducts).filter(Boolean).length;
      const isSelectAllNeedToBeUnchecked =
        isProductExistsInSelection && !selectedProducts[productId];
      const isAllSelectedFromList = state.list.every((p) => selectedProducts[p.id]);

      if (state.allProductsTotal === totalSelected && !!totalSelected) {
        return {
          ...state,
          selectedProducts,
          selectedProductsManually,
          availableProductsTotalToHide,
          selectAll: true,
          isSelectAllManual: false,
        };
      }

      if (isAllSelectedFromList && !!totalSelected && !availableProductsTotalToHide) {
        return {
          ...state,
          selectedProducts,
          selectedProductsManually,
          availableProductsTotalToHide,
          selectAll: true,
          isSelectAllManual: false,
        };
      }

      return {
        ...state,
        selectedProducts,
        selectedProductsManually,
        availableProductsTotalToHide,
        selectAll: isSelectAllNeedToBeUnchecked ? false : state.selectAll,
        isSelectAllManual: isSelectAllNeedToBeUnchecked ? false : state.isSelectAllManual,
      };
    },
    checkProducts: (state, action: PayloadAction<IProductsIdsListResponse>) => {
      const { hidden, visible } = action.payload;
      const selectedProducts = { ...state.selectedProducts };
      const selectedProductsManually = { ...state.selectedProductsManually };

      hidden.forEach((productId) => {
        selectedProducts[productId] = state.selectAll;
        selectedProductsManually[productId] = state.selectAll;
      });

      visible.forEach((productId) => {
        if (state.selectAll) {
          selectedProducts[productId] = true;
          selectedProductsManually[productId] = true;
        } else {
          delete selectedProducts[productId];
          delete selectedProductsManually[productId];
        }
      });

      if (state.selectAll) {
        return {
          ...state,
          selectedProducts,
          selectedProductsManually,
          allProductsTotal: Object.values(selectedProducts).filter(Boolean).length,
        };
      }

      return { ...state, selectedProductsManually, selectedProducts };
    },
    setSelectAll: (state, action: PayloadAction<boolean>) => {
      return { ...state, selectAll: action.payload, isSelectAllManual: true };
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      return { ...state, isLoading: action.payload };
    },
    setHomepageProducts: (
      state,
      action: PayloadAction<{ list: IHomepageProductsListResponse['list'] }>,
    ) => {
      return { ...state, homepageList: action.payload.list };
    },
  },
});

export const productsReducer = productsSlice.reducer;
export const {
  reset,
  setProducts,
  setLoading,
  setSelectAll,
  updateSearchParams,
  checkProducts,
  checkProduct,
  setHomepageProducts,
} = productsSlice.actions;
