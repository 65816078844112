import {
  ICustomerDetails,
  IUserAndNotesDetails,
  TAX_METHODS,
} from 'interfaces/customer-details.interface';

export const mapUserDetails = (customer: IUserAndNotesDetails): IUserAndNotesDetails => {
  const user: ICustomerDetails['user'] = {
    id: '',
    role: 'CUSTOMER',
    email: '',
    phone: undefined,
    lastName: '',
    firstName: '',
    orderedOn: '',
    companyName: '',
    type: customer.user?.type || '',
    paymentMethod: null,
    paymentMethodFrequency: null,
    isSpecificReviewMockupsByCustomerAllowed: null,
    isSpecificReviewPantonesByCustomerAllowed: null,
    tax: customer.user?.tax || TAX_METHODS.TAX_CHANGE,
    isTaxExemptPending: false,
    ...customer.user,
    company: {
      id: '',
      name: '',
      createdAt: new Date().toString(),
      updatedAt: new Date().toString(),
      ...customer.user?.company,
    },
    address: {
      address: '',
      city: '',
      country: '',
      state: '',
      zip: '',
      ...customer.user?.address,
    },
  };

  return {
    ...customer,
    user,
  };
};
