import { FC } from 'react';
import { ValidationError } from 'yup';
import { Input, OptionType, Select, TextField } from '@ezetech/swag-space-x';
import { useSelector } from 'react-redux';
import { IPayout } from 'interfaces/settings.interface';
import {
  AVAILABLE_COUNTRIES_LIST,
  EUROPE_COUNTRY_CODES,
} from 'constants/settings.constants';
import { payoutErrorsSelector, payoutSelector } from 'redux/selectors/settings.selectors';
import { useAppDispatch } from 'redux/store';
import { setPayoutError, patchPayout } from 'redux/slices/settings.slice';
import { cannot, SUBJECTS } from 'boot/ability';

import css from '../forms.module.scss';
import { FIELDS, SAVING_OPTIONS } from './payout.contants';
import { payoutFormSchema } from './payout.schema';

export const PayoutForm: FC = () => {
  const form = useSelector(payoutSelector);
  const errors = useSelector(payoutErrorsSelector);
  const dispatch = useAppDispatch();
  const {
    payoutBankCountry,
    payoutBankName,
    payoutAccountType,
    payoutAccountBeneficiaryName,
    payoutBankAccountNumber,
    payoutBIC,
    payoutRoutingNumber,
    payoutComments,
  } = form;

  const isDisabled = cannot(
    SUBJECTS.PAYOUT_BANK_DETAILS.actions.PAYOUT_BANK_DETAILS_EDIT,
    SUBJECTS.PAYOUT_BANK_DETAILS.value,
  );

  const onChange = async (path: string, value: unknown) => {
    dispatch(
      patchPayout({
        [path]: value,
      }),
    );
    if (path === FIELDS.payoutBankCountry.name && value !== 'US') {
      dispatch(setPayoutError({ [FIELDS.payoutRoutingNumber.name]: undefined }));
    }
    if (path === FIELDS.payoutBankCountry.name && value === 'US') {
      dispatch(setPayoutError({ [FIELDS.payoutBIC.name]: undefined }));
    }
    dispatch(setPayoutError({ [path]: undefined }));
  };

  const onBlur = (path: string) => async () => {
    try {
      await payoutFormSchema.validateAt(
        path,
        {
          [FIELDS.payoutBankCountry.name]: payoutBankCountry,
          [path]: form[path as keyof IPayout],
        },
        {
          abortEarly: false,
        },
      );
      dispatch(setPayoutError({ [path]: undefined }));
    } catch (validationError: unknown) {
      const { inner } = validationError as ValidationError;
      const message = inner.find(({ path: p }) => p === path)?.message ?? '';
      if (message) {
        dispatch(setPayoutError({ [path]: message }));
      }
    }
  };

  const bankCountryValue =
    AVAILABLE_COUNTRIES_LIST.find(({ value }) => value === payoutBankCountry) ?? null;
  const accountTypeValue =
    SAVING_OPTIONS.find(({ value }) => value === payoutAccountType) ?? null;
  const isEurope = EUROPE_COUNTRY_CODES.includes(payoutBankCountry);
  const isUSA = payoutBankCountry === 'US';

  return (
    <form className={css.form}>
      <Select
        isDisabled={isDisabled}
        options={AVAILABLE_COUNTRIES_LIST}
        className={css.input}
        label={FIELDS.payoutBankCountry.label}
        name={FIELDS.payoutBankCountry.name}
        value={bankCountryValue}
        errors={errors.payoutBankCountry}
        onBlur={onBlur(FIELDS.payoutBankCountry.name)}
        onChange={(value) =>
          onChange(FIELDS.payoutBankCountry.name, (value as OptionType).value)
        }
        placeholder={FIELDS.payoutBankCountry.placeholder}
      />
      <div className={css.twoInRowContainer}>
        <Input
          disabled={isDisabled}
          inputWrapperClassName={css.inputWrapper}
          className={css.input}
          label={FIELDS.payoutBankName.label}
          name={FIELDS.payoutBankName.name}
          errors={errors.payoutBankName}
          onBlur={onBlur(FIELDS.payoutBankName.name)}
          value={payoutBankName}
          onChange={(e) => onChange(FIELDS.payoutBankName.name, e.target.value)}
          placeholder={FIELDS.payoutBankName.placeholder}
        />
        <Select
          isDisabled={isDisabled}
          options={SAVING_OPTIONS}
          className={css.input}
          label={FIELDS.payoutAccountType.label}
          name={FIELDS.payoutAccountType.name}
          value={accountTypeValue}
          errors={errors.payoutAccountType}
          onBlur={onBlur(FIELDS.payoutAccountType.name)}
          onChange={(value) =>
            onChange(FIELDS.payoutAccountType.name, (value as OptionType).value)
          }
          placeholder={FIELDS.payoutAccountType.placeholder}
        />
      </div>
      <Input
        disabled={isDisabled}
        inputWrapperClassName={css.inputWrapper}
        className={css.input}
        label={FIELDS.payoutAccountBeneficiaryName.label}
        name={FIELDS.payoutAccountBeneficiaryName.name}
        errors={errors.payoutAccountBeneficiaryName}
        onBlur={onBlur(FIELDS.payoutAccountBeneficiaryName.name)}
        value={payoutAccountBeneficiaryName}
        onChange={(e) =>
          onChange(FIELDS.payoutAccountBeneficiaryName.name, e.target.value)
        }
        placeholder={FIELDS.payoutAccountBeneficiaryName.placeholder}
      />
      <div className={css.twoInRowContainer}>
        <Input
          disabled={isDisabled}
          inputWrapperClassName={css.inputWrapper}
          className={css.input}
          label={FIELDS.payoutBankAccountNumber.label(isEurope)}
          name={FIELDS.payoutBankAccountNumber.name}
          onBlur={onBlur(FIELDS.payoutBankAccountNumber.name)}
          errors={errors.payoutBankAccountNumber}
          value={payoutBankAccountNumber}
          onChange={(e) => onChange(FIELDS.payoutBankAccountNumber.name, e.target.value)}
          placeholder={FIELDS.payoutBankAccountNumber.placeholder(isEurope)}
        />
        <Input
          disabled={isDisabled}
          inputWrapperClassName={css.inputWrapper}
          className={css.input}
          label={FIELDS.payoutBIC.label(isUSA)}
          name={FIELDS.payoutBIC.name}
          onBlur={onBlur(FIELDS.payoutBIC.name)}
          errors={errors.payoutBIC}
          value={payoutBIC}
          onChange={(e) => onChange(FIELDS.payoutBIC.name, e.target.value)}
          placeholder={FIELDS.payoutBIC.placeholder}
        />
      </div>
      {isUSA && (
        <div className={css.twoInRowContainer}>
          <Input
            disabled={isDisabled}
            inputWrapperClassName={css.inputWrapper}
            className={css.input}
            label={FIELDS.payoutRoutingNumber.label}
            name={FIELDS.payoutRoutingNumber.name}
            onBlur={onBlur(FIELDS.payoutRoutingNumber.name)}
            errors={errors.payoutRoutingNumber}
            value={payoutRoutingNumber}
            onChange={(e) => onChange(FIELDS.payoutRoutingNumber.name, e.target.value)}
            placeholder={FIELDS.payoutRoutingNumber.placeholder}
          />
          <div className={css.fieldSpacer} />
        </div>
      )}
      <TextField
        disabled={isDisabled}
        className={css.input}
        label={FIELDS.payoutComments.label}
        name={FIELDS.payoutComments.name}
        errors={errors.payoutComments}
        value={payoutComments}
        onChange={(e) => onChange(FIELDS.payoutComments.name, e.target.value)}
        placeholder={FIELDS.payoutComments.placeholder}
        {...{ rows: 5 }}
      />
    </form>
  );
};
