import {
  Dialog,
  DialogContentWrapper,
  DialogTitle,
  DialogDescription,
  DialogFooter,
  SwagButton,
  Typography,
} from '@ezetech/swag-space-x';
import { useSelector } from 'react-redux';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { closePopup, openPopup } from 'redux/slices/modals.slice';
import COLORS from 'constants/styles/colors-js.constant.module.scss';

import { InvoiceDeleteConfirmationPopupProps } from 'interfaces';
import {
  domainSettingsSelector,
  storeLinkSelector,
} from 'redux/selectors/settings.selectors';
import { useDeleteDomainMutation } from 'redux/api/settings.api';
import { CONFIRMATION_POPUP, EDIT_DOMAIN_POPUP } from '../_logic/popups-list';
import css from './edit-domain-popup.module.scss';

export const EditDomainPopup = ({
  isOpen,
}: InvoiceDeleteConfirmationPopupProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const onBtnCloseClick = () => {
    dispatch(closePopup());
  };
  const storeLink = useAppSelector(storeLinkSelector);
  const customDomainInfo = useSelector(domainSettingsSelector);
  const [deleteCustomDomain, { isLoading }] = useDeleteDomainMutation();

  const onBtnDeleteClick = () => {
    if (customDomainInfo?.id) {
      dispatch(
        openPopup({
          popupName: CONFIRMATION_POPUP,
          popupProps: {
            descriptionComponent: 'Are you sure you want to delete custom domain?',
            isConfirmLoading: isLoading,
            onConfirm: () => {
              if (customDomainInfo.id) {
                deleteCustomDomain({ domainId: customDomainInfo.id });
              }
              dispatch(closePopup());
            },
            onCancel: () => {
              dispatch(openPopup({ popupName: EDIT_DOMAIN_POPUP, popupProps: {} }));
            },
          },
        }),
      );
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onBtnCloseClick} key={customDomainInfo?.id}>
      <DialogContentWrapper
        className={css.dialogContentWrapperClassName}
        overlayClassName={css.dialogOverlayClassName}
      >
        <DialogTitle closeIconClassName={css.closeIconClassName}>
          Edit custom domain
        </DialogTitle>
        <DialogDescription>
          <div className={css.description}>
            <Typography
              color={COLORS.colorTextSubdued}
              fontType="bodyMd"
              lineHeight="162.5%"
            >
              Your store uses a custom domain{' '}
              <a
                href={`https://${customDomainInfo?.domain}`}
                className={css.link}
                target="_blank"
                rel="noreferrer"
              >
                https://{customDomainInfo?.domain}
              </a>
              . You can switch back to the default domain{' '}
              <a href={storeLink} className={css.link} target="_blank" rel="noreferrer">
                {storeLink}
              </a>{' '}
              by removing the custom domain setup.
            </Typography>
          </div>
        </DialogDescription>

        <DialogFooter className={css.footer}>
          <SwagButton type="outlined" onClick={onBtnCloseClick}>
            Cancel
          </SwagButton>
          <SwagButton type="destructivePrimary" onClick={onBtnDeleteClick}>
            Remove custom domain
          </SwagButton>
        </DialogFooter>
      </DialogContentWrapper>
    </Dialog>
  );
};
