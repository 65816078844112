import { Router, To, RouterNavigateOptions } from '@remix-run/router';

let innerRouter: Router;
export function injectRouter(rootRouter: Router) {
  innerRouter = rootRouter;
}

const navigate = (to: To, opts?: RouterNavigateOptions): Promise<void> => {
  return 'navigate' in innerRouter ? innerRouter.navigate(to, opts) : Promise.resolve();
};

export const routerOutside = {
  navigate,
};
