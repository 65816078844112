import { useState } from 'react';
import {
  Dialog,
  DialogContentWrapper,
  DialogTitle,
  DialogContent,
  DialogFooter,
  SwagButton,
  Divider,
  Loader,
} from '@ezetech/swag-space-x';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { closePopup } from 'redux/slices/modals.slice';
import { useSendInvoiceMutation } from 'redux/api/order.api';
import { orderSendInvoicePopupSelector } from 'redux/selectors/orders.selectors';
import { SendAnInvoicePopupProps } from 'interfaces/popup.interface';
import { ISendInvoiceRequest } from 'interfaces/order-api.interface';
import { FREE } from 'constants/common';
import { Price } from 'utils/price.util';
import { OrderTotalPricesRow } from './order-total-prices-row';
import { OrderItemsTable } from './order-items-table';
import css from './send-an-invoice-popup.module.scss';

export const SendAnInvoicePopup = ({
  isOpen,
  orderId = '',
}: SendAnInvoicePopupProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const [taxesMap, setTaxes] = useState<Record<string, number>>({});
  const [sendInvoice, { isLoading }] = useSendInvoiceMutation();
  const { orderTotal, taxesTotal, discountTotal, isTaxExempt } = useAppSelector(
    orderSendInvoicePopupSelector(orderId),
  );
  const userTaxes = Object.values(taxesMap).reduce<number>(
    (all, tax) => all + (tax || 0),
    0,
  );
  const orderTotalPrice = orderTotal - taxesTotal + userTaxes;
  const orderTotalValue = orderTotalPrice > 0 ? Price.formatPrice(orderTotalPrice) : FREE;
  const discountTotalValue =
    discountTotal > 0 ? `-${Price.formatPrice(discountTotal)}` : undefined;

  const handleTaxChange = (itemId: string, value: string) => {
    setTaxes((prevState) => ({ ...prevState, [itemId]: parseFloat(value) * 100 }));
  };

  const checkIsValid = () => {
    const taxes = Object.values(taxesMap);

    if (!taxes.length) {
      return false;
    }

    return Object.values(taxesMap).every((value) => !isNaN(value));
  };

  const onCloseClick = () => {
    dispatch(closePopup());
  };
  const onSendClick = async () => {
    if (!checkIsValid()) {
      return;
    }

    const list = Object.keys(taxesMap).reduce<ISendInvoiceRequest['list']>(
      (all, itemId) => [...all, { itemId, tax: taxesMap[itemId] }],
      [],
    );

    await sendInvoice({ orderId, list });
  };

  return (
    <Dialog open={isOpen} onOpenChange={onCloseClick}>
      <DialogContentWrapper
        className={css.dialogContent}
        overlayClassName={css.dialogOverlay}
      >
        <DialogTitle>Edit invoice</DialogTitle>
        <DialogContent className={css.dialogBody}>
          <OrderItemsTable
            onTaxChange={handleTaxChange}
            orderId={orderId}
            isTaxExempt={isTaxExempt}
          />
          <Divider />
          <div className={css.prices}>
            {discountTotalValue && (
              <OrderTotalPricesRow label="Discount:" value={discountTotalValue} />
            )}
            <OrderTotalPricesRow label="Total:" value={orderTotalValue} />
          </div>
        </DialogContent>
        <DialogFooter showDivider className={css.footer}>
          <SwagButton className={css.button} type="outlined" onClick={onCloseClick}>
            Cancel
          </SwagButton>
          <SwagButton
            disabled={!checkIsValid() || isLoading}
            className={css.button}
            type="primary"
            onClick={onSendClick}
          >
            Send
          </SwagButton>
        </DialogFooter>
        {isLoading && (
          <div className={css.overlay}>
            <Loader />
          </div>
        )}
      </DialogContentWrapper>
    </Dialog>
  );
};
