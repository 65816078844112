import {
  Dialog,
  DialogContentWrapper,
  DialogTitle,
  DialogDescription,
  DialogFooter,
  SwagButton,
  Typography,
} from '@ezetech/swag-space-x';
import { useAppDispatch } from 'redux/store';
import { closePopup } from 'redux/slices/modals.slice';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { useCheckCompanyDetailsMutation } from 'redux/api/settings.api';
import { InvoiceCreationInformationProps } from 'interfaces/popup.interface';
import css from './invoice-creation-information-popup.module.scss';

export const InvoiceCreationInformationPopup = ({
  isOpen,
  backButtonName = 'Cancel',
  presentationEmail,
}: InvoiceCreationInformationProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const [checkCompanyDetails] = useCheckCompanyDetailsMutation();

  const onCloseClick = () => {
    dispatch(closePopup());
  };

  const onAgreeClick = () => {
    checkCompanyDetails({ email: presentationEmail });
  };

  return (
    <Dialog open={isOpen} onOpenChange={onCloseClick}>
      <DialogContentWrapper
        className={css.dialogContentWrapperClassName}
        overlayClassName={css.dialogOverlayClassName}
      >
        <DialogTitle closeIconClassName={css.closeIconClassName}>
          ACH / Net Terms
        </DialogTitle>
        <DialogDescription className={css.textWrapper}>
          <Typography color={COLORS.colorTextSubdued} fontType="bodyMd">
            By creating an invoice you agree to be responsible for collecting the entire
            amount for the order, including all applicable sales taxes, from your
            customers. You further agree to remit all sales taxes collected to the
            appropriate taxing authority, as applicable by state law and nexus mandates.
            In addition to your responsibility on the customer end, when you ultimately
            order products on Swag Space, the order value will be subject to Sales Tax,
            unless you have submitted a valid Tax Resale Certificate with us for the
            states where the products will be shipped. Tax Resale Certificates can be
            uploaded in your settings.
          </Typography>
        </DialogDescription>

        <DialogFooter className={css.footer}>
          <div className={css.controlButtons}>
            <SwagButton type="outlined" onClick={onCloseClick} className={css.btn}>
              {backButtonName}
            </SwagButton>
            <SwagButton type="primary" onClick={onAgreeClick} className={css.btn}>
              Agree
            </SwagButton>
          </div>
        </DialogFooter>
      </DialogContentWrapper>
    </Dialog>
  );
};
