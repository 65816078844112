import { OptionsGroupedType } from '@ezetech/swag-space-x';
import { makeArrayForSelectOptions } from 'utils/common.util';

export const WHAT_INDUSTRY_VALUES = {
  PROMOTIONAL_PRODUCT_DISTRIBUTOR: 'Promotional product distributor',
  PRINT_SHOP_OWNER: 'Print shop owner',
  PARTY_EVENT_PLANNER: 'Party/event planner',
  OTHER: 'Other',
};

export const WHAT_INDUSTRY = {
  [WHAT_INDUSTRY_VALUES.PROMOTIONAL_PRODUCT_DISTRIBUTOR]:
    'Promotional product distributor',
  [WHAT_INDUSTRY_VALUES.PRINT_SHOP_OWNER]: 'Print shop owner',
  [WHAT_INDUSTRY_VALUES.PARTY_EVENT_PLANNER]: 'Party/event planner',
  [WHAT_INDUSTRY_VALUES.OTHER]: 'Other',
};

export const COMPANY_REVENUE_VALUES = {
  UNDER_50K: 'Under $50,000',
  OVER_51K_100K: '$51,000 - $100,000',
  OVER_101K_250K: '$101,000 - $250,000',
  OVER_251K_500K: '$251,000 - $500,000',
  OVER_500K_1M: '$501,000 - $999,000',
  OVER_1M_3M: '$1,000,000 - $2,999,000',
  OVER_3M_5M: '$3,000,000 - $4,999,000',
  OVER_5M: '$5,000,000+',
};

export const COMPANY_REVENUE = {
  [COMPANY_REVENUE_VALUES.UNDER_50K]: 'Under $50,000',
  [COMPANY_REVENUE_VALUES.OVER_51K_100K]: '$51,000 - $100,000',
  [COMPANY_REVENUE_VALUES.OVER_101K_250K]: '$101,000 - $250,000',
  [COMPANY_REVENUE_VALUES.OVER_251K_500K]: '$251,000 - $500,000',
  [COMPANY_REVENUE_VALUES.OVER_500K_1M]: '$501,000 - $999,000',
  [COMPANY_REVENUE_VALUES.OVER_1M_3M]: '$1,000,000 - $2,999,000',
  [COMPANY_REVENUE_VALUES.OVER_3M_5M]: '$3,000,000 - $4,999,000',
  [COMPANY_REVENUE_VALUES.OVER_5M]: '$5,000,000+',
};

export const HOW_MANY_YEARS_VALUES = {
  UNDER_1Y: 'Under 1 year',
  NEAR_1_2Y: '1-2 years',
  NEAR_3_5Y: '3-5 years',
  OVER_6Y: 'Over 6 years',
};

export const HOW_MANY_YEARS = {
  [HOW_MANY_YEARS_VALUES.UNDER_1Y]: 'Under 1 year',
  [HOW_MANY_YEARS_VALUES.NEAR_1_2Y]: '1-2 years',
  [HOW_MANY_YEARS_VALUES.NEAR_3_5Y]: '3-5 years',
  [HOW_MANY_YEARS_VALUES.OVER_6Y]: 'Over 6 years',
};

export const FIELDS = {
  firstName: {
    label: 'First Name',
    name: 'firstName',
    placeholder: 'Enter First Name',
  },
  lastName: {
    label: 'Last Name',
    name: 'lastName',
    placeholder: 'Enter Last Name',
  },
  email: {
    label: 'Email',
    name: 'email',
    placeholder: 'Enter Email',
  },
  cellPhone: {
    label: 'Cell Phone',
    name: 'phone',
    placeholder: 'Enter Cell Phone',
  },
  companyName: {
    label: 'Company Name',
    name: 'companyName',
    placeholder: 'Enter Company Name',
  },
  whatIndustry: {
    label: 'What industry are you in',
    name: 'industry',
    placeholder: 'Select industry',
  },
  certificate: {
    label: 'ASI/PPAI Certificate Number',
    name: 'certificateNumber',
    placeholder: 'Enter Certificate Number',
  },
  howManyYears: {
    label: 'How many years in business',
    name: 'yearsInBusiness',
    placeholder: 'Select years',
  },
  companyRevenue: {
    label: "Last year's company revenue",
    name: 'companyRevenue',
    placeholder: 'Select Company revenue',
  },
};

export const WHAT_INDUSTRY_OPTIONS = makeArrayForSelectOptions<OptionsGroupedType>(
  WHAT_INDUSTRY,
  'Select your industry',
);

export const COMPANY_REVENUE_OPTIONS = makeArrayForSelectOptions<OptionsGroupedType>(
  COMPANY_REVENUE,
  'Select company revenue',
);

export const HOW_MANY_YEARS_OPTIONS = makeArrayForSelectOptions<OptionsGroupedType>(
  HOW_MANY_YEARS,
  'Select years',
);
