import { INotification, NotificationType } from '../redux/slices/notifications.slice';

export function getErrorMessages(
  error: unknown,
  defaultMessage = 'Something went wrong',
): string {
  let result = '';
  if (isErrorWithMessage(error)) {
    const { message } = error.data;
    result = Array.isArray(message) ? message.join(',') : message;
  }
  return result || defaultMessage;
}

export function isErrorWithMessage(
  error: unknown,
): error is { data: { message: string | string[] } } {
  return (
    typeof error === 'object' &&
    error != null &&
    'data' in error &&
    !!(error.data as Record<string, string>).message
  );
}

export function isWithError(error: unknown): error is { error: Record<string, string> } {
  return typeof error === 'object' && error != null && 'error' in error;
}

export function makeToastNotification(
  e: unknown,
  status: INotification['status'] = 'negative',
): INotification {
  const error = isWithError(e) ? e.error : {};
  return {
    message: error.error || getErrorMessages(error),
    status: status,
    type: NotificationType.toasts,
  };
}

export function makeSuccessToastNotification(text: string): INotification {
  return {
    message: text,
    status: 'positive',
    type: NotificationType.toasts,
  };
}

export function makeGenericToastNotification(text: string): INotification {
  return {
    message: text,
    status: 'general',
    type: NotificationType.toasts,
  };
}

export async function downloadFileInBrowser(
  response: Response,
  fileName = 'presentation.pdf',
) {
  if (!response.ok) {
    return;
  }

  const hiddenElement = document.createElement('a');
  const blobPDF = window.URL.createObjectURL(await response.blob());
  hiddenElement.href = blobPDF;
  hiddenElement.download = fileName;

  hiddenElement.click();
  hiddenElement.remove();
}
