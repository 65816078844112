import * as yup from 'yup';

import {
  ERROR_MSG,
  facebookRegexp,
  tiktokRegexp,
  instagramRegexp,
  twitterRegexp,
  linkedInRegexp,
} from 'constants/validation';
import { FIELDS } from './social.constants';

export const socialFormSchema = yup.object({
  [FIELDS.instagram.name]: yup
    .string()
    .strict(true)
    .trim(ERROR_MSG.trim)
    .max(100, ERROR_MSG.max(100))
    .matches(instagramRegexp, { message: ERROR_MSG.url, excludeEmptyString: true })
    .optional(),

  [FIELDS.facebook.name]: yup
    .string()
    .strict(true)
    .trim(ERROR_MSG.trim)
    .max(100, ERROR_MSG.max(100))
    .matches(facebookRegexp, { message: ERROR_MSG.url, excludeEmptyString: true })
    .optional(),
  [FIELDS.tiktok.name]: yup
    .string()
    .strict(true)
    .trim(ERROR_MSG.trim)
    .max(100, ERROR_MSG.max(100))
    .matches(tiktokRegexp, { message: ERROR_MSG.url, excludeEmptyString: true })
    .optional(),
  [FIELDS.twitter.name]: yup
    .string()
    .strict(true)
    .trim(ERROR_MSG.trim)
    .max(100, ERROR_MSG.max(100))
    .matches(twitterRegexp, { message: ERROR_MSG.url, excludeEmptyString: true })
    .optional(),
  [FIELDS.linkedIn.name]: yup
    .string()
    .strict(true)
    .trim(ERROR_MSG.trim)
    .max(100, ERROR_MSG.max(100))
    .matches(linkedInRegexp, { message: ERROR_MSG.url, excludeEmptyString: true })
    .optional(),
});
