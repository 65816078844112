import { CellSize, CellTextColor, CellTextWeight, ICell } from '@ezetech/swag-space-x';
import { LeadsStatusType } from 'interfaces/user-api.interface';

export const PAYMENT_METHODS = {
  CREDIT_CARD: 'creditCard',
  ACH: 'ACH',
  NET_15: 'NET_15',
  NET_30: 'NET_30',
  NET_45: 'NET_45',
  NET_60: 'NET_60',
};

export const PAYMENT_METHODS_LABELS = {
  [PAYMENT_METHODS.CREDIT_CARD]: 'Credit Card',
  [PAYMENT_METHODS.ACH]: 'ACH',
  [PAYMENT_METHODS.NET_15]: 'Net 15',
  [PAYMENT_METHODS.NET_30]: 'Net 30',
  [PAYMENT_METHODS.NET_45]: 'Net 45',
  [PAYMENT_METHODS.NET_60]: 'Net 60',
};

export const PAYMENT_METHODS_FREQUENCY = {
  ONE_TIME: 'ONE_TIME',
  INFINITE: 'INFINITE',
};

export const PAYMENT_METHODS_FREQUENCY_LABELS = {
  [PAYMENT_METHODS_FREQUENCY.ONE_TIME]: 'One time use',
  [PAYMENT_METHODS_FREQUENCY.INFINITE]: 'On going',
};

export enum TAX_METHODS {
  TAX_CHANGE = 'TAX_CHANGE',
  TAX_EXEMPT = 'TAX_EXEMPT',
}

export const TAX_METHODS_LABELS = {
  [TAX_METHODS.TAX_CHANGE]: 'Charge Tax',
  [TAX_METHODS.TAX_EXEMPT]: 'Tax Exempt',
};

export const ORDER_STATUS = {
  PENDING: 'pending',
  COMPLETED: 'completed',
};

export enum OrderFields {
  number = 'number',
  products = 'products',
  status = 'status',
  paidAt = 'paidAt',
  total = 'total',
}

export enum CustomerActions {
  redirect = 'redirect',
}

export const CUSTOMER_TYPES: Record<string, string> = {
  bulk: 'Bulk',
  inventory: 'Inventory',
  inventoryBulk: 'Inventory & Bulk',
};

export const ORDERS_TABLE_HEADER = [
  { text: 'Order no', id: OrderFields.number, size: CellSize.unset },
  { text: 'Products', id: OrderFields.products, size: CellSize.unset },
  { text: 'Status', id: OrderFields.status, size: CellSize.unset },
  { text: 'Order date', id: OrderFields.paidAt, size: CellSize.unset },
  { text: 'Amount', id: OrderFields.total, size: CellSize.unset },
];
export const ORDERS_TABLE_ROW = {
  [OrderFields.number]: {
    size: CellSize.unset,
    textWeight: CellTextWeight.bold,
    color: CellTextColor.pink,
    onClick: CustomerActions.redirect,
  },
  [OrderFields.products]: {
    size: CellSize.unset,
    color: CellTextColor.primary,
  },
  [OrderFields.status]: {
    size: CellSize.unset,
    color: CellTextColor.primary,
  },
  [OrderFields.paidAt]: {
    size: CellSize.unset,
    color: CellTextColor.primary,
  },
  [OrderFields.total]: {
    size: CellSize.unset,
    color: CellTextColor.primary,
  },
};

export interface IUserAddress {
  address: string;
  city: string;
  state: string | null;
  zip: string;
  country: string;
}

export interface IUserCompany {
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
}

export interface IUserProfile {
  id: string;
  role: string;
  type: string;
  email: string;
  phone?: string;
  lastName: string;
  firstName: string;
  orderedOn: string;
  companyName: string;
  paymentMethod: string | null;
  paymentMethodFrequency: string | null;
  company: IUserCompany;
  address: IUserAddress;
  hasInventory?: boolean;
  isSpecificReviewMockupsByCustomerAllowed: boolean | null;
  isSpecificReviewPantonesByCustomerAllowed: boolean | null;
  tax: TAX_METHODS;
  isTaxExemptPending: boolean;
}

export interface ICustomerStatistic {
  avgOrderValue: number;
  lastOrderAt: string | null;
  totalSpent: string;
  totalOrders: string;
}

export interface ICustomerOrder {
  id: string;
  total: string;
  paidAt: string;
  status: string;
  number: string;
  isSpecificReviewMockupsByCustomerAllowed: boolean | null;
  isSpecificReviewPantonesByCustomerAllowed: boolean | null;
  products: {
    name: string;
    total: number;
    quantity: number;
    status: string;
    statusCode: string;
    num: number;
    letterIndicator: string;
    id: string;
    tax: number;
  }[];
}

export interface ICustomerDetails {
  orders: ICustomerOrder[];
  user: IUserProfile | null;
  statistic: ICustomerStatistic;
  notes: string[];
}

export interface IUserAndNotesDetails {
  user: ICustomerDetails['user'];
  notes: ICustomerDetails['notes'];
}

export interface ICustomerOrdersWithStatistic {
  statistic: ICustomerDetails['statistic'];
  orders: ICustomerDetails['orders'];
}

export interface ITransformedCell extends Omit<ICell, 'onClick'> {
  value: string | ICustomerOrder['products'];
  onClick?: CustomerActions;
  orderId?: string;
  itemId?: string;
  isReviewPantonesByCustomerAllowed?: boolean;
  isReviewMockupsByCustomerAllowed?: boolean;
}

export interface IUserErrors {
  firstName?: string;
  lastName?: string;
  email?: string;
  companyName?: string;
  phone?: string;
}

export interface ICompanyErrors {
  name?: string;
}

export interface IAddressErrors {
  address?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
}

//example of interface
export interface IUpdateUser {
  id: string;
  email: string;
  phone: string | null;
  lastName: string;
  firstName: string;
  companyName: string;
  address: string;
  city: string;
  state: string | null;
  zip: string;
  country: string;
  isSpecificReviewMockupsByCustomerAllowed?: boolean | null;
  isSpecificReviewPantonesByCustomerAllowed?: boolean | null;
  tax: string;
}

export interface IUpdateUserPayment {
  id: string;
  paymentMethod?: string;
  paymentMethodFrequency?: string;
  setPaymentMethod?: React.Dispatch<React.SetStateAction<string>>;
}
export interface IUpdateLeadStatus {
  id: string;
  status: LeadsStatusType;
}
