import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IResellerCertificatesResponse,
  IResellerCertificateState,
} from 'interfaces/reseller-certificate.interfaces';
import { DEFAULT_PAGE } from 'constants/common';

const initialState: IResellerCertificateState = {
  list: [],
  total: 0,
  perPage: 20,
  page: DEFAULT_PAGE,
  isLoading: false,
};

export const ResellerCertificatesSlice = createSlice({
  initialState,
  name: 'resellerCertificatesSlice',
  reducers: {
    setCertificates: (state, action: PayloadAction<IResellerCertificatesResponse>) => {
      state.list = action.payload?.list || [];
      state.total = action.payload?.total || 0;
      state.perPage = action.payload?.perPage || 0;
      state.page = action.payload?.page || 0;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

export const resellerCertificatesReducer = ResellerCertificatesSlice.reducer;
export const { setCertificates, setLoading } = ResellerCertificatesSlice.actions;
