import { Typography } from '@ezetech/swag-space-x';

import COLORS from 'constants/styles/colors-js.constant.module.scss';

import css from '../../order-card-item.module.scss';

export const BoxSampleComponent = (): JSX.Element => (
  <div className={css.boxSample}>
    <Typography
      lineHeight="160%"
      fontType="bodyMd"
      color={COLORS.colorSecondaryText}
      className={css.label}
    >
      Box sample
    </Typography>
    <Typography
      lineHeight="160%"
      fontType="bodyMd"
      color={COLORS.colorSecondaryText}
      className={css.label}
    >
      Not including box
    </Typography>
  </div>
);
