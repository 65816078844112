import {
  Dialog,
  DialogContentWrapper,
  DialogTitle,
  DialogFooter,
  SwagButton,
  Typography,
  DialogDescription,
  Tooltip,
} from '@ezetech/swag-space-x';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { closePopup } from 'redux/slices/modals.slice';
import { selectedCartProductsSelector } from 'redux/selectors/carts.selector';
import { CartProductsPreviewPopupProps } from 'interfaces/popup.interface';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { ProductListVariants } from 'components/products-list-tooltip';
import { selectedPresentationProductsSelector } from 'redux/selectors/presentations.selector';
import css from './cart-products-preview-popup.module.scss';

export const CartProductsPreviewPopup = ({
  cartId,
  presentationId,
  isOpen,
}: CartProductsPreviewPopupProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const products = presentationId
    ? useAppSelector(selectedPresentationProductsSelector(presentationId))
    : useAppSelector(selectedCartProductsSelector(cartId));
  const onButtonClick = () => {
    dispatch(closePopup());
  };

  return (
    <Dialog open={isOpen} onOpenChange={onButtonClick}>
      <DialogContentWrapper
        className={css.dialogContent}
        overlayClassName={css.dialogOverlay}
      >
        <DialogTitle>Products</DialogTitle>
        <DialogDescription className={css.body}>
          <div className={css.row}>
            {products.map(({ id, name, quantity: productQuantity, variants }, i) => (
              <div className={css.block} key={`${id}-${i}`}>
                <div className={css.column}>
                  <Typography
                    fontType="bodyMdBold"
                    lineHeight="143%"
                    color={COLORS.colorTypographyHeadings}
                    className={css.title}
                  >
                    {name}
                  </Typography>
                  {!!productQuantity && (
                    <Typography
                      fontType="bodySm"
                      lineHeight="143%"
                      color={COLORS.colorTypographyHeadings}
                    >
                      Quantity: {productQuantity}
                    </Typography>
                  )}
                </div>
                {variants.length > 0 && (
                  <Tooltip
                    content={
                      <Typography
                        fontType="bodySm"
                        lineHeight="143%"
                        color={COLORS.colorPink}
                        link
                      >
                        See breakdown
                      </Typography>
                    }
                  >
                    <ProductListVariants name={name} variants={variants} />
                  </Tooltip>
                )}
              </div>
            ))}
          </div>
        </DialogDescription>
        <DialogFooter className={css.footer}>
          <SwagButton type="primary" onClick={onButtonClick}>
            Close
          </SwagButton>
        </DialogFooter>
      </DialogContentWrapper>
    </Dialog>
  );
};
