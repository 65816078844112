type Path = string | string[];

export function deepGet<T = never>(
  data: Record<string, never>,
  path: Path,
  defaultValue?: T,
): T | undefined {
  const keys: string[] = Array.isArray(path) ? path : path.split('.');

  return keys.reduce((result, key) => {
    return (
      result &&
      ((typeof result === 'object' && key in result
        ? result[key]
        : defaultValue) as never)
    );
  }, data as never) as T;
}
