import { PopupComponentListProps } from 'interfaces';

import { DealComparisonPopup } from 'components/popups/deal-comparison/deal-comparison.popup';
import { WithdrawPopup } from '../withdraw/withdraw.popup';
import { EditCustomerPopup } from '../edit-customer/edit-customer.popup';
import { WithdrawSuccessPopup } from '../withdrawal-success/withdrawal-success.popup';
import { CreateCreditCardPopup } from '../create-credit-card/create-credit-card.popup';
import { CreateACartEmailPopup } from '../create-a-cart-email/create-a-cart-email.popup';
import { CreateACartTypePopup } from '../create-a-cart-type/create-a-cart-type.popup';
import { ResellerMockupApprovalPopup } from '../reseller-mockup-approval-popup/reseller-mockup-approval-popup';
import { ResellerMockupPreApprovalPopup } from '../reseller-mockup-pre-approval-popup/reseller-mockup-pre-approval-popup';
import { ResellerMockupApprovedPopup } from '../reseller-mockup-approved-popup/reseller-mockup-approved-popup';
import { ResellerMockupApprovalRevisionsRequestedPopup } from '../reseller-mockup-approval-revisions-requested-popup/reseller-mockup-approval-revisions-requested-popup';
import { ReviewColorsPopup } from '../review-colors/review-colors.popup';
import { ReviewColorsSuccessPopup } from '../review-colors-success/review-colors-success.popup';
import { CartProductsPreviewPopup } from '../cart-products-preview-popup';
import { EditWhatWeOfferPagePopup } from '../edit-what-we-offer-page/edit-what-we-offer-page-popup';
import { EditHomePagePopup } from '../edit-home-page/edit-home-page-popup';
import { CartNotesPopup } from '../cart-notes/cart-notes.popup';
import { HideProductsPopup } from '../hide-products-popup';
import { ChangePaymentMethodInformationPopup } from '../change-payment-method-information-popup/change-payment-method-information.popup';
import { BillingAddressPopup } from '../billing-address-popup';
import { InvoiceBankDetailsPopup } from '../invoice-bank-details-popup';
import { CreateAPresentationEmailPopup } from '../create-a-presentation-email/create-a-presentation-email.popup';
import { CreateInvoiceEmailPopup } from '../create-invoice-email-popup.tsx/create-a-invoice-email.popup';
import { InvoiceCreationInformationPopup } from '../invoice-creation-information-popup/invoice-creation-information-popup';
import { InvoiceCreationBankDetails } from '../invoice-creation-bank-details-popup/invoice-creation-bank-details-popup';
import { InvoiceCreationBillingAddressPopup } from '../invoice-creation-billing-address/invoice-creation-billing-address-popup';
import { InvoiceCreationCustomerInfoPopup } from '../invoice-creation-customer-info-popup/invoice-creation-customer-info-popup';
import { InvoiceCreationReadyPopup } from '../invoice-creation-ready-popup/invoice-creation-ready-popup';
import { EditPDFPresentationLayoutPopup } from '../edit-pdf-presentation-layout-popup/edit-pdf-presentation-layout-popup';
import { SendAnInvoicePopup } from '../send-an-invoice-popup';
import { CustomerInventorySignContractPopup } from '../customer-inventory-sign-contract-popup/customer-inventory-sign-contract.popup';
import { SavePdfPresentationChangesConfirmationPopup } from '../save-pdf-presentation-changes-confrimation-popup';
import { ConnectDomainPopup } from '../connect-domain/connect-domain.popup';
import { DNSSettingsPopup } from '../dns-settings/dns-settings.popup';
import { VerifyConnectionFinished } from '../verify-connection-successed/verify-connection-finished.popup';
import { EditDomainPopup } from '../edit-domain/edit-domain.popup';
import { ConfirmationPopup } from '../confirmation-popup/confirmation.popup';
import { UploadResellerCertificatePopup } from '../upload-reseller-certificate-popup/upload-reseller-certificate-popup';
import { CopyACartLinkPopup } from '../copy-a-cart-link-popup/';
import { proofOfTaxExemptUploadPopup } from '../proof-of-tax-exempt-upload-popup/proof-of-tax-exempt-upload-popup';

export const WITHDRAW_POPUP = 'WITHDRAW_POPUP';
export const EDIT_CUSTOMER_POPUP = 'EDIT_CUSTOMER_POPUP';
export const WITHDRAW_SUCCESS_POPUP = 'WITHDRAW_SUCCESS_POPUP';
export const CREATE_CREDIT_CARD_POPUP = 'CREATE_CREDIT_CARD_POPUP';
export const RESELLER_MOCKUP_APPROVAL = 'RESELLER_MOCKUP_APPROVAL';
export const RESELLER_MOCKUP_PRE_APPROVAL = 'RESELLER_MOCKUP_PRE_APPROVAL';
export const RESELLER_MOCKUP_APPROVAL_REVISIONS_REQUESTED =
  'RESELLER_MOCKUP_APPROVAL_REVISIONS_REQUESTED';
export const RESELLER_MOCKUP_APPROVED = 'RESELLER_MOCKUP_APPROVED';
export const REVIEW_COLORS_POPUP = 'REVIEW_COLORS_POPUP';
export const REVIEW_COLORS_SUCCESS_POPUP = 'REVIEW_COLORS_SUCCESS_POPUP';
export const CREATE_A_CART_EMAIL_POPUP = 'CREATE_A_CART_EMAIL_POPUP';
export const CREATE_A_CART_TYPE_POPUP = 'CREATE_A_CART_TYPE_POPUP';

export const CART_PRODUCTS_PREVIEW_POPUP = 'CART_PRODUCTS_PREVIEW_POPUP';
export const EDIT_WHAT_WE_OFFER_PAGE = 'EDIT_WHAT_WE_OFFER_PAGE';
export const EDIT_HOME_PAGE = 'EDIT_HOME_PAGE';
export const CART_NOTES_POPUP = 'CART_NOTES_POPUP';
export const HIDE_PRODUCTS_POPUP = 'HIDE_PRODUCTS_POPUP';
export const CHANGE_PAYMENT_METHOD_INFORMATION_POPUP =
  'CHANGE_PAYMENT_METHOD_INFORMATION_POPUP';
export const BILLING_ADDRESS_POPUP = 'BILLING_ADDRESS_POPUP';
export const INVOICE_BANK_DETAILS_POPUP = 'INVOICE_BANK_DETAILS_POPUP';
export const CREATE_A_PRESENTATION_EMAIL_POPUP = 'CREATE_A_PRESENTATION_EMAIL_POPUP';
export const CREATE_INVOICE_EMAIL_POPUP = 'CREATE_INVOICE_EMAIL_POPUP';
export const INVOICE_ACH_NET_TERMS_INFORMATION_POPUP =
  'INVOICE_ACH_NET_TERMS_INFORMATION_POPUP';
export const INVOICE_CREATION_BANK_DETAILS = 'INVOICE_CREATION_BANK_DETAILS';
export const INVOICE_CREATION_BILLING_ADDRESS_POPUP =
  'INVOICE_CREATION_BILLING_ADDRESS_POPUP';
export const INVOICE_CREATION_CUSTOMER_INFO_POPUP =
  'INVOICE_CREATION_CUSTOMER_INFO_POPUP';
export const INVOICE_CREATION_READY_POPUP = 'INVOICE_CREATION_READY_POPUP';
export const EDIT_PDF_PRESENTATION_LAYOUT_POPUP = 'EDIT_PDF_PRESENTATION_LAYOUT_POPUP';
export const SEND_AN_INVOICE_POPUP = 'SEND_AN_INVOICE_POPUP';
export const CUSTOMER_INVENTORY_SIGN_CONTRACT_POPUP =
  'CUSTOMER_INVENTORY_SIGN_CONTRACT_POPUP';
export const SAVE_PDF_PRESENTATION_CHANGES_CONFIRMATION_POPUP =
  'SAVE_PDF_PRESENTATION_CHANGES_CONFIRMATION_POPUP';
export const CONNECT_DOMAIN_POPUP = 'CONNECT_DOMAIN_POPUP';
export const DNS_SETTINGS_POPUP = 'DNS_SETTINGS_POPUP';
export const VERIFY_CONNECTION_FINISHED = 'VERIFY_CONNECTION_FINISHED';
export const EDIT_DOMAIN_POPUP = 'EDIT_DOMAIN_POPUP';
export const RESELLER_CERTIFICATE_UPLOAD_POPUP = 'RESELLER_CERTIFICATE_UPLOAD_POPUP';
export const PROOF_OF_TAX_EXEMPT_UPLOAD_POPUP = 'PROOF_OF_TAX_EXEMPT_UPLOAD_POPUP';

export const CONFIRMATION_POPUP = 'CONFIRMATION_POPUP';

export const COPY_CART_LINK_POPUP = 'COPY_CART_LINK_POPUP';
export const DEAL_COMPARISON_POPUP = 'DEAL_COMPARISON_POPUP';

export const POPUP_LIST_NAMES = {
  [WITHDRAW_POPUP]: 'WITHDRAW_POPUP',
  [EDIT_CUSTOMER_POPUP]: 'EDIT_CUSTOMER_POPUP',
  [WITHDRAW_SUCCESS_POPUP]: 'WITHDRAW_SUCCESS_POPUP',
  [CREATE_CREDIT_CARD_POPUP]: 'CREATE_CREDIT_CARD_POPUP',
  [RESELLER_MOCKUP_APPROVAL]: 'RESELLER_MOCKUP_APPROVAL',
  [RESELLER_MOCKUP_PRE_APPROVAL]: 'RESELLER_MOCKUP_PRE_APPROVAL',
  [RESELLER_MOCKUP_APPROVAL_REVISIONS_REQUESTED]:
    'RESELLER_MOCKUP_APPROVAL_REVISIONS_REQUESTED',
  [RESELLER_MOCKUP_APPROVED]: 'RESELLER_MOCKUP_APPROVED',
  [REVIEW_COLORS_SUCCESS_POPUP]: 'REVIEW_COLORS_SUCCESS_POPUP',
  [REVIEW_COLORS_POPUP]: 'REVIEW_COLORS_POPUP',
  [CREATE_A_CART_EMAIL_POPUP]: 'CREATE_A_CART_EMAIL_POPUP',
  [CREATE_A_CART_TYPE_POPUP]: 'CREATE_A_CART_TYPE_POPUP',
  [CART_PRODUCTS_PREVIEW_POPUP]: 'CART_PRODUCTS_PREVIEW_POPUP',
  [EDIT_WHAT_WE_OFFER_PAGE]: 'EDIT_WHAT_WE_OFFER_PAGE',
  [EDIT_HOME_PAGE]: 'EDIT_HOME_PAGE',
  [CART_NOTES_POPUP]: 'CART_NOTES_POPUP',
  [HIDE_PRODUCTS_POPUP]: 'HIDE_PRODUCTS_POPUP',
  [CHANGE_PAYMENT_METHOD_INFORMATION_POPUP]: 'CHANGE_PAYMENT_METHOD_INFORMATION_POPUP',
  [BILLING_ADDRESS_POPUP]: 'BILLING_ADDRESS_POPUP',
  [INVOICE_BANK_DETAILS_POPUP]: 'INVOICE_BANK_DETAILS_POPUP',
  [CREATE_A_PRESENTATION_EMAIL_POPUP]: 'CREATE_A_PRESENTATION_EMAIL_POPUP',
  [CREATE_INVOICE_EMAIL_POPUP]: 'CREATE_INVOICE_EMAIL_POPUP',
  [INVOICE_ACH_NET_TERMS_INFORMATION_POPUP]: 'INVOICE_ACH_NET_TERMS_INFORMATION_POPUP',
  [INVOICE_CREATION_BANK_DETAILS]: 'INVOICE_CREATION_BANK_DETAILS',
  [INVOICE_CREATION_BILLING_ADDRESS_POPUP]: 'INVOICE_CREATION_BILLING_ADDRESS_POPUP',
  [INVOICE_CREATION_CUSTOMER_INFO_POPUP]: 'INVOICE_CREATION_CUSTOMER_INFO_POPUP',
  [INVOICE_CREATION_READY_POPUP]: 'INVOICE_CREATION_READY_POPUP',
  [EDIT_PDF_PRESENTATION_LAYOUT_POPUP]: 'EDIT_PDF_PRESENTATION_LAYOUT_POPUP',
  [SEND_AN_INVOICE_POPUP]: 'SEND_AN_INVOICE_POPUP',
  [CUSTOMER_INVENTORY_SIGN_CONTRACT_POPUP]: 'CUSTOMER_INVENTORY_SIGN_CONTRACT_POPUP',
  [SAVE_PDF_PRESENTATION_CHANGES_CONFIRMATION_POPUP]:
    'SAVE_PDF_PRESENTATION_CHANGES_CONFIRMATION_POPUP',
  [CONNECT_DOMAIN_POPUP]: 'CONNECT_DOMAIN_POPUP',
  [DNS_SETTINGS_POPUP]: 'DNS_SETTINGS_POPUP',
  [VERIFY_CONNECTION_FINISHED]: 'VERIFY_CONNECTION_FINISHED',
  [EDIT_DOMAIN_POPUP]: 'EDIT_DOMAIN_POPUP',
  [CONFIRMATION_POPUP]: 'CONFIRMATION_POPUP',
  [RESELLER_CERTIFICATE_UPLOAD_POPUP]: 'RESELLER_CERTIFICATE_UPLOAD_POPUP',
  [COPY_CART_LINK_POPUP]: 'COPY_CART_LINK_POPUP',
  [PROOF_OF_TAX_EXEMPT_UPLOAD_POPUP]: 'PROOF_OF_TAX_EXEMPT_UPLOAD_POPUP',
  DEAL_COMPARISON_POPUP,
};

export const POPUPS_LIST: PopupComponentListProps = {
  [WITHDRAW_POPUP]: WithdrawPopup,
  [EDIT_CUSTOMER_POPUP]: EditCustomerPopup,
  [WITHDRAW_SUCCESS_POPUP]: WithdrawSuccessPopup,
  [CREATE_CREDIT_CARD_POPUP]: CreateCreditCardPopup,
  [RESELLER_MOCKUP_PRE_APPROVAL]: ResellerMockupPreApprovalPopup,
  [RESELLER_MOCKUP_APPROVAL]: ResellerMockupApprovalPopup,
  [RESELLER_MOCKUP_APPROVED]: ResellerMockupApprovedPopup,
  [RESELLER_MOCKUP_APPROVAL_REVISIONS_REQUESTED]:
    ResellerMockupApprovalRevisionsRequestedPopup,
  [REVIEW_COLORS_SUCCESS_POPUP]: ReviewColorsSuccessPopup,
  [REVIEW_COLORS_POPUP]: ReviewColorsPopup,
  [CREATE_A_CART_EMAIL_POPUP]: CreateACartEmailPopup,
  [CREATE_A_CART_TYPE_POPUP]: CreateACartTypePopup,
  [CART_PRODUCTS_PREVIEW_POPUP]: CartProductsPreviewPopup,
  [EDIT_WHAT_WE_OFFER_PAGE]: EditWhatWeOfferPagePopup,
  [EDIT_HOME_PAGE]: EditHomePagePopup,
  [CART_NOTES_POPUP]: CartNotesPopup,
  [HIDE_PRODUCTS_POPUP]: HideProductsPopup,
  [CHANGE_PAYMENT_METHOD_INFORMATION_POPUP]: ChangePaymentMethodInformationPopup,
  [BILLING_ADDRESS_POPUP]: BillingAddressPopup,
  [INVOICE_BANK_DETAILS_POPUP]: InvoiceBankDetailsPopup,
  [CREATE_A_PRESENTATION_EMAIL_POPUP]: CreateAPresentationEmailPopup,
  [CREATE_INVOICE_EMAIL_POPUP]: CreateInvoiceEmailPopup,
  [INVOICE_ACH_NET_TERMS_INFORMATION_POPUP]: InvoiceCreationInformationPopup,
  [INVOICE_CREATION_BANK_DETAILS]: InvoiceCreationBankDetails,
  [INVOICE_CREATION_BILLING_ADDRESS_POPUP]: InvoiceCreationBillingAddressPopup,
  [INVOICE_CREATION_CUSTOMER_INFO_POPUP]: InvoiceCreationCustomerInfoPopup,
  [INVOICE_CREATION_READY_POPUP]: InvoiceCreationReadyPopup,
  [EDIT_PDF_PRESENTATION_LAYOUT_POPUP]: EditPDFPresentationLayoutPopup,
  [SEND_AN_INVOICE_POPUP]: SendAnInvoicePopup,
  [CUSTOMER_INVENTORY_SIGN_CONTRACT_POPUP]: CustomerInventorySignContractPopup,
  [SAVE_PDF_PRESENTATION_CHANGES_CONFIRMATION_POPUP]:
    SavePdfPresentationChangesConfirmationPopup,
  [CONNECT_DOMAIN_POPUP]: ConnectDomainPopup,
  [DNS_SETTINGS_POPUP]: DNSSettingsPopup,
  [VERIFY_CONNECTION_FINISHED]: VerifyConnectionFinished,
  [EDIT_DOMAIN_POPUP]: EditDomainPopup,
  [CONFIRMATION_POPUP]: ConfirmationPopup,
  [RESELLER_CERTIFICATE_UPLOAD_POPUP]: UploadResellerCertificatePopup,
  [COPY_CART_LINK_POPUP]: CopyACartLinkPopup,
  [PROOF_OF_TAX_EXEMPT_UPLOAD_POPUP]: proofOfTaxExemptUploadPopup,
  [DEAL_COMPARISON_POPUP]: DealComparisonPopup,
};
