import { useAppSelector } from 'redux/store';
import { useGetOrdersMutation } from 'redux/api/order.api';
import { customerProfileDetailsSelector } from 'redux/selectors/customer-details.selector';
import { filtersRequestSelector } from 'redux/selectors/filters.selectors';
import { IUserProfile } from 'interfaces/customer-details.interface';

export const useRefetchCustomerOrders = () => {
  const [getOrders] = useGetOrdersMutation();
  const user: IUserProfile | null = useAppSelector(customerProfileDetailsSelector);
  const filters = useAppSelector(filtersRequestSelector);

  return () => {
    getOrders({ userId: user?.id || '', ...filters });
  };
};
