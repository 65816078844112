import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { TabsNavigation } from '@ezetech/swag-space-x';
import { canRoute } from 'boot/ability';
import { myOrdersCountSelector } from 'redux/selectors/carts.selector';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { ROUTES } from 'constants/router';
import { resetFilters } from 'redux/slices/filter.slice';
import { PageContent } from 'components/page-content';

import css from './sales-tools-layout.module.scss';

export function SalesToolsLayout(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const pathname = location.pathname.split('/').reverse()[0];

  const myOrdersCounts = useAppSelector(myOrdersCountSelector);

  const SALES_TOOLS_TABS = [
    {
      id: 'invoice-generation',
      name: `Invoices (${myOrdersCounts?.invoices ?? 0})`,
    },
    {
      id: 'pdf-presentation',
      name: `PDF Presentation (${myOrdersCounts?.presentations ?? 0})`,
    },
    {
      id: 'build-a-cart',
      name: `Build a Cart (${myOrdersCounts?.carts ?? 0})`,
    },
  ];

  const TABS_ID_ROUTES_MAP: Record<string, string> = {
    'invoice-generation': ROUTES.INVOICE_GENERATION,
    'pdf-presentation': ROUTES.PDF_PRESENTATION,
    'build-a-cart': ROUTES.BUILD_A_CART,
  };

  const handleChangeTab = (tab: string) => {
    dispatch(resetFilters());
    const route = Object.values(ROUTES).find((r) => r.endsWith(tab))!;
    navigate(route);
  };

  return (
    <PageContent>
      <TabsNavigation
        selectedTab={pathname}
        onChangeTab={handleChangeTab}
        tabs={SALES_TOOLS_TABS.filter((item) => {
          const route = TABS_ID_ROUTES_MAP[item.id];
          const result = canRoute(route);
          return result.isAllowed && !result.redirect;
        })}
      />
      <div className={css.outletWrapper}>
        <div className={css.outletWrapperInner}>
          <Outlet />
        </div>
      </div>
    </PageContent>
  );
}
