import { CellSize, CellTextColor } from '@ezetech/swag-space-x';
import {
  CustomerCompanyStatisticFields,
  ICustomerCompanyStatisticLabel,
  UsersFields,
  OrderFields,
} from 'interfaces/customer-company.interface';

export const customerCompanyStatisticLabels = [
  {
    id: CustomerCompanyStatisticFields.totalSpent,
    label: 'Amount spent',
  },
  { id: CustomerCompanyStatisticFields.totalOrders, label: 'Total orders' },
  { id: CustomerCompanyStatisticFields.avgOrderValue, label: 'Avg order value' },
  { id: CustomerCompanyStatisticFields.lastOrderAt, label: 'Last order' },
];

export const customerCompanyStatisticLabelsMobile = customerCompanyStatisticLabels.reduce<
  ICustomerCompanyStatisticLabel[][]
>((acc, value, index) => {
  if ((index + 1) % 2) {
    acc.push([value]);
  } else if (acc[acc.length - 1]) {
    acc[acc.length - 1].push(value);
  }
  return acc;
}, []);

export const ORDERS_COUNT_PER_PAGE = 10;
export const ORDERS_ITEM_HEIGHT = 67;
export const USERS_COUNT_PER_PAGE = 10;
export const USERS_ITEM_HEIGHT = 67;

export const USERS_TABLE_HEADER = [
  { text: 'Name', id: UsersFields.userName, size: CellSize.medium },
  {
    text: 'Total orders',
    id: UsersFields.orders,
    size: CellSize.medium,
  },
  { text: 'Last order', id: UsersFields.lastOrderAt, size: CellSize.medium },
  {
    text: 'Amount spent',
    id: UsersFields.totalSpent,
    size: CellSize.medium,
  },
];

export const USERS_TABLE_ROW = {
  [UsersFields.userName]: {
    size: CellSize.medium,
    textColor: CellTextColor.pink,
    actionable: true,
  },
  [UsersFields.orders]: {
    size: CellSize.medium,
  },
  [UsersFields.totalSpent]: {
    size: CellSize.medium,
  },
  [UsersFields.lastOrderAt]: {
    size: CellSize.medium,
  },
};
export const CUSTOMER_TYPES: Record<string, string> = {
  bulk: 'Bulk',
  inventory: 'Inventory',
  inventoryBulk: 'Inventory & Bulk',
};

export const ORDERS_TABLE_HEADER = [
  { text: 'Order no', id: OrderFields.number, size: CellSize.unset },
  { text: 'Products', id: OrderFields.products, size: CellSize.unset },
  { text: 'Status', id: OrderFields.status, size: CellSize.unset },
  { text: 'Order date', id: OrderFields.paidAt, size: CellSize.unset },
  { text: 'Amount', id: OrderFields.total, size: CellSize.unset },
];

export const ORDERS_TABLE_ROW = {
  [OrderFields.number]: {
    size: CellSize.unset,
    textColor: CellTextColor.pink,
    actionable: true,
  },
  [OrderFields.products]: {
    size: CellSize.unset,
  },
  [OrderFields.status]: {
    size: CellSize.unset,
  },
  [OrderFields.paidAt]: {
    size: CellSize.unset,
  },
  [OrderFields.total]: {
    size: CellSize.unset,
  },
};
