import { ChangeEvent } from 'react';
import { SwagButton, Typography, Divider, Link, TextField } from '@ezetech/swag-space-x';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';

import COLORS from 'constants/styles/colors-js.constant.module.scss';
import downloadSvg from 'assets/svg/download.svg';
import { ResellerMockupApprovalPopupProps } from 'interfaces/popup.interface';

import css from './reseller-mockup-approval-popup.module.scss';
import { POPUP_MODES } from './reseller-mockup-approval-popup.utils';
import { ProductionMockupViewer } from './production-mockup-viewer';
import { PaginationBlock } from './pagination-block';

export const ContentView = ({
  page,
  mode,
  isPDF,
  setMode,
  isMobile,
  lastPage,
  orderNum,
  delayTime,
  placeholder,
  setLastPage,
  requestText,
  productName,
  handlePageClick,
  textRevisionRef,
  productionMockup,
  animationClasses,
  customerMockupPage,
  customPageIndicator,
  onRequestTextChange,
  onCustomerMockupPage,
  maxCustomerMockupPage,
  onTextRevisionMounted,
  maxCustomerMockupPageIndicator,
  isReviewMockupsByCustomerAllowed,
}: {
  isPDF: boolean;
  productionMockup: ResellerMockupApprovalPopupProps['productionMockup'];
  productName: string;
  orderNum: string;
  mode: string;
  isMobile: boolean;
  textRevisionRef: React.RefObject<HTMLDivElement>;
  requestText: string;
  animationClasses: Record<string, string>;
  delayTime: number;
  placeholder: string;
  page: number;
  setMode: (mode: string) => void;
  onTextRevisionMounted: () => void;
  onRequestTextChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  setLastPage: (page: number) => void;
  onCustomerMockupPage: (page: number) => void;
  maxCustomerMockupPage: number;
  maxCustomerMockupPageIndicator: string;
  customerMockupPage: number;
  lastPage: number;
  customPageIndicator: string;
  isReviewMockupsByCustomerAllowed: boolean;
  handlePageClick: (page: number) => void;
}) => {
  const shouldRenderPMPagination = isMobile && isPDF;

  return (
    <div
      className={classNames(css.contentWrapper, {
        [css.contentWrapperWithoutPMPagination]: !shouldRenderPMPagination,
      })}
    >
      <div className={css.mockupImg}>
        <ProductionMockupViewer
          url={productionMockup?.fileName || ''}
          page={page}
          isPDF={isPDF}
          setLastPage={setLastPage}
          imgClassName={css.imgClassName}
          pagePreviewClassName={css.pagePreviewClassName}
        />
      </div>

      {shouldRenderPMPagination && (
        <PaginationBlock
          hidePMPagination={false}
          hideCMPagination
          isCompareMode={false}
          customerMockupPage={customerMockupPage}
          onCustomerMockupPage={onCustomerMockupPage}
          maxCustomerMockupPage={maxCustomerMockupPage}
          maxCustomerMockupPageIndicator={maxCustomerMockupPageIndicator}
          lastPage={lastPage}
          customPageIndicator={customPageIndicator as string}
          handlePageClick={handlePageClick}
          page={page}
          wrapperClassName={css.paginationBlockContentView}
        />
      )}
      <div className={css.text}>
        <Typography color={COLORS.colorTextSubdued} fontType="bodyMd" lineHeight="162.5%">
          Review mockup for the {productName} in order number #
          <Typography color={COLORS.colorPink} fontType="bodyMd" lineHeight="162.5%">
            {orderNum}
          </Typography>
          .
          <br />
          {isReviewMockupsByCustomerAllowed
            ? `Once you approve this Mockup, we will send this to your customer to approve
          before we print.`
            : 'As per their settings, your customer will not be asked to approve their Mockup. This is the final approval step before production.'}
        </Typography>
      </div>
      <Divider className={css.textDivider} />
      <div className={css.pagination}>
        <div className={css.mockupImgPreview}>
          <ProductionMockupViewer
            url={productionMockup?.fileName || ''}
            page={page}
            isPDF={isPDF}
            setLastPage={setLastPage}
            imgClassName={css.imgClassName}
            pagePreviewClassName={css.smallPagePreviewClassName}
            productionMockupViewerClassName={css.productionMockupViewerClassName}
            loaderClassName={css.loaderClassName}
          />
        </div>
        <Typography
          className={css.paginationText}
          color={COLORS.colorTextSubdued}
          fontType="bodyMd"
          lineHeight="162.5%"
        >
          Review Mockup for:
        </Typography>

        <Typography
          className={css.paginationValue}
          color={COLORS.colorPrimaryText}
          fontType="bodyMdBold"
          lineHeight="162.5%"
        >
          {productName}
        </Typography>
      </div>
      <div className={css.buttons}>
        <Link to={productionMockup?.fileName || ''} target="_blank">
          <SwagButton type="outlined" size="large" className={css.downloadBtn}>
            <img src={downloadSvg} alt="download mockup" />
            <span>Download Mockup</span>
          </SwagButton>
        </Link>
      </div>
      <CSSTransition
        in={mode === POPUP_MODES.REVISIONS}
        timeout={delayTime}
        classNames={animationClasses}
        onEntered={onTextRevisionMounted}
        unmountOnExit
      >
        <div className={css.revisionWrapper} ref={textRevisionRef}>
          <Typography
            color={COLORS.colorPrimaryText}
            fontType="bodySm"
            lineHeight="142.857%"
          >
            Write your revisions
          </Typography>

          <TextField
            textAreaWrapperClassName={css.inputWrapperClassName}
            textAreaClassName={css.textArea}
            onChange={onRequestTextChange}
            value={requestText}
            placeholder={placeholder}
          ></TextField>
        </div>
      </CSSTransition>
    </div>
  );
};
