import React from 'react';
import { Toggle, TogglePlacement } from '@ezetech/swag-space-x';
import css from './page-visibility-toggle.module.scss';

interface IProps {
  label: string;
  name: string;
  value: boolean;
  onChange(): void;
}

export function PageVisibilityToggle({
  name,
  label,
  value,
  onChange,
}: IProps): React.ReactElement {
  return (
    <Toggle
      dataCheckedValue={String(value)}
      className={css.toggle}
      labelClassName={css.label}
      checkedClassName={css.checked}
      placement={TogglePlacement.right}
      name={name}
      label={label}
      value={value}
      onChange={onChange}
    />
  );
}
