import { useRef } from 'react';
import cn from 'classnames';
import { AnnouncementBar, AnnouncementType, SpaceFooter } from '@ezetech/swag-space-x';

import { Outlet, useNavigate } from 'react-router-dom';

import { LANDING_URL } from 'utils/urls.util';

import { ROUTES } from 'constants/router';
import { isOwner } from 'boot/ability';

import { Header } from '../header';
import { NavBar } from '../nav-bar';
import { IntercomChat } from '../intercom-chat';
import { useScrollTop } from '../../hooks/use-scroll-top.hook';
import { useAppSelector } from '../../redux/store';
import { postsSelector } from '../../redux/selectors/blog.selectors';
import { resellerSignContractSelector } from '../../redux/selectors/store-creation.selectors';
import { useIsMobileView } from '../../hooks/is-mobile-view.hook';
import css from './page-layout.module.scss';

export function PageLayout(): JSX.Element {
  const scrollRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const blogPosts = useAppSelector(postsSelector);
  const isContractSigned = useAppSelector(resellerSignContractSelector);
  const isMobile = useIsMobileView(768);
  const showActivateButton = isOwner();
  const leftTextContent =
    isMobile && showActivateButton ? (
      <>
        Please sign contract to
        <br />
        make your site active
      </>
    ) : (
      'Your site is not active'
    );
  const leftTextProps = isMobile
    ? {
        lineHeight: '125%',
      }
    : {};
  const buttonText = isMobile ? 'View' : 'View Contract';

  useScrollTop(scrollRef);
  const showActivateBar = !isContractSigned;
  return (
    <div className={css.pageWrapper}>
      <Header />
      {showActivateBar && (
        <AnnouncementBar
          textLeft={leftTextContent}
          className={css.contractBar}
          buttonLabel={showActivateButton ? buttonText : ''}
          type={AnnouncementType.info}
          textLeftProps={leftTextProps}
          textRight={showActivateButton ? 'Please sign to make active' : ''}
          buttonClassName={css.buttonContractBar}
          textRightClassName={css.contractBarRightText}
          containerInnerClassName={css.innerContractBar}
          onClick={() => {
            navigate(`${ROUTES.SIGN_AGREEMENT}?originUrl=${window.location.href}`);
          }}
        />
      )}
      <NavBar withContractBar={showActivateBar} />
      <main
        className={cn(css.contentWrapper, {
          [css.contentWrapperWithContractBar]: showActivateBar,
        })}
        ref={scrollRef}
      >
        <div className={css.mainContentWrapper}>
          <Outlet />
        </div>
        <SpaceFooter
          blogPosts={blogPosts}
          appBaseUrl={LANDING_URL}
          innerClassName={css.footerInner}
          outerClassName={css.footerOuter}
        />
      </main>
      <IntercomChat />
    </div>
  );
}
