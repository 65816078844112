export const MIN_PASSWORD_LENGTH = 8;
export const MAX_PASSWORD_LENGTH = 128;
export const ONE_LOWERCASE_CHARACTER = 'One lowercase character';
export const ONE_UPPERCASE_CHARACTER = 'One uppercase character';
export const ONE_NUMERIC_CHARACTER = 'One number';
export const ONE_SPECIAL_CHARACTER = 'One special character';
export const MIN_PASSWORD_LENGTH_CHARACTERS = `${MIN_PASSWORD_LENGTH} characters minimum`;
export const MAX_PASSWORD_LENGTH_CHARACTERS = `${MAX_PASSWORD_LENGTH} characters maximum`;
export const PASSWORD_REQUIREMENTS = [
  ONE_LOWERCASE_CHARACTER,
  ONE_UPPERCASE_CHARACTER,
  ONE_NUMERIC_CHARACTER,
  ONE_SPECIAL_CHARACTER,
  MIN_PASSWORD_LENGTH_CHARACTERS,
];

export const AT_LEAST_ONE_LOWERCASE_CHARACTER_REGEXP = /^(?=.*[a-z])/;
export const AT_LEAST_ONE_UPPERCASE_CHARACTER_REGEXP = /^(?=.*[A-Z])/;
export const AT_LEAST_ONE_NUMERIC_CHARACTER_REGEXP = /^(?=.*[0-9])/;
export const AT_LEAST_ONE_SPECIAL_CHARACTER_REGEXP =
  /^(?=.*[ !"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~])/;
export const ENTIRE_PASSWORD_REGEXP =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[ !"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~])/;
