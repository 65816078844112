import cn from 'classnames';
import { Typography } from '@ezetech/swag-space-x';

import { API_URL } from 'redux/api/helpers';
import COLORS from 'constants/styles/colors-js.constant.module.scss';

import css from './low-margin.module.scss';

export const LowMarginTextComponent = ({
  className,
  currentMargin,
  disabled,
  header,
}: {
  header?: string;
  className?: string;
  currentMargin: number;
  disabled: boolean;
}) => {
  const relativeMargin = currentMargin / 100;

  const url = disabled
    ? ''
    : `${API_URL}/company-settings/low-margin-csv/${relativeMargin}`;
  const target = disabled ? '_self' : '_blank';

  return (
    <Typography
      fontType="bodyMd"
      lineHeight="162.5%"
      color={COLORS.colorPrimaryText}
      className={className}
    >
      {header && (
        <Typography
          className={css.header}
          lineHeight="162.5%"
          fontType="bodyMdBold"
          color={COLORS.colorPrimaryText}
        >
          {header}
        </Typography>
      )}
      Certain premium brands that we offer by default have lower commissions. You will
      make less commission when you sell these brands. Please view our{' '}
      <a
        href={url}
        target={target}
        className={cn(css.link, { [css.disabled]: disabled })}
      >
        <Typography fontType="bodyMd" lineHeight="162.5%" color={COLORS.colorPink} link>
          full list here
        </Typography>
      </a>{' '}
      and the commission that you will make.
    </Typography>
  );
};
