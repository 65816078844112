import { IFile } from '@ezetech/swag-space-x';

export interface IBrand {
  primaryColor?: string;
  secondaryColor?: string;
  logo: Record<string, string> | undefined;
  favicon: Record<string, string> | undefined;
}

export interface IBillingAddress {
  billingAddress: string;
  billingCity: string;
  billingState: string;
  billingZip: string;
  billingCountry: string;
}

export interface ICompany {
  name?: string | null;
  email: string;
  phone?: string | null;
  showEmail?: boolean | null;
  showPhone?: boolean | null;
}

export interface ISocial {
  instagram?: string;
  facebook?: string;
  tiktok?: string;
  twitter?: string;
  linkedIn?: string;
}

export interface IStorePreferences {
  brandName?: string;
  homepageTitle?: string;
  logoLinkUrl?: string;
}

export interface IPayout {
  payoutBankCountry: string;
  payoutBankName: string;
  payoutAccountType: string;
  payoutAccountBeneficiaryName: string;
  payoutBankAccountNumber: string;
  payoutBIC?: string;
  payoutRoutingNumber?: string;
  payoutComments?: string;
}

export interface IInvoice {
  invoiceBankCountry: string;
  invoiceBankName: string;
  invoiceAccountType: string;
  invoiceAccountBeneficiaryName: string;
  invoiceBankAccountNumber: string;
  invoiceBIC?: string;
  invoiceRoutingNumber?: string;
  invoiceAsInPayout: boolean;
}

export interface IHoursOperations {
  day: string;
  from: string | null;
  to: string | null;
}

export interface IWhatWeOfferSettings {
  heroText?: string;
  heroImage?: string;
  brandLogos?: string[];
  imagesRaw?: string[];
  formText?: string;
}

export interface IUpdateWhatWeOfferSettings {
  heroText?: string;
  heroImage?: IFile;
  brandLogos?: Array<IFile | null>;
  imagesRaw?: Array<IFile | null>;
  formText?: string;
}

export interface IFieldError {
  [key: string]: string | undefined;
}

export enum TAB_NAMES {
  CompanyAndBrand = 'company-and-brand',
  ProductsAndCommission = 'products-and-commission',
  PlanAndPayment = 'plan-and-payment',
  OperationHours = 'operation-hours',
  UserAndPermissions = 'users',
}

export enum PaymentMethodValidationErrors {
  CreditCardIsNotSet = 'creditCardIsNotSet',
  CreditCardIsExpired = 'creditCardIsExpired',
  BillingAddressIsNotSet = 'billingAddressIsNotSet',
  InvoiceBankDetailsIsNotSet = 'invoiceBankDetailsIsNotSet',
}
export interface IValidatePaymentMethodResponse {
  error: PaymentMethodValidationErrors | null;
}

export enum ProductSortingEnum {
  MOST_POPULAR = 'MOST_POPULAR',
  NEWEST = 'NEWEST',
  HOTTEST = 'HOTTEST',
  HIGHEST_PRICE = 'HIGHEST_PRICE',
  LOWEST_PRICE = 'LOWEST_PRICE',
}
