import { useSelector } from 'react-redux';
import {
  RainbowBox,
  // SwagButton,
  Typography,
} from '@ezetech/swag-space-x';

import { useAppDispatch } from 'redux/store';
import {
  setActiveCategoryTab,
  setBudget,
  setCategoryDisabled,
  setPremium,
  setStandard,
  setSuperInexpensive,
} from 'redux/slices/store-creation.slice';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import {
  activeCategoryTabSelector,
  isCurrentCategoryEnabledSelector,
  isSuperInexpensiveEnabledSelector,
  isPremiumEnabledSelector,
  isStandardEnabledSelector,
  isBudgetEnabledSelector,
  resellerCategoriesStructureSelector,
  resellerSelectedCategoriesSelector,
} from 'redux/selectors/store-creation.selectors';

import css from './product-selection.module.scss';
import { PriceWidget } from './price-widget';
import { ProductWidget } from './products-widget';

export function ProductSelectionComponent(): JSX.Element {
  const dispatch = useAppDispatch();

  const categoriesStructure = useSelector(resellerCategoriesStructureSelector);
  const selectedCategories = useSelector(resellerSelectedCategoriesSelector);
  const activeCategoryTab = useSelector(activeCategoryTabSelector);
  const isCurrentCategoryEnabled = useSelector(isCurrentCategoryEnabledSelector);

  const isSuperInexpensiveEnabled = useSelector(isSuperInexpensiveEnabledSelector);
  const isBudgetEnabled = useSelector(isBudgetEnabledSelector);
  const isStandardEnabled = useSelector(isStandardEnabledSelector);
  const isPremiumEnabled = useSelector(isPremiumEnabledSelector);

  const currentCategory = categoriesStructure[activeCategoryTab];
  const currentCategoryName = currentCategory.name;

  const onTabClick = (index: number) =>
    dispatch(setActiveCategoryTab({ activeCategoryTab: index }));

  const onToggle = () =>
    dispatch(
      setCategoryDisabled({
        isEnabled: !isCurrentCategoryEnabled,
        activeCategoryTab,
      }),
    );

  const onBudgetToggle = () => dispatch(setBudget({ isEnabled: !isBudgetEnabled }));
  const onPremiumToggle = () => dispatch(setPremium({ isEnabled: !isPremiumEnabled }));
  const onStandardToggle = () => dispatch(setStandard({ isEnabled: !isStandardEnabled }));
  const onSuperInexpensiveToggle = () =>
    dispatch(setSuperInexpensive({ isEnabled: !isSuperInexpensiveEnabled }));

  return (
    <section className={css.section}>
      <div className={css.textBlock}>
        <Typography lineHeight="100%" fontType="HEADING6XL" color={COLORS.colorBlack}>
          Product selection
        </Typography>

        <Typography
          className={css.mainHeading}
          lineHeight="100%"
          fontType="HEADING3XL"
          color={COLORS.colorTypographyHeadings}
        >
          Which products do you want to offer?
        </Typography>
      </div>

      <RainbowBox
        borderWidth={5}
        outerClassName={css.outerBoxClassName}
        innerClassName={css.innerBoxClassName}
      >
        <ProductWidget
          selectedCategories={selectedCategories}
          onTabClick={onTabClick}
          activeCategoryTab={activeCategoryTab}
          onToggle={onToggle}
          isCurrentCategoryEnabled={isCurrentCategoryEnabled}
          currentCategoryName={currentCategoryName}
        />
        <PriceWidget
          onSuperInexpensiveToggle={onSuperInexpensiveToggle}
          isSuperInexpensiveEnabled={isSuperInexpensiveEnabled}
          onBudgetToggle={onBudgetToggle}
          isBudgetEnabled={isBudgetEnabled}
          onStandardToggle={onStandardToggle}
          isStandardEnabled={isStandardEnabled}
          onPremiumToggle={onPremiumToggle}
          isPremiumEnabled={isPremiumEnabled}
        />
      </RainbowBox>
    </section>
  );
}
