import { CellTextColor } from '@ezetech/swag-space-x';
import { createSelector } from 'reselect';

import { RootState } from 'redux/store';
import { getTimeMmmDdYyyy } from 'utils/date/date-formatter.util';
import { mapItemIntoDetailedViewProduct } from 'utils/cart.utils';
import { pluralize } from 'utils/string.util';
import { can } from 'boot/ability';
import { SUBJECTS } from 'constants/ability.constant';
import { ASSIGNED_TO_ME_ENTITY } from 'constants/common';
import {
  InvoicesFields,
  InvoicesStatusEnum,
  INVOICE_TABLE_HEADER,
  INVOICE_TABLE_ROW,
  INVOICES_FILTER_OPTIONS,
} from 'interfaces/invoice';
import { ITransformInvoiceCell } from 'interfaces/invoice.interface';
import { assignmentFlowEnabledSelector } from './settings.selectors';

export const invoicesSelector = (state: RootState) => state.invoices;

export const invoicesListSelector = createSelector(
  invoicesSelector,
  (state) => state.invoices,
);

export const invoicesHeaderSelector = createSelector(
  assignmentFlowEnabledSelector,
  (isOrderAssignmentEnabled) => {
    if (!isOrderAssignmentEnabled) {
      return INVOICE_TABLE_HEADER.filter((field) => field.id !== InvoicesFields.assignTo);
    }
    return INVOICE_TABLE_HEADER;
  },
);

export const invoicesRowsSelector = createSelector(
  invoicesListSelector,
  invoicesHeaderSelector,
  (list, invoicesHeader) => {
    const rows: {
      cells: ITransformInvoiceCell[];
      index: number;
    }[] = [];

    list.forEach((invoice, position) => {
      const cells: ITransformInvoiceCell[] & {
        sentAt?: Date | null;
      } = [];

      invoicesHeader.forEach((cell) => {
        const common = INVOICE_TABLE_ROW[cell.id] || {};
        const cellParams = { ...common, rowId: invoice.id };
        const sentAt = invoice.sentAt;
        const paidAt = invoice.paidAt;
        const hasPresentation = !!invoice.presentationId;
        const customerEmail = invoice.customerEmail;
        const isOrdered = !!invoice.orderNum;
        const isInventory = invoice.cartDistributionType === InvoicesStatusEnum.INVENTORY;
        const isContractSigned = !!invoice.inventoryContractSignedAt;
        const inventoryContract = invoice.inventoryContract;
        const assignedToUser = invoice?.assignee
          ? {
              value: invoice.assignee.id,
              label: `${invoice?.assignee.firstName} ${invoice?.assignee.lastName}`,
            }
          : null;

        if (cell.id === InvoicesFields.customerEmail) {
          cells.push({
            ...cellParams,
            text: invoice.customerEmail,
            id: InvoicesFields.customerEmail,
          });
        }
        if (cell.id === InvoicesFields.company) {
          cells.push({
            ...cellParams,
            text: invoice.customerCompanyName || 'n/a',
            customerAddress: invoice.customerAddress,
            textColor: invoice.customerCompanyName
              ? CellTextColor.pink
              : CellTextColor.primary,
            id: InvoicesFields.company,
          });
        }
        if (cell.id === InvoicesFields.emailStatus) {
          cells.push({
            ...cellParams,
            text: 'email status',
            id: InvoicesFields.emailStatus,
            sentAt,
          });
        }
        if (cell.id === InvoicesFields.orderType) {
          cells.push({
            ...cellParams,
            text: isInventory ? 'Inventory' : 'Bulk',
            isInventory,
            isContractSigned,
            inventoryContract,
            id: InvoicesFields.orderType,
          });
        }
        if (cell.id === InvoicesFields.presentation) {
          cells.push({
            ...cellParams,
            text: hasPresentation ? 'Go to' : '',
            id: InvoicesFields.presentation,
            hasPresentation,
            customerEmail,
          });
        }
        if (cell.id === InvoicesFields.downloadInvoice) {
          cells.push({
            ...cellParams,
            text: '',
            id: InvoicesFields.downloadInvoice,
          });
        }
        if (cell.id === InvoicesFields.payment) {
          cells.push({
            ...cellParams,
            text: 'payment',
            id: InvoicesFields.payment,
            paidAt,
          });
        }
        if (cell.id === InvoicesFields.products) {
          cells.push({
            ...cellParams,
            text: `${invoice.items.length} ${pluralize(
              invoice.items.length,
              'Product',
              's',
              true,
            )}`,
            id: InvoicesFields.products,
          });
        }
        if (cell.id === InvoicesFields.createdAt) {
          cells.push({
            ...cellParams,
            text: invoice.createdAt
              ? getTimeMmmDdYyyy(new Date(invoice.createdAt), {
                  day: 'numeric',
                  month: 'short',
                })
              : 'n/a',
            id: InvoicesFields.createdAt,
          });
        }
        if (cell.id === InvoicesFields.assignTo) {
          cells.push({
            ...cellParams,
            text: '',
            id: InvoicesFields.assignTo,
            isOrdered,
            isInventory,
            isContractSigned,
            assignedToUser,
          });
        }
        if (cell.id === InvoicesFields.manage) {
          cells.push({
            ...cellParams,
            text: '',
            id: InvoicesFields.manage,
            isOrdered,
            isInventory,
            isContractSigned,
          });
        }
      });

      rows.push({
        cells,
        index: position + 1,
      });
    });

    return rows;
  },
);

export const isPaginationInvoiceVisibleSelector = createSelector(
  invoicesListSelector,
  (list) => list.length > 0,
);

export const selectedCartProductsSelector = (invoiceId?: string) =>
  createSelector(invoicesListSelector, (invoices) => {
    const invoice = invoices.find((item) => item.id === invoiceId);

    if (!invoice) {
      return [];
    }
    return invoice.items.map(mapItemIntoDetailedViewProduct);
  });

export const clipboardLinkSelector = createSelector(
  invoicesSelector,
  (state) => state.clipboardLink,
);

export const invoicesFilterOptionsSelector = createSelector(
  assignmentFlowEnabledSelector,
  (isOrderAssignmentEnabled) => {
    const isAbleToBeAssignedForInvoice = can(
      SUBJECTS.CREATE_EDIT_INVOICE.actions.ASSIGN_INVOICE,
      SUBJECTS.CREATE_EDIT_INVOICE.value,
    );

    if (!isOrderAssignmentEnabled && isAbleToBeAssignedForInvoice) {
      return INVOICES_FILTER_OPTIONS.filter(
        (option) => option.value !== ASSIGNED_TO_ME_ENTITY,
      );
    }
    return INVOICES_FILTER_OPTIONS;
  },
);

export const initialInvoicesFiltersSelector = createSelector(
  assignmentFlowEnabledSelector,
  (isOrderAssignmentEnabled) => {
    const isAbleToBeAssignedForInvoice = can(
      SUBJECTS.CREATE_EDIT_INVOICE.actions.ASSIGN_INVOICE,
      SUBJECTS.CREATE_EDIT_INVOICE.value,
    );

    if (isOrderAssignmentEnabled && isAbleToBeAssignedForInvoice) {
      return INVOICES_FILTER_OPTIONS.filter(
        (option) => option.value === ASSIGNED_TO_ME_ENTITY,
      );
    }
    return [];
  },
);
