import { Checkbox } from '@ezetech/swag-space-x';

import { SidebarBrandsWeOfferBlockProps } from '../interface';

import css from './sidebar.module.scss';

export const SidebarBrandsWeOfferBlock = (
  props: SidebarBrandsWeOfferBlockProps,
): JSX.Element => {
  const { settings, setChanges } = props;

  return (
    <div className={css.sidebarMainBlock}>
      <div>
        <Checkbox
          onCheckedChange={(e) => setChanges('isHidden', e)}
          name="isHidden"
          checked={settings.isHidden}
          label="Hide section"
        />
      </div>
    </div>
  );
};
