import { IUserProfile, TAX_METHODS } from 'interfaces/customer-details.interface';
export function formatUser(user: IUserProfile | null, isEdit?: boolean) {
  const fallbackUser = {
    userId: '',
    companyId: '',
    phone: isEdit ? '' : 'N/A',
    email: '',
    firstName: '',
    lastName: '',
    companyName: isEdit ? '' : 'N/A',
    address: '',
    city: '',
    zip: '',
    country: '',
    state: '',
    hasInventory: false,
    isSpecificReviewMockupsByCustomerAllowed: null,
    isSpecificReviewPantonesByCustomerAllowed: null,
    tax: TAX_METHODS.TAX_CHANGE,
    isTaxExemptPending: false,
  };

  if (!user) {
    return fallbackUser;
  }

  const {
    email,
    company,
    companyName,
    phone,
    id: userId,
    firstName,
    lastName,
    address,
    hasInventory = false,
    isSpecificReviewMockupsByCustomerAllowed,
    isSpecificReviewPantonesByCustomerAllowed,
    tax,
    isTaxExemptPending,
  } = user;
  const { id } = company;
  const { address: userAddress, city, zip, country, state } = address;
  const fallBackPhone = isEdit ? '' : 'N/A';
  const fallBackCompanyName = isEdit ? '' : 'N/A';

  return {
    userId,
    hasInventory,
    companyId: id,
    firstName: firstName,
    lastName: lastName,
    email: email,
    phone: phone || fallBackPhone,
    companyName: companyName || fallBackCompanyName,
    address: userAddress,
    city: city,
    zip: zip,
    country: country,
    state: state,
    isSpecificReviewMockupsByCustomerAllowed,
    isSpecificReviewPantonesByCustomerAllowed,
    tax,
    isTaxExemptPending,
  };
}

export function formatUserName(user: IUserProfile | null): string {
  if (!user?.firstName && !user?.lastName) {
    return '';
  }

  return `${user.firstName || ''} ${user.lastName || ''}`.trim();
}
