const defaultOptions = {
  day: 'numeric',
  month: 'short',
  year: 'numeric',
} as const;

interface IOptions {
  weekday?: 'short' | 'long';
  year?: 'numeric' | '2-digit' | undefined;
  month?: 'numeric' | '2-digit' | 'long' | 'short' | 'narrow' | undefined;
  day?: 'numeric' | '2-digit' | undefined;
  hour?: '2-digit' | 'numeric' | undefined;
  minute?: '2-digit' | undefined;
  hour12?: boolean;
  timeZone?: string;
}

export const getTimeMmmDdYyyy = (date: Date, options?: IOptions): string => {
  const params = options || defaultOptions;

  return new Intl.DateTimeFormat('en-US', params).format(date);
};

export function getDayAndMonth(date: Date): string {
  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: 'short',
  };
  const dateFormatter = new Intl.DateTimeFormat('en-US', options);
  const formattedDate = dateFormatter.format(date);
  return formattedDate;
}
