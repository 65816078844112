import { Typography } from '@ezetech/swag-space-x';

import { useNavigate } from 'react-router-dom';
import { SUBJECTS } from 'constants/ability.constant';
import { can } from 'boot/ability';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { ROUTES } from 'constants/router';

import css from '../order-card-header/order-card-header.module.scss';
import { resetFilters } from '../../../../redux/slices/filter.slice';
import { useAppDispatch } from '../../../../redux/store';

interface IProps {
  className: string;
  userId: string;
  fullName: string;
  email: string;
}

export const OrderCardHeaderHolderComponent = ({
  className,
  userId,
  fullName,
  email,
}: IProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const subjectViewCustomerDetailsName = SUBJECTS.CUSTOMER_LIST.value;
  const subjectViewCustomerDetailsActions = SUBJECTS.CUSTOMER_LIST.actions;
  const hasAccessForProfileActions = can(
    subjectViewCustomerDetailsActions.VIEW_CUSTOMER_DETAILS,
    subjectViewCustomerDetailsName,
  );

  const navigateToUser = () => {
    dispatch(resetFilters());

    if (hasAccessForProfileActions) {
      navigate(ROUTES.CUSTOMER_PROFILE.replace(':id', userId));
    }
  };

  return (
    <span className={className}>
      <Typography
        lineHeight="142%"
        fontType="bodySmBold"
        color={COLORS.colorPink}
        className={css.pink}
        onClick={navigateToUser}
      >
        {fullName}
      </Typography>
      <span className={css.dash}>-</span>
      <Typography
        lineHeight="142%"
        fontType="bodySmBold"
        color={COLORS.colorPink}
        className={css.pink}
        onClick={navigateToUser}
      >
        {email}
      </Typography>
    </span>
  );
};
