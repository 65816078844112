import {
  Dialog,
  DialogContentWrapper,
  DialogTitle,
  DialogDescription,
  DialogFooter,
  SwagButton,
  Typography,
} from '@ezetech/swag-space-x';
import cn from 'classnames';
import { ConfirmationPopupProps } from 'interfaces/popup.interface';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import css from './confirmation.popup.module.scss';

export const ConfirmationPopup = ({
  isOpen,
  title = 'Confirm Action',
  descriptionComponent = '',
  onConfirm,
  onCancel,
  onXClick,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  isConfirmLoading = false,
  isCancelLoading = false,
  descriptionColor = COLORS.colorTextSubdued,
  descriptionFontType = 'bodyMd',
  dialogContentWrapperClassName,
  dialogOverlayClassName,
  footerClassName,
  buttonClassName,
  confirmButtonClassName,
  cancelButtonClassName,
  confirmButtonStyle = 'regular',
  isPopupLoading = false,
}: ConfirmationPopupProps) => {
  return (
    <Dialog
      isLoading={isPopupLoading}
      open={isOpen}
      onOpenChange={onXClick ? onXClick : onCancel}
    >
      <DialogContentWrapper
        className={cn(css.dialogContentWrapperClassName, dialogContentWrapperClassName)}
        overlayClassName={cn(css.dialogOverlayClassName, dialogOverlayClassName)}
      >
        <DialogTitle closeIconClassName={css.closeIcon}>{title}</DialogTitle>
        <DialogDescription>
          {typeof descriptionComponent === 'string' ? (
            <Typography
              color={descriptionColor}
              fontType={descriptionFontType}
              lineHeight="162.5%"
            >
              {descriptionComponent}
            </Typography>
          ) : (
            descriptionComponent
          )}
        </DialogDescription>

        <DialogFooter className={cn(css.footer, footerClassName)}>
          <SwagButton
            className={cn(buttonClassName, cancelButtonClassName)}
            type="outlined"
            loading={isCancelLoading}
            onClick={onCancel}
          >
            {cancelText}
          </SwagButton>
          <SwagButton
            className={cn(buttonClassName, confirmButtonClassName, {
              [css.confirmButtonDanger]: confirmButtonStyle === 'danger',
              [css.confirmButtonDangerLoading]:
                confirmButtonStyle === 'danger' && isConfirmLoading,
            })}
            type="primary"
            onClick={onConfirm}
            loading={isConfirmLoading}
          >
            {confirmText}
          </SwagButton>
        </DialogFooter>
      </DialogContentWrapper>
    </Dialog>
  );
};
