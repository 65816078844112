import { domainRegex } from 'constants/validation';

export const FIELDS = {
  domain: {
    label: 'Domain',
    name: 'domain',
    placeholder: 'Start typing domain',
    rules: {
      required: 'Domain is required',
      pattern: {
        value: domainRegex,
        message: "Domain you entered doesn't seem to be valid",
      },
    },
  },
};
