import { SwagButton } from '@ezetech/swag-space-x';
import { ReactComponent as Eye } from 'assets/svg/eye.svg';
import { ReactComponent as Pen } from 'assets/svg/pen.svg';

import css from './mobile-preview-button.module.scss';

type PageEditorMobilePreviewButtonProps = {
  onClick?: () => void;
  mobilePreview?: boolean;
};

export const PageEditorMobilePreviewButton = (
  props: PageEditorMobilePreviewButtonProps,
): JSX.Element => {
  const { onClick, mobilePreview } = props;
  return (
    <SwagButton
      onClick={onClick}
      className={css.roundButton}
      buttonTextClassName={css.roundButtonText}
      borderRad={'round'}
      type={'outlined'}
    >
      {mobilePreview ? <Pen /> : <Eye />}
    </SwagButton>
  );
};
