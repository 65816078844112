import { Typography } from '@ezetech/swag-space-x';

import { useAppSelector } from 'redux/store';
import { paymentComponentSelector } from 'redux/selectors/orders.selectors';
import COLORS from 'constants/styles/colors-js.constant.module.scss';

import css from '../../order-card-item.module.scss';

interface IProps {
  orderId: string;
}

export const PaymentComponent = ({ orderId }: IProps): JSX.Element => {
  const { paymentMethod } = useAppSelector(paymentComponentSelector({ orderId }));

  return (
    <div className={css.payment}>
      <Typography lineHeight="170%" fontType="bodyMd" color={COLORS.colorSecondaryText}>
        Payment:
      </Typography>
      <Typography lineHeight="170%" fontType="bodyMdBold" color={COLORS.colorPrimaryText}>
        {paymentMethod}
      </Typography>
    </div>
  );
};
