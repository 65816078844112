import React from 'react';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import { MultiValue } from 'react-select';
import {
  Table,
  TableBody,
  TableHeader,
  TableRow,
  TableHeaderCell,
  TableRowCell,
  WhiteBox,
  Select,
  OptionType,
  Typography,
} from '@ezetech/swag-space-x';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { useNotifications } from 'hooks/notifications.hook';
import { useClipboard } from 'hooks/use-clipboard.hook';
import { CustomerFields, ITransformCell } from 'interfaces/user-api.interface';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { SUBJECTS } from 'constants/ability.constant';
import { can } from 'boot/ability';
import {
  customersHeaderSelector,
  customersTableRowsSelector,
  emptyStateSelector,
} from 'redux/selectors/customers-leads.selectors';
import { customersAssignToOptionsSelector } from 'redux/selectors/assignee.selector';
import { useAssignCustomerToUserMutation } from 'redux/api/assignee.api';
import { NotificationType } from 'redux/slices/notifications.slice';
import { resetFilters } from 'redux/slices/filter.slice';
import { reset as resetCustomerCompany } from 'redux/slices/customer-company.slice';
import { EmptyState } from 'components/empty-state';
import { ROUTES } from 'constants/router';
import classes from './customers-table.module.scss';

export function CustomersTable({
  onHeaderCellClick,
  getSortingDirection,
}: {
  onHeaderCellClick(id: string): void;
  getSortingDirection(id: string): 'up' | 'down';
}): JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { copy } = useClipboard();
  const { pushNotification } = useNotifications();
  const rows = useAppSelector(customersTableRowsSelector);
  const header = useAppSelector(customersHeaderSelector);
  const customersAssignToOptions = useAppSelector(customersAssignToOptionsSelector);
  const emptyState = useAppSelector(emptyStateSelector);
  const [assignCustomerToUserRequest] = useAssignCustomerToUserMutation();

  const subjectViewCustomerDetailsName = SUBJECTS.CUSTOMER_LIST.value;
  const subjectViewCustomerDetailsActions = SUBJECTS.CUSTOMER_LIST.actions;

  const hasAccessForProfileActions = can(
    subjectViewCustomerDetailsActions.VIEW_CUSTOMER_DETAILS,
    subjectViewCustomerDetailsName,
  );

  const isAbleToAssignCustomers = can(
    SUBJECTS.TEAM_MEMBER_CUSTOMER_ASSIGNMENT.actions.ASSIGN_TEAM_MEMBER_TO_CUSTOMER,
    SUBJECTS.TEAM_MEMBER_CUSTOMER_ASSIGNMENT.value,
  );

  const isAbleToBeAssignedForCustomer = can(
    SUBJECTS.EDIT_CUSTOMERS_DETAILS.actions.ASSIGN_CUSTOMERS,
    SUBJECTS.EDIT_CUSTOMERS_DETAILS.value,
  );

  const handleClick = () => {
    navigate(ROUTES.DASHBOARD);
  };

  if (!rows.length) {
    const { title, description, button } = emptyState;

    return (
      <EmptyState
        title={title}
        description={description.replace('record', 'customer')}
        button={button}
        onClick={handleClick}
      />
    );
  }

  const copyText = async (value?: string, field?: string) => {
    const text = field ? `${field}` : 'Text';

    const isSuccess = await copy(value);

    if (isSuccess) {
      pushNotification({
        status: 'positive',
        message: `${text} has been copied.`,
        type: NotificationType.toasts,
      });
    }
  };

  const handleCellClick = (
    e?: React.SyntheticEvent<HTMLSpanElement>,
    name?: string,
    rowId?: string,
  ) => {
    if (name === CustomerFields.email) {
      copyText(e?.currentTarget.innerHTML, 'Email');
    }
    if (name === CustomerFields.customerName && rowId && hasAccessForProfileActions) {
      dispatch(resetFilters());
      navigate(ROUTES.CUSTOMER_PROFILE.replace(':id', rowId));
    }
  };

  const redirectToCustomerCompany = ({ customerCompanyId }: ITransformCell) => {
    if (!customerCompanyId) {
      return;
    }

    dispatch(resetFilters());
    dispatch(resetCustomerCompany());
    navigate(ROUTES.CUSTOMER_COMPANY.replace(':id', customerCompanyId || ''));
  };

  const handleHeaderCellClick = (id: string) => {
    onHeaderCellClick(id);
  };

  const onAssignmentSelect =
    (cell: ITransformCell) =>
    (option: OptionType | MultiValue<OptionType | null> | null) => {
      if ((option as OptionType).value === cell.assignedToUser?.value) {
        return;
      }

      assignCustomerToUserRequest({
        entityId: cell.rowId || '',
        userId: (option as OptionType).value || '',
      });
    };

  return (
    <WhiteBox className={classes.root}>
      <Table className={classes.tableWrapper}>
        <TableHeader>
          {header.map((cell, index) => {
            const cellClassName =
              cell.id === CustomerFields.assignTo
                ? classes.assignToCell
                : classes.headerCell;

            return (
              <TableHeaderCell
                id={cell.id}
                className={cn(cellClassName, { [classes.number]: index === 0 })}
                size={cell.size}
                key={`customers-header-${cell.id}`}
                withSorting={cell.withSorting}
                iconDirection={getSortingDirection(cell.id)}
                onClick={cell.withSorting ? handleHeaderCellClick : undefined}
              >
                {cell.text}
              </TableHeaderCell>
            );
          })}
        </TableHeader>
        <TableBody>
          {rows.map((row, position) => (
            <TableRow
              primaryDataLabel={row.cells[0].rowId}
              key={`customers-${row.index}`}
            >
              {row.cells.map((cell, index) => {
                if (cell.id === CustomerFields.assignTo) {
                  if (
                    isAbleToAssignCustomers ||
                    (isAbleToBeAssignedForCustomer && !cell.assignedToUser)
                  ) {
                    return (
                      <Select
                        value={cell.assignedToUser}
                        options={customersAssignToOptions}
                        onChange={onAssignmentSelect(cell)}
                        className={classes.assignToCell}
                        isSearchable
                        name="assignTo"
                        skipError
                        menuPlacement="auto"
                        size="small"
                        type="outlined"
                        menuPosition="fixed"
                        menuShouldBlockScroll
                      />
                    );
                  }

                  return (
                    <div className={classes.assignToCell}>
                      <Typography color={COLORS.colorPrimaryText} fontType="bodyMd">
                        {cell.assignedToUser?.label}
                      </Typography>
                    </div>
                  );
                }

                return (
                  <TableRowCell
                    dataLabel={`${cell.id + '-' + cell.rowId}`}
                    className={cn(classes.cell, { [classes.number]: index === 0 })}
                    key={`customers-${position}-${cell.id}-${index}`}
                    textColor={cell.textColor}
                    textWeight={cell.textWeight}
                    size={cell.size}
                    id={cell.id}
                    rowId={cell.rowId}
                    actionable={cell.actionable}
                    onClick={
                      cell.id === CustomerFields.companyName
                        ? () => redirectToCustomerCompany(cell)
                        : handleCellClick
                    }
                  >
                    {cell.text}
                  </TableRowCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </WhiteBox>
  );
}
