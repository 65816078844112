import { DEFAULT_PRICE_POINTS } from 'constants/store-creation.constants';
import { STEP_NAME } from 'enums/store-creation.enum';
import { IStoreSettings, IStoreSettingsBack } from 'interfaces/store-creation.interfaces';

export const mapFieldsToStore = (params: IStoreSettingsBack): IStoreSettings => ({
  [STEP_NAME.PRODUCT_SELECTION]: {
    categories: params.categories ?? [],
    pricePoints: params.productsPricePoints ?? DEFAULT_PRICE_POINTS,
  },
  [STEP_NAME.COMMISSION]: { resellerMargin: params.margin || 0 },
  [STEP_NAME.PAGE_LINK]: { resellerStoreName: params.brandName || '' },
  [STEP_NAME.SIGN_CONTRACT]: {
    isContractSigned:
      typeof params.isContractSigned === 'boolean' ? params.isContractSigned : null,
    isContractRead: false,
  },
  [STEP_NAME.BRAND]: {
    primaryColor: params.primaryColor ?? undefined,
    secondaryColor: params.secondaryColor ?? undefined,
    logo: params.logo ?? undefined,
    favicon: params.favicon ?? undefined,
  },
});

export const mapFieldsToDB = (
  params: Partial<
    | IStoreSettings[STEP_NAME.COMMISSION]
    | IStoreSettings[STEP_NAME.BRAND]
    | IStoreSettings[STEP_NAME.SIGN_CONTRACT]
    | IStoreSettings[STEP_NAME.PRODUCT_SELECTION]
    | IStoreSettings[STEP_NAME.PAGE_LINK]
  > & {
    isReviewPantonesByCustomerAllowed?: boolean;
    isReviewMockupsByCustomerAllowed?: boolean;
  },
): IStoreSettingsBack => {
  const res: IStoreSettingsBack = {};

  if ('resellerMargin' in params) {
    res.margin = params.resellerMargin;
  }

  if ('resellerStoreName' in params) {
    res.brandName = params.resellerStoreName;
  }

  if ('isContractSigned' in params) {
    res.isContractSigned = params.isContractSigned;
  }

  if ('primaryColor' in params) {
    res.primaryColor = params.primaryColor;
  }

  if ('secondaryColor' in params) {
    res.secondaryColor = params.secondaryColor;
  }

  if ('logo' in params) {
    res.logo = params.logo;
  }

  if ('favicon' in params) {
    res.favicon = params.favicon;
  }

  if ('categories' in params) {
    res.categories = params.categories;
  }

  if ('pricePoints' in params) {
    res.productsPricePoints = params.pricePoints;
  }

  if ('isReviewPantonesByCustomerAllowed' in params) {
    res.isReviewPantonesByCustomerAllowed = params.isReviewPantonesByCustomerAllowed;
  }

  if ('isReviewMockupsByCustomerAllowed' in params) {
    res.isReviewMockupsByCustomerAllowed = params.isReviewMockupsByCustomerAllowed;
  }

  if ('defaultSorting' in params) {
    res.defaultSorting = params.defaultSorting;
  }

  return res;
};
