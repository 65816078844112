import { useEffect, useState } from 'react';
import { getPublicSid } from 'utils/auth.util';

import { useAppSelector } from '../redux/store';
import { profileSelector } from '../redux/selectors/user.selectors';

export function useAuth() {
  const user = useAppSelector(profileSelector);

  const [isAuthorized, setIsAuthorized] = useState<boolean>(!!getPublicSid());

  const id = user?.id;

  useEffect(() => {
    setIsAuthorized(!!getPublicSid());
  }, [id]);

  return { isAuthorized };
}
