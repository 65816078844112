import { FC } from 'react';
import { createPortal } from 'react-dom';
import { ColoredFrame as Component } from '@ezetech/swag-space-x';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import {
  adminLoggedInAsResellerFrameParamsSelector,
  adminLoggedInAsResellerSelector,
} from '../../redux/selectors/user.selectors';
import { closePopup, openPopup } from '../../redux/slices/modals.slice';
import { CONFIRMATION_POPUP } from '../popups/_logic/popups-list';
import { useLogoutAsReseller } from '../../hooks/use-logout-as-reseller.hook';
import css from './colored-frame.module.scss';

export const ColoredFrame: FC = () => {
  const dispatch = useAppDispatch();
  const adminLoggedInAsReseller = useAppSelector(adminLoggedInAsResellerSelector);
  const adminLoggedInAsResellerFrameParams = useAppSelector(
    adminLoggedInAsResellerFrameParamsSelector,
  );
  const logoutAsReseller = useLogoutAsReseller();

  const handleOnExitAsResellerBtnClick = () => {
    dispatch(
      openPopup({
        popupName: CONFIRMATION_POPUP,
        popupProps: {
          descriptionComponent:
            'Are you sure you want to log out from reseller dashboard?',
          onConfirm: () => {
            logoutAsReseller();
            dispatch(closePopup());
          },
          onCancel: () => {
            dispatch(closePopup());
          },
        },
      }),
    );
  };

  if (!adminLoggedInAsReseller) {
    return null;
  }

  const frame = document.getElementById('frame');

  if (!frame) {
    return null;
  }

  return createPortal(
    <div className={css.frame}>
      <Component
        {...adminLoggedInAsResellerFrameParams}
        onButtonClick={handleOnExitAsResellerBtnClick}
        backgroundColor={COLORS.colorPink}
      />
    </div>,
    frame,
  );
};
