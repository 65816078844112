import {
  Dialog,
  DialogContentWrapper,
  DialogTitle,
  DialogDescription,
  DialogFooter,
  SwagButton,
  Typography,
  WhiteBox,
  Accordion,
  AccordionItem,
} from '@ezetech/swag-space-x';
import cn from 'classnames';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { closePopup } from 'redux/slices/modals.slice';
import { CartNotesPopupProps } from 'interfaces/popup.interface';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { getTimeMmmDdYyyy } from 'utils/date/date-formatter.util';
import { ChevronUp } from 'components/svg/chevron-up';
import { getCartNotesSelector } from 'redux/selectors/carts.selector';
import { unsetCartNotes } from 'redux/slices/carts.slice';

import css from './cart-notes-popup.module.scss';
import { ProductNotesCard } from './product-notes-card';
import { getOtherCartNotesLength } from './cart-notes.util';

export const CartNotesPopup = ({ email, isOpen }: CartNotesPopupProps): JSX.Element => {
  const [isGeneralNotesOpen, setIsGeneralNotesOpen] = useState(false);
  const dispatch = useAppDispatch();
  const notes = useAppSelector(getCartNotesSelector);

  const onBtnCloseClick = () => {
    dispatch(unsetCartNotes());
    dispatch(closePopup());
  };

  const hasGeneralNotes = notes.generalNotes.length > 0;
  const hasIndividualNotes = notes.individualNotes.length > 0;

  return (
    <Dialog open={isOpen} onOpenChange={onBtnCloseClick}>
      <DialogContentWrapper
        className={css.dialogContentWrapperClassName}
        overlayClassName={css.dialogOverlayClassName}
      >
        <DialogTitle
          className={css.titleClassName}
          closeIconClassName={css.closeIconClassName}
        >
          Notes From {email}
        </DialogTitle>
        <DialogDescription className={css.contentWrapper}>
          {hasGeneralNotes && (
            <section>
              <Typography fontType="bodyMd" color={COLORS.colorTextSubdued}>
                General Notes
              </Typography>

              <WhiteBox className={css.whiteBox}>
                <Accordion className={css.accordionWrapper}>
                  <AccordionItem
                    headerComponent={
                      <div
                        data-newest-general-note-id={notes.generalNotes[0].id}
                        className={cn(css.firstGeneralNote, {
                          [css.firstGeneralNoteSingle]: notes.generalNotes.length === 1,
                        })}
                        onClick={() => setIsGeneralNotesOpen(!isGeneralNotesOpen)}
                      >
                        <Typography
                          fontType="bodySm"
                          color={COLORS.colorPrimaryText}
                          className={cn(
                            css.newestNoteLabel,
                            css.newestNoteLabelFirstGeneral,
                          )}
                        >
                          Newest Note
                        </Typography>
                        <Typography
                          className={css.firstGeneralNoteDate}
                          fontType="bodySm"
                          lineHeight="142.857%"
                          color={COLORS.colorText500}
                        >
                          {getTimeMmmDdYyyy(new Date(notes.generalNotes[0].date), {
                            month: 'short',
                            day: 'numeric',
                            year: 'numeric',
                          })}
                        </Typography>

                        {notes.generalNotes.length > 1 && (
                          <Typography
                            className={cn(css.accordion, css.firstGeneralNoteAccordion)}
                            fontType="bodySmBold"
                            color={COLORS.colorPink}
                          >
                            See {isGeneralNotesOpen ? 'less' : 'more'} notes (
                            {getOtherCartNotesLength(notes.generalNotes.length)})
                            <ChevronUp
                              className={cn(css.chevron, {
                                [css.chevronDown]: isGeneralNotesOpen,
                              })}
                            />
                          </Typography>
                        )}

                        <Typography
                          className={css.firstGeneralNoteText}
                          fontType="bodyMd"
                          lineHeight="162.5%"
                          color={COLORS.colorTextSubdued}
                        >
                          <pre className={css.pre}>
                            Notes: {notes.generalNotes[0].text.trim()}
                          </pre>
                        </Typography>
                      </div>
                    }
                    value="Hero Body Copy"
                    className={css.accordionItem}
                    triggerClassName={css.triggerClassName}
                    contentClassName={css.contentClassName}
                    chevronClassName={css.chevronClassName}
                  >
                    <span className={css.maxLengthText}>
                      {notes.generalNotes.map((note, index) => {
                        if (index) {
                          return (
                            <div data-general-note-id={note.id}>
                              <div className={css.divider} />
                              <div className={css.generalNote}>
                                <Typography
                                  className={css.generalNoteText}
                                  fontType="bodyMd"
                                  lineHeight="162.5%"
                                  color={COLORS.colorTextSubdued}
                                >
                                  <pre className={css.pre}>Notes: {note.text.trim()}</pre>
                                </Typography>
                                <Typography
                                  className={css.generalNoteDate}
                                  fontType="bodySm"
                                  lineHeight="142.857%"
                                  color={COLORS.colorText500}
                                >
                                  {getTimeMmmDdYyyy(new Date(note.date), {
                                    month: 'short',
                                    day: 'numeric',
                                    year: 'numeric',
                                  })}
                                </Typography>
                              </div>
                            </div>
                          );
                        }
                      })}
                    </span>
                  </AccordionItem>
                </Accordion>
              </WhiteBox>
            </section>
          )}
          {hasIndividualNotes && (
            <section className={cn({ [css.sectionWithPadding]: hasGeneralNotes })}>
              <Typography fontType="bodyMd" color={COLORS.colorTextSubdued}>
                Individual Product Notes
              </Typography>

              {notes.individualNotes.map((product) => (
                <ProductNotesCard product={product} key={product.id} />
              ))}
            </section>
          )}
        </DialogDescription>

        <DialogFooter className={css.footer}>
          <SwagButton type="primary" onClick={onBtnCloseClick}>
            Close
          </SwagButton>
        </DialogFooter>
      </DialogContentWrapper>
    </Dialog>
  );
};
