import * as yup from 'yup';
import { ERROR_MSG } from 'constants/validation';

export const validationSchema = yup.object({
  oldPassword: yup.string().required(ERROR_MSG.required),
  password: yup.string().required(ERROR_MSG.required),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password')], ERROR_MSG.passwordsMatch)
    .required(ERROR_MSG.required),
});
