import { ICell, MultiOptionType } from '@ezetech/swag-space-x';
import { IPrebuiltItem } from './cart.interfaces';
import { InvoicesActions, InvoicesFields } from './invoice';
import { IAssignedForReseller } from './assignee.interface';

export interface IGetPresentationsRequest {
  search: string | null;
  page: number;
  perPage: number;
  sorting: string;
  sortingOrder: 'ASC' | 'DESC';
  dropdown: MultiOptionType;
}

export interface IInvoiceListResponse {
  invoices: IInvoice[];
  total: number;
  page: number;
  perPage: number;
}

export interface IInvoice {
  id: string;
  cartId: string;
  customerEmail: string;
  customerCompanyName: string;
  customerAddress: string;
  cartDistributionType: string;
  sentAt: Date | null;
  paidAt: Date | null;
  createdAt: Date;
  items: IPrebuiltItem[];
  presentationId: string | null;
  orderNum: string | null;
  inventoryContractSignedAt: Date | null;
  inventoryContract: string;
  assignee?: IAssignedForReseller | null;
}
export interface ITransformInvoiceCell extends Omit<ICell, 'onClick' | 'id'> {
  id: InvoicesFields;
  onClick?: InvoicesActions;
  sentAt?: Date | null;
  paidAt?: Date | null;
  hasPresentation?: boolean;
  isInventory?: boolean;
  isContractSigned?: boolean;
  inventoryContract?: string;
  customerEmail?: string;
  customerAddress?: string;
  isOrdered?: boolean;
  assignedToUser?: { value: string; label: string } | null;
}

export enum InvoiceMarkActionEnum {
  SENT = 'sent',
  PAID = 'paid',
}

export interface IInvoiceCustomerData {
  customerEmail: string;
  companyName: string;
  country: string;
  city: string;
  zip: string;
  state: string;
  address: string;
}
