import { Typography } from '@ezetech/swag-space-x';
import classnames from 'classnames';

import { useAppSelector } from 'redux/store';
import { orderCardItemComponentSelector } from 'redux/selectors/orders.selectors';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { Quantity } from './components/quantity';
import { BoxSample } from './components/box-sample';
import { PriceBlock } from './components/price-block';
import { Status } from './components/status';
import { SentTo } from './components/sent-to';
import { InHandDate } from './components/in-hand-date';
import { Payment } from './components/payment';
import { TotalCost } from './components/total-cost';

import css from './order-card-item.module.scss';

interface IProps {
  orderId: string;
  itemId: string;
  parentBoxItemId?: string;
  isBox?: boolean;
  isFirstItem?: boolean;
  isLastItem?: boolean;
}

export const OrderCardItemComponent = ({
  orderId,
  itemId,
  isBox,
  isFirstItem,
  isLastItem,
}: IProps) => {
  const { isDummy, imageUrl, name, showQuantity, publicId, productType } = useAppSelector(
    orderCardItemComponentSelector({ orderId, itemId }),
  );

  return (
    <div
      data-product={productType}
      data-item-id={itemId}
      data-item-publicId={publicId}
      className={classnames(css.container, {
        [css.containerWithRoundedTopBorder]: isFirstItem,
        [css.containerWithRoundedBottomBorder]: isLastItem,
      })}
    >
      <div className={css.left}>
        <div className={css.imageBlock}>
          <img className={css.image} src={imageUrl} alt="mockup" />
        </div>
        <div className={css.blocksWrapper}>
          <div className={css.titleBlock}>
            <div className={css.title}>
              <Typography
                lineHeight="110%"
                fontType="HEADING6XL"
                color={COLORS.colorPrimaryText}
              >
                {name}
              </Typography>
            </div>
            {showQuantity ? (
              <Quantity orderId={orderId} itemId={itemId} />
            ) : (
              <BoxSample />
            )}
          </div>
          <PriceBlock orderId={orderId} itemId={itemId} isBox={isBox} />
        </div>
      </div>
      <div className={css.right}>
        <div className={css.statusBlock}>
          <Status orderId={orderId} itemId={itemId} />
          <SentTo orderId={orderId} itemId={itemId} />
          <InHandDate orderId={orderId} itemId={itemId} />
        </div>
        <div className={css.paymentBlock}>
          {!isDummy && <Payment orderId={orderId} />}
          <TotalCost orderId={orderId} itemId={itemId} />
        </div>
      </div>
    </div>
  );
};
