import cn from 'classnames';
import { IframeHTMLAttributes, useState, useEffect } from 'react';
import { Loader } from '@ezetech/swag-space-x';

import { PageEditorIframe } from '../iframe';
import css from './preview.module.scss';

interface PageEditorPreviewProps extends IframeHTMLAttributes<HTMLIFrameElement> {
  onLoaded?: () => void;
  isHidden?: boolean;
  wrapperClassName?: string;
}

export function PageEditorPreview(props: PageEditorPreviewProps): JSX.Element {
  const { isHidden, src, onLoaded, className, wrapperClassName } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [previousSrc, setPreviousSrc] = useState(src);

  const onIframeLoaded = () => {
    setIsLoading(false);
    onLoaded && onLoaded();
  };

  useEffect(() => {
    setPreviousSrc(src);
    if (previousSrc !== src) {
      setIsLoading(true);
    }
  }, [src]);

  return (
    <div
      className={cn(css.preview, wrapperClassName, {
        [css.hidden]: isHidden,
      })}
    >
      <div
        className={cn(css.loader, {
          [css.hiddenLoader]: !isLoading,
        })}
      >
        <Loader className={css.loaderClassName} />
      </div>
      <PageEditorIframe src={src} className={className} onLoaded={onIframeLoaded} />
    </div>
  );
}
