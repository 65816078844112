import classNames from 'classnames';
import { createRef, useEffect, useState } from 'react';
import { RainbowBox, SwagButton, Typography } from '@ezetech/swag-space-x';

import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { markContractAsRead } from 'redux/slices/store-creation.slice';
import { usePatchStoreSettingsMutation } from 'redux/api/store-creation.api';
import { resetRedirectOptions } from 'redux/slices/user.slice';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import {
  currentStepIndexSelector,
  resellerSignContractAcceptBtnDisabledSelector,
  resellerSignContractSkipBtnDisabledSelector,
  resellerStoreAcceptBtnDisabledSelector,
  resellerStoreAcceptBtnTextSelector,
  resellerStoreContractReadSelector,
  resellerStoreSkipBtnDisabledSelector,
} from 'redux/selectors/store-creation.selectors';
import dropDown from '../../../assets/svg/drop-down-dark.svg';

import css from './sign-contract.module.scss';
import { Main } from './partials/main';
import { WhatWeDo } from './partials/what-we-do';
import { ApplicationForMembership } from './partials/application-for-membership';
import { Confidentiality } from './partials/confidentiality';
import { CustomerConfidentiality } from './partials/customer-confidentiality';
import { Changes } from './partials/changes';
import { BrandedStorefronts } from './partials/branded-storefronts';
import { Fees } from './partials/fees';
import { IntellectualProperty } from './partials/intellectual-property';
import { UnauthorizedUses } from './partials/unauthorized-uses';
import { ThirdPartySites } from './partials/third-party-sites';
import { TermAndTermination } from './partials/term-and-termination';
import { Disclaimer } from './partials/disclaimer';
import { DisputeResolution } from './partials/dispute-resolution';
import { General } from './partials/general';

const EVENT_TYPE = 'scroll';
const SCROLL_THRESHOLD = 50;

interface ISignContractProps {
  isOnboardingFlow?: boolean;
  redirectUrl?: string;
}

export function SignContractComponent({
  isOnboardingFlow = true,
  redirectUrl,
}: ISignContractProps): JSX.Element {
  const dispatch = useAppDispatch();
  const [isBottom, setPosition] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const acceptBtnText = useAppSelector(resellerStoreAcceptBtnTextSelector);
  const skipBtnDisabled = isOnboardingFlow
    ? useAppSelector(resellerStoreSkipBtnDisabledSelector)
    : useAppSelector(resellerSignContractSkipBtnDisabledSelector);
  const acceptBtnDisabled = isOnboardingFlow
    ? useAppSelector(resellerStoreAcceptBtnDisabledSelector)
    : useAppSelector(resellerSignContractAcceptBtnDisabledSelector);
  const resellerStoreContractRead = useAppSelector(resellerStoreContractReadSelector);
  const [patchStoreSettings] = usePatchStoreSettingsMutation();
  const navigate = useNavigate();
  const currentStepIndex = useAppSelector(currentStepIndexSelector);

  const contractRef = createRef<HTMLDivElement>();

  const onScroll = () => {
    const element = contractRef.current as HTMLDivElement;

    if (element) {
      element.scrollTop = isBottom ? 0 : element.scrollHeight;
    }
  };

  useEffect(() => {
    const element = contractRef.current as HTMLDivElement;

    dispatch(resetRedirectOptions());

    const scrollHandler = () => {
      if (element) {
        const diff = element.scrollHeight - element.scrollTop - element.offsetHeight;
        setPosition(diff < SCROLL_THRESHOLD);

        if (!resellerStoreContractRead && diff < SCROLL_THRESHOLD) {
          dispatch(markContractAsRead({ isContractRead: true }));
        }
      }
    };

    element.addEventListener(EVENT_TYPE, scrollHandler);

    return () => element.removeEventListener(EVENT_TYPE, scrollHandler);
  }, []);

  const onAcceptBtnClick = () => {
    if (!isOnboardingFlow) {
      setIsButtonDisabled(true);
    }
    patchStoreSettings({ currentStepIndex, isContractSigned: true, isOnboardingFlow });
  };

  const onSkipBtnClick = () => {
    patchStoreSettings({ currentStepIndex, isContractSigned: false, isOnboardingFlow });
    if (!isOnboardingFlow) {
      setIsButtonDisabled(true);
      if (redirectUrl) {
        window.location.href = redirectUrl;
      } else {
        navigate(-1);
      }
    }
  };

  const onDownloadCLick = () => {
    const link = document.createElement('a');
    link.href =
      'https://swag-prod.s3.us-east-2.amazonaws.com/shared-pdf/swag-space-terms-of-service.pdf';
    link.target = '_blank';
    link.download = 'Contract.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <section className={css.section}>
      <div className={css.textBlock}>
        <Typography lineHeight="100%" fontType="HEADING6XL" color={COLORS.colorBlack}>
          SIGN AND FINISH
        </Typography>

        <Typography
          className={css.mainHeading}
          lineHeight="100%"
          fontType="HEADING3XL"
          color={COLORS.colorTypographyHeadings}
        >
          Please READ AND SIGN OUR contract
        </Typography>

        <Typography
          lineHeight="162%"
          className={css.text}
          fontType="bodyMd"
          color={COLORS.colorTextSubdued}
        >
          In order to be a Swag Space member, you first need to sign the partnership
          agreement.
        </Typography>
      </div>

      <RainbowBox
        borderWidth={5}
        outerClassName={css.outerBoxClassName}
        innerClassName={css.innerBoxClassName}
      >
        <div className={css.contractTextWrapper}>
          <div className={css.textWrapper} ref={contractRef}>
            <Typography
              className={css.textBase}
              lineHeight="162%"
              fontType="bodyMd"
              color={COLORS.colorTextSubdued}
            >
              <Main />
              <WhatWeDo />
              <ApplicationForMembership />
              <Confidentiality />
              <CustomerConfidentiality />
              <Changes />
              <BrandedStorefronts />
              <Fees />
              <IntellectualProperty />
              <UnauthorizedUses />
              <ThirdPartySites />
              <TermAndTermination />
              <Disclaimer />
              <DisputeResolution />
              <General />
            </Typography>
          </div>

          <div className={css.buttons}>
            <Typography
              lineHeight="112%"
              fontType="bodyMd"
              color={COLORS.colorPink}
              underlined
            >
              <div onClick={onDownloadCLick}>Download a Copy</div>
            </Typography>

            <div className={css.buttonsContainer}>
              <SwagButton
                type="outlined"
                className={css.skipButton}
                size="large"
                onClick={onSkipBtnClick}
                disabled={skipBtnDisabled || isButtonDisabled}
                fullWidth
              >
                I’ll do this later
              </SwagButton>
              <SwagButton
                type="primary"
                className={css.acceptButton}
                size="large"
                onClick={onAcceptBtnClick}
                disabled={acceptBtnDisabled || isButtonDisabled}
                fullWidth
              >
                {acceptBtnText}
              </SwagButton>
            </div>
          </div>

          <div className={css.jumper} onClick={onScroll}>
            <img
              src={dropDown}
              alt="dropDown"
              className={classNames(css.arrow, { [css.isBottom]: isBottom })}
            />
          </div>
        </div>
      </RainbowBox>
    </section>
  );
}
