import { STEP_NAME, STORE_CREATION_ROUTES } from 'enums/store-creation.enum';
import { IUser } from 'interfaces/user-api.interface';
import { ProductSortingEnum } from './settings.interface';

export interface IStep {
  text: string;
  stepName: STEP_NAME;
  prevBtnText: string | null;
  nextBtnText: string;
  route: STORE_CREATION_ROUTES;
  routeRef: React.RefObject<HTMLDivElement>;
}
export interface IStoreSettingsBack {
  brandName?: null | string;
  homepageTitle?: null | string;
  logoLinkUrl?: null | string;
  categories?: CategoriesStructureWithSelections;
  isContractSigned?: null | boolean;
  isHomepageActive?: boolean;
  isWhatWeOfferActive?: boolean;
  logo?: Record<string, string> | null;
  favicon?: Record<string, string> | null;
  productsPricePoints?: IPricePoints | null;
  margin?: null | number;
  primaryColor?: string | null;
  secondaryColor?: string | null;
  socials?: ISocialBack[];
  hoursOperations?: IHoursOperationsBack[];
  categoriesStructure?: CategoriesStructure;
  intercomAppId?: string | null;
  timezone?: string | null;
  storeLink?: string | null;
  customCodeHeader?: string | null;
  customCodeBody?: string | null;
  termsOfServiceHTML?: string | null;
  privacyPolicyHTML?: string | null;
  customDomain?: ICustomDomain;
  owner?: IUser;
  assignmentFlowEnabled?: boolean;
  isReviewMockupsByCustomerAllowed?: boolean;
  isReviewPantonesByCustomerAllowed?: boolean;
  defaultSorting?: ProductSortingEnum | null;
}

export interface IPatchStoreSettings {
  brandName?: string;
  homepageTitle?: string;
  logoLinkUrl?: string;
  primaryColor?: string;
  secondaryColor?: string;
  intercomAppId?: string;
  categories?: CategoriesStructureWithSelections;
  pricePoints?: IPricePoints | null;
  logo?: Record<string, string>;
  margin?: number;
  isContractSigned?: boolean | null;
  isHomepageActive?: boolean;
  isWhatWeOfferActive?: boolean;
  social?: ISocialBack[];
  hoursOperations?: IHoursOperationsBack[];
  currentStepIndex: number;
  customCodeHeader?: string | null;
  customCodeBody?: string | null;
  isOnboardingFlow?: boolean;
}

export interface ISocialBack {
  name: string;
  link: string;
}

export interface IHoursOperationsBack {
  day: string;
  from: number | null;
  to: number | null;
}

export type CategoriesStructure = IParentCategory[];

export interface IParentCategory {
  id: string;
  name: string;
  publicId?: string;
  items: ICategory[];
}

export interface ICategory {
  id: string;
  name: string;
}

export type CategoriesStructureWithSelections = IParentCategoryWithSelections[];

export interface IParentCategoryWithSelections {
  id: string;
  name: string;
  publicId?: string;
  items: ICategoryWithSelections[];
  isEnabled: boolean;
}

export interface IPricePoints {
  isSuperInexpensive: boolean;
  isBudget: boolean;
  isStandard: boolean;
  isPremium: boolean;
}

export interface ICategoryWithSelections {
  isSelected: boolean;
  id: string;
  publicId?: string;
  name: string;
}

export interface IStoreSettings {
  [STEP_NAME.PRODUCT_SELECTION]: {
    categories: CategoriesStructureWithSelections;
    pricePoints: IPricePoints;
  };
  [STEP_NAME.COMMISSION]: {
    resellerMargin: number;
    defaultSorting?: ProductSortingEnum | null;
  };
  [STEP_NAME.PAGE_LINK]: { resellerStoreName: string };
  [STEP_NAME.SIGN_CONTRACT]: {
    isContractSigned: boolean | null;
    isContractRead: boolean;
  };
  [STEP_NAME.BRAND]: {
    primaryColor: string | undefined;
    secondaryColor: string | undefined;
    logo: Record<string, string> | undefined;
    favicon: Record<string, string> | undefined;
  };
}

export enum CUSTOM_DOMAIN_STATUSES {
  PENDING = 'PENDING',
  DELETED = 'DELETED',
  DNS_VERIFICATION_PENDING = 'DNS_VERIFICATION_PENDING',
  DNS_VERIFIED = 'DNS_VERIFIED',
  VERIFIED = 'VERIFIED',
  PROLONGING = 'PROLONGING',
}

export interface ICname {
  name?: string;
  value: string | string[];
}
export interface ICustomDomain {
  id: string;
  companyId: string;
  creatorId: string;
  domain: string;
  cnameRecord: ICname;
  aRecord: ICname;
  status: CUSTOM_DOMAIN_STATUSES;
  createdAt: Date;
  updatedAt: Date | null;
  deletedAt: Date | null;
  verifiedAt: Date | null;
  isSubdomain: boolean;
}

export enum CONNECT_DOMAIN_MODE {
  CREATE = 'create',
  EDIT = 'edit',
}
