import { IconType } from './type';

export const ChevronUp = ({
  className,
  fillColor = '#E73AF6',
  width = '20px',
  height = '20px',
}: IconType): JSX.Element => {
  const style = { width, height, lineHeight: 0 };
  return (
    <div style={style} className={className}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Icon Small">
          <path
            id="Icon/Back (Stroke)"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.5582 12.7604L10 12.1818L9.44176 12.7604C9.75007 13.0799 10.2499 13.0799 10.5582 12.7604ZM10 11.0247L6.34772 7.23964C6.03941 6.92012 5.53954 6.92012 5.23123 7.23964C4.92292 7.55916 4.92292 8.0772 5.23123 8.39672L9.44176 12.7604L10 12.1818L10.5582 12.7604L14.7688 8.39672C15.0771 8.0772 15.0771 7.55916 14.7688 7.23964C14.4605 6.92012 13.9606 6.92012 13.6523 7.23964L10 11.0247Z"
            fill={fillColor}
          />
        </g>
      </svg>
    </div>
  );
};
