import { useEffect, useState } from 'react';
import * as yup from 'yup';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DialogFooter, SwagButton } from '@ezetech/swag-space-x';
import { useDispatch } from 'react-redux';
import { InputForm } from 'components/input-form';
import { SelectForm } from 'components/select-from';
import { isRequired } from 'utils/yup.util';
import {
  AVAILABLE_COUNTRIES_LIST,
  getStates,
  CountriesStatesSelect,
} from 'constants/settings.constants';
import { closePopup, openPopup } from 'redux/slices/modals.slice';
import { filterEmptyProperties } from 'utils/filter-empty-properties.util';

import css from './customer-info.module.scss';
import { FIELDS } from './customer-info.constants';

import { customerFormSchema } from './customer-info.schema';

interface ICustomerInfoFormProps {
  presentationEmail: string;
  fromPresentationPage: boolean;
}

export function InvoiceCreationCustomerForm({
  presentationEmail,
  fromPresentationPage,
}: ICustomerInfoFormProps) {
  const dispatch = useDispatch();
  const { control, getValues, formState, handleSubmit, setValue, clearErrors } =
    useForm<FieldValues>({
      mode: 'onTouched',
      resolver: yupResolver(customerFormSchema),
    });
  const { isValid } = formState;
  const [statePlaceholder, setStatePlaceholder] = useState(FIELDS.state.placeholder);
  const [requiredFields, setRequiredFields] = useState<
    Record<string, yup.SchemaFieldDescription>
  >(customerFormSchema.describe().fields);

  useEffect(() => {
    setValue(FIELDS.country.name, CountriesStatesSelect.US);
    handleOnChange();
  }, []);

  useEffect(() => {
    setValue(FIELDS.customerEmail.name, presentationEmail);
  }, [presentationEmail]);

  const onBtnCloseClick = () => {
    dispatch(closePopup());
  };

  const handleOnChange = () => {
    const country = getValues(FIELDS.country.name);
    const state = getValues(FIELDS.state.name);

    const { fields } = customerFormSchema.describe({
      value: {
        [FIELDS.state.name]: state,
        [FIELDS.country.name]: country,
      },
    });

    if ([CountriesStatesSelect.CA, CountriesStatesSelect.US].includes(country)) {
      setValue(FIELDS.state.name, getStates(country)[0].value || '');
    } else {
      setValue(FIELDS.state.name, '');
    }

    clearErrors(FIELDS.state.name);

    if (!isRequired(fields[FIELDS.state.name])) {
      setStatePlaceholder('Enter state');
    } else {
      setStatePlaceholder(FIELDS.state.placeholder);
    }

    setRequiredFields(fields);
  };

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    const customerData = {
      customerEmail: data[FIELDS.customerEmail.name] || '',
      companyName: data[FIELDS.companyName.name] || '',
      country: data[FIELDS.country.name] || '',
      city: data[FIELDS.city.name] || '',
      zip: data[FIELDS.zip.name] || '',
      state: data[FIELDS.state.name] || '',
      address: data[FIELDS.address.name] || '',
    };

    dispatch(
      openPopup({
        popupName: 'INVOICE_CREATION_READY_POPUP',
        popupProps: {
          customerData: filterEmptyProperties<string>(customerData),
        },
      }),
    );
  };

  const countryRules = {
    onChange: handleOnChange,
  };

  return (
    <form className={css.form} onSubmit={handleSubmit(onSubmit)}>
      <div className={css.formFields}>
        <InputForm
          type="text"
          label={FIELDS.customerEmail.label}
          name={FIELDS.customerEmail.name}
          disabled={fromPresentationPage}
          placeholder={FIELDS.customerEmail.placeholder}
          required={isRequired(requiredFields[FIELDS.customerEmail.name])}
          control={control}
          labelClassName={css.label}
        />
        <div className={css.twoInRowContainer}>
          <InputForm
            className={css.input}
            type="text"
            label={FIELDS.companyName.label}
            name={FIELDS.companyName.name}
            placeholder={FIELDS.companyName.placeholder}
            required={isRequired(requiredFields[FIELDS.companyName.name])}
            control={control}
          />
          <SelectForm
            className={css.input}
            label={FIELDS.country.label}
            options={AVAILABLE_COUNTRIES_LIST}
            name={FIELDS.country.name}
            placeholder={FIELDS.country.placeholder}
            required={isRequired(requiredFields[FIELDS.country.name])}
            rules={countryRules}
            control={control}
          />
        </div>
        <div className={css.twoInRowContainer}>
          <InputForm
            className={css.input}
            label={FIELDS.address.label}
            name={FIELDS.address.name}
            placeholder={FIELDS.address.placeholder}
            required={isRequired(requiredFields[FIELDS.address.name])}
            control={control}
          />
          <InputForm
            className={css.input}
            label={FIELDS.city.label}
            name={FIELDS.city.name}
            placeholder={FIELDS.city.placeholder}
            required={isRequired(requiredFields[FIELDS.city.name])}
            control={control}
          />
        </div>
        <div className={css.twoInRowContainer}>
          {[CountriesStatesSelect.CA, CountriesStatesSelect.US].includes(
            getValues(FIELDS.country.name),
          ) ? (
            <SelectForm
              className={css.input}
              options={getStates(getValues(FIELDS.country.name))}
              label={FIELDS.state.label}
              name={FIELDS.state.name}
              placeholder={statePlaceholder}
              required={isRequired(requiredFields[FIELDS.state.name])}
              control={control}
            />
          ) : (
            <InputForm
              className={css.input}
              type="text"
              label={FIELDS.state.label}
              name={FIELDS.state.name}
              placeholder={statePlaceholder}
              required={isRequired(requiredFields[FIELDS.state.name])}
              control={control}
            />
          )}
          <InputForm
            className={css.input}
            type="text"
            label={FIELDS.zip.label}
            name={FIELDS.zip.name}
            placeholder={FIELDS.zip.placeholder}
            required={isRequired(requiredFields[FIELDS.zip.name])}
            control={control}
          />
        </div>
      </div>
      <DialogFooter className={css.footer}>
        <SwagButton type="outlined" className={css.btn} onClick={onBtnCloseClick}>
          Cancel
        </SwagButton>
        <SwagButton
          type="primary"
          className={css.btn}
          disabled={!isValid}
          htmlType="submit"
        >
          Continue
        </SwagButton>
      </DialogFooter>
    </form>
  );
}
