import { useState } from 'react';
import cn from 'classnames';
import {
  OptionType,
  RadioGroup,
  Radio,
  Select,
  Tooltip,
  TooltipPlacement,
  Typography,
} from '@ezetech/swag-space-x';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import {
  PAYMENT_METHODS,
  PAYMENT_METHODS_FREQUENCY,
  PAYMENT_METHODS_FREQUENCY_LABELS,
  PAYMENT_METHODS_LABELS,
  TAX_METHODS,
  TAX_METHODS_LABELS,
} from 'interfaces';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { useUpdateUserMutation } from 'redux/api/user.api';
import {
  customerPaymentMethodPopupInfoSelector,
  customerPaymentMethodSelector,
  editedCustomerCompanyDetailsSelector,
} from 'redux/selectors/customer-details.selector';
import { openPopup } from 'redux/slices/modals.slice';
import {
  CHANGE_PAYMENT_METHOD_INFORMATION_POPUP,
  PROOF_OF_TAX_EXEMPT_UPLOAD_POPUP,
} from 'components/popups/_logic/popups-list';
import { SUBJECTS } from 'constants/ability.constant';
import { can } from 'boot/ability';
import css from './details-payment-terms.module.scss';

const paymentMethodFrequencyOptions = Object.keys(PAYMENT_METHODS_FREQUENCY).map(
  (frequency) => ({
    value: frequency,
    label: PAYMENT_METHODS_FREQUENCY_LABELS[frequency],
  }),
);

export function DetailsPaymentTerms(): JSX.Element {
  const dispatch = useAppDispatch();
  const user = useAppSelector(editedCustomerCompanyDetailsSelector);
  const [updateUser] = useUpdateUserMutation();
  const { paymentMethod, paymentMethodFrequency } = useAppSelector(
    customerPaymentMethodSelector,
  );
  const subjectCustomerDetailsName = SUBJECTS.EDIT_CUSTOMERS_DETAILS.value;
  const subjectCustomerDetailsActions = SUBJECTS.EDIT_CUSTOMERS_DETAILS.actions;
  const hasAccessForProfileActions = can(
    subjectCustomerDetailsActions.EDIT_CUSTOMER_DETAILS_INFO,
    subjectCustomerDetailsName,
  );
  const paymentMethodOptions = Object.values(PAYMENT_METHODS).map((method) => ({
    value: method,
    label: PAYMENT_METHODS_LABELS[method],
    disabled: !hasAccessForProfileActions,
  }));
  const taxOptions = (Object.keys(TAX_METHODS) as (keyof typeof TAX_METHODS)[]).map(
    (method) => ({
      value: method,
      label: TAX_METHODS_LABELS[method],
      disabled: !hasAccessForProfileActions,
    }),
  );
  const [paymentMethodFrequencyValue, setPaymentMethodFrequency] =
    useState<OptionType>(paymentMethodFrequency);

  const [taxExemptValue, setTaxExemptValue] = useState(user.tax ?? taxOptions[0].value);

  const handlePaymentMethodFrequencyChange = async (option: OptionType) => {
    setPaymentMethodFrequency(option);

    await updateUser({
      id: user.userId,
      paymentMethodFrequency: option.value,
    });
  };

  const handleRadioChange = async (
    value: string,
    setRadioGroupValue: React.Dispatch<React.SetStateAction<string>>,
  ) => {
    if (value === paymentMethod.value) {
      return;
    }

    if (value === PAYMENT_METHODS.CREDIT_CARD) {
      await updateUser({
        id: user.userId,
        paymentMethod: value,
        setPaymentMethod: setRadioGroupValue,
      });

      return;
    }

    const paymentDescription = customerPaymentMethodPopupInfoSelector(value);

    dispatch(
      openPopup({
        popupName: CHANGE_PAYMENT_METHOD_INFORMATION_POPUP,
        popupProps: {
          description: paymentDescription.description,
          title: paymentDescription.title,
          paymentMethod: value,
          setPaymentMethod: setRadioGroupValue,
        },
      }),
    );
  };

  const handleTaxOptionChange = async (option: string): Promise<void> => {
    if (option === TAX_METHODS.TAX_EXEMPT && user.tax === TAX_METHODS.TAX_CHANGE) {
      dispatch(
        openPopup({
          popupName: PROOF_OF_TAX_EXEMPT_UPLOAD_POPUP,
          popupProps: {
            userId: user.userId,
            setTaxOption: setTaxExemptValue,
          },
        }),
      );
    }
  };

  return (
    <div className={css.block}>
      <div className={css.row}>
        <Typography
          className={css.heading}
          lineHeight="162%"
          fontType="bodyMd"
          color={COLORS.colorTextSubdued}
        >
          Payment Terms
        </Typography>
      </div>
      <div className={css.methods}>
        <RadioGroup
          value={paymentMethod.value}
          radioClassName={css.radio}
          options={paymentMethodOptions}
          className={css.radioWrapper}
          name="methods"
          onChange={handleRadioChange}
        />
      </div>
      <div className={css.column}>
        <Typography
          className={css.selectHeading}
          lineHeight="162%"
          fontType="bodyMd"
          color={COLORS.colorTextSubdued}
        >
          One time use / On going
        </Typography>
        <Select
          isDisabled={!hasAccessForProfileActions}
          className={css.select}
          value={paymentMethodFrequencyValue}
          onChange={(value) => handlePaymentMethodFrequencyChange(value as OptionType)}
          name="frequency"
          options={paymentMethodFrequencyOptions}
        />
      </div>
      <div className={css.column}>
        <Typography
          className={css.heading}
          lineHeight="162%"
          fontType="bodyMd"
          color={COLORS.colorTextSubdued}
        >
          Tax
        </Typography>
      </div>
      <div className={css.taxExemptWrapper}>
        <div className={cn(css.radioWrapper, css.taxRadioButtonsContainer)}>
          <Tooltip
            placement={TooltipPlacement.bottom}
            disabled={user.tax === TAX_METHODS.TAX_CHANGE || !hasAccessForProfileActions}
            content={
              <Radio
                key={taxOptions[0].value}
                onChange={handleTaxOptionChange}
                name={taxOptions[0].value}
                option={taxOptions[0].value}
                label={taxOptions[0].label}
                value={taxExemptValue}
                className={css.radio}
                disabled={taxOptions[0].disabled}
              />
            }
          >
            <Typography
              className={css.heading}
              lineHeight="162%"
              fontType="bodyMd"
              color={COLORS.colorTextSubdued}
            >
              {user.isTaxExemptPending
                ? "You can't select 'Charge tax' during this approval process"
                : "You can't select 'Charge tax' for Tax Exempt customer."}
            </Typography>
          </Tooltip>
          <Radio
            key={taxOptions[1].value}
            onChange={handleTaxOptionChange}
            name={taxOptions[1].value}
            option={taxOptions[1].value}
            label={taxOptions[1].label}
            value={taxExemptValue}
            disabled={taxOptions[1].disabled}
            className={css.radio}
          />
        </div>
        {user.isTaxExemptPending && (
          <Typography
            className={css.submissionPending}
            fontType="bodySm"
            lineHeight="143%"
            color={COLORS.colorTextSubdued}
          >
            (Submission Pending)
          </Typography>
        )}
      </div>
    </div>
  );
}
