import { FC, useEffect, useRef } from 'react';
import { Typography, Checkbox, Divider, SwagButton } from '@ezetech/swag-space-x';
import classNames from 'classnames';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import classes from '../edit-pdf-presentation-layout-popup.module.scss';
import { ContentSidebarProps } from '../interfaces/components';

export const ContentSidebar: FC<ContentSidebarProps> = ({
  pageTemplate,
  values,
  onProcess,
  createValueChangedHandler,
  visible,
  onSaveClicked,
  onCloseClicked,
  setIsExitBlocked,
  isExitBlocked,
}) => {
  const {
    name,
    isCanBeHidden,
    isHiddenKeyName,
    fieldsDefinition,
    additionalComponentBefore,
    additionalComponentAfter,
  } = pageTemplate;
  const isHidden =
    isCanBeHidden && isHiddenKeyName ? Boolean(values[isHiddenKeyName]) : false;
  const noEditableFields = fieldsDefinition.length === 0;
  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    scrollRef.current?.scrollTo(0, 0);
  }, [name, visible]);

  return (
    <div
      className={classNames(classes.contentSidebar, {
        [classes.hidden]: visible,
      })}
    >
      <div className={classes.contentSidebarHeader}>
        <div
          className={classNames(classes.contentSidebarHeaderTextWithoutHide, {
            [classes.contentSidebarHeaderText]: isCanBeHidden && isHiddenKeyName,
          })}
        >
          <Typography fontType="HEADING6XL" color={COLORS.colorPrimaryText}>
            {name}
          </Typography>
          {isCanBeHidden && isHiddenKeyName && (
            <Checkbox
              name={isHiddenKeyName}
              onCheckedChange={createValueChangedHandler(isHiddenKeyName)}
              checked={isHidden}
              label="Hide page"
            />
          )}
        </div>
      </div>
      <Divider withMargin={false} color={COLORS.colorNeural200} />
      <div
        ref={scrollRef}
        className={classNames(classes.contentSidebarFields, {
          [classes.contentSidebarFieldsHidden]: isHidden,
        })}
      >
        {noEditableFields && (
          <Typography
            lineHeight="162.5%"
            color={COLORS.colorSecondaryText}
            fontType="bodyMd"
          >
            This page is unable to be edited. This is just a preview of what your page
            will look like.
          </Typography>
        )}
        {additionalComponentBefore && additionalComponentBefore()}
        <div>
          {fieldsDefinition.map(({ component }) => {
            return component({
              onChangeValueCreator: createValueChangedHandler,
              onProcess: onProcess,
              values,
              setIsExitBlocked,
            });
          })}
        </div>
        {additionalComponentAfter && additionalComponentAfter()}
      </div>
      <div className={classes.sidebarButtonGroup}>
        <SwagButton
          type="outlined"
          onClick={onCloseClicked}
          className={classes.sidebarButtonGroupDiscardButton}
        >
          Exit
        </SwagButton>
        <SwagButton type="primary" onClick={onSaveClicked}>
          Save
        </SwagButton>
      </div>
    </div>
  );
};
