import {
  Dialog,
  DialogContentWrapper,
  DialogTitle,
  DialogDescription,
  DialogFooter,
  SwagButton,
  Typography,
} from '@ezetech/swag-space-x';
import { WithdrawSuccessPopupProps } from 'interfaces/popup.interface';
import { useAppDispatch } from 'redux/store';
import { closePopup } from 'redux/slices/modals.slice';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import css from './withdrawal-success.popup.module.scss';

export const WithdrawSuccessPopup = ({
  isOpen,
}: WithdrawSuccessPopupProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const onBtnClick = () => {
    dispatch(closePopup());
  };

  return (
    <Dialog open={isOpen} onOpenChange={onBtnClick}>
      <DialogContentWrapper
        className={css.dialogContentWrapperClassName}
        overlayClassName={css.dialogOverlayClassName}
      >
        <DialogTitle>Withdrawal Initiated</DialogTitle>
        <DialogDescription className={css.textWrapper}>
          <Typography color={COLORS.colorTextSubdued} fontType="bodyMd">
            We will have somebody on our team reach out to you in the next 1-2 business
            days. We are looking forward to helping you out with this.
          </Typography>
        </DialogDescription>

        <DialogFooter>
          <SwagButton type="primary" onClick={onBtnClick} className={css.btn}>
            Close
          </SwagButton>
        </DialogFooter>
      </DialogContentWrapper>
    </Dialog>
  );
};
