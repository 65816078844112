import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IFile } from '@ezetech/swag-space-x';
import { ReducerPath } from 'interfaces';
import { makeToastNotification, makeSuccessToastNotification } from 'utils/query.util';
import { setCertificates, setLoading } from 'redux/slices/reseller-certificate.slice';
import {
  IResellerCertificatesResponse,
  IGetResellerCertificatesRequest,
} from 'interfaces/reseller-certificate.interfaces';
import { closePopup } from 'redux/slices/modals.slice';

import { pushNotification } from '../slices/notifications.slice';
import { baseQueryParams } from './helpers';

export const resellerCertificatesApi = createApi({
  reducerPath: ReducerPath.resellerCertificates,
  baseQuery: fetchBaseQuery({ ...baseQueryParams }),
  endpoints: (builder) => ({
    getCertificates: builder.mutation<
      IResellerCertificatesResponse,
      IGetResellerCertificatesRequest
    >({
      query: ({ page }) => {
        return {
          url: '/reseller-certificates',
          method: 'GET',
          params: {
            page,
            perPage: 10,
          },
        };
      },

      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          dispatch(setLoading(true));
          const { data } = await queryFulfilled;
          if (data) {
            const { list, total, page, perPage } = data;
            dispatch(setCertificates({ list, total, page, perPage }));
          }
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        } finally {
          dispatch(setLoading(false));
        }
      },
    }),

    deleteCertificate: builder.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `/reseller-certificates/${id}`,
        method: 'DELETE',
      }),
      async onQueryStarted(_, { dispatch, getState, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(closePopup());
          dispatch(
            pushNotification(
              makeSuccessToastNotification('Certificate has been successfully deleted'),
            ),
          );
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),

    createCertificate: builder.mutation<void, { state: string; document: IFile }>({
      query: ({ state, document }) => ({
        url: '/reseller-certificates/',
        method: 'POST',
        body: {
          state,
          document: {
            url: document.url,
            originalname: document.name,
            size: document.size,
            key: document.key,
            type: document.type,
            etag: document.etag,
          },
        },
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(closePopup());
          dispatch(
            pushNotification(
              makeSuccessToastNotification('Certificate has been successfully added'),
            ),
          );
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),

    uploadCertificateDocument: builder.mutation<IFile, FormData>({
      query: (data) => ({
        url: '/reseller-certificates/upload-document',
        method: 'POST',
        body: data,
      }),

      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),
  }),
});

export const {
  useGetCertificatesMutation,
  useDeleteCertificateMutation,
  useUploadCertificateDocumentMutation,
  useCreateCertificateMutation,
} = resellerCertificatesApi;
