import { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { Typography } from '@ezetech/swag-space-x';
import { ROUTES } from 'constants/router';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import burgerMenu from 'assets/svg/burger.svg';
import { HeaderMenu } from '../header-menu';
import { HeaderSideMenu } from '../header-side-menu';
import cellphone from '../../assets/svg/cellphone.svg';
import { LogoIcon } from '../svg';
import { HeaderProps } from './header.interface';
import css from './header.module.scss';

export function HeaderComponent({
  headerWrapperClassName,
  headerClassName,
}: HeaderProps): JSX.Element {
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
  const handleOnClose = useCallback(() => {
    setIsSideMenuOpen(false);
  }, []);

  const onBurgerClick = useCallback(() => {
    setIsSideMenuOpen(true);
  }, []);

  return (
    <div className={cn(css.header, headerClassName)}>
      <div className={cn(css.headerWrapper, headerWrapperClassName)}>
        <Link to={ROUTES.INDEX} className={css.logo}>
          <LogoIcon className={css.logo} />
        </Link>

        <a href="tel:+12078505069" className={css.noLink}>
          <img src={cellphone} className={css.cellphone} alt="cellphone" />
          <span className={css.phoneNumber}>
            <Typography lineHeight="162%" fontType="bodySm" color={COLORS.colorWhite}>
              207-850-5069
            </Typography>
          </span>
        </a>
        <span className={css.divider} />
        <HeaderMenu className={css.headerMenu} />
        <div className={css.burgerMenu}>
          <img
            className={css.burgerIcon}
            src={burgerMenu}
            alt="menu icon"
            onClick={onBurgerClick}
          />
          <HeaderSideMenu open={isSideMenuOpen} onClose={handleOnClose} />
        </div>
      </div>
    </div>
  );
}
