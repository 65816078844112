import cn from 'classnames';
import { Typography } from '@ezetech/swag-space-x';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { useClipboard } from 'hooks/use-clipboard.hook';
import { useNotifications } from 'hooks/notifications.hook';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { NotificationType } from 'redux/slices/notifications.slice';
import { setEditedUser } from 'redux/slices/customer-details.slice';
import { customerCompanyDetailsSelector } from 'redux/selectors/customer-details.selector';
import { openPopup } from 'redux/slices/modals.slice';
import { EDIT_CUSTOMER_POPUP } from 'components/popups/_logic/popups-list';
import { CopyIcon } from 'components/svg/copy';
import { SUBJECTS } from 'constants/ability.constant';
import { can } from 'boot/ability';
import css from './details-company.module.scss';

export function DetailsCompany(): JSX.Element {
  const dispatch = useAppDispatch();

  const { copy } = useClipboard();
  const { pushNotification } = useNotifications();
  const {
    email,
    companyName,
    companyId,
    phone,
    userId,
    address,
    city,
    country,
    zip,
    state,
  } = useAppSelector(customerCompanyDetailsSelector);

  const subjectEditCustomerDetailsName = SUBJECTS.EDIT_CUSTOMERS_DETAILS.value;
  const subjectEditCustomerDetailsActions = SUBJECTS.EDIT_CUSTOMERS_DETAILS.actions;

  const handleEmailClick = async () => {
    const isSuccess = await copy(email);

    if (isSuccess) {
      pushNotification({
        status: 'positive',
        message: 'Email has been copied.',
        type: NotificationType.toasts,
      });
    }
  };

  const handleEditClick = () => {
    dispatch(openPopup({ popupName: EDIT_CUSTOMER_POPUP, popupProps: {} }));
    dispatch(setEditedUser());
  };

  const formatAddress = () => {
    if (!address && !country && !city) {
      return 'N/A';
    }

    return `${address}, ${country} ${state}, ${city} ${zip}`;
  };

  return (
    <div className={css.block}>
      <div className={css.row}>
        <div className={css.smallColumn}>
          <Typography
            className={css.title}
            lineHeight="162%"
            fontType="bodyMd"
            color={COLORS.colorTextSubdued}
          >
            Company
          </Typography>
          <span
            data-company-id={companyId}
            data-company-name={companyName}
            className={css.truncate}
          >
            <Typography
              lineHeight="162%"
              fontType="bodyMd"
              color={COLORS.colorPrimaryText}
            >
              {companyName}
            </Typography>
          </span>
        </div>
        <div className={css.bigColumn}>
          <Typography
            className={css.title}
            lineHeight="162%"
            fontType="bodyMd"
            color={COLORS.colorTextSubdued}
          >
            Phone
          </Typography>
          <span data-phone={phone} className={css.truncate}>
            <Typography
              lineHeight="162%"
              fontType="bodyMd"
              color={COLORS.colorPrimaryText}
            >
              {phone}
            </Typography>
          </span>
        </div>

        {can(
          subjectEditCustomerDetailsActions.EDIT_CUSTOMER_DETAILS_INFO,
          subjectEditCustomerDetailsName,
        ) && (
          <span onClick={handleEditClick}>
            <Typography
              className={css.edit}
              lineHeight="162%"
              fontType="bodyMd"
              color={COLORS.colorPink}
              link
            >
              Edit all
            </Typography>
          </span>
        )}
      </div>
      <div className={cn(css.row, css.reverse)}>
        <div className={css.smallColumn}>
          <Typography
            className={css.title}
            lineHeight="162%"
            fontType="bodyMd"
            color={COLORS.colorTextSubdued}
          >
            Address
          </Typography>
          <span data-address={address} className={css.address}>
            <Typography
              lineHeight="162%"
              fontType="bodyMd"
              color={COLORS.colorPrimaryText}
            >
              {formatAddress()}
            </Typography>
          </span>
        </div>
        <div className={css.bigColumn}>
          <Typography
            className={css.title}
            lineHeight="162%"
            fontType="bodyMd"
            color={COLORS.colorTextSubdued}
          >
            Email
          </Typography>
          <div
            className={css.emailWrapper}
            data-user-id={userId}
            data-email={email}
            onClick={handleEmailClick}
          >
            <Typography
              className={css.truncate}
              lineHeight="162%"
              fontType="bodyMd"
              color={COLORS.colorPink}
              link
            >
              {email}
            </Typography>
            <CopyIcon className={css.copy} />
          </div>
        </div>
      </div>
    </div>
  );
}
