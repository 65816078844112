export function marginCustomerPriceFn(marginPercent: number): number {
  return Number(7 + 7 * (marginPercent / (100 - marginPercent)) || 1) * 100;
}

export function marginResellerIncomeFn(
  marginPercent: number,
  marginCustomerPrice: number,
): number {
  return Number((marginPercent * marginCustomerPrice) / 100) || 0;
}
