import { FC } from 'react';
import { Typography } from '@ezetech/swag-space-x';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { makeBold } from '../sign-contract.util';

export const WhatWeDo: FC = () => {
  return (
    <>
      <Typography color={COLORS.colorPrimaryText} fontType="bodyMdBold">
        1. What We Do
      </Typography>
      <br />
      <br />
      We provide an online custom merchandise commerce platform for you to sell customized
      products ({makeBold('“Customized Products”')}). We also provide the related
      printing, fulfillment, and, if selected, inventory services. Using the Services, you
      can sell Customized Products to your customers through a Branded Storefront. We
      provide the blank merchandise products (products that are blank, prior to being
      imprinted upon) ({makeBold('“Blank Products”')}), the customization services (i.e.,
      printing services), the delivery of final Customized Products to your customers and
      if selected, certain inventory services as described below. You are responsible for
      managing your Branded Storefront, the contract terms with your customers and all
      aspects of customer service with your customers, including, but not limited to,
      generating business, marketing and advertising, providing promotional discounts (at
      your discretion), and handling any order issues, returns and refunds. You are
      responsible for all Customer Content submitted to us for the Customized Products
      (more on this below).
      <br />
      <br />
    </>
  );
};
