import React, { useEffect, useState, useRef } from 'react';
import { Typography, Tooltip, TooltipPlacement } from '@ezetech/swag-space-x';
import { capitalize } from 'utils/string.util';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import {
  IResellerCertificate,
  ResellerCertificateStatus,
} from 'interfaces/reseller-certificate.interfaces';
import { getTimeMmmDdYyyy } from 'utils/date/date-formatter.util';
import trashIcon from 'assets/svg/trash-v1.svg';
import { getStates } from 'constants/settings.constants';

import css from './reseller-certificates-item.module.scss';

export type ResellerCertificatesItemType = Pick<
  IResellerCertificate,
  'id' | 'state' | 'status' | 'createdAt' | 'expiredAt' | 'document'
> & {
  key: string;
};

export type ResellerCertificatesItemProps = ResellerCertificatesItemType & {
  onDelete: (id: string) => void;
  isDeleteHidden: boolean;
};

const getIsExpired = (date: string) => {
  return new Date(date).getTime() < new Date().getTime();
};

const getStatusColor = (status: string) => {
  switch (status) {
    case ResellerCertificateStatus.APPROVED:
      return COLORS.colorSuccess700;
    case ResellerCertificateStatus.PENDING:
      return COLORS.colorPending700;
    case ResellerCertificateStatus.REJECTED:
      return COLORS.colorDestructive700;
    default:
      return COLORS.colorSuccess700;
  }
};

const ExtractIdFromS3Link = (link: string): string => {
  const parts = link.split('/');
  const id = parts[parts.length - 1];
  return id.split('.')[0];
};

export const ResellerCertificatesItem: React.FC<ResellerCertificatesItemProps> = ({
  id,
  state,
  status: originStatus,
  document,
  createdAt,
  expiredAt,
  onDelete,
  isDeleteHidden = false,
}) => {
  const isExpired =
    expiredAt && ResellerCertificateStatus.APPROVED === originStatus
      ? getIsExpired(expiredAt)
      : false;
  const statusColor = isExpired ? COLORS.colorText500 : getStatusColor(originStatus);
  const colorText = isExpired ? COLORS.colorText500 : COLORS.colorText900;
  const status = isExpired ? ResellerCertificateStatus.EXPIRED : originStatus;
  const date = getTimeMmmDdYyyy(new Date(createdAt), {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });
  const expiresDate =
    expiredAt && ResellerCertificateStatus.REJECTED !== originStatus
      ? getTimeMmmDdYyyy(new Date(expiredAt), {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
          timeZone: 'UTC',
        })
      : '';
  const expiresDateText =
    expiresDate &&
    (ResellerCertificateStatus.EXPIRED === status
      ? expiresDate
      : `(Expires ${expiresDate})`);

  const [stateName, setStateName] = useState('');
  const [isDisabledTooltip, setIsDisabledTooltip] = useState(false);
  const tooltipWrapperRef = useRef<HTMLDivElement>(null);
  const linkRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    const availableStates = getStates();
    const stateValue = availableStates.find(({ value }) => value === state) ?? null;
    setStateName(stateValue?.label || state);
  }, [state]);

  useEffect(() => {
    if (!tooltipWrapperRef.current || !linkRef.current) {
      return;
    }
    const disabledTooltip =
      tooltipWrapperRef.current.offsetWidth >= linkRef.current.offsetWidth;
    setIsDisabledTooltip(disabledTooltip);
  }, [tooltipWrapperRef.current?.offsetWidth, linkRef.current?.offsetWidth]);

  const onDeleteClick = () => {
    onDelete && onDelete(id);
  };

  return (
    <div className={css.item} data-certificate={ExtractIdFromS3Link(document.url)}>
      <div className={css.itemRow}>
        <Typography
          className={css.itemRowState}
          color={colorText}
          fontType="bodyMdBold"
          lineHeight="162.5%"
        >
          {stateName}
        </Typography>
        <div className={css.itemRowStatus}>
          <Typography color={statusColor} fontType={'bodyMd'} lineHeight="162.5%">
            {capitalize(status.toLowerCase())}
          </Typography>
          <Typography color={COLORS.colorText500} fontType={'bodyMd'} lineHeight="162.5%">
            {expiresDateText}
          </Typography>
        </div>
      </div>
      <div className={css.itemRow}>
        <Typography color={colorText} fontType={'bodyMd'} lineHeight="162.5%">
          Uploaded on {date}
        </Typography>
        <div className={css.itemRowLinks}>
          <Typography color={colorText} fontType={'bodyMd'} lineHeight="162.5%">
            Uploaded:
          </Typography>
          <div className={css.tooltipWrapper} ref={tooltipWrapperRef}>
            <Tooltip
              disabled={isDisabledTooltip}
              triggerClassName={css.tooltipTrigger}
              bodyClassName={css.tooltipBody}
              placement={TooltipPlacement.top}
              content={
                <a
                  href={document.url}
                  target="_blank"
                  className={css.linkHref}
                  ref={linkRef}
                >
                  <Typography
                    link
                    color={COLORS.colorAccent500}
                    fontType="bodyMdBold"
                    lineHeight="162.5%"
                    className={css.link}
                  >
                    {document.originalname}
                  </Typography>
                </a>
              }
            >
              {document.originalname}
            </Tooltip>
          </div>
          {!isDeleteHidden && (
            <img
              src={trashIcon}
              className={css.trashIcon}
              alt="trash"
              onClick={onDeleteClick}
            />
          )}
        </div>
      </div>
    </div>
  );
};
