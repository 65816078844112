import cn from 'classnames';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { editorStateToHtml } from './rich-text-editor.utils';

import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import css from './rich-text-editor.module.scss';

type RichTextEditorProps = {
  editorWrapper?: string;
  editorText?: string;
  onChange?: (html: string, editorState: EditorState) => void;
  options?: [];
  editorState: EditorState;
};

export const RichTextEditor = ({
  editorWrapper,
  editorText,
  onChange,
  options,
  editorState,
}: RichTextEditorProps) => {
  const handleStateChange = (changedEditorState: EditorState) => {
    const htmlResult = editorStateToHtml(changedEditorState);
    onChange && onChange(htmlResult, changedEditorState);
  };

  const toolbar = {
    options: options || ['inline', 'blockType', 'textAlign', 'link', 'history'],
    inline: {
      inDropdown: false,
      options: ['bold', 'italic', 'underline', 'strikethrough'],
    },
    blockType: {
      inDropdown: true,
      options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
    },
  };

  return (
    <Editor
      wrapperClassName={cn(css.editorWrapper, editorWrapper)}
      editorClassName={cn(css.editorText, editorText)}
      editorState={editorState}
      toolbar={toolbar}
      onEditorStateChange={handleStateChange}
    />
  );
};
