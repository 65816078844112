import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { ReducerPath } from 'interfaces';
import { pushNotification } from 'redux/slices/notifications.slice';
import { makeToastNotification } from 'utils/query.util';
import { setWithdrawData } from 'redux/slices/withdraw.slice';
import { baseQueryParams } from './helpers';

export const withdrawApi = createApi({
  reducerPath: ReducerPath.withdraw,
  baseQuery: fetchBaseQuery({
    ...baseQueryParams,
  }),
  endpoints: (builder) => ({
    requestWithdraw: builder.mutation<
      {
        pending: number;
        available: number;
        incoming: number;
      },
      void
    >({
      query: () => ({ url: '/withdrawal/initiate', method: 'POST' }),

      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(setWithdrawData(data));
          }
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),
    getWithdraw: builder.mutation<
      {
        pending: number;
        available: number;
        incoming: number;
      },
      void
    >({
      query: () => ({ url: '/withdrawal', method: 'GET' }),

      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(setWithdrawData(data));
          }
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),
  }),
});

export const { useRequestWithdrawMutation, useGetWithdrawMutation } = withdrawApi;
