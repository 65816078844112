import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAssignedForReseller } from 'interfaces/assignee.interface';
import { IInvoice } from 'interfaces/invoice.interface';

interface IInvoiceState {
  invoices: IInvoice[];
  total: number;
  clipboardLink: string | null;
}
const initialState: IInvoiceState = {
  invoices: [],
  total: 0,
  clipboardLink: null,
};

export const invoicesSlice = createSlice({
  initialState,
  name: 'cartsSlice',
  reducers: {
    reset: () => initialState,
    setInvoices: (
      state,
      action: PayloadAction<{
        invoices: IInvoiceState['invoices'];
        total: IInvoiceState['total'];
      }>,
    ) => {
      if (!action.payload) {
        return state;
      }
      state.invoices = action.payload.invoices;
      state.total = action.payload.total;
    },
    setClipboardLink: (
      state,
      action: PayloadAction<{ invoiceId: string; link: string | null }>,
    ) => {
      state.clipboardLink = action.payload.link;
      const index = state.invoices.findIndex(
        (invoice) => invoice.id === action.payload.invoiceId,
      );
      if (index !== -1) {
        state.invoices[index].inventoryContract = action.payload.link ?? '';
      }
    },
    setAssigneeToInvoiceById: (
      state,
      action: PayloadAction<{ entityId: string; assignee: IAssignedForReseller | null }>,
    ) => {
      const { entityId, assignee } = action.payload;

      const index = state.invoices.findIndex((invoice) => invoice.id === entityId);

      if (index !== -1) {
        state.invoices[index].assignee = assignee;
      }
    },
  },
});

export const invoicesReducer = invoicesSlice.reducer;
export const { reset, setInvoices, setClipboardLink, setAssigneeToInvoiceById } =
  invoicesSlice.actions;
