import cn from 'classnames';
import { PropsWithChildren } from 'react';

import css from './layout.module.scss';

type PageEditorLayoutProps = PropsWithChildren & {
  className?: string;
};

export const PageEditorLayout = (props: PageEditorLayoutProps): JSX.Element => {
  const { children, className } = props;
  return <div className={cn(css.layout, className)}>{children}</div>;
};
