import { ResellerMockupApprovalPopupProps } from 'interfaces/popup.interface';
import { IButtonProps } from './buttons';

export const POPUP_MODES = {
  NORMAL: 'NORMAL',
  COMPARE: 'COMPARE',
  REVISIONS: 'REVISIONS',
};

const SUBMIT_REVISIONS_TEXT = 'Submit Revisions';
const APPROVE_TEXT = 'Approve';
const CANCEL_REVISIONS_TEXT = 'Cancel Revisions';
const BACK_TO_APPROVAL_TEXT = 'Back to Approval';
const REQUEST_REVISIONS_TEXT = 'Request Revisions';

export const getRevisionsData = ({
  mode,
  itemId,
  orderId,
  setMode,
  prevMode,
  requestText,
  rejectMockup,
  approveMockup,
  setRequestText,
  productionMockup,
  isReviewMockupsByCustomerAllowed,
}: {
  mode: string;
  requestText: string;
  orderId: string;
  itemId: string;
  prevMode: string;
  productionMockup: ResellerMockupApprovalPopupProps['productionMockup'];
  setMode: (mode: string) => void;
  setRequestText: (text: string) => void;
  approveMockup: (data: {
    orderId: string;
    itemId: string;
    mockupId: string;
    isReviewMockupsByCustomerAllowed: boolean;
  }) => void;
  rejectMockup: (data: {
    orderId: string;
    itemId: string;
    mockupId: string;
    changeRequest: string;
  }) => void;
  isReviewMockupsByCustomerAllowed?: boolean;
}): IButtonProps => {
  switch (mode) {
    case POPUP_MODES.REVISIONS: {
      const trimmedRequestText = requestText.trim();
      return {
        primaryBtnText: SUBMIT_REVISIONS_TEXT,
        isPrimaryBtnDisabled: !trimmedRequestText.length,
        shouldRenderPrimaryBtn: true,
        onPrimaryBtnClick: () => {
          rejectMockup({
            orderId,
            itemId,
            mockupId: productionMockup!.id,
            changeRequest: trimmedRequestText,
          });
        },
        secondaryBtnText: CANCEL_REVISIONS_TEXT,
        isSecondaryBtnDisabled: false,
        shouldRenderSecondaryBtn: true,
        onSecondaryBtnClick: () => {
          setRequestText('');
          setMode(POPUP_MODES.NORMAL);
        },
        setMode,
        shouldRenderCompareLink: false,
      };
    }

    case POPUP_MODES.COMPARE: {
      return {
        primaryBtnText: SUBMIT_REVISIONS_TEXT,
        isPrimaryBtnDisabled: true,
        shouldRenderPrimaryBtn: false,
        onPrimaryBtnClick: () => null,
        secondaryBtnText: BACK_TO_APPROVAL_TEXT,
        isSecondaryBtnDisabled: false,
        shouldRenderSecondaryBtn: true,
        onSecondaryBtnClick: () => {
          setMode(prevMode || POPUP_MODES.NORMAL);
        },
        setMode,
        shouldRenderCompareLink: false,
      };
    }

    default: {
      return {
        primaryBtnText: APPROVE_TEXT,
        isPrimaryBtnDisabled: false,
        shouldRenderPrimaryBtn: true,
        onPrimaryBtnClick: () => {
          approveMockup({
            orderId,
            itemId,
            mockupId: productionMockup!.id,
            isReviewMockupsByCustomerAllowed: Boolean(isReviewMockupsByCustomerAllowed),
          });
        },
        secondaryBtnText: REQUEST_REVISIONS_TEXT,
        isSecondaryBtnDisabled: false,
        shouldRenderSecondaryBtn: true,
        onSecondaryBtnClick: () => {
          setMode(POPUP_MODES.REVISIONS);
        },
        setMode,
        shouldRenderCompareLink: true,
      };
    }
  }
};
