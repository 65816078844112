import { createRef, FormEvent, useEffect } from 'react';
import { RainbowBox, Typography } from '@ezetech/swag-space-x';
import { CSSTransition } from 'react-transition-group';

import { useAppDispatch, useAppSelector } from 'redux/store';
import { MAX_LINK_CHARACTERS } from 'constants/store-creation.constants';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import {
  resellerStoreNameErrorSelector,
  resellerStoreNameSelector,
} from 'redux/selectors/store-creation.selectors';
import { setResellerStoreName } from 'redux/slices/store-creation.slice';

import css from './page-link.module.scss';

const animationClasses = {
  enter: css.enter,
  enterActive: css.enterActive,
  exit: css.exit,
  exitActive: css.exitActive,
};

export function PageLinkComponent(): JSX.Element {
  const dispatch = useAppDispatch();
  const error = useAppSelector(resellerStoreNameErrorSelector);
  const resellerStoreName = useAppSelector(resellerStoreNameSelector);

  const errorRef = createRef<HTMLSpanElement>();
  const labelRef = createRef<HTMLLabelElement>();

  const onInputHandler = (e: FormEvent<HTMLInputElement>) => {
    const value = (e.target as HTMLInputElement).value;

    dispatch(setResellerStoreName({ resellerStoreName: value.toLowerCase() }));
  };

  useEffect(() => {
    const elem = labelRef.current;
    if (elem) {
      elem.dataset.value = resellerStoreName;
    }
  }, [resellerStoreName]);

  return (
    <section className={css.section}>
      <div className={css.textBlock}>
        <Typography lineHeight="100%" fontType="HEADING6XL" color={COLORS.colorBlack}>
          Own your brand
        </Typography>

        <Typography
          className={css.mainHeading}
          lineHeight="100%"
          fontType="HEADING3XL"
          color={COLORS.colorTypographyHeadings}
        >
          Create your custom URL!
        </Typography>

        <Typography
          lineHeight="162%"
          className={css.text}
          fontType="bodyMd"
          color={COLORS.colorTypographyBody}
        >
          This is the page that your customers will be able to order swag. This is your
          Swag Space.
        </Typography>
      </div>

      <RainbowBox
        borderWidth={5}
        outerClassName={css.outerBoxClassName}
        innerClassName={css.innerBoxClassName}
      >
        <Typography
          className={css.storeName}
          lineHeight="100%"
          fontType="bodyLg"
          color={COLORS.colorPrimaryText}
        >
          <label className={css.inputSizer} ref={labelRef}>
            <input
              type="text"
              maxLength={MAX_LINK_CHARACTERS + 1}
              onInput={onInputHandler}
              value={resellerStoreName}
              className={css.storePrefixName}
              size={10}
              placeholder="Enter brand"
            />
          </label>
          .swag.space
        </Typography>

        <CSSTransition
          in={!!error}
          classNames={animationClasses}
          timeout={500}
          nodeRef={errorRef}
          unmountOnExit
        >
          <Typography
            className={css.errorText}
            lineHeight="120%"
            fontType="bodySm"
            color={COLORS.colorErrorText}
          >
            <span ref={errorRef}>{error}</span>
          </Typography>
        </CSSTransition>
      </RainbowBox>
    </section>
  );
}
