import { createSelector } from 'reselect';
import { RootState } from 'redux/store';
import {
  ABANDONED_CHECKOUTS_CARTS_TABLE_HEADER,
  ABANDONED_CHECKOUTS_CARTS_TABLE_ROW,
  AbandonedCheckoutsCartsFields,
  ITransformAbandonedCheckoutsCartCell,
  ITransformAbandonedCheckoutsCartRow,
  ABANDONED_CHECKOUTS_CARTS_FILTERS,
  ABANDONED_CHECKOUTS_STATUS_LABELS,
} from 'interfaces/abandoned-checkouts-cart.interfaces';
import { Price } from 'utils/price.util';
import { PER_PAGE_CARTS } from 'constants/carts.constant';
import { can } from 'boot/ability';
import { SUBJECTS } from 'constants/ability.constant';
import { ASSIGNED_TO_ME_ENTITY } from 'constants/common';
import {
  searchFiltersSelector,
  dropdownFiltersSelector,
  pageFiltersSelector,
} from 'redux/selectors/filters.selectors';
import { pluralize } from 'utils/string.util';
import { assignmentFlowEnabledSelector } from './settings.selectors';

export const abandonedCheckoutCartsSelector = (state: RootState) =>
  state.abandonedCheckoutsCarts;

export const abandonedCheckoutCartsListSelector = createSelector(
  abandonedCheckoutCartsSelector,
  (state) => state.carts,
);

export const abandonedCheckoutHeaderSelector = createSelector(
  assignmentFlowEnabledSelector,
  (isOrderAssignmentEnabled) => {
    if (!isOrderAssignmentEnabled) {
      return ABANDONED_CHECKOUTS_CARTS_TABLE_HEADER.filter(
        (field) => field.id !== AbandonedCheckoutsCartsFields.assignTo,
      );
    }
    return ABANDONED_CHECKOUTS_CARTS_TABLE_HEADER;
  },
);

export const abandonedCheckoutCartsRowsSelector = createSelector(
  abandonedCheckoutCartsListSelector,
  pageFiltersSelector,
  abandonedCheckoutHeaderSelector,
  (list, page, abandonedCheckoutHeader) => {
    const startIndexingFrom = (page - 1) * PER_PAGE_CARTS;
    const rows: ITransformAbandonedCheckoutsCartRow[] = [];

    list.forEach((cart, position) => {
      const cells: ITransformAbandonedCheckoutsCartCell[] = [];

      abandonedCheckoutHeader.forEach((cell) => {
        const common = ABANDONED_CHECKOUTS_CARTS_TABLE_ROW[cell.id] || {};

        const assignedToUser = cart?.assignee
          ? {
              value: cart.assignee.id,
              label: `${cart?.assignee.firstName} ${cart?.assignee.lastName}`,
            }
          : null;

        const status = cart?.abandonedCheckoutsStatus
          ? {
              value: cart.abandonedCheckoutsStatus,
              label: ABANDONED_CHECKOUTS_STATUS_LABELS[cart.abandonedCheckoutsStatus],
            }
          : null;
        const cellParams = { ...common, rowId: cart.id, status };

        const id = cell.id as AbandonedCheckoutsCartsFields;
        const cartObject: Record<string, unknown> = { ...cart };

        if (cell.id === AbandonedCheckoutsCartsFields.id) {
          cells.push({
            ...cellParams,
            id: cell.id,
            text: `#${position + startIndexingFrom + 1}`,
          });
          return;
        }

        if (cell.id === AbandonedCheckoutsCartsFields.cartTotal) {
          cells.push({
            ...cellParams,
            text: Price.formatPrice(cart.cartTotal),
            id: AbandonedCheckoutsCartsFields.cartTotal,
          });
          return;
        }
        if (cell.id === AbandonedCheckoutsCartsFields.products) {
          cells.push({
            ...cellParams,
            text: `${cart?.items?.length} ${pluralize(
              cart?.items?.length,
              'Product',
              's',
              true,
            )}`,
            id: AbandonedCheckoutsCartsFields.products,
          });
          return;
        }

        if (cell.id === AbandonedCheckoutsCartsFields.assignTo) {
          cells.push({
            ...cellParams,
            id: AbandonedCheckoutsCartsFields.assignTo,
            text: '',
            assignedToUser,
          });
          return;
        }

        cells.push({
          ...cellParams,
          text: cartObject[id] ? String(cartObject[id]) : 'n/a',
          id: cell.id,
        });
      });

      rows.push({
        cells,
        index: position + 1,
        cart,
      });
    });

    return rows;
  },
);

export const selectedAbandonedCartProductsSelector = (cartId?: string) =>
  createSelector(abandonedCheckoutCartsListSelector, (carts) => {
    const cart = carts.find((item) => item.id === cartId);

    if (!cart) {
      return [];
    }

    return cart.items;
  });

export const abandonedCheckoutCartsEmptyStateSelector = createSelector(
  abandonedCheckoutCartsRowsSelector,
  searchFiltersSelector,
  dropdownFiltersSelector,
  (list, search, dropdown) => {
    const areNoFiltersApplied = !search && Array.isArray(dropdown) && !dropdown?.length;

    if (list.length === 0 && areNoFiltersApplied) {
      return {
        title: 'Woah, it’s quiet in here',
        description: 'Get your first record and then come back here',
        button: 'Back to Dashboard',
      };
    }

    if (list.length === 0 && !areNoFiltersApplied) {
      return {
        title: 'Woah, it’s quiet in here',
        description: 'No records matching the filter',
        button: 'Back to Dashboard',
      };
    }

    return {
      title: '',
      description: '',
      button: '',
    };
  },
);

export const abandonedCheckoutFilterOptionsSelector = createSelector(
  assignmentFlowEnabledSelector,
  (isOrderAssignmentEnabled) => {
    const isAbleToBeAssignedForAbandonedOrder = can(
      SUBJECTS.ABANDONED_CHECKOUTS.actions.ASSIGN_ABANDONED_ORDERS,
      SUBJECTS.ABANDONED_CHECKOUTS.value,
    );

    if (!isOrderAssignmentEnabled && isAbleToBeAssignedForAbandonedOrder) {
      return ABANDONED_CHECKOUTS_CARTS_FILTERS.filter(
        (option) => option.value !== ASSIGNED_TO_ME_ENTITY,
      );
    }
    return ABANDONED_CHECKOUTS_CARTS_FILTERS;
  },
);

export const initialAbandonedCheckoutFiltersSelector = createSelector(
  assignmentFlowEnabledSelector,
  (isOrderAssignmentEnabled) => {
    const isAbleToBeAssignedForAbandonedOrder = can(
      SUBJECTS.ABANDONED_CHECKOUTS.actions.ASSIGN_ABANDONED_ORDERS,
      SUBJECTS.ABANDONED_CHECKOUTS.value,
    );

    if (isOrderAssignmentEnabled && isAbleToBeAssignedForAbandonedOrder) {
      return ABANDONED_CHECKOUTS_CARTS_FILTERS.filter(
        (option) => option.value === ASSIGNED_TO_ME_ENTITY,
      );
    }
    return [];
  },
);
