import {
  CellSize,
  CellTextColor,
  CellTextWeight,
  ICell,
  OptionsGroupedType,
  OptionType,
} from '@ezetech/swag-space-x';
import { ASSIGNED_TO_ME_ENTITY } from 'constants/common';
import { ITeamMember } from 'interfaces/invitation.interface';
import { IAssignedForReseller } from './assignee.interface';

export interface IPermissions {
  action: string;
  subject: string;
}

export interface IUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  createdAt: Date;
  isSwagAdminLoginAsUser?: boolean;
  permissions?: IPermissions[];
  isOwner?: boolean;
  companyId?: string;
  company?: { name: string; publicId: string };
}

export interface ICustomer {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  createdAt: Date;
  type: string;
  location: string;
  orders: string;
  totalSpent: string;
  updatedAt: Date;
  deletedAt: Date | null;
  lastOrderDate: Date | null;
  orderedOn: string;
  companyId: string;
  companyName: string;
  publicId: string;
  assignee?: IAssignedForReseller | null;
  customerCompanyId: string | null;
}

export const LeadsStatus = {
  NEW: 'NEW',
  COMPLETED: 'COMPLETED',
};

export type LeadsStatusType = (typeof LeadsStatus)[keyof typeof LeadsStatus];

export const LeadSource = {
  WHAT_WE_OFFER: 'WHAT_WE_OFFER',
  INSTANT_QUOTE: 'INSTANT_QUOTE',
  UNKNOWN: 'UNKNOWN',
};

export type LeadSourceType = (typeof LeadSource)[keyof typeof LeadSource];

export interface ILead {
  id: string;
  email: string;
  firstName: string | null;
  lastName: string | null;
  phone: string | null;
  companySize: string | null;
  companyName: string | null;
  interestedIn: string[];
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
  status: LeadsStatusType;
  publicId: string;
  companyId: string;
  companyUrl: string | null;
  yearly: string | null;
  firstPrintLocationColorsQuantity: number | null;
  printLocationsQuantity: number | null;
  itemsQuantity: number | null;
  zip: string | null;
  country: string | null;
  totalCost: number | null;
  productUrl: string;
  productName: string | null;
  source: LeadSourceType;
  assignee?: IAssignedForReseller | null;
}

export interface IUsersList {
  list: Array<ICustomer | ILead>;
  perPage: number;
  page: number;
  total: number;
  selectionTotal: number;
}

export interface ITeamMembersList {
  teamMembers: ITeamMember[];
  perPage: number;
  page: number;
  total: number;
}

export const CUSTOMERS_FILTERS: OptionType[] | OptionsGroupedType[] = [
  { value: ASSIGNED_TO_ME_ENTITY, label: 'My Customers' },
  { value: 'type:inventory', label: 'Type: Inventory' },
  { value: 'type:bulk', label: 'Type: Bulk' },
  { value: 'type:inventoryBulk', label: 'Type: Inventory & Bulk' },
];
export const LEADS_FILTERS: OptionType[] | OptionsGroupedType[] = [
  { value: ASSIGNED_TO_ME_ENTITY, label: 'My Leads' },
  { value: 'status:new', label: 'Status: New' },
  { value: 'status:completed', label: 'Status: Completed' },
  { value: 'interestedIn:Bulk Ordering', label: 'Interested in: Bulk Ordering' },
  { value: 'interestedIn:Swag Distribution', label: 'Interested in: Swag Distribution' },
  { value: 'interestedIn:Swag In a Box', label: 'Interested in: Swag In a Box' },
  { value: 'interestedIn:Corporate Gifting', label: 'Interested in: Corporate Gifting' },
  { value: 'interestedIn:Company Stores', label: 'Interested in: Company Stores' },
  { value: 'source:INSTANT-QUOTE', label: 'Interested in: Instant Quote' },
];

export enum CustomerFields {
  id = 'id',
  customerName = 'customerName',
  email = 'email',
  companyName = 'companyName',
  phone = 'phone',
  type = 'type',
  location = 'location',
  orders = 'orders',
  totalSpent = 'totalSpent',
  lastOrderDate = 'lastOrderDate',
  assignTo = 'assignTo',
}

export enum LeadFields {
  id = 'id',
  customerName = 'customerName',
  email = 'email',
  companyName = 'companyName',
  phone = 'phone',
  companySize = 'companySize',
  interestedIn = 'interestedIn',
  date = 'date',
  status = 'status',
  assignTo = 'assignTo',
}

export enum LeadsCustomersActions {
  copy = 'copy',
  redirect = 'redirect',
}

export const CUSTOMERS_TABLE_HEADER = [
  { text: '#', id: CustomerFields.id, size: CellSize.small },
  { text: 'Customer name', id: CustomerFields.customerName, size: CellSize.large },
  { text: 'Company name', id: CustomerFields.companyName, size: CellSize.large },
  { text: 'Phone', id: CustomerFields.phone, size: CellSize.medium },
  { text: 'Email', id: CustomerFields.email, size: CellSize.medium },
  { text: 'Type', id: CustomerFields.type, size: CellSize.extraLarge },
  { text: 'Orders', id: CustomerFields.orders, size: CellSize.medium, withSorting: true },
  {
    text: 'Total spent',
    id: CustomerFields.totalSpent,
    size: CellSize.medium,
    withSorting: true,
  },
  { text: 'Last order', id: CustomerFields.lastOrderDate, size: CellSize.medium },
  { text: 'Assign to', id: CustomerFields.assignTo, size: CellSize.extraLarge },
];

export type LeadsTableHeaderType = {
  id: LeadFields;
  size: CellSize;
  text: string;
  withSorting?: boolean;
};

export const LEADS_TABLE_HEADER: LeadsTableHeaderType[] = [
  { text: '#', id: LeadFields.id, size: CellSize.small },
  { text: 'Customer name', id: LeadFields.customerName, size: CellSize.shortLarge },
  { text: 'Company name', id: LeadFields.companyName, size: CellSize.shortLarge },
  { text: 'Phone', id: LeadFields.phone, size: CellSize.shortMedium },
  { text: 'Email', id: LeadFields.email, size: CellSize.shortLarge },
  {
    text: 'Company size',
    id: LeadFields.companySize,
    size: CellSize.shortLarge,
    withSorting: true,
  },
  { text: 'Interested in', id: LeadFields.interestedIn, size: CellSize.extraLarge },
  { text: 'Date', id: LeadFields.date, size: CellSize.compact },
  { text: 'Status', id: LeadFields.status, size: CellSize.large },
  { text: 'Assign to', id: LeadFields.assignTo, size: CellSize.extraLarge },
];

export const CUSTOMERS_TABLE_ROW = {
  [CustomerFields.id]: {
    size: CellSize.small,
    textWeight: CellTextWeight.extraBold,
  },
  [CustomerFields.customerName]: {
    size: CellSize.large,
    textWeight: CellTextWeight.bold,
    textColor: CellTextColor.pink,
    onClick: LeadsCustomersActions.copy,
    actionable: true,
  },
  [CustomerFields.companyName]: {
    size: CellSize.large,
    onClick: LeadsCustomersActions.redirect,
  },
  [CustomerFields.phone]: {
    size: CellSize.medium,
  },
  [CustomerFields.email]: {
    size: CellSize.medium,
    textColor: CellTextColor.pink,
    onClick: LeadsCustomersActions.copy,
    actionable: true,
  },
  [CustomerFields.location]: {
    size: CellSize.medium,
  },
  [CustomerFields.type]: {
    size: CellSize.large,
  },
  [CustomerFields.orders]: {
    size: CellSize.medium,
    textWeight: CellTextWeight.extraBold,
  },
  [CustomerFields.totalSpent]: {
    size: CellSize.medium,
    textWeight: CellTextWeight.extraBold,
  },
  [CustomerFields.lastOrderDate]: {
    size: CellSize.medium,
  },
  [CustomerFields.assignTo]: {
    size: CellSize.extraLarge,
  },
};

export const LEADS_TABLE_ROW = {
  [LeadFields.id]: {
    size: CellSize.small,
    textWeight: CellTextWeight.extraBold,
  },
  [LeadFields.customerName]: {
    size: CellSize.shortLarge,
    textWeight: CellTextWeight.bold,
    textColor: CellTextColor.pink,
  },
  [LeadFields.companyName]: {
    size: CellSize.shortLarge,
    textColor: CellTextColor.pink,
  },
  [LeadFields.phone]: {
    size: CellSize.shortMedium,
  },
  [LeadFields.email]: {
    size: CellSize.shortLarge,
    textColor: CellTextColor.pink,
    onClick: LeadsCustomersActions.copy,
    actionable: true,
  },
  [LeadFields.companySize]: {
    size: CellSize.shortLarge,
  },
  [LeadFields.interestedIn]: {
    size: CellSize.extraLarge,
  },
  [LeadFields.date]: {
    size: CellSize.compact,
  },
  [LeadFields.status]: {
    size: CellSize.large,
  },
  [LeadFields.assignTo]: {
    size: CellSize.extraLarge,
  },
};

export const LEADS_INSTANT_QUOTE_TABLE_ROW = {
  [LeadFields.id]: {
    size: CellSize.small,
    textWeight: CellTextWeight.extraBold,
  },
  [LeadFields.customerName]: {
    size: CellSize.shortLarge,
  },
  [LeadFields.companyName]: {
    size: CellSize.shortLarge,
  },
  [LeadFields.phone]: {
    size: CellSize.shortMedium,
  },
  [LeadFields.email]: {
    size: CellSize.shortLarge,
    textColor: CellTextColor.pink,
    onClick: LeadsCustomersActions.copy,
    actionable: true,
  },
  [LeadFields.companySize]: {
    size: CellSize.shortLarge,
  },
  [LeadFields.interestedIn]: {
    size: CellSize.extraLarge,
  },
  [LeadFields.date]: {
    size: CellSize.compact,
  },
  [LeadFields.status]: {
    size: CellSize.large,
  },
  [LeadFields.assignTo]: {
    size: CellSize.extraLarge,
  },
};

export interface ITransformCell extends Omit<ICell, 'onClick'> {
  onClick?: LeadsCustomersActions;
  assignedToUser?: { value: string; label: string } | null;
  status?: { value: string; label: string };
  customerCompanyId?: string | null;
}

export interface ITransformRow {
  cells: ITransformCell[];
  index: number;
  isInstantQuote: boolean;
  item: ILead;
}
