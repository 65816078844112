import cn from 'classnames';
import { useState, useEffect } from 'react';
import { IFile, Input, Checkbox, Dropzone, Typography } from '@ezetech/swag-space-x';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import {
  MAX_FILE_SIZE_10MB_RESTRICTION,
  JPEG_PNG_ACCEPT_RESTRICTION,
} from 'constants/limits';
import { useProcessFile } from 'hooks/use-process-file';
import { useAppSelector } from 'redux/store';

import { CategoriesStructure } from 'interfaces/store-creation.interfaces';

import { resellerSettingsCategoriesStructureSelector } from 'redux/selectors/settings.selectors';

import { SidebarCategoriesBlockProps } from '../interface';

import css from './sidebar.module.scss';

export const SidebarCategoriesBlock = (
  props: SidebarCategoriesBlockProps,
): JSX.Element => {
  const { settings, setChanges } = props;
  const { handleProcessFile } = useProcessFile();
  const [blockAllCheckbox, setBlockAllCheckbox] = useState(false);
  const categoriesList = useAppSelector(resellerSettingsCategoriesStructureSelector);
  const [categoriesNormalize, setCategoriesNormalize] = useState<Record<
    string,
    CategoriesStructure[0]
  > | null>(null);

  useEffect(() => {
    const normalized = categoriesList.reduce(
      (acc, category) => {
        acc[category.id] = category;
        return acc;
      },
      {} as Record<string, CategoriesStructure[0]>,
    );
    setCategoriesNormalize(normalized);
  }, [categoriesList]);

  useEffect(() => {
    if (settings.categories?.length === 6) {
      setBlockAllCheckbox(true);
    }
  }, [settings.categories?.length]);

  const onCheckedChange = (
    selectedCategory: CategoriesStructure[0],
    isSelected: boolean,
  ) => {
    const categories = [...settings.categories];
    const index = categories.findIndex((category) => category.id === selectedCategory.id);
    if (index === -1 && isSelected) {
      categories.push({
        publicId: selectedCategory?.publicId || '',
        name: selectedCategory.name,
        image: null,
        id: selectedCategory.id,
      });
    } else if (index !== -1 && !isSelected) {
      categories.splice(index, 1);
      setBlockAllCheckbox(false);
    }
    setChanges('categories', categories);
  };

  const onImageChanged = (files: IFile[], id: string) => {
    const categories = [...settings.categories];
    const index = settings.categories.findIndex((category) => category.id === id);
    if (!files[0] && !settings.categories[index].image?.url) {
      return;
    }
    categories[index] = {
      ...categories[index],
      image: files[0] || null,
    };
    setChanges('categories', categories);
  };

  const isCategorySelected = (id: string) =>
    settings.categories.some((category) => category.id === id);

  const isCategoryDisabled = (id: string) => blockAllCheckbox && !isCategorySelected(id);

  return (
    <div className={css.sidebarMainBlock}>
      <div>
        <Checkbox
          onCheckedChange={(e) => setChanges('isHidden', e)}
          name="isHidden"
          checked={settings.isHidden}
          label="Hide section"
        />
      </div>
      <div
        className={cn(css.disabledBlock, { [css.disabledOverlay]: settings.isHidden })}
      />
      <div>
        <Input
          autoComplete="off"
          value={settings.headingText}
          className={css.inputWrapper}
          maxLength={20}
          onChange={(e) => setChanges('headingText', e.target.value)}
          name="headingText"
          placeholder="Shop By Collection"
          label="Heading Copy"
          skipError
        />
        <Typography
          fontType="bodySm"
          color={COLORS.colorText500}
          className={css.subInputInfo}
          lineHeight="142.857%"
        >
          Max 20 characters{' '}
        </Typography>
      </div>

      <div>
        <div>
          <Typography fontType="bodyMd" color={COLORS.colorText900} lineHeight="162.5%">
            Select up to 6 categories
          </Typography>
        </div>
        <Typography fontType="bodyMd" color={COLORS.colorText600} lineHeight="162.5%">
          For best results select either 3 or 6.
        </Typography>

        <div className={css.collectionCheckBox}>
          {categoriesList.map((category) => {
            return (
              <Checkbox
                key={category.id}
                onCheckedChange={(e) => onCheckedChange(category, !!e)}
                name={category.name}
                checked={isCategorySelected(category.id)}
                label={category.name}
                disabled={isCategoryDisabled(category.id)}
              />
            );
          })}
        </div>
      </div>

      <div className={css.imagesList}>
        {settings.categories.map((category, index) => (
          <div key={category.id}>
            <Typography
              fontType="bodySm"
              color={COLORS.colorText900}
              className={css.dropzoneLabel}
              lineHeight="142.857%"
            >
              {`${categoriesNormalize && categoriesNormalize[category.id]?.name} Image`}
            </Typography>
            <Dropzone
              buttonClassName={css.dropzoneButton}
              accept={JPEG_PNG_ACCEPT_RESTRICTION}
              className={css.imageLoader}
              files={category.image ? [category.image] : []}
              processFile={handleProcessFile}
              onFilesChanged={(files) => {
                onImageChanged(files, category.id);
              }}
              maxSize={MAX_FILE_SIZE_10MB_RESTRICTION}
              additionalText="JPG or PNG, no more than 10MB"
              uploadTextWrapperClassName={css.uploadTextWrapper}
              previewClassName={css.previewImageWrapper}
              helperText="Recommended size is 876 x 686 pixels"
              helperTextClassName={css.dropzoneHelperText}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
