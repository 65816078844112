import classNames from 'classnames';
import {
  Table,
  TableBody,
  TableHeader,
  TableRow,
  TableHeaderCell,
  TableRowCell,
  CellSize,
  Divider,
  Typography,
} from '@ezetech/swag-space-x';
import { useAppSelector, useAppDispatch } from 'redux/store';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { closePopup, openPopup } from 'redux/slices/modals.slice';
import { useClipboard } from 'hooks/use-clipboard.hook';
import { PROMOCODES_TABLE_HEADER } from 'constants/promocodes.constants';
import { NotificationType } from 'redux/slices/notifications.slice';
import { useNotifications } from 'hooks/notifications.hook';
import { CopyIcon } from 'components/svg/copy';
import { CONFIRMATION_POPUP } from 'components/popups/_logic/popups-list';
import { promocodesListTableRowsSelector } from 'redux/selectors/promocodes.selectors';
import { SUBJECTS } from 'constants/ability.constant';
import { can } from 'boot/ability';

import trashIcon from '../../../assets/svg/trash-v1.svg';

import { useRemovePromoCodeMutation } from '../../../redux/api/promocode.api';
import classes from './promocodes-table.module.scss';

export const PromocodesTable = () => {
  const dispatch = useAppDispatch();
  const { pushNotification } = useNotifications();
  const [removePromocode] = useRemovePromoCodeMutation();

  const rows = useAppSelector(promocodesListTableRowsSelector);
  const { copy } = useClipboard();

  const subjectPPName = SUBJECTS.PROMOCODES_EDIT.value;
  const subjectPPActions = SUBJECTS.PROMOCODES_EDIT.actions;

  const handleCellClick = (id: string) => {
    dispatch(
      openPopup({
        popupName: CONFIRMATION_POPUP,
        popupProps: {
          descriptionComponent: 'Are you sure you want to delete the promocode?',
          onConfirm: () => {
            removePromocode(id);
            dispatch(closePopup());
          },
          onCancel: () => {
            dispatch(closePopup());
          },
        },
      }),
    );
  };

  const handleCodeClick = async (code: string): Promise<void> => {
    const isSuccess = await copy(code);

    if (isSuccess) {
      pushNotification({
        status: 'positive',
        message: 'Code has been copied.',
        type: NotificationType.toasts,
      });
    }
  };

  return (
    <Table className={classes.root}>
      <TableHeader className={classes.header}>
        <TableHeaderCell id="copy" size={CellSize.unset} className={classes.cellHeader}>
          &nbsp;
        </TableHeaderCell>

        {PROMOCODES_TABLE_HEADER.map((cell) => (
          <TableHeaderCell
            className={classNames(classes.cellHeader, classes[cell.id])}
            id={cell.id}
            size={cell.size}
            key={`promocodes-header-${cell.id}`}
          >
            {cell.text}
          </TableHeaderCell>
        ))}
        <TableHeaderCell id="delete" size={CellSize.unset} className={classes.cellHeader}>
          &nbsp;
        </TableHeaderCell>
      </TableHeader>
      <Divider />
      {!rows.length ? (
        <Typography
          className={classes.noPromo}
          color={COLORS.colorTextSubdued}
          fontType="bodyMdBold"
        >
          No Promocodes
        </Typography>
      ) : (
        <TableBody>
          {rows.map((row, position) => (
            <TableRow key={`promocodes-${row.index}`} className={classes.row}>
              <TableRowCell
                className={classNames(classes.cell, classes.copyCell)}
                key={`promocodes-${position}-copy`}
                size={CellSize.unset}
                skipTooltip={true}
                id={`promocodes-copy-${row.id}`}
                onClick={() => handleCodeClick(row.cells[0].text)}
              >
                <CopyIcon className={classes.copy} />
              </TableRowCell>
              {row.cells.map((cell, index) => (
                <TableRowCell
                  className={classNames(classes.cell, classes[cell.id])}
                  key={`promocodes-${position}-${cell.id}-${row.id}`}
                  id={cell.id}
                  rowId={row.id}
                  size={cell.size || CellSize.unset}
                >
                  {cell.text}
                </TableRowCell>
              ))}
              <TableRowCell
                className={classes.cell}
                key={`promocodes-${position}-delete`}
                size={CellSize.unset}
                skipTooltip={true}
                id={`promocodes-delete-${row.id}`}
                onClick={() => handleCellClick(row.id)}
              >
                {can(subjectPPActions.ACTIVE_PROMOCODES_DELETE, subjectPPName) && (
                  <div className={classes.trashIcon}>
                    <img src={trashIcon} />
                  </div>
                )}
              </TableRowCell>
            </TableRow>
          ))}
        </TableBody>
      )}
    </Table>
  );
};
