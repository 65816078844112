import cn from 'classnames';
import {
  Toggle,
  TogglePlacement,
  ToggleWidth,
  Typography,
  Tooltip,
  TooltipPlacement,
} from '@ezetech/swag-space-x';

import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { cannot, SUBJECTS } from 'boot/ability';

import css from './product-selection.module.scss';

export function PriceWidget({
  onSuperInexpensiveToggle,
  isSuperInexpensiveEnabled,
  onBudgetToggle,
  isBudgetEnabled,
  onStandardToggle,
  isStandardEnabled,
  onPremiumToggle,
  isPremiumEnabled,
  widgetPriceRangeClassName,
  hideTitleLine,
  verticalDividerTabletClassName,
  disableLine2ClassName,
}: {
  isPremiumEnabled: boolean;
  isBudgetEnabled: boolean;
  isSuperInexpensiveEnabled: boolean;
  isStandardEnabled: boolean;
  widgetPriceRangeClassName?: string;
  verticalDividerTabletClassName?: string;
  disableLine2ClassName?: string;
  hideTitleLine?: boolean;
  onSuperInexpensiveToggle: () => void;
  onBudgetToggle: () => void;
  onStandardToggle: () => void;
  onPremiumToggle: () => void;
}): JSX.Element {
  const isDisabled = cannot(
    SUBJECTS.PRICE_POINTS_FOR_ALL_PRODUCTS.actions.PRICE_POINTS_FOR_ALL_PRODUCTS_EDIT,
    SUBJECTS.PRICE_POINTS_FOR_ALL_PRODUCTS.value,
  );
  return (
    <div className={cn(css.widget, css.widgetPriceRange, widgetPriceRangeClassName)}>
      {!hideTitleLine && (
        <div className={css.tooltipWrapper}>
          <Typography
            lineHeight="162%"
            fontType="bodyMdBold"
            color={COLORS.colorTypographyHeadings}
          >
            Price points for all products
          </Typography>

          <div className={css.iconWrapper}>
            <Tooltip placement={TooltipPlacement.right}>
              <div className={css.tooltipText}>
                <Typography
                  fontType="bodySm"
                  lineHeight="143%"
                  color={COLORS.colorTypographyHeadings}
                >
                  In every product category there are different levels of products ranging
                  from super inexpensive, to premium. For example, there could be a $2
                  tshirt, or a $75 tshirt. This is the section where you select the types
                  of products that you want to appear in your store.
                </Typography>
              </div>
            </Tooltip>
          </div>
        </div>
      )}

      <div className={cn(css.disableLine2, disableLine2ClassName)}>
        <Toggle
          onChange={isDisabled ? () => null : onSuperInexpensiveToggle}
          value={isSuperInexpensiveEnabled}
          label="Super Inexpensive"
          name="Super Inexpensive"
          width={ToggleWidth.short}
          placement={TogglePlacement.right}
        />
        <div className={css.verticalDivider} />
        <Toggle
          onChange={isDisabled ? () => null : onBudgetToggle}
          value={isBudgetEnabled}
          label="Budget"
          name="Budget"
          width={ToggleWidth.short}
          placement={TogglePlacement.right}
        />
        <div className={css.verticalDivider} />

        <Toggle
          onChange={isDisabled ? () => null : onStandardToggle}
          value={isStandardEnabled}
          label="Standard"
          name="Standard"
          width={ToggleWidth.short}
          placement={TogglePlacement.right}
        />
        <div className={css.verticalDivider} />

        <Toggle
          onChange={isDisabled ? () => null : onPremiumToggle}
          value={isPremiumEnabled}
          label="Premium"
          name="Premium"
          width={ToggleWidth.short}
          placement={TogglePlacement.right}
        />
      </div>
      <div className={cn(css.verticalDividerTablet, verticalDividerTabletClassName)} />
    </div>
  );
}
