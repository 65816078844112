import {
  TextField,
  Input,
  Select,
  OptionType,
  Dropzone,
  Typography,
} from '@ezetech/swag-space-x';
import cn from 'classnames';
import { CSSObjectWithLabel } from 'react-select/dist/declarations/src/types';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import {
  BUTTON_COLOR_TYPE_OPTIONS,
  COLOR_TYPE_OPTIONS,
} from 'constants/homepage-editor.constants';
import { useAppSelector } from 'redux/store';
import { ButtonColorType } from 'interfaces/homepage-settings.interface';
import {
  MAX_FILE_SIZE_10MB_RESTRICTION,
  JPEG_PNG_ACCEPT_RESTRICTION,
} from 'constants/limits';
import { useProcessFile } from 'hooks/use-process-file';
import { brandColorsSelector } from 'redux/selectors/settings.selectors';
import { SidebarHeroBlockProps } from '../interface';
import css from './sidebar.module.scss';

export const EDITOR_ID = 'homepage-editor';
export const TABLET_WIDTH = 1024;

export const SidebarHeroBlock = (props: SidebarHeroBlockProps): JSX.Element => {
  const { settings, setChanges } = props;
  const { handleProcessFile } = useProcessFile();
  const brandColors = useAppSelector(brandColorsSelector);

  const getButtonColorOptionByValue = (value: string) =>
    BUTTON_COLOR_TYPE_OPTIONS.find((option) => option.value === value) ?? null;

  const getColorOptionByValue = (value: string) =>
    COLOR_TYPE_OPTIONS.find((option) => option.value === value) ?? null;

  const calculatePosition = (style: CSSObjectWithLabel) => {
    const rect = document.getElementById(EDITOR_ID)?.getBoundingClientRect();
    const { top, left, ...rest } = style;

    if (window.innerWidth >= TABLET_WIDTH) {
      return { top, left, ...rest };
    }

    return {
      ...rest,
      left: (left as number) - (rect?.x || 0),
      top: (top as number) - (rect?.y || 0),
    };
  };

  return (
    <div className={css.sidebarMainBlock}>
      <div>
        <Typography
          fontType="bodySm"
          color={COLORS.colorPrimaryText}
          className={css.dropzoneLabel}
          lineHeight="142.857%"
        >
          Hero Image
        </Typography>
        <Dropzone
          buttonClassName={css.dropzoneButton}
          accept={JPEG_PNG_ACCEPT_RESTRICTION}
          className={css.imageLoader}
          files={settings.image ? [settings.image] : []}
          maxSize={MAX_FILE_SIZE_10MB_RESTRICTION}
          processFile={handleProcessFile}
          uploadTextWrapperClassName={css.uploadTextWrapper}
          onFilesChanged={(files) => setChanges('image', files[0])}
          additionalText="JPG or PNG, no more than 10MB"
          previewClassName={css.previewImageWrapper}
          helperText="Recommended size is 3648 x 1212 pixels"
          helperTextClassName={css.dropzoneHelperText}
        />
      </div>
      <div>
        <Input
          autoComplete="off"
          value={settings.headingText}
          maxLength={20}
          onChange={(e) => setChanges('headingText', e.target.value)}
          name="headingText"
          placeholder="Swag Made Easy"
          label="Heading Copy"
          className={css.inputWrapper}
          skipError
        />
        <Typography
          fontType="bodySm"
          color={COLORS.colorText500}
          className={css.subInputInfo}
          lineHeight="142.857%"
        >
          Max 20 characters
        </Typography>
      </div>
      <div>
        <TextField
          value={settings.bodyText}
          maxLength={200}
          onChange={(e) => setChanges('bodyText', e.target.value)}
          name="bodyText"
          placeholder="Write some text here... e.g. Your all-in one swag platform. Shop, store and ship the highest quality swag around the world with the click of a button."
          label="Body Copy"
          className={css.textarea}
          labelClassName={css.textareaLabel}
          textAreaWrapperClassName={css.textareaWrapper}
        />
        <Typography
          fontType="bodySm"
          color={COLORS.colorText500}
          className={css.subInputInfo}
          lineHeight="142.857%"
        >
          Max 200 characters
        </Typography>
      </div>
      <div>
        <Select
          options={COLOR_TYPE_OPTIONS}
          value={getColorOptionByValue(settings.textColorType)}
          onChange={(value) => setChanges('textColorType', (value as OptionType).value)}
          label="Text Color"
          name="textColorType"
          skipError
        />
        <Typography
          fontType="bodySm"
          color={COLORS.colorText500}
          className={css.subInputInfo}
          lineHeight="142.857%"
        >
          Toggle this depending on the color of your image you have uploaded.
        </Typography>
      </div>
      <div>
        <Input
          autoComplete="off"
          className={css.inputWrapper}
          value={settings.buttonText}
          maxLength={15}
          onChange={(e) => setChanges('buttonText', e.target.value)}
          name="buttonText"
          placeholder="Shop Products"
          label="Button Text"
          skipError
        />
        <Typography
          fontType="bodySm"
          color={COLORS.colorText500}
          className={css.subInputInfo}
          lineHeight="142.857%"
        >
          Max 15 characters
        </Typography>
      </div>
      <div>
        <Select
          menuPosition="fixed"
          menuPlacement="auto"
          menuShouldBlockScroll
          styles={{
            menuPortal: (style) => ({
              ...style,
              ...calculatePosition(style),
            }),
          }}
          options={BUTTON_COLOR_TYPE_OPTIONS}
          value={getButtonColorOptionByValue(settings.buttonColorType)}
          onChange={(value) => setChanges('buttonColorType', (value as OptionType).value)}
          label="Button Color"
          name="buttonColorType"
          components={{
            ValueContainer: ({ children, ...propsValue }) => (
              <div className={css.navOptionsMobileValueContainer} {...propsValue}>
                <span
                  className={css.circle}
                  style={{
                    backgroundColor:
                      getButtonColorOptionByValue(settings.buttonColorType)?.value ===
                      ButtonColorType.Primary
                        ? brandColors.primaryColor
                        : brandColors.secondaryColor,
                  }}
                />
                {children}
              </div>
            ),
            Option: ({ innerProps, data }) => (
              <div
                {...innerProps}
                data-label={data?.value}
                className={cn(css.navOptionsMobileOption, {
                  [css.selectedOption]:
                    getButtonColorOptionByValue(settings.buttonColorType)?.value ===
                    data?.value,
                })}
              >
                <span
                  className={css.circleOption}
                  style={{
                    backgroundColor:
                      data?.value === ButtonColorType.Primary
                        ? brandColors?.primaryColor
                        : brandColors?.secondaryColor,
                  }}
                ></span>
                {data?.label}
              </div>
            ),
          }}
          skipError
        />
      </div>
      <div className={css.lastSelect}>
        <Select
          menuPosition="fixed"
          menuPlacement="auto"
          menuShouldBlockScroll
          options={COLOR_TYPE_OPTIONS}
          value={getColorOptionByValue(settings.buttonTextColorType)}
          styles={{
            menuPortal: (style) => ({
              ...style,
              ...calculatePosition(style),
            }),
          }}
          onChange={(value) =>
            setChanges('buttonTextColorType', (value as OptionType).value)
          }
          label="Button Text Color"
          name="buttonTextColorType"
          skipError
        />
      </div>
    </div>
  );
};
