import React from 'react';
import { Typography } from '@ezetech/swag-space-x';
import cn from 'classnames';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

import COLORS from 'constants/styles/colors-js.constant.module.scss';

import {
  MAX_PASSWORD_LENGTH_CHARACTERS,
  ONE_SPECIAL_CHARACTER,
  PASSWORD_REQUIREMENTS,
} from './password-requirements-constants';

import css from './password-requirements-block.module.scss';

type IProps = {
  isLengthMoreThanMaxAllowedCharacters: boolean;
  className?: string;
  completedRequirements: number[];
  isAllRequirementsCompleted: boolean;
  isInputFocused: boolean;
};

const animationClasses = {
  enter: css.enter,
  enterActive: css.enterActive,
  enterDone: css.enterDone,
  exit: css.exit,
  exitActive: css.exitActive,
};

const animationSwitchClasses = {
  enter: css.enterSwitch,
  enterActive: css.enterActiveSwitch,
  enterDone: css.enterDoneSwitch,
  exit: css.exitSwitch,
  exitActive: css.exitActiveSwitch,
};

const isPasswordRequirementDone = (index: number, requirements?: number[]): boolean => {
  return Number.isInteger(requirements?.find((doneIndex) => doneIndex === index));
};

const getRequirementsList = ({
  isLengthMoreThanMaxAllowedCharacters,
  completedRequirements,
  className,
}: Partial<IProps>): JSX.Element => {
  return (
    <ul className={cn(css.requirementsList, className)}>
      <div className={css.requirementsBlock}>
        {PASSWORD_REQUIREMENTS.map((requirement, index) => {
          if (requirement === ONE_SPECIAL_CHARACTER) {
            return (
              <li
                key={requirement}
                className={cn(
                  css.requirement,
                  css.requirementWithTooltip,
                  isPasswordRequirementDone(index, completedRequirements) &&
                    css.doneRequirement,
                )}
              >
                <div className={css.rule}>{requirement}</div>
              </li>
            );
          }

          return (
            <li
              key={requirement}
              className={cn(
                css.requirement,
                isPasswordRequirementDone(index, completedRequirements) &&
                  css.doneRequirement,
              )}
            >
              {requirement}
            </li>
          );
        })}
        <li
          key={MAX_PASSWORD_LENGTH_CHARACTERS}
          className={cn(
            css.requirement,
            css.maxCharactersRequirement,
            isLengthMoreThanMaxAllowedCharacters && css.maxCharactersRequirementAppear,
          )}
        >
          {MAX_PASSWORD_LENGTH_CHARACTERS}
        </li>
      </div>
    </ul>
  );
};

export const PasswordRequirementsBlock: React.FunctionComponent<IProps> = ({
  isLengthMoreThanMaxAllowedCharacters,
  className,
  completedRequirements,
  isAllRequirementsCompleted,
  isInputFocused,
}) => {
  return (
    <>
      <CSSTransition
        in={isInputFocused || isAllRequirementsCompleted}
        classNames={animationClasses}
        timeout={450}
        unmountOnExit
      >
        <SwitchTransition>
          <CSSTransition
            key={isAllRequirementsCompleted ? '1' : '2'}
            timeout={150}
            classNames={animationSwitchClasses}
            unmountOnExit
          >
            <>
              {!isAllRequirementsCompleted ? (
                getRequirementsList({
                  completedRequirements,
                  isLengthMoreThanMaxAllowedCharacters,
                  className,
                })
              ) : (
                <div className={css.passwordSecure}>
                  <Typography
                    lineHeight="100%"
                    fontType="bodyMd"
                    color={COLORS.colorGreen}
                  >
                    Your password is secure, you&apos;re all set!
                  </Typography>
                </div>
              )}
            </>
          </CSSTransition>
        </SwitchTransition>
      </CSSTransition>
    </>
  );
};
