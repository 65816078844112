import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

import { CREDIT_CARD_ABSENT } from 'constants/promocodes.constants';

interface CustomError {
  data: { message: string[] };
}

export function checkIsRejectedByCreditCardValidation(
  error: FetchBaseQueryError | SerializedError | undefined,
): boolean {
  if (!error) {
    return false;
  }

  return (error as CustomError).data?.message?.[0] === CREDIT_CARD_ABSENT;
}
