import { Pagination } from '@ezetech/swag-space-x';

import css from './reseller-mockup-approval-popup.module.scss';

export const PaginationBlock = ({
  page,
  lastPage,
  isCompareMode,
  handlePageClick,
  hidePMPagination,
  hideCMPagination,
  wrapperClassName,
  customerMockupPage,
  customPageIndicator,
  onCustomerMockupPage,
  maxCustomerMockupPage,
  isSinglePageCustomerMockup,
  maxCustomerMockupPageIndicator,
}: {
  page: number;
  lastPage: number;
  isCompareMode: boolean;
  wrapperClassName: string;
  hidePMPagination?: boolean;
  hideCMPagination?: boolean;
  customerMockupPage: number;
  customPageIndicator: string;
  maxCustomerMockupPage: number;
  isSinglePageCustomerMockup?: boolean;
  maxCustomerMockupPageIndicator: string;
  handlePageClick: (page: number) => void;
  onCustomerMockupPage: (page: number) => void;
}) => {
  const shouldHideCMPagination =
    !isCompareMode || (isCompareMode && hideCMPagination) || isSinglePageCustomerMockup;
  const shouldHidePMPagination = hidePMPagination;

  return (
    <div className={wrapperClassName}>
      {!shouldHidePMPagination && (
        <div className={css.paginationLeftPart}>
          <Pagination
            paginationClassName={css.customerMockupPagination}
            goToPage={handlePageClick}
            page={page}
            lastPage={lastPage}
            customPageIndicator={customPageIndicator}
          />
        </div>
      )}

      {!shouldHideCMPagination && (
        <div className={css.paginationRightPart}>
          <Pagination
            paginationClassName={css.customerMockupPagination}
            goToPage={onCustomerMockupPage}
            page={customerMockupPage}
            lastPage={maxCustomerMockupPage}
            customPageIndicator={maxCustomerMockupPageIndicator}
          />
        </div>
      )}
    </div>
  );
};
