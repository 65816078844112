import { ERROR_MSG } from '../constants/validation';
import {
  MAX_LINK_CHARACTERS,
  MIN_LINK_CHARACTERS,
} from '../constants/store-creation.constants';

export function validateStoreName(str: string, emptyValid = true): string | null {
  if (emptyValid) {
    if (!str && !str.length) {
      return null;
    }
  }

  if (str.length < MIN_LINK_CHARACTERS) {
    return ERROR_MSG.storeNameMinLength;
  }

  if (str.length > MAX_LINK_CHARACTERS) {
    return ERROR_MSG.storeNameMaxLength;
  }

  const startRegexp = new RegExp(/^[a-z0-9]/, 'g');
  const endRegexp = new RegExp(/[a-z0-9]$/, 'g');

  if (!startRegexp.test(str) || !endRegexp.test(str)) {
    return ERROR_MSG.storeNameEndStartMatch;
  }

  const twoConsecutiveSymbolsRegexp = new RegExp(/^(?!.*[-]{2}).*$/, 'g');

  if (!twoConsecutiveSymbolsRegexp.test(str)) {
    return ERROR_MSG.storeNameTwoSymbolsMatch;
  }

  // We suppress error here because we will never run out of
  // resources (So we can't block event loop) in this particular case,
  // because we check length of string earlier
  // eslint-disable-next-line security/detect-unsafe-regex
  const regexp = new RegExp(/^(?!.*--)[a-z0-9](?:[a-z0-9-]{0,}[a-z0-9])?$/, 'g');

  if (!regexp.test(str)) {
    return ERROR_MSG.storeNameMatch;
  }

  return null;
}
