import {
  Dialog,
  DialogContentWrapper,
  DialogTitle,
  DialogDescription,
  DialogFooter,
  SwagButton,
  Input,
} from '@ezetech/swag-space-x';
import CopyIcon from 'assets/svg/copy.svg';
import { useAppDispatch } from 'redux/store';
import { closePopup } from 'redux/slices/modals.slice';
import { NotificationType } from 'redux/slices/notifications.slice';
import { useClipboard } from 'hooks/use-clipboard.hook';
import { useNotifications } from 'hooks/notifications.hook';
import { CopyACartLinkPopupProps } from 'interfaces/popup.interface';
import css from './copy-a-cart-link-popup.module.scss';

export const CopyACartLinkPopup = ({
  email,
  cartLink,
  isOpen,
}: CopyACartLinkPopupProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { copy } = useClipboard();
  const { pushNotification } = useNotifications();

  const onCloseButtonClick = () => {
    dispatch(closePopup());
  };

  const copyText = async () => {
    const isSuccess = await copy(cartLink);

    if (isSuccess) {
      pushNotification({
        status: 'positive',
        message: 'Cart link has been copied.',
        type: NotificationType.toasts,
      });
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onCloseButtonClick}>
      <DialogContentWrapper
        className={css.dialogContentWrapperClassName}
        overlayClassName={css.dialogOverlayClassName}
      >
        <DialogTitle closeIconClassName={css.closeIconClassName}>
          Your Cart URL Link for {email}
        </DialogTitle>
        <DialogDescription className={css.contentWrapper}>
          <Input
            value={cartLink}
            errorClassName={css.inputError}
            disabled
            adornmentEnd={
              <div className={css.copyIcon} onClick={copyText}>
                <img alt="copy link" src={CopyIcon} />
              </div>
            }
          />
        </DialogDescription>

        <DialogFooter className={css.footer}>
          <SwagButton type="primary" onClick={onCloseButtonClick}>
            Done
          </SwagButton>
        </DialogFooter>
      </DialogContentWrapper>
    </Dialog>
  );
};
