import { Typography, TypographyProps } from '@ezetech/swag-space-x';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import css from './sign-contract.module.scss';

export const makeBold = (text: string, props?: Partial<TypographyProps>) => {
  return (
    <Typography color={COLORS.colorTextSubdued} fontType="bodyMdBold" {...props}>
      {text}
    </Typography>
  );
};

export const makeLink = (
  text: string,
  href: string,
  props?: Partial<TypographyProps>,
) => {
  return (
    <a className={css.link} href={href} target="_blank">
      <Typography color={COLORS.colorPink} fontType="bodyMd" {...props}>
        {text}
      </Typography>
    </a>
  );
};
