import * as yup from 'yup';
import { ERROR_MSG, noDoubleDashes, storeNameRegexp } from 'constants/validation';
import {
  MIN_LINK_CHARACTERS,
  MAX_LINK_CHARACTERS,
} from 'constants/store-creation.constants';
import { FIELDS } from './store-preferences.constants';

export const storePreferencesFormSchema = yup.object({
  [FIELDS.brandName.name]: yup
    .string()
    .strict(true)
    .trim(ERROR_MSG.trim)
    .max(MAX_LINK_CHARACTERS, ERROR_MSG.storeNameMaxLength)
    .min(MIN_LINK_CHARACTERS, ERROR_MSG.storeNameMinLength)
    .matches(noDoubleDashes, ERROR_MSG.storeNameTwoSymbolsMatch)
    .matches(storeNameRegexp, ERROR_MSG.storeNameMatch)
    .required(),
  [FIELDS.homepageTitle.name]: yup
    .string()
    .trim(ERROR_MSG.trim)
    .max(255, ERROR_MSG.max(255))
    .nullable()
    .optional(),
  [FIELDS.logoLinkUrl.name]: yup
    .string()
    .trim(ERROR_MSG.trim)
    .max(255, ERROR_MSG.max(255))
    .url(ERROR_MSG.urlWithExample)
    .nullable()
    .optional(),
});
