import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AbandonedCheckoutsCartStatusEnum,
  IAbandonedCheckoutsCart,
  IUpdateAbandonedStatus,
} from 'interfaces/abandoned-checkouts-cart.interfaces';
import { IAssignedForReseller } from 'interfaces/assignee.interface';

interface IAbandonedCheckoutsCartsState {
  carts: IAbandonedCheckoutsCart[];
  total: number;
}

const initialState: IAbandonedCheckoutsCartsState = {
  carts: [],
  total: 0,
};

export const abandonedCheckoutsCartsSlice = createSlice({
  initialState,
  name: 'abandonedCheckoutsCartsSlice',
  reducers: {
    reset: () => initialState,
    setAbandonedCheckoutsCarts: (
      state,
      action: PayloadAction<{
        carts: IAbandonedCheckoutsCartsState['carts'];
        total: IAbandonedCheckoutsCartsState['total'];
      }>,
    ) => {
      if (!action.payload) {
        return state;
      }

      state.carts = action.payload.carts;
      state.total = action.payload.total;
    },

    setStatusToCart: (state, action: PayloadAction<IUpdateAbandonedStatus>) => {
      const { id, status } = action.payload;

      const cart = state.carts.find((item) => item.id === id);

      if (cart) {
        cart.abandonedCheckoutsStatus = status as AbandonedCheckoutsCartStatusEnum;
      }
    },

    setAssigneeToAbandonedCheckoutById: (
      state,
      action: PayloadAction<{ entityId: string; assignee: IAssignedForReseller | null }>,
    ) => {
      const { entityId, assignee } = action.payload;

      const index = state.carts.findIndex((cart) => cart.id === entityId);

      if (index !== -1) {
        state.carts[index].assignee = assignee;
      }
    },
  },
});

export const abandonedCheckoutsCartsReducer = abandonedCheckoutsCartsSlice.reducer;
export const {
  reset,
  setAbandonedCheckoutsCarts,
  setStatusToCart,
  setAssigneeToAbandonedCheckoutById,
} = abandonedCheckoutsCartsSlice.actions;
