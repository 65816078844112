import {
  Dialog,
  DialogContentWrapper,
  DialogTitle,
  DialogDescription,
  DialogFooter,
  SwagButton,
  Typography,
} from '@ezetech/swag-space-x';
import { ResellerMockupApprovalRevisionsRequestedPopupProps } from 'interfaces/popup.interface';
import { useAppDispatch } from 'redux/store';
import { closePopup } from 'redux/slices/modals.slice';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import css from './reseller-mockup-approval-revisions-requested-popup.module.scss';

export const ResellerMockupApprovalRevisionsRequestedPopup = ({
  isOpen,
}: ResellerMockupApprovalRevisionsRequestedPopupProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const onBtnClick = () => {
    dispatch(closePopup());
  };

  return (
    <Dialog open={isOpen} onOpenChange={onBtnClick}>
      <DialogContentWrapper
        className={css.dialogContentWrapperClassName}
        overlayClassName={css.dialogOverlayClassName}
      >
        <DialogTitle closeIconClassName={css.closeIconClassName}>
          Mockup Revisions Requested
        </DialogTitle>
        <DialogDescription className={css.contentWrapper}>
          <Typography
            color={COLORS.colorTextSubdued}
            fontType="bodyMd"
            lineHeight="162.5%"
          >
            Your mockup revisions have now been sent to our team, and we will revise this
            and get back to you as soon as possible.
          </Typography>
        </DialogDescription>

        <DialogFooter className={css.footerClassName}>
          <SwagButton
            type="primary"
            size="large"
            onClick={onBtnClick}
            className={css.btn}
          >
            Close
          </SwagButton>
        </DialogFooter>
      </DialogContentWrapper>
    </Dialog>
  );
};
