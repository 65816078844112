import { createListenerMiddleware } from '@reduxjs/toolkit';
import { getDashboard } from '../slices/dashboard.slice';
import { dashboardApi } from '../api/dashboard.api';

export const dashboardMiddleware = createListenerMiddleware();

dashboardMiddleware.startListening({
  actionCreator: getDashboard,
  effect: async (_, listenerApi) => {
    const request = listenerApi.dispatch(
      dashboardApi.endpoints?.getDashboard.initiate(undefined, {
        forceRefetch: true,
      }),
    );
    await request.unwrap();
  },
});
