import cn from 'classnames';
import { Checkbox, Input, TextField, Dropzone, Typography } from '@ezetech/swag-space-x';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import {
  MAX_FILE_SIZE_10MB_RESTRICTION,
  JPEG_PNG_ACCEPT_RESTRICTION,
} from 'constants/limits';
import { useProcessFile } from 'hooks/use-process-file';

import { SidebarAboutUsBlockProps } from '../interface';

import css from './sidebar.module.scss';

export const SidebarAboutUsBlock = (props: SidebarAboutUsBlockProps): JSX.Element => {
  const { settings, setChanges } = props;
  const { handleProcessFile } = useProcessFile();

  return (
    <div className={css.sidebarMainBlock}>
      <Checkbox
        onCheckedChange={(e) => setChanges('isHidden', e)}
        name="isHidden"
        checked={settings.isHidden}
        label="Hide section"
      />
      <div
        className={cn(css.disabledBlock, { [css.disabledOverlay]: settings.isHidden })}
      />
      <div>
        <Input
          autoComplete="off"
          value={settings.headingText}
          className={css.inputWrapper}
          maxLength={20}
          onChange={(e) => setChanges('headingText', e.target.value)}
          name="headingText"
          placeholder="About Us"
          label="Heading Copy"
          skipError
        />
        <Typography
          fontType="bodySm"
          color={COLORS.colorText500}
          className={css.subInputInfo}
          lineHeight="142.857%"
        >
          Max 20 characters
        </Typography>
      </div>

      <div>
        <TextField
          value={settings.bodyText}
          maxLength={200}
          onChange={(e) => setChanges('bodyText', e.target.value)}
          name="bodyText"
          placeholder="Write some text here... e.g. We were founded with a simple goal: to be the best place to buy and manage quality promotional products that people..."
          label="Body Copy"
          className={css.textarea}
          labelClassName={css.textareaLabel}
          textAreaWrapperClassName={css.textareaWrapper}
        />
        <Typography
          fontType="bodySm"
          color={COLORS.colorText500}
          className={css.subInputInfo}
          lineHeight="142.857%"
        >
          Max 200 characters
        </Typography>
      </div>

      <div>
        <Typography
          fontType="bodySm"
          color={COLORS.colorPrimaryText}
          className={css.dropzoneLabel}
          lineHeight="142.857%"
        >
          Upload Image
        </Typography>
        <Dropzone
          buttonClassName={css.dropzoneButton}
          accept={JPEG_PNG_ACCEPT_RESTRICTION}
          className={css.imageLoader}
          files={settings.image ? [settings.image] : []}
          maxSize={MAX_FILE_SIZE_10MB_RESTRICTION}
          processFile={handleProcessFile}
          uploadTextWrapperClassName={css.uploadTextWrapper}
          onFilesChanged={(files) => {
            if (!files[0] && !settings.image?.url) {
              return;
            }
            setChanges('image', files[0] || null);
          }}
          additionalText="JPG or PNG, no more than 10MB"
          previewClassName={css.previewImageWrapper}
          helperText="Recommended size is 1200 x 874 pixels"
          helperTextClassName={css.dropzoneHelperText}
        />
      </div>
    </div>
  );
};
