import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const SLICE_NAME = 'technicalData';

interface ITechnicalDataApiSlice {
  featureFlagList: Record<string, boolean>;
}

const initialState: ITechnicalDataApiSlice = {
  featureFlagList: {},
};

export const modals = createSlice({
  initialState,
  name: SLICE_NAME,
  reducers: {
    setFeatureFlags: (
      state,
      action: PayloadAction<{ featureFlagList: Record<string, boolean> }>,
    ) => {
      state.featureFlagList = action.payload.featureFlagList;
    },
  },
});

export const technicalDataReducer = modals.reducer;
export const { setFeatureFlags } = modals.actions;
