import { MultiValue } from 'react-select';
import { MultiOptionType, OptionType } from '@ezetech/swag-space-x';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { CreditCardCreationFlow } from 'interfaces/flow.inteface';
import { ReducerPath, IPromocodeReq, IPromocodeList } from 'interfaces';
import { CREDIT_CARD_ABSENT } from 'constants/promocodes.constants';
import { SUBJECTS } from 'constants/ability.constant';
import { can } from 'boot/ability';
import { CREATE_CREDIT_CARD_POPUP } from 'components/popups/_logic/popups-list';
import { openPopup } from 'redux/slices/modals.slice';
import { makeToastNotification, makeSuccessToastNotification } from 'utils/query.util';
import { pushNotification } from '../slices/notifications.slice';
import { setPromocodes } from '../slices/promocodes.slice';
import { setFilter } from '../slices/filter.slice';
import { baseQueryParams } from './helpers';

export const promocodesApi = createApi({
  reducerPath: ReducerPath.promocodes,
  baseQuery: fetchBaseQuery({ ...baseQueryParams }),
  endpoints: (builder) => ({
    addPromoCode: builder.mutation<void, IPromocodeReq>({
      query: ({ page, dropdown, ...payload }) => ({
        url: '/promocode/',
        method: 'POST',
        body: payload,
      }),
      async onQueryStarted({ page, dropdown }, { dispatch, queryFulfilled }) {
        try {
          const subjectPPName = SUBJECTS.PROMOCODES_VIEW.value;
          const subjectPPActions = SUBJECTS.PROMOCODES_VIEW.actions;
          await queryFulfilled;
          dispatch(pushNotification(makeSuccessToastNotification('Created')));
          if (can(subjectPPActions.ACTIVE_PROMOCODES_VIEW, subjectPPName)) {
            dispatch(promocodesApi.endpoints.getPromoCodes.initiate({ page, dropdown }));
          }
        } catch (e) {
          const err = e as { error: { data: { message: string[] } } };
          if (
            err.error?.data?.message[0] &&
            err.error?.data?.message[0] === CREDIT_CARD_ABSENT
          ) {
            dispatch(
              openPopup({
                popupName: CREATE_CREDIT_CARD_POPUP,
                popupProps: { flow: CreditCardCreationFlow.Promocode },
              }),
            );
          } else {
            dispatch(pushNotification(makeToastNotification(e)));
          }
        }
      },
    }),
    getPromoCodes: builder.mutation<
      IPromocodeList,
      void | { page?: number; perPage?: number; dropdown?: MultiOptionType }
    >({
      query: (params) => {
        const reqParams: { page?: number; perPage?: number; filter?: string } = {
          page: params?.page ?? 1,
          perPage: params?.perPage ?? 10,
        };

        if (params!.dropdown) {
          const filter: Record<string, string[]> = {};

          (params!.dropdown as MultiValue<OptionType>).forEach(({ value }) => {
            filter.type = [...(filter.type || []), value];
          });

          reqParams.filter = JSON.stringify(filter);
        }

        return {
          url: '/promocode/list',
          method: 'GET',
          params: reqParams || {},
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setPromocodes(data.list));
          dispatch(
            setFilter({
              page: data.page,
              perPage: data.perPage,
              totalCount: data.total,
            }),
          );
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),
    removePromoCode: builder.mutation<void, string>({
      query: (id) => ({
        url: `/promocode/${id}`,
        method: 'DELETE',
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(pushNotification(makeSuccessToastNotification('Removed')));
          dispatch(promocodesApi.endpoints.getPromoCodes.initiate({}));
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),
  }),
});

export const {
  useAddPromoCodeMutation,
  useGetPromoCodesMutation,
  useRemovePromoCodeMutation,
} = promocodesApi;
