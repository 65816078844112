import { emailRegexp } from '../../../constants/validation';

export const FIELDS = {
  email: {
    label: 'Your email',
    name: 'email',
    placeholder: 'Enter Email',
    rules: {
      required: 'Email is required',
      pattern: {
        value: emailRegexp,
        message: "Email you entered doesn't seem to be valid",
      },
    },
  },
};
