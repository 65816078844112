import { FC, useState, useEffect, useRef } from 'react';
import { Input, OptionType, Select, InputProps } from '@ezetech/swag-space-x';

import {
  AVAILABLE_COUNTRIES_LIST,
  CountriesStatesSelect,
  getStates,
} from 'constants/settings.constants';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import {
  addressErrorsSelector,
  editedCustomerCompanyDetailsSelector,
  userErrorsSelector,
} from '../../../redux/selectors/customer-details.selector';
import { updateAddress, updateUser } from '../../../redux/slices/customer-details.slice';
import css from './edit-customer.popup.module.scss';

export const CONTENT_ID = 'edit-customer-container';

const PhoneInput: FC<InputProps> = (props) => {
  const { value, onChange, ...rest } = props;
  const [cursor, setCursor] = useState<number | null>(null);
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    ref.current?.setSelectionRange(cursor, cursor);
  }, [ref, cursor, value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCursor(e.target.selectionStart);
    onChange?.(e);
  };

  return <Input ref={ref} value={value} onChange={handleChange} {...rest} />;
};

export const EditCustomerForm: FC<{ isOpen?: boolean }> = ({ isOpen }) => {
  const dispatch = useAppDispatch();
  const [rect, setRect] = useState<DOMRect | undefined>();
  const {
    firstName,
    lastName,
    email,
    phone,
    companyName,
    state,
    city,
    zip,
    address,
    country,
  } = useAppSelector(editedCustomerCompanyDetailsSelector);
  const userErrors = useAppSelector(userErrorsSelector);
  const addressErrors = useAppSelector(addressErrorsSelector);

  const getClientRect = (): DOMRect | undefined => {
    const editCustomerContainer = document.getElementById(CONTENT_ID);

    return editCustomerContainer?.getBoundingClientRect();
  };

  useEffect(() => {
    setRect(isOpen ? getClientRect() : undefined);
  }, [isOpen]);

  const handleUserChange = (key: string, value: string) => {
    dispatch(
      updateUser({
        key,
        value,
      }),
    );
  };

  const handleOpenMenu = () => {
    setRect(getClientRect());
  };

  const handleAddressChange = (key: string, value: string) => {
    dispatch(
      updateAddress({
        key,
        value,
      }),
    );
  };

  const calculatePosition = ({ left, top }: Record<string, unknown>) => {
    return {
      left: (left as number) - (rect?.x || 0),
      top: (top as number) - (rect?.y || 0),
    };
  };

  const countryValue =
    AVAILABLE_COUNTRIES_LIST.find(({ value }) => value === country) ?? null;
  const availableStates =
    country === CountriesStatesSelect.CA || country === CountriesStatesSelect.US
      ? getStates(country)
      : [];
  const stateValue = availableStates.find(({ value }) => value === state) ?? null;

  return (
    <>
      <div className={css.twoInRowContainer}>
        <Input
          name="firstName"
          label="First Name"
          placeholder="Enter First Name"
          onChange={(e) => handleUserChange('firstName', e.target.value)}
          value={firstName}
          className={css.input}
          type="text"
          errors={userErrors.firstName}
          required
        />
        <Input
          name="lastName"
          label="Last Name"
          placeholder="Enter Last Name"
          onChange={(e) => handleUserChange('lastName', e.target.value)}
          value={lastName}
          className={css.input}
          type="text"
          errors={userErrors.lastName}
          required
        />
      </div>
      <div className={css.twoInRowContainer}>
        <Input
          name="email"
          label="Email"
          placeholder="Enter Email"
          onChange={(e) => handleUserChange('email', e.target.value)}
          value={email}
          className={css.input}
          type="email"
          errors={userErrors.email}
          required
        />
        <PhoneInput
          name="phone"
          label="Cell Phone"
          placeholder="Enter Cell Phone"
          onChange={(e) => handleUserChange('phone', e.target.value)}
          value={phone}
          className={css.input}
          type="tel"
          errors={userErrors.phone}
        />
      </div>
      <Input
        name="companyName"
        label="Company Name"
        placeholder="Enter Company Name"
        onChange={(e) => handleUserChange('companyName', e.target.value)}
        value={companyName}
        type="text"
        errors={userErrors.companyName}
      />
      <Input
        name="address"
        label="Address"
        placeholder="Enter Address"
        onChange={(e) => handleAddressChange('address', e.target.value)}
        value={address ?? ''}
        className={css.input}
        type="text"
        errors={addressErrors.address}
        required
      />
      <div className={css.twoInRowContainer}>
        <Input
          name="city"
          label="City"
          placeholder="Enter City"
          onChange={(e) => handleAddressChange('city', e.target.value)}
          value={city ?? ''}
          className={css.input}
          type="text"
          errors={addressErrors.city}
          required
        />
        <Select
          name="county"
          label="County"
          value={countryValue}
          onChange={(value) =>
            handleAddressChange('country', (value as OptionType).value)
          }
          styles={{
            menuPortal: (style) => ({
              ...style,
              ...calculatePosition(style),
            }),
          }}
          onMenuOpen={handleOpenMenu}
          placeholder="Enter Country"
          options={AVAILABLE_COUNTRIES_LIST}
          className={css.input}
          errors={addressErrors.country}
          menuPosition="fixed"
          menuPlacement="auto"
          menuShouldBlockScroll
          required
        />
      </div>
      <div className={css.twoInRowContainer}>
        <Input
          name="zip"
          label="Zip / Postal Code"
          placeholder="Enter Zip / Postal Code"
          onChange={(e) => handleAddressChange('zip', e.target.value)}
          value={zip ?? ''}
          className={css.input}
          type="text"
          errors={addressErrors.zip}
          required
        />
        {country === CountriesStatesSelect.CA || country === CountriesStatesSelect.US ? (
          <Select
            name="state"
            label="State"
            placeholder="Enter State"
            options={availableStates}
            value={stateValue}
            menuPosition="fixed"
            menuPlacement="auto"
            onMenuOpen={handleOpenMenu}
            menuShouldBlockScroll
            styles={{
              menuPortal: (style) => ({
                ...style,
                ...calculatePosition(style),
              }),
            }}
            onChange={(value) =>
              handleAddressChange('state', (value as OptionType).value)
            }
            className={css.input}
            errors={addressErrors.state}
            required
          />
        ) : (
          <Input
            name="state"
            label="State"
            placeholder="Enter State"
            onChange={(e) => handleAddressChange('state', e.target.value)}
            value={state ?? ''}
            className={css.input}
            type="text"
            errors={addressErrors.state}
          />
        )}
      </div>
    </>
  );
};
