import * as Yup from 'yup';
import { EMAIL_LENGTH, emailRegexp, ERROR_MSG } from 'constants/validation';

export const teamMemberDetailsValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .strict(true)
    .trim(ERROR_MSG.trim)
    .min(2, ERROR_MSG.min(2))
    .max(20, ERROR_MSG.max(20))
    .required(ERROR_MSG.required),
  lastName: Yup.string()
    .strict(true)
    .trim(ERROR_MSG.trim)
    .min(2, ERROR_MSG.min(2))
    .max(20, ERROR_MSG.max(20))
    .required(ERROR_MSG.required),
  email: Yup.string()
    .strict(true)
    .trim(ERROR_MSG.trim)
    .matches(emailRegexp, ERROR_MSG.email)
    .max(EMAIL_LENGTH, ERROR_MSG.max(EMAIL_LENGTH))
    .required(ERROR_MSG.required),
});
