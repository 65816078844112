import * as yup from 'yup';

import { BICSwiftRegexp, ERROR_MSG, routingNumberRegexp } from 'constants/validation';
import { Saving } from '../payout/payout.contants';
import { FIELDS } from './invoice.contants';

export const invoiceFormSchema = yup.object({
  [FIELDS.invoiceAsInPayout.name]: yup.boolean().optional(),
  [FIELDS.invoiceBankCountry.name]: yup
    .string()
    .trim()
    .when(FIELDS.invoiceAsInPayout.name, (isSame, field) => {
      return !isSame[0]
        ? field
            .min(2, ERROR_MSG.min(2))
            .max(2, ERROR_MSG.max(2))
            .required(ERROR_MSG.required)
        : field.optional().nullable();
    }),
  [FIELDS.invoiceBankName.name]: yup
    .string()
    .trim()
    .when(FIELDS.invoiceAsInPayout.name, (isSame, field) => {
      return !isSame[0]
        ? field
            .min(2, ERROR_MSG.min(2))
            .max(80, ERROR_MSG.max(80))
            .required(ERROR_MSG.required)
        : field.optional().nullable();
    }),
  [FIELDS.invoiceAccountType.name]: yup
    .string()
    .trim()
    .oneOf(Object.values(Saving))
    .when(FIELDS.invoiceAsInPayout.name, (isSame, field) => {
      return !isSame[0]
        ? field.required(ERROR_MSG.required)
        : field.optional().nullable();
    }),
  [FIELDS.invoiceAccountBeneficiaryName.name]: yup
    .string()
    .trim()
    .when(FIELDS.invoiceAsInPayout.name, (isSame, field) => {
      return !isSame[0]
        ? field
            .min(2, ERROR_MSG.min(2))
            .max(80, ERROR_MSG.max(80))
            .required(ERROR_MSG.required)
        : field.optional().nullable();
    }),
  [FIELDS.invoiceBankAccountNumber.name]: yup
    .string()
    .trim()
    .when(FIELDS.invoiceAsInPayout.name, (isSame, field) => {
      return !isSame[0]
        ? field
            .min(5, ERROR_MSG.min(5))
            .max(34, ERROR_MSG.max(34))
            .required(ERROR_MSG.required)
        : field.optional().nullable();
    }),
  [FIELDS.invoiceBIC.name]: yup
    .string()
    .trim()
    .when(
      [FIELDS.invoiceAsInPayout.name, FIELDS.invoiceBankCountry.name],
      ([isSame, country], field) => {
        if (isSame) {
          return field
            .transform((value) => (!value ? null : value))
            .matches(BICSwiftRegexp, ERROR_MSG.BICSwiftInvalid)
            .optional()
            .nullable();
        }

        return country !== 'US'
          ? field
              .matches(BICSwiftRegexp, ERROR_MSG.BICSwiftInvalid)
              .required(ERROR_MSG.required)
          : field
              .transform((value) => (!value ? null : value))
              .matches(BICSwiftRegexp, ERROR_MSG.BICSwiftInvalid)
              .optional()
              .nullable();
      },
    ),
  [FIELDS.invoiceRoutingNumber.name]: yup
    .string()
    .trim()
    .when(
      [FIELDS.invoiceAsInPayout.name, FIELDS.invoiceBankCountry.name],
      ([isSame, country], field) => {
        return !isSame && country === 'US'
          ? field
              .transform((value) => (!value ? null : value))
              .matches(routingNumberRegexp, ERROR_MSG.routingNumberInvalid)
              .optional()
              .nullable()
          : field.optional().nullable();
      },
    ),
});
