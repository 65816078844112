import cn from 'classnames';
import { Checkbox, Input, TextField, Typography } from '@ezetech/swag-space-x';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { SidebarContactFormBlockProps } from '../interface';

import css from './sidebar.module.scss';

export const SidebarContactFormBlock = (
  props: SidebarContactFormBlockProps,
): JSX.Element => {
  const { settings, setChanges } = props;

  return (
    <div className={css.sidebarMainBlock}>
      <div>
        <Checkbox
          onCheckedChange={(e) => setChanges('isHidden', e)}
          name="isHidden"
          checked={settings.isHidden}
          label="Hide section"
        />
      </div>
      <div
        className={cn(css.disabledBlock, { [css.disabledOverlay]: settings.isHidden })}
      />
      <div>
        <Input
          autoComplete="off"
          value={settings.headingText}
          className={css.inputWrapper}
          maxLength={20}
          onChange={(e) => setChanges('headingText', e.target.value)}
          name="headingText"
          placeholder="Let us know how we can help"
          label="Heading Copy"
          skipError
        />
        <Typography
          fontType="bodySm"
          color={COLORS.colorText500}
          className={css.subInputInfo}
          lineHeight="142.857%"
        >
          Max 20 characters
        </Typography>
      </div>

      <div>
        <TextField
          value={settings.bodyText}
          maxLength={200}
          onChange={(e) => setChanges('bodyText', e.target.value)}
          name="bodyText"
          placeholder="Write some text here... e.g. Get in touch with us so that we can help you with some amazing Swag."
          label="Body Copy"
          className={css.textarea}
          labelClassName={css.textareaLabel}
          textAreaWrapperClassName={css.textareaWrapper}
        />
        <Typography
          fontType="bodySm"
          color={COLORS.colorText500}
          className={css.subInputInfo}
          lineHeight="142.857%"
        >
          Max 200 characters
        </Typography>
      </div>
    </div>
  );
};
