import { createSelector } from 'reselect';

import { MIN_WITHDRAWAL_BALANCE } from 'constants/withdraw.constants';

import { RootState } from '../store';

export const withdrawSelector = (state: RootState) => state.withdraw;

export const incomingMoneySelector = createSelector(
  withdrawSelector,
  (state) => state.income,
);
export const pendingMoneySelector = createSelector(
  withdrawSelector,
  (state) => state.pending,
);
export const availableMoneySelector = createSelector(
  withdrawSelector,
  (state) => state.available,
);

export const isLowBalanceSelector = createSelector(
  withdrawSelector,
  (state) => state.available < MIN_WITHDRAWAL_BALANCE,
);
