import { GeneralPermissions, Permission } from 'interfaces/permission.interface';
import { AssignedEntitiesData } from 'interfaces/invitation.interface';
import {
  ASSIGNMENT_FLOW_PERMISSIONS,
  AssignmentEntity,
} from 'interfaces/assignee.interface';

export const turnOnAllPermissions = (
  userPermissions: GeneralPermissions,
): GeneralPermissions => {
  return Object.entries(userPermissions).reduce(
    (acc, [group, { permissions, title: groupTitle }]) => {
      acc[group] = {
        title: groupTitle,
        permissions: Object.entries(permissions).reduce(
          (acc2, [permission, { title }]) => {
            acc2[permission] = {
              title,
              value: true,
            };
            return acc2;
          },
          {} as Permission,
        ),
      };
      return acc;
    },
    {} as GeneralPermissions,
  );
};

export function checkShouldShowConfirmationOnChange({
  value,

  permission,
  assignedEntities,
}: {
  value: boolean;
  permission: string;
  assignedEntities?: AssignedEntitiesData;
}) {
  if (value || !assignedEntities) {
    return false;
  }

  switch (permission) {
    case ASSIGNMENT_FLOW_PERMISSIONS.ORDER_ASSIGNMENT:
      return Boolean(assignedEntities[AssignmentEntity.ORDER]);
    case ASSIGNMENT_FLOW_PERMISSIONS.CREATE_EDIT_INVOICE:
      return Boolean(assignedEntities[AssignmentEntity.INVOICE]);
    case ASSIGNMENT_FLOW_PERMISSIONS.CREATE_EDIT_PDF_PRESENTATION:
      return Boolean(assignedEntities[AssignmentEntity.PDF]);
    case ASSIGNMENT_FLOW_PERMISSIONS.CREATE_EDIT_BUILD_A_CART:
      return Boolean(assignedEntities[AssignmentEntity.CART_LINK]);
    case ASSIGNMENT_FLOW_PERMISSIONS.EDIT_CUSTOMERS_DETAILS:
      return (
        Boolean(assignedEntities[AssignmentEntity.CUSTOMER]) ||
        Boolean(assignedEntities[AssignmentEntity.LEAD])
      );
    case ASSIGNMENT_FLOW_PERMISSIONS.ABANDONED_CHECKOUTS:
      return Boolean(assignedEntities[AssignmentEntity.ABANDONED_CHECKOUT]);
    default:
      return false;
  }
}
