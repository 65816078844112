import { IFile } from '@ezetech/swag-space-x';

export enum ColorType {
  Dark = 'dark',
  Light = 'light',
}

export enum ButtonColorType {
  Primary = 'primary',
  Secondary = 'secondary',
}

export interface IHomepageSettingsHero {
  headingText: string;
  bodyText: string;
  textColorType: ColorType;

  buttonText: string;
  buttonTextColorType: ColorType;
  buttonColorType: ButtonColorType;

  image: IFile | null;
}

export interface IHomepageSettingsBrandLogos {
  isHidden: boolean;
  headingText: string;
  images: Array<IFile | null>; // up to 8
}

export interface IHomepageSettingsAboutUs {
  isHidden: boolean;
  headingText: string;
  bodyText: string;
  image: IFile | null;
}

export interface IHomepageSettingsCategoriesCategory {
  id: string;
  name: string;
  publicId: string;
  image: IFile | null;
}

export interface IHomepageSettingsCategories {
  isHidden: boolean;
  headingText: string;
  categories: IHomepageSettingsCategoriesCategory[]; // up to 6
}

export interface IHomepageSettingsPromoProduct {
  isHidden: boolean;
  headingText: string;
  products: {
    publicId: string;
    name: string;
    logo: string;
    isForbiddenToShowOnDashboard: boolean;
  }[]; // up to 9
}

export interface IHomepageSettingsImagesRow {
  isHidden: boolean;
  images: Array<IFile | null>; // up to 3
}

export interface IHomepageSettingsBrandsWeOffer {
  isHidden: boolean;
}

export interface IHomepageSettingsContactForm {
  isHidden: boolean;
  headingText: string;
  bodyText: string;
}

export interface IHomepageSettings {
  heroBlock?: IHomepageSettingsHero;
  brandLogosBlock?: IHomepageSettingsBrandLogos;
  aboutUsBlock?: IHomepageSettingsAboutUs;
  categoriesBlock?: IHomepageSettingsCategories;
  promoProductBlock?: IHomepageSettingsPromoProduct;
  imagesRowBlock?: IHomepageSettingsImagesRow;
  brandsWeOfferBlock?: IHomepageSettingsBrandsWeOffer;
  contactFormBlock?: IHomepageSettingsContactForm;
}

export interface IHomepageSettingsDb extends IHomepageSettings {
  id?: string;
  companySettingsId?: string;
  isHomePageActive?: boolean;
  customHomePageUpdatedAt?: Date | string;
  createdAt?: Date | string;
  updatedAt?: Date | string;
}
