import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Schema, ValidationError } from 'yup';
import { PayloadActionCreator } from '@reduxjs/toolkit';
import { can, SUBJECTS } from 'boot/ability';
import {
  IBillingAddress,
  ICompany,
  IFieldError,
  TAB_NAMES,
} from 'interfaces/settings.interface';
import { IStoreSettingsBack } from 'interfaces/store-creation.interfaces';
import {
  mapCompanyToDB,
  mapHoursToDB,
  mapInvoiceToDB,
  mapPayoutToDB,
} from 'utils/settings/mappers';
import { makeSuccessToastNotification } from 'utils/query.util';

import { isObjectEmpty } from 'utils/is-object-empty.util';
import { useAppDispatch } from '../../redux/store';
import {
  billingAddressSelector,
  companyAndBrandSaveDisabledSelector,
  companyDetailsSelector,
  detailsFormEditedSelector,
  hoursOperationEditedSelector,
  hoursOperationsSaveDisabledSelector,
  hoursOperationsSelector,
  invoiceVisibleSelector,
  paymentEditedSelector,
  paymentInvoiceEditedSelector,
  paymentPayoutEditedSelector,
  paymentSaveDisabledSelector,
  payoutSelector,
  productAndMarginSaveDisabledSelector,
  selectIsProductSettingsAndCommissionEdited,
  selectModifiedCompanySettings,
  selectModifiedProductSettingsAndCommission,
  socialSelector,
  storeIntercomAppIdSelector,
  storePreferencesSelector,
  timezoneEditedSelector,
  timezoneSaveDisabledSelector,
  timezoneSelector,
} from '../../redux/selectors/settings.selectors';
import {
  usePatchBillingAddressMutation,
  usePatchCompanyMutation,
  usePatchCompanySettingsMutation,
  usePatchInvoiceMutation,
  usePatchPayoutMutation,
  useValidateIntercomAppIdMutation,
} from '../../redux/api/settings.api';
import {
  discardCompanyAndBrand,
  discardOperationHours,
  discardPayment,
  discardProductAndMargin,
  setBillingAddressError,
  setCompanyError,
  setInvoiceError,
  setPayoutError,
  setSocialError,
} from '../../redux/slices/settings.slice';
import { addressFormSchema } from '../../pages/settings/company-and-brand/forms/address/address.schema';
import { detailsFormSchema } from '../../pages/settings/company-and-brand/forms/details/details.schema';
import { socialFormSchema } from '../../pages/settings/company-and-brand/forms/social/social.schema';
import { storePreferencesFormSchema } from '../../pages/settings/company-and-brand/forms/store-preferences/store-preferences.schema';
import {
  NotificationType,
  pushNotification,
} from '../../redux/slices/notifications.slice';
import { payoutFormSchema } from '../../pages/settings/plan-and-payment/forms/payout/payout.schema';
import { invoiceFormSchema } from '../../pages/settings/plan-and-payment/forms/invoice/invoice.schema';

interface SaveActionParams {
  onSavedCallback?: () => void;
  redirectFromRoute?: string;
}

export function useSave() {
  const dispatch = useAppDispatch();
  const pathname = useMemo(
    () => location.pathname.split('/').reverse()[0],
    [location.pathname],
  );

  // Company and Brand
  const billingAddress = useSelector(billingAddressSelector);
  const details = useSelector(companyDetailsSelector);
  const socials = useSelector(socialSelector);
  const storePreferences = useSelector(storePreferencesSelector);
  const intercomAppId = useSelector(storeIntercomAppIdSelector);
  const isShownCompanyAndBrand = useSelector(detailsFormEditedSelector);
  const companyAndBrandSaveDisabled = useSelector(companyAndBrandSaveDisabledSelector);

  // Product And Margin
  const productAndMarginSaveDisabled = useSelector(productAndMarginSaveDisabledSelector);
  const isShownProductAndMargin: boolean = useSelector(
    selectIsProductSettingsAndCommissionEdited,
  );

  // Hours Operation
  const hoursOperations = useSelector(hoursOperationsSelector);
  const timezone = useSelector(timezoneSelector);
  const isShownHoursOperations = useSelector(hoursOperationEditedSelector);
  const isShownTimezone = useSelector(timezoneEditedSelector);
  const hoursOperationsSaveDisabled = useSelector(hoursOperationsSaveDisabledSelector);
  const timezoneSaveDisabled = useSelector(timezoneSaveDisabledSelector);
  const paymentSaveDisabled = useSelector(paymentSaveDisabledSelector);

  const [patchBillingAddress, { isLoading: isLoadingBillingAddress }] =
    usePatchBillingAddressMutation();
  const [patchCompany, { isLoading: isLoadingCompany }] = usePatchCompanyMutation();
  const [patchCompanySettings, { isLoading: isLoadingCompanySettings }] =
    usePatchCompanySettingsMutation();
  const [patchPayout, { isLoading: isLoadingPayout }] = usePatchPayoutMutation();
  const [patchInvoice, { isLoading: isLoadingInvoice }] = usePatchInvoiceMutation();
  const [validateIntercomAppId] = useValidateIntercomAppIdMutation();

  // Plan And Payment
  const payout = useSelector(payoutSelector);
  const invoice = useSelector(invoiceVisibleSelector);
  const isShownPayment = useSelector(paymentEditedSelector);
  const payoutEdited = useSelector(paymentPayoutEditedSelector);
  const invoiceEdited = useSelector(paymentInvoiceEditedSelector);

  const modifiedCompanySettings: Partial<IStoreSettingsBack> = useSelector(
    selectModifiedCompanySettings,
  );

  const modifiedProductsAndCommissionSettings: Partial<IStoreSettingsBack> = useSelector(
    selectModifiedProductSettingsAndCommission,
  );

  // Policy
  const hasPolicyForCompanyDetails =
    can(
      SUBJECTS.COMPANY_DETAILS.actions.COMPANY_DETAILS_VIEW,
      SUBJECTS.COMPANY_DETAILS.value,
    ) &&
    can(
      SUBJECTS.COMPANY_DETAILS.actions.COMPANY_DETAILS_EDIT,
      SUBJECTS.COMPANY_DETAILS.value,
    );

  const hasPolicyForBillingAddress =
    can(
      SUBJECTS.BILLING_ADDRESS.actions.BILLING_ADDRESS_EDIT,
      SUBJECTS.BILLING_ADDRESS.value,
    ) &&
    can(
      SUBJECTS.BILLING_ADDRESS.actions.BILLING_ADDRESS_VIEW,
      SUBJECTS.BILLING_ADDRESS.value,
    );

  const isDisabled = useMemo(() => {
    if (pathname === TAB_NAMES.CompanyAndBrand) {
      return companyAndBrandSaveDisabled;
    }
    if (pathname === TAB_NAMES.ProductsAndCommission) {
      return productAndMarginSaveDisabled;
    }
    if (pathname === TAB_NAMES.OperationHours) {
      return hoursOperationsSaveDisabled || timezoneSaveDisabled;
    }
    if (pathname === TAB_NAMES.PlanAndPayment) {
      return paymentSaveDisabled;
    }
    return false;
  }, [
    pathname,
    companyAndBrandSaveDisabled,
    productAndMarginSaveDisabled,
    timezoneSaveDisabled,
    hoursOperationsSaveDisabled,
    paymentSaveDisabled,
  ]);

  const isShown = useMemo(() => {
    if (pathname === TAB_NAMES.CompanyAndBrand) {
      return isShownCompanyAndBrand;
    }
    if (pathname === TAB_NAMES.ProductsAndCommission) {
      return isShownProductAndMargin;
    }
    if (pathname === TAB_NAMES.OperationHours) {
      return isShownHoursOperations || isShownTimezone;
    }
    if (pathname === TAB_NAMES.PlanAndPayment) {
      return isShownPayment;
    }
    return false;
  }, [
    pathname,
    isShownCompanyAndBrand,
    isShownProductAndMargin,
    isShownHoursOperations,
    isShownTimezone,
    isShownPayment,
  ]);

  const onDiscard = () => {
    if (pathname === TAB_NAMES.CompanyAndBrand) {
      dispatch(discardCompanyAndBrand());
    }
    if (pathname === TAB_NAMES.ProductsAndCommission) {
      dispatch(discardProductAndMargin());
    }
    if (pathname === TAB_NAMES.OperationHours) {
      dispatch(discardOperationHours());
    }
    if (pathname === TAB_NAMES.PlanAndPayment) {
      dispatch(discardPayment());
    }
  };

  const formatError = (errors: ValidationError[]) => {
    return errors.reduce<IFieldError>((acc, err) => {
      if (err.path) {
        return {
          ...acc,
          [err.path]: err.errors[0],
        };
      }
      return acc;
    }, {});
  };

  const validateScope = (
    schema: Schema,
    values: unknown,
    actionCreator: PayloadActionCreator<IFieldError>,
    shouldValidate = true,
  ) => {
    try {
      if (shouldValidate) {
        // eslint-disable-next-line no-sync
        schema.validateSync(values, { abortEarly: false });
      }
      return true;
    } catch (error) {
      const { inner } = error as ValidationError;
      const errors = formatError(inner);
      dispatch(actionCreator(errors));
      return false;
    }
  };

  const companyAndBrandHandler = async (callback: () => void) => {
    const billingChanged = Object.values(billingAddress).some((val) => !!val);
    const billingValid = hasPolicyForBillingAddress
      ? validateScope(
          addressFormSchema,
          billingAddress,
          setBillingAddressError,
          billingChanged,
        )
      : true;
    const detailsValid = hasPolicyForCompanyDetails
      ? validateScope(detailsFormSchema, details, setCompanyError)
      : true;

    const socialValid = validateScope(socialFormSchema, socials, setSocialError);
    const storePreferencesValid = validateScope(
      storePreferencesFormSchema,
      storePreferences,
      setSocialError,
    );

    let intercomAppIdValid = !intercomAppId;
    if (intercomAppId) {
      const validationResult = await validateIntercomAppId(intercomAppId);
      intercomAppIdValid = 'data' in validationResult && validationResult.data.valid;
    }
    if (
      billingValid &&
      detailsValid &&
      socialValid &&
      storePreferencesValid &&
      intercomAppIdValid
    ) {
      const companyDetailsPayload: ICompany = {
        ...mapCompanyToDB(details),
      };
      const billingPayload: IBillingAddress = {
        ...billingAddress,
      };

      const promises = [];

      if (!isObjectEmpty(modifiedCompanySettings)) {
        promises.push(patchCompanySettings(modifiedCompanySettings));
      }

      if (hasPolicyForCompanyDetails) {
        promises.push(patchCompany(companyDetailsPayload));
      }

      if (billingChanged && hasPolicyForBillingAddress) {
        promises.push(patchBillingAddress(billingPayload));
      }

      const results = await Promise.all(promises);
      const hasError = results.reduce<boolean>((isError, res) => {
        if (isError) {
          return isError;
        }
        return 'error' in res;
      }, false);
      if (!hasError) {
        callback();
      }
    } else {
      dispatch(
        pushNotification({
          message: 'Some fields are invalid',
          type: NotificationType.toasts,
          status: 'negative',
        }),
      );
    }
  };

  const productAndMarginHandler = async (callback: () => void) => {
    if (isObjectEmpty(modifiedProductsAndCommissionSettings)) {
      return;
    }

    const response = await patchCompanySettings(modifiedProductsAndCommissionSettings);

    if (!('error' in response)) {
      callback();
    }
  };

  const hoursOperationHandler = async (callback: () => void) => {
    const companySettingsPayload: Partial<IStoreSettingsBack> = {
      hoursOperations: mapHoursToDB(hoursOperations),
      timezone,
    };
    await patchCompanySettings(companySettingsPayload);
    callback();
  };

  const paymentHandler = async (callback: () => void) => {
    const payoutValid = payoutEdited
      ? validateScope(payoutFormSchema, payout, setPayoutError)
      : true;
    const invoiceValid = invoiceEdited
      ? validateScope(invoiceFormSchema, invoice, setInvoiceError)
      : true;
    if (payoutValid && invoiceValid) {
      const results = await Promise.all([
        payoutEdited
          ? patchPayout(mapPayoutToDB(payout))
          : Promise.resolve({ data: null }),
        invoiceEdited
          ? patchInvoice(mapInvoiceToDB(invoice))
          : Promise.resolve({ data: null }),
      ]);
      const hasError = results.reduce<boolean>((isError, res) => {
        if (isError) {
          return isError;
        }
        return 'error' in res;
      }, false);
      if (!hasError) {
        callback();
      }
    } else {
      dispatch(
        pushNotification({
          message: 'Some fields are invalid',
          type: NotificationType.toasts,
          status: 'negative',
        }),
      );
    }
  };

  const onSave = (params?: SaveActionParams) => {
    const { onSavedCallback, redirectFromRoute } = params || {};
    const callback = () => {
      dispatch(pushNotification(makeSuccessToastNotification('Settings updated!')));

      if (onSavedCallback) {
        onSavedCallback();
      }
    };

    const processRouteChange = (route: string) => {
      if (route === TAB_NAMES.CompanyAndBrand) {
        companyAndBrandHandler(callback);
      }
      if (route === TAB_NAMES.ProductsAndCommission) {
        productAndMarginHandler(callback);
      }
      if (route === TAB_NAMES.OperationHours) {
        hoursOperationHandler(callback);
      }
      if (route === TAB_NAMES.PlanAndPayment) {
        paymentHandler(callback);
      }
    };

    if (!isDisabled) {
      if (redirectFromRoute) {
        processRouteChange(redirectFromRoute);
      } else {
        processRouteChange(pathname);
      }
    }
  };

  return {
    isLoading:
      isLoadingCompany ||
      isLoadingCompanySettings ||
      isLoadingBillingAddress ||
      isLoadingPayout ||
      isLoadingInvoice,
    isShown,
    onSave,
    onDiscard,
    isDisabled,
  };
}
