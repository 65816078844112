import { ToastsProvider, ToastsViewport, Toasts } from '@ezetech/swag-space-x';
import { INotification } from '../../redux/slices/notifications.slice';
import css from './notification.module.scss';

const DURATION = 6000;
const STATUS = 'general';
const ACTION = false;

const VERTICAL = 'bottom';
const HORIZONTAL = 'left';

type ToastsNotificationProps = {
  toasts: INotification[];
};

export const ToastsNotification = ({ toasts }: ToastsNotificationProps): JSX.Element => {
  return (
    <ToastsProvider>
      {toasts.map((toast, index) => (
        <Toasts
          textClassName={css.text}
          closeClassName={css.close}
          className={css.notification}
          animationDirection="right"
          animationDuration="1500ms"
          key={toast.key || index}
          status={toast.status || STATUS}
          duration={toast.timeout || DURATION}
          action={toast.action || ACTION}
        >
          {toast.message}
        </Toasts>
      ))}
      <ToastsViewport vertical={VERTICAL} horizontal={HORIZONTAL} />
    </ToastsProvider>
  );
};
