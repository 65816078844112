import { can } from 'boot/ability';
import { initialLeadsFiltersSelector } from 'redux/selectors/customers-leads.selectors';
import { pushNotification } from 'redux/slices/notifications.slice';
import { resetFilters, setFilter } from 'redux/slices/filter.slice';
import { makeToastNotification } from 'utils/query.util';
import { assigneeApi } from 'redux/api/assignee.api';
import { userApi } from 'redux/api/user.api';
import { fetchUserData } from 'utils/user.util';
import { IAction } from 'interfaces/loaders.interface';
import { SUBJECTS } from 'constants/ability.constant';
import {
  PER_PAGE_CARTS,
  DEFAULT_PAGE,
  DEFAULT_SORTING_ORDER,
} from 'constants/carts.constant';
import { CLEntity } from 'interfaces/customers-leads.interface.api';
import { fetchStoreSettingsData } from 'utils/store-settings.util';

import { store } from '../../redux/store';

export async function leadsLoader(): Promise<null> {
  store.dispatch(resetFilters());

  await fetchUserData();
  await fetchStoreSettingsData();

  const state = store.getState();
  const initialLeadsFilters = initialLeadsFiltersSelector(state);

  store.dispatch(setFilter({ dropdown: initialLeadsFilters }));

  const requests: Record<string, unknown>[] = [
    {
      endpoint: userApi.endpoints.getUsers.initiate({
        page: DEFAULT_PAGE,
        perPage: PER_PAGE_CARTS,
        search: '',
        sorting: '',
        sortingOrder: DEFAULT_SORTING_ORDER,
        customersOrLeads: CLEntity.leads,
        dropdown: initialLeadsFilters,
      }),
      condition: can(SUBJECTS.LEADS.actions.VIEW_LEADS_LIST, SUBJECTS.LEADS.value),
    },
    { endpoint: assigneeApi.endpoints.getAssigneeListForLeads.initiate() },
  ];

  try {
    const results = await Promise.all(
      requests.map(async (request) => {
        if (request?.condition === false) {
          return;
        }

        return store.dispatch(request.endpoint as IAction).unwrap();
      }),
    );

    if (results.some((result) => result && 'error' in result)) {
      return null;
    }
  } catch (e) {
    store.dispatch(pushNotification(makeToastNotification(e)));
  }

  return null;
}
