import cn from 'classnames';
import { Typography } from '@ezetech/swag-space-x';

import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { ResellerMockupApprovalPopupProps } from 'interfaces/popup.interface';

import css from './reseller-mockup-approval-popup.module.scss';
import { ProductionMockupViewer } from './production-mockup-viewer';
import { PaginationBlock } from './pagination-block';

export const CompareView = ({
  page,
  isPDF,
  isMobile,
  lastPage,
  setLastPage,
  isCompareMode,
  handlePageClick,
  productionMockup,
  customerMockupImg,
  customerMockupPage,
  customPageIndicator,
  onCustomerMockupPage,
  maxCustomerMockupPage,
  isSinglePageCustomerMockup,
  maxCustomerMockupPageIndicator,
}: {
  isPDF: boolean;
  isCompareMode: boolean;
  isMobile: boolean;
  productionMockup: ResellerMockupApprovalPopupProps['productionMockup'];
  customerMockupImg: string;
  page: number;
  setLastPage: (page: number) => void;
  lastPage: number;
  customerMockupPage: number;
  customPageIndicator: string;
  isSinglePageCustomerMockup?: boolean;
  maxCustomerMockupPage: number;
  maxCustomerMockupPageIndicator: string;
  handlePageClick: (page: number) => void;
  onCustomerMockupPage: (page: number) => void;
}) => {
  const shouldRenderCMPagination = isMobile && !isSinglePageCustomerMockup;
  const shouldRenderPMPagination = isMobile && isPDF;

  return (
    <div
      className={cn(css.contentWrapper, {
        [css.compareMode]: isCompareMode,
        [css.contentWrapperWithoutPMPagination]: !shouldRenderPMPagination,
        [css.contentWrapperWithoutCMPagination]: !shouldRenderCMPagination,
      })}
    >
      <div className={css.mockupImg}>
        <ProductionMockupViewer
          url={productionMockup?.fileName || ''}
          isPDF={isPDF}
          page={page}
          setLastPage={setLastPage}
          imgClassName={css.imgClassName}
          pagePreviewClassName={css.pagePreviewClassName}
        />
        <span className={css.label}>
          <Typography
            color={COLORS.colorPrimaryText}
            fontType="bodySm"
            lineHeight="162.5%"
          >
            Production Mockup
          </Typography>
        </span>
      </div>

      {shouldRenderPMPagination && (
        <PaginationBlock
          hideCMPagination
          isCompareMode={isCompareMode}
          customerMockupPage={customerMockupPage}
          onCustomerMockupPage={onCustomerMockupPage}
          maxCustomerMockupPage={maxCustomerMockupPage}
          maxCustomerMockupPageIndicator={maxCustomerMockupPageIndicator}
          lastPage={lastPage}
          customPageIndicator={customPageIndicator as string}
          handlePageClick={handlePageClick}
          page={page}
          wrapperClassName={css.paginationBlockMobile}
        />
      )}

      <div className={css.customerMockupImg}>
        <img src={customerMockupImg} alt="customer mockup" />
        <span className={cn(css.label, css.labelRight)}>
          <Typography
            color={COLORS.colorPrimaryText}
            fontType="bodySm"
            lineHeight="162.5%"
          >
            Customer Mockup
          </Typography>
        </span>
      </div>

      {shouldRenderCMPagination && (
        <PaginationBlock
          wrapperClassName={css.paginationBlockMobile}
          hidePMPagination
          isCompareMode={isCompareMode}
          customerMockupPage={customerMockupPage}
          onCustomerMockupPage={onCustomerMockupPage}
          maxCustomerMockupPage={maxCustomerMockupPage}
          maxCustomerMockupPageIndicator={maxCustomerMockupPageIndicator}
          lastPage={lastPage}
          customPageIndicator={customPageIndicator as string}
          handlePageClick={handlePageClick}
          page={page}
        />
      )}
    </div>
  );
};
