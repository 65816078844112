import { useState, useEffect } from 'react';
import { OptionType, SwagButton, Typography } from '@ezetech/swag-space-x';

import { useForm, SubmitHandler, FieldValues } from 'react-hook-form';
import { QueryStatus } from '@reduxjs/toolkit/dist/query';
import { yupResolver } from '@hookform/resolvers/yup';

import { CheckboxForm } from 'components/checkbox-form';
import { SelectForm } from 'components/select-from';
import { InputForm } from 'components/input-form';
import { IPromocodeReq } from 'interfaces';
import {
  dropdownFiltersSelector,
  pageFiltersSelector,
} from 'redux/selectors/filters.selectors';
import { useAppSelector } from 'redux/store';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { useAddPromoCodeMutation } from 'redux/api/promocode.api';
import { cardSelector } from 'redux/selectors/settings.selectors';
import {
  PromocodeMeasurementEnum,
  FIELDS,
  ADDITIONAL_DISCOUNTS,
  IS_FREE_SHIPPING,
  IS_FREE_SETUP_FEE,
  MEASUREMENTS,
  PROMOCODE_TYPES,
} from 'constants/promocodes.constants';
import { checkIsRejectedByCreditCardValidation } from 'utils/check-is-rejected-by-credit-card-validation';

import { promoCodeFormSchema } from './promocodes-form.schema';
import css from './promocodes-form.module.scss';

enum MeasurementFieldName {
  DiscountAbsolute = 'discountAbsolute',
  DiscountPercent = 'discountPercent',
}

enum MEASUREMENT {
  ABS = '$',
  REL = '%',
}

export const PromoCodeForm = () => {
  const [addPromoCode, { isLoading, status, error, isSuccess }] =
    useAddPromoCodeMutation();
  const selectedOptions = useAppSelector(dropdownFiltersSelector);
  const page = useAppSelector(pageFiltersSelector);
  const [isRejected, setIsRejectedByCCValidation] = useState(false);
  const card = useAppSelector(cardSelector);
  const isCardAttached = Boolean(card);

  const [measurementSymbol, setMeasurementSymbol] = useState<MEASUREMENT>(
    MEASUREMENT.ABS,
  );
  const [discountTypeName, setDiscountTypeName] = useState<MeasurementFieldName>(
    MeasurementFieldName.DiscountAbsolute,
  );

  const { handleSubmit, control, getValues, formState, reset } = useForm<FieldValues>({
    mode: 'all',
    resolver: yupResolver(promoCodeFormSchema),
  });

  const { isValid } = formState;

  useEffect(() => {
    switch (status) {
      case QueryStatus.fulfilled: {
        setMeasurementSymbol(MEASUREMENT.ABS);
        setDiscountTypeName(MeasurementFieldName.DiscountAbsolute);
        reset({ [IS_FREE_SHIPPING]: false, [IS_FREE_SETUP_FEE]: false });
        break;
      }
      case QueryStatus.rejected: {
        if (checkIsRejectedByCreditCardValidation(error)) {
          setIsRejectedByCCValidation(true);
        }
        break;
      }
      default:
        break;
    }
  }, [status]);

  useEffect(() => {
    if (isCardAttached && isRejected) {
      handleSubmit(onSubmit)();
    }
  }, [isCardAttached]);
  const onSubmit: SubmitHandler<FieldValues> = (dataForm, e) => {
    e?.preventDefault();
    const { measurement, Value, ...data } = dataForm;
    const isAbsolute = measurement === PromocodeMeasurementEnum.ABSOLUTE;

    addPromoCode({
      ...data,
      isAbsolute,
      value: isAbsolute ? Value * 100 : Value,
      dropdown: selectedOptions,
      page,
    } as IPromocodeReq);
    if (isSuccess) {
      reset();
    }
  };

  const handleOnChange = () => {
    const measurement = getValues(FIELDS.measurement.name);
    if (measurement === PromocodeMeasurementEnum.ABSOLUTE) {
      setMeasurementSymbol(MEASUREMENT.ABS);
      setDiscountTypeName(MeasurementFieldName.DiscountAbsolute);
    } else {
      setMeasurementSymbol(MEASUREMENT.REL);
      setDiscountTypeName(MeasurementFieldName.DiscountPercent);
    }
  };
  const measurementRules = { onChange: handleOnChange };

  const AdornmentStart = (
    <Typography
      color={COLORS.colorSecondaryText}
      className={css.discountTypeIcon}
      lineHeight="162%"
      fontType="bodyMd"
    >
      {measurementSymbol}
    </Typography>
  );
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={css.form}>
      <InputForm
        className={css.input}
        type="text"
        label={FIELDS.name.label}
        name={FIELDS.name.name}
        placeholder={FIELDS.name.placeholder}
        control={control}
        errorClassName={css.errorClassName}
        defaultValue={''}
      />
      <div className={css.twoInRowContainer}>
        <SelectForm
          options={FIELDS.measurement.options as OptionType[]}
          className={css.input}
          label={FIELDS.measurement.label}
          name={FIELDS.measurement.name}
          placeholder={FIELDS.measurement.placeholder}
          control={control}
          errorClassName={css.errorClassName}
          rules={measurementRules}
          defaultValue={MEASUREMENTS[1].value as unknown as OptionType}
        />
        <InputForm
          className={css.input}
          type="number"
          label={FIELDS[discountTypeName].label}
          name={FIELDS[discountTypeName].name}
          placeholder={FIELDS[discountTypeName].placeholder}
          control={control}
          errorClassName={css.errorClassName}
          adornmentStart={AdornmentStart}
          defaultValue={0}
        />
      </div>
      <div className={css.twoInRowContainer}>
        <SelectForm
          options={FIELDS.type.options as OptionType[]}
          className={css.input}
          label={FIELDS.type.label}
          name={FIELDS.type.name}
          placeholder={FIELDS.type.placeholder}
          errorClassName={css.errorClassName}
          control={control}
          defaultValue={PROMOCODE_TYPES[0].value as unknown as OptionType}
        />
        <div className={css.additionDiscounts}>
          <Typography
            color={COLORS.colorPrimaryText}
            lineHeight="143%"
            fontType="bodyMd"
            className={css.additionDiscountsLabel}
          >
            Additional Discounts (optional)
          </Typography>
          <div className={css.checkboxList}>
            {FIELDS.addition.items.map((item: (typeof ADDITIONAL_DISCOUNTS)[0]) => {
              return (
                <CheckboxForm
                  name={item.type}
                  key={item.type}
                  label={item.value}
                  control={control}
                  checked={item.isSelected}
                />
              );
            })}
          </div>
        </div>
      </div>
      <div>
        <SwagButton
          className={css.button}
          type="primary"
          size="large"
          htmlType="submit"
          disabled={!isValid || isLoading}
          loading={isLoading}
        >
          Create Promocode
        </SwagButton>
      </div>
    </form>
  );
};
