import { FC } from 'react';
import { Typography } from '@ezetech/swag-space-x';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { makeBold } from '../sign-contract.util';

export const UnauthorizedUses: FC = () => {
  return (
    <>
      <Typography color={COLORS.colorPrimaryText} fontType="bodyMdBold">
        9. Unauthorized Uses
      </Typography>
      <br />
      <br />
      We authorize your use of the Services only for the authorized and permitted purposes
      set forth herein. Any other use of the Services is prohibited and, therefore,
      constitutes unauthorized use of the Services.
      <br />
      <br />
      When using the Services, you agree not to engage in any illegal, dangerous,
      seditious, offensive or otherwise undesirable (as determined by Swag Space in its
      sole discretion) activities, including, but not limited to: (a) submitting or
      sharing Content that is patently offensive to the online community, and which
      contains unacceptable elements (such as, for example, content that is threatening or
      promotes racism or hatred); (b) using any robot, spider or other automatic device,
      or a manual process, to monitor or copy user information, content, or any
      information contained within the Services; (c) using any device, software or routine
      to interfere or attempt to interfere with the proper working of the Site
      infrastructure; (d) decompiling, reverse engineering, disassembling or otherwise
      attempting to obtain the source code for the Site; (e) in any manner that violates
      any local, state, national, foreign, or international statute, regulation, rule,
      order, treaty, or other law; (f) in a manner that modifies, publicly displays,
      publicly performs, reproduces or distributes any of the Site other than as expressly
      permitted for your use of the Services; (g) to interfere with or disrupt the Site or
      servers or networks connected to the Site; or (h) to attempt to gain unauthorized
      access to any portion of the Site or any other accounts, computer systems, or
      networks connected to the Site, whether through hacking, password mining, or any
      other means.
      <br />
      <br />
      This list of prohibitions provides examples and is not complete or exclusive. Swag
      Space reserves the right in its sole discretion to remove Branded Storefronts and
      terminate Accounts for such activities or for any other reason and we may take legal
      action to enforce our rights.{' '}
      {makeBold(
        'Swag Space may report to law enforcement authorities any actions that may be illegal, and any reports it receives of such conduct.  When legally required or at Swag Space’s discretion, Swag Space will cooperate with law enforcement agencies in any investigation of alleged illegal activity on the Site or on the Internet.',
      )}{' '}
      You agree to hire attorneys to defend us if you violate these Terms and that
      violation results in a problem for us. You also agree to pay any damages that we may
      end up having to pay as a result of your violation. You alone are responsible for
      any violation of these Terms by you. We reserve the right to assume the exclusive
      defense and control of any matter otherwise subject to indemnification by you and,
      in such case, you agree to cooperate with our defense of such claim.
      <br />
      <br />
    </>
  );
};
