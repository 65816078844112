import React, { useEffect } from 'react';
import { Intercom } from '@intercom/messenger-js-sdk';
import { IntercomSettings } from '@intercom/messenger-js-sdk/types';
import { useAppSelector } from '../../redux/store';
import { profileSelector } from '../../redux/selectors/user.selectors';
import { useIntercomUpdate } from '../../hooks';

interface IProps {
  settings?: Partial<IntercomSettings>;
}

export function IntercomChatComponent({
  settings = {},
}: IProps): React.JSX.Element | null {
  const user = useAppSelector(profileSelector);
  const appId = process.env.REACT_APP_INTERCOM_APP_ID;

  const { updateIntercom } = useIntercomUpdate();
  useEffect(() => updateIntercom(settings), [settings]);

  if (!user || !appId) {
    return null;
  }

  return (
    <>
      {Intercom({
        app_id: appId,
        user_id: user.id,
        name: user.firstName,
        email: user.email,
        created_at: new Date(user.createdAt).valueOf(),
        company: {
          id: user?.companyId,
          name: user.company?.name,
        },
      })}
    </>
  );
}
