import { useRef } from 'react';
import {
  WhiteBox,
  Typography,
  Divider,
  Pagination,
  SkeletonWrapper,
  SkeletonNotReady,
  SkeletonReady,
  Skeleton,
  RoundedSelect,
  MultiOptionType,
} from '@ezetech/swag-space-x';
import COLORS from 'constants/styles/colors-js.constant.module.scss';

import {
  dropdownFiltersSelector,
  lastPageFiltersSelector,
  pageFiltersSelector,
} from 'redux/selectors/filters.selectors';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { useGetPromoCodesMutation } from 'redux/api/promocode.api';
import { ROUNDED_SELECT } from 'components/rounded-select';
import { PROMOCODE_TYPES } from 'constants/promocodes.constants';
import { setFilter } from 'redux/slices/filter.slice';
import { SUBJECTS } from 'constants/ability.constant';
import { PageContent } from '../../components/page-content';
import { can } from '../../boot/ability';
import { PromocodesTable } from './table/promocodes-table';
import { PromoCodeForm } from './form/promocodes-form';
import css from './promocodes.module.scss';

const skeletons = [0, 1, 2];

export function PromocodesComponent(): JSX.Element {
  const dispatch = useAppDispatch();
  const scrollRef = useRef<HTMLDivElement>(null);
  const page = useAppSelector(pageFiltersSelector);
  const lastPage = useAppSelector(lastPageFiltersSelector);
  const selectedOptions = useAppSelector(dropdownFiltersSelector);
  const [getPromocodes, { isLoading }] = useGetPromoCodesMutation();

  const handlePageClick = ({
    value,
    options,
    skipScroll,
  }: {
    value: number;
    skipScroll?: boolean;
    options?: MultiOptionType;
  }) => {
    getPromocodes({ dropdown: options, page: value });
    !skipScroll && scrollRef.current?.scrollIntoView();
  };

  const handleFilterClick = (options: MultiOptionType) => {
    dispatch(setFilter({ dropdown: options }));
    handlePageClick({ value: 1, skipScroll: true, options });
  };

  const goToPage = (value: number) => handlePageClick({ value });

  const subjectPPName = SUBJECTS.PROMOCODES_VIEW.value;
  const subjectPPActions = SUBJECTS.PROMOCODES_VIEW.actions;

  const subjectPromocodesEditName = SUBJECTS.PROMOCODES_EDIT.value;
  const subjectPromocodesEditActions = SUBJECTS.PROMOCODES_EDIT.actions;

  return (
    <PageContent>
      <div className={css.pageInner}>
        <div className={css.root}>
          {can(
            subjectPromocodesEditActions.NEW_PROMOCODE_CREATE,
            subjectPromocodesEditName,
          ) && (
            <WhiteBox className={css.section}>
              <Typography
                className={css.header}
                color={COLORS.colorPrimaryText}
                fontType={'HEADING5XL'}
              >
                New Promocode
              </Typography>
              <Divider />
              <div className={css.content}>
                <PromoCodeForm />
              </div>
            </WhiteBox>
          )}
          <div ref={scrollRef}>
            {can(subjectPPActions.ACTIVE_PROMOCODES_VIEW, subjectPPName) && (
              <WhiteBox className={css.sectionTable}>
                <div className={css.wrapperTable}>
                  <div className={css.headerList}>
                    <Typography
                      className={css.header}
                      color={COLORS.colorPrimaryText}
                      fontType={'HEADING5XL'}
                    >
                      Active Promocodes
                    </Typography>
                    <RoundedSelect
                      styles={ROUNDED_SELECT}
                      name="filter-promocodes"
                      onChange={handleFilterClick}
                      options={PROMOCODE_TYPES}
                      selectedOptions={selectedOptions}
                      menuPortalTarget={document.body}
                      menuPosition="fixed"
                      errorClassName={css.error}
                      selectWrapperClassName={css.select}
                      isMultiSelect
                      menuShouldBlockScroll
                      isCheckboxHidden
                    />
                  </div>

                  <Divider />
                  <SkeletonWrapper isReady={!isLoading}>
                    <SkeletonNotReady>
                      {skeletons.map((number) => (
                        <Skeleton
                          key={String(number)}
                          variant="text"
                          className={css.skeletonItem}
                        />
                      ))}
                    </SkeletonNotReady>
                    <SkeletonReady>
                      <PromocodesTable />
                    </SkeletonReady>
                  </SkeletonWrapper>
                </div>
              </WhiteBox>
            )}
          </div>
          {can(subjectPPActions.ACTIVE_PROMOCODES_VIEW, subjectPPName) && (
            <div className={css.pagination}>
              <Pagination goToPage={goToPage} page={page} lastPage={lastPage} />
            </div>
          )}
        </div>
      </div>
    </PageContent>
  );
}
