import { useState, useEffect } from 'react';
import { EditorState } from 'draft-js';
import { htmlToEditorState } from './rich-text-editor.utils';

export const useRichTextEditor = (initHtml?: string | null) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    setHtmlToEditorState(initHtml);
  }, [initHtml]);

  const setHtmlToEditorState = (htmlToUpdate: string | null | undefined) => {
    const initEditorState = htmlToEditorState(htmlToUpdate || '');
    if (initEditorState) {
      setEditorState(initEditorState);
    }
  };

  return {
    editorState,
    setHtmlToEditorState,
    setEditorState,
  };
};
