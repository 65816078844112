import { FC } from 'react';
import { Typography } from '@ezetech/swag-space-x';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import css from '../sign-contract.module.scss';
import { makeBold } from '../sign-contract.util';

export const BrandedStorefronts: FC = () => {
  return (
    <>
      <Typography color={COLORS.colorPrimaryText} fontType="bodyMdBold">
        6. Branded Storefronts; Services; Reseller Responsibilities
      </Typography>
      <br />
      <br />
      <ol className={css.listLatin}>
        <li className={css.listItem}>
          {makeBold('Branded Storefronts.')}
          <br />
          <br />
          Upon acceptance as a Reseller, you may build and customize your Branded
          Storefront using the Site. Your Branded Storefront will be hosted by Swag Space
          at a URL ending in “swag.space” and managed by you. Swag Space will provide
          order fulfillment and related services as detailed on the Site and as set forth
          below. If you are signing up for the Services on behalf of an entity, the entity
          will be the Reseller. If you are signing up for the Services as an individual,
          you will be the contracting party or Reseller for purposes of these Terms and
          will be the person authorized to use the Account provided in connection with
          your use of the Services. You are responsible for ensuring that your contact
          information is clearly visible on your Branded Storefront’s website.
        </li>
        <br />
        <li className={css.listItem}>
          {makeBold('Services')}
          <br />
          <br />
          <ul className={css.listPoint}>
            <li className={css.listItem}>
              {makeBold('General.')} Swag Space sells the Blank Products your customers
              wish to customize, and provides the custom printing, fulfillment and related
              services needed to fulfill orders placed by your customers through your
              Branded Storefront ({makeBold('“Branded Storefront Orders”')}) or placed
              offline directly with you using the Services ({makeBold('“Offline Orders”')}{' '}
              and collectively with Branded Storefront Orders, the {makeBold('“Orders”')}
              ).
            </li>
            <br />
            <li className={css.listItem}>
              {makeBold('Product Availability and Attributes.')} Blank Products listed or
              displayed for purchase are subject to availability and change at any time
              without notice. We make reasonable efforts to accurately display the
              attributes of Blank Products, mock-ups of the Customized Products and the
              final Customized Products, including the applicable colors, however, the
              actual colors you and your customers see will depend on the computer system
              used for viewing and we cannot guarantee that your computer will accurately
              display such colors. In addition, tolerance on multi-color tight
              registration artwork is 1/16, which is industry standard.
            </li>
            <br />
            <li className={css.listItem}>
              {makeBold('Branded Storefront Orders.')} With respect to Branded Storefront
              Orders, Swag Space, through its service providers (e.g., payment
              processors), will collect the amounts due for purchased Customized Products
              (based on the Retail Prices (as defined below) and with any applicable
              promotional discount applied) and collect and remit applicable sales tax.
              Notwithstanding the foregoing, sales tax on customer orders placed through
              your Branded Storefront will not be collected and remitted if you provide
              Swag Space a valid tax exemption certificate from your customer.
            </li>
            <br />
            <li className={css.listItem}>
              {makeBold('Offline Orders Using the Services.')} With respect to Offline
              Orders, you will collect the amounts due for purchased Customized Goods and
              collect and remit applicable sales tax.
            </li>
            <br />
            <li className={css.listItem}>
              {makeBold('Inventory Services Option.')} If you or your customers select our
              Inventory Services Option (defined below), we or our agents will hold some
              or all of your customers’ selected Customized Products in inventory upon
              your customers’ purchases and before delivery of such items to your
              customers ({makeBold('“Inventory”')}
              ), subject to these Terms and the payment of additional storage, “pick and
              package” and shipping (shipping from the storage facility to the final
              delivery location) fees, which we will provide at the time the Inventory
              Services Option is selected. The {makeBold(
                '“Inventory Services Option”',
              )}{' '}
              allows your customers to have their Customized Products stored in a storage
              facility and distributed to multiple addresses, on demand. Swag Space’s
              obligations regarding Customized Products held in Inventory will be limited
              to a duty to exercise reasonable care in the handling and storage of such
              products. Customized Products stored as Inventory are owned by you or your
              applicable customers and title and risk of loss for such products passes to
              you or your customers (as applicable) when Swag Space delivers such products
              to the carrier for delivery to the applicable storage facility.
              <br />
              <br />
              Swag Space may take exception to certain products held in Inventory and
              require you or your customers to take physical possession of such products
              at any time and for any reason, and further, Swag Space may suspend or
              terminate its Inventory Services Option, in its sole discretion. In the
              event that Swag Space requires you or your customers to take physical
              possession of your customers’ Customized Products held in Inventory, Swag
              Space will require you to provide an address for delivery and pay any
              additional charges for shipment within thirty (30) days’ upon being provided
              notice, unless otherwise mutually agreed by the parties in writing. Swag
              Space reserves the right to destroy any Inventory that remains unclaimed
              after such period. You agree that Swag Space may ship products held in
              Inventory to you or your designee upon your request and at your expense.
              Swag Space will ship products held in Inventory upon payment of any and all
              additional charges required by Swag Space, including, without limitation,
              additional charges for shipment. In the event that you or your customers
              request shipment of products held in Inventory and subsequently cancel a
              portion or all of such request, you will be liable for any and all
              applicable cancellation, restocking, and similar charges imposed by Swag
              Space or its suppliers.
            </li>
            <br />
            <li className={css.listItem}>
              {makeBold('Shipping.')} Swag Space will ship the Customized Products to your
              customers as directed. Shipping costs will be paid by your customers for
              Branded Storefront Orders or by you for Offline Orders. Swag Space will not
              be held financially responsible for any shipping issues that arise outside
              of the reasonable control of Swag Space. This includes, but is not limited
              to, carrier disruptions, slow pickup scans, and address errors. In good
              faith, Swag Space will work with you to address any returned shipments in a
              timely manner. Title and risk of loss of the Customized Products transfers
              to you or your customers (as applicable) upon the shipper or carrier taking
              possession of the Customized Products. Notwithstanding the foregoing, Swag
              Space shall use its own carriers for shipments that shall provide default
              carrier coverage against loss or damage. In the event of a shipping error,
              Swag Space will file a claim with the applicable carrier and provide a
              credit back to you or your customer (as applicable) for the amount recovered
              once the claim is processed and recovery is received.
            </li>
            <br />
            <li className={css.listItem}>
              {makeBold('Returns and Refunds.')} In the event of an order error that is
              solely due to the fault of Swag Space, which results in a refund or
              replacement that is in excess of the agreed upon Error Allowance (as defined
              below), Swag Space will bear the associated costs of such refund or
              replacement at no additional cost to you, including shipping expenses which
              will be at Swag Space’s cost. Any order error must be reported to Swag Space
              within thirty (30) days of delivery of the order.{' '}
              {makeBold('“Error Allowance”')} means a monetary allowance equal to two
              percent (2%) of your Gross Sales per Order for remediating errors (including
              but not limited to refunds, replacements, other order corrections, and
              spoilage). Your {makeBold('“Gross Sales”')} per Order are the gross retail
              sales derived from an Order.
            </li>
          </ul>
          <br />
        </li>
        <li className={css.listItem}>
          {makeBold('Reseller Responsibilities.')}
          <br />
          <br />
          <ul className={css.listPoint}>
            <li className={css.listItem}>
              {makeBold('General.')} You are responsible for the creation and management
              of your Branded Storefront, Reseller Content, Customer Content and all
              aspects of the transactions between you and your customers unless Swag Space
              is collecting the amounts due and collecting and remitting sales taxes for
              purchases made through your Branded Storefront, in which case Swag Space is
              responsible for payment collection and sales tax collection and remittance.
              You are responsible for your contract or terms with your customers, returns,
              refunds, customer service, fraudulent transactions, required legal
              disclosures, regulatory compliance, offers or promotional content, alleged
              or actual violation of applicable laws or your breach of these Terms. You
              represent and warrant that your Branded Storefront, the Reseller Content,
              Customer Content, and the services you provide to your customers through
              your Branded Storefront or your use of the Services will be true and
              accurate and will not violate any applicable laws, regulations or rights of
              third parties.
            </li>
            <br />
            <li className={css.listItem}>
              {makeBold(
                'Reseller Customers – Contract of Sale/Terms of Service; Privacy Policy.',
              )}{' '}
              You are responsible for having a contract of sale or terms of service with
              your customers. Any contract of sale or terms of service made using the
              Services is between you and your customer. Such contract must include, at a
              minimum, your contact information, your return and refund policy and
              estimated delivery times. If you are providing the Inventory Services Option
              to your customers in your Branded Storefront, a provision at least
              substantially equivalent to the following provision with respect to
              Inventory is required:
              <br />
              <br />
              {makeBold('“Inventory')}: Title and risk of loss for any customized products
              you choose to have held in storage as inventory will pass to you upon the
              delivery of such products to the carrier for transport to the applicable
              storage facility. Our and our service provider’s obligations regarding such
              products held as inventory will be limited to a duty to exercise reasonable
              care in the handling and storage of such products. We may take exception to
              certain products held in inventory and require you to take physical
              possession of such products at any time and for any reason, and further, the
              inventory option may be suspended or terminated at any time, in our service
              provider’s sole discretion.”
              <br />
              <br />
              {makeBold(
                'Legal Disclaimer: Swag Space does not provide legal advice.',
              )}{' '}
              You should consult independent legal counsel before providing your customers
              any contract of sale or terms of service on your Branded Storefront. For
              informational purposes only, Swag Space is providing a sample terms of
              service located {makeBold('[here]')}. You should read the provided sample
              terms of service carefully and make all modifications you deem necessary
              should you choose to use it.{' '}
              {makeBold(
                'Use of, access to, or the provision of such sample terms of service is not intended to create, and does not constitute formation of, an attorney-client relationship between Swag Space and you.  You should not rely on the sample terms of service and you should seek legal advice from a licensed attorney in your state or territory.',
              )}{' '}
              Swag Space expressly disclaims any and all liability with respect to any
              action or inaction you take based on the sample terms of service.
              <br />
              <br />
              {makeBold('Privacy Policy.')} You are required by law to have a privacy
              policy on your Branded Storefront. Such privacy policy is between you and
              your customers.
              <br />
              <br />
              {makeBold(
                'Legal Disclaimer: Swag Space does not provide legal advice.',
              )}{' '}
              You should consult independent legal counsel before providing your customers
              with a privacy policy. For informational purposes only, Swag Space is
              providing this sample privacy policy. You should read the provided sample
              privacy policy carefully and make all modifications you deem necessary
              should you choose to use it.{' '}
              {makeBold(
                'Use of, access to, or the provision of such sample privacy policy is not intended to create, and does not constitute formation of, an attorney-client relationship between Swag Space and you.  You should not rely on the sample privacy policy and you should seek legal advice from a licensed attorney in your state or territory. ',
              )}{' '}
              Swag Space expressly disclaims any and all liability with respect to any
              action or inaction you take based on the sample privacy policy.
            </li>
            <br />
            <li className={css.listItem}>
              {makeBold('Design Proofs.')} When your customer places an order through your
              Branded Storefront, for each Customized Product ordered, we will send you a
              mock-up or proof ({makeBold('“Design Proof”')}) of the Customized Product
              for review (such mock-up will preview the design, artwork, logo etc.
              provided by your customer on the product). Once you’ve approved the Design
              Proof, Swag Space will send the Design Proof to your customer for your
              customer’s approval.
            </li>
          </ul>
        </li>
      </ol>
      <br />
    </>
  );
};
