import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type NotificationStatus = 'positive' | 'negative' | 'general';
export enum NotificationType {
  'toasts',
}

export interface INotification {
  status?: NotificationStatus;
  message: string;
  timeout?: number;
  action?: boolean;
  key?: number;
  type: NotificationType;
}

const initialState: INotification[] = [];

export const notificationsSlice = createSlice({
  initialState,
  name: 'notifications',
  reducers: {
    clearNotifications: () => initialState,
    pushNotification: (state, action: PayloadAction<INotification>) => [
      ...state,
      { ...action.payload, key: action.payload?.key || Date.now() },
    ],
    setNotification: (_, action: PayloadAction<INotification>) => [
      { ...action.payload, key: action.payload?.key || Date.now() },
    ],
  },
});

export const notificationReducer = notificationsSlice.reducer;
export const { clearNotifications, pushNotification, setNotification } =
  notificationsSlice.actions;
