import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Loader, SwagButton } from '@ezetech/swag-space-x';
import { useAppSelector } from 'redux/store';
import { PageContent } from 'components/page-content';
import {
  customerCompanyDetailsSelector,
  customerDetailsPageHeadingSelector,
} from 'redux/selectors/customer-details.selector';
import { useGetOrdersMutation } from 'redux/api/order.api';
import { filtersRequestSelector } from 'redux/selectors/filters.selectors';
import { AuthLoginAsAUserContext } from 'enums/auth';
import { useGetUserDetailsMutation } from 'redux/api/user.api';
import { SUBJECTS } from 'constants/ability.constant';
import { can } from 'boot/ability';
import { API_URL } from '../../redux/api/helpers';
import { Statistic } from './statistic';
import { Details } from './details';
import { Orders } from './orders';

import css from './customer-profile.module.scss';

export function CustomerProfileComponent(): JSX.Element {
  const [getOrders, { isSuccess: isCustomerOrdersLoaded }] = useGetOrdersMutation();
  const [getUserDetails, { isSuccess: isCustomerDataLoaded }] =
    useGetUserDetailsMutation();
  const { heading } = useAppSelector(customerDetailsPageHeadingSelector);
  const { page, perPage, sorting, sortingOrder } = useAppSelector(filtersRequestSelector);
  const { hasInventory } = useAppSelector(customerCompanyDetailsSelector);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const params = useParams();

  const subjectEditCustomerDetailsName = SUBJECTS.EDIT_CUSTOMERS_DETAILS.value;
  const subjectEditCustomerDetailsActions = SUBJECTS.EDIT_CUSTOMERS_DETAILS.actions;

  const subjectViewOrdersName = SUBJECTS.VIEW_ORDERS.value;
  const subjectViewOrdersActions = SUBJECTS.VIEW_ORDERS.actions;
  const hasAccessForViewInventory = can(
    subjectEditCustomerDetailsActions.VIEW_INVENTORY,
    subjectEditCustomerDetailsName,
  );

  const hasAccessForLoginAsCustomer = can(
    subjectEditCustomerDetailsActions.LOGIN_AS_CUSTOMER,
    subjectEditCustomerDetailsName,
  );

  const hasAccessForViewOrders = can(
    subjectViewOrdersActions.VIEW_ALL_ORDERS,
    subjectViewOrdersName,
  );

  useEffect(() => {
    if (params.id) {
      getUserDetails({ id: params.id });
      if (hasAccessForViewOrders) {
        getOrders({ userId: params.id, page, perPage, sorting, sortingOrder });
      }
    }
  }, [params.id]);

  useEffect(() => {
    if (
      (isCustomerOrdersLoaded && isCustomerDataLoaded) ||
      (!hasAccessForViewOrders && isCustomerDataLoaded)
    ) {
      setIsLoadingData(false);
    }
  }, [isCustomerOrdersLoaded, isCustomerDataLoaded]);

  const login = (context: AuthLoginAsAUserContext) => {
    if (!params.id) {
      return;
    }
    const queryParams = new URLSearchParams({ id: params.id, context }).toString();
    document.location.replace(`${API_URL}/auth/login-as-user?${queryParams}`);
  };

  const additionalComponent = (
    <div className={css.additionalComponent}>
      {hasAccessForLoginAsCustomer && (
        <SwagButton onClick={() => login(AuthLoginAsAUserContext.STORE)} type="outlined">
          Login to Account
        </SwagButton>
      )}
      {hasInventory && hasAccessForViewInventory && (
        <SwagButton
          onClick={() => login(AuthLoginAsAUserContext.INVENTORY)}
          type="primary"
        >
          View Inventory
        </SwagButton>
      )}
    </div>
  );

  return (
    <PageContent
      additionalComponent={additionalComponent}
      heading={heading}
      headingClassName={css.heading}
      pageContentClassName={css.wrapper}
    >
      {!isLoadingData ? (
        <div className={css.container}>
          <div className={css.left}>
            <Statistic />
            {hasAccessForViewOrders && <Orders />}
          </div>
          <div className={css.right}>
            <Details />
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </PageContent>
  );
}
