import { Outlet } from 'react-router-dom';

import { LogoIcon, StarsIcon } from '../svg';
import halfRingLeft from '../../assets/images/half-ring-left.png';
import halfRingRight from '../../assets/images/half-ring-right.png';
import { LANDING_URL } from '../../utils/urls.util';
import css from './auth-layout.module.scss';

export function AuthLayout(): JSX.Element {
  return (
    <div className={css.root}>
      <StarsIcon className={css.stars1} />
      <StarsIcon className={css.stars2} />
      <img src={halfRingRight} className={css.hRingLeft} alt="right ring" />
      <img src={halfRingLeft} className={css.hRingRight} alt="left ring" />
      <div className={css.container}>
        <div className={css.logo}>
          <a href={LANDING_URL}>
            <LogoIcon fillColor="black" />
          </a>
        </div>
        <div className={css.contentWrapper}>
          <div className={css.content}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}
