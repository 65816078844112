import { FC, useEffect, useState, FormEvent } from 'react';
import { Image, Typography, Checkbox } from '@ezetech/swag-space-x';

import COLORS from 'constants/styles/colors-js.constant.module.scss';
import imagePlaceholder from '../../../assets/svg/image-placeholder.svg';
import css from './hide-products-popup.module.scss';

export interface IProductListItem {
  id: string;
  image: string;
  name: string;
  checked: boolean;
  margin: string;
  disabled: boolean;
  onCheck(id: string, value: boolean): void;
}

export const ProductsListItem: FC<IProductListItem> = ({
  id,
  image,
  name,
  margin,
  checked,
  disabled,
  onCheck,
}) => {
  const [productImage, setProductImage] = useState(imagePlaceholder);

  useEffect(() => {
    setProductImage(image);
  }, [image]);

  const handleCheck = (value: boolean): void => {
    onCheck(id, value);
  };

  const handleRowClick = () => {
    handleCheck(!checked);
  };

  const handleChange = (e: FormEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onCheck(id, !checked);
  };

  const handleImageError = () => {
    setProductImage(imagePlaceholder);
  };

  return (
    <div className={css.product} onClick={disabled ? () => null : handleRowClick}>
      <div className={css.left}>
        <Checkbox
          name={id}
          checkboxClassName={css.checkbox}
          checked={checked}
          onChange={disabled ? () => null : handleChange}
          disabled={disabled}
        />
        <Image
          onError={handleImageError}
          wrapperClassName={css.image}
          src={productImage}
          alt={name}
          loadingClassName={css.loader}
        />
        <Typography
          lineHeight="142.857%"
          fontType="bodySm"
          color={COLORS.colorPrimaryText}
          className={css.name}
        >
          {name}
        </Typography>
      </div>
      <div className={css.right}>
        <Typography
          lineHeight="142.857%"
          fontType="bodySm"
          color={COLORS.colorPrimaryText}
        >
          {margin} commission
        </Typography>
      </div>
    </div>
  );
};
