import { Toggle, TogglePlacement, ToggleWidth } from '@ezetech/swag-space-x';
import { useAppSelector, useAppDispatch } from 'redux/store';
import { assignmentFlowEnabledSelector } from 'redux/selectors/settings.selectors';
import { setAssignmentFlowToggle } from 'redux/slices/settings.slice';
import css from '../forms.module.scss';

interface IProps {
  isDisabled?: boolean;
}

export function OrderAssignment({ isDisabled }: IProps): JSX.Element {
  const dispatch = useAppDispatch();
  const assignmentFlowEnabled = useAppSelector(assignmentFlowEnabledSelector);

  const handleChange = () => {
    if (isDisabled) {
      return null;
    }

    dispatch(setAssignmentFlowToggle(!assignmentFlowEnabled));
  };

  return (
    <div className={css.assignmentOrderContainer}>
      <Toggle
        dataCheckedValue={String(assignmentFlowEnabled)}
        labelClassName={css.toggleLabel}
        onChange={handleChange}
        value={assignmentFlowEnabled}
        label="Enable Assignment"
        name="isOrderAssignmentEnabled"
        width={ToggleWidth.short}
        placement={TogglePlacement.right}
      />
    </div>
  );
}
