import { FC } from 'react';
import { TabsNavigation } from '@ezetech/swag-space-x';
import { PDF_PRESENTATION_FILTERS } from 'constants/pdf-presentation.constants';
import { PdfPresentationEditorFilter } from 'interfaces/pdf-presentation';
import { FiltersProps } from '../interfaces/components';
import classes from '../edit-pdf-presentation-layout-popup.module.scss';

export const Filters: FC<FiltersProps> = ({ value, onChangeValue }) => {
  const tabs = PDF_PRESENTATION_FILTERS.map((filter) => ({
    name: filter.text,
    id: filter.id,
  }));

  return (
    <TabsNavigation
      sideOffset={14}
      className={classes.tabsContainer}
      mobileClassName={classes.tabsMobileContainer}
      tabClassName={classes.tabItem}
      mobileDropdownItemClassName={classes.mobileDropdownItem}
      mobileTriggerClassName={classes.mobileTrigger}
      mobileArrowClassName={classes.arrow}
      tabs={tabs}
      selectedTab={value}
      onChangeTab={(id) => onChangeValue(id as PdfPresentationEditorFilter)}
    />
  );
};
