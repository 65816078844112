import { useEffect, useRef } from 'react';

export interface IParams<T> {
  value: T;
  shouldUpdate?: boolean;
  initValue?: T | null;
}

export function usePrevious<T>({
  value,
  shouldUpdate = true,
  initValue = null,
}: IParams<T>): T | null {
  const ref = useRef<T | null>(initValue);

  useEffect(() => {
    if (shouldUpdate) {
      ref.current = value;
    }
  }, [value]);

  return ref.current;
}
