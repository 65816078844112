import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Loader,
  MultiOptionType,
  Pagination,
  DateRange,
  OptionType,
  OptionsGroupedType,
} from '@ezetech/swag-space-x';

import { can, SUBJECTS } from 'boot/ability';
import { usePrevious } from 'hooks/use-previous.hook';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { ACTION_TOKEN_TYPES } from 'constants/action-tokens.constant';
import {
  filtersRequestSelector,
  lastPageFiltersSelector,
  pageFiltersSelector,
} from 'redux/selectors/filters.selectors';
import {
  ordersIdsListSelector,
  ordersPublicIdsListSelector,
} from 'redux/selectors/orders.selectors';
import { useGetOrdersMutation } from 'redux/api/orders.api';
import { API_URL, handleCSVFileUpload } from 'redux/api/helpers';
import { ROUTES } from 'constants/router';
import { CLIENT_STATUSES } from 'enums/orders.enum';
import { setFilter } from 'redux/slices/filter.slice';
import { EmptyState } from 'components/empty-state';
import { TableToolbar } from 'components/table-toolbar';
import { assignmentFlowEnabledSelector } from 'redux/selectors/settings.selectors';
import {
  DEFAULT_PAGE,
  DEFAULT_SORTING_ORDER,
  MY_ORDERS,
  MY_ORDERS_LABEL,
  PER_PAGE_RECORDS,
} from 'constants/common';

import { PageContent } from 'components/page-content';
import { OrderCard } from './components/order-card';
import css from './my-orders.module.scss';

const ALWAYS_EXISTED_FILTERS: OptionType[] | OptionsGroupedType[] = Object.values(
  CLIENT_STATUSES,
).map((status) => ({ label: `Status: ${status}`, value: status }));

const OPTIONAL_FILTERS: OptionType[] | OptionsGroupedType[] = [
  { label: MY_ORDERS_LABEL, value: MY_ORDERS },
];

export const MyOrdersComponent = () => {
  const canViewAllOrders = can(
    SUBJECTS.VIEW_ORDERS.actions.VIEW_ALL_ORDERS,
    SUBJECTS.VIEW_ORDERS.value,
  );
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const ordersIds = useAppSelector(ordersIdsListSelector);
  const ordersPublicIds = useAppSelector(ordersPublicIdsListSelector);
  const filters = useAppSelector(filtersRequestSelector);
  const page = useAppSelector(pageFiltersSelector);
  const lastPage = useAppSelector(lastPageFiltersSelector);
  const isOrderAssignmentEnabled = useAppSelector(assignmentFlowEnabledSelector);
  const [getOrders, { isLoading }] = useGetOrdersMutation();
  const [searchValue, setSearchValue] = useState<string>('');
  const prevSearchValue = usePrevious({
    value: searchValue,
    shouldUpdate: true,
    initValue: '',
  });

  const queryString = location.search;
  const urlParams = new URLSearchParams(queryString);
  const action = urlParams.get('action');
  const orderNum = urlParams.get('orderNum');
  const orderId = urlParams.get('orderId');
  const isActionSearchByNum = action === ACTION_TOKEN_TYPES.GET_ORDER_BY_NUM_ACTION;

  const ORDER_FILTERS = [
    ...(isOrderAssignmentEnabled ? OPTIONAL_FILTERS : []),
    ...ALWAYS_EXISTED_FILTERS,
  ];

  useEffect(() => {
    if (canViewAllOrders) {
      getOrders({
        page: DEFAULT_PAGE,
        perPage: PER_PAGE_RECORDS,
        search: orderId ? orderId : '',
        sorting: '',
        sortingOrder: DEFAULT_SORTING_ORDER,
        dropdown: isOrderAssignmentEnabled
          ? [{ label: MY_ORDERS_LABEL, value: MY_ORDERS }]
          : [],
      });
    }
  }, []);

  useEffect(() => {
    if (isActionSearchByNum) {
      dispatch(setFilter({ search: orderNum ?? '' }));
      setSearchValue(orderNum ?? '');
    }
  }, [isActionSearchByNum]);

  useEffect(() => {
    if (prevSearchValue !== searchValue) {
      getOrders({ ...filters, search: searchValue, page: 1 });
    }
  }, [searchValue]);

  const handlePageClick = (selectedPage: number) => {
    getOrders({ ...filters, page: selectedPage });
  };

  const handleSearchChange = (searchVal: string) => {
    if (prevSearchValue !== searchVal) {
      setSearchValue(searchVal);
    }
  };

  const handleFilterClick = (selected: MultiOptionType) => {
    getOrders({ ...filters, dropdown: selected, page: 1 });
  };

  const handleClick = () => {
    navigate(ROUTES.DASHBOARD);
  };

  const handleExportCSV = ([startDate, endDate]: DateRange) => {
    const { search, dropdown, sorting, sortingOrder } = filters;
    handleCSVFileUpload({
      baseUrl: `${API_URL}/order/export/`,
      params: {
        search,
        sorting,
        sortingOrder,
        dropdown,
        startDate: startDate ? startDate.toISOString() : null,
        endDate: endDate ? endDate.toISOString() : null,
      },
    });
  };

  return (
    <PageContent>
      <div className={css.container}>
        <TableToolbar
          onNextPageClick={handlePageClick}
          onSearchChange={handleSearchChange}
          onFilterClick={handleFilterClick}
          onExportClick={handleExportCSV}
          filterOptions={ORDER_FILTERS}
          isFilterMultiSelect
          showExportButton
          exportTooltipText="Export orders list"
          className={css.toolbar}
        />
        {ordersIds.length ? (
          <>
            {ordersIds.map((id, i) => (
              <OrderCard
                orderPublicId={String(ordersPublicIds[i])}
                orderId={String(id)}
                key={String(id)}
                isOrderAssignmentEnabled={isOrderAssignmentEnabled}
              />
            ))}
            <Pagination
              paginationClassName={css.pagination}
              goToPage={handlePageClick}
              page={page}
              lastPage={lastPage}
            />
          </>
        ) : (
          <div className={css.emptyStateWrapper}>
            <EmptyState
              title="Woah, it’s quiet in here"
              description="Make your first sale and then come back here."
              button="Back to Dashboard"
              onClick={handleClick}
            />
          </div>
        )}
        {isLoading && <Loader />}
      </div>
    </PageContent>
  );
};
