import cn from 'classnames';
import { useLocation } from 'react-router-dom';
import { Typography } from '@ezetech/swag-space-x';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { PAGE_HEADING, ROUTES } from 'constants/router';
import { getPageName } from 'utils/get-page-name-from-location.util';
import { useAppSelector } from '../../redux/store';
import { firstNameSelector } from '../../redux/selectors/user.selectors';
import { PageContentProps } from './page-content.interface';
import css from './page-content.module.scss';

export function PageContent({
  children,
  heading: pageHeading,
  additionalComponent,
  pageContentClassName,
  headingClassName,
  childrenClassName,
}: PageContentProps): JSX.Element {
  const location = useLocation();
  const name = useAppSelector(firstNameSelector);
  const pathname = getPageName(location?.pathname);
  const heading =
    pageHeading || PAGE_HEADING[`/${pathname}` as ROUTES] || `HEY ${name.toUpperCase()}`;

  return (
    <section className={cn(css.pageContentWrapper, pageContentClassName)}>
      <div className={cn(css.heading, headingClassName)}>
        <Typography
          className={css.text}
          lineHeight="100%"
          fontType="HEADING3XL"
          color={COLORS.colorPrimaryText}
        >
          {heading}
        </Typography>
        {additionalComponent}
      </div>

      <div className={childrenClassName}>{children}</div>
    </section>
  );
}
