import cn from 'classnames';
import { useEffect } from 'react';
import { Checkbox, Input, Typography } from '@ezetech/swag-space-x';
import { useAppSelector } from 'redux/store';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import {
  companyDetailsSelector,
  selectedHomepageProductsListSelector,
} from 'redux/selectors/settings.selectors';
import { SidebarPromoProductBlockProps } from '../interface';
import { SearchProducts } from '../search-products';

import css from './sidebar.module.scss';

export const SidebarPromoProductBlock = (
  props: SidebarPromoProductBlockProps,
): JSX.Element => {
  const { settings, setChanges } = props;
  const company = useAppSelector(companyDetailsSelector);
  const products = useAppSelector(selectedHomepageProductsListSelector);
  useEffect(() => {
    if (!products || settings.products.length === products.length) {
      return;
    }

    if (products.length === 0) {
      setChanges('products', []);
    }

    const prods = products.map((product) => {
      return {
        publicId: product.publicId,
        name: product.name,
        logo: product.logo,
      };
    });
    setChanges('products', prods);
  }, [products?.length]);

  return (
    <div className={css.sidebarMainBlock}>
      <Checkbox
        onCheckedChange={(e) => setChanges('isHidden', e)}
        name="isHidden"
        checked={settings.isHidden}
        label="Hide section"
      />
      <div
        className={cn(css.disabledBlock, { [css.disabledOverlay]: settings.isHidden })}
      />
      <Typography
        fontType="bodyMd"
        color={COLORS.colorText600}
        lineHeight="162.5%"
        className={css.subInputInfoFullWidth}
      >
        Here you can select products that you want to feature on your homepage.
      </Typography>
      <div>
        <Input
          autoComplete="off"
          value={settings.headingText}
          className={css.inputWrapper}
          maxLength={20}
          onChange={(e) => setChanges('headingText', e.target.value)}
          name="headingText"
          placeholder={`Top Picks by ${company.name}`}
          label="Heading Copy"
          skipError
        />
        <Typography
          fontType="bodySm"
          color={COLORS.colorText500}
          className={css.subInputInfo}
          lineHeight="142.857%"
        >
          Max 20 characters
        </Typography>
      </div>
      <div>
        <SearchProducts />
      </div>
    </div>
  );
};
