import {
  CellSize,
  CellTextColor,
  CellTextWeight,
  ICell,
  OptionType,
  MultiOptionType,
} from '@ezetech/swag-space-x';
import { LeadsStatusType } from 'interfaces/user-api.interface';
import { ASSIGNED_TO_ME_ENTITY } from 'constants/common';
import { IAssignedForReseller } from './assignee.interface';
import { IPrebuiltItem } from './cart.interfaces';

export enum AbandonedCheckoutsCartActions {
  copy = 'copy',
}

export enum AbandonedCheckoutsCartStatusEnum {
  NEW = 'NEW',
  COMPLETED = 'COMPLETED',
}

export const ABANDONED_CHECKOUTS_CARTS_FILTERS: OptionType[] = [
  { value: ASSIGNED_TO_ME_ENTITY, label: 'My Customers' },
  { value: AbandonedCheckoutsCartStatusEnum.NEW, label: 'Status: New' },
  { value: AbandonedCheckoutsCartStatusEnum.COMPLETED, label: 'Status: Completed' },
];

export enum AbandonedCheckoutsCartsFields {
  id = 'id',
  customerName = 'customerName',
  companyName = 'customerCompanyName',
  phone = 'phone',
  email = 'email',
  cartTotal = 'cartTotal',
  products = 'products',
  createdAt = 'createdAt',
  cartSyncedAt = 'cartSyncedAt',
  status = 'abandonedCheckoutsStatus',
  assignTo = 'assignTo',
}

export const ABANDONED_CHECKOUTS_CARTS_TABLE_HEADER = [
  { text: '#', id: AbandonedCheckoutsCartsFields.id, size: CellSize.small },
  {
    text: 'Customer name',
    id: AbandonedCheckoutsCartsFields.customerName,
    size: CellSize.large,
  },
  {
    text: 'Company Name',
    id: AbandonedCheckoutsCartsFields.companyName,
    size: CellSize.large,
  },
  { text: 'Phone', id: AbandonedCheckoutsCartsFields.phone, size: CellSize.shortMedium },
  { text: 'Email', id: AbandonedCheckoutsCartsFields.email, size: CellSize.large },
  {
    text: 'Product total',
    id: AbandonedCheckoutsCartsFields.cartTotal,
    size: CellSize.large,
    withSorting: true,
  },
  { text: 'Products', id: AbandonedCheckoutsCartsFields.products, size: CellSize.medium },
  {
    text: 'Creation Date',
    id: AbandonedCheckoutsCartsFields.createdAt,
    size: CellSize.unset,
    withSorting: true,
  },
  {
    text: 'Last Edit Date',
    id: AbandonedCheckoutsCartsFields.cartSyncedAt,
    size: CellSize.unset,
    withSorting: true,
  },
  { text: 'Status', id: AbandonedCheckoutsCartsFields.status, size: CellSize.extraLarge },
  {
    text: 'Assign to',
    id: AbandonedCheckoutsCartsFields.assignTo,
    size: CellSize.extraLarge,
  },
];

export const ABANDONED_CHECKOUTS_CARTS_TABLE_ROW = {
  [AbandonedCheckoutsCartsFields.id]: {
    size: CellSize.small,
    textWeight: CellTextWeight.extraBold,
  },
  [AbandonedCheckoutsCartsFields.customerName]: {
    size: CellSize.large,
    textWeight: CellTextWeight.bold,
    textColor: CellTextColor.primary,
  },
  [AbandonedCheckoutsCartsFields.companyName]: {
    size: CellSize.large,
    textColor: CellTextColor.primary,
  },
  [AbandonedCheckoutsCartsFields.phone]: {
    size: CellSize.shortMedium,
  },
  [AbandonedCheckoutsCartsFields.cartTotal]: {
    textWeight: CellTextWeight.bold,
    size: CellSize.large,
  },
  [AbandonedCheckoutsCartsFields.products]: {
    size: CellSize.medium,
    textColor: CellTextColor.pink,
    actionable: true,
  },
  [AbandonedCheckoutsCartsFields.email]: {
    textWeight: CellTextWeight.bold,
    textColor: CellTextColor.pink,
    onClick: AbandonedCheckoutsCartActions.copy,
    size: CellSize.large,
    actionable: true,
  },
  [AbandonedCheckoutsCartsFields.createdAt]: { size: CellSize.unset },
  [AbandonedCheckoutsCartsFields.cartSyncedAt]: { size: CellSize.unset },
  [AbandonedCheckoutsCartsFields.status]: {
    size: CellSize.extraLarge,
  },
  [AbandonedCheckoutsCartsFields.assignTo]: {
    size: CellSize.extraLarge,
  },
};

export interface IGetAbandonedCheckoutsCartsRequest {
  search: string | null;
  page: number;
  perPage: number;
  sorting: string;
  sortingOrder: 'ASC' | 'DESC';
  dropdown: MultiOptionType;
}

export interface IGetAbandonedCheckoutsCartsResponse {
  carts: IAbandonedCheckoutsCart[];
  total: number;
  page: number;
  perPage: number;
}

export interface IAbandonedCheckoutsCart {
  id: string;
  email: string;
  customerName: string | null;
  customerCompanyName: string | null;
  phone: string | null;
  status: AbandonedCheckoutsCartStatusEnum;
  abandonedCheckoutsStatus: AbandonedCheckoutsCartStatusEnum;
  cartTotal: number;
  items: IPrebuiltItem[];
  createdAt: Date;
  assignee?: IAssignedForReseller | null;
}

export interface ITransformAbandonedCheckoutsCartCell
  extends Omit<ICell, 'onClick' | 'id'> {
  id: AbandonedCheckoutsCartsFields;
  onClick?: AbandonedCheckoutsCartActions;
  isNonClickable?: boolean;
  assignedToUser?: { value: string; label: string } | null;
  status?: { value: string; label: string } | null;
}

export interface ITransformAbandonedCheckoutsCartRow {
  cells: ITransformAbandonedCheckoutsCartCell[];
  index: number;
  cart: IAbandonedCheckoutsCart;
}

export interface IUpdateAbandonedStatus {
  id: string;
  status: LeadsStatusType;
}

export const ABANDONED_CHECKOUTS_STATUS_LABELS = {
  [AbandonedCheckoutsCartStatusEnum.NEW]: 'New',
  [AbandonedCheckoutsCartStatusEnum.COMPLETED]: 'Completed',
};

export const ABANDONED_CHECKOUTS_OPTIONS = [
  {
    value: AbandonedCheckoutsCartStatusEnum.NEW,
    label: ABANDONED_CHECKOUTS_STATUS_LABELS[AbandonedCheckoutsCartStatusEnum.NEW],
  },
  {
    value: AbandonedCheckoutsCartStatusEnum.COMPLETED,
    label: ABANDONED_CHECKOUTS_STATUS_LABELS[AbandonedCheckoutsCartStatusEnum.COMPLETED],
  },
];
