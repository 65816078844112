import {
  Typography,
  ListItem,
  Divider,
  TooltipPlacement,
  Tooltip,
} from '@ezetech/swag-space-x';
import classnames from 'classnames';
import { PERMISSION_ERROR_TOOLTIP_TEXT } from 'constants/common';

import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { useAppDispatch } from 'redux/store';
import {
  getItemStatusLabel,
  filterByStatusAction,
  getNumWithLetterIndicator,
  isItemWithStatusAction,
} from 'utils/items.util';
import { can } from 'boot/ability';
import { ITEM_STATUS_CODE_LABEL } from 'constants/item.constants';
import { getPopupFromStatus } from 'redux/selectors/orders.selectors';
import { openPopup } from 'redux/slices/modals.slice';
import { IItem, IOrder } from 'interfaces/orders.interface';
import { itemDataForProductionMockupForDashboard } from 'redux/selectors/dashboard.selectors';
import { SUBJECTS } from 'constants/ability.constant';

import { IOrdersListCardProps } from './orders-list-card.type';

import css from './orders-list-card.module.scss';

export function OrderItemsList({
  order,
}: {
  order: IOrdersListCardProps['orders'][0];
}): JSX.Element | null {
  const dispatch = useAppDispatch();
  const subjectApprovePantonesName = SUBJECTS.APPROVE_PANTONES.value;
  const subjectApprovePantonesActions = SUBJECTS.APPROVE_PANTONES.actions;
  const canApprovePantones = can(
    subjectApprovePantonesActions.APPROVE_PANTONES,
    subjectApprovePantonesName,
  );

  const subjectApproveMockupName = SUBJECTS.APPROVE_MOCKUPS.value;
  const subjectApproveMockupActions = SUBJECTS.APPROVE_MOCKUPS.actions;
  const canApproveMockups = can(
    subjectApproveMockupActions.APPROVE_MOCKUPS,
    subjectApproveMockupName,
  );

  const checkCanDoActionByStatus = (status: string) => {
    if (
      status === ITEM_STATUS_CODE_LABEL.COLORS_REVIEW_BY_RESELLER &&
      !canApprovePantones
    ) {
      return false;
    }
    if (
      status === ITEM_STATUS_CODE_LABEL.MOCKUP_APPROVAL_BY_RESELLER &&
      !canApproveMockups
    ) {
      return false;
    }

    return true;
  };

  const handleOpenPopup = (orderId: string, orderData: IOrder, item: IItem) => {
    const status = getItemStatusLabel(item);
    const canDoAction = checkCanDoActionByStatus(status);
    if (!canDoAction) {
      return;
    }
    const popupName = getPopupFromStatus(status);
    const { productionMockup, customerMockups } = itemDataForProductionMockupForDashboard(
      item,
      orderData,
    );
    dispatch(
      openPopup({
        popupName,
        popupProps: {
          orderId,
          itemId: item.id,
          orderNum: order.num,
          productName: item.name,
          productionMockup,
          customerMockups,
          item,
        },
      }),
    );
  };

  const itemsFiltered = filterByStatusAction(order.items);

  return (
    <>
      {itemsFiltered.map((item: IItem, index) => {
        const canDoAction = checkCanDoActionByStatus(getItemStatusLabel(item));

        return (
          <div key={`order-${order.id}-${index}`}>
            <div
              data-order-id={order.id}
              data-order-publicId={order.publicId}
              data-item-id={item.id}
              data-item-publicId={item.publicId}
              className={css.groupItems}
            >
              <ListItem vPadding={16} className={css.listItem} key={item.id}>
                <Typography
                  lineHeight="162%"
                  fontType="bodyMdBold"
                  color={COLORS.colorPrimaryText}
                >
                  {getNumWithLetterIndicator(item.num, item.letterIndicator)}
                </Typography>
                <Typography
                  lineHeight="162%"
                  fontType="bodyMd"
                  color={COLORS.colorPrimaryText}
                  className={css.listItemName}
                >
                  {item.name}
                </Typography>
                <Typography
                  lineHeight="162%"
                  fontType="bodyMd"
                  link={canDoAction}
                  color={canDoAction ? COLORS.colorStatePending : COLORS.colorPrimaryText}
                  className={classnames({
                    [css.status]: isItemWithStatusAction(item),
                  })}
                  onClick={() =>
                    handleOpenPopup(order.id, order as unknown as IOrder, item)
                  }
                >
                  {checkCanDoActionByStatus(getItemStatusLabel(item)) ? (
                    getItemStatusLabel(item)
                  ) : (
                    <Tooltip
                      color={COLORS.colorPink}
                      placement={TooltipPlacement.bottom}
                      content={getItemStatusLabel(item)}
                    >
                      {PERMISSION_ERROR_TOOLTIP_TEXT}
                    </Tooltip>
                  )}
                </Typography>
              </ListItem>
            </div>
            <Divider />
          </div>
        );
      })}
    </>
  );
}
