export const FIELDS = {
  address: {
    label: 'Address',
    name: 'billingAddress',
    placeholder: 'Enter Address',
  },
  city: {
    label: 'City',
    name: 'billingCity',
    placeholder: 'Enter City',
  },
  state: {
    label: 'State',
    name: 'billingState',
    placeholder: 'Enter State',
  },
  zip: {
    label: 'Zip / Postal Code',
    name: 'billingZip',
    placeholder: 'Enter Zip / Postal Code',
  },
  country: {
    label: 'Country',
    name: 'billingCountry',
    placeholder: 'Enter Country',
  },
};
