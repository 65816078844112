import {
  Toggle,
  TogglePlacement,
  ToggleWidth,
  Tooltip,
  TooltipPlacement,
  Typography,
} from '@ezetech/swag-space-x';
import { CSSTransition } from 'react-transition-group';
import cn from 'classnames';
import { can } from 'boot/ability';
import { SUBJECTS } from 'constants/ability.constant';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { InfoCircleIcon } from 'components/svg/info-circle';
import { useAppDispatch, useAppSelector } from 'redux/store';
import {
  editedCustomerCompanyDetailsSelector,
  isCalculatedReviewMockupsByCustomerAllowedSelector,
  isCalculatedReviewPantonesByCustomerAllowedSelector,
  isDefaultMockupSettingsOverriddenSelector,
  isDefaultPantoneSettingsOverriddenSelector,
  isSpecificReviewMockupsByCustomerAllowedSelector,
  isSpecificReviewPantonesByCustomerAllowedSelector,
} from 'redux/selectors/customer-details.selector';
import { useUpdateUserMutation } from 'redux/api/user.api';
import { closePopup, openPopup } from 'redux/slices/modals.slice';
import { CONFIRMATION_POPUP } from 'components/popups/_logic/popups-list';

import css from './item-timeline-settings.module.scss';

const DELAY_TIME = 250;

enum actionMap {
  setMockups = 'setMockups',
  setPantones = 'setPantones',
  setDefaultPantones = 'setDefaultPantones',
  setDefaultMockups = 'setDefaultMockups',
}

const animationClasses = {
  enter: css.enter,
  enterActive: css.enterActive,
  exit: css.exit,
  exitActive: css.exitActive,
};

export const ItemTimelineSettings = () => {
  const dispatch = useAppDispatch();
  const [updateUser] = useUpdateUserMutation();
  const isDefaultPantoneSettingsOverridden = useAppSelector(
    isDefaultPantoneSettingsOverriddenSelector,
  );
  const isDefaultMockupSettingsOverridden = useAppSelector(
    isDefaultMockupSettingsOverriddenSelector,
  );
  const data = useAppSelector(editedCustomerCompanyDetailsSelector);
  const isCalculatedReviewMockupsByCustomerAllowed = useAppSelector(
    isCalculatedReviewMockupsByCustomerAllowedSelector,
  );
  const isCalculatedReviewPantonesByCustomerAllowed = useAppSelector(
    isCalculatedReviewPantonesByCustomerAllowedSelector,
  );
  const isSpecificReviewMockupsByCustomerAllowed = useAppSelector(
    isSpecificReviewMockupsByCustomerAllowedSelector,
  );
  const isSpecificReviewPantonesByCustomerAllowed = useAppSelector(
    isSpecificReviewPantonesByCustomerAllowedSelector,
  );

  const hasAccessForPantoneAndMockupApprove = can(
    SUBJECTS.EDIT_CUSTOMERS_DETAILS.actions.PANTONE_AND_MOCKUP_APPROVAL,
    SUBJECTS.EDIT_CUSTOMERS_DETAILS.value,
  );

  const setSpecificCustomerReviewPantonesValue = async () => {
    if (data) {
      await updateUser({
        id: data.userId,
        isSpecificReviewMockupsByCustomerAllowed:
          !isCalculatedReviewMockupsByCustomerAllowed,
      });
    }
  };

  const setSpecificReviewPantonesByCustomerValue = async () => {
    if (data) {
      await updateUser({
        id: data.userId,
        isSpecificReviewPantonesByCustomerAllowed:
          !isCalculatedReviewPantonesByCustomerAllowed,
      });
    }
  };

  const setDefaultValuesForCustomer = async () => {
    if (data) {
      await updateUser({
        id: data.userId,
        isSpecificReviewPantonesByCustomerAllowed: null,
        isSpecificReviewMockupsByCustomerAllowed: null,
      });
    }
  };

  const setDefaultPantoneValuesForCustomer = async () => {
    if (data) {
      await updateUser({
        id: data.userId,
        isSpecificReviewPantonesByCustomerAllowed: null,
      });
    }
  };

  const setDefaultMockupValuesForCustomer = async () => {
    if (data) {
      await updateUser({
        id: data.userId,
        isSpecificReviewMockupsByCustomerAllowed: null,
      });
    }
  };

  const confirmAction = async (action: actionMap) => {
    if (!hasAccessForPantoneAndMockupApprove) {
      return;
    }

    let descriptionComponent = 'Custom settings for this user will be reset to default.';
    let actionFn = setDefaultValuesForCustomer;
    let confirmText = 'Yes, Reset';
    let shouldShowPopup = false;
    let title = 'Confirm Action';

    if (action === actionMap.setMockups) {
      actionFn = setSpecificCustomerReviewPantonesValue;
      descriptionComponent =
        'This will override the default settings for your store on this customer.';
      confirmText = 'Yes, Override';
      if (isSpecificReviewMockupsByCustomerAllowed === null) {
        shouldShowPopup = true;
      }
      title = 'Do you want to override?';
    }

    if (action === actionMap.setPantones) {
      actionFn = setSpecificReviewPantonesByCustomerValue;
      descriptionComponent =
        'This will override the default settings for your store on this customer.';
      confirmText = 'Yes, Override';
      if (isSpecificReviewPantonesByCustomerAllowed === null) {
        shouldShowPopup = true;
        title = 'Do you want to override?';
      }
    }

    if (action === actionMap.setDefaultPantones) {
      actionFn = setDefaultPantoneValuesForCustomer;
      shouldShowPopup = true;
    }

    if (action === actionMap.setDefaultMockups) {
      actionFn = setDefaultMockupValuesForCustomer;
      shouldShowPopup = true;
    }

    if (shouldShowPopup) {
      return dispatch(
        openPopup({
          popupName: CONFIRMATION_POPUP,
          popupProps: {
            title,
            confirmText,
            cancelText: 'Cancel',
            descriptionComponent,
            onConfirm: async () => {
              await actionFn();
              dispatch(closePopup());
            },
            onCancel: () => {
              dispatch(closePopup());
            },
          },
        }),
      );
    } else {
      await actionFn();
    }
  };

  return (
    <div className={css.block}>
      <div className={cn(css.wrapper, css.title)}>
        <Typography lineHeight="162%" fontType="bodyMd" color={COLORS.colorTextSubdued}>
          Pantone & Mockup{' '}
          <Tooltip
            placement={TooltipPlacement.top}
            bodyClassName={css.bodyClassName}
            triggerClassName={css.adornmentInfo}
            content={
              <InfoCircleIcon
                fillColor={COLORS.colorTextIcon}
                className={css.adornmentInfoIcon}
              />
            }
          >
            When Customer reviews Pantones and Mockups are turned on, your customers will
            need to approve Pantones and Mockups before orders go into production. By
            turning them off, you can approve these choices yourself and proceed into
            production.
          </Tooltip>
        </Typography>
      </div>

      <div className={cn(css.wrapper, css.line)}>
        <Toggle
          className={cn(css.toggle, {
            [css.toggleDisabled]: !hasAccessForPantoneAndMockupApprove,
          })}
          labelClassName={css.toggleLabel}
          placement={TogglePlacement.right}
          label="Customer reviews Pantones"
          width={ToggleWidth.short}
          name="customer-reviews-pantones-toggle"
          value={isCalculatedReviewPantonesByCustomerAllowed}
          onChange={() => confirmAction(actionMap.setPantones)}
        />
      </div>
      <CSSTransition
        in={isDefaultPantoneSettingsOverridden}
        key="isDefaultPantoneSettingsOverridden"
        timeout={DELAY_TIME}
        classNames={animationClasses}
        unmountOnExit
      >
        <div className={cn(css.wrapper, css.reset)}>
          <Typography
            lineHeight="162%"
            fontType="bodyMd"
            color={COLORS.colorStatePending}
          >
            Default settings overridden.{' '}
            <Typography
              lineHeight="162%"
              fontType="bodyMd"
              className={css.resetLink}
              underlined
              color={COLORS.colorStatePending}
              onClick={() => confirmAction(actionMap.setDefaultPantones)}
            >
              Reset to default
            </Typography>
          </Typography>
        </div>
      </CSSTransition>
      <div className={cn(css.wrapper, css.line)}>
        <Toggle
          className={cn(css.toggle, {
            [css.toggleDisabled]: !hasAccessForPantoneAndMockupApprove,
          })}
          labelClassName={css.toggleLabel}
          placement={TogglePlacement.right}
          label="Customer reviews Mockups"
          width={ToggleWidth.short}
          name="customer-reviews-mockups-toggle"
          value={isCalculatedReviewMockupsByCustomerAllowed}
          onChange={() => confirmAction(actionMap.setMockups)}
        />
      </div>
      <CSSTransition
        in={isDefaultMockupSettingsOverridden}
        key="isDefaultMockupSettingsOverridden"
        timeout={DELAY_TIME}
        classNames={animationClasses}
        unmountOnExit
      >
        <div className={cn(css.wrapper, css.reset)}>
          <Typography
            lineHeight="162%"
            fontType="bodyMd"
            color={COLORS.colorStatePending}
          >
            Default settings overridden.{' '}
            <Typography
              lineHeight="162%"
              fontType="bodyMd"
              className={css.resetLink}
              underlined
              color={COLORS.colorStatePending}
              onClick={() => confirmAction(actionMap.setDefaultMockups)}
            >
              Reset to default
            </Typography>
          </Typography>
        </div>
      </CSSTransition>
    </div>
  );
};
