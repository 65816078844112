import { FC } from 'react';
import { Typography } from '@ezetech/swag-space-x';
import COLORS from 'constants/styles/colors-js.constant.module.scss';

export const ThirdPartySites: FC = () => {
  return (
    <>
      <Typography color={COLORS.colorPrimaryText} fontType="bodyMdBold">
        10. Third-party Sites
      </Typography>
      <br />
      <br />
      We think links are convenient, and we sometimes provide links on the Site to
      third-party websites. If you use any of these links, you will leave the Site. We are
      not obligated to review any third-party websites that you link to from the Site, we
      do not control any of the third-party websites, and we are not responsible for any
      of the third-party websites (or the products, services, or content available through
      any of them). Thus, we do not endorse or make any representations about such
      third-party websites, any information, software, products, services, or materials
      found there or any results that may be obtained from using them. If you decide to
      access any of the third-party websites linked to from this Site, you do this
      entirely at your own risk and you must follow the privacy policies and terms and
      conditions for those third-party websites. Certain areas of the Site may allow you
      to interact and/or conduct transactions with one or more third-party websites, and,
      if applicable, allow you to configure your privacy settings in that third-party
      website account to permit your activities on this Site to be shared with your
      contacts in your third-party site account.
      <br />
      <br />
    </>
  );
};
