import { ReactNode, useEffect, useState } from 'react';
import css from './fade-inout.module.scss';

const DEFAULT_DURATION = 1; // 1s

export type FadeInOutStates = 'fadeIn' | 'fadeOut' | 'show' | 'hide';

type FadeInOutProps = {
  children: ReactNode;
  delay?: number;
  state?: FadeInOutStates;
  duration?: number;
};

export function FadeInOut({
  delay,
  children,
  duration = DEFAULT_DURATION,
  state = 'show',
}: FadeInOutProps): JSX.Element {
  const [timeId, setId] = useState<number>();
  const [style, setStyle] = useState({});
  const [className, setClassName] = useState(css[state]);
  const [isHidden, seIsHidden] = useState(state === 'hide');

  const fadeOut = () => {
    // Hide container when it becomes invisible.
    const id = setTimeout(() => {
      setId(0);
      seIsHidden(true);
    }, duration * 1000);
    setStyle({
      ...style,
      animationDuration: `${duration}s`,
    });
    setClassName(css.fadeOut);
    setId(Number(id));
  };

  const fadeIn = () => {
    setStyle({
      ...style,
      animationDuration: `${duration}s`,
    });
    seIsHidden(false);
    setClassName(css.fadeIn);
  };

  const show = () => {
    seIsHidden(false);
    setClassName('show');
  };

  const hide = () => {
    seIsHidden(true);
    setClassName('hide');
  };

  const actions = {
    show: show,
    hide: hide,
    fadeIn: fadeIn,
    fadeOut: fadeOut,
  };

  const run = (name: FadeInOutStates) => {
    if (!actions[name]) {
      return;
    }
    if (delay) {
      return setTimeout(actions[name], delay * 1000);
    }
    actions[name]();
  };

  useEffect(() => {
    if (timeId) {
      return;
    }
    run(state);
  }, [state]);
  return (
    <div className={className} style={style}>
      {isHidden ? null : children}
    </div>
  );
}
