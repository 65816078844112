import { createSelector } from 'reselect';
import { RootState } from '../store';
import { NotificationType } from '../slices/notifications.slice';

export const notificationSelector = (state: RootState) => state.notifications;

export const toastsNotificationsSelector = createSelector(
  notificationSelector,
  (notifications) =>
    notifications.filter((notification) => notification.type === NotificationType.toasts),
);
