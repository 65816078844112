export enum CreditCardType {
  VISA = 'visa',
  AMEX = 'amex',
  MASTERCARD = 'mastercard',
  DISCOVER = 'discover',
}

export interface IStripeField {
  empty: boolean;
  error: string;
  complete: boolean;
}

export interface IStripeChangeData {
  isComplete: boolean;
  fields: {
    [key: string]: IStripeField;
  };
}

export interface ICreditCard {
  id: string;
  paymentMethodId: string;
  last4: string;
  expireMonth: number;
  expireYear: number;
  brand: CreditCardType;
}
