import { Typography } from '@ezetech/swag-space-x';
import cn from 'classnames';

import COLORS from 'constants/styles/colors-js.constant.module.scss';

import css from './colored-container-header.module.scss';

type ColoredContainerHeaderProps = {
  tagline?: string;
  taglineClassName?: string;
  headingClassName?: string;
  textClassName?: string;
  title: string;
  description: string;
  className?: string;
};
export function ColoredContainerHeader({
  tagline,
  taglineClassName,
  headingClassName,
  textClassName,
  title,
  description,
  className,
}: ColoredContainerHeaderProps) {
  return (
    <div className={className}>
      {tagline && (
        <Typography
          className={cn(css.tagline, taglineClassName)}
          lineHeight="100%"
          fontType="HEADING6XL"
          color={COLORS.colorGreyDark}
        >
          {tagline}
        </Typography>
      )}
      <Typography
        className={cn(css.title, headingClassName)}
        lineHeight="100%"
        fontType="HEADING4XL"
        color={COLORS.colorGreyDark}
      >
        {title}
      </Typography>
      <Typography
        className={cn(css.description, textClassName)}
        lineHeight="125%"
        fontType="bodyLg"
        color={COLORS.colorGreyDark}
      >
        {description}
      </Typography>
    </div>
  );
}
