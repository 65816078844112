import { createRef } from 'react';
import { STEP_NAME, STORE_CREATION_ROUTES } from '../enums/store-creation.enum';
import { IStep } from '../interfaces/store-creation.interfaces';

const GO_BACK_BTN_TEXT = 'Go Back';
const CONTINUE_BTN_TEXT = 'Continue';
const FINISH_BTN_TEXT = 'Finish';

export const MARGIN_MIN_PERCENT = 0.3;
export const MARGIN_MAX_PERCENT = 0.99;
export const MAX_LINK_CHARACTERS = 60;
export const MAX_LINK_CHARACTERS_CUSTOM_DOMAIN = 200;
export const MIN_LINK_CHARACTERS = 5;

export const STEPS: IStep[] = [
  {
    text: 'Create Brand',
    stepName: STEP_NAME.BRAND,
    prevBtnText: null,
    nextBtnText: CONTINUE_BTN_TEXT,
    route: STORE_CREATION_ROUTES.STORE_CREATION_BRAND,
    routeRef: createRef(),
  },
  {
    text: 'Page Link',
    stepName: STEP_NAME.PAGE_LINK,
    prevBtnText: GO_BACK_BTN_TEXT,
    nextBtnText: CONTINUE_BTN_TEXT,
    route: STORE_CREATION_ROUTES.STORE_CREATION_PAGE_LINK,
    routeRef: createRef(),
  },
  {
    text: 'Product Selection',
    stepName: STEP_NAME.PRODUCT_SELECTION,
    prevBtnText: GO_BACK_BTN_TEXT,
    nextBtnText: CONTINUE_BTN_TEXT,
    route: STORE_CREATION_ROUTES.STORE_CREATION_PRODUCT_SELECTION,
    routeRef: createRef(),
  },
  {
    text: 'Commission',
    stepName: STEP_NAME.COMMISSION,
    prevBtnText: GO_BACK_BTN_TEXT,
    nextBtnText: CONTINUE_BTN_TEXT,
    route: STORE_CREATION_ROUTES.STORE_CREATION_COMMISSION,
    routeRef: createRef(),
  },
  {
    text: 'Sign Contract',
    stepName: STEP_NAME.SIGN_CONTRACT,
    prevBtnText: GO_BACK_BTN_TEXT,
    nextBtnText: FINISH_BTN_TEXT,
    route: STORE_CREATION_ROUTES.STORE_CREATION_CONTRACT,
    routeRef: createRef(),
  },
];

export const DEFAULT_PRICE_POINTS = {
  isSuperInexpensive: true,
  isBudget: true,
  isStandard: true,
  isPremium: true,
};
