import { MultiValue } from 'react-select';
import { OptionsGroupedType, OptionType } from '@ezetech/swag-space-x';

export interface IProduct {
  isHidden: boolean;
  margin: number;
  name: string;
  logo: string;
  publicId: string;
  id: string;
}

export interface IHomepageProduct {
  isHidden: boolean;
  sku: number;
  name: string;
  logo: string;
  publicId: string;
  isForbiddenToShowOnDashboard: boolean;
}

export interface IHomepageProductEditor extends IHomepageProduct {
  isChecked: boolean;
}

export interface IHomepageSelectedProduct {
  publicId: string;
  logo: string;
  name: string;
}

export interface IProductsListRequest {
  perPage: number;
  page: number;
  search: string;
  filter: MultiValue<OptionType | null>;
  includeHiddenList?: boolean;
  selectedIds?: string[];
  excludedIds?: string[];
}

export interface IProductsListResponse {
  list: Array<IProduct>;
  perPage: number;
  page: number;
  pages: number;
  productsTotal: number;
  availableProductsTotalToHide: number;
  areAllSelected: boolean;
  hiddenProductsIds?: string[];
}

export interface IProductsHiddenRequest {
  selected: string[];
  unselected: string[];
}

export interface IProductsHiddenResponse {
  selected: number;
  unselected: number;
  hiddenProductsTotal: number;
}

export interface IProductsIdsListRequest {
  search: string;
  filter: MultiValue<OptionType | null>;
}

export interface IProductsIdsListResponse {
  hidden: string[];
  visible: string[];
}

export interface IHomepageProductsListRequest {
  search?: string;
}

export interface IHomepageProductsListResponse {
  list: Array<IHomepageProduct>;
  perPage: number;
}

export const PRODUCTS_FILTERS: OptionType[] | OptionsGroupedType[] = [
  { label: 'Only sub 30% products', value: 'marginLt:0.3' },
  { label: 'Only hidden products', value: 'isHidden:true' },
  { label: 'Only visible products', value: 'isHidden:false' },
];
