import {
  Dialog,
  DialogContentWrapper,
  DialogTitle,
  DialogDescription,
  DialogFooter,
  SwagButton,
  Typography,
} from '@ezetech/swag-space-x';
import { FieldValues, useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { closePopup, openPopup } from 'redux/slices/modals.slice';
import { ConnectDomainPopupProps } from 'interfaces/popup.interface';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { InputForm } from 'components/input-form';

import { useAddDomainMutation, useEditDomainMutation } from 'redux/api/settings.api';
import { domainSettingsSelector } from 'redux/selectors/settings.selectors';
import { CONNECT_DOMAIN_MODE } from 'interfaces/store-creation.interfaces';
import { DNS_SETTINGS_POPUP } from '../_logic/popups-list';
import css from './connect-domain.popup.module.scss';
import { FIELDS } from './connect-domain.constants';

export const ConnectDomainPopup = ({
  isOpen,
  mode = CONNECT_DOMAIN_MODE.CREATE,
}: ConnectDomainPopupProps): JSX.Element => {
  const { control, formState, getValues, setFocus, setValue } = useForm<FieldValues>({
    mode: 'all',
    delayError: 1000,
  });
  const [addDomain, { isLoading: isDomainCreation }] = useAddDomainMutation();
  const [editDomain, { isLoading }] = useEditDomainMutation();
  const customDomainInfo = useAppSelector(domainSettingsSelector);
  const dispatch = useAppDispatch();

  const { isValid } = formState;
  const domainEntered = Boolean(getValues(FIELDS.domain.name)?.length);

  useEffect(() => {
    setFocus(FIELDS.domain.name);
  }, [setFocus]);

  useEffect(() => {
    if (customDomainInfo?.domain) {
      setValue(FIELDS.domain.name, customDomainInfo?.domain, {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  }, [customDomainInfo?.domain]);

  const isDisabled = !isValid || !domainEntered;
  const onBtnCloseClick = () => {
    dispatch(closePopup());
  };

  const onBtnNextClick = async () => {
    const newDomain = getValues(FIELDS.domain.name);
    if (!customDomainInfo) {
      const response = await addDomain({ domain: newDomain.toLowerCase() });
      if ('data' in response) {
        dispatch(
          openPopup({
            popupName: DNS_SETTINGS_POPUP,
            popupProps: {},
          }),
        );
      }
    } else {
      const response = await editDomain({
        domain: newDomain.toLowerCase(),
        id: customDomainInfo.id,
      });
      if ('data' in response) {
        dispatch(
          openPopup({
            popupName: DNS_SETTINGS_POPUP,
            popupProps: {},
          }),
        );
      }
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onBtnCloseClick}>
      <DialogContentWrapper
        className={css.dialogContentWrapperClassName}
        overlayClassName={css.dialogOverlayClassName}
      >
        <DialogTitle closeIconClassName={css.closeIconClassName}>
          Connect existing Domain
        </DialogTitle>
        <DialogDescription className={css.contentWrapper}>
          <div className={css.description}>
            <Typography
              color={COLORS.colorTextSubdued}
              fontType="bodyMd"
              lineHeight="162.5%"
            >
              Enter the domain you want to connect. You can also connect a subdomain, for
              example swag.your-company.com.
            </Typography>
          </div>
          <InputForm
            autoComplete="off"
            type="text"
            label={FIELDS.domain.label}
            inputClassName={css.inputClassName}
            errorClassName={css.errorClassName}
            labelClassName={css.labelClassName}
            value={customDomainInfo?.domain}
            defaultValue={customDomainInfo?.domain}
            shouldTrimInputValue
            name={FIELDS.domain.name}
            placeholder={FIELDS.domain.placeholder}
            rules={FIELDS.domain.rules}
            control={control}
          />
        </DialogDescription>

        <DialogFooter className={css.footer}>
          <SwagButton
            disabled={isDisabled}
            type="primary"
            onClick={onBtnNextClick}
            loading={isLoading || isDomainCreation}
          >
            Next
          </SwagButton>
        </DialogFooter>
      </DialogContentWrapper>
    </Dialog>
  );
};
