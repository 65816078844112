import { settingsApi } from 'redux/api/settings.api';
import { getPublicSid } from 'utils/auth.util';
import { fetchUserData } from 'utils/user.util';
import { IAction } from 'interfaces/loaders.interface';
import { makeToastNotification } from 'utils/query.util';
import { pushNotification } from '../../redux/slices/notifications.slice';
import { can, SUBJECTS } from '../../boot/ability';
import { store } from '../../redux/store';

export async function pageLoader(): Promise<null> {
  await fetchUserData();

  if (!getPublicSid()) {
    return null;
  }

  const requests: Record<string, unknown>[] = [
    {
      endpoint: settingsApi.endpoints.getPayout.initiate(),
      condition: can(
        SUBJECTS.PAYOUT_BANK_DETAILS.actions.PAYOUT_BANK_DETAILS_VIEW,
        SUBJECTS.PAYOUT_BANK_DETAILS.value,
      ),
    },
  ];

  try {
    const results = await Promise.all(
      requests.map(async (request) => {
        if (request?.condition === false) {
          return;
        }

        return store.dispatch(request.endpoint as IAction).unwrap();
      }),
    );

    if (results.some((result) => result && 'error' in result)) {
      return null;
    }
  } catch (error) {
    store.dispatch(pushNotification(makeToastNotification(error)));
  }

  return null;
}
