import { pluralize } from '../utils/string.util';

import { MIN_LINK_CHARACTERS, MAX_LINK_CHARACTERS } from './store-creation.constants';

export const emailRegexp = /^[^\s]+@[^\s]+\.[^\s]+$/;
export const cellPhoneRegexp = /^[+]?[0-9()-.\s]+$/;
export const certificateNumberRegexp = /^[A-Za-z0-9-]*$/;
export const alphanumericRegexp = /^[A-Za-z0-9-]*$/;
// eslint-disable-next-line security/detect-unsafe-regex
export const domainRegex = new RegExp(
  // eslint-disable-next-line security/detect-unsafe-regex
  /^(https?:\/\/)?[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*\.[a-zA-Z]{2,}$/,
);

export const facebookRegexp =
  /^https:\/\/(www\.)?(mbasic.facebook|m\.facebook|facebook|fb)\.(com|me)\/.+$/i;
export const tiktokRegexp = /^https:\/\/(www\.)?tiktok.com\/.+$/i;
export const instagramRegexp = /^https:\/\/(www\.)?instagram.com\/.+$/i;
export const twitterRegexp = /^https:\/\/(www\.)?(twitter|x)\.com\/.+$/i;
export const linkedInRegexp = /^https:\/\/(www\.)?(linkedin)\.com\/.+$/i;

// Took from https://github.com/nicolaspayot/bic/blob/master/src/index.ts
// Check validation https://wise.com/gb/swift-codes/bic-swift-code-checker
// This Regexp runs on the client side only,
// in this case, we can ignore (detect-unsafe-regex) rule, and long time executions.
// For the back-end side we can't ignore such rules.
// eslint-disable-next-line security/detect-unsafe-regex
export const BICSwiftRegexp = /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/;

export const routingNumberRegexp = /^[0-9]{9}$/;

// We suppress error here because we will never run out of
// resources (So we can't block event loop) in this particular case,
// because we check length of string earlier
// eslint-disable-next-line security/detect-unsafe-regex
export const storeNameRegexp = new RegExp(
  // eslint-disable-next-line security/detect-unsafe-regex
  /^(?!.*--)[a-z0-9](?:[a-z0-9-]{0,}[a-z0-9])?$/,
  'g',
);
export const noDoubleDashes = new RegExp(/^(?!.*[-]{2}).*$/, 'g');

export const EMAIL_LENGTH = 254;

export const CELL_PHONE_LENGTH = 30;

export const ERROR_MSG = {
  url: 'Must be a valid URL',
  urlWithExample:
    'Invalid URL format. Make sure your URL has the format https://www.your-homepage.com',
  number: 'Value must be a number',
  required: 'This field is required',
  trim: 'Please remove unnecessary spaces',
  email: "Email you entered doesn't seem to be valid",
  cellPhone:
    'Should be only digits, spaces and symbols: pluses, parentheses, hyphens, periods',
  cellPhoneDoubleDashes: 'Cell phone cannot have two dashes in a row.',
  max: (number: number) => `Maximum ${pluralize(number, 'symbol')}`,
  min: (number: number) => `Minimum ${pluralize(number, 'symbol')}`,
  digitsSymbols: 'Should be digits and symbols',
  digits: 'Should be digits',
  passwordsMatch: 'Passwords must match',
  storeNameMatch:
    'A subdomain can include alphanumeric characters (a-z, 0-9) and dashes (-).',
  storeNameEndStartMatch: 'A subdomain must start and end with a letter or a number.',
  storeNameTwoSymbolsMatch: 'Subdomains cannot have two dashes in a row.',
  storeNameMinLength: `A subdomain must be at least ${MIN_LINK_CHARACTERS} characters.`,
  storeNameMaxLength: `A subdomain must be ${MAX_LINK_CHARACTERS} characters maximum.`,
  marginInvalid: 'The commission cannot be lower than 30%',
  BICSwiftInvalid: 'BIC/SWIFT invalid',
  routingNumberInvalid: 'Should be a 9-digit number',
};
