import { IconType } from './type';

export const ArrowDownSmallIcon = ({
  className,
  fillColor = '#d90b0b',
  width = '16px',
  height = '12px',
}: IconType): JSX.Element => {
  const style = { width, height, lineHeight: 0 };
  return (
    <div style={style} className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
          fill={fillColor}
        />
      </svg>
    </div>
  );
};
