import * as yup from 'yup';

import { BICSwiftRegexp, ERROR_MSG, routingNumberRegexp } from 'constants/validation';
import { FIELDS, Saving } from './payout.contants';

export const payoutFormSchema = yup.object({
  [FIELDS.payoutBankCountry.name]: yup
    .string()
    .trim()
    .min(2, ERROR_MSG.min(2))
    .max(2, ERROR_MSG.max(2))
    .required(ERROR_MSG.required),
  [FIELDS.payoutBankName.name]: yup
    .string()
    .trim()
    .min(2, ERROR_MSG.min(2))
    .max(80, ERROR_MSG.max(80))
    .required(ERROR_MSG.required),
  [FIELDS.payoutAccountType.name]: yup
    .string()
    .oneOf(Object.values(Saving))
    .required(ERROR_MSG.required),
  [FIELDS.payoutAccountBeneficiaryName.name]: yup
    .string()
    .trim()
    .min(2, ERROR_MSG.min(2))
    .max(80, ERROR_MSG.max(80))
    .required(ERROR_MSG.required),
  [FIELDS.payoutBankAccountNumber.name]: yup
    .string()
    .trim()
    .min(5, ERROR_MSG.min(5))
    .max(34, ERROR_MSG.max(34))
    .required(ERROR_MSG.required),
  [FIELDS.payoutBIC.name]: yup
    .string()
    .trim()
    .when(FIELDS.payoutBankCountry.name, (country, field) => {
      return country[0] !== 'US'
        ? field
            .matches(BICSwiftRegexp, ERROR_MSG.BICSwiftInvalid)
            .required(ERROR_MSG.required)
        : field
            .transform((value) => (!value ? null : value))
            .matches(BICSwiftRegexp, ERROR_MSG.BICSwiftInvalid)
            .optional()
            .nullable();
    }),
  [FIELDS.payoutRoutingNumber.name]: yup
    .string()
    .trim()
    .when(FIELDS.payoutBankCountry.name, (country, field) => {
      return country[0] === 'US'
        ? field
            .transform((value) => (!value ? null : value))
            .matches(routingNumberRegexp, ERROR_MSG.routingNumberInvalid)
            .optional()
            .nullable()
        : field.optional().nullable();
    }),
  [FIELDS.payoutComments.name]: yup
    .string()
    .trim()
    .max(200, ERROR_MSG.max(200))
    .optional(),
});
