import {
  Dialog,
  DialogContentWrapper,
  DialogTitle,
  DialogDescription,
} from '@ezetech/swag-space-x';
import { useAppDispatch } from 'redux/store';
import { closePopup } from 'redux/slices/modals.slice';

import { InvoiceCreationCustomerInfoProps } from 'interfaces';
import { InvoiceCreationCustomerForm } from 'pages/settings/company-and-brand/forms/invoice-creation-customer-info/customer-info';
import { ROUTES } from 'constants/router';
import css from './invoice-creation-customer-info-popup.module.scss';

export const InvoiceCreationCustomerInfoPopup = ({
  isOpen,
  presentationEmail,
}: InvoiceCreationCustomerInfoProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const fromPresentationPage = location.pathname === ROUTES.PDF_PRESENTATION;

  const onBtnCloseClick = () => {
    dispatch(closePopup());
  };

  return (
    <Dialog open={isOpen} onOpenChange={onBtnCloseClick}>
      <DialogContentWrapper
        className={css.dialogContentWrapperClassName}
        overlayClassName={css.dialogOverlayClassName}
      >
        <DialogTitle closeIconClassName={css.closeIconClassName}>
          What customer is this for?
        </DialogTitle>
        <DialogDescription className={css.description}>
          <InvoiceCreationCustomerForm
            presentationEmail={presentationEmail || ''}
            fromPresentationPage={fromPresentationPage}
          />
        </DialogDescription>
      </DialogContentWrapper>
    </Dialog>
  );
};
