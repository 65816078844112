import { useEffect } from 'react';

import {
  RainbowBox,
  Typography,
  ColorsSelector,
  ImageUpload,
  ImageCropTools,
  useImageCropTools,
} from '@ezetech/swag-space-x';

import { useAppDispatch, useAppSelector } from 'redux/store';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { useUploadLogoMutation } from 'redux/api/store-creation.api';
import { setPrimaryColor, setSecondaryColor } from 'redux/slices/store-creation.slice';
import {
  resellerLogoUrlSelector,
  resellerPrimaryColorSelector,
  resellerSecondaryColorSelector,
} from 'redux/selectors/store-creation.selectors';

import css from './brand.module.scss';

export function BrandComponent(): JSX.Element {
  const dispatch = useAppDispatch();
  const primaryColor = useAppSelector(resellerPrimaryColorSelector);
  const secondaryColor = useAppSelector(resellerSecondaryColorSelector);
  const logoUrl = useAppSelector(resellerLogoUrlSelector);
  const [uploadLogo] = useUploadLogoMutation();

  const onPrimaryColorPick = (color: string): void => {
    dispatch(setPrimaryColor({ primaryColor: color }));
  };

  const onSecondaryColorPick = (color: string): void => {
    dispatch(setSecondaryColor({ secondaryColor: color }));
  };

  const handleUpload = (files: File[]): void => {
    if (!files.length) {
      return;
    }
    const fileReader = new FileReader();
    fileReader.onload = () => {
      const formData = new FormData();
      formData.set('file', files[0]);

      uploadLogo(formData);
    };
    fileReader.readAsDataURL(files[0]);
  };

  const {
    toolsOnSaveHandler,
    toolsOnCloseHandler,
    toolsOnScaleHandler,
    toolsOnRevertHandler,

    imageCropOnCroppedHandler,
    imageCropOnLoadHandler,
    imageCropSetSrc,

    setIsVisibleImageCrop,
    onSaved,

    fitScale,
    scale,
    blobCropped,
    isShowImageCrop,
    src,
    isSaving,
  } = useImageCropTools(logoUrl);

  const toolsOnSave = async () => {
    if (blobCropped) {
      toolsOnSaveHandler();
      const formData = new FormData();
      formData.set('file', blobCropped);
      await uploadLogo(formData);
      onSaved();
      toolsOnCloseHandler();
    }
  };

  const toolsOnRevert = (newSrc: string) => {
    toolsOnRevertHandler(newSrc);
  };

  useEffect(() => {
    if (!logoUrl) {
      return;
    }
    imageCropSetSrc(logoUrl);
  }, [logoUrl]);

  return (
    <section className={css.section}>
      <div className={css.textBlock}>
        <Typography lineHeight="100%" fontType="HEADING6XL" color={COLORS.colorBlack}>
          Create your e-commerce experience
        </Typography>

        <Typography
          className={css.mainHeading}
          lineHeight="100%"
          fontType="HEADING3XL"
          color={COLORS.colorTypographyHeadings}
        >
          First upload your logo and select your brand colors!
        </Typography>
      </div>

      <RainbowBox
        borderWidth={5}
        outerClassName={css.outerBoxClassName}
        innerClassName={css.innerBoxClassName}
      >
        <ImageUpload
          url={src || ''}
          onUpload={handleUpload}
          buttonText={logoUrl ? 'Re-Upload' : 'Upload your logo'}
          imageCropProps={{
            src: src || '',
            onLoad: imageCropOnLoadHandler,
            onImageCropped: imageCropOnCroppedHandler,
            scale,
          }}
          withImageCrop={true}
          isShowImageCrop={isShowImageCrop}
          onShowImageCrop={setIsVisibleImageCrop}
        />

        {isShowImageCrop && (
          <ImageCropTools
            src={src || ''}
            scale={scale}
            onRevert={toolsOnRevert}
            onScale={toolsOnScaleHandler}
            fitScale={fitScale}
            onSave={toolsOnSave}
            onClose={toolsOnCloseHandler}
            wrapInWhiteBox
            maxScalePercent={200}
            isSaving={isSaving}
          />
        )}
        {!isShowImageCrop && (
          <ColorsSelector
            primaryColorPickerWrapperClassName={css.primaryColorPickerWrapperClassName}
            secondaryColorPickerWrapperClassName={
              css.secondaryColorPickerWrapperClassName
            }
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
            onPrimaryChange={onPrimaryColorPick}
            onSecondaryChange={onSecondaryColorPick}
            className={css.colorSelector}
          />
        )}
      </RainbowBox>
    </section>
  );
}
