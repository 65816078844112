import { Typography, TierProgressBar } from '@ezetech/swag-space-x';
import COLORS from 'constants/styles/colors-js.constant.module.scss';

import { useAppSelector } from '../../../redux/store';
import { dashboardSelector } from '../../../redux/selectors/dashboard.selectors';

import {
  getColorByTierName,
  getCalculatedDataForTier,
  getNextTier,
} from './tier-card.utils';

import css from './tier-card-progress.module.scss';

export function TierCardProgress(): JSX.Element {
  const currencySymbol = '$';
  const data = useAppSelector(dashboardSelector);
  const nextTier = getNextTier(data.tierPromotion, data.tiers);
  const { revenueToNextTierFormatted, overallRevenueToNextTier, lastYearOverallRevenue } =
    getCalculatedDataForTier(data);
  const tierColor = getColorByTierName(data.tierPromotion.name);

  return (
    <>
      <div className={css.progressWrapper}>
        <TierProgressBar
          from={lastYearOverallRevenue}
          to={overallRevenueToNextTier}
          valueSymbol={currencySymbol}
          value={data.overallRevenue}
          colorBorders={tierColor}
          background={tierColor}
          isPercent={false}
        />
      </div>
      <div className={css.revenue}>
        <Typography lineHeight="162%" color={COLORS.colorPrimaryText}>
          {currencySymbol}
          {revenueToNextTierFormatted}
          <span className={css.revenueInfo}>
            &nbsp;in revenue to proceed to {nextTier?.name || 'Win'}
          </span>
        </Typography>
      </div>
    </>
  );
}
