import { storeSettingsApi } from 'redux/api/store-creation.api';
import { store } from '../../redux/store';
import { getPublicSid } from '../../utils/auth.util';

export async function onboardingLoader(): Promise<null> {
  if (!getPublicSid()) {
    return null;
  }

  try {
    const request = store.dispatch(storeSettingsApi.endpoints.getCategories.initiate());
    const result = await request.unwrap();

    if ('error' in result) {
      return null;
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }

  return null;
}
