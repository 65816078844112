import * as yup from 'yup';

import { emailRegexp, EMAIL_LENGTH, ERROR_MSG } from 'constants/validation';
import { FIELDS } from './customer-info.constants';

export const customerFormSchema = yup
  .object({
    [FIELDS.customerEmail.name]: yup
      .string()
      .trim()
      .matches(emailRegexp, ERROR_MSG.email)
      .max(EMAIL_LENGTH, ERROR_MSG.max(EMAIL_LENGTH))
      .required(ERROR_MSG.required),
    [FIELDS.city.name]: yup
      .string()
      .strict(true)
      .trim(ERROR_MSG.trim)
      .min(1, ERROR_MSG.min(1))
      .max(30, ERROR_MSG.max(30))
      .optional(),
    [FIELDS.zip.name]: yup
      .string()
      .strict(true)
      .trim(ERROR_MSG.trim)
      .min(4, ERROR_MSG.min(4))
      .max(6, ERROR_MSG.max(6))
      .optional(),
    [FIELDS.companyName.name]: yup
      .string()
      .strict(true)
      .trim(ERROR_MSG.trim)
      .min(2, ERROR_MSG.min(2))
      .max(50, ERROR_MSG.max(50))
      .required(ERROR_MSG.required),
    [FIELDS.country.name]: yup
      .string()
      .strict(true)
      .trim(ERROR_MSG.trim)
      .min(2, ERROR_MSG.min(2))
      .max(2, ERROR_MSG.max(2))
      .optional(),
    [FIELDS.address.name]: yup
      .string()
      .strict(true)
      .trim(ERROR_MSG.trim)
      .min(1, ERROR_MSG.min(1))
      .max(255, ERROR_MSG.max(255))
      .optional(),
    [FIELDS.state.name]: yup
      .string()
      .strict(true)
      .trim(ERROR_MSG.trim)
      .max(255, ERROR_MSG.max(255))
      .optional()
      .transform((value) => (value ? value : undefined))
      .optional(),
  })
  .test('is-optional', '', (value) => {
    return Object.values(value).some((v) => !!v);
  });
