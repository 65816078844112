import {
  Dialog,
  DialogContentWrapper,
  DialogTitle,
  DialogDescription,
  DialogFooter,
  SwagButton,
  Typography,
} from '@ezetech/swag-space-x';
import { ResellerMockupApprovedPopupProps } from 'interfaces/popup.interface';
import { useAppDispatch } from 'redux/store';
import { closePopup } from 'redux/slices/modals.slice';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { useRefetchCustomerOrders } from 'hooks/use-refetch-orders.hook';
import css from './reseller-mockup-approved-popup.module.scss';

export const ResellerMockupApprovedPopup = ({
  isOpen,
  isReviewMockupsByCustomerAllowed,
}: ResellerMockupApprovedPopupProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const refetchOrders = useRefetchCustomerOrders();

  const onBtnClick = () => {
    refetchOrders();
    dispatch(closePopup());
  };

  return (
    <Dialog open={isOpen} onOpenChange={onBtnClick}>
      <DialogContentWrapper
        className={css.dialogContentWrapperClassName}
        overlayClassName={css.dialogOverlayClassName}
      >
        <DialogTitle closeIconClassName={css.closeIconClassName}>
          Mockup Approved!
        </DialogTitle>
        <DialogDescription className={css.contentWrapper}>
          <Typography
            color={COLORS.colorTextSubdued}
            fontType="bodyMd"
            lineHeight="162.5%"
          >
            {isReviewMockupsByCustomerAllowed
              ? 'Amazing, your mockup has now been approved and we will send these to your customer to approve.'
              : 'Your Mockup has been approved and production will now begin!'}
          </Typography>
        </DialogDescription>

        <DialogFooter className={css.footerClassName}>
          <SwagButton
            type="primary"
            size="large"
            onClick={onBtnClick}
            className={css.btn}
          >
            Close
          </SwagButton>
        </DialogFooter>
      </DialogContentWrapper>
    </Dialog>
  );
};
