import {
  CategoriesStructureWithSelections,
  IPricePoints,
} from '../interfaces/store-creation.interfaces';

export function hasSelectedCategoriesAndPrices(
  categories: CategoriesStructureWithSelections,
  pricePoints: IPricePoints,
): boolean {
  if (
    !pricePoints?.isBudget &&
    !pricePoints?.isPremium &&
    !pricePoints?.isStandard &&
    !pricePoints?.isSuperInexpensive
  ) {
    return false;
  }

  return categories.reduce<boolean>((acc, parentCategory) => {
    if (acc) {
      return true;
    }
    if (!parentCategory.isEnabled) {
      return false;
    }

    return (
      acc ||
      parentCategory.items.reduce<boolean>(
        (hasSelectedCategory, category) =>
          hasSelectedCategory ? hasSelectedCategory : category.isSelected,
        false,
      )
    );
  }, false);
}
