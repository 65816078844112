import { CellTextColor } from '@ezetech/swag-space-x';
import { createSelector } from 'reselect';

import { RootState } from 'redux/store';
import { OPEN_NOTES_TEXT, PER_PAGE_CARTS } from 'constants/carts.constant';
import {
  CARTS_TABLE_HEADER,
  CARTS_TABLE_ROW,
  CartTypeEnum,
  CartsFields,
  ITransformCartCell,
  CART_FILTER_OPTIONS,
} from 'interfaces/cart.interfaces';
import { can } from 'boot/ability';
import { SUBJECTS } from 'constants/ability.constant';
import { ASSIGNED_TO_ME_ENTITY } from 'constants/common';
import { pluralize } from 'utils/string.util';
import { Price } from 'utils/price.util';
import { getTimeMmmDdYyyy } from 'utils/date/date-formatter.util';
import { getNotesText, mapItemIntoDetailedViewProduct } from 'utils/cart.utils';
import { pageFiltersSelector } from './filters.selectors';
import { assignmentFlowEnabledSelector } from './settings.selectors';

export const cartsSelector = (state: RootState) => state.carts;

export const cartsListSelector = createSelector(cartsSelector, (state) => state.carts);

export const cartsHeaderSelector = createSelector(
  assignmentFlowEnabledSelector,
  (isOrderAssignmentEnabled) => {
    if (!isOrderAssignmentEnabled) {
      return CARTS_TABLE_HEADER.filter((field) => field.id !== CartsFields.assignTo);
    }
    return CARTS_TABLE_HEADER;
  },
);

export const cartsRowsSelector = createSelector(
  cartsListSelector,
  pageFiltersSelector,
  cartsHeaderSelector,
  (list, page, cartsHeader) => {
    const startIndexingFrom = (page - 1) * PER_PAGE_CARTS;
    const rows: { cells: ITransformCartCell[]; index: number }[] = [];

    list.forEach((cart, position) => {
      const cells: (ITransformCartCell & {
        isNonClickable?: boolean;
        isOrdered?: boolean;
        email?: string;
      })[] = [];

      cartsHeader.forEach((cell) => {
        const common = CARTS_TABLE_ROW[cell.id] || {};
        const cellParams = { ...common, rowId: cart.id };

        const isOrdered = Boolean(cart.placedCartOrderNum);

        const assignedToUser = cart?.assignee
          ? {
              value: cart.assignee.id,
              label: `${cart?.assignee.firstName} ${cart?.assignee.lastName}`,
            }
          : null;

        if (cell.id === CartsFields.id) {
          cells.push({
            ...cellParams,
            id: cell.id,
            text: `#${position + startIndexingFrom + 1}`,
          });
        }
        if (cell.id === CartsFields.customerEmail) {
          cells.push({
            ...cellParams,
            text: cart.email,
            id: CartsFields.customerEmail,
          });
        }
        if (cell.id === CartsFields.companyName) {
          cells.push({
            ...cellParams,
            text: cart.companyName || 'n/a',
            textColor: cart.companyName ? CellTextColor.pink : CellTextColor.primary,
            id: CartsFields.companyName,
          });
        }
        if (cell.id === CartsFields.products) {
          cells.push({
            ...cellParams,
            text: `${cart.items.length} ${pluralize(
              cart.items.length,
              'Product',
              's',
              true,
            )}`,
            id: CartsFields.products,
          });
        }
        if (cell.id === CartsFields.cartTotal) {
          cells.push({
            ...cellParams,
            text: Price.formatPrice(cart.cartTotal),
            id: CartsFields.cartTotal,
          });
        }
        if (cell.id === CartsFields.createdAt) {
          cells.push({
            ...cellParams,
            text: cart.createdAt
              ? getTimeMmmDdYyyy(new Date(cart.createdAt), {
                  day: 'numeric',
                  month: 'short',
                })
              : 'n/a',
            id: CartsFields.createdAt,
          });
        }
        if (cell.id === CartsFields.link) {
          cells.push({
            ...cellParams,
            text: cart.placedCartOrderNum
              ? cart.placedCartOrderNum ?? ''
              : cart.absoluteCartLinkToShare ?? '',
            isOrdered,
            id: CartsFields.link,
          });
        }
        if (cell.id === CartsFields.cartType) {
          cells.push({
            ...cellParams,
            text: cart.type === CartTypeEnum.PRE_BUILT_LIMITED ? 'Limited' : 'Regular',
            id: CartsFields.cartType,
          });
        }
        if (cell.id === CartsFields.notes) {
          cells.push({
            ...cellParams,
            text: getNotesText(cart),
            isNonClickable: getNotesText(cart) !== OPEN_NOTES_TEXT,
            email: cart.email,
            id: CartsFields.notes,
          });
        }
        if (cell.id === CartsFields.assignTo) {
          cells.push({
            ...cellParams,
            id: CartsFields.assignTo,
            text: '',
            assignedToUser,
          });
        }
        if (cell.id === CartsFields.manage) {
          cells.push({
            ...cellParams,
            text: isOrdered ? 'Ordered' : 'Pending',
            isOrdered,
            id: CartsFields.manage,
          });
        }
      });

      rows.push({
        cells,
        index: position + 1,
      });
    });

    return rows;
  },
);

export const getCartNotesSelector = createSelector(
  cartsSelector,
  (state) => state.cartNotes,
);

export const isPaginationCartVisibleSelector = createSelector(
  cartsListSelector,
  (list) => list.length > 0,
);

export const myOrdersCountSelector = createSelector(
  cartsSelector,
  (cart) => cart.myOrdersCount,
);

export const selectedCartProductsSelector = (cartId?: string) =>
  createSelector(cartsListSelector, (carts) => {
    const cart = carts.find((item) => item.id === cartId);

    if (!cart) {
      return [];
    }

    return cart.items.map(mapItemIntoDetailedViewProduct);
  });

export const getProductsNumber = (text: string): number => {
  const numbers = text.match(/\d+/);

  if (!numbers) {
    return 0;
  }

  return parseFloat(numbers[0]);
};

export const cartsFilterOptionsSelector = createSelector(
  assignmentFlowEnabledSelector,
  (isOrderAssignmentEnabled) => {
    const isAbleToBeAssignedForCart = can(
      SUBJECTS.CREATE_EDIT_BUILD_A_CART.actions.ASSIGN_BUILD_A_CART,
      SUBJECTS.CREATE_EDIT_BUILD_A_CART.value,
    );

    if (!isOrderAssignmentEnabled && isAbleToBeAssignedForCart) {
      return CART_FILTER_OPTIONS.filter(
        (option) => option.value !== ASSIGNED_TO_ME_ENTITY,
      );
    }
    return CART_FILTER_OPTIONS;
  },
);

export const initialCartsFiltersSelector = createSelector(
  assignmentFlowEnabledSelector,
  (isOrderAssignmentEnabled) => {
    const isAbleToBeAssignedForCart = can(
      SUBJECTS.CREATE_EDIT_BUILD_A_CART.actions.ASSIGN_BUILD_A_CART,
      SUBJECTS.CREATE_EDIT_BUILD_A_CART.value,
    );

    if (isOrderAssignmentEnabled && isAbleToBeAssignedForCart) {
      return CART_FILTER_OPTIONS.filter(
        (option) => option.value === ASSIGNED_TO_ME_ENTITY,
      );
    }
    return [];
  },
);
