import { RainbowInformation } from 'components/rainbow-information';

export function InvitationForbidden() {
  return (
    <RainbowInformation
      tagline="Uh oh!"
      title="You are already a team member of another swag space account."
      description="Each person is only allowed to be a team member of one Swag Space account. In order to become a team member on this account you first need to have your access revoked from another account."
    />
  );
}
