import { FC, useEffect } from 'react';
import { Dropzone, IFile, Typography } from '@ezetech/swag-space-x';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { PdfPresentationEditorPageFieldDefinitionComponentFuncProps } from 'interfaces/pdf-presentation';
import { deepGet } from 'utils/deep-get.util';
import {
  WHAT_WE_OFFER_IMAGE_ACCEPT_RESTRICTION,
  WHAT_WE_OFFER_IMAGE_MB_RESTRICTION,
} from 'constants/limits';
import { BRAND_LOGOS_LENGTH } from 'constants/pdf-presentation.constants';
import { useProcessFile } from '../../../../../hooks/use-process-file';
import classes from '../../edit-pdf-presentation-layout-popup.module.scss';

export const MultipleImageUploader: FC<
  PdfPresentationEditorPageFieldDefinitionComponentFuncProps
> = ({ values, onChangeValueCreator, onProcess, setIsExitBlocked }) => {
  const allFiles = deepGet<Array<IFile | null>>(
    values as never,
    'brandLogos',
    Array.from<IFile | null>({ length: 16 }).fill(null),
  )!;
  const onChangeHandler = onChangeValueCreator('brandLogos');
  const { handleProcessFile, isLoading } = useProcessFile();
  useEffect(() => {
    setIsExitBlocked?.(isLoading);
    onProcess && onProcess(isLoading);
  }, [isLoading]);
  const handleChange = (index: number, files: IFile[]) => {
    const file = files[0] ?? null;
    const newFiles = [...allFiles].map((f, fIndex) => {
      if (fIndex === index) {
        return file;
      }
      return f;
    });
    onChangeHandler(newFiles);
  };

  const getImageNumber = (i: number, isSecond = false) => {
    return isSecond ? i * 2 + 2 : i * 2 + 1;
  };

  return (
    <div className={classes.multipleImageUploader}>
      {Array.from({ length: BRAND_LOGOS_LENGTH / 2 }).map((_, i) => {
        const file1 = deepGet<IFile>(values as never, `brandLogos.${i * 2}`);
        const file2 = deepGet<IFile>(values as never, `brandLogos.${i * 2 + 1}`);

        return (
          <div className={classes.multipleImageUploaderRow} key={`row-${i}`}>
            <div className={classes.dropzoneWrapper}>
              <Typography
                className={classes.dropzoneLabel}
                color={COLORS.colorPrimaryText}
                lineHeight="142.857%"
                fontType="bodySm"
              >
                Logo {getImageNumber(i)}
              </Typography>
              <Dropzone
                className={classes.dropzone}
                buttonClassName={classes.dropzoneButton}
                previewClassName={classes.dropzonePreview}
                accept={WHAT_WE_OFFER_IMAGE_ACCEPT_RESTRICTION}
                maxSize={WHAT_WE_OFFER_IMAGE_MB_RESTRICTION}
                previewButtonsClassName={classes.dropzonePreviewButtons}
                uploadTextWrapperClassName={classes.dropzoneUploadTextWrapper}
                files={file1 ? [file1] : []}
                processFile={handleProcessFile}
                onFilesChanged={(files) => handleChange(i * 2, files)}
                additionalText="JPG or PNG, no more than 10MB"
                helperText="Recommended size is 320 x 320 pixels"
                helperTextClassName={classes.dropzoneHelperText}
              />
            </div>
            <div className={classes.dropzoneWrapper}>
              <Typography
                className={classes.dropzoneLabel}
                color={COLORS.colorPrimaryText}
                lineHeight="142.857%"
                fontType="bodySm"
              >
                Logo {getImageNumber(i, true)}
              </Typography>
              <Dropzone
                className={classes.dropzone}
                buttonClassName={classes.dropzoneButton}
                previewClassName={classes.dropzonePreview}
                accept={WHAT_WE_OFFER_IMAGE_ACCEPT_RESTRICTION}
                maxSize={WHAT_WE_OFFER_IMAGE_MB_RESTRICTION}
                previewButtonsClassName={classes.dropzonePreviewButtons}
                uploadTextWrapperClassName={classes.dropzoneUploadTextWrapper}
                files={file2 ? [file2] : []}
                processFile={handleProcessFile}
                onFilesChanged={(files) => handleChange(i * 2 + 1, files)}
                additionalText="JPG or PNG, no more than 10MB"
                helperText="Recommended size is 320 x 320 pixels"
                helperTextClassName={classes.dropzoneHelperText}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};
