import { OptionType } from '@ezetech/swag-space-x';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { MultiValue } from 'react-select';
import { PresentationStatusEnum, ReducerPath } from 'interfaces';
import {
  makeToastNotification,
  makeSuccessToastNotification,
  downloadFileInBrowser,
} from 'utils/query.util';
import { setFilter } from 'redux/slices/filter.slice';
import { closePopup, openPopup } from 'redux/slices/modals.slice';
import { filtersRequestSelector } from 'redux/selectors/filters.selectors';
import { RootState } from 'redux/store';
import {
  IGetPresentationsRequest,
  IGetPresentationsResponse,
} from 'interfaces/presentations.interface';
import { CartTypeEnum } from 'interfaces/cart.interfaces';
import { ASSIGNED_TO_ME_ENTITY } from 'constants/common';
import { setMyOrdersCount } from 'redux/slices/carts.slice';
import {
  setPresentations,
  updatePresentation,
} from 'redux/slices/pdf-presentations.slice';
import { pushNotification } from 'redux/slices/notifications.slice';
import { COPY_CART_LINK_POPUP } from 'components/popups/_logic/popups-list';
import { IProposalToReAssign } from 'constants/assignment';
import { baseQueryParams } from './helpers';

export const presentationsApi = createApi({
  reducerPath: ReducerPath.presentation,
  baseQuery: fetchBaseQuery({ ...baseQueryParams }),
  endpoints: (builder) => ({
    preCreateAPresentation: builder.mutation<IProposalToReAssign[], { email: string }>({
      query: (payload) => ({
        url: '/presentation/pre-create',
        method: 'GET',
        params: payload,
      }),
    }),

    createAPresentation: builder.query<void, { email: string }>({
      query: (payload) => ({
        url: '/presentation/create',
        method: 'GET',
        params: payload,
      }),
    }),

    getPresentations: builder.mutation<
      IGetPresentationsResponse,
      IGetPresentationsRequest
    >({
      query: ({ dropdown, ...rest }) => {
        const filter: Record<string, string[]> = {};

        (dropdown as MultiValue<OptionType>).forEach(({ value }) => {
          if (
            value === PresentationStatusEnum.SENT ||
            value === PresentationStatusEnum.NOT_SENT
          ) {
            filter.status = [...(filter.status || []), value] || [];
          }

          if (value === ASSIGNED_TO_ME_ENTITY) {
            filter.assignedToMeEntity = [ASSIGNED_TO_ME_ENTITY];
          }
        });

        return {
          url: '/presentation',
          method: 'GET',
          params: {
            ...rest,
            filter: JSON.stringify(filter),
          },
        };
      },

      async onQueryStarted(
        { dropdown, search, sorting, sortingOrder },
        { dispatch, queryFulfilled },
      ) {
        try {
          const { data } = await queryFulfilled;

          if (data) {
            const { presentations, total, page, perPage } = data;

            dispatch(setPresentations({ presentations, total }));
            dispatch(setMyOrdersCount({ presentations: total }));
            dispatch(
              setFilter({
                page,
                perPage,
                dropdown,
                search: search || '',
                sorting,
                sortingOrder,
                totalCount: total,
              }),
            );
          }
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),

    deletePresentation: builder.mutation<void, { presentationId: string }>({
      query: ({ presentationId }) => ({
        url: `/presentation/${presentationId}`,
        method: 'DELETE',
      }),
      async onQueryStarted(_, { dispatch, getState, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(closePopup());
          dispatch(
            pushNotification(
              makeSuccessToastNotification('Presentation has been successfully deleted'),
            ),
          );

          const state = getState();
          const filters = filtersRequestSelector(state as unknown as RootState);

          dispatch(presentationsApi.endpoints.getPresentations.initiate(filters));
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),

    editPresentation: builder.query<void, { presentationId: string }>({
      query: ({ presentationId }) => ({
        url: `/presentation/edit/${presentationId}`,
        method: 'GET',
      }),
    }),
    markAsSent: builder.mutation<{ sentAt: Date | null }, { presentationId: string }>({
      query: ({ presentationId }) => ({
        url: `/presentation/mark-as-sent/${presentationId}`,
        method: 'PATCH',
      }),
      async onQueryStarted(_, { dispatch, getState, queryFulfilled }) {
        try {
          await queryFulfilled;
          const state = getState();
          const filters = filtersRequestSelector(state as unknown as RootState);

          dispatch(presentationsApi.endpoints.getPresentations.initiate(filters));
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),
    downloadPDF: builder.mutation<{ url: string }, { presentationId: string }>({
      query: ({ presentationId }) => ({
        url: `/presentation/download/${presentationId}`,
        method: 'GET',
        responseHandler: async (response) => {
          await downloadFileInBrowser(response);

          return { data: null };
        },
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (e) {
          dispatch(pushNotification(makeToastNotification('Something went wrong')));
        }
      },
    }),
    createCartLink: builder.mutation<
      { link: string; prebuiltCartId: string },
      { email: string; presentationId: string; type: CartTypeEnum; reassign?: boolean }
    >({
      query: ({ presentationId, type, email, reassign = false }) => ({
        url: '/presentation/create-cart-link',
        method: 'POST',
        body: {
          type,
          id: presentationId,
          reassign,
        },
      }),
      async onQueryStarted(body, { dispatch, getState, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(
            updatePresentation({
              id: body.presentationId,
              prebuiltCartId: data.prebuiltCartId,
            }),
          );
          dispatch(
            openPopup({
              popupName: COPY_CART_LINK_POPUP,
              popupProps: { email: body.email, cartLink: data?.link },
            }),
          );
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),
  }),
});

export const {
  useLazyCreateAPresentationQuery,
  useGetPresentationsMutation,
  useDeletePresentationMutation,
  useLazyEditPresentationQuery,
  useMarkAsSentMutation,
  useDownloadPDFMutation,
  useCreateCartLinkMutation,
  usePreCreateAPresentationMutation,
} = presentationsApi;
