import { FC, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { Typography, Tooltip, TooltipPlacement } from '@ezetech/swag-space-x';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { useAppDispatch } from 'redux/store';
import { setTeamMemberDetails } from 'redux/slices/invitations.slice';
import { ActivityBlock } from './activity-block';
import classes from './user-card.module.scss';
import { IUserCardProps } from './index';

export const UserCard: FC<IUserCardProps> = ({
  user,
  role,
  suppressLink,
  isLast = false,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isDisabledTooltip, setIsDisabledTooltip] = useState(false);
  const tooltipWrapperRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLDivElement>(null);

  const handleUserClick = () => {
    if (suppressLink) {
      return;
    }
    dispatch(setTeamMemberDetails(user));
    navigate(`/team-member-details/${user.id}`);
  };

  useEffect(() => {
    if (!tooltipWrapperRef.current || !textRef.current) {
      return;
    }
    const disabledTooltip =
      tooltipWrapperRef.current.offsetWidth >= textRef.current.offsetWidth;
    setIsDisabledTooltip(disabledTooltip);
  }, [tooltipWrapperRef.current?.offsetWidth, textRef.current?.offsetWidth]);

  return (
    <div
      className={classNames(classes.container, {
        [classes.containerNotLast]: !isLast,
      })}
      data-userId={user.userId || 'N/A'}
      data-invitationId={user.id || 'N/A'}
    >
      <div className={classes.row}>
        <Typography
          className={classes.name}
          fontType="bodyMdBold"
          color={suppressLink ? COLORS.colorText900 : COLORS.colorPink}
          link={!suppressLink}
          lineHeight="162.5%"
          onClick={handleUserClick}
        >
          {user.firstName} {user.lastName}
        </Typography>
        {role && (
          <Typography fontType="bodyMd" color={COLORS.colorText500} lineHeight="162.5%">
            {role}
          </Typography>
        )}
      </div>
      <div className={classes.row}>
        <ActivityBlock status={user.status} lastActivityAt={user.lastActivityAt} />
        <div className={classes.tooltipWrapper} ref={tooltipWrapperRef}>
          <Tooltip
            disabled={isDisabledTooltip}
            triggerClassName={classes.tooltipTrigger}
            bodyClassName={classes.tooltipBody}
            placement={TooltipPlacement.top}
            content={
              <Typography
                fontType="bodyMd"
                color={COLORS.colorText900}
                lineHeight="162.5%"
              >
                {user.email}
              </Typography>
            }
          >
            <Typography fontType="bodyMd" color={COLORS.colorText900} lineHeight="162.5%">
              <div ref={textRef}>{user.email}</div>
            </Typography>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};
