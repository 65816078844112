import { useEffect } from 'react';
import {
  InfiniteLoader,
  List as RVList,
  ListRowProps,
  AutoSizer,
  Index,
  CellMeasurer,
  CellMeasurerCache,
} from 'react-virtualized';
import cn from 'classnames';

import {
  Loader,
  Typography,
  WhiteBox,
  List,
  ListHeader,
  ListItem,
  Divider,
} from '@ezetech/swag-space-x';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { REFERRAL_ACTIVITY_TYPE } from 'constants/referral.constant';
import { useGetActionsListMutation } from 'redux/api/referrals.api';
import { useAppSelector } from 'redux/store';
import { isMobile } from 'utils/ui.util';
import {
  hasNextPageSelector,
  isLoadingReferralsSelector,
  totalReferralsSelector,
  pageReferralsSelector,
  perPageReferralsSelector,
  getReferralsActionsListSelector,
} from 'redux/selectors/referrals.selectors';

import css from './refer-a-member.module.scss';

const getColor = (action: string): string => {
  switch (action) {
    case REFERRAL_ACTIVITY_TYPE.APPLIED:
      return COLORS.colorText500;
    case REFERRAL_ACTIVITY_TYPE.APPROVED:
      return COLORS.colorStatePending;
    case REFERRAL_ACTIVITY_TYPE.ONBOARDED:
      return COLORS.colorStatePending;
    case REFERRAL_ACTIVITY_TYPE.ITEM_PRODUCTION_STARTED:
      return COLORS.colorSuccess700;
    default:
      return COLORS.colorText500;
  }
};

const cache = new CellMeasurerCache({
  fixedWidth: true,
  defaultHeight: 106,
});

export function ReferralsBlock({ className }: { className: string }): JSX.Element {
  const [getActionsList] = useGetActionsListMutation();
  const isLoading = useAppSelector(isLoadingReferralsSelector);
  const hasNextPage = useAppSelector(hasNextPageSelector);
  const total = useAppSelector(totalReferralsSelector);
  const page = useAppSelector(pageReferralsSelector);
  const perPage = useAppSelector(perPageReferralsSelector);
  const actionsList = useAppSelector(getReferralsActionsListSelector);
  const isSmallMobileDevice = isMobile(767);

  useEffect(() => {
    getActionsList({ perPage, page });
  }, []);

  const handleNewPageLoad = async () => {
    if (isLoading) {
      return;
    }

    await getActionsList({ perPage, page: page + 1 });
  };

  const isRowLoaded = ({ index }: Index) => {
    return !hasNextPage || !!actionsList[index];
  };

  const getRowHeight = ({ index }: { index: number }): number => {
    const row = actionsList[index];
    if (!row) {
      return 0;
    }

    if (
      [
        REFERRAL_ACTIVITY_TYPE.APPLIED,
        REFERRAL_ACTIVITY_TYPE.APPROVED,
        REFERRAL_ACTIVITY_TYPE.ONBOARDED,
      ].includes(row.action)
    ) {
      return isSmallMobileDevice ? 138 : 106;
    }
    return isSmallMobileDevice ? 175 : 127;
  };

  const rowRenderer = ({ index, key, style, parent }: ListRowProps) => {
    if (!actionsList[index]) {
      return null;
    }

    const { id, actionText, action, data, amountText } = actionsList[index];

    return (
      <CellMeasurer
        key={key}
        cache={cache}
        parent={parent}
        columnIndex={0}
        rowIndex={index}
      >
        <div style={style} data-referral-id={`referral-activity-id-${id}`}>
          <ListItem className={css.row}>
            <Typography
              lineHeight="100%"
              fontType="bodyMdBold"
              color={getColor(action)}
              data-action-text={`data-action-column-text-${id}`}
            >
              {actionText}
            </Typography>

            <Typography
              lineHeight="100%"
              fontType="bodyMd"
              color={COLORS.colorPrimaryText}
              className={css.dataLines}
              data-description={`data-description-${id}`}
            >
              {data.map((item) => (
                <div className={css.dataLineWrapper}>
                  <span key={id + item} className={css.dataLine}>
                    {item}
                  </span>
                </div>
              ))}
            </Typography>

            <Typography
              lineHeight="100%"
              fontType="bodyMd"
              color={COLORS.colorPrimaryText}
              data-amount={`data-amount-${id}`}
            >
              {amountText}
            </Typography>
          </ListItem>
          <Divider />
        </div>
      </CellMeasurer>
    );
  };

  return (
    <WhiteBox className={cn(css.root, className)}>
      <List>
        <ListHeader className={css.header}>
          <Typography
            lineHeight="162%"
            fontType="bodyMdBold"
            color={COLORS.colorTextSubdued}
          >
            Referrals
          </Typography>
        </ListHeader>
        <Divider />
        <ListItem className={cn(css.row, css.firstRow)}>
          <Typography
            lineHeight="100%"
            fontType="bodyMdBold"
            color={COLORS.colorPrimaryText}
          >
            Referral Action
          </Typography>

          <Typography
            lineHeight="100%"
            fontType="bodyMdBold"
            color={COLORS.colorPrimaryText}
          >
            Swag Space member
          </Typography>

          <Typography
            lineHeight="100%"
            fontType="bodyMdBold"
            color={COLORS.colorPrimaryText}
          >
            Amount
          </Typography>
        </ListItem>
        <Divider className={css.firstRow} />

        <AutoSizer disableHeight>
          {({ width }) => (
            <InfiniteLoader
              isRowLoaded={isRowLoaded}
              loadMoreRows={handleNewPageLoad}
              rowCount={total}
            >
              {({ onRowsRendered, registerChild }) => (
                <RVList
                  height={382}
                  width={width}
                  rowHeight={getRowHeight}
                  rowRenderer={rowRenderer}
                  ref={registerChild}
                  className={css.list}
                  rowCount={actionsList.length}
                  onRowsRendered={onRowsRendered}
                />
              )}
            </InfiniteLoader>
          )}
        </AutoSizer>
        {isLoading && (
          <div className={css.overflow}>
            <Loader />
          </div>
        )}
      </List>
    </WhiteBox>
  );
}
