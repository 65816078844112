import { useState, useEffect, useCallback, createRef } from 'react';
import {
  InfinityScroll,
  Loader,
  InfinityRowProps,
  InfinityListType,
} from '@ezetech/swag-space-x';
import { useGetProductsMutation } from 'redux/api/products.api';
import { useAppSelector, useAppDispatch } from 'redux/store';
import {
  filtersProductsRequestSelector,
  hasNextPageSelector,
  isLoadingProductsSelector,
  productsListSelector,
} from 'redux/selectors/products.selectors';
import { checkProduct } from 'redux/slices/products.slice';
import { cannot, SUBJECTS } from 'boot/ability';

import { ProductsListItem, IProductListItem } from './products-list-item';
import css from './hide-products-popup.module.scss';

type itemRenderProps = InfinityRowProps<{
  items: IProductListItem[];
  handleSetChecked: (id: string, value: boolean) => void;
  isDisabled: boolean;
}>;

export const ProductsList = ({ scrollTop }: { scrollTop?: number }) => {
  const dispatch = useAppDispatch();
  const [getProducts] = useGetProductsMutation();
  const filters = useAppSelector(filtersProductsRequestSelector);
  const hasNextPage = useAppSelector(hasNextPageSelector);
  const isLoading = useAppSelector(isLoadingProductsSelector);
  const products = useAppSelector(productsListSelector);

  const listRef = createRef<InfinityListType>();
  const [listHeight, setHeight] = useState(564);
  const isDisabled = cannot(
    SUBJECTS.COMMISSION.actions.HIDE_PRODUCTS_FROM_THE_SITE_EDIT,
    SUBJECTS.COMMISSION.value,
  );

  const handleResize = () => {
    if (window.innerWidth <= 524) {
      return setHeight(140);
    }

    if (window.innerWidth <= 767) {
      return setHeight(300);
    }

    if (window.innerWidth <= 1439) {
      return setHeight(350);
    }

    if (window.innerWidth <= 1919) {
      return setHeight(380);
    }

    setHeight(498);
  };

  useEffect(() => {
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleSetChecked = (id: string): void => {
    dispatch(checkProduct(id));
  };

  const handleNewPageLoad = async () => {
    if (isLoading) {
      return;
    }

    const { search, perPage, page, filter, selectedIds, excludedIds } = filters;

    await getProducts({
      search,
      perPage,
      page: page + 1,
      filter,
      selectedIds,
      excludedIds,
    });
  };

  useEffect(() => {
    if (listRef && typeof scrollTop === 'number') {
      listRef.current?.scrollToItem(scrollTop, 'start');
    }
  }, [scrollTop, listRef]);

  const rowRenderer = useCallback((props: itemRenderProps) => {
    const item = props.data.items[props.index];
    if (!item) {
      return <>Loading...</>;
    }
    return (
      <div style={props.style} className={css.rowWrapper}>
        <ProductsListItem
          id={item.id}
          name={item.name}
          image={item.image}
          margin={item.margin}
          checked={item.checked}
          disabled={props.data.isDisabled}
          onCheck={props.data.handleSetChecked}
        />
      </div>
    );
  }, []);

  if (products.length === 0 && filters.page === 1 && isLoading) {
    return <div className={css.list} />;
  }
  const itemProps = {
    handleSetChecked,
    isDisabled,
  };
  const footerMargin = 24;
  return (
    <div className={css.list}>
      <InfinityScroll
        containerHeight={listHeight - footerMargin}
        itemHeight={54}
        loadPage={handleNewPageLoad}
        items={products}
        itemProps={itemProps}
        isLoading={isLoading}
        hasNextPage={hasNextPage}
        row={rowRenderer}
        listRef={listRef}
      />
      {isLoading && (
        <div className={css.overflow}>
          <Loader />
        </div>
      )}
    </div>
  );
};
