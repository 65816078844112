import { Controller } from 'react-hook-form';
import { Input as InputRegular, InputPassword } from '@ezetech/swag-space-x';

import { InputFormProps } from './input-form.interface';

export function InputForm({
  name,
  rules,
  type,
  control,
  required,
  shouldTrimInputValue,
  defaultValue,
  ...rest
}: InputFormProps): JSX.Element {
  const Input = type === 'password' ? InputPassword : InputRegular;
  return (
    <Controller
      name={name as string}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({
        field: { value, onChange, onBlur, name: formName },
        fieldState,
        formState,
      }) => {
        return (
          <Input
            {...rest}
            errors={(!formState.isValid && fieldState.error?.message) || ''}
            value={value || ''}
            defaultValue={defaultValue}
            required={!!rules?.required || required}
            type={type}
            onBlur={onBlur}
            name={formName}
            onChange={(...args) => {
              if (shouldTrimInputValue) {
                args[0].target.value = args[0].target.value.trim();
              }
              onChange(...args);
            }}
          />
        );
      }}
    />
  );
}
