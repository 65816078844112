import { IItem } from 'interfaces/orders.interface';
import { CLIENT_STATUSES } from 'enums/orders.enum';
import { ITEM_STATUS_CODE_LABEL, ITEM_STATUS_CODE } from 'constants/item.constants';
import { IPrebuiltItem } from 'interfaces/cart.interfaces';
import { IProductionMockup } from 'interfaces/orders.interface';
import { PRODUCTION_MOCKUP_STATUSES } from 'constants/item.constants';

type GetItemStatusLabelProps = Pick<IItem, 'status' | 'statusCode'> & {
  isReviewPantonesByCustomerAllowed?: boolean;
  isReviewMockupsByCustomerAllowed?: boolean;
};

export const getItemStatusLabel = ({
  statusCode,
  status,
  isReviewPantonesByCustomerAllowed = true,
  isReviewMockupsByCustomerAllowed = true,
}: GetItemStatusLabelProps): string => {
  if (
    !isReviewPantonesByCustomerAllowed &&
    statusCode === ITEM_STATUS_CODE.COLORS_REVIEW_BY_CUSTOMER
  ) {
    return ITEM_STATUS_CODE_LABEL[ITEM_STATUS_CODE.CREATING_PRODUCTION_MOCKUP];
  }

  if (
    !isReviewMockupsByCustomerAllowed &&
    statusCode === ITEM_STATUS_CODE.MOCKUP_APPROVAL_BY_CUSTOMER
  ) {
    return ITEM_STATUS_CODE_LABEL[ITEM_STATUS_CODE.IN_PRODUCTION];
  }

  const label = (statusCode && ITEM_STATUS_CODE_LABEL[statusCode]) || status;
  return label;
};

export const isItemWithStatusAction = (item: IItem): boolean => {
  const actionStatuses = [
    ITEM_STATUS_CODE.COLORS_REVIEW_BY_RESELLER,
    ITEM_STATUS_CODE.MOCKUP_APPROVAL_BY_RESELLER,
  ];
  return actionStatuses.includes(item.statusCode);
};

export const filterByStatusAction = (items: IItem[]): IItem[] => {
  return items.filter(isItemWithStatusAction);
};

export const isAllItemsDelivered = (items: GetItemStatusLabelProps[]): boolean => {
  const result = items.every(
    (item) =>
      item.statusCode === ITEM_STATUS_CODE.DELIVERED ||
      (!item.statusCode && item.status === CLIENT_STATUSES.DELIVERED),
  );
  return result;
};

export const getNumWithLetterIndicator = (
  num: string | number | undefined,
  letterIndicator: string | undefined,
): string => `#${num}${letterIndicator ? `-${letterIndicator}` : ''}`;

export const getRelatedItemsForAbandonedCheckouts = (
  items: IPrebuiltItem[],
): string[] => {
  if (!items) {
    return [];
  }
  const relatedItems = items.reduce((acc: { [key: string]: IPrebuiltItem[] }, item) => {
    if (item.asRelatedItemId && !item.boxItemsId) {
      return {
        ...acc,
        [item.asRelatedItemId]: (acc[item.asRelatedItemId] || []).concat(item),
      };
    }
    return acc;
  }, {});

  return Object.values(relatedItems)
    .filter((item) => item.length >= 2)
    .flat()
    .map((item) => item.itemPublicId);
};

export const getItemDataForProductionMockup = (item: Partial<IItem>) => {
  const { num } = item;
  const customerMockups = (item?.pantones || []).map(
    ({ id, location, mockupPreview }) => ({
      id,
      location,
      mockupPreview,
    }),
  );

  if (!customerMockups.length) {
    customerMockups.push({
      mockupPreview: item?.imageUrl || '',
      location: 'full-design',
      id: 'full-design',
    });
  }

  const pendingProductionMockup = item?.productionMockups?.reduce<{
    id: string;
    status: string;
    fileName: string;
  }>(
    (res, mockup: IProductionMockup) => {
      if (res.id) {
        return res;
      }
      if (mockup.status === PRODUCTION_MOCKUP_STATUSES.PENDING_RESELLER_APPROVAL) {
        return {
          id: mockup.id,
          status: mockup.status,
          fileName: mockup.fileName,
        };
      }
      return res;
    },
    { id: '', status: '', fileName: '' },
  );

  return {
    productName: item?.name,
    orderNum: num,
    productionMockup: pendingProductionMockup ?? [],
    customerMockups,
  };
};
