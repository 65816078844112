import { Divider, SwagButton, Typography, WhiteBox } from '@ezetech/swag-space-x';
import { FC } from 'react';
import { useForm, SubmitHandler, FieldValues } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { InputForm } from 'components/input-form';
import { validationSchema } from 'validations/profile-settings/profile';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { profileSelector } from 'redux/selectors/user.selectors';
import { useUpdateUserMutation } from 'redux/api/user.api';
import { pushNotification } from 'redux/slices/notifications.slice';
import { makeSuccessToastNotification } from 'utils/query.util';
import css from './profile.module.scss';

export const Profile: FC = () => {
  const dispatch = useAppDispatch();
  const [updateUser] = useUpdateUserMutation();
  const profile = useAppSelector(profileSelector);
  const { handleSubmit, control, formState, reset } = useForm<FieldValues>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      firstName: profile?.firstName || '',
      lastName: profile?.lastName || '',
      email: profile?.email || '',
      phone: profile?.phone || '',
    },
  });
  const isFormValid = formState.isValid;
  const isFormDirty = formState.isDirty;

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    const result = (await updateUser({
      id: profile?.id || '',
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phone: data.phone,
    })) as { error?: unknown };
    if (!result.error) {
      reset({
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phone: data.phone,
      });
      dispatch(
        pushNotification(makeSuccessToastNotification('Profile updated successfully')),
      );
    }
  };

  return (
    <WhiteBox className={css.container}>
      <Typography
        fontType="HEADING5XL"
        lineHeight="100%"
        className={css.header}
        color={COLORS.colorText900}
      >
        Info
      </Typography>
      <Divider className={css.separator} color={COLORS.colorDivider} />
      <form
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
        method="post"
        className={css.form}
      >
        <input autoComplete="false" name="hidden" type="text" className={css.hidden} />
        <div className={css.row}>
          <InputForm
            control={control}
            name="firstName"
            autoComplete="one-time-code"
            autocomplete="one-time-code"
            label="First Name"
            placeholder="Enter First Name"
          />
          <InputForm
            control={control}
            name="lastName"
            autoComplete="one-time-code"
            autocomplete="one-time-code"
            label="Last Name"
            placeholder="Enter Last Name"
          />
        </div>
        <div className={css.row}>
          <InputForm
            control={control}
            name="email"
            autoComplete="one-time-code"
            autocomplete="one-time-code"
            label="Email"
            placeholder="Enter Email"
          />
          <InputForm
            control={control}
            name="phone"
            autoComplete="one-time-code"
            autocomplete="one-time-code"
            label="Cell Phone"
            placeholder="Enter Cell Phone"
          />
        </div>
      </form>
      <SwagButton
        type="primary"
        className={css.button}
        disabled={!isFormDirty || !isFormValid}
        htmlType="submit"
        onClick={handleSubmit(onSubmit)}
      >
        Apply Changes
      </SwagButton>
    </WhiteBox>
  );
};
