import { LoaderFunctionArgs } from 'react-router-dom';

import { pushNotification } from 'redux/slices/notifications.slice';
import { makeToastNotification } from 'utils/query.util';
import { assigneeApi } from 'redux/api/assignee.api';

import { IAction } from 'interfaces/loaders.interface';
import { fetchUserData } from 'utils/user.util';
import { fetchStoreSettingsData } from 'utils/store-settings.util';

import { store } from '../../redux/store';

export async function myOrdersLoader(params: LoaderFunctionArgs): Promise<null> {
  try {
    await fetchUserData();
    await fetchStoreSettingsData();

    const requests: Record<string, unknown>[] = [
      { endpoint: assigneeApi.endpoints.getAssigneeListForOrders.initiate() },
    ];

    const results = await Promise.all(
      requests.map(async (request) => {
        if (request?.condition === false) {
          return;
        }

        return store.dispatch(request.endpoint as IAction).unwrap();
      }),
    );

    if (results.some((result) => result && 'error' in result)) {
      return null;
    }
  } catch (e) {
    store.dispatch(pushNotification(makeToastNotification(e)));
  }

  return null;
}
