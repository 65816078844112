import * as yup from 'yup';
import cn from 'classnames';
import { createRef, FC, FocusEvent, useEffect, useMemo } from 'react';
import {
  Input,
  Typography,
  Tooltip,
  TooltipPlacement,
  SwagButton,
} from '@ezetech/swag-space-x';
import { useSelector } from 'react-redux';
import { useClipboard } from 'hooks/use-clipboard.hook';

import { isRequired } from 'utils/yup.util';
import { IStorePreferences } from 'interfaces';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import {
  MAX_LINK_CHARACTERS,
  MAX_LINK_CHARACTERS_CUSTOM_DOMAIN,
} from 'constants/store-creation.constants';
import { InfoCircleIcon } from 'components/svg/info-circle';
import { CopyIcon } from 'components/svg/copy';
import { useNotifications } from 'hooks/notifications.hook';
import { NotificationType } from 'redux/slices/notifications.slice';
import {
  patchStorePreferences,
  setStorePreferencesError,
} from 'redux/slices/settings.slice';

import { openPopup } from 'redux/slices/modals.slice';
import {
  CONNECT_DOMAIN_POPUP,
  DNS_SETTINGS_POPUP,
  EDIT_DOMAIN_POPUP,
} from 'components/popups/_logic/popups-list';
import { CUSTOM_DOMAIN_STATUSES } from 'interfaces/store-creation.interfaces';
import { cannot, SUBJECTS } from 'boot/ability';

import { FormProps } from '../forms.interface';
import css from '../forms.module.scss';

import { useAppDispatch } from '../../../../../redux/store';

import {
  domainSettingsSelector,
  isEditCustomDomainMode,
  manageDomainBtnTextSelector,
  storePreferencesErrorSelector,
  storePreferencesSelector,
} from '../../../../../redux/selectors/settings.selectors';

import { storePreferencesFormSchema } from './store-preferences.schema';
import { FIELDS } from './store-preferences.constants';

const AdornmentEnd = (
  <Tooltip
    placement={TooltipPlacement.top}
    triggerClassName={css.adornmentInfo}
    content={
      <InfoCircleIcon
        fillColor={COLORS.colorTextIcon}
        className={css.adornmentInfoIcon}
      />
    }
  >
    When your customers click on your logo on your store link it will direct them back to
    this Logo URL. Most people have this set as their Homepage URL (ex. JennyPromo.com)
  </Tooltip>
);

const ROOT_SPACE_DOMAIN = '.swag.space';

export const StorePreferencesForm: FC<FormProps> = () => {
  const dispatch = useAppDispatch();

  const requiredFields = useMemo<Record<string, yup.SchemaFieldDescription>>(() => {
    return storePreferencesFormSchema.describe().fields;
  }, []);
  const { pushNotification } = useNotifications();
  const storePreferencesValues = useSelector(storePreferencesSelector);
  const storePreferencesError = useSelector(storePreferencesErrorSelector);
  const customDomainInfo = useSelector(domainSettingsSelector);

  const errorRef = createRef<HTMLSpanElement>();
  const labelRef = createRef<HTMLLabelElement>();
  const manageBtnText = useSelector(manageDomainBtnTextSelector);
  const { copy } = useClipboard();
  const isEditDomainMode = useSelector(isEditCustomDomainMode);
  const isStoreLinkDisabled = cannot(
    SUBJECTS.STORE_PREFERENCES.actions.STORE_LINK_EDIT,
    SUBJECTS.STORE_PREFERENCES.value,
  );
  const isHomepageDisabled = cannot(
    SUBJECTS.STORE_PREFERENCES.actions.HOMEPAGE_TITLE_EDIT,
    SUBJECTS.STORE_PREFERENCES.value,
  );
  const isLogoDisabled = cannot(
    SUBJECTS.STORE_PREFERENCES.actions.LOGO_URL_EDIT,
    SUBJECTS.STORE_PREFERENCES.value,
  );
  const isDomainManageButtonHidden = cannot(
    SUBJECTS.STORE_PREFERENCES.actions.CUSTOM_DOMAIN_FLOW,
    SUBJECTS.STORE_PREFERENCES.value,
  );
  useEffect(() => {
    const elem = labelRef.current;
    if (elem) {
      elem.dataset.value = (
        customDomainInfo?.status === CUSTOM_DOMAIN_STATUSES.VERIFIED ||
        customDomainInfo?.status === CUSTOM_DOMAIN_STATUSES.PROLONGING
          ? customDomainInfo?.domain || ''
          : storePreferencesValues.brandName || 'Enter brand'
      ) as string;
    }
  }, [storePreferencesValues.brandName, customDomainInfo?.domain]);

  const onChange = (path: string) => async (value: string) => {
    dispatch(
      patchStorePreferences({
        [path]: value,
      }),
    );
    dispatch(setStorePreferencesError({ [path]: undefined }));
  };

  const onBlur = (path: string) => async (e: FocusEvent<HTMLInputElement>) => {
    try {
      await storePreferencesFormSchema.validateAt(path, { [path]: e.target.value });
      dispatch(setStorePreferencesError({ [path]: undefined }));
    } catch (validationError: unknown) {
      const { message } = validationError as { message: string };
      dispatch(setStorePreferencesError({ [path]: message }));
    }
  };

  const handleManageDomain = () => {
    if (customDomainInfo?.domain) {
      if (isEditDomainMode) {
        dispatch(openPopup({ popupName: EDIT_DOMAIN_POPUP, popupProps: {} }));
      } else {
        dispatch(openPopup({ popupName: DNS_SETTINGS_POPUP, popupProps: {} }));
      }
    } else {
      dispatch(openPopup({ popupName: CONNECT_DOMAIN_POPUP, popupProps: {} }));
    }
  };

  const handleCopyClick = async () => {
    const url =
      customDomainInfo?.status === CUSTOM_DOMAIN_STATUSES.VERIFIED
        ? `https://${customDomainInfo.domain}`
        : `https://${storePreferencesValues.brandName?.trim()}${ROOT_SPACE_DOMAIN}`;
    const isSuccess = await copy(url);

    if (isSuccess) {
      pushNotification({
        status: 'positive',
        message: 'Store Link has been copied.',
        type: NotificationType.toasts,
      });
    }
  };

  return (
    <form className={`${css.form} ${css.formLink}`}>
      <div className={css.storeAndHomepageTitle}>
        <div>
          <Input
            disabled={isHomepageDisabled}
            className={css.input}
            inputClassName={css.inputEllipsis}
            type="text"
            onChange={(e) => onChange(FIELDS.homepageTitle.name)(e.target.value)}
            onBlur={onBlur(FIELDS.homepageTitle.name)}
            value={storePreferencesValues.homepageTitle || ''}
            label={FIELDS.homepageTitle.label}
            name={FIELDS.homepageTitle.name}
            placeholder={FIELDS.homepageTitle.placeholder}
            errors={
              storePreferencesError[FIELDS.homepageTitle.name as keyof IStorePreferences]
            }
            required={isRequired(requiredFields[FIELDS.homepageTitle.name])}
          />
        </div>
        <Input
          disabled={isLogoDisabled}
          adornmentEnd={AdornmentEnd}
          className={css.input}
          inputClassName={css.inputEllipsis}
          type="text"
          onChange={(e) => onChange(FIELDS.logoLinkUrl.name)(e.target.value)}
          onBlur={onBlur(FIELDS.logoLinkUrl.name)}
          value={storePreferencesValues.logoLinkUrl || ''}
          label={FIELDS.logoLinkUrl.label}
          name={FIELDS.logoLinkUrl.name}
          placeholder={FIELDS.logoLinkUrl.placeholder}
          errors={
            storePreferencesError[FIELDS.logoLinkUrl.name as keyof IStorePreferences]
          }
          required={isRequired(requiredFields[FIELDS.logoLinkUrl.name])}
        />
      </div>
      <div className={css.storeNameLabel}>Store Link*</div>
      <Typography
        className={css.storeName}
        lineHeight="100%"
        fontType="bodyMd"
        color={COLORS.colorTypographyBodyGreyed}
      >
        {isEditDomainMode ? (
          <>
            <label className={css.inputSizer} ref={labelRef}>
              <input
                type="text"
                maxLength={MAX_LINK_CHARACTERS_CUSTOM_DOMAIN + 1}
                disabled={true}
                value={customDomainInfo?.domain || ''}
                className={cn(css.storePrefixName, css.inputEllipsis)}
              />
            </label>
            <div onClick={handleCopyClick} className={css.copy}>
              <CopyIcon fillColor={COLORS.colorAccent500} />
            </div>
          </>
        ) : (
          <>
            <label className={css.inputSizer} ref={labelRef}>
              <input
                disabled={isStoreLinkDisabled}
                type="text"
                maxLength={MAX_LINK_CHARACTERS + 1}
                onInput={(e) =>
                  onChange(FIELDS.brandName.name)((e.target as HTMLInputElement).value)
                }
                onBlur={onBlur(FIELDS.brandName.name)}
                value={storePreferencesValues.brandName || ''}
                className={cn(css.storePrefixName, css.inputEllipsis)}
                size={1}
                placeholder="Enter brand"
              />
            </label>
            {ROOT_SPACE_DOMAIN}
            <div onClick={handleCopyClick} className={css.copy}>
              <CopyIcon fillColor={COLORS.colorAccent500} />
            </div>
          </>
        )}
      </Typography>
      <Typography
        className={css.errorText}
        lineHeight="120%"
        fontType="bodySm"
        color={COLORS.colorErrorText}
      >
        <span ref={errorRef}>
          {storePreferencesError[FIELDS.brandName.name as keyof IStorePreferences]}
        </span>
      </Typography>
      {!isDomainManageButtonHidden && (
        <SwagButton
          type="primary"
          className={css.connectDomainBtn}
          onClick={handleManageDomain}
        >
          {manageBtnText}
        </SwagButton>
      )}
    </form>
  );
};
