import { useState } from 'react';
import { Dialog, DialogContentWrapper } from '@ezetech/swag-space-x';
import { ValidationError } from 'yup';
import { closePopup, openPopup } from 'redux/slices/modals.slice';
import { EditPdfPresentationLayoutPopupProps } from 'interfaces/popup.interface';
import { useAppDispatch, useAppSelector } from 'redux/store';

import {
  PdfPresentationEditorFilter,
  PdfPresentationEditorPageId,
  PdfPresentationEditorUpdateBody,
} from 'interfaces/pdf-presentation';

import {
  PageSidebarWidth,
  PDF_PRESENTATION_PAGES_NORMALIZED,
} from 'constants/pdf-presentation.constants';
import { can, SUBJECTS } from 'boot/ability';

import {
  editableDraftSelector,
  filterSelector,
  isEditedSelector,
  isPdfRenderingSelector,
  pagesToRenderSelector,
  paginationSelector,
  pdfImagesOfPagesSelector,
  selectedPageTemplateSelector,
} from '../../../redux/selectors/pdf-presentation-editor.selectors';
import {
  changeFilter,
  changePage,
  changeValue,
  reset,
  setLastChanges,
} from '../../../redux/slices/pdf-presentation-editor.slice';
import { useUpdatePdfPresentationEditorSettingsMutation } from '../../../redux/api/settings.api';
import { EditorValidationSchema } from '../../../validations/pdf-presentations/editor';
import {
  CONFIRMATION_POPUP,
  EDIT_PDF_PRESENTATION_LAYOUT_POPUP,
  SAVE_PDF_PRESENTATION_CHANGES_CONFIRMATION_POPUP,
} from '../_logic/popups-list';
import { usePreviewWidth } from './hooks/use-preview-width';

import classes from './edit-pdf-presentation-layout-popup.module.scss';
import { Header } from './components/header';
import { PagesSidebar } from './components/pages.sidebar';
import { ContentSidebar } from './components/content.sidebar';
import { Preview } from './components/preview';
import { PagesSidebarMobile } from './components/pages.sidebar.mobile';

export const EditPDFPresentationLayoutPopup = ({
  isOpen,
}: EditPdfPresentationLayoutPopupProps): JSX.Element => {
  const [previewActive, setPreviewActive] = useState(false);
  const dispatch = useAppDispatch();
  const [updatePdfPresentationSettings] =
    useUpdatePdfPresentationEditorSettingsMutation();
  const filter = useAppSelector(filterSelector);
  const selectedPageTemplate = useAppSelector(selectedPageTemplateSelector);
  const pagination = useAppSelector(paginationSelector);
  const editableDraft = useAppSelector(editableDraftSelector);
  const pagesToRender = useAppSelector(pagesToRenderSelector);
  const isEdited = useAppSelector(isEditedSelector);
  const pdfImagesOfPages = useAppSelector(pdfImagesOfPagesSelector);
  const isPdfRendering = useAppSelector(isPdfRenderingSelector);
  const previewSidebarWidth = usePreviewWidth(true);
  const [isChangePageBlocked, setIsChangePageBlocked] = useState(false);
  const [isPopupMounted, setPopupMounted] = useState(isOpen);
  const [isExitBlocked, setIsExitBlocked] = useState(false);
  const isEditable = can(
    SUBJECTS.PAGES_POPUPS_PDF_PRESENTATIONS.actions.PDF_PRESENTATION_EDIT,
    SUBJECTS.PAGES_POPUPS_PDF_PRESENTATIONS.value,
  );

  const handleChangeFilter = (value: PdfPresentationEditorFilter) => {
    dispatch(changeFilter(value));
  };

  const handleOnOpenChange = () => {
    if (isExitBlocked) {
      return;
    }
    setPopupMounted(false);
    setTimeout(() => {
      dispatch(reset());
      dispatch(closePopup());
    }, 500);
  };

  const handleCloseClick = () => {
    if (isExitBlocked) {
      return;
    }
    if (isEdited) {
      dispatch(
        openPopup({
          popupName: CONFIRMATION_POPUP,
          popupProps: {
            title: 'Are you sure you want to exit?',
            descriptionComponent:
              'By exiting you will lose all the edits recently made. Are you sure you want to exit?',
            onCancel: () => {
              dispatch(
                openPopup({
                  popupName: EDIT_PDF_PRESENTATION_LAYOUT_POPUP,
                  popupProps: {},
                }),
              );
            },
            onConfirm: () => {
              setPopupMounted(false);
              setTimeout(() => {
                dispatch(reset());
                dispatch(closePopup());
              }, 500);
            },
            cancelText: 'Continue editing',
            confirmText: 'Yes, exit',
          },
        }),
      );
    } else {
      setPopupMounted(false);
      setTimeout(() => {
        dispatch(reset());
        dispatch(closePopup());
      }, 500);
    }
  };

  const handleSaveClick = async () => {
    if (isExitBlocked) {
      return;
    }
    try {
      await EditorValidationSchema.validate(editableDraft, {
        abortEarly: false,
        stripUnknown: true,
      });
      await updatePdfPresentationSettings(editableDraft);
      dispatch(reset());
      dispatch(closePopup());
    } catch (e) {
      const error = e as ValidationError;
      const messages = error.inner
        .filter(({ message }) => {
          const [page] = message.split(':::');
          const pageTemplate = PDF_PRESENTATION_PAGES_NORMALIZED[page];
          if (pageTemplate && !pageTemplate.isHiddenKeyName) {
            return true;
          }
          return (
            pageTemplate &&
            pageTemplate.isHiddenKeyName &&
            !editableDraft[pageTemplate.isHiddenKeyName]
          );
        })
        .map(({ message }) => {
          const [page, , msg] = message.split(':::');
          return {
            text: page
              .split('-')
              .map((str) => str[0].toUpperCase() + str.slice(1))
              .join(' '),
            hint: msg,
          };
        });
      if (messages.length > 0) {
        dispatch(
          openPopup({
            popupName: SAVE_PDF_PRESENTATION_CHANGES_CONFIRMATION_POPUP,
            popupProps: {
              messages,
            },
          }),
        );
      } else {
        await updatePdfPresentationSettings(editableDraft);
        dispatch(reset());
        dispatch(closePopup());
      }
    }
  };

  const handlePageChange = (pageId: PdfPresentationEditorPageId) => {
    dispatch(changePage(pageId));
  };

  const createValueChangedHandler =
    (keyName: keyof PdfPresentationEditorUpdateBody) => (value: unknown) => {
      dispatch(changeValue({ keyName, value }));
      dispatch(setLastChanges({ keyName, value }));
    };

  const togglePreview = () => {
    setPreviewActive(!previewActive);
  };

  const previewFilter = {
    value: filter,
    onChangeValue: handleChangeFilter,
  };

  const previewPagination = {
    ...pagination,
    onChangePage: handlePageChange,
  };

  const onProcess = (isInProcess: boolean) => {
    setIsChangePageBlocked(isInProcess);
  };

  const isPagesSidebarShown = [
    PageSidebarWidth.from1920toInfinity,
    PageSidebarWidth.from1820to1919,
    PageSidebarWidth.from1720to1819,
    PageSidebarWidth.from1620to1719,
    PageSidebarWidth.from1520to1619,
    PageSidebarWidth.from1420to1519,
    PageSidebarWidth.from1320to1419,
    PageSidebarWidth.from1220to1319,
  ].includes(previewSidebarWidth as PageSidebarWidth);

  return (
    <Dialog
      open={isPopupMounted}
      onOpenChange={handleOnOpenChange}
      className={classes.dialog}
    >
      <DialogContentWrapper
        className={classes.dialogContentWrapperClassName}
        overlayClassName={classes.dialogOverlayClassName}
        type="vertical"
      >
        <Header
          onCloseClicked={handleCloseClick}
          onSaveClicked={handleSaveClick}
          previewActive={previewActive}
          isEditMode={isEditable}
          togglePreviewActive={togglePreview}
          isExitBlocked={isExitBlocked}
        />
        <div className={classes.content}>
          {isPagesSidebarShown && (
            <PagesSidebar
              filter={previewFilter}
              pagesToRender={pagesToRender}
              selectedPageTemplateId={selectedPageTemplate?.id}
              previewWidth={previewSidebarWidth}
              handlePageChange={handlePageChange}
              onCloseClicked={handleCloseClick}
              onSaveClicked={handleSaveClick}
              values={editableDraft}
              isPdfRendering={isPdfRendering || isChangePageBlocked}
              imagesOfPdfPages={pdfImagesOfPages}
              isEditMode={isEditable}
            />
          )}

          <Preview
            pagination={previewPagination}
            visible={previewActive}
            pageTemplate={selectedPageTemplate}
            values={editableDraft}
            isPdfRendering={isPdfRendering || isChangePageBlocked}
            imagesOfPdfPages={pdfImagesOfPages}
          >
            <PagesSidebarMobile
              filter={previewFilter}
              values={editableDraft}
              selectedPageTemplateId={selectedPageTemplate?.id}
              previewWidth={previewSidebarWidth}
              handlePageChange={handlePageChange}
              onCloseClicked={handleCloseClick}
              onSaveClicked={handleSaveClick}
              pagesToRender={pagesToRender}
              isPdfRendering={isPdfRendering || isChangePageBlocked}
              imagesOfPdfPages={pdfImagesOfPages}
              isEditMode={isEditable}
            />
          </Preview>

          {selectedPageTemplate && isEditable ? (
            <ContentSidebar
              pageTemplate={selectedPageTemplate}
              values={editableDraft}
              createValueChangedHandler={createValueChangedHandler}
              onProcess={onProcess}
              visible={!previewActive}
              onCloseClicked={handleCloseClick}
              onSaveClicked={handleSaveClick}
              setIsExitBlocked={setIsExitBlocked}
              isExitBlocked={isExitBlocked}
            />
          ) : (
            <div />
          )}
        </div>
      </DialogContentWrapper>
    </Dialog>
  );
};
