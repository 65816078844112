import { ROUTES } from 'constants/router';

export const SUBJECTS = {
  MANAGE: {
    value: 'MANAGE',
    actions: {
      ALL: 'ALL',
    },
  },
  COMPANY_DETAILS: {
    value: 'COMPANY_DETAILS',
    actions: {
      COMPANY_DETAILS_VIEW: 'COMPANY_DETAILS_VIEW',
      COMPANY_DETAILS_EDIT: 'COMPANY_DETAILS_EDIT',
    },
  },
  ASSIGNMENT_FLOW: {
    value: 'ASSIGNMENT_FLOW',
    actions: {
      EDIT_ASSIGNMENT_FLOW: 'EDIT_ASSIGNMENT_FLOW',
    },
  },
  BILLING_ADDRESS: {
    value: 'BILLING_ADDRESS',
    actions: {
      BILLING_ADDRESS_VIEW: 'BILLING_ADDRESS_VIEW',
      BILLING_ADDRESS_EDIT: 'BILLING_ADDRESS_EDIT',
    },
  },
  RESELLER_CERTIFICATES: {
    value: 'RESELLER_CERTIFICATES',
    actions: {
      RESELLER_CERTIFICATES_VIEW: 'RESELLER_CERTIFICATES_VIEW',
      RESELLER_CERTIFICATES_UPLOAD: 'RESELLER_CERTIFICATES_UPLOAD',
      RESELLER_CERTIFICATES_DELETE: 'RESELLER_CERTIFICATES_DELETE',
    },
  },
  TERMS_OF_SERVICE: {
    value: 'TERMS_OF_SERVICE',
    actions: {
      TERMS_OF_SERVICE_VIEW: 'TERMS_OF_SERVICE_VIEW',
      TERMS_OF_SERVICE_UPLOAD: 'TERMS_OF_SERVICE_UPLOAD',
      TERMS_OF_SERVICE_EDIT: 'TERMS_OF_SERVICE_EDIT',
      TERMS_OF_SERVICE_SAVE: 'TERMS_OF_SERVICE_SAVE',
      TERMS_OF_SERVICE_DOWNLOAD: 'TERMS_OF_SERVICE_DOWNLOAD',
    },
  },
  PRIVACY_POLICY: {
    value: 'PRIVACY_POLICY',
    actions: {
      PRIVACY_POLICY_VIEW: 'PRIVACY_POLICY_VIEW',
      PRIVACY_POLICY_UPLOAD: 'PRIVACY_POLICY_UPLOAD',
      PRIVACY_POLICY_EDIT: 'PRIVACY_POLICY_EDIT',
      PRIVACY_POLICY_SAVE: 'PRIVACY_POLICY_SAVE',
      PRIVACY_POLICY_DOWNLOAD: 'PRIVACY_POLICY_DOWNLOAD',
    },
  },
  BUSINESS_HOURS: {
    value: 'BUSINESS_HOURS',
    actions: {
      BUSINESS_HOURS_VIEW: 'BUSINESS_HOURS_VIEW',
      BUSINESS_HOURS_EDIT: 'BUSINESS_HOURS_EDIT',
    },
  },
  SOCIAL_HANDLE: {
    value: 'SOCIAL_HANDLE',
    actions: {
      SOCIAL_HANDLE_VIEW: 'SOCIAL_HANDLE_VIEW',
      SOCIAL_HANDLE_EDIT: 'SOCIAL_HANDLE_EDIT',
    },
  },
  STORE_PREFERENCES: {
    value: 'STORE_PREFERENCES',
    actions: {
      STORE_LINK_VIEW: 'STORE_LINK_VIEW',
      STORE_LINK_EDIT: 'STORE_LINK_EDIT',
      HOMEPAGE_TITLE_VIEW: 'HOMEPAGE_TITLE_VIEW',
      HOMEPAGE_TITLE_EDIT: 'HOMEPAGE_TITLE_EDIT',
      LOGO_URL_VIEW: 'LOGO_URL_VIEW',
      LOGO_URL_EDIT: 'LOGO_URL_EDIT',
      CUSTOM_DOMAIN_FLOW: 'CUSTOM_DOMAIN_FLOW',
    },
  },
  BRAND: {
    value: 'BRAND',
    actions: {
      BRAND_LOGO_VIEW: 'BRAND_LOGO_VIEW',
      BRAND_LOGO_EDIT: 'BRAND_LOGO_EDIT',
      STORE_COLORS_VIEW: 'STORE_COLORS_VIEW',
      STORE_COLORS_EDIT: 'STORE_COLORS_EDIT',
    },
  },
  PAGES_POPUPS_PDF_PRESENTATIONS: {
    value: 'PAGES_POPUPS_PDF_PRESENTATIONS',
    actions: {
      HOMEPAGE_VIEW: 'HOMEPAGE_VIEW',
      HOMEPAGE_EDIT: 'HOMEPAGE_EDIT',
      PDF_PRESENTATION_VIEW: 'PDF_PRESENTATION_VIEW',
      PDF_PRESENTATION_EDIT: 'PDF_PRESENTATION_EDIT',
      WHAT_WE_OFFER_PAGE_VIEW: 'WHAT_WE_OFFER_PAGE_VIEW',
      WHAT_WE_OFFER_PAGE_EDIT: 'WHAT_WE_OFFER_PAGE_EDIT',
    },
  },
  INTERCOM: {
    value: 'INTERCOM',
    actions: {
      INTERCOM_VIEW: 'INTERCOM_VIEW',
      INTERCOM_EDIT: 'INTERCOM_EDIT',
    },
  },
  ADD_3RD_PARTY_CODE: {
    value: 'ADD_3RD_PARTY_CODE',
    actions: {
      ADD_3RD_PARTY_CODE_VIEW: 'ADD_3RD_PARTY_CODE_VIEW',
      ADD_3RD_PARTY_CODE_EDIT: 'ADD_3RD_PARTY_CODE_EDIT',
    },
  },
  AVAILABLE_PRODUCTS: {
    value: 'AVAILABLE_PRODUCTS',
    actions: {
      AVAILABLE_PRODUCTS_VIEW: 'AVAILABLE_PRODUCTS_VIEW',
      AVAILABLE_PRODUCTS_EDIT: 'AVAILABLE_PRODUCTS_EDIT',
    },
  },
  PRICE_POINTS_FOR_ALL_PRODUCTS: {
    value: 'PRICE_POINTS_FOR_ALL_PRODUCTS',
    actions: {
      PRICE_POINTS_FOR_ALL_PRODUCTS_VIEW: 'PRICE_POINTS_FOR_ALL_PRODUCTS_VIEW',
      PRICE_POINTS_FOR_ALL_PRODUCTS_EDIT: 'PRICE_POINTS_FOR_ALL_PRODUCTS_EDIT',
    },
  },
  COMMISSION: {
    value: 'COMMISSION',
    actions: {
      PRODUCT_COMMISSION_VIEW: 'PRODUCT_COMMISSION_VIEW',
      PRODUCT_COMMISSION_EDIT: 'PRODUCT_COMMISSION_EDIT',
      HIDE_PRODUCTS_FROM_THE_SITE_VIEW: 'HIDE_PRODUCTS_FROM_THE_SITE_VIEW',
      HIDE_PRODUCTS_FROM_THE_SITE_EDIT: 'HIDE_PRODUCTS_FROM_THE_SITE_EDIT',
    },
  },
  PANTONE_AND_MOCKUP_APPROVAL: {
    value: 'PANTONE_AND_MOCKUP_APPROVAL',
    actions: {
      PANTONE_AND_MOCKUP_APPROVAL_VIEW: 'PANTONE_AND_MOCKUP_APPROVAL_VIEW',
      PANTONE_AND_MOCKUP_APPROVAL_EDIT: 'PANTONE_AND_MOCKUP_APPROVAL_EDIT',
    },
  },
  PRODUCT_SORTING: {
    value: 'PRODUCT_SORTING',
    actions: {
      PRODUCT_SORTING_VIEW: 'PRODUCT_SORTING_VIEW',
      PRODUCT_SORTING_EDIT: 'PRODUCT_SORTING_EDIT',
    },
  },
  PAYOUT_BANK_DETAILS: {
    value: 'PAYOUT_BANK_DETAILS',
    actions: {
      PAYOUT_BANK_DETAILS_VIEW: 'PAYOUT_BANK_DETAILS_VIEW',
      PAYOUT_BANK_DETAILS_EDIT: 'PAYOUT_BANK_DETAILS_EDIT',
    },
  },
  INVOICE_BANK_DETAILS: {
    value: 'INVOICE_BANK_DETAILS',
    actions: {
      INVOICE_BANK_DETAILS_VIEW: 'INVOICE_BANK_DETAILS_VIEW',
      INVOICE_BANK_DETAILS_EDIT: 'INVOICE_BANK_DETAILS_EDIT',
    },
  },
  ACH_NET_PAYMENTS_MAJOR_DISCOUNT_PROMO_CODES: {
    value: 'ACH_NET_PAYMENTS_MAJOR_DISCOUNT_PROMO_CODES',
    actions: {
      ACH_NET_PAYMENTS_MAJOR_DISCOUNT_PROMO_CODES_VIEW:
        'ACH_NET_PAYMENTS_MAJOR_DISCOUNT_PROMO_CODES_VIEW',
      ACH_NET_PAYMENTS_MAJOR_DISCOUNT_PROMO_CODES_ADD:
        'ACH_NET_PAYMENTS_MAJOR_DISCOUNT_PROMO_CODES_ADD',
      ACH_NET_PAYMENTS_MAJOR_DISCOUNT_PROMO_CODES_REMOVE:
        'ACH_NET_PAYMENTS_MAJOR_DISCOUNT_PROMO_CODES_REMOVE',
    },
  },
  PROMOCODES_VIEW: {
    value: 'PROMOCODES_VIEW',
    actions: {
      ACTIVE_PROMOCODES_VIEW: 'ACTIVE_PROMOCODES_VIEW',
      ACTIVE_PROMOCODES_FILTER: 'ACTIVE_PROMOCODES_FILTER',
    },
  },
  PROMOCODES_EDIT: {
    value: 'PROMOCODES_EDIT',
    actions: {
      NEW_PROMOCODE_CREATE: 'NEW_PROMOCODE_CREATE',
      ACTIVE_PROMOCODES_DELETE: 'ACTIVE_PROMOCODES_DELETE',
    },
  },
  WITHDRAW_VIEW: {
    value: 'WITHDRAW_VIEW',
    actions: {
      WITHDRAW_VIEW: 'WITHDRAW_VIEW',
    },
  },
  DO_WITHDRAW: {
    value: 'DO_WITHDRAW',
    actions: {
      DO_WITHDRAW_USE: 'DO_WITHDRAW_USE',
    },
  },
  REFER_A_MEMBER: {
    value: 'REFER_A_MEMBER',
    actions: {
      REFER_A_MEMBER_VIEW: 'REFER_A_MEMBER_VIEW',
      REFER_A_MEMBER_COPY_LINK: 'REFER_A_MEMBER_COPY_LINK',
    },
  },
  VIEW_STAFF: {
    value: 'VIEW_STAFF',
    actions: {
      VIEW_STAFF: 'VIEW_STAFF',
    },
  },
  EDIT_STAFF: {
    value: 'EDIT_STAFF',
    actions: {
      EDIT_STAFF_VIEW: 'EDIT_STAFF_VIEW',
      EDIT_STAFF_EDIT: 'EDIT_STAFF_EDIT',
      ADD_STAFF: 'ADD_STAFF',
    },
  },
  VIEW_ORDERS: {
    value: 'VIEW_ORDERS',
    actions: {
      VIEW_ALL_ORDERS: 'VIEW_ALL_ORDERS',
      SEARCH_ORDERS: 'SEARCH_ORDERS',
      FILTER_ORDERS: 'FILTER_ORDERS',
      EXPORT_ORDERS: 'EXPORT_ORDERS',
      ACH_NET_RETRY_PAYMENT: 'ACH_NET_RETRY_PAYMENT',
    },
  },
  ORDER_ASSIGNMENT: {
    value: 'ORDER_ASSIGNMENT',
    actions: {
      ASSIGN_TO_ORDERS: 'ASSIGN_TO_ORDERS',
    },
  },
  TEAM_MEMBER_ORDER_ASSIGNMENT: {
    value: 'TEAM_MEMBER_ORDER_ASSIGNMENT',
    actions: {
      ASSIGN_TEAM_MEMBER_TO_ORDER: 'ASSIGN_TEAM_MEMBER_TO_ORDER',
    },
  },
  SEND_INVOICE: {
    value: 'SEND_INVOICE',
    actions: {
      SEND_INVOICE: 'SEND_INVOICE',
    },
  },
  DOWNLOAD_INVOICE: {
    value: 'DOWNLOAD_INVOICE',
    actions: {
      DOWNLOAD_ORDER_INVOICE: 'DOWNLOAD_ORDER_INVOICE',
    },
  },
  APPROVE_PANTONES: {
    value: 'APPROVE_PANTONES',
    actions: {
      APPROVE_PANTONES: 'APPROVE_PANTONES',
    },
  },
  APPROVE_MOCKUPS: {
    value: 'APPROVE_MOCKUPS',
    actions: {
      APPROVE_MOCKUPS: 'APPROVE_MOCKUPS',
    },
  },
  VIEW_INVOICE: {
    value: 'VIEW_INVOICE',
    actions: {
      VIEW_INVOICE_VIEW: 'VIEW_INVOICE_VIEW',
      VIEW_INVOICE_SEARCH: 'VIEW_INVOICE_SEARCH',
      VIEW_INVOICE_FILTER: 'VIEW_INVOICE_FILTER',
    },
  },
  CREATE_EDIT_INVOICE: {
    value: 'CREATE_EDIT_INVOICE',
    actions: {
      CREATE_INVOICE: 'CREATE_INVOICE',
      DOWNLOAD_INVOICE: 'DOWNLOAD_INVOICE',
      MARK_AS_SENT: 'MARK_AS_SENT',
      MARK_AS_PAID: 'MARK_AS_PAID',
      EDIT_INVOICE: 'EDIT_INVOICE',
      DELETE_INVOICE: 'DELETE_INVOICE',
      ASSIGN_INVOICE: 'ASSIGN_INVOICE',
    },
  },
  TEAM_MEMBER_INVOICE_ASSIGNMENT: {
    value: 'TEAM_MEMBER_INVOICE_ASSIGNMENT',
    actions: {
      ASSIGN_TEAM_MEMBER_TO_INVOICE: 'ASSIGN_TEAM_MEMBER_TO_INVOICE',
    },
  },
  MAKE_ORDER_FROM_INVOICE: {
    value: 'MAKE_ORDER_FROM_INVOICE',
    actions: {
      ORDER_INVOICE: 'ORDER_INVOICE',
    },
  },
  VIEW_PDF_PRESENTATION: {
    value: 'VIEW_PDF_PRESENTATION',
    actions: {
      VIEW_PDF_PRESENTATION_VIEW: 'VIEW_PDF_PRESENTATION_VIEW',
      VIEW_PDF_PRESENTATION_SEARCH: 'VIEW_PDF_PRESENTATION_SEARCH',
      VIEW_PDF_PRESENTATION_FILTER: 'VIEW_PDF_PRESENTATION_FILTER',
    },
  },
  CREATE_EDIT_PDF_PRESENTATION: {
    value: 'CREATE_EDIT_PDF_PRESENTATION',
    actions: {
      CREATE_PDF_PRESENTATION: 'CREATE_PDF_PRESENTATION',
      DOWNLOAD_PDF_PRESENTATION: 'DOWNLOAD_PDF_PRESENTATION',
      MARK_PDF_PRESENTATION_AS_SENT: 'MARK_PDF_PRESENTATION_AS_SENT',
      EDIT_PDF_PRESENTATION: 'EDIT_PDF_PRESENTATION',
      DELETE_PDF_PRESENTATION: 'DELETE_PDF_PRESENTATION',
      CREATE_INVOICE_FROM_PDF_PRESENTATION: 'CREATE_INVOICE_FROM_PDF_PRESENTATION',
      CREATE_CART_LINK_FROM_PDF_PRESENTATION: 'CREATE_CART_LINK_FROM_PDF_PRESENTATION',
      ASSIGN_PDF_PRESENTATION: 'ASSIGN_PDF_PRESENTATION',
    },
  },
  TEAM_MEMBER_PDF_PRESENTATION_ASSIGNMENT: {
    value: 'TEAM_MEMBER_PDF_PRESENTATION_ASSIGNMENT',
    actions: {
      ASSIGN_TEAM_MEMBER_TO_PDF_PRESENTATION: 'ASSIGN_TEAM_MEMBER_TO_PDF_PRESENTATION',
    },
  },
  VIEW_BUILD_A_CART: {
    value: 'VIEW_BUILD_A_CART',
    actions: {
      VIEW_BUILD_A_CART_VIEW: 'VIEW_BUILD_A_CART_VIEW',
      VIEW_BUILD_A_CART_SEARCH: 'VIEW_BUILD_A_CART_SEARCH',
      VIEW_BUILD_A_CART_FILTER: 'VIEW_BUILD_A_CART_FILTER',
    },
  },
  CREATE_EDIT_BUILD_A_CART: {
    value: 'CREATE_EDIT_BUILD_A_CART',
    actions: {
      CREATE_BUILD_A_CART: 'CREATE_BUILD_A_CART',
      EDIT_BUILD_A_CART: 'EDIT_BUILD_A_CART',
      DELETE_BUILD_A_CART: 'DELETE_BUILD_A_CART',
      ASSIGN_BUILD_A_CART: 'ASSIGN_BUILD_A_CART',
    },
  },
  TEAM_MEMBER_BUILD_A_CART_ASSIGNMENT: {
    value: 'TEAM_MEMBER_BUILD_A_CART_ASSIGNMENT',
    actions: {
      ASSIGN_TEAM_MEMBER_TO_BUILD_A_CART: 'ASSIGN_TEAM_MEMBER_TO_BUILD_A_CART',
    },
  },
  CUSTOMER_LIST: {
    value: 'CUSTOMER_LIST',
    actions: {
      VIEW_CUSTOMERS_LIST: 'VIEW_CUSTOMERS_LIST',
      SEARCH_CUSTOMERS: 'SEARCH_CUSTOMERS',
      FILTER_CUSTOMERS: 'FILTER_CUSTOMERS',
      VIEW_CUSTOMER_DETAILS: 'VIEW_CUSTOMER_DETAILS',
    },
  },
  EDIT_CUSTOMERS_DETAILS: {
    value: 'EDIT_CUSTOMERS_DETAILS',
    actions: {
      LOGIN_AS_CUSTOMER: 'LOGIN_AS_CUSTOMER',
      EDIT_CUSTOMER_DETAILS_INFO: 'EDIT_CUSTOMER_DETAILS_INFO',
      EDIT_CUSTOMER_PAYMENT_TERMS: 'EDIT_CUSTOMER_PAYMENT_TERMS',
      VIEW_INVENTORY: 'VIEW_INVENTORY',
      PANTONE_AND_MOCKUP_APPROVAL: 'PANTONE_AND_MOCKUP_APPROVAL',
      ASSIGN_CUSTOMERS: 'ASSIGN_CUSTOMERS',
    },
  },
  TEAM_MEMBER_CUSTOMER_ASSIGNMENT: {
    value: 'TEAM_MEMBER_CUSTOMER_ASSIGNMENT',
    actions: {
      ASSIGN_TEAM_MEMBER_TO_CUSTOMER: 'ASSIGN_TEAM_MEMBER_TO_CUSTOMER',
    },
  },
  LEADS: {
    value: 'LEADS',
    actions: {
      VIEW_LEADS_LIST: 'VIEW_LEADS_LIST',
      SEARCH_LEADS: 'SEARCH_LEADS',
      FILTER_LEADS: 'FILTER_LEADS',
      EDIT_LEAD_STATUS: 'EDIT_LEAD_STATUS',
      ASSIGN_LEADS: 'ASSIGN_LEADS',
    },
  },
  TEAM_MEMBER_LEAD_ASSIGNMENT: {
    value: 'TEAM_MEMBER_LEAD_ASSIGNMENT',
    actions: {
      ASSIGN_TEAM_MEMBER_TO_LEAD: 'ASSIGN_TEAM_MEMBER_TO_LEAD',
    },
  },
  ABANDONED_CHECKOUTS: {
    value: 'ABANDONED_CHECKOUTS',
    actions: {
      VIEW_ABANDONED_CHECKOUTS_LIST: 'VIEW_ABANDONED_CHECKOUTS_LIST',
      SEARCH_ABANDONED_CHECKOUTS: 'SEARCH_ABANDONED_CHECKOUTS',
      FILTER_ABANDONED_CHECKOUTS: 'FILTER_ABANDONED_CHECKOUTS',
      EDIT_ABANDONED_CHECKOUTS_STATUS: 'EDIT_ABANDONED_CHECKOUTS_STATUS',
      ASSIGN_ABANDONED_ORDERS: 'ASSIGN_ABANDONED_ORDERS',
    },
  },
  TEAM_MEMBER_ABANDONED_ORDER_ASSIGNMENT: {
    value: 'TEAM_MEMBER_ABANDONED_ORDER_ASSIGNMENT',
    actions: {
      ASSIGN_TEAM_MEMBER_TO_ABANDONED_ORDER: 'ASSIGN_TEAM_MEMBER_TO_ABANDONED_ORDER',
    },
  },
};

export const CAN_COMBINED_NAMES = {
  WITHDRAW: 'WITHDRAW',

  PROMOCODE_PAGE: ROUTES.PROMOCODES,
  MY_ORDERS_PAGE: ROUTES.MY_ORDERS,
  SALES_TOOLS_PAGE: ROUTES.INVOICE_GENERATION,
  INVOICES_PAGE: ROUTES.INVOICE_GENERATION,
  PDF_PRESENTATION_PAGE: ROUTES.PDF_PRESENTATION,
  BUILD_A_CART_PAGE: ROUTES.BUILD_A_CART,
  CUSTOMERS_AND_LEADS_PAGE: ROUTES.CUSTOMERS,
  CUSTOMERS_PAGE: ROUTES.CUSTOMERS,
  LEADS_PAGE: ROUTES.LEADS,
  ABANDONED_CHECKOUTS_PAGE: ROUTES.ABANDONED_CHECKOUTS,
  REFER_A_MEMBER_PAGE: ROUTES.REFER_A_MEMBER,

  SETTINGS_COMPANY_AND_BRAND_PAGE: ROUTES.SETTINGS_COMPANY_AND_BRAND,
  SETTINGS_PRODUCTS_AND_COMMISSION_PAGE: ROUTES.SETTINGS_PRODUCTS_AND_COMMISSION,
  SETTINGS_PAYMENT_PAGE: ROUTES.SETTINGS_PLAN_AND_PAYMENT,
  SETTINGS_BUSINESS_HOURS_PAGE: ROUTES.SETTINGS_OPERATION_HOURS,
  SETTINGS_USER_AND_PERMISSIONS_PAGE: ROUTES.SETTINGS_USER_AND_PERMISSIONS,
};

export const SETTINGS_FALLBACKS = [
  CAN_COMBINED_NAMES.SETTINGS_COMPANY_AND_BRAND_PAGE,
  CAN_COMBINED_NAMES.SETTINGS_PRODUCTS_AND_COMMISSION_PAGE,
  CAN_COMBINED_NAMES.SETTINGS_PAYMENT_PAGE,
  CAN_COMBINED_NAMES.SETTINGS_BUSINESS_HOURS_PAGE,
  CAN_COMBINED_NAMES.SETTINGS_USER_AND_PERMISSIONS_PAGE,
];

export const SALES_TOOLS_FALLBACKS = [
  CAN_COMBINED_NAMES.INVOICES_PAGE,
  CAN_COMBINED_NAMES.PDF_PRESENTATION_PAGE,
  CAN_COMBINED_NAMES.BUILD_A_CART_PAGE,
];

export const CUSTOMERS_AND_LEADS_FALLBACKS = [
  CAN_COMBINED_NAMES.CUSTOMERS_PAGE,
  CAN_COMBINED_NAMES.LEADS_PAGE,
];

export const CAN_COMBINED = {
  [CAN_COMBINED_NAMES.PROMOCODE_PAGE]: {
    fallbacks: [],
    permissions: [
      {
        subject: SUBJECTS.PROMOCODES_VIEW.value,
        action: SUBJECTS.PROMOCODES_VIEW.actions.ACTIVE_PROMOCODES_VIEW,
      },
      {
        subject: SUBJECTS.PROMOCODES_EDIT.value,
        action: SUBJECTS.PROMOCODES_EDIT.actions.NEW_PROMOCODE_CREATE,
      },
    ],
  },
  [CAN_COMBINED_NAMES.MY_ORDERS_PAGE]: {
    fallbacks: [],
    permissions: [
      {
        subject: SUBJECTS.VIEW_ORDERS.value,
        action: SUBJECTS.VIEW_ORDERS.actions.VIEW_ALL_ORDERS,
      },
    ],
  },
  [CAN_COMBINED_NAMES.SALES_TOOLS_PAGE]: {
    fallbacks: SALES_TOOLS_FALLBACKS,
    permissions: [
      {
        subject: SUBJECTS.VIEW_BUILD_A_CART.value,
        action: SUBJECTS.VIEW_BUILD_A_CART.actions.VIEW_BUILD_A_CART_VIEW,
      },
      {
        subject: SUBJECTS.VIEW_PDF_PRESENTATION.value,
        action: SUBJECTS.VIEW_PDF_PRESENTATION.actions.VIEW_PDF_PRESENTATION_VIEW,
      },
      {
        subject: SUBJECTS.VIEW_INVOICE.value,
        action: SUBJECTS.VIEW_INVOICE.actions.VIEW_INVOICE_VIEW,
      },
    ],
  },
  [CAN_COMBINED_NAMES.INVOICES_PAGE]: {
    fallbacks: SALES_TOOLS_FALLBACKS,
    permissions: [
      {
        subject: SUBJECTS.VIEW_INVOICE.value,
        action: SUBJECTS.VIEW_INVOICE.actions.VIEW_INVOICE_VIEW,
      },
    ],
  },
  [CAN_COMBINED_NAMES.PDF_PRESENTATION_PAGE]: {
    fallbacks: SALES_TOOLS_FALLBACKS,
    permissions: [
      {
        subject: SUBJECTS.VIEW_PDF_PRESENTATION.value,
        action: SUBJECTS.VIEW_PDF_PRESENTATION.actions.VIEW_PDF_PRESENTATION_VIEW,
      },
    ],
  },
  [CAN_COMBINED_NAMES.BUILD_A_CART_PAGE]: {
    fallbacks: SALES_TOOLS_FALLBACKS,
    permissions: [
      {
        subject: SUBJECTS.VIEW_BUILD_A_CART.value,
        action: SUBJECTS.VIEW_BUILD_A_CART.actions.VIEW_BUILD_A_CART_VIEW,
      },
    ],
  },
  [CAN_COMBINED_NAMES.CUSTOMERS_PAGE]: {
    fallbacks: CUSTOMERS_AND_LEADS_FALLBACKS,
    permissions: [
      {
        subject: SUBJECTS.CUSTOMER_LIST.value,
        action: SUBJECTS.CUSTOMER_LIST.actions.VIEW_CUSTOMERS_LIST,
      },
    ],
  },
  [CAN_COMBINED_NAMES.LEADS_PAGE]: {
    fallbacks: CUSTOMERS_AND_LEADS_FALLBACKS,
    permissions: [
      {
        subject: SUBJECTS.LEADS.value,
        action: SUBJECTS.LEADS.actions.VIEW_LEADS_LIST,
      },
    ],
  },
  [CAN_COMBINED_NAMES.ABANDONED_CHECKOUTS_PAGE]: {
    fallbacks: [],
    permissions: [
      {
        subject: SUBJECTS.ABANDONED_CHECKOUTS.value,
        action: SUBJECTS.ABANDONED_CHECKOUTS.actions.VIEW_ABANDONED_CHECKOUTS_LIST,
      },
    ],
  },
  [CAN_COMBINED_NAMES.REFER_A_MEMBER_PAGE]: {
    fallbacks: [],
    permissions: [
      {
        subject: SUBJECTS.REFER_A_MEMBER.value,
        action: SUBJECTS.REFER_A_MEMBER.actions.REFER_A_MEMBER_VIEW,
      },
    ],
  },
  [CAN_COMBINED_NAMES.SETTINGS_COMPANY_AND_BRAND_PAGE]: {
    fallbacks: SETTINGS_FALLBACKS,
    permissions: [
      {
        subject: SUBJECTS.COMPANY_DETAILS.value,
        action: SUBJECTS.COMPANY_DETAILS.actions.COMPANY_DETAILS_VIEW,
      },
      {
        subject: SUBJECTS.BILLING_ADDRESS.value,
        action: SUBJECTS.BILLING_ADDRESS.actions.BILLING_ADDRESS_VIEW,
      },
      {
        subject: SUBJECTS.SOCIAL_HANDLE.value,
        action: SUBJECTS.SOCIAL_HANDLE.actions.SOCIAL_HANDLE_VIEW,
      },
      {
        subject: SUBJECTS.STORE_PREFERENCES.value,
        action: SUBJECTS.STORE_PREFERENCES.actions.HOMEPAGE_TITLE_VIEW,
      },
      {
        subject: SUBJECTS.STORE_PREFERENCES.value,
        action: SUBJECTS.STORE_PREFERENCES.actions.STORE_LINK_VIEW,
      },
      {
        subject: SUBJECTS.STORE_PREFERENCES.value,
        action: SUBJECTS.STORE_PREFERENCES.actions.LOGO_URL_VIEW,
      },
      {
        subject: SUBJECTS.BRAND.value,
        action: SUBJECTS.BRAND.actions.BRAND_LOGO_VIEW,
      },
      {
        subject: SUBJECTS.BRAND.value,
        action: SUBJECTS.BRAND.actions.STORE_COLORS_VIEW,
      },
      {
        subject: SUBJECTS.PAGES_POPUPS_PDF_PRESENTATIONS.value,
        action: SUBJECTS.PAGES_POPUPS_PDF_PRESENTATIONS.actions.PDF_PRESENTATION_VIEW,
      },
      {
        subject: SUBJECTS.PAGES_POPUPS_PDF_PRESENTATIONS.value,
        action: SUBJECTS.PAGES_POPUPS_PDF_PRESENTATIONS.actions.HOMEPAGE_VIEW,
      },
      {
        subject: SUBJECTS.PAGES_POPUPS_PDF_PRESENTATIONS.value,
        action: SUBJECTS.PAGES_POPUPS_PDF_PRESENTATIONS.actions.WHAT_WE_OFFER_PAGE_VIEW,
      },
      {
        subject: SUBJECTS.INTERCOM.value,
        action: SUBJECTS.INTERCOM.actions.INTERCOM_VIEW,
      },
      {
        subject: SUBJECTS.ADD_3RD_PARTY_CODE.value,
        action: SUBJECTS.ADD_3RD_PARTY_CODE.actions.ADD_3RD_PARTY_CODE_VIEW,
      },
      {
        subject: SUBJECTS.RESELLER_CERTIFICATES.value,
        action: SUBJECTS.RESELLER_CERTIFICATES.actions.RESELLER_CERTIFICATES_VIEW,
      },
      {
        subject: SUBJECTS.TERMS_OF_SERVICE.value,
        action: SUBJECTS.TERMS_OF_SERVICE.actions.TERMS_OF_SERVICE_VIEW,
      },
      {
        subject: SUBJECTS.PRIVACY_POLICY.value,
        action: SUBJECTS.PRIVACY_POLICY.actions.PRIVACY_POLICY_VIEW,
      },
      {
        subject: SUBJECTS.ASSIGNMENT_FLOW.value,
        action: SUBJECTS.ASSIGNMENT_FLOW.actions.EDIT_ASSIGNMENT_FLOW,
      },
    ],
  },
  [CAN_COMBINED_NAMES.SETTINGS_PRODUCTS_AND_COMMISSION_PAGE]: {
    fallbacks: SETTINGS_FALLBACKS,
    permissions: [
      {
        subject: SUBJECTS.AVAILABLE_PRODUCTS.value,
        action: SUBJECTS.AVAILABLE_PRODUCTS.actions.AVAILABLE_PRODUCTS_VIEW,
      },
      {
        subject: SUBJECTS.PRICE_POINTS_FOR_ALL_PRODUCTS.value,
        action:
          SUBJECTS.PRICE_POINTS_FOR_ALL_PRODUCTS.actions
            .PRICE_POINTS_FOR_ALL_PRODUCTS_VIEW,
      },
      {
        subject: SUBJECTS.COMMISSION.value,
        action: SUBJECTS.COMMISSION.actions.HIDE_PRODUCTS_FROM_THE_SITE_VIEW,
      },
      {
        subject: SUBJECTS.COMMISSION.value,
        action: SUBJECTS.COMMISSION.actions.PRODUCT_COMMISSION_VIEW,
      },
      {
        subject: SUBJECTS.PRODUCT_SORTING.value,
        action: SUBJECTS.PRODUCT_SORTING.actions.PRODUCT_SORTING_VIEW,
      },
      {
        subject: SUBJECTS.PANTONE_AND_MOCKUP_APPROVAL.value,
        action:
          SUBJECTS.PANTONE_AND_MOCKUP_APPROVAL.actions.PANTONE_AND_MOCKUP_APPROVAL_VIEW,
      },
    ],
  },
  [CAN_COMBINED_NAMES.SETTINGS_PAYMENT_PAGE]: {
    fallbacks: SETTINGS_FALLBACKS,
    permissions: [
      {
        subject: SUBJECTS.PAYOUT_BANK_DETAILS.value,
        action: SUBJECTS.PAYOUT_BANK_DETAILS.actions.PAYOUT_BANK_DETAILS_VIEW,
      },
      {
        subject: SUBJECTS.INVOICE_BANK_DETAILS.value,
        action: SUBJECTS.INVOICE_BANK_DETAILS.actions.INVOICE_BANK_DETAILS_VIEW,
      },
      {
        subject: SUBJECTS.ACH_NET_PAYMENTS_MAJOR_DISCOUNT_PROMO_CODES.value,
        action:
          SUBJECTS.ACH_NET_PAYMENTS_MAJOR_DISCOUNT_PROMO_CODES.actions
            .ACH_NET_PAYMENTS_MAJOR_DISCOUNT_PROMO_CODES_VIEW,
      },
    ],
  },
  [CAN_COMBINED_NAMES.SETTINGS_BUSINESS_HOURS_PAGE]: {
    fallbacks: SETTINGS_FALLBACKS,
    permissions: [
      {
        subject: SUBJECTS.BUSINESS_HOURS.value,
        action: SUBJECTS.BUSINESS_HOURS.actions.BUSINESS_HOURS_VIEW,
      },
    ],
  },

  [CAN_COMBINED_NAMES.SETTINGS_USER_AND_PERMISSIONS_PAGE]: {
    fallbacks: SETTINGS_FALLBACKS,
    permissions: [
      {
        subject: SUBJECTS.VIEW_STAFF.value,
        action: SUBJECTS.VIEW_STAFF.actions.VIEW_STAFF,
      },
    ],
  },
};

export type CAN_COMBINED_KEYS_TYPE = keyof typeof CAN_COMBINED;
