import cn from 'classnames';
import {
  Typography,
  WhiteBox,
  List,
  ListHeader,
  ListItem,
  Divider,
} from '@ezetech/swag-space-x';
import { useNavigate } from 'react-router-dom';
import COLORS from 'constants/styles/colors-js.constant.module.scss';

import { TrendDirectionInline } from '../trend-direction-inline';
import { SingleTrendCardProps } from './single-trend-card.type';

import css from './single-trend-card.module.scss';

export function SingleTrendCard({
  title,
  titleLink,
  value,
  valueDescription,
  percent,
  percentText,
  className,
  link,
}: SingleTrendCardProps): JSX.Element {
  const navigate = useNavigate();

  const shouldRenderLink = titleLink && link;
  return (
    <WhiteBox className={cn(css.root, className)}>
      <List>
        <ListHeader className={css.header}>
          <Typography
            lineHeight="162%"
            fontType="bodyMdBold"
            color={COLORS.colorTextSubdued}
          >
            {title}
          </Typography>
          {shouldRenderLink && (
            <Typography lineHeight="162%" fontType="bodyMd" color={COLORS.colorPink} link>
              <div
                onClick={() => {
                  navigate(link);
                }}
              >
                {titleLink}
              </div>
            </Typography>
          )}
        </ListHeader>
        <Divider />
        <ListItem className={css.value}>
          <Typography
            lineHeight="100%"
            fontType={'HEADING5XL'}
            color={COLORS.colorPrimaryText}
          >
            {value}
          </Typography>
          <Typography fontType="bodySm" color={COLORS.colorTextSubdued}>
            {valueDescription}
          </Typography>
        </ListItem>
        {percent !== undefined ? (
          <>
            <Divider />
            <ListItem className={css.percent}>
              <div>
                <TrendDirectionInline percent={percent} text={percentText} />
              </div>
            </ListItem>
          </>
        ) : null}
      </List>
    </WhiteBox>
  );
}
