import { FC } from 'react';
import { Typography } from '@ezetech/swag-space-x';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { LANDING_URL } from 'utils/urls.util';
import css from '../sign-contract.module.scss';
import { makeBold, makeLink } from '../sign-contract.util';

export const Main: FC = () => {
  return (
    <>
      <Typography
        className={css.alignCenter}
        color={COLORS.colorPrimaryText}
        fontType="bodyLgBold"
      >
        Reseller Terms of Service
      </Typography>
      <br />
      <br />
      <Typography color={COLORS.colorPrimaryText} fontType="bodyMdBold">
        Last Updated: July 5, 2023
      </Typography>
      <br />
      <br />
      These Reseller Terms of Service and all other documents or policies referenced here
      and/or incorporated by reference (collectively, the {makeBold('"Terms"')}) set forth
      the legal contract between you ({makeBold('"Reseller"')}
      or {makeBold('"you"')} or {makeBold('"your"')}) and Swag Space, a business of
      Swag.com, LLC ({makeBold('"Swag Space"')}, {makeBold('"we"')}, or {makeBold('"us"')}
      ) regarding the use of the Services (as defined below).
      <br />
      <br />
      These Terms govern:
      <ul className={css.listPoint}>
        <li className={css.listItem}>
          your access to and use of the proprietary online platform, available at{' '}
          {makeLink('Swag.Space', LANDING_URL)} (collectively, with any other microsites
          or other digital properties operated by Swag Space related to the Services, the{' '}
          {makeBold('"Site"')});
        </li>
        <li className={css.listItem}>
          the use of Content (as defined below) that either we (and our affiliates and
          business partners) may make available to you through the Site and services
          provided to you or that you or your customers may submit through the Site; and
        </li>
        <li className={css.listItem}>
          all services, tools, software, and functionalities we may provide through or in
          connection with the Site including (a) tools for merchants or resellers having
          an account with Swag Space to build online custom merchandise or “swag” stores
          (each, a {makeBold('“Branded Storefront”')}
          )) and sell custom merchandise to customers (whether online (via the Branded
          Storefront) or offline), and (b) printing, fulfillment and inventory services
          (collectively, the {makeBold('“Fulfillment Services”')} and the Fulfillment
          Services with the Site and other services are referred to as{' '}
          {makeBold('“Services”')}
          ). Any new features or tools which are added to the current Services will also
          be subject to these Terms.
        </li>
      </ul>
      <br />
      Before you use the Services, you must read, agree with, and accept all of the terms
      and conditions contained or referenced in these Terms, including the{' '}
      {makeLink('Privacy Policy', `${LANDING_URL}/privacy-policy`)} and the{' '}
      {makeLink('Data Processing Terms', `${LANDING_URL}/dpa`)}. By registering for the
      Site or otherwise using the Services, you are agreeing with all these Terms.
      <br />
      <br />
      NOTE: THESE TERMS CONTAIN A DISPUTE RESOLUTION AND ARBITRATION PROVISION, INCLUDING
      A CLASS ACTION WAIVER THAT AFFECTS YOUR RIGHTS UNDER THESE TERMS AND WITH RESPECT TO
      DISPUTES YOU MAY HAVE WITH SWAG SPACE. YOU MAY OPT OUT OF THE BINDING INDIVIDUAL
      ARBITRATION AND CLASS ACTION WAIVER AS PROVIDED BELOW.
      <br />
      <br />
      What We Do
      <br />
      Application for Membership and Account Creation
      <br />
      Confidentiality
      <br />
      Non-solicitation
      <br />
      Changes
      <br />
      Branded Storefronts; Services; Reseller Responsibilities
      <br />
      Fees, Payments and Taxes
      <br />
      Intellectual Property and Licenses
      <br />
      Unauthorized Uses
      <br />
      Third Party Sites
      <br />
      Term and Termination
      <br />
      Disclaimer; Indemnity; Limitation of Liability
      <br />
      Dispute Resolution and Arbitration; Class Action Waiver
      <br />
      General
      <br />
      <br />
    </>
  );
};
