import { createSelector } from 'reselect';
import { RootState } from 'redux/store';
import { PDF_PRESENTATION_SETTINGS_PAGES } from 'constants/pdf-presentation-settings.constants';
import { PdfPresentationEditorFilter } from 'interfaces/pdf-presentation';
import { mapDBToStore } from 'utils/pdf-presentation-editor/mappers';
import { deepEqual } from 'utils/deep-equal';

export const pdfPresentationEditorSelector = (state: RootState) =>
  state.pdfPresentationsEditor;

export const pdfSelector = (state: RootState) => state.pdfPresentationsEditor.pdf;

export const filterSelector = createSelector(
  pdfPresentationEditorSelector,
  (state) => state.filter,
);

export const editableSelector = createSelector(
  pdfPresentationEditorSelector,
  (state) => state.editable,
);

export const editableDraftSelector = createSelector(
  pdfPresentationEditorSelector,
  (state) => state.editableDraft,
);

export const initialAsEditableSelector = createSelector(
  pdfPresentationEditorSelector,
  (state) => mapDBToStore(state.loadedConfig).editable,
);

export const selectedPageIdSelector = createSelector(
  pdfPresentationEditorSelector,
  (state) => state.selectedPage,
);

export const selectedPageTemplateSelector = createSelector(
  selectedPageIdSelector,
  (pageId) => PDF_PRESENTATION_SETTINGS_PAGES[pageId],
);

export const pagesToRenderSelector = createSelector(filterSelector, (filter) => {
  switch (filter) {
    case PdfPresentationEditorFilter.EDITABLE:
      return Object.values(PDF_PRESENTATION_SETTINGS_PAGES).filter(
        (page) => page.fieldsDefinition.length > 0,
      );
    case PdfPresentationEditorFilter.NON_EDITABLE:
      return Object.values(PDF_PRESENTATION_SETTINGS_PAGES).filter(
        (page) => page.fieldsDefinition.length === 0,
      );
    default:
      return Object.values(PDF_PRESENTATION_SETTINGS_PAGES);
  }
});

export const paginationSelector = createSelector(
  selectedPageIdSelector,
  pagesToRenderSelector,
  (pageId, pages) => {
    const foundIndex = pages.findIndex((page) => page.id === pageId);
    const selectedIndex = foundIndex == -1 ? 0 : foundIndex;
    const total = pages.length;
    const nextTemplateName =
      selectedIndex + 1 < total ? pages[selectedIndex + 1].id : null;
    const prevTemplateName = selectedIndex - 1 >= 0 ? pages[selectedIndex - 1].id : null;
    return {
      currentPage: selectedIndex + 1,
      total,
      nextTemplateName,
      prevTemplateName,
    };
  },
);

export const isEditedSelector = createSelector(
  editableSelector,
  editableDraftSelector,
  (editable, editableDraft) => {
    return !deepEqual(editable as never, editableDraft as never);
  },
);

export const pdfImagesOfPagesSelector = createSelector(
  pdfSelector,
  (state) => state.imagesOfPages,
);

export const isPdfRenderingSelector = createSelector(
  pdfSelector,
  (state) => state.rendering,
);

export const editableLastChangesSelector = createSelector(
  pdfPresentationEditorSelector,
  (state) => state.lastChanges,
);
