import React, { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import {
  TableBody,
  TableHeader,
  TableRow,
  TableHeaderCell,
  TableRowCell,
  WhiteBox,
  Typography,
  Divider,
  InfinityScroll,
  InfinityRowProps,
} from '@ezetech/swag-space-x';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { UsersFields, IUsersTransformRow } from 'interfaces/customer-company.interface';
import { USERS_TABLE_HEADER } from 'constants/customer-company.constant';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { resetFilters } from 'redux/slices/filter.slice';

import { ROUTES } from 'constants/router';

import { customerCompanyNameSelector } from 'redux/selectors/customer-company.selectors';
import { useCustomerCompanyUsers } from './users.hook';
import css from './users.module.scss';

const COUNT_PER_PAGE = 10;
const ITEM_HEIGHT = 59;

type itemRenderProps = InfinityRowProps<{
  items: IUsersTransformRow[];
  handleCellClick: (
    e?: React.SyntheticEvent<HTMLSpanElement>,
    number?: string,
    rowId?: string,
  ) => void;
}>;

export function Users(): JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const customerCompanyName = useAppSelector(customerCompanyNameSelector);
  const { getUsers, hasNextPage, currentPage, rows, isLoading } =
    useCustomerCompanyUsers();
  const { id } = useParams();

  const handleCellClick = (
    e?: React.SyntheticEvent<HTMLSpanElement>,
    name?: string,
    rowId?: string,
  ) => {
    if (name === UsersFields.userName && rowId) {
      dispatch(resetFilters());
      navigate(ROUTES.CUSTOMER_PROFILE.replace(':id', rowId));
    }
  };

  const handleNewPageLoad = useCallback(async () => {
    if (!id) {
      return;
    }
    getUsers({
      id,
      page: currentPage + 1,
      perPage: COUNT_PER_PAGE,
    });
  }, [getUsers, currentPage, id]);

  useEffect(() => {
    if (!id) {
      return;
    }
    getUsers({
      id,
      page: 1,
      perPage: COUNT_PER_PAGE,
    });
  }, [id]);

  const rowRenderer = useCallback(({ index, data, style }: itemRenderProps) => {
    const row = data.items[index];
    if (!row) {
      return <></>;
    }

    return (
      <div style={style}>
        <TableRow className={css.tableRow} primaryDataLabel={row.cells[0].rowId}>
          {row.cells.map((cell, cellIndex) => {
            return (
              <TableRowCell
                dataLabel={`${cell.id + '-' + cell.rowId}`}
                className={css.tableCell}
                key={`users-${index}-${cell.id}-${cellIndex}`}
                textColor={cell.textColor}
                textWeight={cell.textWeight}
                size={cell.size}
                id={cell.id}
                rowId={cell.rowId}
                actionable={cell.actionable}
                onClick={data.handleCellClick}
              >
                {cell.text}
              </TableRowCell>
            );
          })}
        </TableRow>
      </div>
    );
  }, []);

  const infinityWrapperStyle = {
    minHeight: ITEM_HEIGHT,
    height:
      ITEM_HEIGHT *
      (rows?.length && rows?.length < COUNT_PER_PAGE ? rows?.length : COUNT_PER_PAGE),
  };

  const itemProps = {
    handleCellClick,
  };

  return (
    <WhiteBox className={css.root}>
      <div className={css.header}>
        <Typography
          className={css.title}
          lineHeight="162%"
          fontType="bodyMdBold"
          color={COLORS.colorText600}
        >
          Full {customerCompanyName} Team
        </Typography>
      </div>
      <Divider />
      {rows && rows.length === 0 && (
        <div className={css.footer}>
          <Typography
            className={css.title}
            lineHeight="162%"
            fontType="bodyMdBold"
            color={COLORS.colorText600}
          >
            No Users
          </Typography>
        </div>
      )}
      {rows && rows.length !== 0 && (
        <div className={css.tableWrapper}>
          <TableHeader className={css.tableHeader}>
            {USERS_TABLE_HEADER.map((cell) => {
              return (
                <TableHeaderCell
                  id={cell.id}
                  className={css.headerCell}
                  size={cell.size}
                  key={`users-header-${cell.id}`}
                >
                  {cell.text}
                </TableHeaderCell>
              );
            })}
          </TableHeader>
          <Divider />
          <TableBody>
            <div style={infinityWrapperStyle}>
              <InfinityScroll
                itemHeight={ITEM_HEIGHT}
                items={rows}
                loadPage={handleNewPageLoad}
                row={rowRenderer}
                itemProps={itemProps}
                hasNextPage={hasNextPage}
                isLoading={isLoading}
              />
            </div>
          </TableBody>
        </div>
      )}
    </WhiteBox>
  );
}
