import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ITeamMember } from 'interfaces/invitation.interface';
import { IUpdateFieldPayload } from 'interfaces/form.interface';
import { GeneralPermissions } from 'interfaces/permission.interface';
import { teamMemberDetailsValidationSchema } from '../../validations/settings/team.validation';

interface IInvitationsState {
  team: ITeamMember[];
  page: number;
  perPage: number;
  total: number;
  isLoading: boolean;
  teamMemberDetails: ITeamMember | null;
  form: {
    email: string;
    firstName: string;
    lastName: string;
  };
  formErrors: {
    email?: string;
    firstName?: string;
    lastName?: string;
  };
  permissions: GeneralPermissions;
}

const initialState: IInvitationsState = {
  team: [],
  page: 1,
  perPage: 5,
  total: 0,
  isLoading: false,
  teamMemberDetails: null,
  form: {
    email: '',
    firstName: '',
    lastName: '',
  },
  formErrors: {},
  permissions: {},
};

export const handleFieldChange = createAsyncThunk(
  'invitations/handleFieldChange',
  async (payload: IUpdateFieldPayload, { dispatch, getState }) => {
    try {
      const {
        invitations: { formErrors },
      } = getState() as { invitations: IInvitationsState };
      if (formErrors[payload.key as keyof IInvitationsState['formErrors']]) {
        await teamMemberDetailsValidationSchema.validateAt(payload.key, {
          [payload.key]: payload.value,
        });
      }
      return {
        key: payload.key,
        value: payload.value,
        error: undefined,
      };
    } catch (validationError: unknown) {
      const { message } = validationError as { message: string };
      return { key: payload.key, value: payload.value, error: message };
    }
  },
);

export const handleFieldBlur = createAsyncThunk(
  'invitations/handleFieldBlur',
  async (payload: IUpdateFieldPayload, { dispatch }) => {
    try {
      await teamMemberDetailsValidationSchema.validateAt(payload.key, {
        [payload.key]: payload.value,
      });
      return { key: payload.key, value: payload.value, error: undefined };
    } catch (validationError: unknown) {
      const { message } = validationError as { message: string };
      return { key: payload.key, value: payload.value, error: message };
    }
  },
);

export const validateForm = createAsyncThunk(
  'invitations/validateForm',
  async (_, { dispatch, getState }) => {
    const {
      invitations: { form },
    } = getState() as { invitations: IInvitationsState };
    try {
      await teamMemberDetailsValidationSchema.validate(form, { abortEarly: false });
      return true;
    } catch (validationError: unknown) {
      const { inner } = validationError as { inner: { path: string; message: string }[] };
      const errors = inner.reduce(
        (acc, { path, message }) => {
          acc[path as keyof IInvitationsState['formErrors']] = message;
          return acc;
        },
        {} as IInvitationsState['formErrors'],
      );
      dispatch(setFormError({ key: 'email', error: errors.email }));
      dispatch(setFormError({ key: 'firstName', error: errors.firstName }));
      dispatch(setFormError({ key: 'lastName', error: errors.lastName }));
      return false;
    }
  },
);

export const invitationsSlice = createSlice({
  initialState,
  name: 'invitationsSlice',
  reducers: {
    reset: () => initialState,
    clear: (state) => {
      state.team = [];
      state.page = 1;
      state.total = 0;
      state.perPage = 5;
      state.isLoading = false;
    },
    setInvitations: (
      state,
      action: PayloadAction<
        Pick<IInvitationsState, 'team' | 'page' | 'perPage' | 'total' | 'isLoading'>
      >,
    ) => {
      if (!action.payload) {
        return state;
      }
      const { team, page, perPage, total, isLoading } = action.payload;
      state.team = page === 1 ? action.payload.team : [...state.team, ...team];
      state.page = page;
      state.perPage = perPage;
      state.total = total;
      state.isLoading = isLoading;
    },
    setTeam: (state, action: PayloadAction<ITeamMember[]>) => {
      state.team = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setPerPage: (state, action: PayloadAction<number>) => {
      state.perPage = action.payload;
    },
    setTotal: (state, action: PayloadAction<number>) => {
      state.total = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setTeamMemberDetails: (state, action: PayloadAction<ITeamMember | null>) => {
      state.teamMemberDetails = action.payload;
      state.form = {
        email: action.payload?.email || '',
        firstName: action.payload?.firstName || '',
        lastName: action.payload?.lastName || '',
      };
      if (action.payload?.permissions) {
        state.permissions = action.payload.permissions;
      }
      state.formErrors = {};
    },
    setPermissionValue: (
      state,
      action: PayloadAction<{ group: string; permission: string; value?: boolean }>,
    ) => {
      const { group, permission, value } = action.payload;
      const newPermissions = {
        ...state.permissions,
        [group]: {
          ...state.permissions[group],
          permissions: {
            ...state.permissions[group].permissions,
            [permission]: {
              ...state.permissions[group].permissions[permission],
              value: value ?? !state.permissions[group].permissions[permission].value,
            },
          },
        },
      };
      state.permissions = newPermissions;
    },
    resetPermissions: (state) => {
      state.permissions = state.teamMemberDetails?.permissions ?? {};
    },
    setPermissions: (state, action: PayloadAction<GeneralPermissions>) => {
      state.permissions = action.payload;
    },
    setFormError: (
      state,
      action: PayloadAction<{ key: string; error: string | undefined }>,
    ) => {
      state.formErrors[action.payload.key as keyof IInvitationsState['formErrors']] =
        action.payload.error;
    },
    clearFormErrors: (state) => {
      state.formErrors = {};
    },
    setInvitation: (state, action: PayloadAction<ITeamMember>) => {
      state.team = state.team.map((member) =>
        member.id === action.payload.id ? action.payload : member,
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(handleFieldChange.fulfilled, (state, action) => {
      const { key, value, error } = action.payload;
      state.form[key as keyof IInvitationsState['form']] = value as string;
      state.formErrors[key as keyof IInvitationsState['formErrors']] = error;
    });
    builder.addCase(handleFieldBlur.fulfilled, (state, action) => {
      const { key, error } = action.payload;
      state.formErrors[key as keyof IInvitationsState['formErrors']] = error;
    });
  },
});

export const invitationsReducer = invitationsSlice.reducer;
export const {
  reset,
  clear,
  setInvitations,
  setTeam,
  setPage,
  setPerPage,
  setTotal,
  setLoading,
  setPermissions,
  setTeamMemberDetails,
  setFormError,
  clearFormErrors,
  setInvitation,
  setPermissionValue,
  resetPermissions,
} = invitationsSlice.actions;
