import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ROUTES } from 'constants/router';

import {
  MARGIN_MIN_PERCENT,
  STEPS,
  DEFAULT_PRICE_POINTS,
} from 'constants/store-creation.constants';
import { ONBOARDING_STATUS, STEP_NAME } from 'enums/store-creation.enum';
import {
  CategoriesStructure,
  IStoreSettings,
} from 'interfaces/store-creation.interfaces';
import { getStepBySlug } from 'utils/get-creation-step-by-slug.utils';
import { transformCategories } from 'utils/transform-categories.util';

import { routerOutside } from '../../utils/router-outside.utils';

const SLICE_NAME = 'storeCreation';

export interface IStoreCreationState {
  activeCategoryTab: number;
  currentIndex: number;
  categoriesStructure: CategoriesStructure;
  isCompleted: ONBOARDING_STATUS | null;
  storeSettings: IStoreSettings;
  initialStoreSettings: IStoreSettings;
  hasMarginBeenSet: boolean;
  hasCategoriesBeenSet: boolean;
}

const initialStoreSettings = {
  [STEP_NAME.PRODUCT_SELECTION]: { categories: [], pricePoints: DEFAULT_PRICE_POINTS },
  [STEP_NAME.COMMISSION]: { resellerMargin: MARGIN_MIN_PERCENT },
  [STEP_NAME.PAGE_LINK]: { resellerStoreName: '' },
  [STEP_NAME.SIGN_CONTRACT]: { isContractSigned: false, isContractRead: false },
  [STEP_NAME.BRAND]: {
    primaryColor: undefined,
    secondaryColor: undefined,
    logo: undefined,
    favicon: undefined,
  },
};

const initialState: IStoreCreationState = {
  activeCategoryTab: 0,
  currentIndex: getStepBySlug(),
  isCompleted: null,
  storeSettings: initialStoreSettings,
  initialStoreSettings: initialStoreSettings,
  categoriesStructure: [],
  hasMarginBeenSet: false,
  hasCategoriesBeenSet: false,
};

export const storeCreation = createSlice({
  initialState,
  name: SLICE_NAME,
  reducers: {
    setStoreSettings: (state, action: PayloadAction<IStoreSettings>) => {
      const resellerMargin = action.payload[STEP_NAME.COMMISSION].resellerMargin;
      const categories = action.payload[STEP_NAME.PRODUCT_SELECTION].categories;
      const pricePoints = action.payload[STEP_NAME.PRODUCT_SELECTION].pricePoints;

      state.initialStoreSettings = {
        ...action.payload,
        [STEP_NAME.COMMISSION]: { resellerMargin: resellerMargin || MARGIN_MIN_PERCENT },
        [STEP_NAME.PRODUCT_SELECTION]: {
          categories: categories.length
            ? categories
            : transformCategories(state.categoriesStructure),
          pricePoints: pricePoints || DEFAULT_PRICE_POINTS,
        },
      };
      state.storeSettings = {
        ...action.payload,
        [STEP_NAME.COMMISSION]: { resellerMargin: resellerMargin || MARGIN_MIN_PERCENT },
        [STEP_NAME.PRODUCT_SELECTION]: {
          categories: categories.length
            ? categories
            : transformCategories(state.categoriesStructure),
          pricePoints: pricePoints || DEFAULT_PRICE_POINTS,
        },
      };
      state.hasMarginBeenSet = !!resellerMargin;
      state.hasCategoriesBeenSet = !!categories.length;
    },

    setCurrentIndex: (state, action: PayloadAction<{ currentStepIndex: number }>) => {
      state.currentIndex = action.payload.currentStepIndex;
    },

    updateStoreSettingsIndex: (
      state,
      action: PayloadAction<{ currentStepIndex: number } & Record<string, number>>,
    ) => {
      if (action.payload.currentStepIndex < STEPS.length - 1) {
        const newStepIndex = action.payload.currentStepIndex + 1;
        const newStep = STEPS[newStepIndex];
        state.currentIndex = newStepIndex;
        routerOutside.navigate(newStep.route);
      }
    },

    setResellerMargin: (state, action: PayloadAction<{ resellerMargin: number }>) => {
      state.storeSettings[STEP_NAME.COMMISSION].resellerMargin =
        action.payload.resellerMargin / 100;
    },

    setResellerStoreName: (
      state,
      action: PayloadAction<{ resellerStoreName: string }>,
    ) => {
      state.storeSettings[STEP_NAME.PAGE_LINK].resellerStoreName =
        action.payload.resellerStoreName;
    },

    setActiveCategoryTab: (
      state,
      action: PayloadAction<{ activeCategoryTab: number }>,
    ) => {
      state.activeCategoryTab = action.payload.activeCategoryTab;
    },

    signContract: (state) => {
      state.storeSettings[STEP_NAME.SIGN_CONTRACT].isContractSigned = true;
    },

    setPrimaryColor: (state, action: PayloadAction<{ primaryColor: string }>) => {
      state.storeSettings[STEP_NAME.BRAND].primaryColor = action.payload.primaryColor;
    },

    setSecondaryColor: (state, action: PayloadAction<{ secondaryColor: string }>) => {
      state.storeSettings[STEP_NAME.BRAND].secondaryColor = action.payload.secondaryColor;
    },

    setLogoUrl: (state, action: PayloadAction<{ logo: Record<string, string> }>) => {
      state.storeSettings[STEP_NAME.BRAND].logo = action.payload.logo;
    },

    setFaviconUrl: (
      state,
      action: PayloadAction<{ favicon: Record<string, string> }>,
    ) => {
      state.storeSettings[STEP_NAME.BRAND].favicon = action.payload.favicon;
    },

    markContractAsRead: (state, action: PayloadAction<{ isContractRead: boolean }>) => {
      state.storeSettings[STEP_NAME.SIGN_CONTRACT].isContractRead =
        action.payload.isContractRead;
    },

    selectCategory: (
      state,
      action: PayloadAction<{ checked: boolean; parentIndex: number; index: number }>,
    ) => {
      const { checked, parentIndex, index } = action.payload;

      const categories = state.storeSettings[STEP_NAME.PRODUCT_SELECTION].categories;
      categories[parentIndex].items[index].isSelected = checked;
    },

    finishOnboarding: () => {
      routerOutside.navigate(ROUTES.INDEX, {
        state: { from: document.location.pathname },
      });
      return initialState;
    },

    setCategoryDisabled: (
      state,
      action: PayloadAction<{ isEnabled: boolean; activeCategoryTab: number }>,
    ) => {
      state.storeSettings[STEP_NAME.PRODUCT_SELECTION].categories[
        action.payload.activeCategoryTab
      ].isEnabled = action.payload.isEnabled;
    },

    setSuperInexpensive: (state, action: PayloadAction<{ isEnabled: boolean }>) => {
      state.storeSettings[STEP_NAME.PRODUCT_SELECTION].pricePoints.isSuperInexpensive =
        action.payload.isEnabled;
    },

    setBudget: (state, action: PayloadAction<{ isEnabled: boolean }>) => {
      state.storeSettings[STEP_NAME.PRODUCT_SELECTION].pricePoints.isBudget =
        action.payload.isEnabled;
    },

    setPremium: (state, action: PayloadAction<{ isEnabled: boolean }>) => {
      state.storeSettings[STEP_NAME.PRODUCT_SELECTION].pricePoints.isPremium =
        action.payload.isEnabled;
    },

    setStandard: (state, action: PayloadAction<{ isEnabled: boolean }>) => {
      state.storeSettings[STEP_NAME.PRODUCT_SELECTION].pricePoints.isStandard =
        action.payload.isEnabled;
    },

    setOnboardingFlowStatus: (state, action: PayloadAction<{ isCompleted: boolean }>) => {
      state.isCompleted = action.payload.isCompleted
        ? ONBOARDING_STATUS.COMPLETED
        : ONBOARDING_STATUS.UNCOMPLETED;
    },

    setCategories: (
      state,
      action: PayloadAction<{ categories: CategoriesStructure }>,
    ) => {
      state.categoriesStructure = action.payload.categories;
    },

    goToPrevStep: (state, action: PayloadAction<{ currentStepIndex: number }>) => {
      if (action.payload.currentStepIndex > 0) {
        const newStepIndex = action.payload.currentStepIndex - 1;
        const newStep = STEPS[newStepIndex];
        state.currentIndex = newStepIndex;
        routerOutside.navigate(newStep.route);
      }
    },
  },
});

export const storeCreationReducer = storeCreation.reducer;
export const {
  updateStoreSettingsIndex,
  setCurrentIndex,
  setStoreSettings,
  goToPrevStep,
  setResellerMargin,
  setResellerStoreName,
  signContract,
  selectCategory,
  setCategories,
  setActiveCategoryTab,
  markContractAsRead,
  setCategoryDisabled,
  setSuperInexpensive,
  setBudget,
  setPremium,
  setStandard,
  setPrimaryColor,
  setSecondaryColor,
  setOnboardingFlowStatus,
  setLogoUrl,
  setFaviconUrl,
  finishOnboarding,
} = storeCreation.actions;
