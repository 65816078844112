export enum AssignmentEntityType {
  CUSTOMER = 'CUSTOMER',
  LEAD = 'LEAD',
  PDF = 'PDF',
  CART_LINK = 'CART_LINK',
  INVOICE = 'INVOICE',
  ORDER = 'ORDER',
  ABANDONED_CHECKOUT = 'ABANDONED_CHECKOUT',
}

export interface IProposalToReAssign {
  currentAssignmentResellerId: string;
  entityId: string;
  entityType: AssignmentEntityType;
}

export const DESCRIPTIONS = {
  HAS_ASSIGNMENT:
    'Customer is already assigned to another admin. Would you like to reassign customer to yourself?',
  HAS_NOT_ASSIGNMENT:
    'Customer is not currently assigned to anyone. Would you like to assign customer to yourself?',
};

export const HEADERS = {
  HAS_ASSIGNMENT: 'Reassign Customer Confirmation',
  HAS_NOT_ASSIGNMENT: 'Assign Customer Confirmation',
};

export const BUTTONS = {
  HAS_ASSIGNMENT: 'Reassign',
  HAS_NOT_ASSIGNMENT: 'Assign',
};
