import { FC } from 'react';
import { Typography } from '@ezetech/swag-space-x';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import css from '../sign-contract.module.scss';
import { makeBold } from '../sign-contract.util';

export const IntellectualProperty: FC = () => {
  return (
    <>
      <Typography color={COLORS.colorPrimaryText} fontType="bodyMdBold">
        8. Intellectual Property and Licenses
      </Typography>
      <br />
      <br />
      <ol className={css.listLatin}>
        <li className={css.listItem}>
          {makeBold('Intellectual Property.')} You acknowledge and agree that the Services
          (including contents, features and functionality), Swag Space Content (as defined
          below), Swag Space Marks (as defined below), and any other proprietary rights
          related thereto or appearing on the Site, are owned by or licensed to Swag
          Space, its licensors or other providers of such material, and are protected by
          copyrights, trademarks, service marks, patent rights, trade secrets and/or other
          proprietary rights ("Intellectual Property"). As between you and Swag Space,
          Swag Space reserves all right, title, and interest not expressly granted, in and
          to the Services, the Swag Space Content and the Swag Space Marks. You may only
          use the Services and the Swag Space Content (and the Intellectual Property) as
          expressly permitted in these Terms and for no other purpose. The following are
          certain definitions used herein:
          <br />
          <br />
          {makeBold('“Content”')} refers collectively to Swag Space Content, Reseller
          Content or Customer Content.
          <br />
          <br />
          {makeBold('“Customer Content”')} means and includes, without limitation,
          information, photos, images, text, videos, sounds, comments, writings, notes,
          and other elements and materials, in any media or format now known or
          hereinafter devised, whether physical, electronic, digital, analog or otherwise,
          which is owned, submitted, uploaded, posted, displayed, or otherwise made
          available by your customers. Customer Content is owned or controlled by your
          customers.
          <br />
          <br />
          {makeBold('“Reseller Content”')} means and includes, without limitation,
          information, photos, images, text, videos, sounds, comments, writings, notes,
          and other elements and materials, in any media or format now known or
          hereinafter devised, whether physical, electronic, digital, analog or otherwise,
          which is owned, submitted, uploaded, posted, displayed, or otherwise made
          available by you as a Reseller. Reseller Content is owned or controlled by you.
          <br />
          <br />
          {makeBold('“Swag Space Content”')} means and includes, without limitation,
          information, data, photos, images, text, comments, artwork, fonts, designs,
          software, graphics, sounds, music, videos, interactive features, writings,
          notes, and other elements and materials, in any media or format now known or
          hereinafter devised, whether physical, electronic, digital, analog or otherwise
          that is owned or controlled by Swag Space.
          <br />
          <br />
          {makeBold('“Swag Space Marks”')} means and include, without limitation,
          trademarks, service marks, trade dress, domain names, logos, business and
          product names, slogans, and registrations and applications for registration
          thereof owned and/or in use or controlled by Swag Space or other entities.
          <br />
          <br />
          The Site as a whole is copyrighted as a collective work, and Content appearing
          on or accessible through the Site, is owned by or licensed to Swag Space and is
          likewise subject to copyright protection domestically and internationally.
          Content on the Site is provided to you AS IS for use only in connection with
          your authorized use of the Services and the Services and may not be used,
          copied, reproduced, distributed, transmitted, broadcast, displayed, sold,
          licensed, or otherwise exploited for any other purposes whatsoever without the
          prior written consent of Swag Space or the respective owners. You agree to not
          engage in the use, copying, or distribution of any of the Swag Space Content
          other than expressly permitted herein. You must abide by all copyright notices,
          information, or restrictions contained in or attached to any Content.
          <br />
          <br />
          You agree not to circumvent, disable or otherwise interfere with security
          related features of the Site or features that prevent or restrict use or copying
          of Swag Space Content or enforce limitations on use of the Site or the Swag
          Space Content therein. You agree to be liable for any damages resulting from any
          infringement of copyright, trademark, or other proprietary right, or any other
          harm resulting from your use of Services.
          <br />
          <br />
          All Swag Space Marks not owned by Swag Space that appear on the Site are the
          property of their respective owners and are used by permission. Ownership of
          Swag Space Marks and the goodwill associated therewith remains with us or with
          those other entities.
        </li>
        <br />
        <li className={css.listItem}>
          {makeBold('License to Use Services and Swag Space Content.')} Swag Space grants
          you a limited, non-exclusive, non-transferable and terminable license to access,
          use and display, as applicable, the Services and the Swag Space Content,
          provided that you comply with all of the terms and conditions of these Terms. If
          you breach these Terms, this license terminates automatically. Except as
          provided herein, you obtain no other rights under these Terms to the Services or
          Swag Space Content, including any related intellectual property rights. You may
          not modify, edit, copy, reproduce, create derivative works of, reverse engineer,
          alter, enhance or in any way exploit any of the Services or the Swag Space
          Content in any manner not authorized herein. Please refer to our Unauthorized
          Uses section below for more information pertaining to the use of the Services.
        </li>
        <br />
        <li className={css.listItem}>
          {makeBold('Reseller Content; Customer Content.')} You are solely responsible for
          all Reseller Content that you make available to Swag Space, including by
          uploading, posting, publishing or displaying via the Services or by emailing or
          otherwise making it available to Swag Space. You are also solely responsible for
          all Customer Content that your customers make available by uploading, posting,
          publishing or displaying via your Branded Storefront or by emailing or otherwise
          making it available to you. You are responsible for obtaining the license or
          other valid permission from your customers to make Customer Content available to
          us for use with the Services, including for reproducing it (and making any
          necessary modifications in connection therewith) on Blank Products.
        </li>
        <br />
        <li className={css.listItem}>
          {makeBold('License to Use Reseller Content and Customer Content.')} We do not
          claim ownership in Reseller Content or Customer Content provided to Swag Space,
          however, we do require a license to such Content. All ownership rights in
          Reseller Content and Customer Content remain with the applicable rights holder.
          You grant Swag Space a non-exclusive, worldwide, royalty-free, fully paid-up,
          transferable, sublicensable right and license to reproduce, distribute copies
          of, prepare derivative works based upon, publicly perform, publicly display and
          otherwise use Reseller Content and Customer Content in connection with the
          provision of the Customized Products and the operation of the Site and Services
          in any form, medium or technology now known or later developed, including,
          without limitation:
          <br />
          <br />
          <ul className={css.listPoint}>
            <li className={css.listItem}>
              reproducing Customer Content or Reseller Content on Blank Products;
            </li>
            <li className={css.listItem}>
              modifying, with your approval, Customer Content or Reseller Content for
              purposes of improving their printing quality and or display-ability, or
              enhancing their appearance or placement on Blank Products;
            </li>
            <li className={css.listItem}>
              displaying Reseller Content and Customer Content, as applicable, on your
              Branded Storefront; and
            </li>
            <li className={css.listItem}>
              allowing you to design, market, make available for sale, sell, produce,
              reproduce and distribute Customized Products.
            </li>
          </ul>
          <br />
          <br />
          You represent and warrant to Swag Space that:
          <br />
          <br />
          <ul className={css.listPoint}>
            <li className={css.listItem}>
              you either own the Reseller Content or have a license or other valid
              permission from the applicable rights owner(s) to make your Reseller Content
              available to us for use with the Services and to grant the license set forth
              above;
            </li>
            <li className={css.listItem}>
              you have a license or other valid permission from the applicable rights
              owner(s) to make Customer Content available to us for use with the Services
              and to grant the license set forth above;
            </li>
            <li className={css.listItem}>
              the Reseller Content and Customer Content does not infringe the copyright,
              trademark, patent, trade secret, or other intellectual property rights,
              privacy rights, or any other legal or moral rights of any third party;
            </li>
            <li className={css.listItem}>
              the Reseller Content and Customer Content does not contain any viruses,
              worms, spyware, adware, or other potentially damaging programs or files; and
            </li>
            <li className={css.listItem}>
              the Reseller Content and Customer Content does not violate any law, rule,
              regulation and/or self-regulatory practices, (including, but not limited to,
              those governing export control, consumer protection, unfair competition,
              anti-discrimination, or false advertising).
            </li>
          </ul>
          <br />
          <br />
          You acknowledge that Swag Space does not pre-screen Content. Notwithstanding the
          foregoing, Swag Space may, but is not obligated to, pre-screen Reseller Content
          or Customer Content or monitor any area of the Site through which Content may be
          submitted. We are not required to host, display, or distribute any Content on or
          through the Site and may remove at any time or refuse any Content for any
          reason. We are not responsible for any loss, theft, or damage of any kind to any
          Content. Further, you agree that we may freely disclose Reseller Content or
          Customer Content that is not Confidential Information to any third party absent
          any obligation of confidence on the part of the recipient.
        </li>
        <br />
        <li className={css.listItem}>
          {makeBold('Feedback.')} Notwithstanding anything to the contrary herein, any
          questions, comments, suggestions, ideas, feedback, reviews, or other information
          about the Services ({makeBold('“Feedback”')}), provided by you to Swag Space is
          non-confidential and Swag Space will be entitled to the unrestricted use and
          dissemination of any Feedback for any purpose, commercial or otherwise, without
          acknowledgment, attribution, or compensation to you.
        </li>
      </ol>
      <br />
    </>
  );
};
