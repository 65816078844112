import { createSelector } from 'reselect';
import { deepEqual } from 'utils/deep-equal';
import {
  CategoriesStructureWithSelections,
  CUSTOM_DOMAIN_STATUSES,
  ICustomDomain,
  IStoreSettingsBack,
} from 'interfaces/store-creation.interfaces';
import { IPricePoints } from 'interfaces/store-creation.interfaces';
import { hasSelectedCategoriesAndPrices } from 'utils/has-selected-categories.util';
import { hoursOperationComplete } from 'utils/settings/hours-operation-complete';
import {
  IHoursOperations,
  IInvoice,
  IUpdateWhatWeOfferSettings,
  IHomepageSettings,
} from 'interfaces';
import {
  marginCustomerPriceFn,
  marginResellerIncomeFn,
} from 'utils/margin-calculation.util';
import { areErrorsExistsInForm } from 'utils/settings/forms';
import {
  CountriesStatesSelect,
  TABS,
  TABS_ID_ROUTES_MAP,
} from 'constants/settings.constants';
import { mapMarginToDB, mapPayoutToInvoice, mapSocialToDB } from 'utils/settings/mappers';
import { canRoute } from 'boot/ability';

import { isObjectEmpty } from 'utils/is-object-empty.util';
import { RootState } from '../store';
import { ISettingsSlice } from '../slices/settings.slice';
import { productsSelector } from './products.selectors';
import { technicalDataSelector } from './technical-data.selectors';

const MAX_PRODUCTS_TO_SELECT = 9;

export const settingsSelector = (state: RootState) => state.settings;

export const companyDetailsSelector = createSelector(settingsSelector, (state) => ({
  email: state.company.email,
  phone: state.company.phone ?? '',
  name: state.company.name ?? '',
  showEmail: state.company.showEmail ?? false,
  showPhone: state.company.showPhone ?? false,
}));

export const companyDetailsErrorsSelector = createSelector(settingsSelector, (state) => ({
  email: state.companyErrors.email,
  phone: state.companyErrors.phone,
  name: state.companyErrors.name,
}));

export const billingAddressSelector = createSelector(
  settingsSelector,
  (state) => state.billingAddress,
);

export const billingAddressErrorsSelector = createSelector(
  settingsSelector,
  (state) => state.billingAddressErrors,
);

export const isBillingAddressValidSelector = createSelector(
  billingAddressSelector,
  billingAddressErrorsSelector,
  (values, errors) => {
    const isCountryWithStates = [
      CountriesStatesSelect.CA,
      CountriesStatesSelect.US,
    ].includes(values.billingCountry as CountriesStatesSelect);
    const requiredFields = [
      'billingAddress',
      'billingCity',
      'billingZip',
      'billingCountry',
    ];

    if (isCountryWithStates) {
      requiredFields.push('billingState');
    }

    const areValuesNotEmpty = requiredFields.every(
      (key) =>
        !!values[
          key as
            | 'billingAddress'
            | 'billingCity'
            | 'billingState'
            | 'billingZip'
            | 'billingCountry'
        ],
    );

    return areValuesNotEmpty && !areErrorsExistsInForm(errors);
  },
);

export const initialPayoutSelector = createSelector(
  settingsSelector,
  (state) => state.initialPayout,
);
export const payoutSelector = createSelector(settingsSelector, (state) => state.payout);

export const payoutErrorsSelector = createSelector(
  settingsSelector,
  (state) => state.payoutErrors,
);

export const invoiceSelector = createSelector(
  settingsSelector,
  (state): IInvoice => state.invoice,
);

export const invoiceVisibleSelector = createSelector(
  settingsSelector,
  (state): IInvoice => {
    if (state.invoice.invoiceAsInPayout) {
      return mapPayoutToInvoice(state.payout);
    } else {
      return {
        invoiceBIC: state.invoice.invoiceBIC,
        invoiceAccountType: state.invoice.invoiceAccountType,
        invoiceBankName: state.invoice.invoiceBankName,
        invoiceBankCountry: state.invoice.invoiceBankCountry,
        invoiceBankAccountNumber: state.invoice.invoiceBankAccountNumber,
        invoiceAccountBeneficiaryName: state.invoice.invoiceAccountBeneficiaryName,
        invoiceRoutingNumber: state.invoice.invoiceRoutingNumber,
        invoiceAsInPayout: false,
      };
    }
  },
);

export const invoiceErrorsSelector = createSelector(
  settingsSelector,
  (state) => state.invoiceErrors,
);

export const isInvoiceValidValidSelector = createSelector(
  invoiceVisibleSelector,
  invoiceErrorsSelector,
  (values, errors) => {
    // ignore invoiceAsInPayout flag
    const validBIC = values.invoiceBankCountry !== 'US' ? values.invoiceBIC : true;

    const areValuesNotEmpty = Boolean(
      values.invoiceBankCountry &&
        values.invoiceBankName &&
        values.invoiceAccountType &&
        values.invoiceAccountBeneficiaryName &&
        values.invoiceBankAccountNumber &&
        validBIC,
    );

    return areValuesNotEmpty && !areErrorsExistsInForm(errors);
  },
);

export const resellerSettingsCategoriesSelector = createSelector(
  settingsSelector,
  (state) => state.companySettings.categories as CategoriesStructureWithSelections,
);

export const resellerSettingsCategoriesStructureSelector = createSelector(
  settingsSelector,
  (state) =>
    state.companySettings.categoriesStructure as CategoriesStructureWithSelections,
);

export const resellerSettingsActiveCategoryTabSelector = createSelector(
  settingsSelector,
  (state) => state.activeCategoryTab,
);

export const isCurrentResellerSettingsCategorySelector = createSelector(
  resellerSettingsCategoriesSelector,
  resellerSettingsActiveCategoryTabSelector,
  (categories, index) => categories?.[index],
);

export const isCurrentResellerSettingsCategoryEnabledSelector = createSelector(
  isCurrentResellerSettingsCategorySelector,
  (category) => Boolean(category?.isEnabled),
);

export const categoriesPricePointsSelector = createSelector(
  settingsSelector,
  (state) => state.companySettings.productsPricePoints as IPricePoints,
);

export const isReviewMockupsByCustomerAllowedSelector = createSelector(
  settingsSelector,
  (state) => !!state.companySettings.isReviewMockupsByCustomerAllowed,
);

export const isReviewPantonesByCustomerAllowedSelector = createSelector(
  settingsSelector,
  (state) => !!state.companySettings.isReviewPantonesByCustomerAllowed,
);

export const resellerSettingsBudgetEnabledSelector = createSelector(
  categoriesPricePointsSelector,
  (pricePoints) => !!pricePoints?.isBudget,
);

export const resellerSettingsPremiumEnabledSelector = createSelector(
  categoriesPricePointsSelector,
  (pricePoints) => !!pricePoints?.isPremium,
);

export const resellerSettingsStandardEnabledSelector = createSelector(
  categoriesPricePointsSelector,
  (pricePoints) => !!pricePoints?.isStandard,
);

export const resellerSettingsSuperInexpensiveEnabledSelector = createSelector(
  categoriesPricePointsSelector,
  (pricePoints) => !!pricePoints?.isSuperInexpensive,
);

export const storePreferencesSelector = createSelector(
  settingsSelector,
  ({ companySettings: { brandName, logoLinkUrl, homepageTitle } }: ISettingsSlice) => ({
    brandName,
    logoLinkUrl,
    homepageTitle,
  }),
);

export const storePreferencesErrorSelector = createSelector(
  settingsSelector,
  (state) => state.storePreferencesError,
);

export const storeIntercomAppIdSelector = createSelector(
  settingsSelector,
  (state) => state.companySettings.intercomAppId,
);

export const storeCustomCodeHeaderSelector = createSelector(
  settingsSelector,
  (state) => state.companySettings.customCodeHeader,
);

export const storeCustomCodeBodySelector = createSelector(
  settingsSelector,
  (state) => state.companySettings.customCodeBody,
);

export const storeIntercomAppIdErrorSelector = createSelector(
  settingsSelector,
  (state) => state.intercomAppIdError,
);

export const socialSelector = createSelector(settingsSelector, (state) => state.social);

export const socialErrorsSelector = createSelector(
  settingsSelector,
  (state) => state.socialErrors,
);

export const brandColorsSelector = createSelector(settingsSelector, (state) => ({
  primaryColor: state.brand.primaryColor,
  secondaryColor: state.brand.secondaryColor,
}));

export const brandLogoSelector = createSelector(
  settingsSelector,
  (state) => state.brand.logo?.url ?? undefined,
);

export const selectModifiedCompanySettings = createSelector(
  settingsSelector,
  ({
    initialCompanySettings,
    companySettings,
    ...state
  }: ISettingsSlice): Partial<IStoreSettingsBack> => {
    const editedFields: Partial<IStoreSettingsBack> = {};

    const compareAndAssign = (
      fieldName: keyof IStoreSettingsBack,
      initialValue = initialCompanySettings[fieldName],
      currentValue = companySettings[fieldName],
    ) =>
      initialValue !== currentValue &&
      Object.assign(editedFields, { [fieldName]: currentValue });

    const fieldsToCompare = [
      'customCodeHeader',
      'customCodeBody',
      'intercomAppId',
      'timezone',
      'isHomepageActive',
      'isWhatWeOfferActive',
      'assignmentFlowEnabled',
      'brandName',
      'homepageTitle',
      'logoLinkUrl',
      'categories',
      'productsPricePoints',
      'isReviewMockupsByCustomerAllowed',
      'isReviewPantonesByCustomerAllowed',
      'defaultSorting',
    ] as const;

    fieldsToCompare.forEach((field) => compareAndAssign(field));

    const trimmedFieldsToCompare = ['termsOfServiceHTML', 'privacyPolicyHTML'] as const;
    trimmedFieldsToCompare.forEach((field) =>
      compareAndAssign(
        field,
        initialCompanySettings[field]?.trim(),
        companySettings[field]?.trim(),
      ),
    );

    if (!deepEqual(state.initialSocial, state.social)) {
      Object.assign(editedFields, { socials: mapSocialToDB(state.social) });
    }

    if (!deepEqual(state.initialBrand, state.brand)) {
      Object.assign(editedFields, { ...state.brand });
    }

    if (state.initialMarginValue !== state.marginValue) {
      editedFields.margin = mapMarginToDB(state.marginValue).margin;
    }

    return editedFields;
  },
);

export const detailsFormEditedSelector = createSelector(settingsSelector, (state) => {
  return (
    !deepEqual(state.initialCompany as never, state.company as never) ||
    !deepEqual(state.initialBillingAddress as never, state.billingAddress as never) ||
    !deepEqual(state.initialSocial as never, state.social as never) ||
    state.initialCompanySettings.brandName !== state.companySettings.brandName ||
    state.initialCompanySettings.homepageTitle !== state.companySettings.homepageTitle ||
    state.initialCompanySettings.logoLinkUrl !== state.companySettings.logoLinkUrl ||
    state.initialCompanySettings.intercomAppId !== state.companySettings.intercomAppId ||
    state.initialCompanySettings.customCodeHeader !==
      state.companySettings.customCodeHeader ||
    state.initialCompanySettings.customCodeBody !==
      state.companySettings.customCodeBody ||
    state.initialCompanySettings.privacyPolicyHTML?.trim() !==
      state.companySettings.privacyPolicyHTML?.trim() ||
    state.initialCompanySettings.termsOfServiceHTML?.trim() !==
      state.companySettings.termsOfServiceHTML?.trim() ||
    !deepEqual(state.brand as never, state.initialBrand as never) ||
    state.initialCompanySettings.isHomepageActive !==
      state.companySettings.isHomepageActive ||
    state.initialCompanySettings.isWhatWeOfferActive !==
      state.companySettings.isWhatWeOfferActive ||
    state.initialCompanySettings.assignmentFlowEnabled !==
      state.companySettings.assignmentFlowEnabled
  );
});

export const selectModifiedProductSettingsAndCommission = createSelector(
  settingsSelector,
  ({
    initialCompanySettings,
    companySettings,
    marginValue,
    initialMarginValue,
  }: ISettingsSlice): Partial<IStoreSettingsBack> => {
    const editedFields: Partial<IStoreSettingsBack> = {};

    const compareAndAssign = (
      fieldName: keyof IStoreSettingsBack,
      initialValue = initialCompanySettings[fieldName],
      currentValue = companySettings[fieldName],
    ) =>
      !deepEqual(initialValue, currentValue) &&
      Object.assign(editedFields, { [fieldName]: currentValue });

    const fieldsToCompare = [
      'categories',
      'productsPricePoints',
      'isReviewMockupsByCustomerAllowed',
      'isReviewPantonesByCustomerAllowed',
      'defaultSorting',
    ] as const;

    fieldsToCompare.forEach((field) => compareAndAssign(field));

    if (marginValue !== initialMarginValue) {
      editedFields.margin = mapMarginToDB(marginValue).margin;
    }

    return editedFields;
  },
);

export const selectIsProductSettingsAndCommissionEdited = createSelector(
  selectModifiedProductSettingsAndCommission,
  (editedFields: Partial<IStoreSettingsBack>) => !isObjectEmpty(editedFields),
);

export const productAndMarginEditedSelector = createSelector(
  settingsSelector,
  (state) => {
    return (
      (state?.initialCompanySettings?.categories &&
      state?.companySettings?.productsPricePoints
        ? !deepEqual(
            state.initialCompanySettings.categories as never,
            state.companySettings.categories as never,
          )
        : false) ||
      (state?.initialCompanySettings?.categories &&
      state?.companySettings?.productsPricePoints
        ? !deepEqual(
            state.initialCompanySettings.productsPricePoints as never,
            state.companySettings.productsPricePoints as never,
          )
        : false) ||
      state.marginValue !== state.initialMarginValue ||
      state.initialCompanySettings.isReviewMockupsByCustomerAllowed !==
        state.companySettings.isReviewMockupsByCustomerAllowed ||
      state.initialCompanySettings.isReviewPantonesByCustomerAllowed !==
        state.companySettings.isReviewPantonesByCustomerAllowed ||
      state.initialCompanySettings.defaultSorting !== state.companySettings.defaultSorting
    );
  },
);

export const paymentEditedSelector = createSelector(settingsSelector, (state) => {
  return (
    !deepEqual(state.initialPayout as never, state.payout as never) ||
    !deepEqual(state.initialInvoice as never, state.invoice as never)
  );
});

export const paymentPayoutEditedSelector = createSelector(settingsSelector, (state) => {
  return !deepEqual(state.initialPayout as never, state.payout as never);
});

export const paymentInvoiceEditedSelector = createSelector(settingsSelector, (state) => {
  return !deepEqual(state.initialInvoice as never, state.invoice as never);
});

export const companyAndBrandSaveDisabledSelector = createSelector(
  settingsSelector,
  (state) => {
    return (
      Object.values(state.companySettingsErrors).filter((value) => value).length !== 0 ||
      Object.values(state.companyErrors).filter((value) => value).length !== 0 ||
      Object.values(state.billingAddressErrors).filter((value) => value).length !== 0 ||
      Object.values(state.socialErrors).filter((value) => value).length !== 0 ||
      Object.values(state.storePreferencesError).filter((value) => value).length !== 0
    );
  },
);

export const paymentSaveDisabledSelector = createSelector(settingsSelector, (state) => {
  const arePayoutErrorsExist =
    Object.values(state.payoutErrors).filter((value) => value).length !== 0;
  const areInvoiceErrorsExist =
    Object.values(state.invoiceErrors).filter((value) => value).length !== 0;

  return arePayoutErrorsExist || areInvoiceErrorsExist;
});

export const productAndMarginSaveDisabledSelector = createSelector(
  resellerSettingsCategoriesSelector,
  categoriesPricePointsSelector,
  settingsSelector,
  (categories, pricePoints, settings) =>
    !hasSelectedCategoriesAndPrices(categories, pricePoints) || !!settings.marginError,
);

export const hoursOperationsSelector = createSelector(
  settingsSelector,
  (settings) => settings.companySettings.hoursOperations as IHoursOperations[],
);

export const timezoneSelector = createSelector(
  settingsSelector,
  (settings) => settings.companySettings.timezone,
);

export const timezoneSaveDisabledSelector = createSelector(
  settingsSelector,
  (settings) => !settings.companySettings.timezone,
);

export const hoursOperationsSaveDisabledSelector = createSelector(
  hoursOperationsSelector,
  (hours) => !hoursOperationComplete(hours),
);

export const hoursOperationEditedSelector = createSelector(settingsSelector, (state) => {
  return !deepEqual(
    state.initialCompanySettings.hoursOperations as never,
    state.companySettings.hoursOperations as never,
  );
});

export const timezoneEditedSelector = createSelector(settingsSelector, (state) => {
  return state.initialCompanySettings.timezone !== state.companySettings.timezone;
});

export const marginValueSelector = createSelector(settingsSelector, (state) => {
  return state.marginValue;
});

export const marginErrorSelector = createSelector(settingsSelector, (state) => {
  return state.marginError;
});

export const marginPercentSelector = createSelector(marginValueSelector, (margin) => {
  return Number(margin);
});

export const marginProductCostSelector = createSelector(
  marginPercentSelector,
  marginCustomerPriceFn,
);

export const marginYouMakeSelector = createSelector(
  marginPercentSelector,
  marginProductCostSelector,
  marginResellerIncomeFn,
);
export const isAnySettingsEditedSelector = createSelector(
  detailsFormEditedSelector,
  productAndMarginEditedSelector,
  hoursOperationEditedSelector,
  timezoneEditedSelector,
  paymentEditedSelector,
  (...edited) => {
    return edited.some((val) => val);
  },
);
export const cardSelector = createSelector(settingsSelector, (state) => {
  return state.card;
});

export const whatWeOfferSelector = createSelector(
  settingsSelector,
  (state): IUpdateWhatWeOfferSettings => {
    return state.whatWeOfferSettings;
  },
);

export const homePageSelector = createSelector(
  settingsSelector,
  (state): IHomepageSettings => {
    return state.homepageSettings;
  },
);

export const storeLinkSelector = createSelector(settingsSelector, (state): string => {
  return state.companySettings.storeLink || '';
});

export const storeTermsOfServiceHTMLSelector = createSelector(
  settingsSelector,
  (state) => state.companySettings.termsOfServiceHTML,
);

export const storePrivacyPolicyHTMLSelector = createSelector(
  settingsSelector,
  (state) => state.companySettings.privacyPolicyHTML,
);

export const storeInitialPrivacyPolicyHTMLSelector = createSelector(
  settingsSelector,
  (state) => state.initialCompanySettings.privacyPolicyHTML,
);

export const storeInitialTermsOfServiceHTMLSelector = createSelector(
  settingsSelector,
  (state) => state.initialCompanySettings.termsOfServiceHTML,
);

export const isContractSignedSelector = createSelector(
  settingsSelector,
  (state): boolean => {
    return !!state.companySettings.isContractSigned;
  },
);

export const domainSettingsSelector = createSelector(
  settingsSelector,
  (state): ICustomDomain | undefined => {
    if (state.companySettings.customDomain) {
      return state.companySettings.customDomain;
    }
  },
);

export const manageDomainBtnTextSelector = createSelector(
  domainSettingsSelector,
  (domain): string => {
    if (
      domain &&
      (domain.status === CUSTOM_DOMAIN_STATUSES.VERIFIED ||
        domain.status === CUSTOM_DOMAIN_STATUSES.PROLONGING)
    ) {
      return 'Edit Custom Domain';
    }
    return 'Connect Custom Domain';
  },
);

export const isEditCustomDomainMode = createSelector(
  domainSettingsSelector,
  (domain): boolean => {
    if (!domain) {
      return false;
    }
    return (
      domain.status === CUSTOM_DOMAIN_STATUSES.VERIFIED ||
      domain.status === CUSTOM_DOMAIN_STATUSES.PROLONGING
    );
  },
);

export const isBackAllowedSelector = createSelector(
  domainSettingsSelector,
  (domain): boolean => {
    if (!domain) {
      return false;
    }
    return ![
      CUSTOM_DOMAIN_STATUSES.DNS_VERIFIED,
      CUSTOM_DOMAIN_STATUSES.VERIFIED,
      CUSTOM_DOMAIN_STATUSES.PROLONGING,
    ].includes(domain.status);
  },
);

export const selectedHomepageProductsListSelector = createSelector(
  settingsSelector,
  (state) => state.selectedHomepageProducts,
);

export const isSelectionDisabledSelector = createSelector(
  selectedHomepageProductsListSelector,
  (products) => products.length >= MAX_PRODUCTS_TO_SELECT,
);

export const homepageProductsListSelector = createSelector(
  productsSelector,
  selectedHomepageProductsListSelector,
  (state, selectedHomepage) => {
    const selectedMap = selectedHomepage.reduce<Record<string, string>>(
      (all, next) => ({ ...all, [next.publicId]: next.publicId }),
      {},
    );
    const list = state.homepageList.map((p) => ({
      ...p,
      isChecked: !!selectedMap[p.publicId],
    }));

    return list;
  },
);

export const isHomepageActiveSelector = createSelector(
  settingsSelector,
  ({ companySettings: { isHomepageActive } }: ISettingsSlice): boolean =>
    !!isHomepageActive,
);

export const isWhatWeOfferActiveSelector = createSelector(
  settingsSelector,
  ({ companySettings: { isWhatWeOfferActive } }: ISettingsSlice): boolean =>
    !!isWhatWeOfferActive,
);

export const ownerSelector = createSelector(
  settingsSelector,
  (state) => state.companySettings.owner,
);

export const settingsLayoutTabsSelector = createSelector(
  technicalDataSelector,
  (technicalData) => {
    return TABS.filter((tab) => {
      if (tab.featureToggle && !technicalData.featureFlagList[tab.featureToggle]) {
        return false;
      }

      const route = TABS_ID_ROUTES_MAP[tab.id];
      const result = canRoute(route);
      return result.isAllowed && !result.redirect;
    });
  },
);

export const assignmentFlowEnabledSelector = createSelector(
  settingsSelector,
  (state) => state.companySettings.assignmentFlowEnabled || false,
);

export const defaultSortingSelector = createSelector(
  settingsSelector,
  (state) => state.companySettings.defaultSorting,
);
