import classnames from 'classnames';
import RemoveIcon from 'assets/svg/remove-icon.svg';
import { IPantoneColorsProps } from '../review-colors.popup';
import classes from '../review-colors.popup.module.scss';

interface IColorsViewProps {
  colors: IPantoneColorsProps[];
  handleRemove: (color: IPantoneColorsProps) => void;
}
export const ColorsView = ({ colors, handleRemove }: IColorsViewProps) => (
  <div className={classes.scrollableBlock}>
    {colors.map((color) => (
      <div className={classes.colorPicker} key={color.id}>
        <span
          className={classnames(classes.color, {
            [classes.noColor]: color.hex.length < 2,
          })}
          style={{
            backgroundColor: color.hex ? color.hex : 'transparent',
          }}
        ></span>
        <span className={classes.colorName}>{color.pms}</span>
        <div>
          {colors.length > 1 && (
            <img src={RemoveIcon} onClick={() => handleRemove(color)} />
          )}
        </div>
      </div>
    ))}
  </div>
);
