import {
  PdfPresentationEditor,
  PdfPresentationEditorReview,
  PdfPresentationEditorUpdateBody,
} from 'interfaces/pdf-presentation';
import { REVIEWS_LENGTH } from 'constants/pdf-presentation.constants';
import { IPresentationEditorState } from '../../redux/slices/pdf-presentation-editor.slice';

const fieldsToRemoveToDbSave = ['id'];

export const mapEditableToDB = (editable: PdfPresentationEditorUpdateBody) => {
  const toDb = Object.fromEntries(
    Object.entries(editable).filter(([key]) => !fieldsToRemoveToDbSave.includes(key)),
  );
  return {
    ...toDb,
    template: 'template-box',
  };
};

const fieldsToRemoveFromEditable = [
  'deletedAt',
  'createdAt',
  'updatedAt',
  'publicId',
  'companyId',
  'reviews',
];
export const mapDBToStore = (data: PdfPresentationEditor) => {
  const state: Pick<IPresentationEditorState, 'editable' | 'loadedConfig'> = {
    loadedConfig: data,
    editable: {
      ...Object.fromEntries(
        Object.entries(data).filter(([key]) => !fieldsToRemoveFromEditable.includes(key)),
      ),
      reviews: Array.from({ length: REVIEWS_LENGTH })
        .fill(null)
        .map((el, i) =>
          data.reviews?.[i]
            ? {
                text:
                  data.reviews?.[i]?.text ?? (el as PdfPresentationEditorReview)?.text,
                logo:
                  data.reviews?.[i]?.logo ?? (el as PdfPresentationEditorReview)?.logo,
              }
            : null,
        ),
    } as PdfPresentationEditorUpdateBody,
  };
  return state;
};
