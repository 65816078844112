import { FC } from 'react';
import { Typography } from '@ezetech/swag-space-x';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { useAppSelector } from 'redux/store';
import { selectedCartProductsSelector } from 'redux/selectors/carts.selector';
import { selectedPresentationProductsSelector } from 'redux/selectors/presentations.selector';
import { selectedCartProductsSelector as selectedInvoiceProductsSelector } from 'redux/selectors/invoices.selector';
import { selectedCustomerCompanyItemSelector } from 'redux/selectors/customer-company.selectors';
import { ICartProductDetails } from 'interfaces/cart.interfaces';
import { ProductListVariants } from './product-list-variants';
import { IProductsListTooltip } from './products-list-tooltip.interface';
import css from './products-list-tooltip.module.scss';

export const ProductsListTooltip: FC<IProductsListTooltip> = ({
  cartId,
  presentationId,
  invoiceId,
  customerCompanyItemId,
}: IProductsListTooltip) => {
  let products;
  let product;
  switch (true) {
    case !!presentationId:
      products = useAppSelector(selectedPresentationProductsSelector(presentationId));
      break;
    case !!invoiceId:
      products = useAppSelector(selectedInvoiceProductsSelector(invoiceId));
      break;
    case !!customerCompanyItemId:
      product = useAppSelector(
        selectedCustomerCompanyItemSelector(customerCompanyItemId),
      );
      products = product ? [product] : [];
      break;
    default:
      products = useAppSelector(selectedCartProductsSelector(cartId));
  }
  const showExtendFields = !!customerCompanyItemId;
  const extendedFields = ({
    pricePerItem,
    productionTime,
    estInHand,
  }: Partial<ICartProductDetails>) => {
    return (
      <>
        {!!pricePerItem && (
          <Typography
            fontType="bodySm"
            lineHeight="143%"
            color={COLORS.colorTypographyHeadings}
          >
            Cost per Item: {pricePerItem}
          </Typography>
        )}
        {!!productionTime && (
          <Typography
            fontType="bodySm"
            lineHeight="143%"
            color={COLORS.colorTypographyHeadings}
          >
            Production time: {productionTime}
          </Typography>
        )}
        {!!estInHand && (
          <Typography
            fontType="bodySm"
            lineHeight="143%"
            color={COLORS.colorTypographyHeadings}
          >
            Est In Hand: {estInHand}
          </Typography>
        )}
      </>
    );
  };
  return (
    <div>
      {products.map(
        (
          {
            id,
            name,
            quantity: productQuantity,
            variants,
            pricePerItem,
            productionTime,
            estInHand,
          },
          i,
        ) => (
          <div className={css.row} key={`${id}-${i}`}>
            {i !== 0 && <div className={css.divider} />}
            <Typography
              fontType="bodySmBold"
              lineHeight="143%"
              color={COLORS.colorTypographyHeadings}
              className={css.name}
            >
              {name}
            </Typography>
            {!variants.length && productQuantity > 0 && (
              <Typography
                fontType="bodySm"
                lineHeight="143%"
                color={COLORS.colorTypographyHeadings}
              >
                Quantity: {productQuantity}
              </Typography>
            )}
            <ProductListVariants variants={variants} />
            {showExtendFields &&
              extendedFields({ pricePerItem, productionTime, estInHand })}
          </div>
        ),
      )}
    </div>
  );
};
