import { IconType } from './type';

export const ArrowUpSmallIcon = ({
  className,
  fillColor = '#258750',
  width = '16px',
  height = '12px',
}: IconType): JSX.Element => {
  const style = { width, height, lineHeight: 0 };
  return (
    <div style={style} className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"
          fill={fillColor}
        />
      </svg>
    </div>
  );
};
