import { useCallback, useEffect } from 'react';
import { RainbowBox } from '@ezetech/swag-space-x';
import { BoxWidgetEnum } from 'enums/box-widget-enum';
import { BoxWidget } from 'components/box-widget';
import { ColoredContainerHeader } from 'components/colored-container-header';
import { useLoginMutation } from 'redux/api/auth.api';
import { setInvitation } from 'redux/slices/user.slice';
import {
  loginFormInformationSelector,
  invitationSelector,
} from 'redux/selectors/user.selectors';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { useQuery } from 'hooks/use-query.hook';
import { UserLoginType } from 'interfaces';
import { LoginForm } from './form/login-form';
import css from './login.module.scss';

export function Login() {
  const [login, { isLoading }] = useLoginMutation();
  const query = useQuery();
  const dispatch = useAppDispatch();
  const { title, description } = useAppSelector(loginFormInformationSelector);
  const invitation = useAppSelector(invitationSelector);

  useEffect(() => {
    const param = query.get('invitation');

    if (param) {
      dispatch(setInvitation(param));
    }
  }, [query]);

  const onSubmit = useCallback(
    (data: UserLoginType) => {
      const payload = {
        ...data,
      };

      if (invitation) {
        payload.invitation = invitation;
      }

      login(payload);
    },
    [invitation],
  );

  return (
    <RainbowBox borderWidth={8} innerClassName={css.rainbowBlock}>
      <div className={css.login}>
        <ColoredContainerHeader title={title} description={description} />
        <BoxWidget type={BoxWidgetEnum.REGULAR} className={css.formContainer}>
          <LoginForm hideForgot={!!invitation} onSubmit={onSubmit} loading={isLoading} />
        </BoxWidget>
      </div>
    </RainbowBox>
  );
}
