import { SwagButton } from '@ezetech/swag-space-x';
import { LANDING_URL } from 'utils/urls.util';
import { ColoredContainerHeader } from 'components/colored-container-header';

import css from './submitted.module.scss';

export function Submitted() {
  const onClick = () => {
    window.location.assign(LANDING_URL);
  };
  return (
    <div className={css.submitted}>
      <div>
        <ColoredContainerHeader
          title="We've got you covered!"
          description="If a user with this email exists we will send an email with password reset instructions."
        />
      </div>
      <div>
        <SwagButton type="primary" size="xl" fullWidth onClick={onClick}>
          Back to Swag Space
        </SwagButton>
      </div>
    </div>
  );
}
