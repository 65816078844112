import {
  Dialog,
  DialogContentWrapper,
  DialogTitle,
  DialogContent,
  DialogFooter,
  SwagButton,
} from '@ezetech/swag-space-x';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { closePopup } from 'redux/slices/modals.slice';
import {
  useCheckCompanyDetailsMutation,
  usePatchBillingAddressMutation,
} from 'redux/api/settings.api';
import { useUpdateUserMutation } from 'redux/api/user.api';
import {
  setBillingAddressErrors,
  clearBillingAddress,
} from 'redux/slices/settings.slice';
import {
  billingAddressSelector,
  isBillingAddressValidSelector,
} from 'redux/selectors/settings.selectors';
import { editedCustomerCompanyDetailsSelector } from 'redux/selectors/customer-details.selector';
import { AddressForm } from 'pages/settings/company-and-brand/forms';
import { BillingAddressPopupProps } from 'interfaces/popup.interface';
import css from './billing-address-popup.module.scss';

export const BillingAddressPopup = ({
  isOpen,
  paymentMethod,
  setPaymentMethod,
  checkPaymentDetails,
  email,
}: BillingAddressPopupProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const values = useAppSelector(billingAddressSelector);
  const isFormValid = useAppSelector(isBillingAddressValidSelector);
  const user = useAppSelector(editedCustomerCompanyDetailsSelector);
  const [updateUser, { isLoading: updateLoading }] = useUpdateUserMutation();
  const [checkCompanyDetails] = useCheckCompanyDetailsMutation();
  const [patchBillingAddress, { isLoading: patchLoading }] =
    usePatchBillingAddressMutation();
  const isLoading = updateLoading || patchLoading;

  const onCloseClick = () => {
    dispatch(closePopup());
    dispatch(setBillingAddressErrors({}));
    dispatch(clearBillingAddress());
  };
  const onSaveClick = async () => {
    if (!isFormValid || isLoading) {
      return;
    }

    await patchBillingAddress(values);
    if (paymentMethod) {
      await updateUser({
        id: user.userId,
        paymentMethod,
        setPaymentMethod,
      });
    }
    if (checkPaymentDetails) {
      checkCompanyDetails({ email });
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onCloseClick}>
      <DialogContentWrapper
        className={css.dialogContent}
        overlayClassName={css.dialogOverlay}
      >
        <DialogTitle>Billing address</DialogTitle>
        <DialogContent className={css.dialogBody}>
          <AddressForm />
        </DialogContent>
        <DialogFooter showDivider={false} className={css.footer}>
          <SwagButton className={css.button} type="outlined" onClick={onCloseClick}>
            Cancel
          </SwagButton>
          <SwagButton
            className={css.button}
            loading={isLoading}
            disabled={!isFormValid || isLoading}
            type="primary"
            onClick={onSaveClick}
          >
            Save changes
          </SwagButton>
        </DialogFooter>
      </DialogContentWrapper>
    </Dialog>
  );
};
