import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { ReducerPath } from 'interfaces';

import { pushNotification } from 'redux/slices/notifications.slice';
import { setFeatureFlags } from 'redux/slices/technical-data.slice';

import { makeToastNotification } from 'utils/query.util';

import { baseQueryParams } from './helpers';

export const technicalDataApi = createApi({
  reducerPath: ReducerPath.technicalData,
  baseQuery: fetchBaseQuery(baseQueryParams),
  endpoints: (builder) => ({
    getFeatureFlags: builder.query<{ featureFlagList: Record<string, boolean> }, void>({
      query: () => ({ url: '/technical-data/feature-flag', method: 'GET' }),

      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(setFeatureFlags({ featureFlagList: data.featureFlagList }));
          }
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),
  }),
});

export const { useGetFeatureFlagsQuery } = technicalDataApi;
