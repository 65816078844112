import { CellSize, CellTextColor, CellTextWeight } from '@ezetech/swag-space-x';
import { ASSIGNED_TO_ME_ENTITY } from 'constants/common';

export enum InvoicesStatusEnum {
  BULK = 'BULK',
  INVENTORY = 'INVENTORY',
  EMAIL_SENT = 'SENT',
  EMAIL_NOT_SENT = 'NOT SENT',
  PAID = 'PAID',
  NOT_PAID = 'NOT PAID',
}

export const INVOICES_FILTER_OPTIONS = [
  { label: 'My Invoices', value: ASSIGNED_TO_ME_ENTITY },
  { label: 'Order type: Bulk', value: InvoicesStatusEnum.BULK },
  { label: 'Order type: Inventory ', value: InvoicesStatusEnum.INVENTORY },
  { label: 'Email status: Sent', value: InvoicesStatusEnum.EMAIL_SENT },
  { label: 'Email status: Not sent', value: InvoicesStatusEnum.EMAIL_NOT_SENT },
  { label: 'Payment: Paid', value: InvoicesStatusEnum.PAID },
  { label: 'Payment: Unpaid', value: InvoicesStatusEnum.NOT_PAID },
];

export enum InvoicesFields {
  customerEmail = 'customerEmail',
  company = 'company',
  orderType = 'order type',
  products = 'products',
  createdAt = 'createdAt',
  emailStatus = 'emailStatus',
  payment = 'payment',
  presentation = 'presentation',
  downloadInvoice = 'download invoice',
  manage = 'manage',
  assignTo = 'assignTo',
}

export enum InvoicesActions {
  copy = 'copy',
  redirect = 'redirect',
}

export const INVOICE_TABLE_HEADER = [
  { text: 'Email', id: InvoicesFields.customerEmail, size: CellSize.large },
  { text: 'Company', id: InvoicesFields.company, size: CellSize.medium },
  { text: 'Order type', id: InvoicesFields.orderType, size: CellSize.extraLarge },

  { text: 'Products', id: InvoicesFields.products, size: CellSize.medium },
  {
    text: 'Created',
    id: InvoicesFields.createdAt,
    size: CellSize.medium,
    withSorting: true,
  },
  {
    text: 'Email status',
    id: InvoicesFields.emailStatus,
    size: CellSize.extraLarge,
  },
  {
    text: 'Payment',
    id: InvoicesFields.payment,
    size: CellSize.extraLarge,
  },
  {
    text: 'Presentation',
    id: InvoicesFields.presentation,
    size: CellSize.compact,
  },
  {
    text: 'Invoice',
    id: InvoicesFields.downloadInvoice,
    size: CellSize.compact,
  },
  { text: 'Assign to', id: InvoicesFields.assignTo, size: CellSize.extraLarge },
  { text: 'Manage', id: InvoicesFields.manage, size: CellSize.extraLarge },
];

export const INVOICE_TABLE_ROW = {
  [InvoicesFields.customerEmail]: {
    size: CellSize.large,
    textWeight: CellTextWeight.bold,
    textColor: CellTextColor.pink,
    onClick: InvoicesActions.copy,
    actionable: true,
  },
  [InvoicesFields.company]: {
    size: CellSize.medium,
  },
  [InvoicesFields.orderType]: {
    size: CellSize.extraLarge,
  },
  [InvoicesFields.products]: {
    size: CellSize.medium,
    textColor: CellTextColor.pink,
    actionable: true,
  },
  [InvoicesFields.createdAt]: { size: CellSize.medium },
  [InvoicesFields.emailStatus]: {
    size: CellSize.extraLarge,
  },
  [InvoicesFields.payment]: {
    size: CellSize.extraLarge,
  },
  [InvoicesFields.presentation]: {
    size: CellSize.compact,
    textColor: CellTextColor.pink,
  },

  [InvoicesFields.downloadInvoice]: {
    size: CellSize.compact,
  },
  [InvoicesFields.assignTo]: { size: CellSize.extraLarge },
  [InvoicesFields.manage]: { size: CellSize.extraLarge },
};
