import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@ezetech/swag-space-x';
import { CodeEditor } from 'components/code-editor';
import COLORS from 'constants/styles/colors-js.constant.module.scss';

import { useAppDispatch } from 'redux/store';
import {
  storeCustomCodeBodySelector,
  storeCustomCodeHeaderSelector,
} from 'redux/selectors/settings.selectors';
import { setCustomCodeBody, setCustomCodeHeader } from 'redux/slices/settings.slice';
import { cannot, SUBJECTS } from 'boot/ability';

import css from './custom-code.module.scss';

export function CustomCode(): JSX.Element {
  const dispatch = useAppDispatch();
  const customCodeHeader = useSelector(storeCustomCodeHeaderSelector);
  const customCodeBody = useSelector(storeCustomCodeBodySelector);
  const readOnly = cannot(
    SUBJECTS.ADD_3RD_PARTY_CODE.actions.ADD_3RD_PARTY_CODE_EDIT,
    SUBJECTS.ADD_3RD_PARTY_CODE.value,
  );

  const onCodeHeaderChange = useCallback((value: string) => {
    dispatch(setCustomCodeHeader(value));
  }, []);

  const onCodeBodyChange = useCallback((value: string) => {
    dispatch(setCustomCodeBody(value));
  }, []);

  const basicSetup = { lineNumbers: true, foldGutter: false };
  return (
    <div className={css.root}>
      <Typography color={COLORS.colorPrimaryText} lineHeight="162.5%">
        You are now able to easily add 3rd party code, such as Google Tag Manager,
        abandoned page popups or anything else that you’d like.
      </Typography>
      <CodeEditor
        readOnly={readOnly}
        onChange={onCodeHeaderChange}
        height="110px"
        value={customCodeHeader || undefined}
        label="Site <head> section code. Code here will be injected into <head> section on every page of the site."
        placeholder="Enter code here..."
        basicSetup={basicSetup}
      />
      <CodeEditor
        readOnly={readOnly}
        value={customCodeBody || undefined}
        height="110px"
        onChange={onCodeBodyChange}
        label="Site <body> section code. Code here will be injected into <body> section on every page of the site."
        placeholder="Enter code here..."
      />
    </div>
  );
}
