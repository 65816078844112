import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { makeToastNotification } from 'utils/query.util';
import {
  assigneeForOrdersByIdSelector,
  assigneeForInvoiceByIdSelector,
  assigneeForPresentationByIdSelector,
  assigneeForCustomerByIdSelector,
  assigneeForLeadByIdSelector,
  assigneeForCartByIdSelector,
  assigneeForAbandonedCheckoutByIdSelector,
} from 'redux/selectors/assignee.selector';
import { pushNotification } from 'redux/slices/notifications.slice';
import { RootState } from 'redux/store';
import {
  getOrdersAssigneeList,
  getInvoicesAssigneeList,
  getPresentationsAssigneeList,
  getCustomersAssigneeList,
  getLeadsAssigneeList,
  getCartsAssigneeList,
  getAbandonedCheckoutsAssigneeList,
} from 'redux/slices/assignee.slice';
import { setAssigneeToOrderById } from 'redux/slices/orders.slice';
import { setAssigneeToInvoiceById } from 'redux/slices/invoices.slice';
import { setAssigneeToPresentationById } from 'redux/slices/pdf-presentations.slice';
import { setAssigneeToCustomerLeadById } from 'redux/slices/customers-leads.slice';
import { setAssigneeToCartById } from 'redux/slices/carts.slice';
import { setAssigneeToAbandonedCheckoutById } from 'redux/slices/abandoned-checkouts-carts.slice';
import {
  AssignmentEntity,
  IAssignEntityToUserRequest,
  IGetAssigneeListForOrdersResponse,
  IGetAssigneeListForInvoicesResponse,
  IGetAssigneeListForCustomersResponse,
  IGetAssigneeListForLeadsResponse,
  IGetAssigneeListForCartsResponse,
} from 'interfaces/assignee.interface';

import { baseQueryParams } from './helpers';

export const assigneeApi = createApi({
  reducerPath: 'assigneeApi',
  baseQuery: fetchBaseQuery({ ...baseQueryParams }),
  endpoints: (builder) => ({
    getAssigneeListForOrders: builder.mutation<IGetAssigneeListForOrdersResponse, void>({
      query: () => `/assignee/${AssignmentEntity.ORDER}/users`,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(getOrdersAssigneeList(data));
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),
    assignOrdersToUser: builder.mutation<void, IAssignEntityToUserRequest>({
      query: ({ entityId, userId }) => ({
        url: '/assignee/assign',
        method: 'POST',
        body: { entityId, userId, entityType: AssignmentEntity.ORDER },
      }),
      async onQueryStarted({ entityId, userId }, { dispatch, queryFulfilled, getState }) {
        try {
          await queryFulfilled;
          const state = getState();

          const assignee = assigneeForOrdersByIdSelector(state as unknown as RootState, {
            userId,
          });
          dispatch(setAssigneeToOrderById({ entityId, assignee }));
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),
    getAssigneeListForInvoices: builder.mutation<
      IGetAssigneeListForInvoicesResponse,
      void
    >({
      query: () => ({
        url: `/assignee/${AssignmentEntity.INVOICE}/users`,
        method: 'GET',
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;

          dispatch(getInvoicesAssigneeList(data));
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),
    assignInvoiceToUser: builder.mutation<void, IAssignEntityToUserRequest>({
      query: ({ entityId, userId }) => ({
        url: '/assignee/assign',
        method: 'POST',
        body: { entityId, userId, entityType: AssignmentEntity.INVOICE },
      }),
      async onQueryStarted({ entityId, userId }, { dispatch, queryFulfilled, getState }) {
        try {
          await queryFulfilled;
          const state = getState();

          const assignee = assigneeForInvoiceByIdSelector(state as unknown as RootState, {
            userId,
          });
          dispatch(setAssigneeToInvoiceById({ entityId, assignee }));
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),
    getAssigneeListForCustomers: builder.mutation<
      IGetAssigneeListForCustomersResponse,
      void
    >({
      query: () => ({
        url: `/assignee/${AssignmentEntity.CUSTOMER}/users`,
        method: 'GET',
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;

          dispatch(getCustomersAssigneeList(data));
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),
    assignCustomerToUser: builder.mutation<void, IAssignEntityToUserRequest>({
      query: ({ entityId, userId }) => ({
        url: '/assignee/assign',
        method: 'POST',
        body: { entityId, userId, entityType: AssignmentEntity.CUSTOMER },
      }),
      async onQueryStarted({ entityId, userId }, { dispatch, queryFulfilled, getState }) {
        try {
          await queryFulfilled;
          const state = getState();
          const assignee = assigneeForCustomerByIdSelector(
            state as unknown as RootState,
            {
              userId,
            },
          );
          dispatch(setAssigneeToCustomerLeadById({ entityId, assignee }));
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),
    getAssigneeListForLeads: builder.mutation<IGetAssigneeListForLeadsResponse, void>({
      query: () => ({
        url: `/assignee/${AssignmentEntity.LEAD}/users`,
        method: 'GET',
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;

          dispatch(getLeadsAssigneeList(data));
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),
    assignLeadToUser: builder.mutation<void, IAssignEntityToUserRequest>({
      query: ({ entityId, userId }) => ({
        url: '/assignee/assign',
        method: 'POST',
        body: { entityId, userId, entityType: AssignmentEntity.LEAD },
      }),
      async onQueryStarted({ entityId, userId }, { dispatch, queryFulfilled, getState }) {
        try {
          await queryFulfilled;
          const state = getState();
          const assignee = assigneeForLeadByIdSelector(state as unknown as RootState, {
            userId,
          });
          dispatch(setAssigneeToCustomerLeadById({ entityId, assignee }));
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),
    getAssigneeListForPresentations: builder.mutation<
      IGetAssigneeListForInvoicesResponse,
      void
    >({
      query: () => ({
        url: `/assignee/${AssignmentEntity.PDF}/users`,
        method: 'GET',
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;

          dispatch(getPresentationsAssigneeList(data));
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),
    assignPresentationToUser: builder.mutation<void, IAssignEntityToUserRequest>({
      query: ({ entityId, userId }) => ({
        url: '/assignee/assign',
        method: 'POST',
        body: { entityId, userId, entityType: AssignmentEntity.PDF },
      }),
      async onQueryStarted({ entityId, userId }, { dispatch, queryFulfilled, getState }) {
        try {
          await queryFulfilled;
          const state = getState();
          const assignee = assigneeForPresentationByIdSelector(
            state as unknown as RootState,
            {
              userId,
            },
          );
          dispatch(setAssigneeToPresentationById({ entityId, assignee }));
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),
    getAssigneeListForCarts: builder.mutation<IGetAssigneeListForCartsResponse, void>({
      query: () => ({
        url: `/assignee/${AssignmentEntity.CART_LINK}/users`,
        method: 'GET',
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;

          dispatch(getCartsAssigneeList(data));
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),
    assignCartToUser: builder.mutation<void, IAssignEntityToUserRequest>({
      query: ({ entityId, userId }) => ({
        url: '/assignee/assign',
        method: 'POST',
        body: { entityId, userId, entityType: AssignmentEntity.CART_LINK },
      }),
      async onQueryStarted({ entityId, userId }, { dispatch, queryFulfilled, getState }) {
        try {
          await queryFulfilled;
          const state = getState();
          const assignee = assigneeForCartByIdSelector(state as unknown as RootState, {
            userId,
          });
          dispatch(setAssigneeToCartById({ entityId, assignee }));
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),
    getAssigneeListForAbandonedCheckouts: builder.mutation<
      IGetAssigneeListForCartsResponse,
      void
    >({
      query: () => ({
        url: `/assignee/${AssignmentEntity.ABANDONED_CHECKOUT}/users`,
        method: 'GET',
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;

          dispatch(getAbandonedCheckoutsAssigneeList(data));
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),
    assignAbandonedCheckoutToUser: builder.mutation<void, IAssignEntityToUserRequest>({
      query: ({ entityId, userId }) => ({
        url: '/assignee/assign',
        method: 'POST',
        body: { entityId, userId, entityType: AssignmentEntity.ABANDONED_CHECKOUT },
      }),
      async onQueryStarted({ entityId, userId }, { dispatch, queryFulfilled, getState }) {
        try {
          await queryFulfilled;
          const state = getState();
          const assignee = assigneeForAbandonedCheckoutByIdSelector(
            state as unknown as RootState,
            {
              userId,
            },
          );
          dispatch(setAssigneeToAbandonedCheckoutById({ entityId, assignee }));
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),
  }),
});

export const {
  useGetAssigneeListForOrdersMutation,
  useAssignOrdersToUserMutation,
  useGetAssigneeListForInvoicesMutation,
  useAssignInvoiceToUserMutation,
  useGetAssigneeListForPresentationsMutation,
  useAssignPresentationToUserMutation,
  useGetAssigneeListForCustomersMutation,
  useAssignCustomerToUserMutation,
  useGetAssigneeListForLeadsMutation,
  useAssignLeadToUserMutation,
  useGetAssigneeListForCartsMutation,
  useAssignCartToUserMutation,
  useGetAssigneeListForAbandonedCheckoutsMutation,
  useAssignAbandonedCheckoutToUserMutation,
} = assigneeApi;
