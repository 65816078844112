import { FC } from 'react';
import { Typography } from '@ezetech/swag-space-x';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import css from '../sign-contract.module.scss';
import { makeBold, makeLink } from '../sign-contract.util';

export const DisputeResolution: FC = () => {
  return (
    <>
      <Typography color={COLORS.colorPrimaryText} fontType="bodyMdBold">
        13. Dispute Resolution and Arbitration; Class Action Waiver
      </Typography>
      <br />
      <br />
      <Typography
        className={css.italic}
        color={COLORS.colorPrimaryText}
        fontType="bodyMdBold"
      >
        PLEASE READ THIS SECTION CAREFULLY. IT AFFECTS YOUR LEGAL RIGHTS.
      </Typography>
      <br />
      <br />
      This section facilitates the prompt and efficient resolution of any dispute (e.g.,
      claim or controversy, whether based in contract, statute, regulation, ordinance,
      tort – including, but not limited to, fraud, misrepresentation, fraudulent
      inducement, or negligence – or any other legal or equitable theory, and includes the
      validity, enforceability or scope of this section (with the exception of the
      enforceability of the Class Action Waiver clause below) that may arise between you
      and Swag Space. Effectively, then, “dispute” is given the broadest meaning
      enforceable by law and includes any claims against other parties relating to
      services or products provided or billed to you (such as our licensors, suppliers,
      dealers or third-party vendors) whenever you also assert claims against Swag Space
      in the same proceeding.
      <br />
      <br />
      This section provides that all disputes between you and Swag Space shall be resolved
      by binding arbitration because acceptance of these Terms constitutes a waiver of
      your right to litigation claims and all opportunity to be heard by a judge or jury.
      To be clear, there is no judge or jury in arbitration, and court review of an
      arbitration award is limited. The arbitrator must follow this agreement and can
      award the same damages and relief as a court (including attorney’s fees). You may,
      however, opt-out of this section which means you would have a right or opportunity
      to bring claims in a court, before a judge or jury, and/or to participate in or be
      represented in a case filed in court by others (including, but not limited to, class
      actions). YOU AND SWAG SPACE AGREE THAT, EXCEPT AS PROVIDED BELOW, ANY AND ALL
      DISPUTES, AS DEFINED ABOVE, WHETHER PRESENTLY IN EXISTENCE OR BASED ON ACTS OR
      OMISSIONS IN THE PAST OR IN THE FUTURE, WILL BE RESOLVED EXCLUSIVELY AND FINALLY BY
      BINDING ARBITRATION RATHER THAN IN COURT IN ACCORDANCE WITH THIS SECTION.
      <br />
      <br />
      <ol className={css.listLatin}>
        <li className={css.listItem}>
          {makeBold('Pre-Arbitration Claim Resolution.')}
          <br />
          <br />
          For all disputes, whether pursued in court or arbitration, you must first give
          us an opportunity to resolve the dispute by emailing us at{' '}
          {makeLink('contact@swag.space', 'mailto:contact@swag.space')} the following
          information: (1) your name, (2) your address, (3) a written description of your
          claim, and (4) a description of the specific relief you seek. If we do not
          resolve the dispute within forty-five (45) days after receiving your
          notification, than you may pursue your dispute in arbitration. You may pursue
          your dispute in a court only under the circumstances described below.
        </li>
        <br />
        <li className={css.listItem}>
          {makeBold('Exclusions from Arbitration/Right to Opt Out')}
          <br />
          <br />
          Notwithstanding the above, your or we may choose to pursue a dispute in court
          and not by arbitration if: (i) the dispute qualifies for initiation in small
          claims court; or (ii) YOU OPT-OUT OF THESE ARBITRATION PROCEDURES WITHIN 30 DAYS
          FROM THE DATE THAT YOU FIRST CONSENT TO THIS AGREEMENT (the{' '}
          {makeBold('“Opt-Out Deadline”')}). You may opt-out of this section by emailing
          us at {makeLink('contact@swag.space', 'mailto:contact@swag.space')} the
          following information: (1) your name; (2) your contact information; (3) a clear
          statement that you do not wish to resolve disputes with us through arbitration.
          Either way, we will not take any decision you make personally. In fact, we
          promise that your decision to opt-out of this Arbitration section will have no
          adverse effect on your relationship with us. But, we do have to enforce the
          Opt-Out Deadline so keep in mind that any opt-out request received after the
          Opt-Out Deadline will not be valid and you must pursue your dispute in
          arbitration or small claims court.
        </li>
        <br />
        <li className={css.listItem}>
          {makeBold('Arbitration Procedures.')}
          <br />
          <br />
          If this section applies and the dispute is not resolved as provided above
          (Pre-Arbitration Claim Resolution) either You or We may initiate arbitration
          proceedings. The American Arbitration Association (“AAA”), www.adr.org, or JAMS,
          www.jamsadr.com, will arbitrate all disputes, and the arbitration will be
          conducted before a single arbitrator. The arbitration shall be commenced as an
          individual arbitration, and shall in no event be commenced as a class
          arbitration. All issues shall be for the arbitrator to decide, including the
          scope of this section.
          <br />
          <br />
          For arbitration before AAA, for disputes of less than $75,000, the AAA’s
          Supplementary Procedures for Consumer-Related Disputes will apply; for Disputes
          involving $75,000 or more, the AAA’s Commercial Arbitration Rules will apply. In
          either instance, the AAA’s Optional Rules For Emergency Measures Of Protection
          shall apply. The AAA rules are available at www.adr.org or by calling
          1-800-778-7879. For arbitration before JAMS, the JAMS Comprehensive Arbitration
          Rules & Procedures and the JAMS Recommended Arbitration Discovery Protocols For
          Domestic, Commercial Cases will apply. The JAMS rules are available at
          www.jamsadr.com or by calling 1-800-352-5267. This section governs in the event
          it conflicts with the applicable arbitration rules. Under no circumstances will
          class action procedures or rules apply to the arbitration.
          <br />
          <br />
          Because the Services and these Terms concern interstate commerce, the Federal
          Arbitration Act (“FAA”) governs the arbitrability of all disputes. However, the
          arbitrator will apply applicable substantive law consistent with the FAA and the
          applicable statute of limitations or condition precedent to suit.
          <br />
          <br />
          Arbitration Award – The arbitrator may award on an individual basis any relief
          that would be available pursuant to applicable law, and will not have the power
          to award relief to, against or for the benefit of any person who is not a party
          to the proceeding. The arbitrator will make any award in writing but need not
          provide a statement of reasons unless requested by a party. Such award will be
          final and binding on the parties, except for any right of appeal provided by the
          FAA, and may be entered in any court having jurisdiction over the parties for
          purposes of enforcement.
          <br />
          <br />
          Location of Arbitration – You or Swag Space may initiate arbitration in either
          Virginia or the federal judicial district that includes your billing address. In
          the event that you select the latter, we may transfer the arbitration to
          Virginia so long as we agree to pay any additional fees or costs which the
          arbitrator determines you incur as a result of the transfer.
          <br />
          <br />
          Payment of Arbitration Fees and Costs – So long as you place a request in
          writing prior to commencement of the arbitration, we will pay all arbitration
          fees and associated costs and expenses. But, you will still be responsible for
          all additional fees and costs that you incur in the arbitration which include
          but are not limited to attorneys’ fees or expert witnesses. In addition to any
          fees and costs recoverable under applicable law, if you provide notice and
          negotiate in good faith with Us as provided in the section above titled
          “Pre-Arbitration Claim Resolution” and the arbitrator concludes that you are the
          prevailing party in the arbitration, you will be entitled to recover reasonable
          attorney’s fees and costs as determined by the arbitrator.
        </li>
        <br />
        <li className={css.listItem}>
          {makeBold('Class Action Waiver.')}
          <br />
          <br />
          Except as otherwise provided in this section, the arbitrator may not consolidate
          more than one person’s claims, and may not otherwise preside over any form of a
          class or representative proceeding or claims (such as a class action,
          consolidated action or private attorney general action) unless both you and we
          specifically agree to do so following initiation of the arbitration. If you
          choose to pursue your dispute in court by opting out of the Arbitration
          Provision, as specified above, this Class Action Waiver will not apply to you.
          Neither you, nor any other user of the Services can be a class representative,
          class member, or otherwise participate in a class, consolidated, or
          representative proceeding without having complied with the opt-out requirements
          above.
        </li>
        <br />
        <li className={css.listItem}>
          {makeBold('Jury Waiver.')}
          <br />
          <br />
          You understand and agree that by accepting this section in these terms, you and
          we are each waiving the right to a jury trial or a trial before a judge in a
          public court. In the absence of this section, you and we might otherwise have
          had a right or opportunity to bring disputes in a court, before a judge or jury,
          and/or to participate or be represented in a case filed in court by others
          (including class actions). Except as otherwise provided below, those rights are
          waived. Other rights that you would have if you went to court (e.g., the rights
          to both appeal and certain types of discovery) may be more limited or may also
          be waived.
        </li>
        <br />
        <li className={css.listItem}>
          {makeBold('Severability.')}
          <br />
          <br />
          If any clause within this section (other than the Class Action Waiver clause
          above) is found to be illegal or unenforceable, that clause will be severed from
          this section whose remainder will be given full force and effect. If the Class
          Action Waiver clause is found to be illegal or unenforceable, this entire
          section will be unenforceable and the dispute will be decided by a court.
        </li>
        <br />
        <li className={css.listItem}>
          {makeBold('Continuation.')}
          <br />
          <br />
          This section shall survive the termination of your Account with us and your
          discontinued use of the Services. Notwithstanding any provision in this
          Agreement to the contrary, we agree that if we make any change to this section
          (other than a change to the Notice Address), you may reject any such change and
          require us to adhere to the language in this section if a dispute between us
          arises.
        </li>
      </ol>
      <br />
    </>
  );
};
