import { FC } from 'react';
import { Typography } from '@ezetech/swag-space-x';
import COLORS from 'constants/styles/colors-js.constant.module.scss';

export const Changes: FC = () => {
  return (
    <>
      <Typography color={COLORS.colorPrimaryText} fontType="bodyMdBold">
        5. Changes
      </Typography>
      <br />
      <br />
      Prices for our Services are subject to change without notice. We may alter the Site,
      Swag Space Content (as defined below) or the Services we offer you or we may choose
      to modify, suspend or discontinue the Site (or any feature thereof) at any time and
      without notifying you. We may also change, update, add or remove provisions of these
      Terms from time to time. We will inform You of any modifications to these Terms by
      posting them on the Site. We recommend that you review these Terms periodically.
      <br />
      <br />
      If you object to any such modifications, your sole recourse shall be to cease using
      the Services. Continued use of the Services following notice of any such
      modifications indicates that you acknowledge and agree to be bound by the
      modifications. Also, please know that these Terms may be superseded by
      expressly-designated legal notices or terms located on particular features or pages
      of the Site. These expressly-designated legal notices or terms are incorporated into
      these Terms and supersede the provision(s) of these Terms that are designated as
      being superseded.
      <br />
      <br />
    </>
  );
};
