import {
  Typography,
  SkeletonWrapper,
  SkeletonNotReady,
  SkeletonReady,
  Skeleton,
  Loader,
} from '@ezetech/swag-space-x';

import { PageContent } from 'components/page-content';
import { SingleTrendCard } from 'components/single-trend-card';
import { Price } from 'utils/price.util';
import { useAppSelector } from 'redux/store';
import {
  pendingReferralsSelector,
  referralLinkSelector,
  referralsCountSelector,
  totalEarnedSelector,
} from 'redux/selectors/dashboard.selectors';
import { useGetDashboardQuery } from 'redux/api/dashboard.api';

import { ReferralLinkBlock } from './referral-link-block';
import { ReferralsBlock } from './referrals';
import css from './refer-a-member.module.scss';

export function ReferAMemberComponent(): JSX.Element {
  const { isLoading } = useGetDashboardQuery();
  const referralLinkSuffix = useAppSelector(referralLinkSelector);
  const totalEarned = useAppSelector(totalEarnedSelector);
  const pendingReferrals = useAppSelector(pendingReferralsSelector);
  const referralsCount = useAppSelector(referralsCountSelector);

  return (
    <SkeletonWrapper isReady={!isLoading}>
      <SkeletonReady>
        <PageContent>
          <div className={css.grid}>
            <SingleTrendCard
              className={css.boxTotal}
              title="Total Earned"
              value={`${Price.formatPrice(totalEarned)}`}
              valueDescription={`${referralsCount} Referrals`}
            />
            <SingleTrendCard
              className={css.boxLeads}
              title="Pending Referrals"
              value={pendingReferrals}
            />
            <ReferralLinkBlock
              className={css.boxOrders}
              linkSuffix={referralLinkSuffix}
            />
            <ReferralsBlock className={css.boxTier} />
          </div>
        </PageContent>
      </SkeletonReady>
      <SkeletonNotReady>
        <Typography lineHeight="262%">
          <Skeleton width="70%" />
        </Typography>
        <div className={css.grid}>
          <Skeleton height={253} className={css.boxTotal} />
          <Skeleton variant="rectangular" height={253} className={css.boxLeads} />
          <Skeleton variant="rectangular" height={258} className={css.boxOrders} />
          <Skeleton variant="rectangular" height={504} className={css.boxTier} />
        </div>
        <Loader />
      </SkeletonNotReady>
    </SkeletonWrapper>
  );
}
