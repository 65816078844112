import { OptionType, Select, Typography } from '@ezetech/swag-space-x';
import { MultiValue } from 'react-select';

import { ProductSortingEnum } from 'interfaces/settings.interface';
import COLORS from 'constants/styles/colors-js.constant.module.scss';

import css from './product-selection.module.scss';

const PRODUCT_SORTING = {
  [ProductSortingEnum.MOST_POPULAR]: 'Most Popular',
  [ProductSortingEnum.NEWEST]: 'Newest',
  [ProductSortingEnum.HOTTEST]: 'Hottest',
  [ProductSortingEnum.HIGHEST_PRICE]: 'Highest Price',
  [ProductSortingEnum.LOWEST_PRICE]: 'Lowest Price',
};

const options = Object.entries(PRODUCT_SORTING).map(([value, label]) => ({
  label,
  value,
}));

export const DefaultSorting = ({
  defaultSorting,
  onChange,
}: {
  defaultSorting?: ProductSortingEnum | null;
  onChange: (option: OptionType | MultiValue<OptionType | null> | null) => void;
}): JSX.Element => {
  return (
    <div className={css.defaultSortingWrapper}>
      <Typography lineHeight="162.5%" color={COLORS.colorPrimaryText} fontType="bodyMd">
        You can easily change the default sorting method for all of your product pages
      </Typography>
      <Select
        className={css.defaultSortingSelect}
        options={options}
        value={
          defaultSorting
            ? {
                value: defaultSorting,
                label: PRODUCT_SORTING[defaultSorting],
              }
            : null
        }
        onChange={onChange}
        label="Select default sorting"
        name="defaultSorting"
        skipError
      />
    </div>
  );
};
