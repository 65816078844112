import { createSelector } from 'reselect';
import { RootState } from '../store';

export const productsSelector = (state: RootState) => state.products;

export const searchProductsSelector = createSelector(
  productsSelector,
  (filters) => filters.search || '',
);
export const pageProductsSelector = createSelector(
  productsSelector,
  (filters) => filters.page,
);

export const perPageProductsSelector = createSelector(
  productsSelector,
  (filters) => filters.perPage,
);

export const isLoadingProductsSelector = createSelector(
  productsSelector,
  (products) => products.isLoading,
);

export const totalProductsSelector = createSelector(
  productsSelector,
  (products) => products.total,
);

export const totalHiddenProductsSelector = createSelector(
  productsSelector,
  (products) => products.hiddenTotal,
);
export const selectedFilterOptionsSelector = createSelector(
  productsSelector,
  (products) => products.filter,
);
export const selectedProductsSelector = createSelector(
  productsSelector,
  (products) => products.selectedProducts,
);

export const selectAllProductsSelector = createSelector(
  productsSelector,
  (products) => products.selectAll,
);

export const selectedProductsManuallySelector = createSelector(
  productsSelector,
  (products) => products.selectedProductsManually,
);

export const selectionTotalProductsSelector = createSelector(
  selectedProductsSelector,
  (selectedProducts) => {
    let selectedManually = 0;

    Object.keys(selectedProducts).forEach((productId) => {
      if (selectedProducts[productId]) {
        selectedManually++;
      }
    });

    return selectedManually;
  },
);

export const hasNextPageSelector = createSelector(
  productsSelector,
  ({ perPage, total, page }) => Math.ceil(total / perPage) >= page,
);

export const productsFiltersSelector = createSelector(
  productsSelector,
  (filter) => filter.filter,
);

export const productsListSelector = createSelector(
  productsSelector,
  selectedProductsSelector,
  (products, selectedProducts) => {
    return products.list.map((item) => {
      const { isHidden, margin, name, logo, id } = item;
      const isProductExistsInSelection = Object.hasOwnProperty.call(selectedProducts, id);

      const isProductChecked =
        selectedProducts[id] || (isHidden && !isProductExistsInSelection);

      return {
        id,
        key: id,
        name,
        image: logo,
        checked: isProductChecked,
        margin: `${(margin * 100).toFixed(0)}%`,
      };
    });
  },
);

export const filtersProductsRequestSelector = createSelector(
  pageProductsSelector,
  searchProductsSelector,
  perPageProductsSelector,
  productsFiltersSelector,
  selectedProductsManuallySelector,
  (page, search, perPage, filter, selectedProductsManually) => {
    const selectedIds: string[] = [];
    const excludedIds: string[] = [];

    Object.keys(selectedProductsManually).forEach((id) => {
      if (selectedProductsManually[id]) {
        selectedIds.push(id);
      } else {
        excludedIds.push(id);
      }
    });

    return {
      page,
      perPage,
      search,
      filter,
      selectedIds,
      excludedIds,
    };
  },
);

export const hideDataRequestSelector = createSelector(
  selectedProductsSelector,
  (selectedMap) => {
    const unselected: string[] = [];
    const selected: string[] = [];

    Object.keys(selectedMap).forEach((productId) => {
      if (selectedMap[productId]) {
        selected.push(productId);
      } else {
        unselected.push(productId);
      }
    });

    return {
      selected,
      unselected,
    };
  },
);
