import { store } from 'redux/store';
import { userApi } from 'redux/api/user.api';
import { pushNotification } from 'redux/slices/notifications.slice';
import { makeToastNotification } from 'utils/query.util';
import { fetchStoreSettingsData } from 'utils/store-settings.util';

export async function profileSettingsLoader(): Promise<null> {
  await fetchStoreSettingsData();

  try {
    const request = store.dispatch(userApi.endpoints.getUser.initiate(null));
    await request.unwrap();
  } catch (e) {
    store.dispatch(pushNotification(makeToastNotification(e)));
  }
  return null;
}
