import { FC } from 'react';
import {
  InputProps,
  Input,
  TextField,
  TextFieldProps,
  Typography,
} from '@ezetech/swag-space-x';
import COLORS from '../../constants/styles/colors-js.constant.module.scss';

import classes from './input-with-help-text.module.scss';

export const InputWithHelpText: FC<
  InputProps & {
    helpText?: string;
  }
> = ({ helpText, ...props }) => {
  return (
    <div className={classes.root}>
      <Input {...props} />
      <Typography
        color={COLORS.colorText500}
        fontType="bodySm"
        className={classes.helpText}
      >
        {helpText}
      </Typography>
    </div>
  );
};

export const TextFieldWithHelpText: FC<
  TextFieldProps & {
    helpText?: string;
  }
> = ({ helpText, ...props }) => {
  return (
    <div className={classes.root}>
      <TextField {...props} />
      <Typography
        color={COLORS.colorText500}
        fontType="bodySm"
        className={classes.helpText}
      >
        {helpText}
      </Typography>
    </div>
  );
};
