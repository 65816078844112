import {
  Dialog,
  DialogContentWrapper,
  DialogTitle,
  DialogDescription,
  DialogContent,
  DialogFooter,
  SwagButton,
  Typography,
} from '@ezetech/swag-space-x';
import { closePopup, openPopup } from 'redux/slices/modals.slice';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { SavePdfPresentationChangesConfirmationPopupProps } from 'interfaces/popup.interface';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { useUpdatePdfPresentationEditorSettingsMutation } from '../../../redux/api/settings.api';
import { editableDraftSelector } from '../../../redux/selectors/pdf-presentation-editor.selectors';
import { EDIT_PDF_PRESENTATION_LAYOUT_POPUP } from '../_logic/popups-list';
import { reset } from '../../../redux/slices/pdf-presentation-editor.slice';
import css from './save-pdf-presentation-changes-confirmation-popup.module.scss';

export const SavePdfPresentationChangesConfirmationPopup = ({
  isOpen,
  messages,
}: SavePdfPresentationChangesConfirmationPopupProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const editableDraft = useAppSelector(editableDraftSelector);
  const [updatePdfPresentationSettings] =
    useUpdatePdfPresentationEditorSettingsMutation();

  const onSaveClick = async () => {
    await updatePdfPresentationSettings(editableDraft);
    dispatch(reset());
    dispatch(closePopup());
  };
  const onCloseClick = () => {
    dispatch(
      openPopup({
        popupName: EDIT_PDF_PRESENTATION_LAYOUT_POPUP,
        popupProps: {},
      }),
    );
  };

  return (
    <Dialog open={isOpen} onOpenChange={onCloseClick}>
      <DialogContentWrapper
        className={css.dialogContent}
        overlayClassName={css.dialogOverlay}
      >
        <DialogTitle closeIconClassName={css.closeIcon}>
          Warning! You’re not quite finished.
        </DialogTitle>
        <div className={css.dialogBodyWrapper}>
          <DialogDescription className={css.dialogDescription}>
            There are still a few places that need your input. If you do not want to
            complete these missing elements, we will put in a placeholder image where
            necessary and remove certain pages that are optional. Would you like to
            continue editing?
          </DialogDescription>
          <DialogContent className={css.dialogBody}>
            <div className={css.information}>
              {messages?.length && (
                <Typography
                  color={COLORS.colorPrimaryText}
                  fontType="bodyMd"
                  lineHeight="162.5%"
                  className={css.primaryText}
                >
                  Pages that require your attention:
                </Typography>
              )}
              {messages?.map((error) => (
                <div className={css.row}>
                  <Typography
                    color={COLORS.colorPrimaryText}
                    fontType="bodyMd"
                    lineHeight="162.5%"
                  >
                    {error.text} -{' '}
                    <Typography
                      color={COLORS.colorSecondaryText}
                      fontType="bodyMd"
                      lineHeight="162.5%"
                    >
                      {error.hint}
                    </Typography>
                  </Typography>
                </div>
              ))}
            </div>
          </DialogContent>
        </div>

        <DialogFooter className={css.footer}>
          <SwagButton className={css.button} type="outlined" onClick={onCloseClick}>
            Continue editing
          </SwagButton>
          <SwagButton className={css.button} type="primary" onClick={onSaveClick}>
            Save
          </SwagButton>
        </DialogFooter>
      </DialogContentWrapper>
    </Dialog>
  );
};
