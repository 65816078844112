import * as yup from 'yup';
import { ERROR_MSG } from 'constants/validation';

export const MARGIN_VALIDATION = yup
  .number()
  .transform(function (value, originalValue) {
    if (this.isType(value)) {
      return value;
    }
    if (!originalValue || !originalValue.trim()) {
      return null;
    }
    return 0;
  })
  .min(30, ERROR_MSG.marginInvalid)
  .max(99, ERROR_MSG.marginInvalid)
  .required(ERROR_MSG.marginInvalid);
