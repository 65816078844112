import { Controller, Control, FieldValues, RegisterOptions } from 'react-hook-form';
import {
  Select,
  SelectProps,
  OptionType,
  OptionsGroupedType,
} from '@ezetech/swag-space-x';

type Props = SelectProps & {
  rules?: RegisterOptions<FieldValues>;
  control: Control<FieldValues>;
};

function getValueFromOptions(options: OptionType[], current: string): OptionType | null {
  const selectOptions = options as OptionType[];
  const selected = selectOptions.find((option) => option.value === current) || null;

  return selected || null;
}

export function SelectForm({
  name,
  rules,
  options,
  control,
  required,
  defaultValue,
  ...rest
}: Props): JSX.Element {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field: { onChange, value: ctrlValue, ...fieldProps }, fieldState }) => {
        let value: OptionType | null = null;
        if ('options' in options[0]) {
          const groups = options as OptionsGroupedType[];
          groups.forEach((item) => {
            if (value) {
              return;
            }

            value = getValueFromOptions(item.options as OptionType[], ctrlValue);
          });
        } else if ('value' in options[0]) {
          value = getValueFromOptions(options as OptionType[], ctrlValue);
        }
        return (
          <Select
            {...rest}
            {...fieldProps}
            options={options}
            value={value || defaultValue}
            onChange={(e) => onChange((e as OptionType).value)}
            errors={fieldState.error?.message || ''}
            required={!!rules?.required || required}
          />
        );
      }}
    />
  );
}
