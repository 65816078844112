import { Typography, WhiteBox } from '@ezetech/swag-space-x';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { useAppSelector } from 'redux/store';
import { customerStatisticDetailsSelector } from 'redux/selectors/customer-details.selector';
import { Separator, VerticalSeparator } from '../separator';
import css from './statistic.module.scss';

enum StatisticFields {
  avgOrderValue = 'avgOrderValue',
  lastOrderAt = 'lastOrderAt',
  totalSpent = 'totalSpent',
  totalOrders = 'totalOrders',
}

const firstRow = [
  {
    id: StatisticFields.totalSpent,
    label: 'Amount spent',
  },
  { id: StatisticFields.totalOrders, label: 'Total orders' },
];
const secondRow = [
  { id: StatisticFields.avgOrderValue, label: 'Avg order value' },
  { id: StatisticFields.lastOrderAt, label: 'Last order' },
];

const columns = [...firstRow, ...secondRow];
const mobileRows = [firstRow, secondRow];

export function Statistic(): JSX.Element {
  const statistic = useAppSelector(customerStatisticDetailsSelector);

  return (
    <WhiteBox className={css.container}>
      <div className={css.wrapper}>
        <div className={css.row}>
          {columns.map((column) => (
            <div className={css.column} key={column.id} data-label={column.label}>
              <Typography
                className={css.title}
                lineHeight="162%"
                fontType="bodyMdBold"
                color={COLORS.colorTextSubdued}
              >
                {column.label}
              </Typography>
            </div>
          ))}
        </div>
        <Separator />
        <div className={css.row}>
          {columns.map((column) => (
            <div className={css.column} key={column.id} data-label={column.label}>
              <Typography
                className={css.title}
                lineHeight="100%"
                fontType="HEADING5XL"
                color={COLORS.colorPrimaryText}
              >
                {statistic[column.id]}
              </Typography>
            </div>
          ))}
        </div>
      </div>
      <div className={css.mobileWrapper}>
        {mobileRows.map((row, index) => (
          <>
            <div className={css.mobileRow}>
              {row.map((column, position) => (
                <>
                  <div
                    className={css.mobileColumn}
                    key={column.id}
                    data-label={column.label}
                  >
                    <Typography
                      className={css.title}
                      lineHeight="162%"
                      fontType="bodyMdBold"
                      color={COLORS.colorTextSubdued}
                    >
                      {column.label}
                    </Typography>
                    <Typography
                      className={css.title}
                      lineHeight="100%"
                      fontType="HEADING4XL"
                      color={COLORS.colorPrimaryText}
                    >
                      {statistic[column.id]}
                    </Typography>
                  </div>
                  {position === 0 && <VerticalSeparator />}
                </>
              ))}
            </div>
            {index === 0 && <Separator />}
          </>
        ))}
      </div>
    </WhiteBox>
  );
}
