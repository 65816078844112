import { OptionType } from '@ezetech/swag-space-x';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { MultiValue } from 'react-select';
import { ReducerPath } from 'interfaces';
import { makeToastNotification, makeSuccessToastNotification } from 'utils/query.util';
import { setFilter } from 'redux/slices/filter.slice';
import { closePopup } from 'redux/slices/modals.slice';
import { filtersRequestSelector } from 'redux/selectors/filters.selectors';
import { RootState } from 'redux/store';
import { ASSIGNED_TO_ME_ENTITY } from 'constants/common';

import { IGetPresentationsRequest } from 'interfaces/presentations.interface';
import { setMyOrdersCount } from 'redux/slices/carts.slice';
import { IInvoiceListResponse } from 'interfaces/invoice.interface';
import { setInvoices } from 'redux/slices/invoices.slice';
import { InvoicesStatusEnum } from 'interfaces/invoice';
import { IProposalToReAssign } from 'constants/assignment';
import { pushNotification } from '../slices/notifications.slice';
import { baseQueryParams } from './helpers';

export const invoicesApi = createApi({
  reducerPath: ReducerPath.invoice,
  baseQuery: fetchBaseQuery({ ...baseQueryParams }),
  endpoints: (builder) => ({
    preCreateInvoice: builder.mutation<IProposalToReAssign[], { [key: string]: string }>({
      query: (payload) => ({
        url: '/invoice/pre-create',
        method: 'GET',
        params: payload,
      }),
    }),

    prePlaceInvoiceOrder: builder.mutation<
      {
        isInvoiceAssigned: boolean;
        isInvoiceAssignedToHimself: boolean;
        isInvoiceOwnerCanBeAssignedToOrder: boolean | null;
        proposals: IProposalToReAssign[];
      },
      { invoiceId: string }
    >({
      query: ({ invoiceId }) => ({
        url: `/invoice/pre-place-order/${invoiceId}`,
        method: 'GET',
      }),
    }),

    createInvoice: builder.query<void, { email: string }>({
      query: (payload) => ({
        url: '/invoice/create',
        method: 'GET',
        params: payload,
      }),
    }),

    getInvoices: builder.mutation<IInvoiceListResponse, IGetPresentationsRequest>({
      query: ({ dropdown, ...rest }) => {
        const filter: Record<string, string[]> = {};

        (dropdown as MultiValue<OptionType>).forEach(({ value }) => {
          if (
            value === InvoicesStatusEnum.BULK ||
            value === InvoicesStatusEnum.INVENTORY
          ) {
            filter.cartDistributionType =
              [...(filter.cartDistributionType || []), value] || [];
          }

          if (
            value === InvoicesStatusEnum.EMAIL_SENT ||
            value === InvoicesStatusEnum.EMAIL_NOT_SENT
          ) {
            filter.sentAt = [...(filter.sentAt || []), value] || [];
          }
          if (
            value === InvoicesStatusEnum.PAID ||
            value === InvoicesStatusEnum.NOT_PAID
          ) {
            filter.paidAt = [...(filter.paidAt || []), value];
          }
          if (value === ASSIGNED_TO_ME_ENTITY) {
            filter.assignedToMeEntity = [ASSIGNED_TO_ME_ENTITY];
          }
        });

        return {
          url: '/invoice',
          method: 'GET',
          params: {
            ...rest,
            filter: JSON.stringify(filter),
          },
        };
      },

      async onQueryStarted(
        { dropdown, search, sorting, sortingOrder },
        { dispatch, queryFulfilled },
      ) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            const { invoices, total, page, perPage } = data;

            dispatch(setInvoices({ invoices, total }));
            dispatch(setMyOrdersCount({ invoices: total }));
            dispatch(
              setFilter({
                page,
                perPage,
                dropdown,
                search: search || '',
                sorting,
                sortingOrder,
                totalCount: total,
              }),
            );
          }
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),

    deleteInvoice: builder.mutation<void, { invoiceId: string }>({
      query: ({ invoiceId }) => ({
        url: `/invoice/${invoiceId}`,
        method: 'DELETE',
      }),
      async onQueryStarted(_, { dispatch, getState, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(closePopup());
          dispatch(
            pushNotification(
              makeSuccessToastNotification('Invoice has been successfully deleted'),
            ),
          );

          const state = getState();
          const filters = filtersRequestSelector(state as unknown as RootState);

          dispatch(invoicesApi.endpoints.getInvoices.initiate(filters));
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),

    editInvoice: builder.query<void, { invoiceId: string }>({
      query: ({ invoiceId }) => ({
        url: `/invoice/edit/${invoiceId}`,
        method: 'GET',
      }),
    }),
    markAsSent: builder.mutation<
      { sentAt: Date | null } | { paidAt: Date | null },
      { invoiceId: string; action: string }
    >({
      query: ({ invoiceId, action }) => ({
        url: `/invoice/mark-as/${action}/${invoiceId}`,
        method: 'PATCH',
      }),
      async onQueryStarted(_, { dispatch, getState, queryFulfilled }) {
        try {
          await queryFulfilled;
          const state = getState();
          const filters = filtersRequestSelector(state as unknown as RootState);

          dispatch(invoicesApi.endpoints.getInvoices.initiate(filters));
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),
    downloadPDF: builder.mutation<{ url: string }, { invoiceId: string }>({
      query: ({ invoiceId }) => ({
        url: `/invoice/download/${invoiceId}`,
        method: 'GET',
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          document.location.replace(data.url);
        } catch (e) {
          dispatch(pushNotification(makeToastNotification('Something went wrong')));
        }
      },
    }),
  }),
});

export const {
  useCreateInvoiceQuery,
  useDeleteInvoiceMutation,
  useEditInvoiceQuery,
  useGetInvoicesMutation,
  useMarkAsSentMutation,
  useDownloadPDFMutation,
  usePreCreateInvoiceMutation,
  usePrePlaceInvoiceOrderMutation,
} = invoicesApi;
