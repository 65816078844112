export const ONE_TIME_LINK_ERROR_TITLE = 'This link was already used.';
export const ONE_TIME_LINK_ERROR_DESCRIPTION =
  'This is a one-time link that was already used.';

export const EXPIRED_BY_TIME_ERROR_TITLE = 'This brand has expired.';
export const EXPIRED_BY_TIME_ERROR_DESCRIPTION =
  'Your password recovery link has expired, you need to get a new one.';

export const CONFIRM_ACTION_EXPIRED = 'CONFIRM_ACTION_EXPIRED';
export const CONFIRM_ACTION_USED = 'CONFIRM_ACTION_USED';
export const CONFIRM_ACTION_DOESNT_EXIST = 'CONFIRM_ACTION_DOESNT_EXIST';
