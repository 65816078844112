import { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { store, useAppSelector } from 'redux/store';
import { customerCompanyApi } from 'redux/api/customer-company.api';
import {
  ICustomerCompanyOrdersRequest,
  ICustomerCompanyOrdersList,
} from 'interfaces/customer-company.interface';

import { getTimeMmmDdYyyy } from 'utils/date/date-formatter.util';
import {
  OrderFields,
  IOrdersTransformRow,
  ICustomerCompanyOrder,
} from 'interfaces/customer-company.interface';

import {
  ORDERS_TABLE_HEADER,
  ORDERS_TABLE_ROW,
} from 'constants/customer-company.constant';

import { customerCompanyOrdersSelector } from 'redux/selectors/customer-company.selectors';
import {
  isReviewPantonesByCustomerAllowedSelector,
  isReviewMockupsByCustomerAllowedSelector,
} from 'redux/selectors/settings.selectors';
import { getItemStatusLabel, getNumWithLetterIndicator } from 'utils/items.util';
import { Price } from 'utils/price.util';

const fetchOrders = async (queryParams: ICustomerCompanyOrdersRequest) => {
  try {
    const request = store.dispatch(
      customerCompanyApi.endpoints.getCustomerCompanyOrders.initiate(queryParams, {
        forceRefetch: true,
      }),
    );
    await request.unwrap();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn(e);
  }
};

const getRows = ({
  orders,
  isReviewMockupAllowed,
  isReviewPantonesAllowed,
}: {
  orders: ICustomerCompanyOrdersList['list'];
  isReviewPantonesAllowed: boolean;
  isReviewMockupAllowed: boolean;
}) => {
  const rows: IOrdersTransformRow[] = [];
  const makeCells = (
    order: ICustomerCompanyOrder,
    item?: ICustomerCompanyOrder['item'],
  ) => {
    const { user } = order;
    const isReviewPantonesByCustomerAllowed =
      user.isSpecificReviewPantonesByCustomerAllowed === null
        ? isReviewPantonesAllowed
        : user.isSpecificReviewPantonesByCustomerAllowed;

    const isReviewMockupsByCustomerAllowed =
      user.isSpecificReviewMockupsByCustomerAllowed === null
        ? isReviewMockupAllowed
        : user.isSpecificReviewMockupsByCustomerAllowed;

    const cells = ORDERS_TABLE_HEADER.map((cell) => {
      const common = ORDERS_TABLE_ROW[cell.id] || {};
      const cellParams = { ...common, rowId: order.num, key: order.num, id: cell.id };

      if (cell.id === OrderFields.number) {
        const value = order.num;
        return {
          ...cellParams,
          value,
          text: getNumWithLetterIndicator(item?.num, item?.letterIndicator),
        };
      }

      if (cell.id === OrderFields.status) {
        const value = item
          ? getItemStatusLabel({
              status: item.status,
              statusCode: item.statusCode,
              isReviewPantonesByCustomerAllowed,
              isReviewMockupsByCustomerAllowed,
            })
          : 'Completed';

        return {
          ...cellParams,
          value,
          orderId: order.id,
          itemId: item?.id,
          text: value,
          isReviewPantonesByCustomerAllowed,
          isReviewMockupsByCustomerAllowed,
        };
      }

      if (cell.id === OrderFields.products) {
        return {
          ...cellParams,
          value: cell.id,
          itemId: item?.id,
          text: item?.name || '',
        };
      }

      if (cell.id === OrderFields.paidAt) {
        const value = order[cell.id];

        return {
          ...cellParams,
          value,
          text: value
            ? getTimeMmmDdYyyy(new Date(value), {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
              })
            : 'N/A',
        };
      }

      if (cell.id === OrderFields.total) {
        const value = Price.formatPrice(order.grandTotal);

        return {
          ...cellParams,
          value,
          text: value,
        };
      }

      const value = cell.id ? order[cell.id] : '';

      return {
        ...cellParams,
        value,
        text: value?.toString() ?? 'N/A',
      };
    });

    return cells;
  };

  let position = 0;
  orders.forEach((order) => {
    const cells = makeCells(order, order.item);
    rows.push({
      cells,
      index: position,
      key: order.item.id,
      order,
    });

    position += 1;
  });

  return rows;
};

export const useCustomerCompanyOrders = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState<IOrdersTransformRow[]>([]);
  const { id } = useParams();
  const customerCompanyOrders = useAppSelector(customerCompanyOrdersSelector);
  const isReviewPantonesAllowed = useAppSelector(
    isReviewPantonesByCustomerAllowedSelector,
  );
  const isReviewMockupAllowed = useAppSelector(isReviewMockupsByCustomerAllowedSelector);
  const ordersList = customerCompanyOrders.listInfinity;
  const hasNextPage =
    customerCompanyOrders.listInfinity.length < customerCompanyOrders.total;
  const total = customerCompanyOrders.total;
  const currentPage = customerCompanyOrders.page;

  const getOrders = useCallback(
    async (queryParams: ICustomerCompanyOrdersRequest) => {
      setIsLoading(true);
      await fetchOrders(queryParams);
      setIsLoading(false);
    },
    [setIsLoading, fetchOrders, id],
  );

  useEffect(() => {
    const newRows = getRows({
      orders: ordersList,
      isReviewPantonesAllowed,
      isReviewMockupAllowed,
    });
    setRows(newRows);
  }, [ordersList, ordersList.length, isReviewPantonesAllowed, isReviewMockupAllowed]);

  return {
    total,
    getOrders,
    ordersList,
    isLoading,
    hasNextPage,
    currentPage,
    rows,
    rowsCount: (rows && rows.length) || 0,
  };
};
