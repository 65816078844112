import { OptionType } from '@ezetech/swag-space-x';
import { MultiValue } from 'react-select';
import { NON_JSON_ENDPOINTS } from 'constants/router';
import { IOrderCSVExportRequestParams } from 'interfaces/orders.interface';
import { getItem, StorageKeys } from 'utils/window-storage.util';
import { MY_ORDERS } from 'constants/common';

export const API_URL = `${process.env.REACT_APP_API_URL || ''}/api/v1`;

export async function prepareHeaders(
  headers: Headers,
  { endpoint }: { endpoint: string },
) {
  headers.set(StorageKeys.csrf, getItem(StorageKeys.csrf));

  if (!NON_JSON_ENDPOINTS.includes(endpoint)) {
    return;
  } else {
    headers.set('Content-type', 'application/json; charset=UTF-8');
  }
}

export const baseQueryParams = {
  baseUrl: API_URL,
  credentials: 'include' as const,
  prepareHeaders,
};

export function handleCSVFileUpload({
  baseUrl = API_URL,
  params,
}: {
  baseUrl?: string;
  params?: IOrderCSVExportRequestParams;
}) {
  const url = new URL(baseUrl, document.baseURI);
  const { dropdown, ...rest } = params || {};
  const filter: Record<string, string[]> = {};

  (dropdown as MultiValue<OptionType>)?.forEach(({ value }) => {
    if ([MY_ORDERS].includes(value)) {
      filter.orders = [MY_ORDERS];
      return;
    }
    filter.status = [...(filter.status || []), value];
  });
  url.searchParams.append('filter', JSON.stringify(filter));

  Object.entries(rest).forEach(([key, value]) => {
    if (value) {
      url.searchParams.append(key, value);
    }
  });

  handFileUpload(url.href);
}

export function handFileUpload(url: string) {
  const link = document.createElement('a');
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
