import { FC } from 'react';
import { Typography } from '@ezetech/swag-space-x';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import css from '../sign-contract.module.scss';
import { makeBold } from '../sign-contract.util';

export const Confidentiality: FC = () => {
  return (
    <>
      <Typography color={COLORS.colorPrimaryText} fontType="bodyMdBold">
        3. Confidentiality
      </Typography>
      <br />
      <br />
      <ol className={css.listLatin}>
        <li className={css.listItem}>
          {makeBold('“Confidential Information”')} means any and all information
          associated with a party’s business and not publicly known, including, but not
          limited to, specific business information, technical processes and formulas,
          software, technology, customer lists, prospective customer lists, names,
          addresses and other information regarding customers and prospective customers,
          vendor or service provider information, sales, costs (including any relevant
          processing fees), price lists, and other unpublished financial information,
          business plans and marketing data. Swag Space’s Confidential Information
          includes all information that you receive relating to us, or to the Services,
          that is not known to the general public.
        </li>
        <br />
        <li className={css.listItem}>
          Swag Space and Reseller both agree to use the other’s Confidential Information
          solely as necessary for performing its obligations under these Terms. Each party
          agrees that it will take all reasonable steps, which are at least substantially
          equivalent to the steps it takes to protect its own confidential information, to
          prevent the duplication, disclosure or use of any such Confidential Information
          received from the other party, other than (i) by or to its employees, agents and
          contractors who must have access to such Confidential Information to perform
          such party’s obligations hereunder, who each will treat such Confidential
          Information as provided herein, and who are each subject to obligations of
          confidentiality to such party that are at least as stringent as those contained
          herein; or (ii) as required by any law, regulation, or order of any court of
          proper jurisdiction over the parties and the subject matter contained in these
          Terms, provided that, if legally permitted, the receiving party will give the
          disclosing party prompt written notice and use commercially reasonable efforts
          at the disclosing party’s expense to ensure that such disclosure is accorded
          confidential treatment. Confidential Information will not include any
          information received from the disclosing party that: (a) was or is already in
          the public domain, or was already known by or in the possession of the receiving
          party, at the time of disclosure of such information; (b) is independently
          developed by the receiving party without use of or reference to the other
          party’s Confidential Information, and without breaching any provisions of these
          Terms; or (c) is thereafter rightly obtained by the receiving party from a
          source other than the disclosing party without breaching any provision of these
          Terms.
        </li>
      </ol>
      <br />
    </>
  );
};
