import { useRef } from 'react';
import cn from 'classnames';
import { SwagButton, Typography } from '@ezetech/swag-space-x';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import COLORS from 'constants/styles/colors-js.constant.module.scss';

import { POPUP_MODES } from './reseller-mockup-approval-popup.utils';

import css from './reseller-mockup-approval-popup.module.scss';

export interface IButtonProps {
  primaryBtnText: string;
  isPrimaryBtnDisabled: boolean;
  shouldRenderPrimaryBtn: boolean;
  onPrimaryBtnClick: () => void;
  secondaryBtnText: string;
  isSecondaryBtnDisabled: boolean;
  shouldRenderSecondaryBtn: boolean;
  onSecondaryBtnClick: () => void;
  setMode: (mode: string) => void;
  shouldRenderCompareLink: boolean;
}

export const Buttons = ({
  secondaryBtnText,
  delayTime,
  onSecondaryBtnClick,
  isSecondaryBtnDisabled,
  shouldRenderPrimaryBtn,
  shouldRenderCompareLink,
  primaryBtnText,
  isPrimaryBtnDisabled,
  onPrimaryBtnClick,
  animationClasses,
  isLoading,
  isRejectLoading,
  setMode,
}: IButtonProps & {
  delayTime: number;
  isLoading: boolean;
  isRejectLoading: boolean;
  animationClasses: Record<string, unknown>;
}) => {
  const secondaryBtnRef = useRef<HTMLButtonElement>(null);
  const primaryBtnRef = useRef<HTMLButtonElement>(null);
  const linkBtnRef = useRef<HTMLSpanElement>(null);

  return (
    <>
      <div className={css.btnLeft}>
        <SwitchTransition>
          <CSSTransition
            key={'See Customer Mockup'}
            timeout={delayTime}
            classNames={animationClasses}
            unmountOnExit
            nodeRef={linkBtnRef}
          >
            <span
              ref={linkBtnRef}
              onClick={() => setMode(POPUP_MODES.COMPARE)}
              className={cn({
                [css.shouldHideOpacity]: !shouldRenderCompareLink,
                [css.shouldShowOpacity]: shouldRenderCompareLink,
              })}
            >
              <Typography
                lineHeight={'162.5%'}
                fontType="bodyMdBold"
                color={COLORS.colorAccent500}
                link
              >
                See Customer Mockup
              </Typography>
            </span>
          </CSSTransition>
        </SwitchTransition>
      </div>
      <SwitchTransition>
        <CSSTransition
          key={secondaryBtnText}
          timeout={delayTime}
          classNames={animationClasses}
          unmountOnExit
          nodeRef={secondaryBtnRef}
        >
          <SwagButton
            type="outlined"
            size="large"
            onClick={onSecondaryBtnClick}
            disabled={isSecondaryBtnDisabled}
            className={cn(css.btn, {
              [css.shouldMoveSecondaryBtn]: !shouldRenderPrimaryBtn,
            })}
            ref={secondaryBtnRef}
          >
            {secondaryBtnText}
          </SwagButton>
        </CSSTransition>
      </SwitchTransition>

      <SwitchTransition>
        <CSSTransition
          key={primaryBtnText}
          timeout={delayTime}
          classNames={animationClasses}
          unmountOnExit
          nodeRef={primaryBtnRef}
        >
          <SwagButton
            type="primary"
            size="large"
            disabled={isPrimaryBtnDisabled}
            onClick={onPrimaryBtnClick}
            className={cn(css.btn, {
              [css.shouldHide]: !shouldRenderPrimaryBtn,
            })}
            loading={isLoading || isRejectLoading}
            ref={primaryBtnRef}
          >
            {primaryBtnText}
          </SwagButton>
        </CSSTransition>
      </SwitchTransition>
    </>
  );
};
