import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ReducerPath } from 'interfaces';
import {
  IProductsHiddenRequest,
  IProductsHiddenResponse,
  IProductsListRequest,
  IProductsListResponse,
  IProductsIdsListRequest,
  IProductsIdsListResponse,
  IHomepageProductsListResponse,
  IHomepageProductsListRequest,
} from 'interfaces/products.interface';
import { makeSuccessToastNotification, makeToastNotification } from 'utils/query.util';
import { pushNotification } from '../slices/notifications.slice';
import {
  setProducts,
  setLoading,
  reset,
  checkProducts,
  setHomepageProducts,
} from '../slices/products.slice';
import { closePopup } from '../slices/modals.slice';
import { baseQueryParams } from './helpers';

export const productsApi = createApi({
  reducerPath: ReducerPath.products,
  baseQuery: fetchBaseQuery(baseQueryParams),
  endpoints: (builder) => ({
    getProducts: builder.mutation<IProductsListResponse, IProductsListRequest>({
      query: ({ filter, ...rest }) => {
        const options = filter.reduce((all, next) => {
          if (!next || !next.value) {
            return all;
          }

          const [field, value] = next.value.split(':');

          return {
            ...all,
            [field]: field.includes('margin') ? parseFloat(value) : value === 'true',
          };
        }, {});

        return {
          url: '/products/hidden/list',
          method: 'POST',
          body: {
            ...rest,
            perPage: 20,
            filter: JSON.stringify(options),
          },
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          dispatch(setLoading(true));
          const { data } = await queryFulfilled;

          if (data) {
            const {
              list,
              page,
              perPage,
              productsTotal,
              hiddenProductsIds,
              areAllSelected,
              availableProductsTotalToHide,
            } = data;

            dispatch(
              setProducts({
                list,
                page,
                perPage,
                areAllSelected,
                hiddenProductsIds,
                availableProductsTotalToHide,
                total: productsTotal,
              }),
            );
          }
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        } finally {
          dispatch(setLoading(false));
        }
      },
    }),
    getProductsIds: builder.mutation<IProductsIdsListResponse, IProductsIdsListRequest>({
      query: ({ filter, ...rest }) => {
        const options = filter.reduce((all, next) => {
          if (!next || !next.value) {
            return all;
          }

          const [field, value] = next.value.split(':');

          return {
            ...all,
            [field]: field.includes('margin') ? parseFloat(value) : value === 'true',
          };
        }, {});

        return {
          url: '/products/list/ids',
          method: 'GET',
          params: {
            ...rest,
            filter: JSON.stringify(options),
          },
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          dispatch(setLoading(true));
          const { data } = await queryFulfilled;
          dispatch(checkProducts(data));
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        } finally {
          dispatch(setLoading(false));
        }
      },
    }),
    hideProducts: builder.mutation<IProductsHiddenResponse, IProductsHiddenRequest>({
      query: (data) => ({
        url: '/products/hidden',
        method: 'PATCH',
        body: data,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          dispatch(setLoading(true));
          const { data } = await queryFulfilled;
          dispatch(closePopup());
          dispatch(reset());
          dispatch(
            setProducts({
              list: [],
              page: 1,
              perPage: 20,
              total: 0,
              hiddenProductsIds: [],
              hiddenTotal: data.hiddenProductsTotal,
            }),
          );
          dispatch(pushNotification(makeSuccessToastNotification('Settings updated!')));
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        } finally {
          dispatch(setLoading(false));
        }
      },
    }),

    getHiddenProductsCount: builder.mutation<{ count: number }, void>({
      query: (data) => ({
        url: '/products/hidden/count',
        method: 'GET',
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          dispatch(setLoading(true));
          const { data } = await queryFulfilled;
          dispatch(
            setProducts({
              list: [],
              page: 1,
              perPage: 20,
              total: 0,
              hiddenProductsIds: [],
              hiddenTotal: data.count,
            }),
          );
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        } finally {
          dispatch(setLoading(false));
        }
      },
    }),

    getHomepageProducts: builder.mutation<
      IHomepageProductsListResponse,
      IHomepageProductsListRequest
    >({
      query: ({ search }) => {
        return {
          url: '/products/homepage/list',
          method: 'GET',
          params: search ? { search } : {},
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          dispatch(setLoading(true));
          const { data } = await queryFulfilled;
          dispatch(setHomepageProducts({ list: data.list }));
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        } finally {
          dispatch(setLoading(false));
        }
      },
    }),
  }),
});

export const {
  useGetProductsMutation,
  useHideProductsMutation,
  useGetProductsIdsMutation,
  useGetHiddenProductsCountMutation,
  useGetHomepageProductsMutation,
} = productsApi;
