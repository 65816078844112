import { createSelector } from 'reselect';

import {
  MARGIN_MAX_PERCENT,
  MARGIN_MIN_PERCENT,
  STEPS,
} from 'constants/store-creation.constants';
import { STEP_NAME, ONBOARDING_STATUS } from 'enums/store-creation.enum';
import { validateStoreName } from 'validations/store-creation.validation';
import { getStepIndex } from 'utils/get-current-step-index.util';
import {
  marginCustomerPriceFn,
  marginResellerIncomeFn,
} from '../../utils/margin-calculation.util';
import { hasSelectedCategoriesAndPrices } from '../../utils/has-selected-categories.util';

const maxPercent = MARGIN_MAX_PERCENT * 100;
const minPercent = MARGIN_MIN_PERCENT * 100;

import { RootState } from '../store';

export const storeCreationSelector = (state: RootState) => state.storeCreation;

export const resellerStoreSettingsSelector = createSelector(
  storeCreationSelector,
  (state) => state.storeSettings,
);

export const hasMarginBeenSetSelector = createSelector(
  storeCreationSelector,
  (state) => state.hasMarginBeenSet,
);

export const hasCategoriesBeenSetSelector = createSelector(
  storeCreationSelector,
  (state) => state.hasCategoriesBeenSet,
);

export const resellerCategoriesStructureSelector = createSelector(
  storeCreationSelector,
  (state) => state.categoriesStructure,
);

export const initialStoreSettingsSelector = createSelector(
  storeCreationSelector,
  (state) => state.initialStoreSettings,
);

export const resellerSignContractSelector = createSelector(
  resellerStoreSettingsSelector,
  (state) => state[STEP_NAME.SIGN_CONTRACT].isContractSigned,
);

export const resellerStoreContractReadSelector = createSelector(
  resellerStoreSettingsSelector,
  (state) => state[STEP_NAME.SIGN_CONTRACT].isContractRead,
);

export const isOnboardingFlowCompletedSelector = createSelector(
  storeCreationSelector,
  (state) => state.isCompleted === ONBOARDING_STATUS.COMPLETED,
);

export const currentStepIndexSelector = createSelector(
  storeCreationSelector,
  (state) => state.currentIndex,
);

export const currentStepSelector = createSelector(
  currentStepIndexSelector,
  (index) => STEPS[index],
);

export const currentStepDataSelector = createSelector(
  resellerStoreSettingsSelector,
  currentStepSelector,
  (resellerStoreSettings, currentStep) => {
    const stepName = currentStep.stepName;
    return resellerStoreSettings[stepName];
  },
);

export const hasUnsavedChangesSelector = createSelector(
  resellerStoreSettingsSelector,
  initialStoreSettingsSelector,
  currentStepIndexSelector,
  (resellerStoreSettings, initialStoreSettings, currentStepIndex) => {
    const currentStep = STEPS[currentStepIndex];
    const stepName = currentStep.stepName;

    return (
      JSON.stringify(resellerStoreSettings[stepName]) !==
      JSON.stringify(initialStoreSettings[stepName])
    );
  },
);

export const resellerPrimaryColorSelector = createSelector(
  resellerStoreSettingsSelector,
  (state) => state[STEP_NAME.BRAND].primaryColor,
);

export const resellerSecondaryColorSelector = createSelector(
  resellerStoreSettingsSelector,
  (state) => state[STEP_NAME.BRAND].secondaryColor,
);

export const resellerLogoUrlSelector = createSelector(
  resellerStoreSettingsSelector,
  (state) => state[STEP_NAME.BRAND].logo?.url ?? '',
);

export const resellerStoreNameSelector = createSelector(
  resellerStoreSettingsSelector,
  (state) => state[STEP_NAME.PAGE_LINK].resellerStoreName,
);

export const resellerSelectedCategoriesSelector = createSelector(
  resellerStoreSettingsSelector,
  (state) => state[STEP_NAME.PRODUCT_SELECTION].categories,
);

export const activeCategoryTabSelector = createSelector(
  storeCreationSelector,
  (state) => state.activeCategoryTab,
);

export const isCurrentCategorySelector = createSelector(
  resellerStoreSettingsSelector,
  activeCategoryTabSelector,
  (storeSettings, index) =>
    storeSettings[STEP_NAME.PRODUCT_SELECTION].categories[index] ?? {},
);

export const categoriesPricePointsSelector = createSelector(
  resellerStoreSettingsSelector,
  activeCategoryTabSelector,
  (storeSettings) => storeSettings[STEP_NAME.PRODUCT_SELECTION].pricePoints,
);

export const uncompletedStepSelector = createSelector(
  initialStoreSettingsSelector,
  hasMarginBeenSetSelector,
  hasCategoriesBeenSetSelector,
  (state, hasMarginBeenSet, hasCategoriesBeenSet) => {
    if (
      !state[STEP_NAME.BRAND].primaryColor ||
      !state[STEP_NAME.BRAND].secondaryColor ||
      !state[STEP_NAME.BRAND].logo
    ) {
      return STEPS.find((step) => step.stepName === STEP_NAME.BRAND)?.route;
    }
    if (!state[STEP_NAME.PAGE_LINK].resellerStoreName) {
      return STEPS.find((step) => step.stepName === STEP_NAME.PAGE_LINK)?.route;
    }
    if (!hasCategoriesBeenSet) {
      return STEPS.find((step) => step.stepName === STEP_NAME.PRODUCT_SELECTION)?.route;
    }
    if (!hasMarginBeenSet) {
      return STEPS.find((step) => step.stepName === STEP_NAME.COMMISSION)?.route;
    }
    if (!state[STEP_NAME.SIGN_CONTRACT].isContractSigned) {
      return STEPS.find((step) => step.stepName === STEP_NAME.SIGN_CONTRACT)?.route;
    }
    return null;
  },
);

export const uncompletedStepIndexSelector = createSelector(
  uncompletedStepSelector,
  (uncompletedStep) => getStepIndex(uncompletedStep as string),
);

export const isCurrentCategoryEnabledSelector = createSelector(
  isCurrentCategorySelector,
  (category) => Boolean(category?.isEnabled),
);

export const isSuperInexpensiveEnabledSelector = createSelector(
  categoriesPricePointsSelector,
  (pricePoints) => !!pricePoints.isSuperInexpensive,
);

export const isPremiumEnabledSelector = createSelector(
  categoriesPricePointsSelector,
  (pricePoints) => !!pricePoints?.isPremium,
);

export const isStandardEnabledSelector = createSelector(
  categoriesPricePointsSelector,
  (pricePoints) => !!pricePoints?.isStandard,
);

export const isBudgetEnabledSelector = createSelector(
  categoriesPricePointsSelector,
  (pricePoints) => !!pricePoints?.isBudget,
);

export const marginPercentSelector = createSelector(
  resellerStoreSettingsSelector,
  (state) => Number((state[STEP_NAME.COMMISSION].resellerMargin * 100).toFixed(1)) || 0,
);

export const marginCustomerPriceSelector = createSelector(
  marginPercentSelector,
  marginCustomerPriceFn,
);

export const marginResellerIncomeSelector = createSelector(
  marginPercentSelector,
  marginCustomerPriceSelector,
  marginResellerIncomeFn,
);

export const resellerStoreNameErrorSelector = createSelector(
  resellerStoreNameSelector,
  (resellerStoreName) => validateStoreName(resellerStoreName),
);

export const marginPercentErrorSelector = createSelector(
  marginPercentSelector,
  (marginPercent) =>
    marginPercent > maxPercent || marginPercent < minPercent
      ? `The commission cannot be lower than ${MARGIN_MIN_PERCENT * 100}%`
      : null,
);

export const isOnboardingCompletedSelector = createSelector(
  currentStepIndexSelector,
  resellerPrimaryColorSelector,
  resellerSecondaryColorSelector,
  resellerLogoUrlSelector,
  resellerSignContractSelector,
  marginPercentErrorSelector,
  marginPercentSelector,
  resellerStoreNameErrorSelector,
  resellerStoreNameSelector,
  resellerSelectedCategoriesSelector,
  categoriesPricePointsSelector,
  (
    currentIndex,
    resellerPrimaryColor,
    resellerSecondaryColor,
    resellerLogoUrl,
    resellerSignContract,
    marginPercentError,
    marginPercent,
    resellerStoreNameError,
    resellerStoreName,
    resellerSelectedCategories,
    categoriesPricePoints,
  ) =>
    Boolean(
      currentIndex === STEPS.length - 1 &&
        resellerPrimaryColor &&
        resellerSecondaryColor &&
        resellerLogoUrl &&
        resellerSignContract !== null &&
        !marginPercentError &&
        marginPercent &&
        !resellerStoreNameError &&
        resellerStoreName &&
        hasSelectedCategoriesAndPrices(resellerSelectedCategories, categoriesPricePoints),
    ),
);

export const isNextBtnDisabledSelector = createSelector(
  currentStepIndexSelector,
  resellerStoreNameErrorSelector,
  resellerStoreNameSelector,
  resellerPrimaryColorSelector,
  resellerSecondaryColorSelector,
  resellerLogoUrlSelector,
  marginPercentErrorSelector,
  isOnboardingCompletedSelector,
  resellerSelectedCategoriesSelector,
  categoriesPricePointsSelector,
  (
    currentIndex,
    resellerStoreNameError,
    resellerStoreName,
    resellerPrimaryColor,
    resellerSecondaryColor,
    resellerLogoUrl,
    marginPercentError,
    isOnboardingCompleted,
    resellerSelectedCategories,
    categoriesPricePoints,
  ) => {
    if (
      currentIndex === STEPS.findIndex((step) => step.stepName === STEP_NAME.BRAND) &&
      (!resellerPrimaryColor || !resellerSecondaryColor || !resellerLogoUrl)
    ) {
      return true;
    }

    if (
      currentIndex ===
        STEPS.findIndex((step) => step.stepName === STEP_NAME.PRODUCT_SELECTION) &&
      !hasSelectedCategoriesAndPrices(resellerSelectedCategories, categoriesPricePoints)
    ) {
      return true;
    }

    if (
      currentIndex ===
        STEPS.findIndex((step) => step.stepName === STEP_NAME.SIGN_CONTRACT) &&
      !isOnboardingCompleted
    ) {
      return true;
    }

    if (
      currentIndex ===
        STEPS.findIndex((step) => step.stepName === STEP_NAME.COMMISSION) &&
      !!marginPercentError
    ) {
      return true;
    }

    if (
      currentIndex === STEPS.findIndex((step) => step.stepName === STEP_NAME.PAGE_LINK) &&
      ((resellerStoreNameError && resellerStoreName.length) || !resellerStoreName.length)
    ) {
      return true;
    }

    return false;
  },
);

export const resellerStoreAcceptBtnTextSelector = createSelector(
  resellerSignContractSelector,
  (resellerSignContract) => (resellerSignContract ? 'Accepted' : 'Accept Contract'),
);

export const resellerStoreAcceptBtnDisabledSelector = createSelector(
  resellerSignContractSelector,
  resellerStoreContractReadSelector,
  (resellerSignContract, resellerStoreContractRead) =>
    !resellerStoreContractRead || resellerSignContract !== null,
);

export const resellerStoreSkipBtnDisabledSelector = createSelector(
  resellerSignContractSelector,
  (resellerSignContract) => resellerSignContract !== null,
);

export const resellerSignContractSkipBtnDisabledSelector = createSelector(
  resellerSignContractSelector,
  (resellerSignContract) => !!resellerSignContract,
);

export const resellerSignContractAcceptBtnDisabledSelector = createSelector(
  resellerSignContractSelector,
  resellerStoreContractReadSelector,
  (resellerSignContract, resellerStoreContractRead) =>
    !resellerStoreContractRead || !!resellerSignContract,
);
