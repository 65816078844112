import { OptionType } from '@ezetech/swag-space-x';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { MultiValue } from 'react-select';
import { ReducerPath } from 'interfaces';
import { CartStatusEnum, ICartNotes, ICreateACartReq } from 'interfaces/cart.interfaces';
import { IGetCartsRequest, IGetCartsResponse } from 'interfaces/cart.interfaces';
import { makeToastNotification, makeSuccessToastNotification } from 'utils/query.util';
import { setFilter } from 'redux/slices/filter.slice';
import { closePopup, openPopup } from 'redux/slices/modals.slice';
import { setCartNotes, setCarts, setMyOrdersCount } from 'redux/slices/carts.slice';
import { filtersRequestSelector } from 'redux/selectors/filters.selectors';
import { CART_NOTES_POPUP } from 'components/popups/_logic/popups-list';
import { RootState } from 'redux/store';
import { ASSIGNED_TO_ME_ENTITY } from 'constants/common';

import { IProposalToReAssign } from 'constants/assignment';
import { pushNotification } from '../slices/notifications.slice';
import { baseQueryParams } from './helpers';

export const cartApi = createApi({
  reducerPath: ReducerPath.cart,
  baseQuery: fetchBaseQuery({ ...baseQueryParams }),
  endpoints: (builder) => ({
    preCreateACart: builder.mutation<IProposalToReAssign[], ICreateACartReq>({
      query: (payload) => ({
        url: '/cart/pre-create',
        method: 'GET',
        params: payload,
      }),
    }),

    createACart: builder.query<void, ICreateACartReq>({
      query: (payload) => ({
        url: '/cart/create',
        method: 'GET',
        params: payload,
      }),
    }),

    getCarts: builder.mutation<IGetCartsResponse, IGetCartsRequest>({
      query: ({ dropdown, ...rest }) => {
        const filter: Record<string, string[]> = {};

        (dropdown as MultiValue<OptionType>).forEach(({ value }) => {
          if (value === CartStatusEnum.ORDERED || value === CartStatusEnum.PENDING) {
            filter.status = [...(filter.status || []), value] || [];
          }

          if (value === ASSIGNED_TO_ME_ENTITY) {
            filter.assignedToMeEntity = [ASSIGNED_TO_ME_ENTITY];
          }
        });

        return {
          url: '/cart',
          method: 'GET',
          params: {
            ...rest,
            filter: JSON.stringify(filter),
          },
        };
      },

      async onQueryStarted(
        { dropdown, search, sorting, sortingOrder },
        { dispatch, queryFulfilled },
      ) {
        try {
          const { data } = await queryFulfilled;

          if (data) {
            const { carts, total, page, perPage } = data;

            dispatch(setCarts({ carts, total }));
            dispatch(setMyOrdersCount({ carts: total }));
            dispatch(
              setFilter({
                page,
                perPage,
                dropdown,
                search: search || '',
                sorting,
                sortingOrder,
                totalCount: total,
              }),
            );
          }
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),

    deleteCart: builder.mutation<void, { cartId: string }>({
      query: ({ cartId }) => ({
        url: `/cart/${cartId}`,
        method: 'DELETE',
      }),
      async onQueryStarted(_, { dispatch, getState, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(closePopup());
          dispatch(
            pushNotification(makeSuccessToastNotification('Cart successfully deleted')),
          );

          const state = getState();
          const filters = filtersRequestSelector(state as RootState);

          dispatch(cartApi.endpoints.getCarts.initiate(filters));
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),

    editCart: builder.query<void, { cartId: string }>({
      query: ({ cartId }) => ({
        url: `/notes/${cartId}`,
        method: 'GET',
      }),
    }),

    getCartNotes: builder.mutation<ICartNotes, { cartId: string; email: string }>({
      query: ({ cartId }) => ({
        url: `/cart/notes/${cartId}`,
        method: 'GET',
      }),
      async onQueryStarted({ cartId, email }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          if (data) {
            dispatch(setCartNotes(data));

            dispatch(
              openPopup({ popupName: CART_NOTES_POPUP, popupProps: { cartId, email } }),
            );
          }
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),
  }),
});

export const {
  useLazyCreateACartQuery,
  useGetCartsMutation,
  useDeleteCartMutation,
  useLazyEditCartQuery,
  useGetCartNotesMutation,
  usePreCreateACartMutation,
} = cartApi;
