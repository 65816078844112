export const FIELDS = {
  instagram: {
    label: 'URL of your Instagram account',
    name: 'instagram',
    placeholder: 'https://www.instagram.com/username',
  },
  facebook: {
    label: 'URL of your Facebook page',
    name: 'facebook',
    placeholder: 'https://www.facebook.com/username',
  },
  tiktok: {
    label: 'URL of your TikTok account',
    name: 'tiktok',
    placeholder: 'https://www.tiktok.com/@username',
  },
  twitter: {
    label: 'URL of your Twitter account',
    name: 'twitter',
    placeholder: 'https://www.twitter.com/username',
  },
  linkedIn: {
    label: 'URL of your LinkedIn account',
    name: 'linkedIn',
    placeholder: 'https://www.linkedin.com/in/username',
  },
};
