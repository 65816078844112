import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { pushNotification } from 'redux/slices/notifications.slice';
import { makeToastNotification } from 'utils/query.util';
import { turnOnAllPermissions } from 'utils/permission.util';
import { setPermissions } from 'redux/slices/invitations.slice';
import { baseQueryParams } from './helpers';

export const permissionApi = createApi({
  reducerPath: 'permissionApi',
  baseQuery: fetchBaseQuery({ ...baseQueryParams }),
  endpoints: (builder) => ({
    getPermissionsForSettings: builder.mutation({
      query: () => '/permissions/settings',
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setPermissions(turnOnAllPermissions(data)));
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),
  }),
});

export const { useGetPermissionsForSettingsMutation } = permissionApi;
