import { IDashboardData } from 'interfaces';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { IDashboardTier } from '../../../interfaces/dashboard-api.interface';

type CalculatedDataForTierResult = {
  overallRevenueToNextTier: number;
  lastYearOverallRevenue: number;

  revenueFormatted: string;
  revenueToNextTierFormatted: string;
  bottomThresholdFormatted: string;
};

const TIERS_COLORS: Record<string, string> = {
  'Tier 1': COLORS.colorPink,
  'Tier 2': COLORS.colorSecondaryText500,
  'Tier 3': COLORS.colorPrimaryPurple,
};

export function getNextTier(
  tier: IDashboardTier,
  tiers: IDashboardTier[],
): IDashboardTier | undefined {
  const nextTier = tiers.find(
    (item) =>
      tier.topThreshold === item.bottomThreshold &&
      tier.bottomThreshold < item.bottomThreshold,
  );
  return nextTier;
}

export function getColorByTierName(tierName: string): string {
  return TIERS_COLORS[tierName] || TIERS_COLORS['Tier 1'];
}

export const currencyFormat = (value: number | bigint): string =>
  new Intl.NumberFormat('en-US').format(value);

export const getCalculatedDataForTier = (
  data: IDashboardData,
): CalculatedDataForTierResult => {
  const lastYearOverallRevenue = data.overallRevenue - data.revenue;
  const overallRevenueToNextTier =
    lastYearOverallRevenue + data.tierPromotion.topThreshold;

  const revenueToNextTier = data.tierPromotion.topThreshold - data.revenue;

  const revenueFormatted = currencyFormat(data.revenue);
  const revenueToNextTierFormatted = currencyFormat(revenueToNextTier);
  const bottomThresholdFormatted = currencyFormat(data.tierPromotion.bottomThreshold);

  return {
    overallRevenueToNextTier,
    lastYearOverallRevenue,

    revenueFormatted,
    revenueToNextTierFormatted,
    bottomThresholdFormatted,
  };
};

export const calculateTierMargin = (margin: number, marginIncrement: number): number => {
  const result = Math.floor(margin * 100 + marginIncrement * 100);
  return result;
};
