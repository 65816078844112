import { ITextToggleProps } from '@ezetech/swag-space-x';
import {
  PdfPresentationEditorFilter,
  PdfPresentationEditorPage,
  PdfPresentationEditorPageId,
} from 'interfaces/pdf-presentation';

export const REVIEWS_LENGTH = 6;
export const BRAND_LOGOS_LENGTH = 16;

export enum PageSidebarWidth {
  from1920toInfinity = 281,
  from1820to1919 = 278,
  from1720to1819 = 262,
  from1620to1719 = 247,
  from1520to1619 = 224,
  from1420to1519 = 207,
  from1320to1419 = 193,
  from1220to1319 = 178,
  from1024to1219 = 80.4,
  from768to1023 = 53.6,
}

export enum PreviewWidth {
  from1920toInfinity = 1002,
  from1820to1919 = 950,
  from1720to1819 = 897,
  from1620to1719 = 845,
  from1520to1619 = 793,
  from1420to1519 = 748,
  from1320to1419 = 714,
  from1220to1319 = 646,
  from1024to1219 = 657,
  from768to1023 = 401,
  fromStartTo767 = 375,
}

export const PDF_PRESENTATION_FILTERS: Omit<ITextToggleProps['buttons'][0], 'onClick'>[] =
  [
    { id: PdfPresentationEditorFilter.ALL, text: 'All' },
    { id: PdfPresentationEditorFilter.EDITABLE, text: 'Editable' },
    {
      id: PdfPresentationEditorFilter.NON_EDITABLE,
      text: 'Non Editable',
    },
  ];

export const PDF_PRESENTATION_PAGES: PdfPresentationEditorPage[] = [
  {
    id: PdfPresentationEditorPageId.COVER,
    name: 'Cover',
    isCanBeHidden: true,
    isHiddenKeyName: 'isCoverHidden',
  },
  {
    id: PdfPresentationEditorPageId.WHAT_WE_DO,
    name: 'What We Do',
    isCanBeHidden: true,
    isHiddenKeyName: 'isWhatWeDoHidden',
  },
  {
    id: PdfPresentationEditorPageId.WHO_WE_WORK_WITH,
    name: 'Who We Work With',
    isCanBeHidden: true,
    isHiddenKeyName: 'isWhoWeWorkWithHidden',
  },
  {
    id: PdfPresentationEditorPageId.PAST_WORKS,
    name: 'Past Works',
    isCanBeHidden: true,
    isHiddenKeyName: 'isPastWorksHidden',
  },
  {
    id: PdfPresentationEditorPageId.BOX_OVERVIEW,
    name: 'Box Overview',
    isCanBeHidden: false,
  },
  {
    id: PdfPresentationEditorPageId.BOX_PAGE,
    name: 'Box Page',
    isCanBeHidden: false,
  },
  {
    id: PdfPresentationEditorPageId.PRODUCT_PAGE,
    name: 'Product Page',
    isCanBeHidden: false,
  },
  {
    id: PdfPresentationEditorPageId.SUMMARY,
    name: 'Order Summary',
    isCanBeHidden: false,
  },
  {
    id: PdfPresentationEditorPageId.REVIEWS,
    name: 'Reviews',
    isCanBeHidden: true,
    isHiddenKeyName: 'isReviewsHidden',
  },
  {
    id: PdfPresentationEditorPageId.SO_MUCH_MORE,
    name: 'So Much More',
    isCanBeHidden: true,
    isHiddenKeyName: 'isSoMuchMoreHidden',
  },
  {
    id: PdfPresentationEditorPageId.BENEFITS,
    name: 'Tons Of Benefits',
    isCanBeHidden: true,
    isHiddenKeyName: 'isTonsOfBenefitsHidden',
  },
  {
    id: PdfPresentationEditorPageId.SHOWCASE,
    name: 'Product Showcase',
    isCanBeHidden: true,
    isHiddenKeyName: 'isShowcaseHidden',
  },
  {
    id: PdfPresentationEditorPageId.THANK_YOU,
    name: 'Thank You',
    isCanBeHidden: true,
    isHiddenKeyName: 'isThankYouHidden',
  },
];

export const PDF_PRESENTATION_PAGES_NORMALIZED = PDF_PRESENTATION_PAGES.reduce(
  (acc, page) => {
    if (!acc[page.id]) {
      acc[page.id] = page;
    }
    return acc;
  },
  {} as Record<string, PdfPresentationEditorPage>,
);
