import { FC } from 'react';
import { Typography } from '@ezetech/swag-space-x';
import { show } from '@intercom/messenger-js-sdk';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { makeBold, makeLink } from '../sign-contract.util';
import css from '../sign-contract.module.scss';

export const General: FC = () => {
  return (
    <>
      <Typography color={COLORS.colorPrimaryText} fontType="bodyMdBold">
        14. General
      </Typography>
      <br />
      <br />
      We think direct communication resolves most issues – if we feel that you are not
      complying with these Terms, we will tell you. We will even provide you with
      recommended necessary corrective action(s) because we value this relationship.
      However, certain violations of these Terms, as determined by us, may require
      immediate termination of your access to the Services without prior notice to you.
      <br />
      <br />
      The Federal Arbitration Act, Virginia state law and applicable U.S. federal law,
      without regard to the choice or conflicts of law provisions, will govern these
      Terms. Unless we expressly indicate so, foreign laws do not apply. Except for
      disputes subject to arbitration as described above, any disputes relating to these
      Terms or the Services will be heard in the courts located in Fairfax County,
      Virginia. If any of these Terms are deemed inconsistent with applicable law, then
      such term(s) shall be interpreted to reflect the intentions of the parties, and no
      other terms will be modified. By choosing not to enforce any of these Terms, we are
      not waiving our rights. These Terms are the entire agreement between you and Swag
      Space and, therefore, supersede all prior or contemporaneous negotiations,
      discussions or agreements between you and Swag Space about the Services. The
      proprietary rights, disclaimer of warranties, any representations made by you,
      indemnities, limitations of liability and general provisions shall survive any
      termination of these Terms. These Terms are binding on the parties, their successors
      and assigns. You shall not assign these Terms or any license hereunder without the
      express written consent of Swag Space. Any other attempt by you to sublicense,
      assign or transfer these Terms or the licenses granted hereunder shall be null and
      void. There are no representations, promises, warranties or understandings relied
      upon by you which are not contained herein. If any provision of these Terms is found
      unenforceable, the remaining provisions shall continue in full force and effect.
      Swag Space will not be in default hereunder by reason of any failure or delay in the
      performance of its obligations where such failure or delay is due to civil
      disturbances, riot, epidemic, pandemic, hostilities, war, terrorist attack, embargo,
      natural disaster, acts of God, flood, fire, sabotage, fluctuations or unavailability
      of electrical power, network access or equipment, or any other circumstances or
      causes beyond Swag Space’s reasonable control.
      <br />
      <br />
      You agree to comply with all laws, restrictions and regulations relating to the
      export of products and information. For purposes of the U.S. Export Administration
      Act ({makeBold('"Export Laws"')}), you state you are: (a) not a citizen, or
      otherwise located within, an embargoed nation (including without limitation the
      Office of Foreign Assets Control ({makeBold('"OFAC"')}) comprehensively embargoed
      countries and certain Specially Designated Nationals ({makeBold('SDN')}) listed by
      OFAC as updated from time to time), (b) not a SDN, and (b) not otherwise prohibited
      under the Export Laws from receiving such products and information. Funds may be
      frozen and/or turned over to the applicable governmental enforcement agency if a
      Branded Storefront is deemed to be in violation of Export Laws.
      <br />
      <br />
      If you have any questions about these Terms or otherwise need to contact us for any
      reason, you can reach us at{' '}
      {makeLink('contact@swag.space', 'mailto:contact@swag.space')} or contact us on
      <Typography
        color={COLORS.colorPink}
        fontType="bodyMd"
        onClick={show}
        className={css.pointerText}
      >
        {' '}
        chat
      </Typography>
      .
    </>
  );
};
