import cn from 'classnames';
import { Divider } from '@ezetech/swag-space-x';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import css from './separator.module.scss';

export function Separator({ className }: { className?: string }): JSX.Element {
  return <Divider className={cn(css.container, className)} color={COLORS.colorDivider} />;
}

export function VerticalSeparator(): JSX.Element {
  return <div className={css.verticalContainer} />;
}
