import * as yup from 'yup';
import { EMAIL_LENGTH, ERROR_MSG, emailRegexp } from '../../constants/validation';

import { FIELDS } from './reset-password.constants';

export const resetPasswordSchema = yup.object({
  [FIELDS.email.name]: yup
    .string()
    .trim()
    .matches(emailRegexp, ERROR_MSG.email)
    .max(EMAIL_LENGTH, ERROR_MSG.max(EMAIL_LENGTH))
    .required(ERROR_MSG.required),
});
