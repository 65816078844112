import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDashboardData } from 'interfaces';

const initialState: IDashboardData = {
  orders: [],
  tiers: [],

  sales: { total: 0, percent: 0, ordersCount: 0 },
  leads: { total: 0, percent: 0 },
  margin: 0,
  overallRevenue: 0,
  revenue: 0,
  revenuePeriodFrom: '',
  revenuePeriodTo: '',
  tierPeriodFrom: '',
  tierPeriodTo: '',
  tierPromotion: {
    name: '',
    topThreshold: 0,
    bottomThreshold: 0,
    validUntil: '',
    marginIncrement: 0,
  },
  referralLink: '',
  totalEarned: 0,
  pendingReferrals: 0,
  referralsCount: 0,
};

export const dashboardSlice = createSlice({
  initialState,
  name: 'dashboardSlice',
  reducers: {
    getDashboard: (state) => state,
    setDashboard: (state, action: PayloadAction<IDashboardData | null>) => {
      return action.payload ? { ...action.payload } : { ...state };
    },
  },
});

export const dashboardReducer = dashboardSlice.reducer;
export const { getDashboard, setDashboard } = dashboardSlice.actions;
