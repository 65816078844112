import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { ReducerPath } from 'interfaces';
import {
  IReferralsActionsListRequest,
  IReferralsListResponse,
} from 'interfaces/referrals.interface';
import { setLoading, setReferralsActions } from 'redux/slices/referrals.slice';
import { makeToastNotification } from 'utils/query.util';

import { pushNotification } from '../slices/notifications.slice';
import { baseQueryParams } from './helpers';

export const referralsApi = createApi({
  reducerPath: ReducerPath.referrals,
  baseQuery: fetchBaseQuery(baseQueryParams),
  endpoints: (builder) => ({
    getActionsList: builder.mutation<
      IReferralsListResponse,
      IReferralsActionsListRequest
    >({
      query: (params) => ({
        url: '/referrals/list',
        method: 'GET',
        params,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          dispatch(setLoading(true));
          const { data } = await queryFulfilled;

          if (data) {
            dispatch(
              setReferralsActions({
                list: data.list,
                total: data.total,
                perPage: data.perPage,
                page: data.page,
              }),
            );
          }
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        } finally {
          dispatch(setLoading(false));
        }
      },
    }),
  }),
});

export const { useGetActionsListMutation } = referralsApi;
