import {
  Dialog,
  DialogContentWrapper,
  DialogTitle,
  DialogDescription,
  DialogFooter,
  SwagButton,
} from '@ezetech/swag-space-x';
import { FieldValues, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { closePopup, openPopup, patchProps } from 'redux/slices/modals.slice';
import { CreateAPresentationTypePopupProps } from 'interfaces/popup.interface';
import { InputForm } from 'components/input-form';

import { API_URL } from 'redux/api/helpers';
import { usePreCreateAPresentationMutation } from 'redux/api/presentation.api';
import { BUTTONS, DESCRIPTIONS, HEADERS } from 'constants/assignment';
import { popupNameSelector } from 'redux/selectors/modals.selectors';
import { FIELDS } from '../create-a-cart-email/create-a-cart-email.constants';
import { CONFIRMATION_POPUP } from '../_logic/popups-list';
import css from './create-a-presentation.popup.module.scss';

export const CreateAPresentationEmailPopup = ({
  email,
  isOpen,
}: CreateAPresentationTypePopupProps): JSX.Element => {
  const { handleSubmit, control, formState, getValues, setValue, setFocus } =
    useForm<FieldValues>({
      mode: 'all',
    });
  const [preCreateAPresentation, { isLoading: isLoadingPreCreate }] =
    usePreCreateAPresentationMutation();
  const [loading, setLoading] = useState(false);
  const currentPopupName = useAppSelector(popupNameSelector);

  const dispatch = useAppDispatch();

  const { isValid } = formState;
  const emailEntered = Boolean(getValues(FIELDS.email.name)?.length);

  useEffect(() => {
    setFocus(FIELDS.email.name);
  }, [setFocus]);

  const isDisabled = !isValid || !emailEntered || loading;

  useEffect(() => {
    if (email?.length) {
      setValue(FIELDS.email.name, email, { shouldDirty: true, shouldValidate: true });
    }
  }, [email]);

  const onBtnCloseClick = () => {
    if (loading || isLoadingPreCreate) {
      return;
    }
    dispatch(closePopup());
  };

  const onBtnContinueClick = () => {
    setLoading(true);
    handleSubmit(async (data) => {
      const result = await preCreateAPresentation({
        email: data[FIELDS.email.name] || '',
      });
      const queryParams = new URLSearchParams({
        email: data[FIELDS.email.name] || '',
      }).toString();
      if ('data' in result) {
        const hasReassignProposals = result.data?.length;
        const hasAssignment = result.data?.every((assignment) =>
          Boolean(assignment.currentAssignmentResellerId),
        );

        const descriptionComponent = hasAssignment
          ? DESCRIPTIONS.HAS_ASSIGNMENT
          : DESCRIPTIONS.HAS_NOT_ASSIGNMENT;
        const headerComponent = hasAssignment
          ? HEADERS.HAS_ASSIGNMENT
          : HEADERS.HAS_NOT_ASSIGNMENT;
        const buttonComponent = hasAssignment
          ? BUTTONS.HAS_ASSIGNMENT
          : BUTTONS.HAS_NOT_ASSIGNMENT;

        if (hasReassignProposals) {
          dispatch(
            openPopup({
              popupName: CONFIRMATION_POPUP,
              popupProps: {
                title: headerComponent,
                descriptionComponent,
                onXClick: () => {
                  dispatch(closePopup());
                },
                onCancel: () => {
                  setLoading(true);
                  document.location.replace(
                    `${API_URL}/presentation/create?${queryParams}`,
                  );
                },
                onConfirm: () => {
                  setLoading(true);
                  const queryParamsWithReassign = new URLSearchParams({
                    email: data[FIELDS.email.name] || '',
                    reassign: 'true',
                  }).toString();

                  document.location.replace(
                    `${API_URL}/presentation/create?${queryParamsWithReassign}`,
                  );
                },
                confirmText: buttonComponent,
                cancelText: 'Keep it as it is',
              },
            }),
          );
          return;
        }
      }

      document.location.replace(`${API_URL}/presentation/create?${queryParams}`);
    })();
  };
  useEffect(() => {
    if (currentPopupName === CONFIRMATION_POPUP) {
      const loadingInProcess = loading || isLoadingPreCreate;
      dispatch(
        patchProps({
          isPopupLoading: loadingInProcess,
          onXClick: () => !loadingInProcess && dispatch(closePopup()),
        }),
      );
    }
  }, [loading, isLoadingPreCreate]);

  return (
    <Dialog open={isOpen} onOpenChange={onBtnCloseClick}>
      <DialogContentWrapper
        className={css.dialogContentWrapperClassName}
        overlayClassName={css.dialogOverlayClassName}
      >
        <DialogTitle closeIconClassName={css.closeIconClassName}>
          What customer is this for?
        </DialogTitle>
        <DialogDescription className={css.contentWrapper}>
          <InputForm
            autoComplete="off"
            type="text"
            label={FIELDS.email.label}
            errorClassName={css.errorClassName}
            labelClassName={css.labelClassName}
            shouldTrimInputValue
            name={FIELDS.email.name}
            placeholder={FIELDS.email.placeholder}
            rules={FIELDS.email.rules}
            control={control}
          />
        </DialogDescription>

        <DialogFooter className={css.footer}>
          <SwagButton
            type="outlined"
            onClick={onBtnCloseClick}
            className={classNames({
              [css.btnHover]: loading || isLoadingPreCreate,
            })}
          >
            Cancel
          </SwagButton>
          <SwagButton
            disabled={isDisabled}
            type="primary"
            onClick={onBtnContinueClick}
            loading={loading || isLoadingPreCreate}
          >
            Continue
          </SwagButton>
        </DialogFooter>
      </DialogContentWrapper>
    </Dialog>
  );
};
