import { Typography } from '@ezetech/swag-space-x';

import { useAppSelector } from 'redux/store';
import { inHandDateComponentSelector } from 'redux/selectors/orders.selectors';
import COLORS from 'constants/styles/colors-js.constant.module.scss';

import css from '../../order-card-item.module.scss';

interface IProps {
  orderId: string;
  itemId: string;
}

export const InHandDateComponent = ({ orderId, itemId }: IProps): JSX.Element => {
  const { estInHand, label } = useAppSelector(
    inHandDateComponentSelector({ orderId, itemId }),
  );

  return (
    <div className={css.inHandDate}>
      <Typography
        lineHeight="170%"
        fontType="bodyMd"
        color={COLORS.colorSecondaryText}
        className={css.label}
      >
        {label}
      </Typography>
      <Typography
        lineHeight="170%"
        fontType="bodyMdBold"
        color={COLORS.colorPrimaryText}
        className={css.value}
      >
        {estInHand}
      </Typography>
    </div>
  );
};
