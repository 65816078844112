import { Typography } from '@ezetech/swag-space-x';

import { Price } from 'utils/price.util';
import COLORS from 'constants/styles/colors-js.constant.module.scss';

import css from './order-card-item-footer.module.scss';

interface IProps {
  total: number;
  setupFee?: number;
  shipping?: number;
}

export const OrderCardItemFooterComponent = ({ total, setupFee, shipping }: IProps) => {
  return (
    <div className={css.footer}>
      {Boolean(setupFee) && (
        <div className={css.price}>
          <Typography
            lineHeight="180%"
            fontType="bodyMd"
            color={COLORS.colorSecondaryText}
          >
            Setup Fee:
          </Typography>
          <Typography
            lineHeight="180%"
            fontType="bodyMdBold"
            color={COLORS.colorPrimaryText}
          >
            {Price.formatPrice(setupFee)}
          </Typography>
        </div>
      )}
      {Boolean(shipping) && (
        <div className={css.price}>
          <Typography
            lineHeight="180%"
            fontType="bodyMd"
            color={COLORS.colorSecondaryText}
          >
            Shipping:
          </Typography>
          <Typography
            lineHeight="180%"
            fontType="bodyMdBold"
            color={COLORS.colorPrimaryText}
          >
            {Price.formatPrice(shipping)}
          </Typography>
        </div>
      )}
      <div className={css.price}>
        <Typography lineHeight="180%" fontType="bodyMd" color={COLORS.colorSecondaryText}>
          Order Total:
        </Typography>
        <Typography
          lineHeight="180%"
          fontType="bodyMdBold"
          color={COLORS.colorPrimaryText}
        >
          {Price.formatPrice(total)}
        </Typography>
      </div>
    </div>
  );
};
