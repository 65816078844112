import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  CellSize,
  MultiOptionType,
  OptionsGroupedType,
  OptionType,
  Pagination,
} from '@ezetech/swag-space-x';
import { PageContent } from 'components/page-content';
import { TableToolbar } from 'components/table-toolbar';
import { CLEntity } from 'interfaces/customers-leads.interface.api';
import { useAppDispatch, useAppSelector } from 'redux/store';
import {
  filtersRequestSelector,
  lastPageFiltersSelector,
  pageFiltersSelector,
} from 'redux/selectors/filters.selectors';
import { useGetUsersMutation } from 'redux/api/user.api';
import {
  customersFilterOptionsSelector,
  customersHeaderSelector,
  isPaginationVisibleSelector,
  leadsFilterOptionsSelector,
  leadsHeaderSelector,
} from 'redux/selectors/customers-leads.selectors';
import { setFilter } from 'redux/slices/filter.slice';
import { Tabs } from './tabs';
import { LeadsTable } from './leads-table';
import { CustomersTable } from './customers-table';

import classes from './customers-and-leads.module.scss';

export function CustomersAndLeadsComponent() {
  const [options, setOptions] = useState<OptionType[] | OptionsGroupedType[]>([]);
  const location = useLocation();
  const filters = useAppSelector(filtersRequestSelector);
  const page = useAppSelector(pageFiltersSelector);
  const lastPage = useAppSelector(lastPageFiltersSelector);
  const isPaginationVisible = useAppSelector(isPaginationVisibleSelector);
  const customersHeader = useAppSelector(customersHeaderSelector);
  const leadsHeader = useAppSelector(leadsHeaderSelector);
  const customersFilterOptions = useAppSelector(customersFilterOptionsSelector);
  const leadsFilterOptions = useAppSelector(leadsFilterOptionsSelector);
  const dispatch = useAppDispatch();
  const [getUsers] = useGetUsersMutation();
  const screen = location.pathname.split('/').pop() as CLEntity;
  const isCustomersPage = screen === CLEntity.customers;
  const isLeadsPage = screen === CLEntity.leads;

  useEffect(() => {
    setOptions(isCustomersPage ? customersFilterOptions : leadsFilterOptions);
  }, [location.pathname]);

  const handlePageClick = (value: number) => {
    getUsers({
      ...filters,
      page: value,
      customersOrLeads: screen,
    });
  };
  const handleSearchChange = (search: string) => {
    getUsers({
      ...filters,
      search,
      page: 1,
      customersOrLeads: screen,
    });
  };

  const handleFilterClick = (selected: MultiOptionType) => {
    getUsers({
      ...filters,
      dropdown: selected,
      page: 1,
      customersOrLeads: screen,
    });
  };

  const handleSortingClick = (field: string) => {
    const { sorting, sortingOrder } = filters;
    const isSortingNew = sorting !== field;
    const oppositeSortingForAppliedField = sortingOrder === 'ASC' ? 'DESC' : 'ASC';
    const newSortingOrder = isSortingNew ? 'ASC' : oppositeSortingForAppliedField;

    dispatch(setFilter({ sortingOrder: newSortingOrder, sorting: field }));
    getUsers({
      ...filters,
      page: 1,
      sorting: field,
      sortingOrder: newSortingOrder,
      customersOrLeads: screen,
    });
  };

  const getSortingIconDirection = (id: string): 'up' | 'down' => {
    const list: { text: string; id: string; size?: CellSize; withSorting?: boolean }[] =
      isCustomersPage ? customersHeader : leadsHeader;
    const cell = list.find((c) => c.id === id);

    if (!cell?.withSorting || cell.id !== filters.sorting) {
      return 'down';
    }

    return filters.sortingOrder === 'DESC' ? 'down' : 'up';
  };

  return (
    <PageContent pageContentClassName={classes.wrapper}>
      <Tabs />
      <TableToolbar
        onNextPageClick={handlePageClick}
        onSearchChange={handleSearchChange}
        onFilterClick={handleFilterClick}
        filterOptions={options}
        className={classes.toolbar}
        exportTooltipText={`Export ${screen} list`}
        isFilterMultiSelect
      />
      {isCustomersPage && (
        <CustomersTable
          getSortingDirection={getSortingIconDirection}
          onHeaderCellClick={handleSortingClick}
        />
      )}
      {isLeadsPage && (
        <LeadsTable
          getSortingDirection={getSortingIconDirection}
          onHeaderCellClick={handleSortingClick}
        />
      )}
      {isPaginationVisible && (
        <Pagination
          paginationClassName={classes.pagination}
          goToPage={handlePageClick}
          page={page}
          lastPage={lastPage}
        />
      )}
    </PageContent>
  );
}
