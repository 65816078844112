import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { redirectUrlAfterPopupCloseSelector } from 'redux/selectors/modals.selectors';

import { useAppSelector } from '../redux/store';

export function useModalsRedirects() {
  const navigate = useNavigate();
  const redirectUrlAfterPopupClose = useAppSelector(redirectUrlAfterPopupCloseSelector);

  useEffect(() => {
    if (redirectUrlAfterPopupClose) {
      navigate(redirectUrlAfterPopupClose);
    }
  }, [redirectUrlAfterPopupClose]);
}
