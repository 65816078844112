import { useCallback, useState } from 'react';

import {
  getCompletedPasswordRequirements,
  isAllPasswordRequirementsCompleted,
  isPasswordLengthMoreThanMaxAllowedCharacters,
} from './password-requirements-utils';

export const usePasswordRequirementsHook = () => {
  const [isFocused, setIsFocused] = useState(false);
  const [isLengthError, setIsLengthError] = useState(false);
  const [isAllRequirementsCompleted, setIsAllRequirementsCompleted] = useState(false);
  const [completedRequirements, setCompletedRequirements] = useState<Array<number>>([]);

  const onFieldChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const password = event.target.value;
    const indexes = getCompletedPasswordRequirements(password);

    setCompletedRequirements(indexes);

    const lengthError = isPasswordLengthMoreThanMaxAllowedCharacters(password);
    setIsLengthError(lengthError);

    const completed = isAllPasswordRequirementsCompleted(password, indexes);
    setIsAllRequirementsCompleted(completed);
  }, []);

  const onFieldBlur = useCallback((event: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(false);
  }, []);

  const onFieldFocus = useCallback((event: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(true);
  }, []);

  return {
    onFieldChange,
    onFieldFocus,
    onFieldBlur,
    completedRequirements,
    isFocused,
    isAllRequirementsCompleted,
    isLengthError,
  };
};
