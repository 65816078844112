import { Controller } from 'react-hook-form';
import { Checkbox } from '@ezetech/swag-space-x';

import { CheckboxFormProps } from './checkbox-form.interface';

export function CheckboxForm({
  name,
  rules,
  control,
  label,
}: CheckboxFormProps): JSX.Element {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { value, onChange, ...rest } }) => (
        <Checkbox {...rest} label={label} checked={value} onCheckedChange={onChange} />
      )}
    />
  );
}
