import { IFile } from '@ezetech/swag-space-x';

export enum ResellerCertificateStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  EXPIRED = 'EXPIRED',
}

export interface IS3Document extends Omit<IFile, 'name'> {
  originalname: string;
}
export interface IResellerCertificate {
  id: string;
  state: string;
  status: ResellerCertificateStatus;
  document: IS3Document;
  updatedAt: string;
  expiredAt: string;
  createdAt: string;
}

export interface IGetResellerCertificatesRequest {
  page: string | number;
}

export interface IResellerCertificatesResponse {
  list: IResellerCertificate[];
  total: number;
  perPage: number;
  page: number;
}

export interface IResellerCertificateState extends IResellerCertificatesResponse {
  isLoading: boolean;
}
