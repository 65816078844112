import { FC } from 'react';
import { Divider, Typography } from '@ezetech/swag-space-x';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { useAppSelector } from 'redux/store';
import { orderItemsByOrderIdSelector } from 'redux/selectors/orders.selectors';
import { OrderItemsTableRow } from './order-items-table-row';
import css from './order-items-table.module.scss';

export const OrderItemsTable: FC<{
  orderId: string;
  onTaxChange(itemId: string, value: string): void;
  isTaxExempt: boolean;
}> = ({ orderId, onTaxChange, isTaxExempt }) => {
  const items = useAppSelector(orderItemsByOrderIdSelector(orderId));

  return (
    <div className={css.table}>
      <div className={css.header}>
        <Typography
          className={css.name}
          color={COLORS.colorText900}
          fontType="bodyMd"
          lineHeight="162.5%"
        >
          Product
        </Typography>
        <Typography
          className={css.quantity}
          color={COLORS.colorText900}
          fontType="bodyMd"
          lineHeight="162.5%"
        >
          Quantity
        </Typography>
        <Typography
          className={css.unitPrice}
          color={COLORS.colorText900}
          fontType="bodyMd"
          lineHeight="162.5%"
        >
          Unit Price
        </Typography>
        <Typography
          className={css.setupFee}
          color={COLORS.colorText900}
          fontType="bodyMd"
          lineHeight="162.5%"
        >
          Setup Fee
        </Typography>
        <Typography
          className={css.shipping}
          color={COLORS.colorText900}
          fontType="bodyMd"
          lineHeight="162.5%"
        >
          Shipping
        </Typography>
        <Typography
          className={css.tax}
          color={COLORS.colorText900}
          fontType="bodyMd"
          lineHeight="162.5%"
        >
          Tax
        </Typography>
        <Typography
          className={css.amount}
          color={COLORS.colorText900}
          fontType="bodyMd"
          lineHeight="162.5%"
        >
          Amount USD
        </Typography>
      </div>
      <Divider className={css.divider} />
      <div className={css.body}>
        {items.map((item, index) => (
          <OrderItemsTableRow
            key={item.id}
            onTaxChange={onTaxChange}
            item={item}
            isLast={index === items.length - 1}
            isTaxExempt={isTaxExempt}
          />
        ))}
      </div>
    </div>
  );
};
