import { useState } from 'react';
import {
  Dialog,
  DialogContentWrapper,
  DialogTitle,
  DialogDescription,
  DialogFooter,
  SwagButton,
  Typography,
} from '@ezetech/swag-space-x';
import { parse } from 'tldts';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { closePopup, openPopup } from 'redux/slices/modals.slice';
import { DNSSettingsPopupProps } from 'interfaces/popup.interface';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { useClipboard } from 'hooks/use-clipboard.hook';
import { NotificationType, pushNotification } from 'redux/slices/notifications.slice';
import { CopyIcon } from 'components/svg/copy';
import {
  domainSettingsSelector,
  isBackAllowedSelector,
} from 'redux/selectors/settings.selectors';
import { useVerifyDomainMutation } from 'redux/api/settings.api';
import { CONNECT_DOMAIN_POPUP, VERIFY_CONNECTION_FINISHED } from '../_logic/popups-list';
import css from './dns-settings.popup.module.scss';

const DOMAIN_VERIFICATION_ERROR_MESSAGE =
  'Please ensure you have entered the correct values. Some providers take a few minutes to update settings. You can check later for connection updates, or you can try again.';

export const DNSSettingsPopup = ({ isOpen }: DNSSettingsPopupProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { copy } = useClipboard();

  const [verifyDomain, { isLoading }] = useVerifyDomainMutation();
  const customDomainInfo = useAppSelector(domainSettingsSelector);
  const isBackAllowed = useAppSelector(isBackAllowedSelector);
  const parsedDomain = parse(customDomainInfo?.domain || '');

  const isSubdomain = customDomainInfo?.isSubdomain || false;
  const subdomain = isSubdomain ? parsedDomain.subdomain : 'www';
  const rootDomain = isSubdomain ? parsedDomain.domain : customDomainInfo?.domain;
  const aRecordValues = (customDomainInfo?.aRecord?.value || []) as string[];
  const cnameRecordValue = (customDomainInfo?.cnameRecord?.value || '') as string;

  const onBackButtonClick = () => {
    setShowDomainVerificationError(false);
    dispatch(
      openPopup({ popupName: CONNECT_DOMAIN_POPUP, popupProps: { mode: 'edit' } }),
    );
  };
  const [showDomainVerificationError, setShowDomainVerificationError] = useState(false);

  const handleOnOpenChange = () => {
    setShowDomainVerificationError(false);
    dispatch(closePopup());
  };
  const onVerifyConnectionClick = async () => {
    const response = await verifyDomain({ domainId: customDomainInfo?.id || '' });
    if ('data' in response) {
      dispatch(
        openPopup({
          popupName: VERIFY_CONNECTION_FINISHED,
          popupProps: { customDomainName: customDomainInfo?.domain },
        }),
      );
    } else {
      setShowDomainVerificationError(true);
    }
  };
  const handleCopyClick = async (code: string): Promise<void> => {
    const isSuccess = await copy(code);
    if (isSuccess) {
      dispatch(
        pushNotification({
          status: 'positive',
          message: 'code has been copied.',
          type: NotificationType.toasts,
        }),
      );
    }
  };
  const handleStepByStepClick = () => {
    window.open('https://swag.space/blog/how-to-set-up-custom-domain/', '_blank');
  };

  return (
    <Dialog open={isOpen} onOpenChange={handleOnOpenChange}>
      <DialogContentWrapper
        className={css.dialogContentWrapperClassName}
        overlayClassName={css.dialogOverlayClassName}
      >
        <DialogTitle closeIconClassName={css.closeIconClassName}>
          Connect existing domain
        </DialogTitle>
        <DialogDescription className={css.contentWrapper}>
          <Typography
            color={COLORS.colorTextSubdued}
            fontType="bodyMd"
            lineHeight="162.5%"
          >
            Add DNS records shown below into your third-party domain provider settings.
            Follow this{' '}
            <Typography
              color={COLORS.colorPink}
              link
              fontType="bodyMd"
              lineHeight="162.5%"
              onClick={handleStepByStepClick}
            >
              step-by-step
            </Typography>{' '}
            instructions and verify connection when you are done.
          </Typography>
          {isSubdomain && (
            <>
              <Typography
                color={COLORS.colorBlack}
                fontType="bodyMd"
                lineHeight="162.5%"
                className={css.subTitle}
              >
                Type: CNAME
              </Typography>
              <div className={css.inputWrapper}>
                <Typography
                  lineHeight="100%"
                  fontType="bodyMd"
                  color={COLORS.colorTypographyBodyGreyed}
                  className={css.dnsSettingsRecord}
                >
                  {subdomain}
                  <div
                    onClick={() => handleCopyClick(subdomain || '')}
                    className={css.copy}
                  >
                    <CopyIcon fillColor={COLORS.colorAccent500} />
                  </div>
                </Typography>

                <Typography
                  lineHeight="100%"
                  fontType="bodyMd"
                  color={COLORS.colorTypographyBodyGreyed}
                  className={css.dnsSettingsRecord}
                >
                  {cnameRecordValue}
                  <div
                    onClick={() => handleCopyClick(cnameRecordValue)}
                    className={css.copy}
                  >
                    <CopyIcon fillColor={COLORS.colorAccent500} />
                  </div>
                </Typography>
              </div>
            </>
          )}
          {!isSubdomain && (
            <>
              <Typography
                color={COLORS.colorBlack}
                fontType="bodyMd"
                lineHeight="162.5%"
                className={css.subTitle}
              >
                Type: A Record
              </Typography>
              {aRecordValues.map((value, index) => {
                return (
                  <div key={index} className={css.inputWrapper}>
                    <Typography
                      lineHeight="100%"
                      fontType="bodyMd"
                      color={COLORS.colorTypographyBodyGreyed}
                      className={css.dnsSettingsRecord}
                    >
                      @
                      <div onClick={() => handleCopyClick('@')} className={css.copy}>
                        <CopyIcon fillColor={COLORS.colorAccent500} />
                      </div>
                    </Typography>
                    <Typography
                      lineHeight="100%"
                      fontType="bodyMd"
                      color={COLORS.colorTypographyBodyGreyed}
                      className={css.dnsSettingsRecord}
                    >
                      {value}
                      <div onClick={() => handleCopyClick(value)} className={css.copy}>
                        <CopyIcon fillColor={COLORS.colorAccent500} />
                      </div>
                    </Typography>
                  </div>
                );
              })}
              <Typography
                color={COLORS.colorBlack}
                fontType="bodyMd"
                lineHeight="162.5%"
                className={css.subTitle}
              >
                Type: CNAME
              </Typography>

              <div className={css.inputWrapper}>
                <Typography
                  lineHeight="100%"
                  fontType="bodyMd"
                  color={COLORS.colorTypographyBodyGreyed}
                  className={css.dnsSettingsRecord}
                >
                  www
                  <div onClick={() => handleCopyClick('@')} className={css.copy}>
                    <CopyIcon fillColor={COLORS.colorAccent500} />
                  </div>
                </Typography>

                <Typography
                  lineHeight="100%"
                  fontType="bodyMd"
                  color={COLORS.colorTypographyBodyGreyed}
                  className={css.dnsSettingsRecord}
                >
                  {rootDomain}
                  <div
                    onClick={() => handleCopyClick(rootDomain || '')}
                    className={css.copy}
                  >
                    <CopyIcon fillColor={COLORS.colorAccent500} />
                  </div>
                </Typography>
              </div>
            </>
          )}
          {showDomainVerificationError && (
            <div>
              <Typography color={COLORS.colorErrorText}>
                {DOMAIN_VERIFICATION_ERROR_MESSAGE}
              </Typography>
            </div>
          )}
        </DialogDescription>

        <DialogFooter className={css.footer}>
          {isBackAllowed && (
            <SwagButton type="outlined" onClick={onBackButtonClick} loading={isLoading}>
              Back
            </SwagButton>
          )}
          <SwagButton
            type="primary"
            onClick={onVerifyConnectionClick}
            loading={isLoading}
          >
            Verify Connection
          </SwagButton>
        </DialogFooter>
      </DialogContentWrapper>
    </Dialog>
  );
};
