import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RainbowBox } from '@ezetech/swag-space-x';
import { BoxWidgetEnum } from 'enums/box-widget-enum';
import { BoxWidget } from 'components/box-widget';
import { ColoredContainerHeader } from 'components/colored-container-header';
import { ROUTES } from 'constants/router';
import { useCheckTokenMutation, usePasswordRecoveryMutation } from 'redux/api/auth.api';
import { UserPasswordRecoveryType } from 'interfaces';

import { CreatePasswordForm } from 'pages/create-password/form/create-password-form';
import { FadeInOut } from 'components/fade-inout';
import css from './password-recovery.module.scss';
import { Expired } from './expired';
import { CONFIRM_ACTION_DOESNT_EXIST } from './password-recovery.constants';
import { getTitleAndDescription, ITitles } from './helpers';

const RESET_PASSWORD_STEP = 'resetPassword';
export function PasswordRecovery() {
  const navigate = useNavigate();
  const { token } = useParams();
  const [checkToken, { isError, data: tokenData }] = useCheckTokenMutation();
  const [changePass, { isSuccess, isLoading }] = usePasswordRecoveryMutation();

  const [titles, setTitles] = useState<ITitles>({
    title: '',
    description: '',
  });

  const [step, setStep] = useState('');

  useEffect(() => {
    if (token) {
      checkToken({ token });
    }
  }, [token]);

  useEffect(() => {
    if (tokenData) {
      if (tokenData?.message) {
        if (tokenData.message === CONFIRM_ACTION_DOESNT_EXIST) {
          navigate('/404');
        }
        const newTitles = getTitleAndDescription(tokenData.message);
        setTitles(newTitles);
        if (newTitles.title) {
          setStep('expired');
        }
        return;
      }
      setStep(RESET_PASSWORD_STEP);
    }
  }, [tokenData]);

  useEffect(() => {
    if (isSuccess) {
      navigate(ROUTES.LOGIN);
    }
  }, [isLoading]);

  const onSubmit = useCallback((data: UserPasswordRecoveryType) => {
    changePass({ ...data, token } as UserPasswordRecoveryType);
  }, []);
  const expiredState = step === 'expired' ? 'fadeIn' : 'hide';

  return (
    <>
      {step === RESET_PASSWORD_STEP ? (
        <RainbowBox borderWidth={8} innerClassName={css.rainbowBlock}>
          <div className={css.resetPassword}>
            <ColoredContainerHeader
              title="Let's Get your password reset!"
              description="When creating your new password, please ensure it meets our guidelines."
              headingClassName={css.headingClassName}
              textClassName={css.textClassName}
            />
            <BoxWidget type={BoxWidgetEnum.REGULAR} className={css.formContainer}>
              <CreatePasswordForm
                onSubmit={onSubmit}
                loading={isLoading}
                error={isError}
              />
            </BoxWidget>
          </div>
        </RainbowBox>
      ) : (
        <>
          <FadeInOut state={expiredState} delay={0.3}>
            <RainbowBox borderWidth={8} innerClassName={css.rainbowBlock}>
              <Expired title={titles.title} description={titles.description} />
            </RainbowBox>
          </FadeInOut>
        </>
      )}
    </>
  );
}
