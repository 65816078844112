import cn from 'classnames';
import { Tooltip, TooltipPlacement, Typography } from '@ezetech/swag-space-x';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { CLIENT_STATUSES } from 'enums/orders.enum';
import { getItemStatusLabel } from 'utils/items.util';
import { ITEM_STATUS_CODE, ITEM_STATUS_CODE_LABEL } from 'constants/item.constants';
import {
  PERMISSION_ERROR_TOOLTIP_TEXT,
  MISSING_MOCKUP_ERROR_TOOLTIP_TEXT,
} from 'constants/common';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import {
  getInfoForReviewColors,
  getPopupFromStatus,
} from 'redux/selectors/orders.selectors';
import { openPopup } from 'redux/slices/modals.slice';

import { getInfoForReviewColorsFromCustomerProfile } from 'redux/selectors/customer-details.selector';
import { SUBJECTS } from 'constants/ability.constant';
import { can } from 'boot/ability';
import {
  POPUP_LIST_NAMES,
  RESELLER_MOCKUP_PRE_APPROVAL,
  REVIEW_COLORS_POPUP,
} from 'components/popups/_logic/popups-list';
import { IItem } from 'interfaces/orders.interface';
import css from '../../order-card-item.module.scss';
import { MockupApprovalByReseller } from './mockup-approval-by-reseller';

interface IProps {
  status: string;
  statusCode?: string;
  trackingNumberLink: string;
  className?: string;
  orderId: string;
  orderItem?: Partial<IItem>;
  itemId: string;
  fromCustomerProfile?: boolean;
  isReviewPantonesByCustomerAllowed: boolean;
  isReviewMockupsByCustomerAllowed: boolean;
}

export const StatusRowComponent = ({
  trackingNumberLink,
  className,
  orderId,
  itemId,
  orderItem,
  fromCustomerProfile = false,
  isReviewPantonesByCustomerAllowed,
  isReviewMockupsByCustomerAllowed,
  ...statuses
}: IProps): JSX.Element => {
  const status = getItemStatusLabel({
    status: statuses.status,
    statusCode: statuses.statusCode || '',
    isReviewPantonesByCustomerAllowed,
    isReviewMockupsByCustomerAllowed,
  });
  const dispatch = useAppDispatch();
  const item =
    orderItem ||
    (fromCustomerProfile
      ? useAppSelector(getInfoForReviewColorsFromCustomerProfile({ orderId, itemId }))
      : useAppSelector(getInfoForReviewColors({ orderId, itemId })));

  const popupName = getPopupFromStatus(status);

  const subjectApprovePantonesName = SUBJECTS.APPROVE_PANTONES.value;
  const subjectApprovePantonesActions = SUBJECTS.APPROVE_PANTONES.actions;
  const canApprovePantones = can(
    subjectApprovePantonesActions.APPROVE_PANTONES,
    subjectApprovePantonesName,
  );

  const subjectApproveMockupName = SUBJECTS.APPROVE_MOCKUPS.value;
  const subjectApproveMockupActions = SUBJECTS.APPROVE_MOCKUPS.actions;
  const canApproveMockups = can(
    subjectApproveMockupActions.APPROVE_MOCKUPS,
    subjectApproveMockupName,
  );

  const handleOpenPopup = () => {
    if (popupName === POPUP_LIST_NAMES[REVIEW_COLORS_POPUP] && !canApprovePantones) {
      return;
    }
    if (
      popupName === POPUP_LIST_NAMES[RESELLER_MOCKUP_PRE_APPROVAL] &&
      !canApproveMockups
    ) {
      return;
    }

    if (popupName) {
      dispatch(
        openPopup({
          popupName,
          popupProps: {
            orderId,
            item,
            isReviewPantonesByCustomerAllowed,
            isReviewMockupsByCustomerAllowed,
          },
        }),
      );
    }
  };

  const isGreen = [
    CLIENT_STATUSES.CREATING_PRODUCTION_MOCKUP,
    CLIENT_STATUSES.PROCESSING_ORDER,
    CLIENT_STATUSES.IN_PRODUCTION,
  ].includes(status as CLIENT_STATUSES);

  const isGrey = [
    CLIENT_STATUSES.DELIVERED,
    CLIENT_STATUSES.DELIVERED_TO_WAREHOUSE,
    CLIENT_STATUSES.READY_FOR_DISTRIBUTION,
  ].includes(status as CLIENT_STATUSES);

  const isRed = [CLIENT_STATUSES.CANCELLED, CLIENT_STATUSES.REJECTED].includes(
    status as CLIENT_STATUSES,
  );

  if (status === CLIENT_STATUSES.TRACKING_NUMBER) {
    if (!trackingNumberLink) {
      return <div className={cn(css.green, className)}>Shipped</div>;
    }

    return (
      <a
        href={trackingNumberLink}
        target="_blank"
        rel="noreferrer noopener"
        className={cn(css.green, className)}
      >
        Tracking number
      </a>
    );
  }

  if (status === ITEM_STATUS_CODE_LABEL[ITEM_STATUS_CODE.MOCKUP_APPROVAL_BY_RESELLER]) {
    if (!item.productionMockups?.length) {
      return (
        <div className={css.toolTipWrapper}>
          <Tooltip
            color={COLORS.colorPink}
            placement={TooltipPlacement.right}
            content={
              <Typography
                link
                className={cn(
                  {
                    [css.yellow]: true,
                    [css.bold]: !fromCustomerProfile,
                  },
                  className,
                )}
              >
                {status}
              </Typography>
            }
          >
            <span>{MISSING_MOCKUP_ERROR_TOOLTIP_TEXT}</span>
          </Tooltip>
        </div>
      );
    }
    const approveMockupsAccess = can(
      SUBJECTS.APPROVE_MOCKUPS.actions.APPROVE_MOCKUPS,
      SUBJECTS.APPROVE_MOCKUPS.value,
    );
    if (!approveMockupsAccess) {
      return (
        <div className={css.toolTipWrapper}>
          <Tooltip
            color={COLORS.colorPink}
            placement={TooltipPlacement.right}
            content={
              <Typography
                link
                className={cn(
                  {
                    [css.yellow]: true,
                    [css.bold]: !fromCustomerProfile,
                  },
                  className,
                )}
              >
                {status}
              </Typography>
            }
          >
            <span>{PERMISSION_ERROR_TOOLTIP_TEXT}</span>
          </Tooltip>
        </div>
      );
    }
    return (
      <MockupApprovalByReseller
        itemId={itemId}
        item={item}
        orderId={orderId}
        status={status}
        className={cn(css.yellow, className)}
        fromCustomerProfile={fromCustomerProfile}
        isReviewMockupsByCustomerAllowed={isReviewMockupsByCustomerAllowed}
        fontType={fromCustomerProfile ? 'bodyMd' : 'bodyMdBold'}
      />
    );
  }

  if (status === ITEM_STATUS_CODE_LABEL[ITEM_STATUS_CODE.COLORS_REVIEW_BY_RESELLER]) {
    const approvePantonesAccess = can(
      SUBJECTS.APPROVE_PANTONES.actions.APPROVE_PANTONES,
      SUBJECTS.APPROVE_PANTONES.value,
    );
    if (!approvePantonesAccess) {
      return (
        <div className={css.toolTipWrapper}>
          <Tooltip
            color={COLORS.colorPink}
            placement={TooltipPlacement.right}
            content={
              <Typography
                link
                className={cn(
                  {
                    [css.yellow]: true,
                    [css.bold]: !fromCustomerProfile,
                  },
                  className,
                )}
              >
                {status}
              </Typography>
            }
          >
            {PERMISSION_ERROR_TOOLTIP_TEXT}
          </Tooltip>
        </div>
      );
    }

    return (
      <Typography
        link
        onClick={handleOpenPopup}
        className={cn(
          {
            [css.yellow]: true,
            [css.bold]: !fromCustomerProfile,
          },
          className,
        )}
      >
        {status}
      </Typography>
    );
  }

  return (
    <div
      onClick={handleOpenPopup}
      className={cn(
        {
          [css.green]: isGreen,
          [css.grey]: isGrey,
          [css.red]: isRed,
          [css.yellow]: !isGreen && !isGrey && !isRed,
          [css.bold]: !fromCustomerProfile,
        },
        className,
      )}
    >
      {status}
    </div>
  );
};
