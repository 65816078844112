import { useState } from 'react';
export type CopyState = 'ready' | 'success' | Error;

export const useClipboard = () => {
  const [state, setState] = useState<CopyState>('ready');

  function handleCopyResult(result: CopyState) {
    setState(result);
  }

  const copy = async (value?: string) => {
    if ('clipboard' in navigator) {
      try {
        await navigator.clipboard.writeText(value || '');
        handleCopyResult('success');

        return true;
      } catch (error) {
        if (error instanceof Error) {
          handleCopyResult(error);
        } else {
          handleCopyResult(new Error('`useClipboard`: Something bad happened.'));
        }

        return false;
      }
    }

    handleCopyResult(new Error('`useClipboard`: Navigation Clipboard is not supported.'));
  };

  return { copy, state };
};
