import {
  Tooltip,
  TooltipPlacement,
  Typography,
  TypographyProps,
  Divider,
} from '@ezetech/swag-space-x';
import cn from 'classnames';

import { useAppSelector } from 'redux/store';
import {
  availableMoneySelector,
  incomingMoneySelector,
  pendingMoneySelector,
} from 'redux/selectors/withdraw.selectors';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { Price } from 'utils/price.util';
import { QuestionMarkIcon } from 'components/svg/question-mark';

import css from './nav-bar.module.scss';

interface IProps {
  onWithdrawClick?: () => void;
  classNameWrapperClassName: string;
  incomingAmountLineHeight: string;
  availableToWithdrawAmountLineHeight: string;
  availableToWithdrawLinkLineHeight: string;
  pendingAmountLineHeight: string;
  availableToWithdrawAmountFont: TypographyProps['fontType'];
  hideActionBtn?: boolean;
  withdrawSectionClassName?: string;
  incomingTextFont: TypographyProps['fontType'];
  incomingAmountFont: TypographyProps['fontType'];
  incomingTextLineHeight: string;
  availableToWithdrawTextLineHeight: string;
  availableToWithdrawTextFont: TypographyProps['fontType'];
  pendingTextLineHeight: string;
  pendingTextFont: TypographyProps['fontType'];
  pendingAmountFont: TypographyProps['fontType'];
  withoutSpace?: boolean;
  withdrawBottomSection?: string;
  popupView?: boolean;
  middleWithdrawSectionClassName?: string;
  withContractBar?: boolean;
}

export const WithdrawWidget = ({
  popupView,
  withoutSpace,
  hideActionBtn,
  withContractBar,
  pendingTextFont,
  onWithdrawClick,
  incomingTextFont,
  pendingAmountFont,
  incomingAmountFont,
  withdrawBottomSection,
  pendingTextLineHeight,
  incomingTextLineHeight,
  pendingAmountLineHeight,
  incomingAmountLineHeight,
  withdrawSectionClassName,
  classNameWrapperClassName,
  availableToWithdrawTextFont,
  availableToWithdrawAmountFont,
  middleWithdrawSectionClassName,
  availableToWithdrawTextLineHeight,
  availableToWithdrawLinkLineHeight,
  availableToWithdrawAmountLineHeight,
}: IProps) => {
  const pendingMoney = useAppSelector(pendingMoneySelector);
  const incomingMoney = useAppSelector(incomingMoneySelector);
  const availableMoney = useAppSelector(availableMoneySelector);

  return (
    <div
      className={cn(classNameWrapperClassName, {
        [css.popupView]: popupView,
        [css.withdrawMobileSectionWrapperWithContractBar]: !popupView && withContractBar,
      })}
    >
      <div className={cn(css.withdrawSection, withdrawSectionClassName)}>
        <Typography
          lineHeight={incomingTextLineHeight}
          fontType={incomingTextFont}
          color={COLORS.colorTypographyBodyGreyed}
          className={cn({ [css.inlineText]: popupView })}
        >
          Incoming:
          {popupView && (
            <Tooltip
              placement={TooltipPlacement.bottom}
              triggerClassName={css.triggerClassName}
              bodyClassName={css.bodyClassName}
              content={<QuestionMarkIcon />}
            >
              <Typography color={COLORS.colorTextSubdued}>
                This is the amount that is owed to you based on the orders that have been
                placed, but these orders have not yet gone into production. Once they go
                into production, the pending amount will be moved into available to
                withdraw.
              </Typography>
            </Tooltip>
          )}
        </Typography>

        <div className={cn(css.withdrawBottomSection, withdrawBottomSection)}>
          <Typography
            lineHeight={incomingAmountLineHeight}
            fontType={incomingAmountFont}
            color={COLORS.colorTextSubdued}
          >
            {Price.formatPrice(incomingMoney)}
          </Typography>
        </div>
      </div>

      <div
        className={cn(
          css.withdrawSection,
          withdrawSectionClassName,
          middleWithdrawSectionClassName,
        )}
      >
        {popupView && <Divider className={css.popupDividerTop} />}
        <Typography
          lineHeight={availableToWithdrawTextLineHeight}
          fontType={availableToWithdrawTextFont}
          color={COLORS.colorTypographyBodyGreyed}
          className={css.withdrawSectionAvailable}
        >
          Available to withdraw:
        </Typography>

        <div className={css.withdrawBottomSection}>
          <Typography
            lineHeight={availableToWithdrawAmountLineHeight}
            fontType={availableToWithdrawAmountFont}
            color={COLORS.colorPrimaryText}
          >
            {!withoutSpace ? ' ' : ''}
            {Price.formatPrice(availableMoney)}
          </Typography>
          {!hideActionBtn && (
            <span onClick={onWithdrawClick} className={css.withdrawWrapper}>
              <Typography
                lineHeight={availableToWithdrawLinkLineHeight}
                className={css.withdraw}
                fontType="bodySm"
                color={COLORS.colorPink}
                link
              >
                Withdraw
              </Typography>
            </span>
          )}
        </div>
        {popupView && <Divider className={css.popupDividerBottom} />}
      </div>

      <div className={cn(css.withdrawSection, withdrawSectionClassName)}>
        <Typography
          lineHeight={pendingTextLineHeight}
          fontType={pendingTextFont}
          color={COLORS.colorTypographyBodyGreyed}
          className={cn({ [css.inlineText]: popupView })}
        >
          Pending:
          {popupView && (
            <Tooltip
              triggerClassName={css.triggerClassName}
              bodyClassName={css.bodyClassName}
              placement={TooltipPlacement.bottom}
              content={<QuestionMarkIcon />}
            >
              <Typography color={COLORS.colorTextSubdued}>
                This is the amount that you've already initiated to withdraw. Since it
                takes up to 10 business days to receive your money, we are showing you
                what you should be expecting.
              </Typography>
            </Tooltip>
          )}
        </Typography>

        <div className={cn(css.withdrawBottomSection, withdrawBottomSection)}>
          <Typography
            lineHeight={pendingAmountLineHeight}
            fontType={pendingAmountFont}
            color={COLORS.colorTextSubdued}
          >
            {Price.formatPrice(pendingMoney)}
          </Typography>
        </div>
      </div>
    </div>
  );
};
