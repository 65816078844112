import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { GenericResponseType, ReducerPath, ApplicationSignUpType } from 'interfaces';
import { makeToastNotification } from 'utils/query.util';

import { pushNotification } from '../slices/notifications.slice';
import { baseQueryParams } from './helpers';

export const applicationApi = createApi({
  reducerPath: ReducerPath.application,
  baseQuery: fetchBaseQuery({
    ...baseQueryParams,
  }),
  endpoints: (builder) => ({
    create: builder.mutation<GenericResponseType, ApplicationSignUpType>({
      query: (payload) => ({
        url: '/application/create',
        method: 'POST',
        body: payload,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),
  }),
});

export const { useCreateMutation } = applicationApi;
