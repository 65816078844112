import { LoaderFunctionArgs } from 'react-router-dom';
import { pushNotification } from 'redux/slices/notifications.slice';
import { makeToastNotification } from 'utils/query.util';
import { userApi } from 'redux/api/user.api';
import { permissionApi } from 'redux/api/permissions.api';
import { fetchStoreSettingsData } from 'utils/store-settings.util';

import { store } from 'redux/store';

export async function newTeamMemberLoader(): Promise<null> {
  await fetchStoreSettingsData();

  try {
    const request = store.dispatch(
      permissionApi.endpoints.getPermissionsForSettings.initiate({}),
    );
    const result = await request.unwrap();
    if (!result || 'error' in result) {
      return null;
    }
  } catch (e) {
    store.dispatch(pushNotification(makeToastNotification(e)));
  }
  return null;
}

export async function teamMemberLoader({ params }: LoaderFunctionArgs): Promise<null> {
  try {
    if (!params.id) {
      throw new Error('No ID provided');
    }

    await fetchStoreSettingsData();

    const request = store.dispatch(
      userApi.endpoints.getTeamMemberById.initiate({ id: params.id }),
    );
    const result = await request.unwrap();
    if (!result || 'error' in result) {
      return null;
    }
  } catch (e) {
    store.dispatch(pushNotification(makeToastNotification(e)));
  }
  return null;
}
