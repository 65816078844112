export const TEAM_MEMBER_DETAILS_MANAGE_ACCESS_TEXT = {
  create:
    'By clicking the "Invite" button below, you will send an invitation with access to this Swag Space account.\n' +
    'Upon sending the invite, there will be an email notification with instructions on how to accept the invitation and get access to your account.',
  edit: 'Keep in mind removed team members will no longer have access to your store dashboard.',
};

export const TEAM_MEMBER_DETAILS_MANAGE_ACCESS_BUTTON_TEXT = {
  create: 'Invite',
  edit: 'Remove Access',
};
