import cn from 'classnames';
import { PropsWithChildren } from 'react';
import { Typography } from '@ezetech/swag-space-x';
import COLORS from 'constants/styles/colors-js.constant.module.scss';

import css from './sidebar.module.scss';

type PageEditorSidebarProps = {
  title?: string;
  isHidden?: boolean;
  className?: string;
};

export const PageEditorSidebar = (
  props: PropsWithChildren<PageEditorSidebarProps>,
): JSX.Element => {
  const { children, title, isHidden, className } = props;
  return (
    <div className={cn(css.sidebar, className, { [css.hidden]: isHidden })}>
      {title && (
        <div className={css.sidebarTitleText}>
          <Typography
            lineHeight="100%"
            fontType="HEADING6XL"
            color={COLORS.colorGreyDark}
          >
            {title}
          </Typography>
        </div>
      )}
      <hr />
      {children}
    </div>
  );
};
