import { wrap } from 'comlink';
import 'workers/pdf-worker/pdf-worker.runner';
import { PdfDocumentSettingsProps } from '../../popups/edit-pdf-presentation-layout-popup/interfaces/components';
let worker: Worker;
export async function renderPdfInWorker(pdfDoc: PdfDocumentSettingsProps) {
  if (worker?.terminate) {
    worker.terminate();
  }
  worker = new Worker(new URL('./index.worker.ts', import.meta.url), {
    type: 'module',
  });
  const workerApi = wrap<import('./index.worker').RenderPdfInWorker>(worker);
  try {
    return await workerApi.renderPdfInWorker(pdfDoc);
  } catch (error) {
    worker.terminate();
    throw new Error('Worker error');
  }
}
