import { ImagesOfPagesType } from './pdf-viewer.interface';

async function makeImages(
  pdfDocOriginal: HTMLDivElement,
  pagesToRender: string[],
): Promise<ImagesOfPagesType[]> {
  const pages = pdfDocOriginal.getElementsByClassName('react-pdf__Page');

  // Use setTimeout to avoid freezing, `canvas[0].toDataURL` takes a lot of resources.
  const promises = Array.from(pages).map(async (page) => {
    return new Promise<ImagesOfPagesType>((resolve, reject) => {
      setTimeout(() => {
        const pageNumber = page.getAttribute('data-page-number');
        const canvas = page.getElementsByTagName('canvas');
        if (!canvas?.length) {
          reject('canvas not fount');
        }
        resolve({
          dataUrl: canvas[0].toDataURL(),
          pageNumber: Number(pageNumber || 1),
          pageId: pagesToRender[Number(pageNumber || 1) - 1],
        });
      });
    });
  });
  const images = await Promise.all(promises);
  return images;
}

export const generateImagesFromPages = async (
  pdfDocOriginal: HTMLDivElement,
  pagesToRender: string[],
  imagesOfPages: ImagesOfPagesType[] | undefined,
  pageId: string | undefined,
) => {
  const images = await makeImages(pdfDocOriginal, pagesToRender);

  if (imagesOfPages?.length && images.length === 1) {
    const index = imagesOfPages.findIndex((page) => page.pageId === pageId);
    if (imagesOfPages[index]?.dataUrl) {
      const newImages = imagesOfPages.map((image, i) => {
        if (index === i) {
          return {
            dataUrl: images[0].dataUrl,
            pageId: image.pageId,
            pageNumber: image.pageNumber,
          };
        }
        return image;
      });
      return newImages;
    }
    return;
  }

  return images;
};
