import { FC, useEffect, useRef, useState } from 'react';
import { Loader, SwagButton, Typography } from '@ezetech/swag-space-x';
import classNames from 'classnames';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import ChevronUp from 'assets/svg/chevron-up-secondary.svg';
import {
  PdfPresentationEditorPage,
  PdfPresentationEditorPageId,
} from 'interfaces/pdf-presentation';
import { PagesSidebarProps } from '../interfaces/components';
import classes from '../edit-pdf-presentation-layout-popup.module.scss';
import { useIsMobileView } from '../../../../hooks/is-mobile-view.hook';
import { Filters } from './filters';
import { PagePreviewFilters } from './page-preview.filters';

import '@ezetech/swag-presentation-templates/dist/utils/register-fonts';

export const PagesSidebarMobile: FC<PagesSidebarProps> = ({
  filter: { value, onChangeValue },
  selectedPageTemplateId,
  handlePageChange,
  onCloseClicked,
  onSaveClicked,
  pagesToRender,
  values,
  isPdfRendering,
  imagesOfPdfPages,
  isEditMode,
}) => {
  const [filtersOpened, setFiltersOpened] = useState(false);
  const is1023AndLower = useIsMobileView(1024);
  const isFilterOpened = filtersOpened || is1023AndLower;
  const containerRef = useRef<HTMLDivElement>(null);

  const handleToggleFilters = () => {
    setFiltersOpened((v) => !v);
  };

  const getPageIsHidden = (pageTemplate: PdfPresentationEditorPage) => {
    return pageTemplate?.isCanBeHidden && pageTemplate?.isHiddenKeyName
      ? Boolean(values[pageTemplate?.isHiddenKeyName])
      : false;
  };

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollLeft = 0;
    }
  }, [value]);

  return (
    <div className={classes.pagesSidebarMobile}>
      <div className={classes.pagesSidebarMobileContent}>
        <div className={classes.pagesSidebarMobileHeader} onClick={handleToggleFilters}>
          <Typography fontType="bodySm" color={COLORS.colorText500}>
            Select Page
          </Typography>
          <img
            className={classNames(classes.pagesSidebarMobileChevron, {
              [classes.pagesSidebarMobileChevronOpened]: isFilterOpened,
            })}
            src={ChevronUp}
            alt="Toggle pages menu"
          />
        </div>
        {isFilterOpened && (
          <div>
            <Filters value={value} onChangeValue={onChangeValue} />
            <div ref={containerRef} className={classes.pagesSidebarPagesContainer}>
              {isPdfRendering && (
                <div className={classes.pagesSidebarLoader}>
                  <Loader />
                </div>
              )}
              {imagesOfPdfPages?.length ? (
                <div className={classes.pagesViewer}>
                  <div className={classes.pagesSidebarPages}>
                    {imagesOfPdfPages.map((image, index) => {
                      return (
                        <PagePreviewFilters
                          key={image.pageId}
                          pageTemplateId={image.pageId as PdfPresentationEditorPageId}
                          pageIndex={index}
                          selectedPageTemplateId={selectedPageTemplateId}
                          handlePageChange={handlePageChange}
                          isHidden={getPageIsHidden(pagesToRender[index])}
                          page={
                            <div className={classes.previewSectionPage}>
                              <img src={image.dataUrl} />
                            </div>
                          }
                        />
                      );
                    })}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        )}
        <div className={classes.sidebarButtonGroup}>
          <SwagButton
            type="outlined"
            onClick={onCloseClicked}
            className={classes.sidebarButtonGroupDiscardButton}
          >
            Exit
          </SwagButton>
          {isEditMode && (
            <SwagButton type="primary" onClick={onSaveClicked}>
              Save
            </SwagButton>
          )}
        </div>
      </div>
    </div>
  );
};
