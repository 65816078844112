import { Tooltip, TooltipPlacement, Typography } from '@ezetech/swag-space-x';

import { useAppSelector } from 'redux/store';
import { sentToComponentSelector } from 'redux/selectors/orders.selectors';
import COLORS from 'constants/styles/colors-js.constant.module.scss';

import css from '../../order-card-item.module.scss';

interface IProps {
  orderId: string;
  itemId: string;
}

export const SentToComponent = ({ orderId, itemId }: IProps): JSX.Element => {
  const { inventoryName, email, fullName, fullShippingAddress } = useAppSelector(
    sentToComponentSelector({ orderId, itemId }),
  );

  return (
    <div className={css.sentTo}>
      <Typography
        lineHeight="170%"
        fontType="bodyMd"
        color={COLORS.colorSecondaryText}
        className={css.label}
      >
        Sent to:
      </Typography>
      {inventoryName ? (
        <Typography
          lineHeight="170%"
          fontType="bodyMdBold"
          color={COLORS.colorPink}
          className={css.value}
        >
          {inventoryName}
        </Typography>
      ) : (
        <Tooltip
          placement={TooltipPlacement.right}
          triggerClassName={css.value}
          content={
            <Typography
              lineHeight="170%"
              fontType="bodyMdBold"
              color={COLORS.colorPink}
              className={css.underlined}
            >
              View Address
            </Typography>
          }
        >
          <div className={css.addressTooltipContent}>
            <Typography
              lineHeight="150%"
              fontType="bodySm"
              color={COLORS.colorPrimaryText}
            >
              {fullName}
            </Typography>
            <Typography
              lineHeight="150%"
              fontType="bodySm"
              color={COLORS.colorPrimaryText}
            >
              {email}
            </Typography>
            <Typography
              lineHeight="150%"
              fontType="bodySm"
              color={COLORS.colorPrimaryText}
            >
              {fullShippingAddress}
            </Typography>
          </div>
        </Tooltip>
      )}
    </div>
  );
};
