import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  storeInitialTermsOfServiceHTMLSelector,
  storeTermsOfServiceHTMLSelector,
} from 'redux/selectors/settings.selectors';
import { TOS_PP_TYPE } from 'constants/settings.constants';
import { setTermsOfServiceHTML } from 'redux/slices/settings.slice';
import { useAppDispatch } from 'redux/store';
import { cannot, SUBJECTS } from 'boot/ability';

import { ToSPP } from '../tos-pp';

export function TermsOfService(): JSX.Element {
  const dispatch = useAppDispatch();
  const html = useSelector(storeTermsOfServiceHTMLSelector);
  const initHtml = useSelector(storeInitialTermsOfServiceHTMLSelector);
  const isEditDisabled = cannot(
    SUBJECTS.TERMS_OF_SERVICE.actions.TERMS_OF_SERVICE_EDIT,
    SUBJECTS.TERMS_OF_SERVICE.value,
  );
  const isDownloadHidden = cannot(
    SUBJECTS.TERMS_OF_SERVICE.actions.TERMS_OF_SERVICE_DOWNLOAD,
    SUBJECTS.TERMS_OF_SERVICE.value,
  );
  const isUploadHidden = cannot(
    SUBJECTS.TERMS_OF_SERVICE.actions.TERMS_OF_SERVICE_UPLOAD,
    SUBJECTS.TERMS_OF_SERVICE.value,
  );
  const onChange = useCallback((updatedHtml: string) => {
    dispatch(setTermsOfServiceHTML(updatedHtml));
  }, []);

  return (
    <ToSPP
      title="Terms of service"
      type={TOS_PP_TYPE.TermsOfService}
      onChange={onChange}
      initHtml={initHtml}
      html={html}
      isEditDisabled={isEditDisabled}
      isDownloadHidden={isDownloadHidden}
      isUploadHidden={isUploadHidden}
    >
      We are not lawyers and we recommend you have your legal team review this. Here is
      your Terms of Service. Feel free to update this to your liking at any time. This is
      the Terms of Service that your customers will need to Accept when they create an
      account and hold products in Inventory for the first time.
    </ToSPP>
  );
}
