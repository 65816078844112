import cn from 'classnames';
import { RainbowBox, SwagButton, Typography } from '@ezetech/swag-space-x';
import classNames from 'classnames';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import classes from './empty-state.module.scss';

interface IProps {
  title: string;
  description: string;
  button?: string;
  buttonClassName?: string;
  onClick?(): void;
  isSmallerTitleForMobile?: boolean;
}

export function EmptyState({
  title,
  description,
  button,
  buttonClassName,
  onClick,
  isSmallerTitleForMobile = false,
}: IProps): JSX.Element {
  return (
    <RainbowBox
      outerClassName={classes.outer}
      innerClassName={classes.inner}
      borderWidth={8}
    >
      <Typography
        className={classNames(classes.title, {
          [classes.smallerTitle]: isSmallerTitleForMobile,
        })}
        lineHeight="100%"
        fontType="HEADING2XL"
        color={COLORS.colorBlack}
      >
        {title}
      </Typography>
      {description && (
        <Typography
          className={classes.description}
          lineHeight="125%"
          fontType="bodyLg"
          color={COLORS.colorGreyDark}
        >
          {description}
        </Typography>
      )}
      {button && (
        <SwagButton
          onClick={onClick}
          className={cn(classes.button, buttonClassName)}
          type="primary"
        >
          {button}
        </SwagButton>
      )}
    </RainbowBox>
  );
}
