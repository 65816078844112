import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RainbowBox } from '@ezetech/swag-space-x';
import { BoxWidgetEnum } from 'enums/box-widget-enum';
import { BoxWidget } from 'components/box-widget';
import { useCheckTokenMutation, useCreatePasswordMutation } from 'redux/api/auth.api';
import { ColoredContainerHeader } from 'components/colored-container-header';
import { ROUTES } from 'constants/router';
import { UserCreatePasswordType } from 'interfaces';
import { setInvitation } from 'redux/slices/user.slice';
import {
  createPasswordFormInformationSelector,
  invitationSelector,
} from 'redux/selectors/user.selectors';
import { useQuery } from 'hooks/use-query.hook';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { CONFIRM_ACTION_DOESNT_EXIST } from 'pages/password-recovery/password-recovery.constants';
import { getTitleAndDescription, ITitles } from 'pages/password-recovery/helpers';
import { Expired } from 'pages/password-recovery/expired';
import { FadeInOut } from 'components/fade-inout';

import { CreatePasswordForm } from './form/create-password-form';
import css from './create-password.module.scss';

const CREATE_PASSWORD_STEP = 'createPassword';

export function CreatePassword() {
  const navigate = useNavigate();
  const [createPass, { isLoading, isSuccess }] = useCreatePasswordMutation();
  const { token } = useParams();
  const query = useQuery();
  const dispatch = useAppDispatch();
  const { title, description } = useAppSelector(createPasswordFormInformationSelector);
  const invitation = useAppSelector(invitationSelector);
  const [checkToken, { data: tokenData }] = useCheckTokenMutation();

  const [titles, setTitles] = useState<ITitles>({
    title: '',
    description: '',
  });

  const [step, setStep] = useState('');

  useEffect(() => {
    const param = query.get('invitation');

    if (param) {
      dispatch(setInvitation(param));
    }
  }, [query]);

  useEffect(() => {
    if (token) {
      checkToken({ token });
    }
  }, [token]);

  useEffect(() => {
    if (tokenData) {
      if (tokenData?.message) {
        if (tokenData.message === CONFIRM_ACTION_DOESNT_EXIST) {
          navigate('/404');
        }
        const newTitles = getTitleAndDescription(tokenData.message);
        setTitles(newTitles);
        if (newTitles.title) {
          setStep('expired');
        }
        return;
      }
      setStep(CREATE_PASSWORD_STEP);
    }
  }, [tokenData]);

  useEffect(() => {
    if (isSuccess) {
      if (invitation) {
        dispatch(setInvitation(null));
      }

      navigate(ROUTES.LOGIN);
    }
  }, [isLoading]);

  const onSubmit = useCallback(
    (data: UserCreatePasswordType) => {
      const payload = {
        ...data,
      };

      if (invitation) {
        payload.invitation = invitation;
      }
      createPass(payload);
    },
    [invitation],
  );

  const expiredState = step === 'expired' ? 'fadeIn' : 'hide';

  return (
    <>
      {step === CREATE_PASSWORD_STEP ? (
        <RainbowBox borderWidth={8} innerClassName={css.rainbowBlock}>
          <div className={css.login}>
            <ColoredContainerHeader
              title={title}
              description={description}
              headingClassName={css.headingClassName}
              textClassName={css.textClassName}
            />
            <BoxWidget type={BoxWidgetEnum.REGULAR} className={css.formContainer}>
              <CreatePasswordForm onSubmit={onSubmit} loading={isLoading} />
            </BoxWidget>
          </div>
        </RainbowBox>
      ) : (
        <>
          <FadeInOut state={expiredState} delay={0.3}>
            <RainbowBox borderWidth={8} innerClassName={css.rainbowBlock}>
              <Expired title={titles.title} description={titles.description} />
            </RainbowBox>
          </FadeInOut>
        </>
      )}
    </>
  );
}
