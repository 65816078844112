import { createSelector } from 'reselect';
import { IUser } from 'interfaces/user-api.interface';
import { RootState } from '../store';

export const userSelector = (state: RootState) => state.user;

export const profileSelector = createSelector(userSelector, (user) => user.profile);
export const loginRedirectUrlSelector = createSelector(
  userSelector,
  (state) => state.loginRedirectUrl,
);
export const logoutExecutedSelector = createSelector(
  userSelector,
  (state) => state.logoutExecuted,
);

export const firstNameSelector = createSelector(
  userSelector,
  (user) => user.profile?.firstName || 'Anonymous',
);

export const lastNameSelector = createSelector(
  userSelector,
  (user) => user.profile?.lastName || 'Anonymous',
);

export const userIdSelector = createSelector(
  profileSelector,
  (profile) => profile?.id || '',
);

export const adminLoggedInAsResellerSelector = createSelector(
  profileSelector,
  (profile: IUser | null) => !!profile?.isSwagAdminLoginAsUser,
);

export const adminLoggedInAsResellerFrameParamsSelector = createSelector(
  userSelector,
  (user) => ({
    labelText: `You are currently browsing as ${user.profile?.email}`,
    buttonLabel: 'Exit',
  }),
);

export const invitationSelector = createSelector(
  userSelector,
  (state) => state.invitation,
);

export const loginFormInformationSelector = createSelector(
  invitationSelector,
  (invitation) => {
    if (!invitation) {
      return {
        title: 'Great to see you again!',
        description: "Let's get you logged into your account.",
      };
    }

    return {
      title: 'Great to see you!',
      description: 'Please log in to accept the invitation.',
    };
  },
);

export const createPasswordFormInformationSelector = createSelector(
  invitationSelector,
  (invitation) => {
    if (!invitation) {
      return {
        title: 'Let’s create your password!',
        description:
          'When creating your new password, please ensure it meets our guidelines.',
      };
    }

    return {
      title: 'Let’s create your password!',
      description: 'Please set your password to accept the invitation.',
    };
  },
);
