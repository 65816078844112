import React, { createRef } from 'react';
import { Input, Typography } from '@ezetech/swag-space-x';
import { useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { cannot, SUBJECTS } from 'boot/ability';

import { FormProps } from '../forms.interface';
import css from '../forms.module.scss';
import {
  storeIntercomAppIdErrorSelector,
  storeIntercomAppIdSelector,
} from '../../../../../redux/selectors/settings.selectors';
import { useAppDispatch } from '../../../../../redux/store';
import {
  setIntercomAppId,
  setIntercomAppIdError,
} from '../../../../../redux/slices/settings.slice';

const FIELD = {
  label: 'Intercom Key',
  name: 'intercom',
  placeholder: 'Enter Intercom Key',
};

const animationClasses = {
  enter: css.enter,
  enterActive: css.enterActive,
  exit: css.exit,
  exitActive: css.exitActive,
};

export const IntercomForm: React.FC<FormProps> = () => {
  const errorRef = createRef<HTMLSpanElement>();

  const dispatch = useAppDispatch();
  const value = useSelector(storeIntercomAppIdSelector);
  const inputError = useSelector(storeIntercomAppIdErrorSelector);
  const isDisabled = cannot(
    SUBJECTS.INTERCOM.actions.INTERCOM_EDIT,
    SUBJECTS.INTERCOM.value,
  );

  const onChange = (val: string): void => {
    dispatch(setIntercomAppId(val ? val : null));
    dispatch(setIntercomAppIdError(''));
  };

  return (
    <form className={`${css.form} ${css.formLink}`}>
      <Input
        disabled={isDisabled}
        type="text"
        name={FIELD.name}
        label={FIELD.label}
        value={value || ''}
        onChange={(e) => onChange(e.target.value)}
        placeholder={FIELD.placeholder}
        className={css.input}
      />
      <CSSTransition
        in={!!inputError}
        classNames={animationClasses}
        timeout={500}
        nodeRef={errorRef}
        unmountOnExit
      >
        <Typography
          className={css.errorText}
          lineHeight="120%"
          fontType="bodySm"
          color={COLORS.colorErrorText}
        >
          <span ref={errorRef}>{inputError}</span>
        </Typography>
      </CSSTransition>
    </form>
  );
};
