import * as yup from 'yup';
import { ERROR_MSG } from 'constants/validation';
import { CountriesStatesSelect } from 'constants/settings.constants';

export const createCreditCardSchema = yup.object({
  firstName: yup
    .string()
    .strict(true)
    .trim(ERROR_MSG.trim)
    .min(2, ERROR_MSG.min(2))
    .max(20, ERROR_MSG.max(20))
    .required(ERROR_MSG.required),
  lastName: yup
    .string()
    .strict(true)
    .trim(ERROR_MSG.trim)
    .min(2, ERROR_MSG.min(2))
    .max(20, ERROR_MSG.max(20))
    .required(ERROR_MSG.required),
  street: yup
    .string()
    .strict(true)
    .trim(ERROR_MSG.trim)
    .min(1, ERROR_MSG.min(1))
    .max(255, ERROR_MSG.max(255))
    .required(ERROR_MSG.required),
  unit: yup
    .string()
    .strict(true)
    .trim(ERROR_MSG.trim)
    .max(255, ERROR_MSG.max(255))
    .optional(),
  city: yup
    .string()
    .strict(true)
    .trim(ERROR_MSG.trim)
    .min(1, ERROR_MSG.min(1))
    .max(255, ERROR_MSG.max(255))
    .required(ERROR_MSG.required),
  zip: yup
    .string()
    .strict(true)
    .trim(ERROR_MSG.trim)
    .min(4, ERROR_MSG.min(4))
    .max(6, ERROR_MSG.max(6))
    .required(ERROR_MSG.required),
  country: yup
    .string()
    .strict(true)
    .trim(ERROR_MSG.trim)
    .min(2, ERROR_MSG.min(2))
    .max(2, ERROR_MSG.max(2))
    .required(ERROR_MSG.required),
  state: yup
    .string()
    .strict(true)
    .trim(ERROR_MSG.trim)
    .max(255, ERROR_MSG.max(255))
    .optional()
    .transform((value) => (value ? value : undefined))
    .when('country', (country, field) => {
      return country[0]?.toString() === CountriesStatesSelect.CA ||
        country[0]?.toString() === CountriesStatesSelect.US
        ? field.required(ERROR_MSG.required)
        : field.optional();
    }),
});
