import { IFile } from '@ezetech/swag-space-x';
import { isMobile } from 'utils/ui.util';

export const isTabletDevice = () => isMobile(1023);
export const isMobileDevice = () => isMobile(767);
export const isSmallMobileDevice = () => isMobile(381);

type QueryBuilderProps = {
  heroImage?: IFile | null | undefined;
  heroText?: string;
  formText?: string;
  storeLink?: string;
  brandLogos?: (IFile | null)[];
  imagesRaw?: (IFile | null)[];
};

export const queryBuilder = ({
  heroImage,
  heroText,
  formText,
  storeLink,
  brandLogos,
  imagesRaw,
}: QueryBuilderProps): string => {
  let query = `${storeLink}what-we-offer?isDashboardPreview=true&`;
  if (heroImage?.url) {
    query = `${query}heroImage=${encodeURIComponent(heroImage?.url)}&`;
  }
  if (heroText) {
    query = `${query}heroText=${encodeURIComponent(heroText)}&`;
  }
  if (formText) {
    query = `${query}formText=${encodeURIComponent(formText)}&`;
  }
  if (brandLogos?.length) {
    const urls = JSON.stringify(
      brandLogos &&
        brandLogos
          .filter((brandLogo) => !!brandLogo && brandLogo.url)
          .map((logo) => logo?.url),
    );
    query = `${query}brandLogos=${encodeURIComponent(urls)}&`;
  }

  if (imagesRaw?.length) {
    const urls = JSON.stringify(
      imagesRaw &&
        imagesRaw.filter((imageRaw) => !!imageRaw && imageRaw.url).map((raw) => raw?.url),
    );
    query = `${query}imagesRaw=${encodeURIComponent(urls)}&`;
  }

  return query.replace(/'/g, ';apos;');
};

export const getHeroBodyPlaceholder = (): string => {
  if (isMobileDevice()) {
    return 'Write some text here... e.g. Your all-in one swag platform. Shop, store and ship the highest quality swag around the world with the click of a button.';
  }
  if (isTabletDevice()) {
    return 'Write some text here... e.g. Your all-in one swag platform. Shop, store and ship the highest quality swag around the world...';
  }
  return 'Write some text here... e.g. Your all-in one swag platform. Shop, store and ship the highest quality swag around the world with the click of a button.';
};

export const getRowsForBodyCopy = (): number => {
  if (isSmallMobileDevice()) {
    return 5;
  }
  if (isMobileDevice()) {
    return 4;
  }
  if (isTabletDevice()) {
    return 5;
  }
  return 4;
};
