import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DEFAULT_PAGE } from 'constants/common';
import { IReferralsActionItem } from 'interfaces/referrals.interface';

interface IReferralsState {
  list: IReferralsActionItem[];
  total: number;
  perPage: number;
  page: number;
  isLoading: boolean;
}

const initialState: IReferralsState = {
  list: [],
  total: 0,
  perPage: 8,
  page: DEFAULT_PAGE,
  isLoading: false,
};

export const referralsSlice = createSlice({
  initialState,
  name: 'referralsSlice',
  reducers: {
    reset: () => initialState,
    setReferralsActions: (
      state,
      action: PayloadAction<{
        list: IReferralsState['list'];
        total: IReferralsState['total'];
        perPage: IReferralsState['perPage'];
        page: IReferralsState['page'];
      }>,
    ) => {
      if (!action.payload) {
        return state;
      }

      const { list, ...rest } = action.payload;
      return {
        ...state,
        ...rest,
        list: rest.page === DEFAULT_PAGE ? list : [...state.list, ...list],
      };
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      return { ...state, isLoading: action.payload };
    },
  },
});

export const referralsReducer = referralsSlice.reducer;
export const { reset, setReferralsActions, setLoading } = referralsSlice.actions;
