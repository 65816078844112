import { Typography, WhiteBox, Accordion, AccordionItem } from '@ezetech/swag-space-x';
import cn from 'classnames';
import { useState } from 'react';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { getTimeMmmDdYyyy } from 'utils/date/date-formatter.util';
import { ChevronUp } from 'components/svg/chevron-up';
import { ICartNotes } from 'interfaces/cart.interfaces';

import css from './cart-notes-popup.module.scss';
import { getOtherCartNotesLength } from './cart-notes.util';

interface IProps {
  product: ICartNotes['individualNotes'][0];
}

export const ProductNotesCard = (product: IProps) => {
  const {
    product: { img, productName, notes, itemPublicId },
  } = product;
  const [isProductNotesOpen, setIsProductNotesOpen] = useState(false);
  const capitalizedProductName = productName
    .split(' ')
    .map((word) => word[0].toUpperCase() + word.slice(1))
    .join(' ');

  return (
    <WhiteBox className={css.whiteBox}>
      <div data-item-public-id={itemPublicId}>
        <Accordion className={css.accordionWrapper}>
          <AccordionItem
            headerComponent={
              <div
                data-newest-note-id={notes[0].id}
                className={cn(css.firstIndividualProductNote, {
                  [css.firstIndividualProductNoteSingle]: notes.length === 1,
                })}
                onClick={() => setIsProductNotesOpen(!isProductNotesOpen)}
              >
                <img src={img} alt={productName} className={css.individualProductImage} />

                <Typography
                  className={css.firstIndividualProductName}
                  fontType="bodyMdBold"
                  lineHeight="162.5%"
                  color={COLORS.colorPrimaryText}
                >
                  {
                    <span
                      className={cn(
                        css.newestNoteLabel,
                        css.firstIndividualProductNewestNoteLabel,
                      )}
                    >
                      <Typography fontType="bodySm" color={COLORS.colorPrimaryText}>
                        Newest Note
                      </Typography>
                    </span>
                  }
                  {capitalizedProductName}{' '}
                </Typography>

                <Typography
                  className={css.firstIndividualProductDate}
                  fontType="bodySm"
                  color={COLORS.colorText500}
                >
                  {getTimeMmmDdYyyy(new Date(notes[0].date), {
                    month: 'short',
                    day: 'numeric',
                    year: 'numeric',
                  })}
                </Typography>

                <Typography
                  className={css.firstIndividualProductText}
                  fontType="bodyMd"
                  lineHeight="162.5%"
                  color={COLORS.colorTextSubdued}
                >
                  <pre className={css.pre}>Notes: {notes[0].text.trim()}</pre>
                </Typography>

                {notes.length > 1 && (
                  <Typography
                    className={cn(css.accordion, css.firstIndividualProductAccordion)}
                    fontType="bodySmBold"
                    color={COLORS.colorPink}
                  >
                    See {isProductNotesOpen ? 'less' : 'more'} notes (
                    {getOtherCartNotesLength(notes.length)})
                    <ChevronUp
                      className={cn(css.chevron, {
                        [css.chevronDown]: isProductNotesOpen,
                      })}
                    />
                  </Typography>
                )}
              </div>
            }
            value="Hero Body Copy"
            className={css.accordionItem}
            triggerClassName={css.triggerClassName}
            contentClassName={css.contentClassName}
            chevronClassName={css.chevronClassName}
          >
            {notes.map((note, index) => {
              if (index) {
                return (
                  <span key={note.id} data-note-id={note.id}>
                    <div className={css.divider} />
                    <div className={css.individualProductNote}>
                      <span className={css.dummy} />
                      <Typography
                        className={css.individualProductText}
                        fontType="bodyMd"
                        lineHeight="162.5%"
                        color={COLORS.colorTextSubdued}
                      >
                        <pre className={css.pre}>Notes: {note.text.trim()}</pre>
                      </Typography>

                      <Typography
                        className={css.individualProductDate}
                        fontType="bodySm"
                        lineHeight="142.857%"
                        color={COLORS.colorText500}
                      >
                        {getTimeMmmDdYyyy(new Date(note.date), {
                          month: 'short',
                          day: 'numeric',
                          year: 'numeric',
                        })}
                      </Typography>
                    </div>
                  </span>
                );
              }
            })}
          </AccordionItem>
        </Accordion>
      </div>
    </WhiteBox>
  );
};
