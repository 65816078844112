import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFilters } from 'interfaces/filters.interface';
import { PER_PAGE_RECORDS } from 'constants/common';

export interface IFilterState extends IFilters {}

export const initialState: IFilterState = {
  page: 1,
  totalCount: 0,
  perPage: PER_PAGE_RECORDS,
  sorting: '',
  sortingOrder: 'ASC',
  search: '',
  startDate: null,
  endDate: null,
  dropdown: [],
};

export const filtersSlice = createSlice({
  initialState,
  name: 'filters',
  reducers: {
    resetFilters: () => initialState,
    setFilter: (state, action: PayloadAction<Partial<IFilterState>>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const filtersReducer = filtersSlice.reducer;
export const { setFilter, resetFilters } = filtersSlice.actions;
