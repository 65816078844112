import {
  ColorsSelector,
  ImageUpload,
  ImageCropTools,
  useImageCropTools,
} from '@ezetech/swag-space-x';

import { FC, useEffect } from 'react';
import { cannot, SUBJECTS } from 'boot/ability';

import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import { useUploadLogoMutation } from '../../../../../redux/api/settings.api';
import css from '../forms.module.scss';
import { FormProps } from '../forms.interface';
import {
  brandColorsSelector,
  brandLogoSelector,
} from '../../../../../redux/selectors/settings.selectors';
import { patchBrand } from '../../../../../redux/slices/settings.slice';

export const BrandForm: FC<FormProps> = () => {
  const dispatch = useAppDispatch();

  const { primaryColor, secondaryColor } = useAppSelector(brandColorsSelector);
  const logoUrl = useAppSelector(brandLogoSelector);
  const [uploadLogo] = useUploadLogoMutation();
  const isLogoUploadHidden = cannot(
    SUBJECTS.BRAND.actions.BRAND_LOGO_EDIT,
    SUBJECTS.BRAND.value,
  );
  const isColorPickerHidden = cannot(
    SUBJECTS.BRAND.actions.STORE_COLORS_EDIT,
    SUBJECTS.BRAND.value,
  );

  const {
    toolsOnSaveHandler,
    toolsOnCloseHandler,
    toolsOnScaleHandler,
    toolsOnRevertHandler,

    imageCropOnCroppedHandler,
    imageCropOnLoadHandler,
    imageCropSetSrc,

    setIsVisibleImageCrop,
    onSaved,

    fitScale,
    scale,
    blobCropped,
    isShowImageCrop,
    src,
    isSaving,
  } = useImageCropTools(logoUrl);

  const onPrimaryColorPick = (color: string): void => {
    dispatch(patchBrand({ primaryColor: color }));
  };

  const onSecondaryColorPick = (color: string): void => {
    dispatch(patchBrand({ secondaryColor: color }));
  };

  const handleUpload = (files: File[]): void => {
    if (!files.length) {
      return;
    }
    const fileReader = new FileReader();
    fileReader.onload = () => {
      const formData = new FormData();
      formData.set('file', files[0]);

      uploadLogo(formData);
    };
    fileReader.readAsDataURL(files[0]);
  };

  const toolsOnSave = async () => {
    if (blobCropped) {
      toolsOnSaveHandler();
      const formData = new FormData();
      formData.set('file', blobCropped);
      await uploadLogo(formData);
      onSaved();
      toolsOnCloseHandler();
    }
  };

  const toolsOnRevert = (newSrc: string) => {
    toolsOnRevertHandler(newSrc);
  };

  useEffect(() => {
    if (!logoUrl) {
      return;
    }
    imageCropSetSrc(logoUrl);
  }, [logoUrl]);

  return (
    <form className={css.formBrand}>
      {!isLogoUploadHidden && (
        <ImageUpload
          wrapperClassName={css.imageUpload}
          url={src}
          onUpload={handleUpload}
          buttonText={logoUrl ? 'Re-Upload' : 'Upload your logo'}
          imageCropProps={{
            src: src || '',
            onLoad: imageCropOnLoadHandler,
            onImageCropped: imageCropOnCroppedHandler,
            scale,
          }}
          withImageCrop={true}
          isShowImageCrop={isShowImageCrop}
          onShowImageCrop={setIsVisibleImageCrop}
        />
      )}
      {isShowImageCrop && (
        <ImageCropTools
          wrapperClassName={css.imageUploadTools}
          src={src || ''}
          scale={scale}
          onRevert={toolsOnRevert}
          onScale={toolsOnScaleHandler}
          fitScale={fitScale}
          onSave={toolsOnSave}
          onClose={toolsOnCloseHandler}
          wrapInWhiteBox
          maxScalePercent={200}
          isSaving={isSaving}
        />
      )}
      {!isColorPickerHidden && !isShowImageCrop && (
        <ColorsSelector
          primaryColorPickerWrapperClassName={'primaryColorPicker'}
          secondaryColorPickerWrapperClassName={'secondaryColorPicker'}
          className={css.colors}
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
          onPrimaryChange={onPrimaryColorPick}
          onSecondaryChange={onSecondaryColorPick}
        />
      )}
    </form>
  );
};
