import { useEffect, useCallback } from 'react';
import cn from 'classnames';
import {
  TableBody,
  TableHeader,
  TableHeaderCell,
  TableRow,
  TableRowCell,
  Typography,
  WhiteBox,
  Divider,
  InfinityScroll,
  InfinityRowProps,
} from '@ezetech/swag-space-x';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { ROUTES } from 'constants/router';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { StatusRow } from 'pages/my-orders/components/order-card-item/components/status-row';
import { OrderFields, IOrdersTransformRow } from 'interfaces/customer-company.interface';
import { ORDERS_TABLE_HEADER } from 'constants/customer-company.constant';
import { useCustomerCompanyOrders } from './orders.hook';

import css from './orders.module.scss';

const COUNT_PER_PAGE = 10;
const ITEM_HEIGHT = 59;

type itemRenderProps = InfinityRowProps<{
  items: IOrdersTransformRow[];
  handleCellClick: (
    e?: React.SyntheticEvent<HTMLSpanElement>,
    number?: string,
    rowId?: string,
  ) => void;
}>;

export function Orders(): JSX.Element {
  const navigate = useNavigate();
  const { getOrders, hasNextPage, currentPage, rows, isLoading } =
    useCustomerCompanyOrders();
  const { id } = useParams();

  const handleCellClick = (
    e?: React.SyntheticEvent<HTMLSpanElement>,
    number?: string,
    rowId?: string,
  ) => {
    if (number === OrderFields.number && rowId) {
      const path = {
        pathname: ROUTES.MY_ORDERS,
        search: createSearchParams({ orderId: rowId }).toString(),
      };
      navigate(path);
    }
  };

  const handleNewPageLoad = useCallback(async () => {
    if (!id) {
      return;
    }
    getOrders({
      id,
      page: currentPage + 1,
      perPage: COUNT_PER_PAGE,
    });
  }, [getOrders, currentPage, id]);

  useEffect(() => {
    if (!id) {
      return;
    }
    getOrders({
      id,
      page: 1,
      perPage: COUNT_PER_PAGE,
    });
  }, [id]);

  const rowRenderer = useCallback(({ index, data, style }: itemRenderProps) => {
    const row = data?.items[index];

    if (!row) {
      return <></>;
    }

    return (
      <div style={style}>
        <TableRow className={cn(css.tableRow)} primaryDataLabel={row.order.id}>
          {row.cells.map((cell, cellIndex) => (
            <TableRowCell
              className={css.tableCell}
              onClick={data.handleCellClick}
              key={`orders-${cellIndex}-${cell.id}`}
              textColor={cell.textColor}
              textWeight={cell.textWeight}
              size={cell.size}
              id={cell.id}
              rowId={cell.rowId}
              skipTooltip={cell.id === OrderFields.status}
              actionable={cell.actionable}
              tooltipBody={null}
              dataLabel={`${cell.id + '-' + cell.rowId}`}
            >
              {cell.id === OrderFields.status ? (
                <StatusRow
                  className={css.inline}
                  trackingNumberLink=""
                  status={cell.value as string}
                  orderId={cell.orderId || ''}
                  itemId={row.order.item.id}
                  orderItem={row.order.item}
                  fromCustomerProfile={true}
                  isReviewPantonesByCustomerAllowed={Boolean(
                    cell.isReviewPantonesByCustomerAllowed,
                  )}
                  isReviewMockupsByCustomerAllowed={Boolean(
                    cell.isReviewMockupsByCustomerAllowed,
                  )}
                />
              ) : (
                cell.text
              )}
            </TableRowCell>
          ))}
        </TableRow>
      </div>
    );
  }, []);

  const infinityWrapperStyle = {
    minHeight: ITEM_HEIGHT,
    height:
      ITEM_HEIGHT *
      (rows?.length && rows?.length < COUNT_PER_PAGE ? rows?.length : COUNT_PER_PAGE),
  };

  const itemProps = {
    handleCellClick,
  };

  return (
    <WhiteBox className={css.root}>
      <div className={css.header}>
        <Typography
          className={css.title}
          lineHeight="162%"
          fontType="bodyMdBold"
          color={COLORS.colorTextSubdued}
        >
          All orders placed
        </Typography>
      </div>
      <Divider />
      {rows && rows.length === 0 && (
        <div className={css.footer}>
          <Typography
            className={css.title}
            lineHeight="162%"
            fontType="bodyMdBold"
            color={COLORS.colorTextSubdued}
          >
            No Orders
          </Typography>
        </div>
      )}
      {rows && rows.length !== 0 && (
        <div className={css.tableWrapper}>
          <TableHeader className={css.tableHeader}>
            {ORDERS_TABLE_HEADER.map((cell) => (
              <TableHeaderCell
                id={cell.id}
                className={css.headerCell}
                size={cell.size}
                key={`orders-header-${cell.id}`}
              >
                {cell.text}
              </TableHeaderCell>
            ))}
          </TableHeader>
          <Divider className={css.headerDivider} />
          <TableBody>
            <div style={infinityWrapperStyle}>
              <InfinityScroll
                itemHeight={ITEM_HEIGHT}
                items={rows}
                itemProps={itemProps}
                loadPage={handleNewPageLoad}
                row={rowRenderer}
                hasNextPage={hasNextPage}
                isLoading={isLoading}
              />
            </div>
          </TableBody>
        </div>
      )}
    </WhiteBox>
  );
}
