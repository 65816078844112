import {
  OptionType,
  SwagButton,
  Tooltip,
  TooltipPlacement,
  Typography,
  WhiteBox,
} from '@ezetech/swag-space-x';
import { useSelector } from 'react-redux';
import { MultiValue } from 'react-select';
import { ChangeEvent, createRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from 'redux/store';
import {
  defaultSortingSelector,
  isCurrentResellerSettingsCategoryEnabledSelector,
  isReviewMockupsByCustomerAllowedSelector,
  isReviewPantonesByCustomerAllowedSelector,
  marginErrorSelector,
  marginProductCostSelector,
  marginValueSelector,
  marginYouMakeSelector,
  resellerSettingsActiveCategoryTabSelector,
  resellerSettingsBudgetEnabledSelector,
  resellerSettingsCategoriesSelector,
  resellerSettingsPremiumEnabledSelector,
  resellerSettingsStandardEnabledSelector,
  resellerSettingsSuperInexpensiveEnabledSelector,
} from 'redux/selectors/settings.selectors';
import {
  HIDE_PRODUCTS_POPUP,
  DEAL_COMPARISON_POPUP,
} from 'components/popups/_logic/popups-list';
import { LowMarginText } from 'components/low-margin';
import { ProductWidget } from 'pages/store-creation/product-selection/products-widget';
import { PriceWidget } from 'pages/store-creation/product-selection/price-widget';
import { DefaultSorting } from 'pages/store-creation/product-selection/default-sorting';
import {
  updateMargin,
  setResellerSettingsActiveCategoryTab,
  setResellerSettingsBudget,
  setResellerSettingsCategoryChecked,
  setResellerSettingsCategoryDisabled,
  setResellerSettingsPremium,
  setResellerSettingsStandard,
  setResellerSettingsSuperInexpensive,
  setCustomersReviewMockups,
  setCustomersReviewPantones,
  setDefaultSorting,
} from 'redux/slices/settings.slice';
import { openPopup } from 'redux/slices/modals.slice';
import { useGetProductsMutation } from 'redux/api/products.api';
import { ProductSortingEnum } from 'interfaces/settings.interface';
import {
  filtersProductsRequestSelector,
  totalHiddenProductsSelector,
} from 'redux/selectors/products.selectors';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import {
  MARGIN_MAX_PERCENT,
  MARGIN_MIN_PERCENT,
} from 'constants/store-creation.constants';
import { Price } from 'utils/price.util';
import { can, SUBJECTS } from 'boot/ability';

import { CustomItemTimelineTogglesWidget } from '../../store-creation/product-selection/custom-item-timeline-toggles-widget';

import css from './products-and-margin.module.scss';

const animationClasses = {
  enter: css.enter,
  enterActive: css.enterActive,
  exit: css.exit,
  exitActive: css.exitActive,
};

export function ProductsAndMargin(): JSX.Element {
  const errorRef = createRef<HTMLSpanElement>();
  const dispatch = useAppDispatch();
  const [getProducts] = useGetProductsMutation();
  const filters = useAppSelector(filtersProductsRequestSelector);
  const totalHidden = useAppSelector(totalHiddenProductsSelector);
  const selectedCategories = useSelector(resellerSettingsCategoriesSelector);
  const activeCategoryTab = useSelector(resellerSettingsActiveCategoryTabSelector);
  const isCurrentCategoryEnabled = useSelector(
    isCurrentResellerSettingsCategoryEnabledSelector,
  );

  const isBudgetEnabled = useSelector(resellerSettingsBudgetEnabledSelector);
  const isPremiumEnabled = useSelector(resellerSettingsPremiumEnabledSelector);
  const isStandardEnabled = useSelector(resellerSettingsStandardEnabledSelector);
  const isSuperInexpensiveEnabled = useSelector(
    resellerSettingsSuperInexpensiveEnabledSelector,
  );
  const margin = useSelector(marginValueSelector);
  const marginError = useSelector(marginErrorSelector);
  const productCost = useSelector(marginProductCostSelector);
  const youMake = useSelector(marginYouMakeSelector);
  const isReviewMockupsByCustomerAllowed = useSelector(
    isReviewMockupsByCustomerAllowedSelector,
  );
  const isReviewPantonesByCustomerAllowed = useSelector(
    isReviewPantonesByCustomerAllowedSelector,
  );
  const defaultSorting = useSelector(defaultSortingSelector);

  const currentCategory = selectedCategories[activeCategoryTab];
  const currentCategoryName = currentCategory?.name;

  const canEditPantoneAndMockupApproval = can(
    SUBJECTS.PANTONE_AND_MOCKUP_APPROVAL.actions.PANTONE_AND_MOCKUP_APPROVAL_EDIT,
    SUBJECTS.PANTONE_AND_MOCKUP_APPROVAL.value,
  );
  const canViewPantoneAndMockupApproval = can(
    SUBJECTS.PANTONE_AND_MOCKUP_APPROVAL.actions.PANTONE_AND_MOCKUP_APPROVAL_VIEW,
    SUBJECTS.PANTONE_AND_MOCKUP_APPROVAL.value,
  );
  const hasProductSortingViewPermission = can(
    SUBJECTS.PRODUCT_SORTING.actions.PRODUCT_SORTING_VIEW,
    SUBJECTS.PRODUCT_SORTING.value,
  );

  const setProductSubCategorySelected = ({
    checked,
    parentIndex,
    index,
  }: {
    checked: boolean;
    parentIndex: number;
    index: number;
  }) => {
    dispatch(setResellerSettingsCategoryChecked({ checked, parentIndex, index }));
  };

  const openDealComparisonPopup = (): void => {
    dispatch(openPopup({ popupName: DEAL_COMPARISON_POPUP, popupProps: {} }));
  };

  const onTabClick = (index: number) => {
    dispatch(setResellerSettingsActiveCategoryTab({ activeCategoryTab: index }));
  };

  const onToggle = () => {
    dispatch(
      setResellerSettingsCategoryDisabled({
        isEnabled: !isCurrentCategoryEnabled,
        activeCategoryTab,
      }),
    );
  };

  const onBudgetToggle = () => {
    dispatch(setResellerSettingsBudget({ isEnabled: !isBudgetEnabled }));
  };
  const onPremiumToggle = () => {
    dispatch(setResellerSettingsPremium({ isEnabled: !isPremiumEnabled }));
  };
  const onStandardToggle = () => {
    dispatch(setResellerSettingsStandard({ isEnabled: !isStandardEnabled }));
  };
  const onSuperInexpensiveToggle = () => {
    dispatch(
      setResellerSettingsSuperInexpensive({ isEnabled: !isSuperInexpensiveEnabled }),
    );
  };

  const onCustomersReviewMockupsToggleClick = () => {
    if (canEditPantoneAndMockupApproval) {
      dispatch(setCustomersReviewMockups(!isReviewMockupsByCustomerAllowed));
    }
  };

  const onCustomersReviewPantonesToggleClick = () => {
    if (canEditPantoneAndMockupApproval) {
      dispatch(setCustomersReviewPantones(!isReviewPantonesByCustomerAllowed));
    }
  };

  const onMarginChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>): void => {
    if (value.length < 3) {
      dispatch(updateMargin(value));
    }
  };

  const onShowHideProductsClick = async () => {
    const { search, perPage, page, filter } = filters;
    await getProducts({ search, perPage, page, filter, includeHiddenList: true });

    dispatch(openPopup({ popupName: HIDE_PRODUCTS_POPUP, popupProps: {} }));
  };

  const onDefaultSortingSelect = (
    option: OptionType | MultiValue<OptionType | null> | null,
  ) => {
    dispatch(setDefaultSorting((option as OptionType)?.value as ProductSortingEnum));
  };

  return (
    <div className={css.root}>
      {can(
        SUBJECTS.AVAILABLE_PRODUCTS.actions.AVAILABLE_PRODUCTS_VIEW,
        SUBJECTS.AVAILABLE_PRODUCTS.value,
      ) && (
        <WhiteBox className={css.section}>
          <Typography
            className={css.header}
            color={COLORS.colorDarkBlue}
            fontType="HEADING5XL"
          >
            Available Products
          </Typography>
          <div className={css.underline} />
          <div className={css.content}>
            <ProductWidget
              selectedCategories={selectedCategories}
              onTabClick={onTabClick}
              activeCategoryTab={activeCategoryTab}
              onToggle={onToggle}
              isCurrentCategoryEnabled={isCurrentCategoryEnabled}
              currentCategoryName={currentCategoryName}
              dispatchFn={setProductSubCategorySelected}
              widgetProductsListClassName={css.widgetProductsListClassName}
              disableLineClassName={css.disableLineClassName}
            />
          </div>
        </WhiteBox>
      )}
      {can(
        SUBJECTS.PRICE_POINTS_FOR_ALL_PRODUCTS.actions.PRICE_POINTS_FOR_ALL_PRODUCTS_VIEW,
        SUBJECTS.PRICE_POINTS_FOR_ALL_PRODUCTS.value,
      ) && (
        <WhiteBox className={css.section}>
          <Typography
            className={css.header}
            color={COLORS.colorDarkBlue}
            fontType="HEADING5XL"
          >
            <span className={css.pricePointsText}>Price points for all products</span>
            <Tooltip placement={TooltipPlacement.right} titleClassName={css.icon}>
              <div className={css.tooltipText}>
                <Typography
                  fontType="bodySm"
                  lineHeight="143%"
                  color={COLORS.colorTypographyHeadings}
                >
                  In every product category there are different levels of products ranging
                  from super inexpensive, to premium. For example, there could be a $2
                  tshirt, or a $75 tshirt. This is the section where you select the types
                  of products that you want to appear in your store.
                </Typography>
              </div>
            </Tooltip>
          </Typography>
          <div className={css.underline} />
          <div className={css.content}>
            <PriceWidget
              onSuperInexpensiveToggle={onSuperInexpensiveToggle}
              isSuperInexpensiveEnabled={isSuperInexpensiveEnabled}
              onBudgetToggle={onBudgetToggle}
              isBudgetEnabled={isBudgetEnabled}
              onStandardToggle={onStandardToggle}
              isStandardEnabled={isStandardEnabled}
              onPremiumToggle={onPremiumToggle}
              isPremiumEnabled={isPremiumEnabled}
              widgetPriceRangeClassName={css.widgetPriceRangeClassName}
              verticalDividerTabletClassName={css.verticalDividerTabletClassName}
              disableLine2ClassName={css.disableLine2ClassName}
              hideTitleLine
            />
          </div>
        </WhiteBox>
      )}
      {canViewPantoneAndMockupApproval && (
        <WhiteBox className={css.section}>
          <Typography
            className={css.header}
            color={COLORS.colorDarkBlue}
            fontType="HEADING5XL"
          >
            <span className={css.pricePointsText}>Pantone & Mockup Approvals</span>
          </Typography>
          <div className={css.underline} />
          <div className={css.content}>
            <Typography
              lineHeight="162.5%"
              color={COLORS.colorPrimaryText}
              fontType="bodyMd"
            >
              By turning off customer review for Pantones and Mockups, all orders will go
              straight into production after your approval. Your customer will NOT receive
              a Mockup, and will NOT be prompted to approve their Pantone colors.
              <br />
              <br />
              This can also be adjusted on a per customer basis from the customer profile
              page.
            </Typography>

            <CustomItemTimelineTogglesWidget
              toggleClassName={css.toggleClassName}
              isReviewMockupsByCustomerAllowed={isReviewMockupsByCustomerAllowed}
              isReviewPantonesByCustomerAllowed={isReviewPantonesByCustomerAllowed}
              onCustomersReviewMockupsToggleClick={onCustomersReviewMockupsToggleClick}
              onCustomersReviewPantonesToggleClick={onCustomersReviewPantonesToggleClick}
              disableLine2ClassName={css.togglesLine}
            />
          </div>
        </WhiteBox>
      )}
      {hasProductSortingViewPermission && (
        <WhiteBox className={cn(css.section, css.visibleOverflow)}>
          <Typography
            className={css.header}
            color={COLORS.colorDarkBlue}
            fontType="HEADING5XL"
          >
            Default sorting
          </Typography>
          <div className={css.underline} />
          <div className={css.content}>
            <DefaultSorting
              defaultSorting={defaultSorting}
              onChange={onDefaultSortingSelect}
            />
          </div>
        </WhiteBox>
      )}
      {can(
        SUBJECTS.COMMISSION.actions.PRODUCT_COMMISSION_VIEW,
        SUBJECTS.COMMISSION.value,
      ) && (
        <WhiteBox className={css.section}>
          <Typography
            className={css.header}
            color={COLORS.colorDarkBlue}
            fontType="HEADING5XL"
          >
            Commission
          </Typography>
          <div className={css.underline} />
          <div className={css.marginContainer}>
            <div className={css.inputWrapper}>
              <Typography
                lineHeight={'120%'}
                fontType={'bodyMd'}
                color={COLORS.colorDarkBlue}
                className={css.label}
              >
                Product Commission
              </Typography>
              <div className={css.fakeInput}>
                <div>
                  <input
                    min={MARGIN_MIN_PERCENT * 100}
                    max={MARGIN_MAX_PERCENT * 100}
                    maxLength={10}
                    onChange={onMarginChange}
                    value={margin}
                    name={'commission'}
                    className={css.input}
                    disabled={
                      !can(
                        SUBJECTS.COMMISSION.actions.PRODUCT_COMMISSION_EDIT,
                        SUBJECTS.COMMISSION.value,
                      )
                    }
                  />
                  <Typography color={COLORS.colorTypographyBodyGreyed}>%</Typography>
                </div>
                <Tooltip
                  titleClassName={css.tooltipTextInputTrigger}
                  bodyClassName={css.tooltipBody}
                  placement={TooltipPlacement.bottom}
                >
                  <div className={css.tooltipTextInput}>
                    <Typography
                      fontType="bodySm"
                      lineHeight="143%"
                      color={COLORS.colorTypographyHeadings}
                    >
                      Set your commissions and choose how much you want to make on your
                      sales. The minimum amount is 30%. Keep in mind, if you increase the
                      commission more than 30%, your prices will be more expensive than
                      what is recommended based on industry standard.
                    </Typography>
                  </div>
                </Tooltip>
              </div>
              <CSSTransition
                in={!!marginError}
                classNames={animationClasses}
                timeout={500}
                nodeRef={errorRef}
                unmountOnExit
              >
                <Typography
                  className={css.errorText}
                  lineHeight="100%"
                  fontType="bodySm"
                  color={COLORS.colorErrorText}
                >
                  <span ref={errorRef}>{marginError}</span>
                </Typography>
              </CSSTransition>
            </div>
            <div className={css.calculationsContainer}>
              <Typography fontType="bodyMdBold" color={COLORS.colorPrimaryText}>
                Based on a $10 Tshirt
              </Typography>
              <div className={css.calculationsResults}>
                <div className={css.calculationsResultItem}>
                  <Typography fontType="bodyMd" color={COLORS.colorText500}>
                    Product cost:
                  </Typography>
                  <Typography fontType="bodyMd" color={COLORS.colorPrimaryText}>
                    {Price.formatPrice(productCost)}
                  </Typography>
                </div>
                <div className={css.calculationsResultItem}>
                  <Typography fontType="bodyMd" color={COLORS.colorText500}>
                    You make:
                  </Typography>
                  <Typography fontType="bodyMd" color={COLORS.colorPrimaryText}>
                    {Price.formatPrice(youMake)}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          <div className={css.centerAlignedContainer}>
            <SwagButton
              size="large"
              type="outlined"
              onClick={openDealComparisonPopup}
              className={css.openDealComparisonPopupButton}
            >
              <Typography
                lineHeight="162.5%"
                fontType="bodyMdBold"
                color={COLORS.colorPrimaryText}
              >
                See how it compares
              </Typography>
            </SwagButton>
          </div>
          {can(
            SUBJECTS.COMMISSION.actions.HIDE_PRODUCTS_FROM_THE_SITE_VIEW,
            SUBJECTS.COMMISSION.value,
          ) && (
            <div className={css.lowMarginContainer}>
              <div className={css.underlineNoMargin} />
              <LowMarginText
                header="Show/Hide products from your site"
                currentMargin={Number(margin)}
                disabled={!!marginError}
              />
              <div className={css.hideSectionFooter}>
                <SwagButton
                  className={css.hideButton}
                  onClick={onShowHideProductsClick}
                  type="primary"
                >
                  View Products
                </SwagButton>
                <Typography
                  fontType="bodyMd"
                  lineHeight="162.5%"
                  color={COLORS.colorSecondaryText}
                >
                  <Typography
                    fontType="bodyMd"
                    lineHeight="162.5%"
                    color={COLORS.colorPink}
                  >
                    {totalHidden}
                  </Typography>{' '}
                  Products hidden from site
                </Typography>
              </div>
            </div>
          )}
        </WhiteBox>
      )}
    </div>
  );
}
