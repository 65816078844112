import {
  Dialog,
  DialogContentWrapper,
  DialogTitle,
  DialogDescription,
  DialogFooter,
  SwagButton,
  Typography,
} from '@ezetech/swag-space-x';
import { useAppDispatch } from 'redux/store';
import { closePopup } from 'redux/slices/modals.slice';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { VerifyConnectionFinishedProps } from 'interfaces/popup.interface';
import css from './verify-connection-finished-popup.module.scss';

export const VerifyConnectionFinished = ({
  isOpen,
  customDomainName,
}: VerifyConnectionFinishedProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const onCloseClick = () => {
    dispatch(closePopup());
  };

  return (
    <Dialog open={isOpen} onOpenChange={onCloseClick}>
      <DialogContentWrapper
        className={css.dialogContentWrapperClassName}
        overlayClassName={css.dialogOverlayClassName}
      >
        <DialogTitle closeIconClassName={css.closeIconClassName} className={css.title}>
          Connect existing domain
        </DialogTitle>
        <DialogDescription className={css.textWrapper}>
          <Typography color={COLORS.colorTextSubdued} fontType="bodyMd">
            We are generating an SSL certificate for your domain{' '}
            <a
              href={`https://${customDomainName}`}
              className={css.link}
              target="_blank"
              rel="noreferrer"
            >
              https://{customDomainName}
            </a>
            . This process can take up to 10 minutes. Once it is completed your domain
            will automatically become active.
          </Typography>
        </DialogDescription>

        <DialogFooter className={css.footer}>
          <SwagButton type="primary" onClick={onCloseClick} className={css.btn}>
            Finish
          </SwagButton>
        </DialogFooter>
      </DialogContentWrapper>
    </Dialog>
  );
};
