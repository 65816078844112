import cn from 'classnames';
import React from 'react';
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderCell,
  TableRow,
  TableRowCell,
  WhiteBox,
  TooltipTriggerTypes,
  Select,
  Typography,
  OptionType,
} from '@ezetech/swag-space-x';
import { useNavigate } from 'react-router-dom';
import { MultiValue } from 'react-select';
import { useAppSelector } from 'redux/store';
import { SUBJECTS, can } from 'boot/ability';

import {
  ABANDONED_CHECKOUTS_OPTIONS,
  AbandonedCheckoutsCartStatusEnum,
  AbandonedCheckoutsCartsFields,
  ITransformAbandonedCheckoutsCartCell,
  ITransformAbandonedCheckoutsCartRow,
} from 'interfaces/abandoned-checkouts-cart.interfaces';

import {
  abandonedCheckoutCartsEmptyStateSelector,
  abandonedCheckoutCartsRowsSelector,
  abandonedCheckoutHeaderSelector,
} from 'redux/selectors/abandoned-checkout-carts.selector';

import { NotificationType } from 'redux/slices/notifications.slice';
import { EmptyState } from 'components/empty-state';
import { ROUTES } from 'constants/router';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { useNotifications } from 'hooks/notifications.hook';
import { useClipboard } from 'hooks/use-clipboard.hook';
import { getTimeMmmDdYyyy } from 'utils/date/date-formatter.util';
import { getProductsNumber } from 'redux/selectors/carts.selector';
import { abandonedCheckoutsAssignToOptionsSelector } from 'redux/selectors/assignee.selector';
import { useAssignAbandonedCheckoutToUserMutation } from 'redux/api/assignee.api';

import { useUpdateAbandonedStatusMutation } from '../../../redux/api/abandoned-checkouts-carts.api';
import css from './abandoned-checkout-cart.module.scss';
import { ProductsTooltipContent } from './products-tooltip-content/products-tooltip-content';

const SHOULD_SKIP_TOOLTIP_CELLS = [
  AbandonedCheckoutsCartsFields.status,
  AbandonedCheckoutsCartsFields.assignTo,
];

export function AbandonedCheckoutCart({
  onHeaderCellClick,
  getSortingDirection,
}: {
  onHeaderCellClick(id: string): void;
  getSortingDirection(id: string): 'up' | 'down';
}): JSX.Element {
  const navigate = useNavigate();
  const { copy } = useClipboard();
  const { pushNotification } = useNotifications();
  const headerCells = useAppSelector(abandonedCheckoutHeaderSelector);
  const rows = useAppSelector(abandonedCheckoutCartsRowsSelector);
  const emptyState = useAppSelector(abandonedCheckoutCartsEmptyStateSelector);
  const [updateStatus] = useUpdateAbandonedStatusMutation();
  const [assignAbandonedCheckoutToUserRequest] =
    useAssignAbandonedCheckoutToUserMutation();
  const abandonedCheckoutsAssignToOptions = useAppSelector(
    abandonedCheckoutsAssignToOptionsSelector,
  );

  const isAbleToAssignAbandonedOrder = can(
    SUBJECTS.TEAM_MEMBER_ABANDONED_ORDER_ASSIGNMENT.actions
      .ASSIGN_TEAM_MEMBER_TO_ABANDONED_ORDER,
    SUBJECTS.TEAM_MEMBER_ABANDONED_ORDER_ASSIGNMENT.value,
  );

  const isAbleToBeAssignedForAbandonedOrder = can(
    SUBJECTS.ABANDONED_CHECKOUTS.actions.ASSIGN_ABANDONED_ORDERS,
    SUBJECTS.ABANDONED_CHECKOUTS.value,
  );

  const handleClick = () => {
    navigate(ROUTES.DASHBOARD);
  };

  const copyText = async (value?: string, field?: string) => {
    const text = field ? `${field}` : 'Text';
    const isSuccess = await copy(value);

    if (isSuccess) {
      pushNotification({
        status: 'positive',
        message: `${text} has been copied.`,
        type: NotificationType.toasts,
      });
    }
  };

  const handleHeaderCellClick = (id: string) => {
    onHeaderCellClick(id);
  };

  const handleCellClick = (e?: React.SyntheticEvent<HTMLSpanElement>, id?: string) => {
    if (id === AbandonedCheckoutsCartsFields.email) {
      copyText(e?.currentTarget.innerHTML, 'Email');
    }
  };

  const shouldShowTooltipAlways = (
    cell: ITransformAbandonedCheckoutsCartCell,
  ): boolean => {
    const { id, text } = cell;
    if (id === AbandonedCheckoutsCartsFields.products && getProductsNumber(text) > 0) {
      return true;
    }
    return false;
  };

  const getIsActionableCell = (cell: ITransformAbandonedCheckoutsCartCell): boolean => {
    const { text, id } = cell;

    if (id !== AbandonedCheckoutsCartsFields.products) {
      return cell.actionable || false;
    }

    return getProductsNumber(text) > 0;
  };

  const renderTooltipCellContent = (
    cell: ITransformAbandonedCheckoutsCartCell,
  ): null | React.ReactNode => {
    const { id } = cell;
    if (id !== AbandonedCheckoutsCartsFields.products) {
      return null;
    }
    return <ProductsTooltipContent abandonedCartId={cell.rowId} />;
  };

  const onAssignmentSelect =
    (cell: ITransformAbandonedCheckoutsCartCell) =>
    (option: OptionType | MultiValue<OptionType | null> | null) => {
      if ((option as OptionType).value === cell.assignedToUser?.value) {
        return;
      }

      assignAbandonedCheckoutToUserRequest({
        entityId: cell.rowId || '',
        userId: (option as OptionType).value || '',
      });
    };

  const onStatusSelect =
    (cell: ITransformAbandonedCheckoutsCartCell) =>
    (option: OptionType | MultiValue<OptionType | null> | null) => {
      if ((option as OptionType).value === cell.status?.value || !cell.rowId) {
        return;
      }

      updateStatus({ id: cell.rowId, status: (option as OptionType).value });
    };

  const getCellContent = (
    cell: ITransformAbandonedCheckoutsCartCell,
    row: ITransformAbandonedCheckoutsCartRow,
  ) => {
    switch (true) {
      case cell.id === AbandonedCheckoutsCartsFields.status:
        return (
          <div
            className={cn(css.selectWrapper, {
              [css.colorGreen]:
                cell.status?.value === AbandonedCheckoutsCartStatusEnum.COMPLETED,
            })}
          >
            <Select
              options={ABANDONED_CHECKOUTS_OPTIONS}
              value={cell.status}
              isSearchable={false}
              onChange={onStatusSelect(cell)}
              name="status"
              skipError
              menuPlacement="auto"
              size="small"
              type="outlined"
              menuPosition="fixed"
              menuShouldBlockScroll
            />
          </div>
        );
      case cell.id === AbandonedCheckoutsCartsFields.createdAt ||
        cell.id === AbandonedCheckoutsCartsFields.cartSyncedAt:
        return (
          <div className={css.cartSyncedAt}>
            {getTimeMmmDdYyyy(new Date(cell.text), {
              month: 'short',
              day: '2-digit',
              hour: 'numeric',
              minute: '2-digit',
              hour12: true,
            })}
          </div>
        );

      case cell.id === AbandonedCheckoutsCartsFields.assignTo: {
        if (
          isAbleToAssignAbandonedOrder ||
          (isAbleToBeAssignedForAbandonedOrder && !cell.assignedToUser)
        ) {
          return (
            <div className={css.selectWrapper}>
              <Select
                options={abandonedCheckoutsAssignToOptions}
                value={cell.assignedToUser}
                isSearchable={true}
                onChange={onAssignmentSelect(cell)}
                name="assignTo"
                skipError
                menuPlacement="auto"
                size="small"
                type="outlined"
                menuPosition="fixed"
                menuShouldBlockScroll
              />
            </div>
          );
        }

        return (
          <div className={css.selectWrapper}>
            <Typography color={COLORS.colorPrimaryText} fontType="bodyMd">
              {cell.assignedToUser?.label}
            </Typography>
          </div>
        );
      }

      default:
        return cell.text;
    }
  };

  if (!rows.length) {
    const { title, description, button } = emptyState;

    return (
      <EmptyState
        title={title}
        button={button}
        description={description}
        onClick={handleClick}
      />
    );
  }

  return (
    <WhiteBox className={css.root}>
      <Table className={css.tableWrapper}>
        <TableHeader>
          {headerCells.map((cell, index) => (
            <TableHeaderCell
              id={cell.id}
              className={cn({
                [css.number]: index === 0,
                [css.cartSyncedAt]:
                  cell.id === AbandonedCheckoutsCartsFields.cartSyncedAt ||
                  cell.id === AbandonedCheckoutsCartsFields.createdAt,
              })}
              size={cell.size}
              iconDirection={getSortingDirection(cell.id)}
              withSorting={cell.withSorting}
              onClick={cell.withSorting ? handleHeaderCellClick : undefined}
              key={`abandoned-checkout-cart-header-${cell.id}`}
            >
              {cell.text}
            </TableHeaderCell>
          ))}
        </TableHeader>
        <TableBody>
          {rows.map((row, position) => (
            <TableRow
              key={`abandoned-checkout-cart-${row.index}`}
              primaryDataLabel={row.cells[0].rowId}
            >
              {row.cells.map((cell, index) => (
                <TableRowCell
                  className={cn(css.cell, {
                    [css.number]: index === 0,
                    [css.naStyle]: cell.text === 'n/a',
                    [css.status]: cell.id === AbandonedCheckoutsCartsFields.status,
                  })}
                  key={`abandoned-checkout-cart-${position}-${cell.id}-${index}`}
                  textColor={cell.textColor}
                  textWeight={cell.textWeight}
                  size={cell.size}
                  skipTooltip={SHOULD_SKIP_TOOLTIP_CELLS.includes(cell.id)}
                  tooltipBody={renderTooltipCellContent(cell)}
                  forceShow={shouldShowTooltipAlways(cell)}
                  tooltipTrigger={
                    cell.id === 'products'
                      ? TooltipTriggerTypes.click
                      : TooltipTriggerTypes.mouseenter
                  }
                  id={cell.id}
                  rowId={cell.rowId}
                  actionable={getIsActionableCell(cell)}
                  onClick={handleCellClick}
                  dataLabel={`${cell.id + '-' + cell.rowId}`}
                >
                  {getCellContent(cell, row)}
                </TableRowCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </WhiteBox>
  );
}
