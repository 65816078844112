import { makeToastNotification } from 'utils/query.util';
import { fetchUserData } from 'utils/user.util';
import { IAction } from 'interfaces/loaders.interface';
import { fetchStoreSettingsData } from 'utils/store-settings.util';
import { settingsApi } from '../../redux/api/settings.api';
import { productsApi } from '../../redux/api/products.api';
import { pushNotification } from '../../redux/slices/notifications.slice';
import { store } from '../../redux/store';
import { can, SUBJECTS } from '../../boot/ability';

export async function settingsLoader(): Promise<null> {
  await fetchUserData();
  await fetchStoreSettingsData();

  const requests: Record<string, unknown>[] = [
    {
      endpoint: settingsApi.endpoints.getCompany.initiate(),
      condition: can(
        SUBJECTS.COMPANY_DETAILS.actions.COMPANY_DETAILS_VIEW,
        SUBJECTS.COMPANY_DETAILS.value,
      ),
    },
    {
      endpoint: settingsApi.endpoints.getBillingAddress.initiate(),
      condition: can(
        SUBJECTS.BILLING_ADDRESS.actions.BILLING_ADDRESS_VIEW,
        SUBJECTS.BILLING_ADDRESS.value,
      ),
    },
    {
      endpoint: settingsApi.endpoints.getInvoice.initiate(),
      condition: can(
        SUBJECTS.INVOICE_BANK_DETAILS.actions.INVOICE_BANK_DETAILS_VIEW,
        SUBJECTS.INVOICE_BANK_DETAILS.value,
      ),
    },
    {
      endpoint: settingsApi.endpoints.getCreditCards.initiate(),
      condition: can(
        SUBJECTS.ACH_NET_PAYMENTS_MAJOR_DISCOUNT_PROMO_CODES.actions
          .ACH_NET_PAYMENTS_MAJOR_DISCOUNT_PROMO_CODES_VIEW,
        SUBJECTS.ACH_NET_PAYMENTS_MAJOR_DISCOUNT_PROMO_CODES.value,
      ),
    },
    {
      endpoint: settingsApi.endpoints.getWhatWeOfferData.initiate(),
      condition: can(
        SUBJECTS.PAGES_POPUPS_PDF_PRESENTATIONS.actions.WHAT_WE_OFFER_PAGE_VIEW,
        SUBJECTS.PAGES_POPUPS_PDF_PRESENTATIONS.value,
      ),
    },
    {
      endpoint: productsApi.endpoints.getHiddenProductsCount.initiate(),
      condition: can(
        SUBJECTS.COMMISSION.actions.HIDE_PRODUCTS_FROM_THE_SITE_VIEW,
        SUBJECTS.COMMISSION.value,
      ),
    },
    {
      endpoint: settingsApi.endpoints.getHomepageData.initiate(),
      condition: can(
        SUBJECTS.PAGES_POPUPS_PDF_PRESENTATIONS.actions.HOMEPAGE_VIEW,
        SUBJECTS.PAGES_POPUPS_PDF_PRESENTATIONS.value,
      ),
    },
  ];

  try {
    const results = await Promise.all(
      requests.map(async (request) => {
        if (request?.condition === false) {
          return;
        }

        return store.dispatch(request.endpoint as IAction).unwrap();
      }),
    );

    if (results.some((result) => result && 'error' in result)) {
      return null;
    }
  } catch (error) {
    store.dispatch(pushNotification(makeToastNotification(error)));
  }

  return null;
}
