import { useState, useRef, useEffect } from 'react';
import { Page, Document } from 'react-pdf';
import { useAsync } from 'react-use';

import 'workers/pdf-worker/pdf-worker.runner';
import 'react-pdf/dist/Page/TextLayer.css';

import {
  PdfViewerHookProps,
  PdfDocumentLoadedProps,
  ImagesOfPagesType,
} from './pdf-viewer.interface';
import { renderPdfInWorker } from './worker/wrap';
import { generateImagesFromPages } from './pdf-viewer.utils';

export const usePdfViewer = (props: PdfViewerHookProps) => {
  const {
    pdfSettings,
    currentPageIndex,
    size = {},
    onPagesRendered,
    reRenderAllPages,
  } = props;

  const currentPageId =
    currentPageIndex && pdfSettings?.pagesToRender[currentPageIndex]
      ? pdfSettings?.pagesToRender[currentPageIndex]
      : pdfSettings?.pagesToRender[0];

  const [numPages, setNumPages] = useState(1);
  const [allImagesOfPages, setAllImagesOfPages] = useState<ImagesOfPagesType[]>();

  const [multiPagesRendered, setMultiPagesRendered] = useState(0);
  const [isRendering, setIsRendering] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [lastRenderedPageId, setLastRenderedPageId] = useState('');

  const mainDocumentRef = useRef<HTMLDivElement>(null);
  const doFullReRender = isFirstRender || !allImagesOfPages?.length || reRenderAllPages;

  const render = useAsync(async () => {
    if (
      !pdfSettings?.values ||
      !pdfSettings?.primaryColor ||
      !pdfSettings?.secondaryColor ||
      !pdfSettings?.logoUrl
    ) {
      return null;
    }
    setIsRendering(true);
    const url = await renderPdfInWorker({
      ...pdfSettings,
      ...(doFullReRender ? {} : { pageId: currentPageId }),
    });
    if (currentPageId) {
      setLastRenderedPageId(doFullReRender ? '' : currentPageId);
    }
    return url;
  }, [
    JSON.stringify(pdfSettings?.values),
    pdfSettings?.primaryColor,
    pdfSettings?.secondaryColor,
    pdfSettings?.logoUrl,
  ]);

  const getImagesToRender = (images: ImagesOfPagesType[] | undefined) => {
    if (pdfSettings?.pagesToRender.length && images?.length) {
      const filteredImages = images?.filter((image) =>
        pdfSettings.pagesToRender.includes(image.pageId),
      );
      return filteredImages;
    }
    return [];
  };

  useEffect(() => {
    if (!pdfSettings?.pagesToRender) {
      return;
    }
    const imagesToRender = getImagesToRender(allImagesOfPages);
    onPagesRendered && onPagesRendered(imagesToRender);
  }, [pdfSettings?.pagesToRender?.join()]);

  const onDocumentLoadSuccess = (data: PdfDocumentLoadedProps) => {
    const numPagesInDoc = data?.numPages || 1;
    setNumPages(numPagesInDoc);
  };

  const updateImagesOfPages = async () => {
    if (!mainDocumentRef.current || !pdfSettings) {
      return;
    }
    const images = await generateImagesFromPages(
      mainDocumentRef.current,
      pdfSettings.pagesToRender,
      allImagesOfPages,
      lastRenderedPageId,
    );

    if (images) {
      setAllImagesOfPages(images);
    }
    const imagesToRender = getImagesToRender(images);
    onPagesRendered && onPagesRendered(imagesToRender || []);
  };

  const onRendered = async () => {
    await updateImagesOfPages();
    setIsRendering(false);
    setIsFirstRender(false);
  };

  useEffect(() => {
    if (multiPagesRendered === 0 || multiPagesRendered < numPages) {
      return;
    }
    setMultiPagesRendered(0);
    onRendered();
  }, [multiPagesRendered]);

  if (!isRendering && render.loading) {
    setIsRendering(true);
  }

  const hiddenStyle = { display: 'none' };
  const HiddenDocument = (
    <div style={hiddenStyle}>
      <Document
        inputRef={mainDocumentRef}
        key={render.value}
        file={render.value}
        loading={null}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {numPages &&
          new Array(numPages)
            .fill('')
            .map((val, index) => (
              <Page
                {...size}
                key={index + 1}
                onRenderSuccess={() => setMultiPagesRendered((num) => (num += 1))}
                onRenderError={() => setMultiPagesRendered((num) => (num += 1))}
                pageNumber={index + 1}
                loading={null}
                renderAnnotationLayer={false}
                renderTextLayer={false}
              />
            ))}
      </Document>
    </div>
  );
  return {
    HiddenDocument,
    isRendering,
  };
};
