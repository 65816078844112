import { useEffect, useMemo, useState } from 'react';
import { PageSidebarWidth, PreviewWidth } from 'constants/pdf-presentation.constants';

export const usePreviewWidth = (isSidebar = false) => {
  const [width, setWidth] = useState(window.innerWidth);
  const handleResize = () => {
    setWidth(window.innerWidth);
  };

  const previewWidth = useMemo(() => {
    if (width >= 1920) {
      return isSidebar
        ? PageSidebarWidth.from1920toInfinity
        : PreviewWidth.from1920toInfinity;
    }
    if (1920 > width && width >= 1820) {
      return isSidebar ? PageSidebarWidth.from1820to1919 : PreviewWidth.from1820to1919;
    }
    if (1820 > width && width >= 1720) {
      return isSidebar ? PageSidebarWidth.from1720to1819 : PreviewWidth.from1720to1819;
    }
    if (1720 > width && width >= 1620) {
      return isSidebar ? PageSidebarWidth.from1620to1719 : PreviewWidth.from1620to1719;
    }
    if (1620 > width && width >= 1520) {
      return isSidebar ? PageSidebarWidth.from1520to1619 : PreviewWidth.from1520to1619;
    }
    if (1520 > width && width >= 1420) {
      return isSidebar ? PageSidebarWidth.from1420to1519 : PreviewWidth.from1420to1519;
    }
    if (1420 > width && width >= 1320) {
      return isSidebar ? PageSidebarWidth.from1320to1419 : PreviewWidth.from1320to1419;
    }
    if (1320 > width && width >= 1220) {
      return isSidebar ? PageSidebarWidth.from1220to1319 : PreviewWidth.from1220to1319;
    }
    // unchanged
    if (1220 > width && width >= 1024) {
      return isSidebar ? PageSidebarWidth.from1024to1219 : PreviewWidth.from1024to1219;
    }
    if (1024 > width && width >= 768) {
      return isSidebar ? PageSidebarWidth.from768to1023 : PreviewWidth.from768to1023;
    }
    return isSidebar ? PageSidebarWidth.from1024to1219 : PreviewWidth.fromStartTo767;
  }, [width]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return previewWidth;
};
