import cn from 'classnames';

import { BoxWidgetEnum } from 'enums/box-widget-enum';
import { BoxWidgetProps } from './box-widget.interface';

import css from './box-widget.module.scss';

export function BoxWidgetComponent({
  type,
  className,
  children,
}: BoxWidgetProps): JSX.Element {
  return (
    <div className={cn(className, css.boxWidget)}>
      {type === BoxWidgetEnum.HEADED && <div className={css.gradientHead} />}
      {children}
    </div>
  );
}
