// convert table and code from https://codebeautify.org/rgb-to-pantone-converter
const aPMS = [
  'white',
  'black',
  'yellow',
  'yellow 012',
  'orange 021',
  'warm red',
  'red 032',
  'rubine red',
  'rhodamine red',
  'purple',
  'violet',
  'blue 072',
  'reflex blue',
  'process blue',
  'green',
  'black',
  'yellow 0131',
  'red 0331',
  'magenta 0521',
  'violet 0631',
  'blue 0821',
  'green 0921',
  'black 0961',
  '801',
  '802',
  '803',
  '804',
  '805',
  '806',
  '807',
  '871',
  '872',
  '873',
  '874',
  '875',
  '876',
  '877',
  'medium yellow',
  'bright orange',
  'bright red',
  'strong red',
  'pink',
  'medium purple',
  'dark blue',
  'medium blue',
  'bright green',
  'neutral black',
  '100',
  '101',
  '102',
  '103',
  '104',
  '105',
  '7401',
  '7402',
  '7403',
  '7404',
  '7405',
  '7406',
  '7407',
  '106',
  '107',
  '108',
  '109',
  '110',
  '111',
  '112',
  '113',
  '114',
  '115',
  '116',
  '117',
  '118',
  '119',
  '127',
  '128',
  '129',
  '130',
  '131',
  '132',
  '133',
  '1205',
  '1215',
  '1225',
  '1235',
  '1245',
  '1255',
  '1265',
  '120',
  '121',
  '122',
  '123',
  '124',
  '125',
  '126',
  '7548',
  '7549',
  '7550',
  '7551',
  '7552',
  '7553',
  '7554',
  '7555',
  '7556',
  '7557',
  '7558',
  '7559',
  '7560',
  '7561',
  '134',
  '135',
  '136',
  '137',
  '138',
  '139',
  '140',
  '1345',
  '1355',
  '1365',
  '1375',
  '1385',
  '1395',
  '1405',
  '141',
  '142',
  '143',
  '144',
  '145',
  '146',
  '147',
  '7408',
  '7409',
  '7410',
  '7411',
  '7412',
  '7413',
  '7414',
  '7562',
  '7563',
  '7564',
  '7565',
  '7566',
  '7567',
  '7568',
  '7569',
  '7570',
  '7571',
  '7572',
  '7573',
  '7574',
  '7575',
  '712',
  '713',
  '714',
  '715',
  '716',
  '717',
  '718',
  '148',
  '149',
  '150',
  '151',
  '152',
  '153',
  '154',
  '155',
  '156',
  '157',
  '158',
  '159',
  '160',
  '161',
  '1485',
  '1495',
  '1505',
  '1525',
  '1535',
  '1545',
  '1555',
  '1565',
  '1575',
  '1585',
  '1595',
  '1605',
  '1615',
  '162',
  '163',
  '164',
  '165',
  '166',
  '167',
  '168',
  '7576',
  '7577',
  '7578',
  '7579',
  '7580',
  '7581',
  '7582',
  '1625',
  '1635',
  '1645',
  '1655',
  '1665',
  '1675',
  '1685',
  '169',
  '170',
  '171',
  '172',
  '173',
  '174',
  '175',
  '7583',
  '7584',
  '7585',
  '7586',
  '7587',
  '7588',
  '7589',
  '7590',
  '7591',
  '7592',
  '7593',
  '7594',
  '7595',
  '7596',
  '7597',
  '7598',
  '7599',
  '7600',
  '7601',
  '7602',
  '7603',
  '7604',
  '7605',
  '7606',
  '7607',
  '7608',
  '7609',
  '7610',
  '7611',
  '7612',
  '7613',
  '7614',
  '7615',
  '7616',
  '7617',
  '7520',
  '7521',
  '7522',
  '7523',
  '7524',
  '7525',
  '7526',
  '489',
  '488',
  '487',
  '486',
  '485',
  '484',
  '483',
  '176',
  '177',
  '178',
  '179',
  '180',
  '181',
  '1765',
  '1775',
  '1785',
  '1788',
  '1795',
  '1805',
  '1815',
  '1767',
  '1777',
  '1787',
  '1797',
  '1807',
  '1817',
  '7618',
  '7619',
  '7620',
  '7621',
  '7622',
  '7623',
  '7624',
  '7625',
  '7626',
  '7627',
  '7628',
  '7629',
  '7630',
  '7631',
  '7415',
  '7416',
  '7417',
  '7418',
  '7419',
  '7420',
  '7421',
  '182',
  '183',
  '184',
  '185',
  '186',
  '187',
  '188',
  '196',
  '197',
  '198',
  '199',
  '200',
  '201',
  '202',
  '189',
  '190',
  '191',
  '192',
  '193',
  '194',
  '195',
  '1895',
  '1905',
  '1915',
  '1925',
  '1935',
  '1945',
  '1955',
  '705',
  '706',
  '707',
  '708',
  '709',
  '710',
  '711',
  '698',
  '699',
  '700',
  '701',
  '702',
  '703',
  '704',
  '203',
  '204',
  '205',
  '206',
  '207',
  '208',
  '209',
  '210',
  '211',
  '212',
  '213',
  '214',
  '215',
  '216',
  '7422',
  '7423',
  '7424',
  '7425',
  '7426',
  '7427',
  '7428',
  '7632',
  '7633',
  '7634',
  '7635',
  '7636',
  '7637',
  '7638',
  '217',
  '218',
  '219',
  '220',
  '221',
  '222',
  '7639',
  '7640',
  '7641',
  '7642',
  '7643',
  '7644',
  '7645',
  '223',
  '224',
  '225',
  '226',
  '227',
  '228',
  '229',
  '230',
  '231',
  '232',
  '233',
  '234',
  '235',
  '670',
  '671',
  '672',
  '673',
  '674',
  '675',
  '676',
  '677',
  '678',
  '679',
  '680',
  '681',
  '682',
  '683',
  '684',
  '685',
  '686',
  '687',
  '688',
  '689',
  '690',
  '510',
  '509',
  '508',
  '507',
  '506',
  '505',
  '504',
  '7429',
  '7430',
  '7431',
  '7432',
  '7433',
  '7434',
  '7435',
  '691',
  '692',
  '693',
  '694',
  '695',
  '696',
  '697',
  '496',
  '495',
  '494',
  '493',
  '492',
  '491',
  '490',
  '503',
  '502',
  '501',
  '500',
  '499',
  '498',
  '497',
  '5035',
  '5025',
  '5015',
  '5005',
  '4995',
  '4985',
  '4975',
  '236',
  '237',
  '238',
  '239',
  '240',
  '241',
  '242',
  '2365',
  '2375',
  '2385',
  '2395',
  '2405',
  '2415',
  '2425',
  '243',
  '244',
  '245',
  '246',
  '247',
  '248',
  '249',
  '7646',
  '7647',
  '7648',
  '7649',
  '7650',
  '7651',
  '7652',
  '250',
  '251',
  '252',
  '253',
  '254',
  '255',
  '517',
  '516',
  '515',
  '514',
  '513',
  '512',
  '511',
  '7436',
  '7437',
  '7438',
  '7439',
  '7440',
  '7441',
  '7442',
  '2562',
  '2572',
  '2582',
  '2592',
  '2602',
  '2612',
  '2622',
  '7653',
  '7654',
  '7655',
  '7656',
  '7657',
  '7658',
  '7659',
  '524',
  '523',
  '522',
  '521',
  '520',
  '519',
  '518',
  '5245',
  '5235',
  '5225',
  '5215',
  '5205',
  '5195',
  '5185',
  '5175',
  '5165',
  '5155',
  '5145',
  '5135',
  '5125',
  '5115',
  '531',
  '530',
  '529',
  '528',
  '527',
  '526',
  '525',
  '256',
  '257',
  '258',
  '259',
  '260',
  '261',
  '262',
  '2563',
  '2573',
  '2583',
  '2593',
  '2603',
  '2613',
  '2623',
  '7660',
  '7661',
  '7662',
  '7663',
  '7664',
  '7665',
  '7666',
  '2567',
  '2577',
  '2587',
  '2597',
  '2607',
  '2617',
  '2627',
  '263',
  '264',
  '265',
  '266',
  '267',
  '268',
  '269',
  '2635',
  '2645',
  '2655',
  '2665',
  '2685',
  '2695',
  '270',
  '271',
  '272',
  '273',
  '274',
  '275',
  '276',
  '2705',
  '2715',
  '2725',
  '2735',
  '2745',
  '2755',
  '2765',
  '7667',
  '7668',
  '7669',
  '7670',
  '7671',
  '7672',
  '7673',
  '7443',
  '7444',
  '7445',
  '7446',
  '7447',
  '7448',
  '7449',
  '7674',
  '7675',
  '7676',
  '7677',
  '7678',
  '7679',
  '7680',
  '663',
  '664',
  '665',
  '666',
  '667',
  '668',
  '669',
  '5315',
  '5305',
  '5295',
  '5285',
  '5275',
  '5265',
  '5255',
  '538',
  '537',
  '536',
  '535',
  '534',
  '533',
  '532',
  '7541',
  '7542',
  '7543',
  '7544',
  '7545',
  '7546',
  '7547',
  '552',
  '551',
  '550',
  '549',
  '548',
  '547',
  '546',
  '5455',
  '5445',
  '5435',
  '5425',
  '5415',
  '5405',
  '5395',
  '642',
  '643',
  '644',
  '645',
  '646',
  '647',
  '648',
  '649',
  '650',
  '651',
  '652',
  '653',
  '654',
  '655',
  '656',
  '657',
  '658',
  '659',
  '660',
  '661',
  '662',
  '7450',
  '7451',
  '7452',
  '7453',
  '7454',
  '7455',
  '7456',
  '2706',
  '2716',
  '2726',
  '2736',
  '2746',
  '2756',
  '2766',
  '2708',
  '2718',
  '2728',
  '2738',
  '2748',
  '2758',
  '2768',
  '2707',
  '2717',
  '2727',
  '2747',
  '2757',
  '2767',
  '277',
  '278',
  '279',
  '280',
  '281',
  '282',
  '283',
  '284',
  '285',
  '286',
  '287',
  '288',
  '289',
  '7681',
  '7682',
  '7683',
  '7684',
  '7685',
  '7686',
  '7687',
  '545',
  '544',
  '543',
  '542',
  '541',
  '540',
  '539',
  '290',
  '291',
  '292',
  '293',
  '294',
  '295',
  '296',
  '2905',
  '2915',
  '2925',
  '2935',
  '2945',
  '2955',
  '2965',
  '297',
  '298',
  '299',
  '300',
  '301',
  '302',
  '303',
  '7688',
  '7689',
  '7690',
  '7691',
  '7692',
  '7693',
  '7694',
  '2975',
  '2985',
  '2995',
  '3005',
  '3015',
  '3025',
  '3035',
  '7695',
  '7696',
  '7697',
  '7698',
  '7699',
  '7700',
  '7701',
  '7457',
  '7458',
  '7459',
  '7460',
  '7461',
  '7462',
  '7463',
  '304',
  '305',
  '306',
  '307',
  '308',
  '309',
  '635',
  '636',
  '637',
  '638',
  '639',
  '640',
  '641',
  '7702',
  '7703',
  '7704',
  '7705',
  '7706',
  '7707',
  '7708',
  '628',
  '629',
  '630',
  '631',
  '632',
  '633',
  '634',
  '310',
  '311',
  '312',
  '313',
  '314',
  '315',
  '316',
  '3105',
  '3115',
  '3125',
  '3135',
  '3145',
  '3155',
  '3165',
  '7709',
  '7710',
  '7711',
  '7712',
  '7713',
  '7714',
  '7715',
  '317',
  '318',
  '319',
  '320',
  '321',
  '322',
  '323',
  '7464',
  '7465',
  '7466',
  '7467',
  '7468',
  '7469',
  '7470',
  '7471',
  '7472',
  '7473',
  '7474',
  '7475',
  '7476',
  '7477',
  '5523',
  '5513',
  '5503',
  '5493',
  '5483',
  '5473',
  '5463',
  '7716',
  '7717',
  '7718',
  '7719',
  '7720',
  '7721',
  '7722',
  '324',
  '325',
  '326',
  '327',
  '328',
  '329',
  '330',
  '3242',
  '3252',
  '3262',
  '3272',
  '3282',
  '3292',
  '3302',
  '3245',
  '3255',
  '3265',
  '3275',
  '3285',
  '3295',
  '3305',
  '3248',
  '3258',
  '3268',
  '3278',
  '3288',
  '3298',
  '3308',
  '566',
  '565',
  '564',
  '563',
  '562',
  '561',
  '560',
  '573',
  '572',
  '571',
  '570',
  '569',
  '568',
  '567',
  '559',
  '558',
  '557',
  '556',
  '555',
  '554',
  '553',
  '5595',
  '5585',
  '5575',
  '5565',
  '5555',
  '5545',
  '5535',
  '5665',
  '5655',
  '5645',
  '5635',
  '5625',
  '5615',
  '5605',
  '5527',
  '5517',
  '5507',
  '5497',
  '5487',
  '5477',
  '5467',
  '621',
  '622',
  '623',
  '624',
  '625',
  '626',
  '627',
  '331',
  '332',
  '333',
  '334',
  '335',
  '336',
  '337',
  '338',
  '339',
  '340',
  '341',
  '342',
  '343',
  '7723',
  '7724',
  '7725',
  '7726',
  '7727',
  '7728',
  '7729',
  '3375',
  '3385',
  '3395',
  '3405',
  '3415',
  '3425',
  '3435',
  '344',
  '345',
  '346',
  '347',
  '348',
  '349',
  '350',
  '351',
  '352',
  '353',
  '354',
  '355',
  '356',
  '357',
  '7478',
  '7479',
  '7480',
  '7481',
  '7482',
  '7483',
  '7484',
  '7730',
  '7731',
  '7732',
  '7733',
  '7734',
  '7735',
  '7736',
  '7737',
  '7738',
  '7739',
  '7740',
  '7741',
  '7742',
  '7743',
  '358',
  '359',
  '360',
  '361',
  '362',
  '363',
  '364',
  '7485',
  '7486',
  '7487',
  '7488',
  '7489',
  '7490',
  '7491',
  '365',
  '366',
  '367',
  '368',
  '369',
  '370',
  '371',
  '372',
  '373',
  '374',
  '375',
  '376',
  '377',
  '378',
  '580',
  '579',
  '578',
  '577',
  '576',
  '575',
  '574',
  '5807',
  '5797',
  '5787',
  '5777',
  '5767',
  '5757',
  '5747',
  '5875',
  '5865',
  '5855',
  '5845',
  '5835',
  '5825',
  '5815',
  '5803',
  '5793',
  '5783',
  '5773',
  '5763',
  '5753',
  '5743',
  '7492',
  '7493',
  '7494',
  '7495',
  '7496',
  '7497',
  '7498',
  '7744',
  '7745',
  '7746',
  '7747',
  '7748',
  '7749',
  '7750',
  '379',
  '380',
  '381',
  '382',
  '383',
  '384',
  '385',
  '386',
  '387',
  '388',
  '389',
  '390',
  '391',
  '392',
  '587',
  '586',
  '585',
  '584',
  '583',
  '582',
  '581',
  '393',
  '394',
  '395',
  '396',
  '397',
  '398',
  '399',
  '3935',
  '3945',
  '3955',
  '3965',
  '3975',
  '3985',
  '3995',
  '600',
  '601',
  '602',
  '603',
  '604',
  '605',
  '606',
  '607',
  '608',
  '609',
  '610',
  '611',
  '612',
  '613',
  '461',
  '460',
  '459',
  '458',
  '457',
  '456',
  '455',
  '614',
  '615',
  '616',
  '617',
  '618',
  '619',
  '620',
  '7751',
  '7752',
  '7753',
  '7754',
  '7755',
  '7756',
  '7757',
  '7758',
  '7759',
  '7760',
  '7761',
  '7762',
  '7763',
  '7764',
  '7765',
  '7766',
  '7767',
  '7768',
  '7769',
  '7770',
  '7771',
  '4545',
  '4535',
  '4525',
  '4515',
  '4505',
  '4495',
  '4485',
  '454',
  '453',
  '452',
  '451',
  '450',
  '449',
  '448',
  '7499',
  '7500',
  '7501',
  '7502',
  '7503',
  '7504',
  '7505',
  '468',
  '467',
  '466',
  '465',
  '464',
  '463',
  '462',
  '7506',
  '7507',
  '7508',
  '7509',
  '7510',
  '7511',
  '7512',
  '719',
  '720',
  '721',
  '722',
  '723',
  '724',
  '725',
  '475',
  '474',
  '473',
  '472',
  '471',
  '470',
  '469',
  '726',
  '727',
  '728',
  '729',
  '730',
  '731',
  '732',
  '4685',
  '4675',
  '4665',
  '4655',
  '4645',
  '4635',
  '4625',
  '7513',
  '7514',
  '7515',
  '7516',
  '7517',
  '7518',
  '7519',
  '4755',
  '4745',
  '4735',
  '4725',
  '4715',
  '4705',
  '4695',
  '482',
  '481',
  '480',
  '479',
  '478',
  '477',
  '476',
  '7527',
  '7528',
  '7529',
  '7530',
  '7531',
  '7532',
  '7533',
  '7534',
  '7535',
  '7536',
  '7537',
  '7538',
  '7539',
  '7540',
  '427',
  '428',
  '429',
  '430',
  '431',
  '432',
  '433',
  '420',
  '421',
  '422',
  '423',
  '424',
  '425',
  '426',
  '441',
  '442',
  '443',
  '444',
  '445',
  '446',
  '447',
  '413',
  '414',
  '415',
  '416',
  '417',
  '418',
  '419',
  '400',
  '401',
  '402',
  '403',
  '404',
  '405',
  '406',
  '407',
  '408',
  '409',
  '410',
  '411',
  '412',
  '434',
  '435',
  '436',
  '437',
  '438',
  '439',
  '440',
  '4210',
  'warm gray 1',
  'warm gray 2',
  'warm gray 3',
  'warm gray 4',
  'warm gray 5',
  'warm gray 6',
  'warm gray 7',
  'warm gray 8',
  'warm gray 9',
  'warm gray 10',
  'warm gray 11',
  'cool gray 1',
  'cool gray 2',
  'cool gray 3',
  'cool gray 4',
  'cool gray 5',
  'cool gray 6',
  'cool gray 7',
  'cool gray 8',
  'cool gray 9',
  'cool gray 10',
  'cool gray 11',
  'black 2',
  'black 3',
  'black 4',
  'black 5',
  'black 6',
  'black 7',
];
const aRGB = [
  'FFFFFF',
  '000000',
  'FEDD00',
  'FFD700',
  'FE5000',
  'F9423A',
  'EF3340',
  'CE0058',
  'E10098',
  'BB29BB',
  '440099',
  '10069F',
  '001489',
  '0085CA',
  '00AB84',
  '2D2926',
  'F2F0A1',
  'FCAEBB',
  'F1B2DC',
  'BF9BDE',
  '74D1EA',
  '9DE7D7',
  '9E978E',
  '009ACE',
  '44D62C',
  'FFE900',
  'FFAA4D',
  'FF7276',
  'FF3EB5',
  'EA27C2',
  '84754E',
  '85714D',
  '866D4B',
  '8B6F4E',
  '87674F',
  '8B634B',
  '8A8D8F',
  'FFD900',
  'FF5E00',
  'F93822',
  'CE0056',
  'D62598',
  '4E008E',
  '00239C',
  '0084CA',
  '00B08B',
  '222223',
  'F6EB61',
  'F7EA48',
  'FCE300',
  'C5A900',
  'AF9800',
  '897A27',
  'F5E1A4',
  'ECD898',
  'EED484',
  'F4DA40',
  'F2CD00',
  'F1C400',
  'CBA052',
  'F9E547',
  'FBE122',
  'FEDB00',
  'FFD100',
  'DAAA00',
  'AA8A00',
  '9C8412',
  'FAE053',
  'FBDD40',
  'FDDA24',
  'FFCD00',
  'C99700',
  'AC8400',
  '897322',
  'F3DD6D',
  'F3D54E',
  'F3D03E',
  'F2A900',
  'CC8A00',
  'A07400',
  '6C571B',
  'F8E08E',
  'FBD872',
  'FFC845',
  'FFB81C',
  'C69214',
  'AD841F',
  '886B25',
  'FBDB65',
  'FDD757',
  'FED141',
  'FFC72C',
  'EAAA00',
  'B58500',
  '9A7611',
  'FFC600',
  'FFB500',
  'D19000',
  'B47E00',
  '73531D',
  '5A4522',
  '4B3D2A',
  'D29F13',
  'B78B20',
  '9F7D23',
  '967126',
  '8F6A2A',
  '7D622E',
  '6C5D34',
  'FDD26E',
  'FFC658',
  'FFBF3F',
  'FFA300',
  'DE7C00',
  'AF6D04',
  '74531C',
  'FDD086',
  'FFC56E',
  'FFB549',
  'FF9E1B',
  'D57800',
  '996017',
  '6E4C1E',
  'F2C75C',
  'F1BE48',
  'F1B434',
  'ED8B00',
  'CF7F00',
  'A76D11',
  '715C2A',
  'F6BE00',
  'F0B323',
  'FEAD77',
  'E6A65D',
  'D38235',
  'DC8633',
  'C16C18',
  'BD9B60',
  'D69A2D',
  'DB8A06',
  'CD7925',
  'AD6433',
  '89532F',
  '775135',
  'D78825',
  'D3832B',
  'C67D30',
  'B67233',
  'A7662B',
  '9E6A38',
  '835D32',
  'FCC89B',
  'FDBE87',
  'FDAA63',
  'F68D2E',
  'EA7600',
  'D45D00',
  'BE4D00',
  'FECB8B',
  'FFC27B',
  'FFB25B',
  'FF8200',
  'E57200',
  'BE6A14',
  '9B5A1A',
  'EFD19F',
  'EFBE7D',
  'ECA154',
  'E87722',
  'CB6015',
  'A1561C',
  '603D20',
  'FFAE62',
  'FF8F1C',
  'FF6900',
  'B94700',
  '94450B',
  '653819',
  'FFB990',
  'FFA06A',
  'FF7F32',
  'FF6A13',
  'D86018',
  'A65523',
  '8B4720',
  'FFBE9F',
  'FF9D6E',
  'FF7F41',
  'FF671F',
  'E35205',
  'BE531C',
  '73381D',
  'DB864E',
  'E07E3C',
  'DC6B2F',
  'DC582A',
  'C05131',
  '864A33',
  '674736',
  'FFA38B',
  'FF8D6D',
  'FF6A39',
  'FC4C02',
  'DC4405',
  'A9431E',
  '833921',
  'FFB3AB',
  'FF8674',
  'FF5C39',
  'FA4616',
  'CF4520',
  '963821',
  '6B3529',
  'C4622D',
  'BA5826',
  'AF5C37',
  '9E5330',
  '924C2E',
  '7B4D35',
  '5C4738',
  'D4B59E',
  'C07D59',
  'B15533',
  '9D432C',
  '7C3A2D',
  '6B3D2E',
  '5C3D31',
  'D14124',
  'BD472A',
  'B33D26',
  '8D3F2B',
  '83412C',
  '7B4931',
  '674230',
  'E4D5D3',
  'E1BBB4',
  'D6938A',
  'C26E60',
  'A4493D',
  '823B34',
  '683431',
  'DDBCB0',
  'CA9A8E',
  'BC8A7E',
  'A37F74',
  '866761',
  '6B4C4C',
  '583D3E',
  'EABEB0',
  'C09C83',
  'B46A55',
  'AB5C57',
  'A45248',
  '9A6A4F',
  '8A391B',
  'ECC3B2',
  'ECBAA8',
  'EAA794',
  'E8927C',
  'DA291C',
  '9A3324',
  '653024',
  'FFB1BB',
  'FF808B',
  'FF585D',
  'E03C31',
  'BE3A34',
  '81312F',
  'FFA3B5',
  'FF8DA1',
  'F8485E',
  'EE2737',
  'D22630',
  'AF272F',
  '7C2529',
  'FCAFC0',
  'FB637E',
  'F4364C',
  'CB333B',
  'A4343A',
  '643335',
  'C66E4E',
  'C04C36',
  'B7312C',
  'AB2328',
  '93272C',
  '8A2A2B',
  '802F2D',
  'E1523D',
  'C63527',
  'A72B2A',
  '9E2A2B',
  '6D3332',
  '633231',
  '572D2D',
  'E6BAA8',
  'E56A54',
  'E04E39',
  'CD545B',
  'B04A5A',
  '9B2242',
  '651D32',
  'FABBCB',
  'FC9BB3',
  'F65275',
  'E4002B',
  'C8102E',
  'A6192E',
  '76232F',
  'ECC7CD',
  'E89CAE',
  'DF4661',
  'D50032',
  'BA0C2F',
  '9D2235',
  '862633',
  'F8A3BC',
  'F67599',
  'EF426F',
  'E40046',
  'BF0D3E',
  '9B2743',
  '782F40',
  'F5B6CD',
  'F59BBB',
  'EF4A81',
  'E0004D',
  'C5003E',
  'A6093D',
  '8A1538',
  'F5DADF',
  'F7CED7',
  'F9B5C4',
  'F890A5',
  'EF6079',
  'E03E52',
  'CB2C30',
  'F2D4D7',
  'F4C3CC',
  'F2ACB9',
  'E68699',
  'D25B73',
  'B83A4B',
  '9E2A2F',
  'ECB3CB',
  'E782A9',
  'E0457B',
  'CE0037',
  'A50034',
  '861F41',
  '6F263D',
  'F99FC9',
  'F57EB6',
  'F04E98',
  'E31C79',
  'CE0F69',
  'AC145A',
  '7D2248',
  'F4CDD4',
  'E06287',
  'E24585',
  'B52555',
  'A4123F',
  '971B2F',
  '6A2C3E',
  'D6C9CA',
  'C4A4A7',
  'C16784',
  'C63663',
  'BC204B',
  '912F46',
  '7E2D40',
  'EABEDB',
  'E56DB1',
  'DA1884',
  'A50050',
  '910048',
  '6C1D45',
  '936D73',
  '934054',
  '8E2C48',
  '732E4A',
  '672E45',
  '582D40',
  '502B3A',
  'EF95CF',
  'EB6FBD',
  'DF1995',
  'D0006F',
  'AA0061',
  '890C58',
  '672146',
  'F4A6D7',
  'F277C6',
  'E93CAC',
  'C6007E',
  'A20067',
  '840B55',
  'EAD3E2',
  'E6BCD8',
  'DFA0C9',
  'D986BA',
  'C6579A',
  'AE2573',
  '960051',
  'E5CEDB',
  'E3C8D8',
  'DEBED2',
  'C996B6',
  'B06C96',
  '994878',
  '7C2855',
  'E4C6D4',
  'DCB6C9',
  'D0A1BA',
  'BE84A3',
  'A76389',
  '893B67',
  '612141',
  'EBBECB',
  'E8B3C3',
  'E4A9BB',
  'D592AA',
  '84344E',
  '6F2C3F',
  '572932',
  'E2BCCB',
  'DCA9BF',
  'C9809E',
  'B55C80',
  'A73A64',
  '9B3259',
  '872651',
  'E9CDD0',
  'E4BEC3',
  'D7A3AB',
  'C48490',
  'B46B7A',
  '984856',
  '893C47',
  'F2C6CF',
  'F1BDC8',
  'E9A2B2',
  'DC8699',
  '8F3237',
  '7F3035',
  '5D2A2C',
  'E9C4C7',
  'E5BAC1',
  'DAA5AD',
  'C6858F',
  '7A3E3A',
  '6A3735',
  '512F2E',
  'DFC2C3',
  'DBB7BB',
  'CCA1A6',
  'B07C83',
  '9C6169',
  '874B52',
  '3F2021',
  'F1A7DC',
  'EC86D0',
  'E45DBF',
  'DB3EB1',
  'C5299B',
  'AF1685',
  '80225F',
  'EFBAE1',
  'E277CD',
  'D539B5',
  'C800A1',
  'B0008E',
  '9E007E',
  '830065',
  'EAB8E4',
  'E59BDC',
  'DD7FD3',
  'C724B1',
  'BB16A3',
  'A51890',
  '80276C',
  'A56E87',
  'A83D72',
  '991E66',
  '8A1B61',
  '722257',
  '6A2A5B',
  '5E2751',
  'E7BAE4',
  'DD9CDF',
  'C964CF',
  'AD1AAC',
  '981D97',
  '72246C',
  'EBC6DF',
  'E6BEDD',
  'E2ACD7',
  'D48BC8',
  '93328E',
  '833177',
  '612C51',
  'EEDAEA',
  'CCAED0',
  'D59ED7',
  'B288B9',
  'A277A6',
  '9F5CC0',
  '963CBD',
  'D7A9E3',
  'C98BDB',
  'AC4FC6',
  '9B26B6',
  '87189D',
  '772583',
  '653165',
  '948794',
  'A2789C',
  'A15A95',
  '8E3A80',
  '6E2B62',
  '6A3460',
  '5D3754',
  'D5C2D8',
  'C9B1D0',
  'BA9CC5',
  'A57FB2',
  '642F6C',
  '59315F',
  '4B3048',
  'DBCDD3',
  'D0BEC7',
  'C6B0BC',
  'AF95A6',
  '86647A',
  '66435A',
  '4A3041',
  'D8C8D1',
  'D3C0CD',
  'BFA5B8',
  '9B7793',
  '7E5475',
  '693C5E',
  '512A44',
  'DFC8E7',
  'D7B9E4',
  'CAA2DD',
  'B580D1',
  '8031A7',
  '702F8A',
  '572C5F',
  'D6BFDD',
  'C6A1CF',
  '8C4799',
  '6D2077',
  '642667',
  '5D285F',
  '51284F',
  'CBA3D8',
  'B884CB',
  'A05EB5',
  '84329B',
  '702082',
  '671E75',
  '5F2167',
  '9991A4',
  '8D6E97',
  '7A4183',
  '6B3077',
  '653279',
  '5E366E',
  '5C4E63',
  'C1A0DA',
  'A77BCA',
  '8246AF',
  '5C068C',
  '500778',
  '470A68',
  '3C1053',
  'D7C6E6',
  'C1A7E2',
  '9063CD',
  '753BBD',
  '5F259F',
  '582C83',
  '512D6D',
  'C5B4E3',
  'AD96DC',
  '9678D3',
  '7D55C7',
  '330072',
  '2E1A47',
  'B4B5DF',
  '9595D2',
  '7474C1',
  '24135F',
  '211551',
  '201747',
  '221C35',
  'A7A4E0',
  '8B84D7',
  '685BC7',
  '2E008B',
  '280071',
  '250E62',
  '201547',
  '6E7CA0',
  '686E9F',
  '615E9B',
  '565294',
  '514689',
  '4C4184',
  '535486',
  'DDDAE8',
  'B6B8DC',
  'A7A2C3',
  '8986CA',
  '5D4777',
  '4B384C',
  '41273B',
  '878CB4',
  '7C7FAB',
  '7566A0',
  '6F5091',
  '68478D',
  '563D82',
  '523178',
  'E5E1E6',
  'E0DBE3',
  'C6BCD0',
  'A192B2',
  '7C6992',
  '614B79',
  '3F2A56',
  'D8D7DF',
  'C6C4D2',
  'B3B0C4',
  '8D89A5',
  '595478',
  '403A60',
  '1E1A34',
  'C5CFDA',
  'BBC7D6',
  'A2B2C8',
  '8E9FBC',
  '1B365D',
  '1F2A44',
  '1C1F2A',
  'D9E1E2',
  'A4BCC2',
  '98A4AE',
  '768692',
  '425563',
  '253746',
  '131E29',
  'B9D3DC',
  'A3C7D2',
  '8DB9CA',
  '6BA4B8',
  '003D4C',
  '00313C',
  '072B31',
  'BFCED6',
  'B7C9D3',
  'A6BBC8',
  '7A99AC',
  '5B7F95',
  '4F758B',
  '081F2C',
  'D1DDE6',
  'C6D6E3',
  '9BB8D3',
  '7DA1C4',
  '5E8AB4',
  '236192',
  '002E5D',
  'DBE2E9',
  'CED9E5',
  'A7BCD6',
  '7D9BC1',
  '326295',
  '003A70',
  '002554',
  'DDE5ED',
  'C8D8EB',
  'B1C9E8',
  '7BA4DB',
  '407EC9',
  '003594',
  '001A70',
  'BDC5DB',
  '89ABE3',
  '8094DD',
  '7BA6DE',
  '5F8FB4',
  '3A5DAE',
  '606EB2',
  'CBD3EB',
  '9FAEE5',
  '485CC7',
  '1E22AA',
  '171C8F',
  '151F6D',
  '141B4D',
  'B8CCEA',
  '5C88DA',
  '0047BB',
  '06038D',
  '001871',
  '001E62',
  '071D49',
  'C3D7EE',
  'A7C6ED',
  '307FE2',
  '001A72',
  '001E60',
  '13294B',
  'ABCAE9',
  '8BB8E8',
  '418FDE',
  '012169',
  '00205B',
  '041E42',
  '92C1E9',
  '6CACE4',
  '0072CE',
  '0033A0',
  '003087',
  '002D72',
  '0C2340',
  '94A9CB',
  '6787B7',
  '426DA9',
  '385E9D',
  '2C5697',
  '1D4F91',
  '1D428A',
  'C6DAE7',
  'BDD6E6',
  'A4C8E1',
  '7BAFD4',
  '003C71',
  '003057',
  '00263A',
  'B9D9EB',
  '9BCBEB',
  '69B3E7',
  '003DA5',
  '002F6C',
  '002855',
  '041C2C',
  '8DC8E8',
  '62B5E5',
  '009CDE',
  '0057B8',
  '004C97',
  '003865',
  '00263E',
  '71C5E8',
  '41B6E6',
  '00A3E0',
  '005EB8',
  '004B87',
  '003B5C',
  '002A3A',
  '4698CB',
  '298FC2',
  '0076A8',
  '006298',
  '005587',
  '004976',
  '01426A',
  '99D6EA',
  '5BC2E7',
  '00A9E0',
  '0077C8',
  '00629B',
  '004F71',
  '003E51',
  '7BA7BC',
  '6399AE',
  '4E87A0',
  '41748D',
  '34657F',
  '165C7D',
  '005776',
  'BBDDE6',
  '71B2C9',
  '4298B5',
  '0086BF',
  '007DBA',
  '00558C',
  '002B49',
  '9ADBE8',
  '59CBE8',
  '00B5E2',
  '006BA6',
  '00587C',
  '003B49',
  'A4DBE8',
  '8BD3E6',
  '4EC3E0',
  '00AFD7',
  '0095C8',
  '0082BA',
  '0067A0',
  '48A9C5',
  '009CBD',
  '0085AD',
  '007096',
  '006A8E',
  '00617F',
  '005670',
  'B8DDE1',
  '9BD3DD',
  '77C5D5',
  '3EB1C8',
  '0093B2',
  '007396',
  '005F83',
  '6AD1E3',
  '05C3DE',
  '00A9CE',
  '0092BC',
  '007FA3',
  '00677F',
  '004851',
  '68D2DF',
  '00C1D5',
  '00AEC7',
  '008EAA',
  '00778B',
  '006272',
  '004F59',
  '63B1BC',
  '00A7B5',
  '0097A9',
  '00859B',
  '007D8A',
  '007680',
  '006269',
  'B1E4E3',
  '88DBDF',
  '2DCCD3',
  '009CA6',
  '008C95',
  '007377',
  '005F61',
  'A0D1CA',
  '40C1AC',
  '00B0B9',
  '00A3AD',
  '007398',
  '005F86',
  '005A70',
  '7EDDD3',
  '5CB8B2',
  '279989',
  '007681',
  '487A7B',
  '0D5257',
  '244C5A',
  'B6CFD0',
  'ABC7CA',
  '94B7BB',
  '7FA9AE',
  '4F868E',
  '115E67',
  '07272D',
  '00968F',
  '00857D',
  '007672',
  '006D68',
  '00635B',
  '005E5D',
  '005151',
  '9CDBD9',
  '64CCC9',
  '00B2A9',
  '008675',
  '007367',
  '00685E',
  '00534C',
  '71DBD4',
  '2AD2C9',
  '00BFB3',
  '00A499',
  '008578',
  '00594F',
  '004C45',
  '7CE0D3',
  '2CD5C4',
  '00C7B1',
  '00B398',
  '009681',
  '007864',
  '004E42',
  '6DCDB8',
  '49C5B1',
  '00AB8E',
  '009B77',
  '008264',
  '006A52',
  '034638',
  'B9DCD2',
  'A1D6CA',
  '86C8BC',
  '6BBBAE',
  '006F62',
  '00594C',
  '1D3C34',
  'B5E3D8',
  'A5DFD3',
  '98DBCE',
  '6BCABA',
  '00816D',
  '006C5B',
  '173F35',
  'ADCAB8',
  '9ABEAA',
  '85B09A',
  '6FA287',
  '28724F',
  '205C40',
  '284734',
  'BFCEC2',
  'A7BDB1',
  '92ACA0',
  '7F9C90',
  '5C7F71',
  '43695B',
  '183028',
  'BAC5B9',
  'B0BDB0',
  'A3B2A4',
  '94A596',
  '708573',
  '5E7461',
  '22372B',
  'BCC9C5',
  'B1C0BC',
  '9DB0AC',
  '829995',
  '5D7975',
  '3E5D58',
  '18332F',
  'D1E0D7',
  'B7CDC2',
  '9AB9AD',
  '789F90',
  '507F70',
  '285C4D',
  '13322B',
  'A7E6D7',
  '8CE2D0',
  '3CDBC0',
  '009775',
  '007B5F',
  '00664F',
  '8FD6BD',
  '6ECEB2',
  '00B388',
  '00965E',
  '007A53',
  '006747',
  '115740',
  '50A684',
  '00966C',
  '008755',
  '007B4B',
  '006F44',
  '006845',
  '005844',
  '7AE1BF',
  '47D7AC',
  '00C389',
  '00AF66',
  '007749',
  '006341',
  '154734',
  'A0DAB3',
  '91D6AC',
  '71CC98',
  '009A44',
  '00843D',
  '046A38',
  '2C5234',
  'A2E4B8',
  '8FE2B0',
  '80E0A7',
  '00B140',
  '009639',
  '007A33',
  '215732',
  '9BE3BF',
  '26D07C',
  '00BF6F',
  '00B74F',
  '009F4D',
  '275D38',
  '00573F',
  '4B9560',
  '228848',
  '007A3E',
  '007041',
  '286140',
  '36573B',
  '395542',
  '6BA539',
  '48A23F',
  '319B42',
  '3A913F',
  '44883E',
  '4A773C',
  '44693D',
  'ADDC91',
  'A1D884',
  '6CC24A',
  '43B02A',
  '509E2F',
  '4C8C2B',
  '4A7729',
  'D0DEBB',
  'BCE194',
  '8EDD65',
  '78D64B',
  '74AA50',
  '719949',
  '79863C',
  'C2E189',
  'B7DD79',
  'A4D65E',
  '78BE20',
  '64A70B',
  '658D1B',
  '546223',
  'D4EB8E',
  'CDEA80',
  'C5E86C',
  '97D700',
  '84BD00',
  '7A9A01',
  '59621D',
  'C4D6A4',
  'BCD19B',
  'B7CE95',
  'A9C47F',
  '789D4A',
  '67823A',
  '4E5B31',
  'D0D1AB',
  'C6C89B',
  'BABD8B',
  'A2A569',
  '8A8D4A',
  '6D712E',
  '3D441E',
  'D2CE9E',
  'CBC793',
  'C0BB87',
  'AFA96E',
  'A09958',
  '89813D',
  '555025',
  'C3C6A8',
  'B3B995',
  'A3AA83',
  '899064',
  '737B4C',
  '5E6738',
  '3E4827',
  'BFCC80',
  'BBC592',
  '9CAF88',
  '8F993E',
  '76881D',
  '7A7256',
  '5B6236',
  'BABC16',
  'ABAD23',
  '999B30',
  '888D30',
  '7C8034',
  '727337',
  '656635',
  'E2E868',
  'DBE442',
  'CEDC00',
  'C4D600',
  'A8AD00',
  '949300',
  '787121',
  'E9EC6B',
  'E3E935',
  'E0E721',
  'D0DF00',
  'B5BD00',
  '9A9500',
  '827A04',
  'E3E48D',
  'E0E27C',
  'DBDE70',
  'D2D755',
  'B7BF10',
  '8E8C13',
  '625D20',
  'F0EC74',
  'EDE939',
  'ECE81A',
  'E1E000',
  'BFB800',
  'ADA400',
  'A09200',
  'F3EA5D',
  'F3E500',
  'EFDF00',
  'EEDC00',
  'BBA600',
  '9A8700',
  '685C20',
  'F1EB9C',
  'F0E991',
  'F0E87B',
  'EDE04B',
  'EADA24',
  'E1CD00',
  'CFB500',
  'EBE49A',
  'E9E186',
  'E6DE77',
  'E1D555',
  'D7C826',
  'C4B000',
  'B39B00',
  'E9DF97',
  'E4D77E',
  'DECD63',
  'D9C756',
  'B89D18',
  'A28E2A',
  '695B24',
  'DCD59A',
  'D6CF8D',
  'D0C883',
  'C0B561',
  'AC9F3C',
  '9F912A',
  '8A7B19',
  'CAB64B',
  'CFB023',
  'C1A01E',
  'A08629',
  '897630',
  '736635',
  '675E33',
  'D4C304',
  'C4B200',
  '91852C',
  '747136',
  '5D6439',
  '585C3B',
  '535435',
  'BBB323',
  'B4A91F',
  'AA9D2E',
  '8F7E35',
  '716135',
  '635939',
  '4E4934',
  'D5CB9F',
  'CFC493',
  'C5B783',
  'B3A369',
  '998542',
  '8C7732',
  '614F25',
  'CAC7A7',
  'BFBB98',
  'B0AA7E',
  '9B945F',
  '594A25',
  '524727',
  '4A412A',
  'F1E6B2',
  'DFD1A7',
  'D9C89E',
  'CEB888',
  'A89968',
  '94795D',
  '816040',
  'DDCBA4',
  'D3BC8D',
  'C6AA76',
  'B9975B',
  '8B5B29',
  '744F28',
  '5C462B',
  'EFDBB2',
  'FCD299',
  'E1B87F',
  'D6A461',
  'C6893F',
  'B77729',
  'A6631B',
  'EDC8A3',
  'E7B78A',
  'DDA46F',
  'C88242',
  'B36924',
  '934D11',
  '7D3F16',
  'F3CFB3',
  'F1C6A7',
  'F0BF9B',
  'E59E6D',
  'B86125',
  'A45A2A',
  '693F23',
  'E0C09F',
  'D9B48F',
  'CDA077',
  'B58150',
  '9E652E',
  '774212',
  '623412',
  'E0C6AD',
  'DCBFA6',
  'CDA788',
  'BF9474',
  'AD7C59',
  '946037',
  '4F2C1D',
  'E1B7A7',
  'D5A286',
  'C58B68',
  '99552B',
  '85431E',
  '6D4F47',
  '5E4B3C',
  'D7C4B7',
  'CDB5A7',
  'C0A392',
  'AE8A79',
  '956C58',
  '7C4D3A',
  '5B3427',
  'DBC8B6',
  'D3BBA8',
  'C6A992',
  'AA8066',
  '703F2A',
  '623B2A',
  '4E3629',
  'D6D2C4',
  'C5B9AC',
  'B7A99A',
  'A39382',
  '7A6855',
  '63513D',
  '473729',
  'D1CCBD',
  'B7B09C',
  'A69F88',
  'A7ACA2',
  '949A90',
  '8E9089',
  '4B4F54',
  'D0D3D4',
  'C1C6C8',
  'A2AAAD',
  '7C878E',
  '5B6770',
  '333F48',
  '1D252D',
  'C7C9C7',
  'B2B4B2',
  '9EA2A2',
  '898D8D',
  '707372',
  '54585A',
  '25282A',
  'BEC6C4',
  'A2ACAB',
  '919D9D',
  '717C7D',
  '505759',
  '3F4444',
  '373A36',
  'BABBB1',
  'A8A99E',
  '919388',
  '7E7F74',
  '65665C',
  '51534A',
  '212322',
  'C4BFB6',
  'AFA9A0',
  '9D968D',
  '8C857B',
  '776E64',
  '696158',
  'C4BCB7',
  'B2A8A2',
  '978C87',
  '857874',
  '746661',
  '5E514D',
  '382F2D',
  'D0C4C5',
  'C1B2B6',
  'AB989D',
  '7B6469',
  '584446',
  '453536',
  '382E2C',
  'D7D2CB',
  'CBC4BC',
  'BFB8AF',
  'B6ADA5',
  'ACA39A',
  'A59C94',
  '968C83',
  '8C8279',
  '83786F',
  '796E65',
  '6E6259',
  'D9D9D6',
  'D0D0CE',
  'C8C9C7',
  'BBBCBC',
  'B1B3B3',
  'A7A8AA',
  '97999B',
  '888B8D',
  '75787B',
  '63666A',
  '53565A',
  '332F21',
  '212721',
  '31261D',
  '3E2B2E',
  '101820',
  '3D3935',
];

// CF - Color family
const BLACK_CF = 'black';
const GRAY_CF = 'gray';
const SILVER_CF = 'silver';

const BROWN_CF = 'brown';

const BLUE_CF = 'blue';

const GREEN_CF = 'green';

const YELLOW_CF = 'yellow';
const ORANGE_CF = 'orange';
const RED_CF = 'red';

const PINK_CF = 'pink';
const PURPLE_CF = 'purple';

const WHITE_CF = 'white';

const NO_FAMILY_CF = 'no-family';

const colorFamilies = [
  BLACK_CF,
  GRAY_CF,
  SILVER_CF,
  BROWN_CF,
  BLUE_CF,
  GREEN_CF,
  YELLOW_CF,
  ORANGE_CF,
  RED_CF,
  PINK_CF,
  PURPLE_CF,
  WHITE_CF,
];

export {
  aPMS,
  aRGB,
  BLACK_CF,
  GRAY_CF,
  SILVER_CF,
  BROWN_CF,
  BLUE_CF,
  GREEN_CF,
  YELLOW_CF,
  ORANGE_CF,
  RED_CF,
  PINK_CF,
  PURPLE_CF,
  WHITE_CF,
  NO_FAMILY_CF,
  colorFamilies,
};
