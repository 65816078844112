import { ChangeEvent, useEffect, useState } from 'react';
import {
  Checkbox,
  Input,
  MultiOptionType,
  OptionType,
  RoundedSelect,
} from '@ezetech/swag-space-x';
import { MultiValue } from 'react-select';
import { useAppDispatch, useAppSelector } from 'redux/store';
import {
  filtersProductsRequestSelector,
  selectAllProductsSelector,
  selectedFilterOptionsSelector,
  selectionTotalProductsSelector,
} from 'redux/selectors/products.selectors';
import { usePrevious } from 'hooks/use-previous.hook';
import { useDebounce } from 'hooks/use-debounce.hook';
import {
  useGetProductsMutation,
  useGetProductsIdsMutation,
} from 'redux/api/products.api';
import { setSelectAll, updateSearchParams } from 'redux/slices/products.slice';
import { getCustomRoundedSelectStyles } from 'components/rounded-select';
import { PRODUCTS_FILTERS } from 'interfaces/products.interface';
import searchIcon from 'assets/svg/search.svg';
import { cannot, SUBJECTS } from 'boot/ability';

import css from './hide-products-popup.module.scss';

const styles = {
  menuStyles: {
    padding: '4px 8px',
  },
};

export const ProductsFilter = ({
  resetListPosition,
}: {
  resetListPosition(value?: number): void;
}) => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(filtersProductsRequestSelector);
  const [getProducts] = useGetProductsMutation();
  const [getProductsIds] = useGetProductsIdsMutation();
  const selectedProductsTotal = useAppSelector(selectionTotalProductsSelector);
  const selectAll = useAppSelector(selectAllProductsSelector);
  const selectedOptions = useAppSelector(selectedFilterOptionsSelector);
  const [searchValue, setSearchValue] = useState<string>('');
  const debouncedSearchTerm = useDebounce(filters.search, 1000);
  const prevSearchValue = usePrevious({
    value: searchValue,
    shouldUpdate: true,
    initValue: '',
  });
  const isDisabled = cannot(
    SUBJECTS.COMMISSION.actions.HIDE_PRODUCTS_FROM_THE_SITE_EDIT,
    SUBJECTS.COMMISSION.value,
  );

  useEffect(() => {
    setSearchValue(debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (prevSearchValue !== searchValue) {
      getProducts({
        page: 1,
        search: searchValue,
        perPage: filters.perPage,
        filter: selectedOptions,
        selectedIds: filters.selectedIds,
        excludedIds: filters.excludedIds,
      });
      resetListPosition(0);
    }
  }, [searchValue]);

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    dispatch(updateSearchParams({ search: value }));
  };

  const getUpdatedFilters = (selected: MultiOptionType) => {
    let numberOfHiddenOptions = 0;
    const options = (selected as MultiValue<OptionType | null>) || [];

    return options.reduceRight<MultiValue<OptionType | null>>((all, next) => {
      if (next?.value.includes('isHidden')) {
        if (!numberOfHiddenOptions) {
          numberOfHiddenOptions++;
          return [...all, next];
        }

        return all;
      }

      return [...all, next];
    }, []);
  };

  const handleFilterClick = (selected: MultiOptionType) => {
    const updatedFilter = getUpdatedFilters(selected);

    dispatch(updateSearchParams({ filter: updatedFilter }));
    getProducts({
      page: 1,
      search: filters.search,
      perPage: filters.perPage,
      filter: updatedFilter,
      selectedIds: filters.selectedIds,
      excludedIds: filters.excludedIds,
    });
    resetListPosition(0);
  };

  const handleCheckAllClick = (value: boolean) => {
    dispatch(setSelectAll(value));

    getProductsIds({
      search: filters.search,
      filter: filters.filter,
    });
  };

  return (
    <div className={css.filter}>
      <div className={css.checkboxBlock}>
        <Checkbox
          name="selectAll"
          checked={selectAll}
          labelClassName={css.checkboxLabel}
          checkboxClassName={css.checkbox}
          label={`${selectedProductsTotal} Selected`}
          onCheckedChange={handleCheckAllClick}
          disabled={isDisabled}
        />
      </div>
      <div className={css.search}>
        <Input
          adornmentStart={
            <img className={css.adornment} src={searchIcon} alt="searchIcon" />
          }
          className={css.searchInput}
          errorClassName={css.searchError}
          name="searchProducts"
          value={filters.search}
          placeholder="Search"
          onChange={handleSearchChange}
        />
        <RoundedSelect
          name="productsFilter"
          styles={getCustomRoundedSelectStyles(styles)}
          onChange={handleFilterClick}
          options={PRODUCTS_FILTERS}
          selectedOptions={selectedOptions}
          menuPortalTarget={document.body}
          menuPosition="fixed"
          errorClassName={css.filterError}
          selectWrapperClassName={css.filterSelect}
          optionTypographyClassName={css.optionTypography}
          menuShouldBlockScroll
          isCheckboxHidden
          isMultiSelect
        />
      </div>
    </div>
  );
};
