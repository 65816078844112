export const ITEM_STATUS_CODE = {
  REFUNDED: 'REFUNDED',
  CANCELLED: 'CANCELLED',
  REJECTED: 'REJECTED',
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  COLORS_REVIEW_BY_RESELLER: 'COLORS_REVIEW_BY_RESELLER',
  COLORS_REVIEW_BY_CUSTOMER: 'COLORS_REVIEW_BY_CUSTOMER',
  PROCESSING_ORDER: 'PROCESSING_ORDER',
  CREATING_PRODUCTION_MOCKUP: 'CREATING_PRODUCTION_MOCKUP',
  MOCKUP_APPROVAL_BY_RESELLER: 'MOCKUP_APPROVAL_BY_RESELLER',
  MOCKUP_REVISION_BY_RESELLER: 'MOCKUP_REVISION_BY_RESELLER',
  MOCKUP_APPROVAL_BY_CUSTOMER: 'MOCKUP_APPROVAL_BY_CUSTOMER',
  MOCKUP_REVISION_BY_CUSTOMER: 'MOCKUP_REVISION_BY_CUSTOMER',
  IN_PRODUCTION: 'IN_PRODUCTION',
  SHIPPED: 'SHIPPED',
  DELIVERED_TO_WAREHOUSE: 'DELIVERED_TO_WAREHOUSE',
  READY_FOR_DISTRIBUTION: 'READY_FOR_DISTRIBUTION',
  DELIVERED: 'DELIVERED',
};

export const ITEM_STATUS_CODE_LABEL = {
  [ITEM_STATUS_CODE.REFUNDED]: 'Cancelled',
  [ITEM_STATUS_CODE.CANCELLED]: 'Cancelled',
  [ITEM_STATUS_CODE.REJECTED]: 'Rejected',
  [ITEM_STATUS_CODE.PENDING_APPROVAL]: 'Pending approval',
  [ITEM_STATUS_CODE.COLORS_REVIEW_BY_RESELLER]: 'Review colors',
  [ITEM_STATUS_CODE.COLORS_REVIEW_BY_CUSTOMER]: 'Requested colors review by Customer',
  [ITEM_STATUS_CODE.CREATING_PRODUCTION_MOCKUP]: 'Creating production mockup',
  [ITEM_STATUS_CODE.MOCKUP_APPROVAL_BY_RESELLER]: 'View production mockup',
  [ITEM_STATUS_CODE.MOCKUP_REVISION_BY_RESELLER]: 'Requested revisions',
  [ITEM_STATUS_CODE.MOCKUP_APPROVAL_BY_CUSTOMER]: 'Mockup sent to Customer',
  [ITEM_STATUS_CODE.MOCKUP_REVISION_BY_CUSTOMER]: 'Customer requested revisions',
  [ITEM_STATUS_CODE.IN_PRODUCTION]: 'In production',
  [ITEM_STATUS_CODE.SHIPPED]: 'Tracking number',
  [ITEM_STATUS_CODE.DELIVERED]: 'Delivered',
  [ITEM_STATUS_CODE.DELIVERED_TO_WAREHOUSE]: 'Delivered to warehouse',
  [ITEM_STATUS_CODE.READY_FOR_DISTRIBUTION]: 'Ready for distribution',
};

export enum CUSTOMER_MOCKUP_STATUSES {
  UPLOADED = 'uploaded',
}

export enum PRODUCTION_MOCKUP_STATUSES {
  UPLOADED = 'uploaded',
  PENDING_RESELLER_APPROVAL = 'pending_reseller_approval',
  PENDING_CUSTOMER_APPROVAL = 'pending_customer_approval',
  APPROVED_BY_CUSTOMER = 'approved_by_customer',
  DECLINED_BY_RESELLER = 'declined_by_reseller',
  DECLINED_BY_CUSTOMER = 'declined_by_customer',
}
