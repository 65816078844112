import { useCallback, useState, useEffect } from 'react';
import cn from 'classnames';
import { Accordion, AccordionItem } from '@ezetech/swag-space-x';
import { useAppDispatch } from 'redux/store';

import { IHomepageSettings } from 'interfaces/homepage-settings.interface';
import { debounce } from 'utils/common.util';

import {
  checkHomepageProducts,
  removeHomepageAllProductsSelection,
} from 'redux/slices/settings.slice';
import { getHomepageObject } from '../utils';
import { SidebarHeroBlock, EDITOR_ID } from './hero-block';
import { SidebarBrandLogosBlock } from './brand-logos-block';
import { SidebarAboutUsBlock } from './about-us-block';
import { SidebarBrandsWeOfferBlock } from './brands-we-offer-block';
import { SidebarCategoriesBlock } from './collections-block';
import { SidebarContactFormBlock } from './contact-form-block';
import { SidebarImagesRowBlock } from './images-row-block';
import { SidebarPromoProductBlock } from './promo-product-block';
import css from './sidebar.module.scss';

type SidebarProps = {
  settings: IHomepageSettings;
  onSettingsChanged: (data: IHomepageSettings) => void;
};

export const EditHomepageSidebar = (props: SidebarProps): JSX.Element => {
  const { settings, onSettingsChanged } = props;
  const dispatch = useAppDispatch();
  const [settingsUpdated, setSettingsUpdated] = useState(getHomepageObject(settings));
  const [dynamicProductsClassName, setDynamicProductsClassName] = useState<
    string | undefined
  >();

  const handleUpdates = (toUpdate: typeof settingsUpdated) => {
    onSettingsChanged(toUpdate);
  };

  const debouncedHandleUpdates = useCallback(debounce(handleUpdates, 800), []);

  const setChanges = (toUpdate: typeof settingsUpdated) => {
    setSettingsUpdated(toUpdate);
    debouncedHandleUpdates(toUpdate);
  };

  const checkIsProductsBlockWithoutOverflow = (value: string | string[]) => {
    const toCheck = Array.isArray(value) ? value : [value];

    return toCheck.includes('Top Picks by You');
  };

  const handleAccordionValuesChange = (value: string | string[]) => {
    const isProductOpen = checkIsProductsBlockWithoutOverflow(value);

    if (isProductOpen && !dynamicProductsClassName) {
      setTimeout(() => setDynamicProductsClassName(css.skipOverflow), 1000);

      return;
    }

    if (!isProductOpen && dynamicProductsClassName) {
      setDynamicProductsClassName(undefined);
    }
  };

  useEffect(() => {
    setSettingsUpdated(getHomepageObject(settings));
    dispatch(removeHomepageAllProductsSelection());
    settings.promoProductBlock?.products.forEach((product) => {
      dispatch(
        checkHomepageProducts({
          publicId: product.publicId,
          value: {
            publicId: product.publicId,
            name: product.name,
            logo: product.logo || '',
          },
        }),
      );
    });
  }, [settings]);

  return (
    <Accordion onValueChange={handleAccordionValuesChange} className={css.accordion}>
      <AccordionItem
        headerComponent="Hero"
        value="Hero"
        className={css.accordionItem}
        triggerClassName={css.accordionItemContent}
        contentClassName={css.accordionItemContent}
      >
        <SidebarHeroBlock
          settings={settingsUpdated.heroBlock}
          setChanges={(name, value) => {
            setChanges({
              ...settingsUpdated,
              heroBlock: {
                ...settingsUpdated.heroBlock,
                [name]: value,
              },
            });
          }}
        />
      </AccordionItem>
      <AccordionItem
        headerComponent="Brand Logos"
        value="Brand Logos"
        className={css.accordionItem}
        triggerClassName={css.accordionItemContent}
        contentClassName={css.accordionItemContent}
      >
        <SidebarBrandLogosBlock
          settings={settingsUpdated.brandLogosBlock}
          setChanges={(name, value) => {
            setChanges({
              ...settingsUpdated,
              brandLogosBlock: {
                ...settingsUpdated.brandLogosBlock,
                [name]: value,
              },
            });
          }}
        />
      </AccordionItem>
      <AccordionItem
        headerComponent="About Us"
        value="About Us"
        className={css.accordionItem}
        triggerClassName={css.accordionItemContent}
        contentClassName={css.accordionItemContent}
      >
        <SidebarAboutUsBlock
          settings={settingsUpdated.aboutUsBlock}
          setChanges={(name, value) => {
            setChanges({
              ...settingsUpdated,
              aboutUsBlock: {
                ...settingsUpdated.aboutUsBlock,
                [name]: value,
              },
            });
          }}
        />
      </AccordionItem>
      <AccordionItem
        headerComponent="Collections"
        value="Collections"
        className={css.accordionItem}
        triggerClassName={css.accordionItemContent}
        contentClassName={css.accordionItemContent}
      >
        <SidebarCategoriesBlock
          settings={settingsUpdated.categoriesBlock}
          setChanges={(name, value) => {
            setChanges({
              ...settingsUpdated,
              categoriesBlock: {
                ...settingsUpdated.categoriesBlock,
                [name]: value,
              },
            });
          }}
        />
      </AccordionItem>
      <AccordionItem
        headerComponent="Top Picks by You"
        value="Top Picks by You"
        className={css.accordionItem}
        triggerClassName={css.accordionItemContent}
        contentClassName={cn(css.accordionItemContent, dynamicProductsClassName)}
      >
        <SidebarPromoProductBlock
          settings={settingsUpdated.promoProductBlock}
          setChanges={(name, value) => {
            setChanges({
              ...settingsUpdated,
              promoProductBlock: {
                ...settingsUpdated.promoProductBlock,
                [name]: value,
              },
            });
          }}
        />
      </AccordionItem>
      <AccordionItem
        headerComponent="Brands We Offer"
        value="Brands We Offer"
        className={css.accordionItem}
        triggerClassName={css.accordionItemContent}
        contentClassName={css.accordionItemContent}
      >
        <SidebarBrandsWeOfferBlock
          settings={settingsUpdated.brandsWeOfferBlock}
          setChanges={(name, value) => {
            setChanges({
              ...settingsUpdated,
              brandsWeOfferBlock: {
                ...settingsUpdated.brandsWeOfferBlock,
                [name]: value,
              },
            });
          }}
        />
      </AccordionItem>
      <AccordionItem
        headerComponent="3 Image Row"
        value="3 Image Row"
        className={css.accordionItem}
        triggerClassName={css.accordionItemContent}
        contentClassName={css.accordionItemContent}
      >
        <SidebarImagesRowBlock
          settings={settingsUpdated.imagesRowBlock}
          setChanges={(name, value) => {
            setChanges({
              ...settingsUpdated,
              imagesRowBlock: {
                ...settingsUpdated.imagesRowBlock,
                [name]: value,
              },
            });
          }}
        />
      </AccordionItem>
      <AccordionItem
        headerComponent="Contact Form"
        value="Contact Form"
        className={css.accordionItem}
        triggerClassName={css.accordionItemContent}
        contentClassName={css.accordionItemContent}
      >
        <SidebarContactFormBlock
          settings={settingsUpdated.contactFormBlock}
          setChanges={(name, value) => {
            setChanges({
              ...settingsUpdated,
              contactFormBlock: {
                ...settingsUpdated.contactFormBlock,
                [name]: value,
              },
            });
          }}
        />
      </AccordionItem>
    </Accordion>
  );
};

export { EDITOR_ID };
