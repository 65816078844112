import { IUsersList } from 'interfaces/user-api.interface';

export interface ICustomersLeadsData {
  list: IUsersList['list'];
  customersTotal?: number;
  leadsTotal?: number;
}

export enum CLEntity {
  customers = 'customers',
  leads = 'leads',
}

export type TextToggleLabels = { id: string; text: string }[];
