import { Checkbox, Dropzone, Typography } from '@ezetech/swag-space-x';
import cn from 'classnames';
import COLORS from 'constants/styles/colors-js.constant.module.scss';

import {
  MAX_FILE_SIZE_10MB_RESTRICTION,
  JPEG_PNG_ACCEPT_RESTRICTION,
} from 'constants/limits';
import { useProcessFile } from 'hooks/use-process-file';

import { SidebarImagesRowBlockProps } from '../interface';

import css from './sidebar.module.scss';

export const SidebarImagesRowBlock = (props: SidebarImagesRowBlockProps): JSX.Element => {
  const { settings, setChanges } = props;
  const { handleProcessFile } = useProcessFile();

  return (
    <div className={css.sidebarMainBlock}>
      <Checkbox
        onCheckedChange={(e) => setChanges('isHidden', e)}
        name="isHidden"
        checked={settings.isHidden}
        label="Hide section"
      />
      <div
        className={cn(css.disabledBlock, { [css.disabledOverlay]: settings.isHidden })}
      />
      <Typography
        fontType="bodyMd"
        color={COLORS.colorText600}
        lineHeight="162.5%"
        className={css.subInputInfoFullWidth}
      >
        Add images you think will make your site pop. We suggest past images of work.
      </Typography>
      <div className={css.imagesList}>
        {settings.images.map((selectedFile, index) => (
          <div key={index}>
            <Typography
              fontType="bodySm"
              color={COLORS.colorText900}
              className={css.dropzoneLabel}
              lineHeight="142.857%"
            >
              {`Upload Image ${index + 1}`}
            </Typography>
            <Dropzone
              buttonClassName={css.dropzoneButton}
              accept={JPEG_PNG_ACCEPT_RESTRICTION}
              className={css.imageLoader}
              files={selectedFile ? [selectedFile] : []}
              processFile={handleProcessFile}
              onFilesChanged={(files) => {
                if (!files[0] && !settings.images[index]?.url) {
                  return;
                }
                const existsImages = [...settings.images];
                existsImages[index] = files[0] || null;
                setChanges('images', existsImages);
              }}
              maxSize={MAX_FILE_SIZE_10MB_RESTRICTION}
              additionalText="JPG or PNG, no more than 10MB"
              uploadTextWrapperClassName={css.uploadTextWrapper}
              previewClassName={css.previewImageWrapper}
              helperText="Recommended size is 1194 x 874 pixels"
              helperTextClassName={css.dropzoneHelperText}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
