import { Typography } from '@ezetech/swag-space-x';
import { TypographyProps } from '@ezetech/swag-space-x/dist/components/typography/typography.types';
import { RESELLER_MOCKUP_PRE_APPROVAL } from 'components/popups/_logic/popups-list';
import { openPopup } from 'redux/slices/modals.slice';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { itemDataForProductionMockup } from 'redux/selectors/orders.selectors';
import { itemDataForProductionMockupForCustomerProfile } from 'redux/selectors/customer-details.selector';
import { IItem } from 'interfaces/orders.interface';
import { getItemDataForProductionMockup } from 'utils/items.util';

export const MockupApprovalByReseller = ({
  status,
  className,
  item,
  itemId,
  orderId,
  fromCustomerProfile = false,
  isReviewMockupsByCustomerAllowed,
  fontType = 'bodyMdBold',
}: {
  item?: Partial<IItem>;
  itemId: string;
  orderId: string;
  status: string;
  className: string;
  isReviewMockupsByCustomerAllowed: boolean;
  fromCustomerProfile?: boolean;
  fontType?: TypographyProps['fontType'];
}) => {
  const dispatch = useAppDispatch();
  const { orderNum, productName, productionMockup, customerMockups } =
    (item && getItemDataForProductionMockup(item)) ||
    (fromCustomerProfile
      ? useAppSelector(itemDataForProductionMockupForCustomerProfile({ itemId, orderId }))
      : useAppSelector(itemDataForProductionMockup({ itemId, orderId })));

  const onClick = () => {
    dispatch(
      openPopup({
        popupName: RESELLER_MOCKUP_PRE_APPROVAL,
        popupProps: {
          itemId,
          orderId,
          orderNum,
          productName,
          productionMockup,
          customerMockups,
          isReviewMockupsByCustomerAllowed,
        },
      }),
    );
  };

  return (
    <div onClick={onClick}>
      <Typography
        lineHeight="170%"
        fontType={fontType}
        color={COLORS.colorPrimaryText}
        className={className}
        link
      >
        {status}
      </Typography>
    </div>
  );
};
