import { Typography } from '@ezetech/swag-space-x';
import {
  PdfPresentationEditorSettingsPage,
  PdfPresentationEditorPageId,
} from 'interfaces/pdf-presentation';
import { PDF_PRESENTATION_PAGES_NORMALIZED } from 'constants/pdf-presentation.constants';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { deepGet } from 'utils/deep-get.util';
import ExamplePastWorks from 'assets/images/example-past-works.png';
import ExampleBoxOverview from 'assets/images/example-box-overview.png';
import { MultipleImageUploader } from '../components/popups/edit-pdf-presentation-layout-popup/components/forms/multiple-image-uploader';
import { SingleImageUploaderWithExample } from '../components/popups/edit-pdf-presentation-layout-popup/components/forms/single-image-uploader-with-example';
import { ThankYouForm } from '../components/popups/edit-pdf-presentation-layout-popup/components/forms/thank-you-form';
import { ReviewsForm } from '../components/popups/edit-pdf-presentation-layout-popup/components/forms/reviews-form';

import {
  InputWithHelpText,
  TextFieldWithHelpText,
} from '../components/input-with-help-text';

export const PDF_PRESENTATION_SETTINGS_PAGES: Record<
  PdfPresentationEditorPageId,
  PdfPresentationEditorSettingsPage
> = {
  [PdfPresentationEditorPageId.COVER]: {
    ...PDF_PRESENTATION_PAGES_NORMALIZED[PdfPresentationEditorPageId.COVER],
    fieldsDefinition: [
      {
        component: ({ values, onChangeValueCreator }) => (
          <InputWithHelpText
            key={PdfPresentationEditorPageId.COVER}
            label="Heading"
            placeholder="e.g. Swag Simplified."
            name="homepageHeading"
            value={deepGet<string>(values as never, 'homepageHeading', '')}
            onChange={({ target: { value } }) =>
              onChangeValueCreator('homepageHeading')(value.slice(0, 50))
            }
            helpText="Max 50 characters"
          />
        ),
      },
      {
        component: ({ values, onChangeValueCreator }) => (
          <TextFieldWithHelpText
            label="Body Copy"
            key={`${PdfPresentationEditorPageId.COVER}-copy`}
            placeholder="e.g. Your all-in one swag platform. Shop, store and ship the highest quality swag around the world with the click of a button."
            name="homepageBody"
            value={deepGet<string>(values as never, 'homepageBody', '')}
            onChange={({ target: { value } }) =>
              onChangeValueCreator('homepageBody')(value.slice(0, 200))
            }
            helpText="Max 200 characters"
            {...{ rows: 4 }}
          />
        ),
      },
    ],
  },
  [PdfPresentationEditorPageId.WHAT_WE_DO]: {
    ...PDF_PRESENTATION_PAGES_NORMALIZED[PdfPresentationEditorPageId.WHAT_WE_DO],
    fieldsDefinition: [
      {
        component: ({ values, onChangeValueCreator }) => (
          <TextFieldWithHelpText
            label="Heading"
            key={PdfPresentationEditorPageId.WHAT_WE_DO}
            placeholder="e.g. Jenny Promo is the best place for companies to buy quality promotional products that people will actually want to keep."
            name="whatWeDoHeading"
            value={deepGet<string>(values as never, 'whatWeDoHeading', '')}
            onChange={({ target: { value } }) =>
              onChangeValueCreator('whatWeDoHeading')(value.slice(0, 200))
            }
            helpText="Max 200 characters"
            {...{ rows: 4 }}
          />
        ),
      },
      {
        component: ({ values, onChangeValueCreator }) => (
          <TextFieldWithHelpText
            label="Body Copy"
            key={`${PdfPresentationEditorPageId.WHAT_WE_DO}-copy`}
            placeholder="e.g. We have curated the best of whats out there to make sure that everything that we offer will help you stand out."
            name="whatWeDoBody"
            value={deepGet<string>(values as never, 'whatWeDoBody', '')}
            onChange={({ target: { value } }) =>
              onChangeValueCreator('whatWeDoBody')(value.slice(0, 200))
            }
            helpText="Max 200 characters"
            {...{ rows: 4 }}
          />
        ),
      },
    ],
  },
  [PdfPresentationEditorPageId.WHO_WE_WORK_WITH]: {
    ...PDF_PRESENTATION_PAGES_NORMALIZED[PdfPresentationEditorPageId.WHO_WE_WORK_WITH],
    additionalComponentBefore: () => (
      <Typography lineHeight="162.5%" fontType="bodyMd" color={COLORS.colorSecondaryText}>
        For best results, we recommend 4, 8, 12 or 16 logos. Recommended file type, PNG or
        JPG with a white or transparent background.
      </Typography>
    ),
    fieldsDefinition: [
      {
        component: (props) => (
          <MultipleImageUploader
            key={PdfPresentationEditorPageId.WHO_WE_WORK_WITH}
            {...props}
          />
        ),
      },
    ],
  },
  [PdfPresentationEditorPageId.PAST_WORKS]: {
    ...PDF_PRESENTATION_PAGES_NORMALIZED[PdfPresentationEditorPageId.PAST_WORKS],
    fieldsDefinition: [
      {
        component: (props) => (
          <SingleImageUploaderWithExample
            label="Upload Past Works Image"
            key={PdfPresentationEditorPageId.PAST_WORKS}
            keyName="portfolioImage"
            exampleImage={ExamplePastWorks}
            helperText="Recommended size is 2880 x 2160 pixels"
            {...props}
          />
        ),
      },
    ],
  },
  [PdfPresentationEditorPageId.BOX_OVERVIEW]: {
    ...PDF_PRESENTATION_PAGES_NORMALIZED[PdfPresentationEditorPageId.BOX_OVERVIEW],
    fieldsDefinition: [
      {
        component: (props) => (
          <SingleImageUploaderWithExample
            label="Upload Box Image"
            key={PdfPresentationEditorPageId.BOX_OVERVIEW}
            keyName="boxOverviewImage"
            exampleImage={ExampleBoxOverview}
            placeholder="Upload an image of a box order that you are proud of, if you don't have one, upload a beautiful non box past works image"
            helperText="Recommended size is 1808 x 2160 pixels"
            {...props}
          />
        ),
      },
    ],
  },
  [PdfPresentationEditorPageId.BOX_PAGE]: {
    ...PDF_PRESENTATION_PAGES_NORMALIZED[PdfPresentationEditorPageId.BOX_PAGE],
    fieldsDefinition: [],
  },
  [PdfPresentationEditorPageId.PRODUCT_PAGE]: {
    ...PDF_PRESENTATION_PAGES_NORMALIZED[PdfPresentationEditorPageId.PRODUCT_PAGE],
    fieldsDefinition: [],
  },
  [PdfPresentationEditorPageId.SUMMARY]: {
    ...PDF_PRESENTATION_PAGES_NORMALIZED[PdfPresentationEditorPageId.SUMMARY],
    fieldsDefinition: [],
  },
  [PdfPresentationEditorPageId.REVIEWS]: {
    ...PDF_PRESENTATION_PAGES_NORMALIZED[PdfPresentationEditorPageId.REVIEWS],
    fieldsDefinition: [
      {
        component: (props) => (
          <ReviewsForm {...props} key={PdfPresentationEditorPageId.REVIEWS} />
        ),
      },
    ],
    additionalComponentBefore: () => (
      <Typography lineHeight="162.5%" fontType="bodyMd" color={COLORS.colorSecondaryText}>
        For best results, we recommend adding 3 or 6 reviews. Logos - Recommend file type,
        PNG or JPG with a white or transparent background.
      </Typography>
    ),
  },
  [PdfPresentationEditorPageId.SO_MUCH_MORE]: {
    ...PDF_PRESENTATION_PAGES_NORMALIZED[PdfPresentationEditorPageId.SO_MUCH_MORE],
    fieldsDefinition: [],
  },
  [PdfPresentationEditorPageId.BENEFITS]: {
    ...PDF_PRESENTATION_PAGES_NORMALIZED[PdfPresentationEditorPageId.BENEFITS],
    fieldsDefinition: [],
  },
  [PdfPresentationEditorPageId.SHOWCASE]: {
    ...PDF_PRESENTATION_PAGES_NORMALIZED[PdfPresentationEditorPageId.SHOWCASE],
    fieldsDefinition: [
      {
        component: (props) => (
          <SingleImageUploaderWithExample
            key={PdfPresentationEditorPageId.SHOWCASE}
            label="Upload Product Showcase Image"
            keyName="productShowcaseImage"
            exampleImage={ExamplePastWorks}
            helperText="Recommended size is 2880 x 2160 pixels"
            {...props}
          />
        ),
      },
    ],
  },
  [PdfPresentationEditorPageId.THANK_YOU]: {
    ...PDF_PRESENTATION_PAGES_NORMALIZED[PdfPresentationEditorPageId.THANK_YOU],
    fieldsDefinition: [
      {
        component: (props) => (
          <ThankYouForm key={PdfPresentationEditorPageId.THANK_YOU} {...props} />
        ),
      },
    ],
    additionalComponentBefore: () => (
      <Typography lineHeight="162.5%" fontType="bodyMd" color={COLORS.colorSecondaryText}>
        We pulled your phone, email and address from your company settings.
      </Typography>
    ),
  },
};
