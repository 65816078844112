import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IAssignedForReseller } from 'interfaces/assignee.interface';
import { IOrder } from 'interfaces/orders.interface';

interface IOrdersState {
  list: IOrder[];
  total: number;
}

const initialState: IOrdersState = {
  list: [],
  total: 0,
};

export const ordersSlice = createSlice({
  initialState,
  name: 'ordersSlice',
  reducers: {
    reset: () => initialState,
    setOrders: (state, action: PayloadAction<IOrdersState>) => {
      if (!action.payload) {
        return state;
      }

      return { ...action.payload };
    },
    updateOrder: (state, action: PayloadAction<IOrder>) => {
      const { list } = state;
      const { payload } = action;

      return {
        ...state,
        list: list.map((item) => {
          if (item.id === payload.id) {
            return payload;
          }

          return item;
        }),
      };
    },
    patchOrder: (state, action: PayloadAction<Partial<IOrder>>) => {
      const { list } = state;
      const { payload } = action;

      return {
        ...state,
        list: list.map((item) => {
          if (item.id === payload.id) {
            return {
              ...item,
              ...payload,
            };
          }

          return item;
        }),
      };
    },
    setAssigneeToOrderById: (
      state,
      action: PayloadAction<{ entityId: string; assignee: IAssignedForReseller | null }>,
    ) => {
      const { entityId, assignee } = action.payload;

      return {
        ...state,
        list: state.list.map((item) => {
          if (item.id === entityId) {
            return {
              ...item,
              assignee,
            };
          }

          return item;
        }),
      };
    },
  },
});

export const ordersReducer = ordersSlice.reducer;
export const { reset, setOrders, updateOrder, patchOrder, setAssigneeToOrderById } =
  ordersSlice.actions;
