import cn from 'classnames';
import { ReactElement } from 'react';

import { Divider, RainbowBox, Typography } from '@ezetech/swag-space-x';

import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { Price } from 'utils/price.util';

import css from './deal-comparison-widget.module.scss';

export function DealComparisonWidget(): ReactElement {
  return (
    <div className={css.wrapperGrid}>
      <RainbowBox
        borderWidth={0}
        outerClassName={css.columnFirst}
        innerClassName={css.columnInnerClassName}
      >
        <section className={css.greyBlock}>
          <Typography
            lineHeight="162.5%"
            fontType="bodyMdBold"
            color={COLORS.colorTypographyHeadings}
          >
            Doing it yourself
          </Typography>
          <Divider color={COLORS.colorDivider} margin={12} className={css.divider} />
          <div className={css.contentRow}>
            <Typography
              lineHeight="162.5%"
              fontType="bodyMd"
              color={COLORS.colorTypographyHeadings}
              className={css.leftSideText}
            >
              Customer pays:
            </Typography>
            <Typography
              lineHeight="162.5%"
              fontType="bodyMd"
              color={COLORS.colorTypographyHeadings}
            >
              {Price.formatPrice(1000)}
            </Typography>
          </div>
        </section>
        <section className={css.greyBlock}>
          <Typography
            lineHeight="162.5%"
            fontType="bodyMdBold"
            color={COLORS.colorTypographyHeadings}
          >
            Costs to close a deal
          </Typography>
          <Divider color={COLORS.colorDivider} margin={12} className={css.divider} />
          <div className={css.contentRow}>
            <Typography
              lineHeight="162.5%"
              fontType="bodyMd"
              color={COLORS.colorTextSubdued}
              className={css.leftSideText}
            >
              Product cost:
            </Typography>
            <Typography
              lineHeight="162.5%"
              fontType="bodyMd"
              color={COLORS.colorErrorText}
            >
              -{Price.formatPrice(600)}
            </Typography>
          </div>
          <Divider color={COLORS.colorDivider} margin={12} className={css.divider} />
          <Typography
            lineHeight="162.5%"
            fontType="bodyMdBold"
            color={COLORS.colorTypographyHeadings}
            className={css.leftSideTitle}
          >
            Pre-order
          </Typography>
          <div className={css.contentRow}>
            <Typography
              lineHeight="162.5%"
              fontType="bodyMd"
              color={COLORS.colorTextSubdued}
              className={css.leftSideText}
            >
              Emails, price quotes, presentations, design, phone calls, collecting money,
              invoicing:
            </Typography>
            <Typography
              lineHeight="162.5%"
              fontType="bodyMd"
              color={COLORS.colorErrorText}
            >
              -{Price.formatPrice(100)}
            </Typography>
          </div>
          <Divider color={COLORS.colorDivider} margin={12} className={css.divider} />
          <Typography
            lineHeight="162.5%"
            fontType="bodyMdBold"
            color={COLORS.colorTypographyHeadings}
            className={css.leftSideTitle}
          >
            Post-order
          </Typography>
          <div className={css.contentRow}>
            <Typography
              lineHeight="162.5%"
              fontType="bodyMd"
              color={COLORS.colorTextSubdued}
              className={css.leftSideText}
            >
              Placing order and following up with supplier, general order management:
            </Typography>
            <Typography
              lineHeight="162.5%"
              fontType="bodyMd"
              color={COLORS.colorErrorText}
            >
              -{Price.formatPrice(100)}
            </Typography>
          </div>
        </section>
        <section className={css.greyBlock}>
          <div className={css.contentRow}>
            <Typography
              lineHeight="162.5%"
              fontType="bodyMd"
              color={COLORS.colorTypographyHeadings}
              className={css.leftSideText}
            >
              You make:
            </Typography>
            <Typography
              lineHeight="162.5%"
              fontType="bodyMd"
              color={COLORS.colorSuccess700}
            >
              {Price.formatPrice(200)}
            </Typography>
          </div>
        </section>
      </RainbowBox>

      <RainbowBox
        borderWidth={0}
        outerClassName={cn(css.columnOuterClassName, css.columnSecond)}
        innerClassName={css.columnInnerClassName}
      >
        <section className={css.greyBlock}>
          <Typography
            lineHeight="162.5%"
            fontType="bodyMdBold"
            color={COLORS.colorTypographyHeadings}
          >
            Backend operations partner
          </Typography>
          <Divider color={COLORS.colorDivider} margin={12} className={css.divider} />
          <div className={css.contentRow}>
            <Typography
              lineHeight="162.5%"
              fontType="bodyMd"
              color={COLORS.colorTypographyHeadings}
              className={css.leftSideText}
            >
              Customer pays:
            </Typography>
            <Typography
              lineHeight="162.5%"
              fontType="bodyMd"
              color={COLORS.colorTypographyHeadings}
            >
              {Price.formatPrice(1000)}
            </Typography>
          </div>
        </section>
        <section className={css.greyBlock}>
          <Typography
            lineHeight="162.5%"
            fontType="bodyMdBold"
            color={COLORS.colorTypographyHeadings}
          >
            Costs to close a deal
          </Typography>
          <Divider color={COLORS.colorDivider} margin={12} className={css.divider} />
          <div className={css.contentRow}>
            <Typography
              lineHeight="162.5%"
              fontType="bodyMd"
              color={COLORS.colorTextSubdued}
              className={css.leftSideText}
            >
              Product cost:
            </Typography>
            <Typography
              lineHeight="162.5%"
              fontType="bodyMd"
              color={COLORS.colorErrorText}
            >
              -{Price.formatPrice(600)}
            </Typography>
          </div>
          <Divider color={COLORS.colorDivider} margin={12} className={css.divider} />
          <Typography
            lineHeight="162.5%"
            fontType="bodyMdBold"
            color={COLORS.colorTypographyHeadings}
            className={css.leftSideTitle}
          >
            Pre-order
          </Typography>
          <div className={css.contentRow}>
            <Typography
              lineHeight="162.5%"
              fontType="bodyMd"
              color={COLORS.colorTextSubdued}
              className={css.leftSideText}
            >
              Emails, price quotes, presentations, design, phone calls:
            </Typography>
            <Typography
              lineHeight="162.5%"
              fontType="bodyMd"
              color={COLORS.colorErrorText}
            >
              -{Price.formatPrice(80)}
            </Typography>
          </div>
          <br className={css.preOrderBreak} />
          <br className={css.preOrderBreak} />

          <Divider color={COLORS.colorDivider} margin={12} className={css.divider} />
          <Typography
            lineHeight="162.5%"
            fontType="bodyMdBold"
            color={COLORS.colorTypographyHeadings}
            className={css.leftSideTitle}
          >
            Post-order
          </Typography>
          <div className={css.contentRow}>
            <Typography
              lineHeight="162.5%"
              fontType="bodyMd"
              color={COLORS.colorTextSubdued}
              className={css.leftSideText}
            >
              The backend partner handles all the order management. Their take is:
            </Typography>
            <Typography
              lineHeight="162.5%"
              fontType="bodyMd"
              color={COLORS.colorErrorText}
            >
              -{Price.formatPrice(160)}
            </Typography>
          </div>
        </section>
        <section className={css.greyBlock}>
          <div className={css.contentRow}>
            <Typography
              lineHeight="162.5%"
              fontType="bodyMd"
              color={COLORS.colorTypographyHeadings}
              className={css.leftSideText}
            >
              You make:
            </Typography>
            <Typography
              lineHeight="162.5%"
              fontType="bodyMd"
              color={COLORS.colorSuccess700}
            >
              {Price.formatPrice(160)}
            </Typography>
          </div>
        </section>
      </RainbowBox>

      <RainbowBox
        borderWidth={3}
        outerClassName={cn(css.columnOuterClassName, css.columnThird)}
        innerClassName={cn(css.columnInnerClassName, css.innerColumnThird)}
      >
        <section className={cn(css.greyBlock, css.swagSpaceGreyBlock)}>
          <Typography
            lineHeight="100%"
            fontType="HEADING5XL"
            color={COLORS.colorTypographyHeadings}
          >
            SWAG SPACE
          </Typography>
          <Divider
            color={COLORS.colorTabsSelection}
            margin={12}
            className={css.divider}
          />
          <div className={css.contentRow}>
            <Typography
              lineHeight="162.5%"
              fontType="bodyMd"
              color={COLORS.colorTypographyHeadings}
              className={css.leftSideText}
            >
              Customer pays:
            </Typography>
            <Typography
              lineHeight="162.5%"
              fontType="bodyMd"
              color={COLORS.colorTypographyHeadings}
            >
              {Price.formatPrice(1000)}
            </Typography>
          </div>
        </section>
        <section
          className={cn(css.greyBlock, css.swagSpaceGreyBlock, css.swagSpaceCostsBlock)}
        >
          <Typography
            lineHeight="162.5%"
            fontType="bodyMdBold"
            color={COLORS.colorTypographyHeadings}
          >
            Costs to close a deal
          </Typography>
          <Divider color={COLORS.colorDivider} margin={12} className={css.divider} />
          <div className={css.contentRow}>
            <Typography
              lineHeight="162.5%"
              fontType="bodyMd"
              color={COLORS.colorTypographyHeadings}
              className={css.leftSideText}
            >
              Product cost:
            </Typography>
            <Typography
              lineHeight="162.5%"
              fontType="bodyMd"
              color={COLORS.colorErrorText}
            >
              -{Price.formatPrice(600)}
            </Typography>
          </div>
          <Divider
            color={COLORS.colorTabsSelection}
            margin={12}
            className={css.divider}
          />
          <div className={css.contentRow}>
            <Typography
              lineHeight="162.5%"
              fontType="bodyMd"
              color={COLORS.colorTypographyHeadings}
              className={css.leftSideText}
            >
              Swag Space take:
            </Typography>
            <Typography
              lineHeight="162.5%"
              fontType="bodyMd"
              color={COLORS.colorErrorText}
            >
              -{Price.formatPrice(100)}
            </Typography>
          </div>
          <br />
        </section>
        <section className={cn(css.greyBlock, css.swagSpaceGreyBlock, css.iconsBlock)}>
          <span className={css.icons}>🚀 😀</span>
          <Typography
            lineHeight="162.5%"
            fontType="bodyMdBold"
            color={COLORS.colorTypographyHeadings}
          >
            Thousands of hours saved!
          </Typography>
        </section>
        <section className={cn(css.greyBlock, css.swagSpaceGreyBlock)}>
          <div className={css.contentRow}>
            <Typography
              lineHeight="162.5%"
              fontType="bodyMd"
              color={COLORS.colorTypographyHeadings}
              className={css.leftSideText}
            >
              You make:
            </Typography>
            <Typography
              lineHeight="162.5%"
              fontType="bodyMd"
              color={COLORS.colorSuccess700}
            >
              {Price.formatPrice(300)}
            </Typography>
          </div>
        </section>
      </RainbowBox>
    </div>
  );
}
