export enum ORDER_ITEMS_TYPES {
  BOX = 'BOX',
  REGULAR = 'REGULAR',
  SPECIAL_BOX_PRODUCT = 'SPECIAL_BOX_PRODUCT',
}

export enum CLIENT_STATUSES {
  MOCKUP_SENT_TO_RESELLER = 'Mockup sent to reseller',
  RESELLER_REQUESTED_REVISIONS = 'Requested revisions',
  REQUESTED_COLORS_REVIEW_BY_RESELLER = 'Requested colors review',
  REQUIRES_ATTENTION = 'Requires attention',
  REVIEW_COLORS = 'Requested colors review by Customer',
  PENDING_APPROVAL = 'Pending approval',
  VIEW_PRODUCTION_MOCKUP = 'Mockup sent to Customer',
  REQUESTED_REVISIONS = 'Customer requested revisions',
  CREATING_PRODUCTION_MOCKUP = 'Creating production mockup',
  PROCESSING_ORDER = 'Processing order',
  IN_PRODUCTION = 'In production',
  TRACKING_NUMBER = 'Tracking number',
  DELIVERED = 'Delivered',
  DELIVERED_TO_WAREHOUSE = 'Delivered to Warehouse',
  READY_FOR_DISTRIBUTION = 'Ready for distribution',
  CANCELLED = 'Cancelled',
  REJECTED = 'Rejected',
}

export enum PAYMENT_METHODS {
  DUMMY = 'Dummy payment',
}
