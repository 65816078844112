import cn from 'classnames';
import {
  Typography,
  WhiteBox,
  List,
  ListHeader,
  ListItem,
  Divider,
} from '@ezetech/swag-space-x';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { CopyIcon } from 'components/svg/copy';
import { NotificationType } from 'redux/slices/notifications.slice';
import { useClipboard } from 'hooks/use-clipboard.hook';
import { useNotifications } from 'hooks/notifications.hook';

import { LANDING_URL } from '../../utils/urls.util';
import css from './refer-a-member.module.scss';

export function ReferralLinkBlock({
  className,
  linkSuffix,
}: {
  className: string;
  linkSuffix: string;
}): JSX.Element {
  const { pushNotification } = useNotifications();
  const { copy } = useClipboard();
  const url = `${LANDING_URL}?referrals=${linkSuffix}`;
  const handleCopyClick = async () => {
    const isSuccess = await copy(url);

    if (isSuccess) {
      pushNotification({
        status: 'positive',
        message: 'Referral Link has been copied.',
        type: NotificationType.toasts,
      });
    }
  };

  return (
    <WhiteBox className={cn(css.root, className)}>
      <List>
        <ListHeader className={css.header}>
          <Typography
            lineHeight="162%"
            fontType="bodyMdBold"
            color={COLORS.colorTextSubdued}
          >
            Referral Link
          </Typography>
        </ListHeader>
        <Divider />
        <ListItem className={css.value}>
          <Typography lineHeight="100%" fontType="bodyMd" color={COLORS.colorPrimaryText}>
            For every person referred that becomes a member, we will give you percentage
            of their sales. You can earn 1% of the default pricing up to $1M of sales. The
            max per referral you can make is $10k. Send your link to your friends and
            others who you think will get value from being a Swag Space member.
          </Typography>
        </ListItem>
        <Divider />
        <ListItem className={css.percent}>
          <div>
            <div className={css.storeNameLabel}>Store Link*</div>
            <Typography
              className={css.storeName}
              lineHeight="100%"
              fontType="bodyMd"
              color={COLORS.colorTypographyBodyGreyed}
            >
              <label className={css.inputSizer}>
                <input
                  type="text"
                  value={url}
                  className={cn(css.storePrefixName)}
                  disabled
                  placeholder="https://swag.space/*******"
                />
              </label>
              <div onClick={handleCopyClick} className={css.copy}>
                <CopyIcon fillColor={COLORS.colorAccent500} />
              </div>
            </Typography>
          </div>
        </ListItem>
      </List>
    </WhiteBox>
  );
}
