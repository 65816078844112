import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  IUser,
  UserLoginType,
  UserCreatePasswordType,
  GenericResponseType,
  ReducerPath,
  UserResetPasswordType,
  UserPasswordRecoveryType,
  UserCheckTokenType,
  LoginAsAUserType,
  UserChangePasswordType,
} from 'interfaces';
import { makeSuccessToastNotification, makeToastNotification } from 'utils/query.util';

import { setWhatWeOfferSettings } from 'redux/slices/settings.slice';
import { logout } from '../slices/user.slice';
import { pushNotification } from '../slices/notifications.slice';
import { setWithdrawData } from '../slices/withdraw.slice';
import { baseQueryParams } from './helpers';
import { userApi } from './user.api';
import { storeSettingsApi } from './store-creation.api';

export const authApi = createApi({
  reducerPath: ReducerPath.auth,
  baseQuery: fetchBaseQuery({
    ...baseQueryParams,
  }),
  endpoints: (builder) => ({
    login: builder.mutation<IUser, UserLoginType>({
      query: (payload) => ({
        url: '/auth/login',
        method: 'POST',
        body: payload,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          await dispatch(userApi.endpoints.getUserNoCache.initiate(null));
          await Promise.all([
            dispatch(storeSettingsApi.endpoints.getCategories.initiate()),
            dispatch(storeSettingsApi.endpoints.getIsOnboardingCompleted.initiate()),
            dispatch(storeSettingsApi.endpoints.getStoreSettings.initiate()),
          ]);
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),
    logout: builder.mutation<string, void>({
      query: () => ({
        url: '/auth/logout',
        method: 'GET',
        responseHandler: 'text',
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(logout());
          dispatch(setWithdrawData({ available: 0, incoming: 0, pending: 0 }));
          dispatch(setWhatWeOfferSettings({}));
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),
    createPassword: builder.mutation<GenericResponseType, UserCreatePasswordType>({
      query: (payload) => ({
        url: '/auth/set-password',
        method: 'POST',
        body: {
          password: payload.password,
          token: payload.token,
          invitation: payload.invitation,
        },
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),
    getCSRFToken: builder.mutation<{ csrfToken: string }, null>({
      query: () => ({
        url: '/auth/csrf/token',
        method: 'GET',
      }),
    }),
    resetPassword: builder.mutation<GenericResponseType, UserResetPasswordType>({
      query: (payload) => ({
        url: '/auth/forgot-password',
        method: 'POST',
        body: {
          email: payload.email,
        },
      }),
    }),
    passwordRecovery: builder.mutation<GenericResponseType, UserPasswordRecoveryType>({
      query: (payload) => ({
        url: '/auth/reset-password',
        method: 'POST',
        body: {
          password: payload.password,
          token: payload.token,
        },
      }),
    }),
    loginAsAUser: builder.query<void, LoginAsAUserType>({
      query: (payload) => ({
        url: '/auth/login-as-user',
        method: 'GET',
        params: {
          id: payload.userId,
          context: JSON.stringify(payload.context),
        },
      }),
    }),
    checkToken: builder.mutation<GenericResponseType, UserCheckTokenType>({
      query: (payload) => ({
        url: '/auth/check-token',
        method: 'POST',
        body: {
          token: payload.token,
        },
      }),
    }),
    changePassword: builder.mutation<{ isSuccess: boolean }, UserChangePasswordType>({
      query: (payload) => ({
        url: '/auth/change-password',
        method: 'POST',
        body: {
          ...payload,
        },
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            pushNotification(
              makeSuccessToastNotification('Password successfully changed!'),
            ),
          );
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),
  }),
});
export const {
  useLoginMutation,
  useLogoutMutation,
  useCreatePasswordMutation,
  useResetPasswordMutation,
  usePasswordRecoveryMutation,
  useCheckTokenMutation,
  useLazyLoginAsAUserQuery,
  useChangePasswordMutation,
} = authApi;
