import React, { useState, useRef } from 'react';
import cn from 'classnames';
import { Image, Typography, Checkbox } from '@ezetech/swag-space-x';
import {
  IHomepageProductEditor,
  IHomepageSelectedProduct,
} from 'interfaces/products.interface';
import { useAppSelector } from 'redux/store';
import { isSelectionDisabledSelector } from 'redux/selectors/settings.selectors';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import RemoveIcon from 'assets/svg/red-remove-icon.svg';
import imagePlaceholder from 'assets/svg/image-placeholder.svg';
import css from './search-products.module.scss';

interface IProductRow {
  isSearch?: boolean;
  product:
    | IHomepageProductEditor
    | (IHomepageSelectedProduct & { isForbiddenToShowOnDashboard?: boolean });
  onProductClick?(id: string, value: IHomepageSelectedProduct): void;
  onRemoveClick?(id: string): void;
}

export const Product: React.FC<IProductRow> = ({
  product,
  onProductClick,
  onRemoveClick,
  isSearch = true,
}) => {
  const { logo, publicId, name, isForbiddenToShowOnDashboard } = product;
  const isChecked = (product as IHomepageProductEditor).isChecked;
  const checkbox = useRef<HTMLButtonElement>(null);
  const [productImage, setProductImage] = useState(logo);
  const isSelectionDisabled = useAppSelector(isSelectionDisabledSelector);

  const ignoreCheck =
    (isSelectionDisabled && !isChecked) ||
    (isForbiddenToShowOnDashboard && !isChecked) ||
    !isSearch ||
    !onProductClick;

  const handleRowClick = (e: React.SyntheticEvent) => {
    if (ignoreCheck) {
      return;
    }

    if (
      e.currentTarget !== e.target &&
      !(
        (e.target as HTMLElement).className.includes('image') ||
        (e.target as HTMLElement).className.includes('typography') ||
        (e.target as HTMLElement).className.includes('name')
      )
    ) {
      return;
    }

    onProductClick(publicId, { publicId, logo, name });
  };

  const handleCheck = () => {
    if (ignoreCheck) {
      return;
    }

    onProductClick(publicId, { publicId, logo, name });
  };

  const handleImageError = () => {
    setProductImage(imagePlaceholder);
  };

  const handleRemoveClick = () => {
    if (!onRemoveClick) {
      return;
    }

    onRemoveClick(product.publicId);
  };

  return (
    <div
      onClick={handleRowClick}
      className={cn(css.product, {
        [css.result]: !isSearch,
        [css.greyedOut]: isForbiddenToShowOnDashboard,
      })}
    >
      {isSearch && (
        <Checkbox
          ref={checkbox}
          rootClassName={cn(css.checkbox, {
            [css.productDisabled]: isForbiddenToShowOnDashboard && !isChecked,
          })}
          name={publicId}
          checked={isChecked}
          onCheckedChange={handleCheck}
          disabled={
            (isSelectionDisabled && !isChecked) ||
            (isForbiddenToShowOnDashboard && !isChecked)
          }
        />
      )}
      <Image
        onError={handleImageError}
        wrapperClassName={cn(css.image, {
          [css.productDisabled]: isForbiddenToShowOnDashboard,
        })}
        loadingClassName={css.loader}
        alt={name}
        src={productImage}
      />
      <Typography
        lineHeight="142.857%"
        fontType="bodySm"
        color={COLORS.colorPrimaryText}
        className={cn(css.name, {
          [css.productDisabled]: isForbiddenToShowOnDashboard,
          [css.nameWithTrashIcon]: !isSearch,
        })}
      >
        <div className={css.nameInner}>{name}</div>
      </Typography>
      {!isSearch && (
        <img
          className={cn(css.remove, {
            [css.productDisabled]: isForbiddenToShowOnDashboard,
          })}
          alt="Remove button"
          src={RemoveIcon}
          onClick={handleRemoveClick}
        />
      )}
    </div>
  );
};
