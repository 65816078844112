import { FC } from 'react';
import { Loader, Pagination } from '@ezetech/swag-space-x';
import classNames from 'classnames';
import { deepGet } from 'utils/deep-get.util';
import classes from '../edit-pdf-presentation-layout-popup.module.scss';
import { PreviewProps } from '../interfaces/components';

import '@ezetech/swag-presentation-templates/dist/utils/register-fonts';
import { usePreviewWidth } from '../hooks/use-preview-width';
import { useIsMobileView } from '../../../../hooks/is-mobile-view.hook';

export const Preview: FC<PreviewProps> = ({
  pagination,
  children,
  visible,
  pageTemplate,
  values,
  isPdfRendering,
  imagesOfPdfPages,
}) => {
  const previewWidth = usePreviewWidth();
  const isMobile = useIsMobileView(1024);
  const isHidden =
    pageTemplate && pageTemplate.isCanBeHidden && pageTemplate.isHiddenKeyName
      ? deepGet(values as never, pageTemplate.isHiddenKeyName, false)
      : false;

  const handleGoToPage = (page: number) => {
    const templateId =
      page < pagination.currentPage
        ? pagination.prevTemplateName
        : pagination.nextTemplateName;
    if (templateId) {
      pagination.onChangePage(templateId);
    }
  };
  const imageStyle = { width: `${previewWidth}px` };

  return (
    <div
      className={classNames(classes.previewSection, {
        [classes.hidden]: visible,
      })}
    >
      <div id="main-preview-wrapper" className={classes.previewSectionPageWrapper}>
        {isHidden && (
          <div className={classes.previewSectionOverflow}>
            <div className={classes.previewSectionHidden} />
          </div>
        )}
        {isPdfRendering && (
          <div className={classes.previewSectionOverflow}>
            <div className={classes.previewSectionLoader}>
              <Loader />
            </div>
          </div>
        )}
        {imagesOfPdfPages?.length ? (
          <div className={classes.previewSectionDocument}>
            <img
              src={imagesOfPdfPages[pagination.currentPage - 1].dataUrl}
              className={classes.previewSectionPage}
              style={imageStyle}
            />
          </div>
        ) : null}
        {!isMobile && children}
      </div>
      <Pagination
        arrowClassName={classNames({ [classes.blockedPaginationEvent]: isPdfRendering })}
        page={pagination.currentPage}
        lastPage={pagination.total}
        goToPage={handleGoToPage}
        paginationClassName={classes.pagesSidebarPagination}
      />
      {isMobile && children}
    </div>
  );
};
