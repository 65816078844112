import { FC } from 'react';
import { Typography } from '@ezetech/swag-space-x';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { makeLink } from '../sign-contract.util';
import css from '../sign-contract.module.scss';

export const TermAndTermination: FC = () => {
  return (
    <>
      <Typography color={COLORS.colorPrimaryText} fontType="bodyMdBold">
        11. Term and Termination
      </Typography>
      <br />
      <br />
      These Terms will become effective and binding when you use the Site, when you apply
      as a Reseller, or when you indicate your agreement by following any instructions we
      place on the Site (such as buttons labeled “Submit”) and will continue until
      terminated by us or you. If you wish to stop using the Services, please provide us
      ten (10) days’ prior written notice, submitted to:{' '}
      {makeLink('contact@swag.space', 'mailto:contact@swag.space')}. We may suspend or
      terminate your Account, your Branded Storefront or these Terms and your access to
      the Services at any time without notice at any time for any reason without
      liability. Your rights under these Terms will automatically and immediately
      terminate if you fail to comply with your promises and obligations stated in these
      Terms. Swag Space reserves the right to decide whether Content violates these Terms.
      Swag Space may at any time, without prior notice and in its sole discretion, remove
      such Content and/or terminate your Account for submitting such material in violation
      of these Terms. All provisions that logically ought to survive termination of these
      Terms shall survive the termination of these Terms.
      <br />
      <br />
      Upon termination of the use of the Services by either party for any reason:
      <br />
      <br />
      <ul className={css.listPoint}>
        <li className={css.listItem}>
          Swag Space will cease providing you with access to the Services and you will no
          longer be able to access your Account;
        </li>
        <li className={css.listItem}>
          unless otherwise provided in the Terms, you will not be entitled to any refunds
          of any fees, pro rata or otherwise;
        </li>
        <li className={css.listItem}>
          any outstanding balance owed to you or becoming due, will paid to you;
        </li>
        <li className={css.listItem}>
          any outstanding balance owed to Swag Space through the effective date of
          termination will immediately become due and payable in full; and
        </li>
        <li className={css.listItem}>your Branded Storefront will be taken offline.</li>
      </ul>
      <br />
    </>
  );
};
