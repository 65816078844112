import { useState } from 'react';
import {
  Dialog,
  DialogContentWrapper,
  DialogTitle,
  DialogDescription,
  DialogContent,
  DialogFooter,
  SwagButton,
  Typography,
  OptionType,
  Dropzone,
  Loader,
  IFile,
  Select,
} from '@ezetech/swag-space-x';
import {
  RESELLER_CERTIFICATE_DOCUMENT_MB_RESTRICTION,
  RESELLER_CERTIFICATE_DOCUMENT_ACCEPT_RESTRICTION,
} from 'constants/limits';
import { useAppDispatch } from 'redux/store';
import { closePopup } from 'redux/slices/modals.slice';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { getStates } from 'constants/settings.constants';
import {
  useUploadCertificateDocumentMutation,
  useCreateCertificateMutation,
} from 'redux/api/reseller-certificates.api';
import { IResellerCertificateUploadPopupProps } from 'interfaces/popup.interface';
import css from '../common/popup-with-upload-common.module.scss';

export const UploadResellerCertificatePopup = ({
  id,
  isOpen,
  onSubmit,
}: IResellerCertificateUploadPopupProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const [uploadCertificateDocument, { isLoading: isUploading }] =
    useUploadCertificateDocumentMutation();
  const [createCertificate, { isLoading: isCreation }] = useCreateCertificateMutation();

  const [file, setFile] = useState<IFile>();
  const [state, setState] = useState<string>();

  const handleProcessFile = async (pdfFile: File): Promise<IFile> => {
    const formData = new FormData();
    formData.set('file', pdfFile);

    const response = await uploadCertificateDocument(formData);

    if ('data' in response) {
      const { data } = response;

      return data;
    } else {
      throw new Error('Upload failed');
    }
  };
  const onBtnCloseClick = () => {
    if (isUploading || isCreation) {
      return;
    }
    dispatch(closePopup());
  };

  const onBtnSubmitClick = async () => {
    if (file) {
      await createCertificate({ document: file, state: state || '' });
      onSubmit && onSubmit();
      dispatch(closePopup());
    }
  };

  const handleFileChange = (files: IFile[]) => {
    const fileData = files[0] ?? null;
    setFile(fileData);
  };

  const handleStateChange = (value: string) => {
    setState(value);
  };
  const availableStates = getStates();
  const stateValue = availableStates.find(({ value }) => value === state) ?? null;

  return (
    <Dialog open={isOpen} onOpenChange={onBtnCloseClick} key={id} loading={true}>
      <DialogContentWrapper
        className={css.dialogContentWrapperClassName}
        overlayClassName={css.dialogOverlayClassName}
      >
        {isCreation && (
          <div className={css.overlay}>
            <Loader />
          </div>
        )}
        <DialogTitle>Submit Reseller certificate</DialogTitle>
        <DialogDescription>
          <Typography
            color={COLORS.colorTextSubdued}
            fontType="bodyMd"
            lineHeight="162.5%"
          >
            Please upload your tax resale certificates. First select the state and upload
            the corresponding certificate. Once uploaded, we will review. The review
            process takes up to 48 hours.
          </Typography>
        </DialogDescription>
        <DialogContent>
          <Select
            options={availableStates}
            className={css.inputSelect}
            label="Select state*"
            name="state"
            placeholder="Select state"
            value={stateValue}
            onChange={(value) => handleStateChange((value as OptionType).value)}
            skipError
          />

          <Dropzone
            accept={RESELLER_CERTIFICATE_DOCUMENT_ACCEPT_RESTRICTION}
            maxSize={RESELLER_CERTIFICATE_DOCUMENT_MB_RESTRICTION}
            files={file ? [file] : []}
            processFile={handleProcessFile}
            onFilesChanged={handleFileChange}
            additionalText="JPG, PNG or PDF, no more than 10MB"
          />
        </DialogContent>
        <DialogFooter className={css.footerClassName}>
          <SwagButton
            className={css.button}
            type="outlined"
            onClick={onBtnCloseClick}
            size="large"
            disabled={isUploading || isCreation}
          >
            Cancel
          </SwagButton>
          <SwagButton
            className={css.button}
            type="primary"
            onClick={onBtnSubmitClick}
            disabled={isUploading || isCreation || !state || !file?.key}
            size="large"
          >
            Submit
          </SwagButton>
        </DialogFooter>
      </DialogContentWrapper>
    </Dialog>
  );
};
