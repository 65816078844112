import {
  Dialog,
  DialogContentWrapper,
  DialogTitle,
  DialogDescription,
  DialogFooter,
  SwagButton,
  Typography,
  Divider,
} from '@ezetech/swag-space-x';
import { ChangePaymentMethodInformationPopupProps } from 'interfaces/popup.interface';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { closePopup } from 'redux/slices/modals.slice';
import { useUpdateUserMutation } from 'redux/api/user.api';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { editedCustomerCompanyDetailsSelector } from 'redux/selectors/customer-details.selector';
import css from './change-payment-method-information.popup.module.scss';

export const ChangePaymentMethodInformationPopup = ({
  isOpen,
  title,
  description,
  paymentMethod,
  setPaymentMethod,
}: ChangePaymentMethodInformationPopupProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(editedCustomerCompanyDetailsSelector);
  const [updateUser] = useUpdateUserMutation();

  const onCloseClick = () => {
    dispatch(closePopup());
  };

  const onContinueClick = async () => {
    if (paymentMethod) {
      await updateUser({
        id: user.userId,
        paymentMethod,
        setPaymentMethod,
      });
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onCloseClick}>
      <DialogContentWrapper
        className={css.dialogContentWrapperClassName}
        overlayClassName={css.dialogOverlayClassName}
      >
        <DialogTitle className={css.title} showDivider={false}>
          {title}
        </DialogTitle>
        <Divider className={css.divider} />
        <DialogDescription className={css.textWrapper}>
          <Typography color={COLORS.colorTextSubdued} fontType="bodyMd">
            {description}
          </Typography>
        </DialogDescription>
        <Divider className={css.divider} />
        <DialogFooter className={css.footer} showDivider={false}>
          <div className={css.controlButtons}>
            <SwagButton type="outlined" onClick={onCloseClick} className={css.btn}>
              Cancel
            </SwagButton>
            <SwagButton type="primary" onClick={onContinueClick} className={css.btn}>
              Yes, continue
            </SwagButton>
          </div>
        </DialogFooter>
      </DialogContentWrapper>
    </Dialog>
  );
};
