import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { shutdown, boot } from '@intercom/messenger-js-sdk';
import { IUser } from 'interfaces';
import { updateAbility } from 'boot/ability';

interface IUserState {
  profile: IUser | null;
  logoutExecuted: boolean;
  loginRedirectUrl: string | null;
  invitation: string | null;
}

const initialState: IUserState = {
  profile: null,
  logoutExecuted: false,
  loginRedirectUrl: null,
  invitation: null,
};

export const userSlice = createSlice({
  initialState,
  name: 'userSlice',
  reducers: {
    logout: () => {
      updateAbility([]);
      // reset intercom chat
      shutdown();
      return { ...initialState, logoutExecuted: true };
    },
    setUser: (state, { payload }: PayloadAction<IUser | null>) => {
      state.profile = payload;
      updateAbility([]);
      updateAbility(state.profile?.permissions, state.profile?.isOwner);
      const appId = process.env.REACT_APP_INTERCOM_APP_ID;
      // boot intercom chat
      if (payload && appId) {
        boot({
          app_id: appId,
          user_id: payload.id,
          name: payload.firstName,
          email: payload.email,
          created_at: new Date(payload.createdAt).valueOf(),
          company: {
            id: payload?.companyId,
            name: payload.company?.name,
          },
        });
      }
    },
    setLoginRedirectUrl: (state, action: PayloadAction<string | null>) => {
      state.loginRedirectUrl = action.payload;
    },
    resetRedirectOptions: (state) => {
      state.loginRedirectUrl = null;
      state.logoutExecuted = false;
    },
    setInvitation: (state, action: PayloadAction<string | null>) => {
      state.invitation = action.payload;
    },
  },
});

export const userReducer = userSlice.reducer;
export const {
  logout,
  setUser,
  setLoginRedirectUrl,
  resetRedirectOptions,
  setInvitation,
} = userSlice.actions;
