import * as yup from 'yup';
import { ERROR_MSG } from 'constants/validation';

export const FIELDS = {
  password: {
    label: 'Your password',
    name: 'password',
    placeholder: 'Enter Password',
  },

  passwordRepeat: {
    label: 'Repeat your password',
    name: 'passwordRepeat',
    placeholder: 'Enter Password',
  },
};

export const createPasswordFormSchema = yup.object({
  [FIELDS.password.name]: yup.string().required(ERROR_MSG.required),
  [FIELDS.passwordRepeat.name]: yup
    .string()
    .oneOf([yup.ref(FIELDS.password.name)], ERROR_MSG.passwordsMatch)
    .required(ERROR_MSG.required),
});
