import { FC } from 'react';
import { Input, TextField } from '@ezetech/swag-space-x';
import { PdfPresentationEditorPageFieldDefinitionComponentFuncProps } from 'interfaces/pdf-presentation';
import { deepGet } from 'utils/deep-get.util';
import classes from '../../edit-pdf-presentation-layout-popup.module.scss';

export const ThankYouForm: FC<
  PdfPresentationEditorPageFieldDefinitionComponentFuncProps
> = ({ values, onChangeValueCreator }) => {
  return (
    <div className={classes.thankYouForm}>
      <Input
        label="Phone"
        placeholder="Enter phone number"
        value={deepGet(values as never, 'contactPhone', '')}
        onChange={({ target: { value } }) =>
          onChangeValueCreator('contactPhone')(value.slice(0, 150))
        }
      />
      <Input
        label="Email"
        placeholder="Enter email"
        value={deepGet(values as never, 'contactEmail', '')}
        onChange={({ target: { value } }) =>
          onChangeValueCreator('contactEmail')(value.slice(0, 150))
        }
      />
      <TextField
        name="address"
        label="Address"
        placeholder="Enter address"
        value={deepGet(values as never, 'contactAddress', '')}
        onChange={({ target: { value } }) =>
          onChangeValueCreator('contactAddress')(value.slice(0, 150))
        }
        {...{ rows: 4 }}
      />
    </div>
  );
};
