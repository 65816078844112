import CodeMirror, { ReactCodeMirrorProps } from '@uiw/react-codemirror';
import { html } from '@codemirror/lang-html';
import { InputWrapper } from '@ezetech/swag-space-x';

import css from './code-editor.module.scss';

interface IReactCodeMirrorProps extends Omit<ReactCodeMirrorProps, 'extensions'> {
  label?: string;
}

const defaultBasicSetup = {
  lineNumbers: true,
  foldGutter: false,
};

export const CodeEditor = ({
  basicSetup = defaultBasicSetup,
  label,
  ...props
}: IReactCodeMirrorProps) => {
  return (
    <InputWrapper
      dataInputWrapperLabel={label}
      label={label}
      inputWrapperClassName={css.root}
      skipError={true}
    >
      <CodeMirror
        readOnly={props.readOnly}
        extensions={[html()]}
        basicSetup={defaultBasicSetup}
        className={css.codeMirrorRoot}
        {...props}
      />
    </InputWrapper>
  );
};
