import { ColorType, ButtonColorType } from 'interfaces/homepage-settings.interface';

const BUTTON_COLOR_TYPE_OBJECT = Object.entries(ButtonColorType).reduce<
  Record<string, string>
>((acc, entry) => {
  acc[entry[0]] = entry[1];
  return acc;
}, {});

export const BUTTON_COLOR_TYPE_OPTIONS = Object.keys(ButtonColorType).map((key) => {
  return {
    value: BUTTON_COLOR_TYPE_OBJECT[key],
    label: key,
  };
});

const COLOR_TYPE_OBJECT = Object.entries(ColorType).reduce<Record<string, string>>(
  (acc, entry) => {
    acc[entry[0]] = entry[1];
    return acc;
  },
  {},
);

export const COLOR_TYPE_OPTIONS = Object.keys(ColorType).map((key) => {
  return {
    value: COLOR_TYPE_OBJECT[key],
    label: key,
  };
});
