import { Typography } from '@ezetech/swag-space-x';
import COLORS from 'constants/styles/colors-js.constant.module.scss';

import { ArrowDownSmallIcon } from 'components/svg/arrow-down-small';
import { ArrowUpSmallIcon } from '../svg';
import { TrendDirectionInlineProps } from './trend-direction-inline.type';

import css from './trend-direction-inline.module.scss';

export function TrendDirectionInline({
  percent,
  text = 'up from yesterday',
}: TrendDirectionInlineProps): JSX.Element {
  return (
    <div className={css.root}>
      <Typography fontType="bodyMd" lineHeight="162%" color={COLORS.colorTextSubdued}>
        {percent}% {text}
      </Typography>
      {Number(percent) >= 0 ? (
        <ArrowUpSmallIcon className={css.icon} />
      ) : (
        <ArrowDownSmallIcon className={css.icon} />
      )}
    </div>
  );
}
