import { useEffect, useState } from 'react';
import cn from 'classnames';
import { Link, useLocation } from 'react-router-dom';

import { Typography, Select } from '@ezetech/swag-space-x';
import { useAppDispatch } from 'redux/store';
import { openPopup } from 'redux/slices/modals.slice';
import { can, cannot, canCombined, SUBJECTS } from 'boot/ability';
import { WITHDRAW_POPUP } from 'components/popups/_logic/popups-list';
import { NAV_OPTIONS } from 'constants/router';
import ChevronUp from 'assets/svg/chevron-up.svg';
import ChevronDown from 'assets/svg/chevron-down.svg';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { WithdrawWidget } from './withdraw.component';

import css from './nav-bar.module.scss';
import { NavBarProps } from './index';

export function NavBarComponent({ withContractBar = false }: NavBarProps): JSX.Element {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const currentStepRoute = location.pathname;
  const [currentIndex, setCurrentIndex] = useState<number | null>(null);
  const [currentOptionName, setCurrentOptionName] = useState<string | null>(null);
  const [filteredNav, setFilteredNav] = useState<typeof NAV_OPTIONS>([]);

  const NAV_OPTIONS_MOBILE = filteredNav.map((option) => {
    return {
      label: option.label,
      value: option.link,
    };
  });

  useEffect(() => {
    setFilteredNav(
      NAV_OPTIONS.filter((nav) => {
        const result = canCombined(nav.link);
        return result;
      }),
    );
  }, NAV_OPTIONS);

  useEffect(() => {
    const res = filteredNav.reduce<number>((result, option, index) => {
      if (option.link === currentStepRoute) {
        result = index;
      }
      return result;
    }, -1);

    setCurrentIndex(res);
    setCurrentOptionName(filteredNav[res]?.label ?? null);
  }, [currentStepRoute]);

  const onWithdrawClick = () => {
    dispatch(openPopup({ popupName: WITHDRAW_POPUP, popupProps: {} }));
  };
  const hideWithdrawActionBtn = cannot(
    SUBJECTS.DO_WITHDRAW.actions.DO_WITHDRAW_USE,
    SUBJECTS.DO_WITHDRAW.value,
  );

  return (
    <>
      <div
        className={cn(css.navBar, {
          [css.navBarWithContractBar]: withContractBar,
        })}
      >
        <div className={css.navBarWrapper}>
          <nav className={css.navOptions}>
            {filteredNav.map((option, index) => (
              <div className={css.link} key={option.label}>
                <Link to={option.link} className={css.unlink}>
                  <Typography
                    lineHeight="162%"
                    className={cn(css.navOption, {
                      [css.navOptionSelected]: index === currentIndex,
                    })}
                    fontType="bodyMdBold"
                    color={COLORS.colorPrimaryText}
                    link
                  >
                    {option.label}
                  </Typography>
                </Link>
              </div>
            ))}
          </nav>
          <Select
            name="nav-options"
            options={NAV_OPTIONS_MOBILE}
            value={null}
            maxMenuHeight={400}
            className={css.navOptionsMobile}
            components={{
              DropdownIndicator: (props) => {
                return (
                  <div {...props}>
                    {props.selectProps.menuIsOpen ? (
                      <img src={ChevronUp} alt="chevron up" />
                    ) : (
                      <img src={ChevronDown} alt="chevron down" />
                    )}
                  </div>
                );
              },
              Placeholder: (props) => (
                <div {...props}>
                  <Typography fontType="bodyMdBold" color={COLORS.colorDarkBlue}>
                    {currentOptionName}
                  </Typography>
                </div>
              ),
              Control: ({ children, ...props }) => (
                <div
                  className={css.navOptionsMobileControl}
                  {...props}
                  {...props.innerProps}
                >
                  {children}
                </div>
              ),
              ValueContainer: ({ children, ...props }) => (
                <div className={css.navOptionsMobileValueContainer} {...props}>
                  {children}
                </div>
              ),
              Menu: ({ children, ...props }) => (
                <div
                  className={css.navOptionsMobileMenu}
                  {...props}
                  {...props.innerProps}
                >
                  {children}
                </div>
              ),
              Option: ({ children, data, ...props }) => (
                <div
                  className={css.navOptionsMobileOption}
                  {...props}
                  {...props.innerProps}
                >
                  <Link className={css.unlink} to={data!.value}>
                    {children}
                  </Link>
                </div>
              ),
            }}
          />
          {can(
            SUBJECTS.WITHDRAW_VIEW.actions.WITHDRAW_VIEW,
            SUBJECTS.WITHDRAW_VIEW.value,
          ) && (
            <WithdrawWidget
              hideActionBtn={hideWithdrawActionBtn}
              withContractBar={withContractBar}
              onWithdrawClick={onWithdrawClick}
              classNameWrapperClassName={css.withdrawSectionWrapper}
              incomingAmountLineHeight="100%"
              availableToWithdrawAmountLineHeight="100%"
              availableToWithdrawAmountFont="HEADING6XL"
              middleWithdrawSectionClassName={css.middleWithdrawSection}
              availableToWithdrawLinkLineHeight="123%"
              pendingAmountLineHeight="100%"
              incomingTextFont="bodySm"
              incomingAmountFont="bodySmBold"
              incomingTextLineHeight="143%"
              availableToWithdrawTextLineHeight="143%"
              availableToWithdrawTextFont="bodySm"
              pendingTextLineHeight="143%"
              pendingTextFont="bodySm"
              pendingAmountFont="bodySmBold"
            />
          )}
        </div>
      </div>

      {can(
        SUBJECTS.WITHDRAW_VIEW.actions.WITHDRAW_VIEW,
        SUBJECTS.WITHDRAW_VIEW.value,
      ) && (
        <WithdrawWidget
          hideActionBtn={hideWithdrawActionBtn}
          withContractBar={withContractBar}
          onWithdrawClick={onWithdrawClick}
          classNameWrapperClassName={css.withdrawMobileSectionWrapper}
          middleWithdrawSectionClassName={css.withdrawMobileSectionMiddleWrapper}
          incomingAmountLineHeight="143%"
          availableToWithdrawAmountLineHeight="143%"
          availableToWithdrawAmountFont="bodySmBold"
          availableToWithdrawLinkLineHeight="143%"
          pendingAmountLineHeight="143%"
          incomingAmountFont="bodySmBold"
          incomingTextLineHeight="143%"
          incomingTextFont="bodySm"
          availableToWithdrawTextLineHeight="143%"
          availableToWithdrawTextFont="bodySm"
          pendingTextLineHeight="143%"
          pendingTextFont="bodySm"
          pendingAmountFont="bodySmBold"
        />
      )}
    </>
  );
}
