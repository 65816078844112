import { useState } from 'react';
import * as yup from 'yup';
import { SwagButton } from '@ezetech/swag-space-x';
import { useForm, SubmitHandler, FieldValues } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { InputForm } from 'components/input-form';
import { SelectForm } from 'components/select-from';
import { ApplicationSignUpType } from 'interfaces/auth-api.interface';
import { isRequired } from 'utils/yup.util';
import {
  FIELDS,
  WHAT_INDUSTRY_OPTIONS,
  COMPANY_REVENUE_OPTIONS,
  HOW_MANY_YEARS_OPTIONS,
} from '../signup.constants';

import { signUpFormSchema } from '../signup.schema';
import { SignUpFormProps } from '../signup.interface';

import css from './signup-form.module.scss';

export function SignUpForm(props: SignUpFormProps<ApplicationSignUpType>) {
  const { onSubmit: onFormSubmit, loading } = props;
  const { handleSubmit, control, formState, getValues, setValue, clearErrors } =
    useForm<FieldValues>({
      mode: 'onTouched',
      resolver: yupResolver(signUpFormSchema),
    });
  const { isValid } = formState;
  const [requiredFields, setRequiredFields] = useState<
    Record<string, yup.SchemaFieldDescription>
  >(signUpFormSchema.describe().fields);

  const [companyRevenueClassName, setCompanyRevenueClassName] = useState(
    isRequired(requiredFields[FIELDS.companyRevenue.name]) ? css.show : css.hide,
  );

  const [certificateClassName, setCertificateClassName] = useState(
    isRequired(requiredFields[FIELDS.certificate.name]) ? css.show : css.hide,
  );

  const handleOnChange = () => {
    const { fields } = signUpFormSchema.describe({
      value: {
        [FIELDS.howManyYears.name]: getValues(FIELDS.howManyYears.name),
        [FIELDS.whatIndustry.name]: getValues(FIELDS.whatIndustry.name),
      },
    });

    setRequiredFields(fields);

    if (!isRequired(fields[FIELDS.companyRevenue.name])) {
      setValue(FIELDS.companyRevenue.name, '');
      clearErrors(FIELDS.companyRevenue.name);
      setCompanyRevenueClassName(css.hide);
    } else {
      setCompanyRevenueClassName(css.show);
    }

    if (!isRequired(fields[FIELDS.certificate.name])) {
      setValue(FIELDS.certificate.name, '');
      clearErrors(FIELDS.certificate.name);
      setCertificateClassName(css.hide);
    } else {
      setCertificateClassName(css.show);
    }
  };

  const whatIndustryRules = {
    onChange: handleOnChange,
  };

  const howManyYearsRules = {
    onChange: handleOnChange,
  };

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    onFormSubmit(data as ApplicationSignUpType);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={css.form}>
      <div className={css.formFields}>
        <InputForm
          type="text"
          label={FIELDS.firstName.label}
          name={FIELDS.firstName.name}
          placeholder={FIELDS.firstName.placeholder}
          required={isRequired(requiredFields[FIELDS.firstName.name])}
          control={control}
        />
        <InputForm
          type="text"
          label={FIELDS.lastName.label}
          name={FIELDS.lastName.name}
          placeholder={FIELDS.lastName.placeholder}
          required={isRequired(requiredFields[FIELDS.lastName.name])}
          control={control}
        />
        <InputForm
          type="text"
          label={FIELDS.email.label}
          name={FIELDS.email.name}
          placeholder={FIELDS.email.placeholder}
          required={isRequired(requiredFields[FIELDS.email.name])}
          control={control}
        />
        <InputForm
          type="text"
          label={FIELDS.cellPhone.label}
          name={FIELDS.cellPhone.name}
          placeholder={FIELDS.cellPhone.placeholder}
          required={isRequired(requiredFields[FIELDS.cellPhone.name])}
          control={control}
        />
        <div className={css.fullWidthContainer}>
          <InputForm
            className={css.companyNameInput}
            type="text"
            label={FIELDS.companyName.label}
            name={FIELDS.companyName.name}
            placeholder={FIELDS.companyName.placeholder}
            required={isRequired(requiredFields[FIELDS.email.name])}
            control={control}
          />
        </div>
        <SelectForm
          options={HOW_MANY_YEARS_OPTIONS}
          label={FIELDS.howManyYears.label}
          name={FIELDS.howManyYears.name}
          placeholder={FIELDS.howManyYears.placeholder}
          required={isRequired(requiredFields[FIELDS.howManyYears.name])}
          rules={howManyYearsRules}
          control={control}
        />
        <div className={css.fieldContainer}>
          <SelectForm
            className={companyRevenueClassName}
            options={COMPANY_REVENUE_OPTIONS}
            label={FIELDS.companyRevenue.label}
            name={FIELDS.companyRevenue.name}
            required={isRequired(requiredFields[FIELDS.companyRevenue.name])}
            placeholder={FIELDS.companyRevenue.placeholder}
            control={control}
          />
        </div>
        <SelectForm
          options={WHAT_INDUSTRY_OPTIONS}
          label={FIELDS.whatIndustry.label}
          name={FIELDS.whatIndustry.name}
          placeholder={FIELDS.whatIndustry.placeholder}
          required={isRequired(requiredFields[FIELDS.whatIndustry.name])}
          rules={whatIndustryRules}
          control={control}
        />
        <div className={css.fieldContainer}>
          <InputForm
            type="text"
            className={certificateClassName}
            disabled={!isRequired(requiredFields[FIELDS.certificate.name])}
            label={FIELDS.certificate.label}
            name={FIELDS.certificate.name}
            placeholder={FIELDS.certificate.placeholder}
            required={isRequired(requiredFields[FIELDS.certificate.name])}
            control={control}
          />
        </div>
      </div>

      <SwagButton
        type="primary"
        size="xl"
        htmlType="submit"
        fullWidth
        disabled={!isValid}
        loading={loading}
      >
        Apply
      </SwagButton>
    </form>
  );
}
