import { FC, useEffect } from 'react';
import { Divider, SwagButton, Typography, WhiteBox } from '@ezetech/swag-space-x';
import { useForm, SubmitHandler, FieldValues } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { InputForm } from 'components/input-form';
import {
  PasswordRequirementsBlock,
  usePasswordRequirementsHook,
} from 'components/password-requirements-block';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { validationSchema } from 'validations/profile-settings/change-password.validation';
import { useChangePasswordMutation, useLogoutMutation } from 'redux/api/auth.api';
import { useAppSelector } from 'redux/store';
import { useLogoutAsReseller } from 'hooks/use-logout-as-reseller.hook';
import { adminLoggedInAsResellerSelector } from 'redux/selectors/user.selectors';

import css from './change-password.module.scss';

export const ChangePassword: FC = () => {
  const adminLoggedInAsReseller = useAppSelector(adminLoggedInAsResellerSelector);
  const [logout] = useLogoutMutation();
  const logoutAsReseller = useLogoutAsReseller();

  const [changePassword] = useChangePasswordMutation();
  const { handleSubmit, control, formState, reset, watch, trigger } =
    useForm<FieldValues>({
      mode: 'all',
      resolver: yupResolver(validationSchema),
    });
  const { isValid } = formState;
  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    const result = await changePassword({
      oldPassword: data.oldPassword,
      password: data.password,
      confirmPassword: data.confirmPassword,
    });

    if ('data' in result && result.data?.isSuccess) {
      reset();
      if (adminLoggedInAsReseller) {
        logoutAsReseller();
      } else {
        logout();
      }
    }
  };

  const {
    onFieldChange,
    onFieldFocus,
    completedRequirements,
    isFocused,
    isAllRequirementsCompleted,
    isLengthError,
  } = usePasswordRequirementsHook();

  const rules = {
    onChange: onFieldChange,
  };

  const password = watch('password');
  const confirmPassword = watch('confirmPassword');

  useEffect(() => {
    if (confirmPassword) {
      trigger('confirmPassword');
    }
  }, [password, trigger]);

  return (
    <WhiteBox className={css.container}>
      <Typography
        fontType="HEADING5XL"
        lineHeight="100%"
        className={css.header}
        color={COLORS.colorText900}
      >
        Change Password
      </Typography>
      <Divider className={css.separator} color={COLORS.colorDivider} />
      <form
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
        method="post"
        action=""
        className={css.form}
      >
        <input autoComplete="false" name="hidden" type="text" className={css.hidden} />
        <div className={css.row}>
          <InputForm
            type="password"
            autoComplete="one-time-code"
            autocomplete="one-time-code"
            name="oldPassword"
            label="Old Password"
            placeholder="Enter Old Password"
            control={control}
          />
        </div>
        <div className={css.row}>
          <InputForm
            type="password"
            autoComplete="one-time-code"
            autocomplete="one-time-code"
            name="password"
            label="New Password"
            placeholder="Enter New Password"
            rules={rules}
            onFocus={onFieldFocus}
            control={control}
          />
          <InputForm
            type="password"
            autoComplete="one-time-code"
            autocomplete="one-time-code"
            name="confirmPassword"
            label="Confirm New Password"
            placeholder="Confirm New Password"
            rules={rules}
            onFocus={onFieldFocus}
            control={control}
          />
        </div>
      </form>
      <div>
        <PasswordRequirementsBlock
          isLengthMoreThanMaxAllowedCharacters={isLengthError}
          completedRequirements={completedRequirements}
          isAllRequirementsCompleted={isValid && isAllRequirementsCompleted}
          isInputFocused={isFocused}
        />
      </div>
      <SwagButton
        type="primary"
        htmlType="submit"
        className={css.button}
        onClick={handleSubmit(onSubmit)}
        disabled={!isValid || !isAllRequirementsCompleted}
      >
        Change Password
      </SwagButton>
    </WhiteBox>
  );
};
