import { ORDER_ITEMS_TYPES } from 'enums/orders.enum';
import { ITEM_STATUS_CODE } from 'constants/item.constants';
import { IItem, IOrder } from 'interfaces/orders.interface';

export const getRelatedItems = (items: IItem[]): string[] => {
  const relatedItems = items.reduce((acc: { [key: string]: IItem[] }, item) => {
    if (item.asRelatedItemId && !item.boxItemsId) {
      return {
        ...acc,
        [item.asRelatedItemId]: (acc[item.asRelatedItemId] || []).concat(item),
      };
    }
    return acc;
  }, {});

  return Object.values(relatedItems)
    .filter((item) => item.length >= 2)
    .flat()
    .map((item) => item.id);
};

export const isInventoryOrder = (order: IOrder) => {
  return order.items.some((item) => Boolean(item.inventoryPublicId));
};

export const isBox = (productType: string): boolean => {
  return productType === ORDER_ITEMS_TYPES.BOX;
};

export const checkIsItemCancelled = ({ statusCode }: IItem): boolean => {
  return [ITEM_STATUS_CODE.REJECTED, ITEM_STATUS_CODE.CANCELLED].includes(statusCode);
};
