import { FC } from 'react';
import classNames from 'classnames';
import { Typography } from '@ezetech/swag-space-x';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { PagePreviewFiltersProps } from '../interfaces/components';
import classes from '../edit-pdf-presentation-layout-popup.module.scss';
import '@ezetech/swag-presentation-templates/dist/utils/register-fonts';

export const PagePreviewFilters: FC<PagePreviewFiltersProps> = ({
  pageIndex,
  pageTemplateId,
  selectedPageTemplateId,
  handlePageChange,
  page,
  isHidden = false,
}) => {
  return (
    <div
      className={classes.pagePreviewFilterContainer}
      onClick={() => handlePageChange(pageTemplateId)}
    >
      <Typography
        fontType="bodySm"
        color={COLORS.colorTextDisabled}
        lineHeight="142.857%"
      >
        {pageIndex + 1}
      </Typography>
      <div
        className={classNames(classes.pagePreviewFilterPage, {
          [classes.pagePreviewFilterPageSelected]:
            pageTemplateId === selectedPageTemplateId,
          [classes.pagePreviewFilterPageHidden]: isHidden,
        })}
      >
        {page}
      </div>
    </div>
  );
};
