import { ReportHandler } from 'web-vitals';

export const reportWebVitals = async (onPerformanceEntry?: ReportHandler) => {
  if (onPerformanceEntry && onPerformanceEntry instanceof Function) {
    const { getCLS, getFID, getFCP, getLCP, getTTFB } = await import('web-vitals');
    getCLS(onPerformanceEntry);
    getFID(onPerformanceEntry);
    getFCP(onPerformanceEntry);
    getLCP(onPerformanceEntry);
    getTTFB(onPerformanceEntry);
  }
};
