import { useDispatch } from 'react-redux';
import { useAppSelector } from '../redux/store';

import { toastsNotificationsSelector } from '../redux/selectors/notification.selectors';

import {
  INotification,
  clearNotifications,
  pushNotification,
  setNotification,
} from '../redux/slices/notifications.slice';

export function useNotifications() {
  const toastsNotifications = useAppSelector(toastsNotificationsSelector);
  const dispatch = useDispatch();

  return {
    toastsNotifications,
    clearNotification: () => dispatch(clearNotifications()),
    pushNotification: (notification: INotification) =>
      dispatch(pushNotification(notification)),
    setNotification: (notification: INotification) =>
      dispatch(setNotification(notification)),
  };
}
