export const PER_PAGE_CARTS = 10;
export const DEFAULT_PAGE = 1;
export const DEFAULT_SORTING_ORDER = 'ASC';
export const MARK_AS_SENT = 'Mark as sent';

export const MAX_PRODUCTS_IN_TOOLTIP = 8;

export const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
export const OPEN_NOTES_TEXT = 'View';
