import { useAppSelector } from 'redux/store';
import { orderCardRelatedItemsWrapperComponentSelector } from 'redux/selectors/orders.selectors';
import { OrderCardItem } from '../order-card-item';
import { OrderCardItemFooter } from '../order-card-item-footer';

import css from './order-card-related-items-wrapper.module.scss';

interface IProps {
  orderId: string;
  relatedItemsIds: string[];
}

export const OrderCardRelatedItemsWrapperComponent = ({
  orderId,
  relatedItemsIds,
}: IProps) => {
  const { relatedItemsTotal, relatedItemsSetupFee } = useAppSelector(
    orderCardRelatedItemsWrapperComponentSelector({
      orderId,
      relatedItemsIds,
    }),
  );

  return (
    <>
      <div className={css.associatedHeader}>Associated Products</div>
      {relatedItemsIds.map((itemId) => (
        <OrderCardItem orderId={orderId} itemId={itemId} key={itemId} />
      ))}
      <OrderCardItemFooter total={relatedItemsTotal} setupFee={relatedItemsSetupFee} />
    </>
  );
};
