import { AbilityBuilder, createMongoAbility, Subject } from '@casl/ability';
import { IPermissions } from 'interfaces/user-api.interface';
import {
  CAN_COMBINED_KEYS_TYPE,
  CAN_COMBINED,
  SUBJECTS,
} from 'constants/ability.constant';
import { ROUTES } from 'constants/router';

export { CAN_COMBINED_NAMES, SUBJECTS } from 'constants/ability.constant';

const abilityBuilder = new AbilityBuilder(createMongoAbility);
const ability = abilityBuilder.build();

const isOwner = () => ability.can(SUBJECTS.MANAGE.actions.ALL, SUBJECTS.MANAGE.value);

function updateAbility(permissions: IPermissions[] | undefined, isUserOwner = false) {
  permissions?.forEach((permission) => {
    abilityBuilder.can(permission.action, permission.subject);
  });

  if (isUserOwner) {
    abilityBuilder.can(SUBJECTS.MANAGE.actions.ALL, SUBJECTS.MANAGE.value);
  }
  ability.update(permissions?.length ? abilityBuilder.rules : []);
  abilityBuilder.rules = [];
}

const checkCombination = (canCombinedName: CAN_COMBINED_KEYS_TYPE) => {
  if (!CAN_COMBINED[canCombinedName]?.permissions?.length) {
    return true;
  }
  const isAllowed = CAN_COMBINED[canCombinedName].permissions.some((canObject) =>
    can(canObject.action, canObject.subject),
  );
  return isAllowed;
};

const findFallbackIndex = (canCombinedName: CAN_COMBINED_KEYS_TYPE) => {
  if (!CAN_COMBINED[canCombinedName].fallbacks.length) {
    return -1;
  }

  const fallbackIndex = CAN_COMBINED[canCombinedName].fallbacks.findIndex((fallback) =>
    checkCombination(fallback),
  );

  return fallbackIndex;
};

const canRoute = (
  page: string,
): {
  isAllowed: boolean;
  redirect: ROUTES | undefined;
} => {
  let isAllowed = checkCombination(page);
  let redirect;

  if (!isAllowed) {
    const fallbackIndex = findFallbackIndex(page);
    if (fallbackIndex !== -1) {
      isAllowed = true;
      redirect = CAN_COMBINED[page].fallbacks[fallbackIndex];
    }
    return { isAllowed, redirect };
  }

  return { isAllowed, redirect };
};

const canCombined = (canCombinedName: CAN_COMBINED_KEYS_TYPE) => {
  let isAllowed = checkCombination(canCombinedName);

  if (!isAllowed) {
    const fallbackIndex = findFallbackIndex(canCombinedName);
    if (fallbackIndex !== -1) {
      isAllowed = true;
    }
  }
  return isAllowed;
};

const can = (action: string, subject: Subject, field?: string | undefined) =>
  isOwner() || ability.can(action, subject, field);

const cannot = (action: string, subject: Subject, field?: string | undefined) =>
  !isOwner() && ability.cannot(action, subject, field);

export { isOwner, can, cannot, canRoute, canCombined, updateAbility };
