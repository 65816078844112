import classNames from 'classnames';
import { Input, OptionType, Select, Typography, WhiteBox } from '@ezetech/swag-space-x';
import { useAppDispatch, useAppSelector } from 'redux/store';

import { setHoursOperations, setTimezone } from 'redux/slices/settings.slice';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import {
  hoursOperationsSelector,
  timezoneSelector,
} from 'redux/selectors/settings.selectors';
import {
  FROM,
  TO,
  OPERATION_HOURS,
  WEEK_DAYS,
  SHORT_DAY_NAME,
} from 'constants/settings.constants';
import { cannot, SUBJECTS } from 'boot/ability';

import { getTimezoneOptions } from './operation-hours.utils';

import css from './operation-hours.module.scss';

export function OperationHours(): JSX.Element {
  const dispatch = useAppDispatch();
  const timezoneOptions = getTimezoneOptions();
  const hoursOperations = useAppSelector(hoursOperationsSelector);
  const timezone = useAppSelector(timezoneSelector);
  const isDisabled = cannot(
    SUBJECTS.BUSINESS_HOURS.actions.BUSINESS_HOURS_EDIT,
    SUBJECTS.BUSINESS_HOURS.value,
  );

  const getFromValue = (day: string) => {
    const value = hoursOperations?.find((item) => item.day === day)?.from;
    const label = OPERATION_HOURS.find((item) => item.value === value)?.label;
    return value ? { value, label: label ?? value } : { label: 'Offline', value: '' };
  };

  const getToValue = (day: string) => {
    const value = hoursOperations?.find((item) => item.day === day)?.to;
    const label = OPERATION_HOURS.find((item) => item.value === value)?.label;
    return value ? { value, label: label ?? value } : { label: 'Offline', value: '' };
  };

  const getTimezoneValue = () => {
    if (timezone) {
      // transliteration fix : https://github.com/iamkun/dayjs/issues/2059#issuecomment-1473162037
      const formattedTimezone = new Intl.DateTimeFormat('en', {
        timeZone: timezone,
      }).resolvedOptions().timeZone;
      return timezoneOptions?.find((item) => item.value === formattedTimezone);
    }
    return;
  };

  const checkIsRequired = (day: string) => {
    const value = hoursOperations?.find((item) => item.day === day);
    return Boolean(value && (value.to || value.from));
  };

  const changeHoursOperations = (
    option: OptionType | null,
    day: string,
    type: 'from' | 'to',
  ) => {
    dispatch(
      setHoursOperations({
        value: option?.value === 'reset' ? null : option?.value ?? null,
        day,
        type,
      }),
    );
  };

  const changeTimezone = (option: OptionType | null) => {
    if (option?.value) {
      dispatch(setTimezone(option?.value));
    }
  };

  const getFilteredOperationHours = (type: 'from' | 'to', day: string) => {
    if (type === FROM) {
      const dayItem = hoursOperations?.find((item) => item.day === day);
      const valueOfToField = dayItem?.to ?? OPERATION_HOURS[0];
      const indexInHoursList =
        OPERATION_HOURS.findIndex((item) => item.value === valueOfToField) ??
        OPERATION_HOURS[0];

      return [
        { label: 'Offline', value: 'reset' },
        ...OPERATION_HOURS.slice(0, indexInHoursList),
      ];
    }
    const dayItem = hoursOperations?.find((item) => item.day === day);
    const valueOfFromField = dayItem?.from ?? OPERATION_HOURS[OPERATION_HOURS.length];
    const indexInHoursList =
      OPERATION_HOURS.findIndex((item) => item.value === valueOfFromField) ??
      OPERATION_HOURS[OPERATION_HOURS.length];

    return [
      { label: 'Offline', value: 'reset' },
      ...OPERATION_HOURS.slice(indexInHoursList + 1, OPERATION_HOURS.length),
    ];
  };

  return (
    <div className={css.root}>
      <WhiteBox className={css.section}>
        <Typography
          className={css.header}
          color={COLORS.colorDarkBlue}
          fontType="HEADING5XL"
        >
          Business hours
        </Typography>

        <div className={css.underline} />

        <div className={classNames(css.content, css.listTimezone)}>
          <Typography
            color={COLORS.colorPrimaryText}
            fontType="bodyMd"
            className={css.timezonePlaceholder}
          >
            Select timezone*
          </Typography>
          <Select
            isDisabled={isDisabled}
            className={css.selectInput}
            key="timezone"
            options={getTimezoneOptions()}
            name="timezone"
            label="Select timezone"
            required={true}
            value={getTimezoneValue()}
            onChange={(option) => changeTimezone(option as OptionType)}
            errorClassName={css.errorClassName}
            placeholder=""
          />
        </div>

        <div className={classNames(css.content, css.list)}>
          <Typography
            color={COLORS.colorPrimaryText}
            fontType="bodyMd"
            className={css.dayText}
          >
            Day
          </Typography>
          <Typography
            color={COLORS.colorPrimaryText}
            fontType="bodyMd"
            className={css.dayText}
          >
            From
          </Typography>
          <Typography
            color={COLORS.colorPrimaryText}
            fontType="bodyMd"
            className={css.dayText}
          >
            To
          </Typography>

          {Object.values(WEEK_DAYS).map((day) => (
            <>
              <Input
                key={day + 'AsItIs'}
                className={css.inputDayName}
                type="text"
                value={`${day}${checkIsRequired(day) ? '*' : ''}`}
                name={day}
                errorClassName={css.errorClassName}
                labelClassName={css.labelClassName}
                disabled
              />
              <Select
                isDisabled={isDisabled}
                className={css.selectInput}
                key={day + 'from'}
                options={getFilteredOperationHours(FROM, day)}
                name={day + 'from'}
                label={`${SHORT_DAY_NAME[day]} From`}
                value={getFromValue(day)}
                onChange={(option) =>
                  changeHoursOperations(option as OptionType, day, FROM)
                }
                errorClassName={css.errorClassName}
                placeholder=""
                required={checkIsRequired(day)}
              />
              <Select
                isDisabled={isDisabled}
                className={css.selectInput}
                key={day + 'to'}
                options={getFilteredOperationHours(TO, day)}
                name={day + 'to'}
                label={`${SHORT_DAY_NAME[day]} To`}
                value={getToValue(day)}
                onChange={(option) =>
                  changeHoursOperations(option as OptionType, day, TO)
                }
                errorClassName={css.errorClassName}
                placeholder=""
                required={checkIsRequired(day)}
              />
            </>
          ))}
        </div>
      </WhiteBox>
    </div>
  );
}
