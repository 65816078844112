import {
  Typography,
  WhiteBox,
  List,
  ListHeader,
  ListItem,
  Divider,
} from '@ezetech/swag-space-x';
import dateFormat from 'dateformat';

import COLORS from 'constants/styles/colors-js.constant.module.scss';

import { useAppSelector } from '../../../redux/store';
import { dashboardSelector } from '../../../redux/selectors/dashboard.selectors';
import { TierCardProgress } from './tier-card-progress';
import { TierCardProgressHighest } from './tier-card-progress-highest';
import { ITierCardProps } from './tier-card.type';
import {
  getColorByTierName,
  calculateTierMargin,
  getCalculatedDataForTier,
  getNextTier,
} from './tier-card.utils';

import css from './tier-card.module.scss';

export function TierCard({
  className,
  currencySymbol = '$',
}: ITierCardProps): JSX.Element {
  const data = useAppSelector(dashboardSelector);
  const { revenueFormatted } = getCalculatedDataForTier(data);

  const tiers = data.tiers;
  const tierPromotion = data.tierPromotion;
  const nextTier = getNextTier(tierPromotion, tiers);

  const tierColor = getColorByTierName(tierPromotion.name);
  const headStyle: React.CSSProperties = {
    backgroundColor: tierColor,
  };

  return (
    <WhiteBox className={className}>
      <List>
        <ListHeader style={headStyle} className={css.header}>
          <div>
            <Typography lineHeight="162%" fontType="bodyMdBold" color={COLORS.colorWhite}>
              Current:
            </Typography>
            <Typography lineHeight="162%" fontType="bodyMdBold" color={COLORS.colorWhite}>
              This years revenue:
            </Typography>
          </div>
          <div>
            <Typography lineHeight="100%" fontType="HEADING5XL" color={COLORS.colorWhite}>
              {tierPromotion.name}
            </Typography>
            <Typography lineHeight="100%" fontType="HEADING5XL" color={COLORS.colorWhite}>
              {currencySymbol}
              {revenueFormatted}
            </Typography>
          </div>
        </ListHeader>
        <ListItem className={css.content}>
          {nextTier ? (
            <>
              <TierCardProgress />
              <Divider />
            </>
          ) : (
            <TierCardProgressHighest />
          )}

          <div className={css.footer}>
            <div>
              <Typography lineHeight="162%" color={COLORS.colorSecondaryText}>
                Commission:
              </Typography>
              <div className={css.tiers}>
                {tiers.map((item) => {
                  const style = { color: getColorByTierName(item.name) };
                  const margin = calculateTierMargin(data.margin, item.marginIncrement);
                  return (
                    <Typography
                      lineHeight="162%"
                      color={COLORS.colorPrimaryText}
                      key={item.name}
                    >
                      <div className={css.tierItem}>
                        {item.name}: <span style={style}>{margin}%</span>
                      </div>
                    </Typography>
                  );
                })}
              </div>
            </div>
            <div>
              <Typography lineHeight="162%" color={COLORS.colorSecondaryText}>
                Start and End Dates:
              </Typography>
              <div className={css.tiersDates}>
                <div className={css.tierItem}>
                  <Typography lineHeight="162%" color={COLORS.colorPrimaryText}>
                    From:
                  </Typography>
                  <Typography
                    lineHeight="162%"
                    color={COLORS.colorPrimaryText}
                    className={css.revenueDate}
                  >
                    {dateFormat(data.revenuePeriodFrom, 'mmm d, yyyy')}
                  </Typography>
                </div>
                <div className={css.tierItem}>
                  <Typography lineHeight="162%" color={COLORS.colorPrimaryText}>
                    To:&nbsp;&nbsp;
                  </Typography>
                  <Typography
                    lineHeight="162%"
                    color={COLORS.colorPrimaryText}
                    className={css.revenueDate}
                  >
                    {dateFormat(data.revenuePeriodTo, 'mmm d, yyyy')}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </ListItem>
      </List>
    </WhiteBox>
  );
}
