import { useState, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import {
  Typography,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuItem,
} from '@ezetech/swag-space-x';
import { useLogoutMutation } from 'redux/api/auth.api';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { ROUTES, ROUTES_NAMES } from 'constants/router';

import { isOnboardingFlowCompletedSelector } from 'redux/selectors/store-creation.selectors';
import { isShowReferLink, isShowSettingsLink } from 'utils/policy.util';

import userLogo from 'assets/svg/user.svg';
import { useAppDispatch, useAppSelector } from 'redux/store';
import {
  adminLoggedInAsResellerSelector,
  firstNameSelector,
} from 'redux/selectors/user.selectors';
import dropDown from 'assets/svg/drop-down.svg';
import { closePopup, openPopup } from 'redux/slices/modals.slice';
import { useLogoutAsReseller } from 'hooks/use-logout-as-reseller.hook';

import { CONFIRMATION_POPUP } from '../popups/_logic/popups-list';
import { HeaderMenuProps } from './header-menu.interface';
import css from './header-menu.module.scss';

export function HeaderMenuComponent({ className }: HeaderMenuProps): JSX.Element {
  const dispatch = useAppDispatch();
  const [hover, setHover] = useState(false);
  const isOnboardingFlowCompleted = useAppSelector(isOnboardingFlowCompletedSelector);
  const name = useAppSelector(firstNameSelector);
  const adminLoggedInAsReseller = useAppSelector(adminLoggedInAsResellerSelector);
  const [logout] = useLogoutMutation();
  const logoutAsReseller = useLogoutAsReseller();
  const [showReferLink, setShowReferLink] = useState(false);
  const [showSettingsLink, setShowSettingsLink] = useState(false);

  const onLogout = useCallback(() => {
    if (adminLoggedInAsReseller) {
      dispatch(
        openPopup({
          popupName: CONFIRMATION_POPUP,
          popupProps: {
            descriptionComponent:
              'Are you sure you want to log out from reseller dashboard?',
            onConfirm: () => {
              logoutAsReseller();
              dispatch(closePopup());
            },
            onCancel: () => {
              dispatch(closePopup());
            },
          },
        }),
      );
    } else {
      logout();
    }
  }, []);

  useEffect(() => {
    setShowReferLink(isShowReferLink());
    setShowSettingsLink(isShowSettingsLink());
  }, []);

  return (
    <div
      className={cn(css.menu, className)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <img src={userLogo} className={css.userLogo} alt="user" />
      <div>
        <Typography
          lineHeight="162%"
          className={css.menuText}
          fontType="bodySm"
          color={COLORS.colorWhite}
          underlined={hover}
        >
          Welcome {name}&nbsp;
        </Typography>
        <DropdownMenu modal={false} open={hover}>
          <DropdownMenuTrigger asChild>
            <img
              src={dropDown}
              className={cn(css.dropDown, {
                [css.arrowHover]: hover,
              })}
              alt="drop-down menu"
            />
          </DropdownMenuTrigger>

          <DropdownMenuContent
            className={css.content}
            sideOffset={6}
            align="end"
            alignOffset={-5}
          >
            {isOnboardingFlowCompleted ? (
              <>
                <Link to={ROUTES.PROFILE_SETTINGS} className={css.unlink}>
                  <DropdownMenuItem>{ROUTES_NAMES.PROFILE_SETTINGS}</DropdownMenuItem>
                </Link>
                {showSettingsLink && (
                  <Link to={ROUTES.SETTINGS_COMPANY_AND_BRAND} className={css.unlink}>
                    <DropdownMenuItem>Company Settings</DropdownMenuItem>
                  </Link>
                )}
                {showReferLink && (
                  <Link to={ROUTES.REFER_A_MEMBER} className={css.unlink}>
                    <DropdownMenuItem>{ROUTES_NAMES.REFER_A_MEMBER}</DropdownMenuItem>
                  </Link>
                )}
              </>
            ) : null}
            <DropdownMenuItem onSelect={onLogout}>
              <span data-option="logout">Logout</span>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  );
}
