import { FC, FocusEvent, useMemo } from 'react';
import * as yup from 'yup';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { Input } from '@ezetech/swag-space-x';
import { isRequired } from 'utils/yup.util';
import { ISocial } from 'interfaces';
import { cannot, SUBJECTS } from 'boot/ability';

import { FormProps } from '../forms.interface';
import css from '../forms.module.scss';
import { useAppDispatch } from '../../../../../redux/store';
import {
  socialErrorsSelector,
  socialSelector,
} from '../../../../../redux/selectors/settings.selectors';
import { patchSocial, setSocialError } from '../../../../../redux/slices/settings.slice';
import { FIELDS } from './social.constants';
import { socialFormSchema } from './social.schema';

export const SocialForm: FC<FormProps> = ({ loading, success, error }) => {
  const requiredFields = useMemo<Record<string, yup.SchemaFieldDescription>>(() => {
    return socialFormSchema.describe().fields;
  }, []);

  const dispatch = useAppDispatch();
  const values = useSelector(socialSelector);
  const errors = useSelector(socialErrorsSelector);
  const isDisabled = cannot(
    SUBJECTS.SOCIAL_HANDLE.actions.SOCIAL_HANDLE_EDIT,
    SUBJECTS.SOCIAL_HANDLE.value,
  );

  const onChange = (path: string) => async (value: unknown) => {
    dispatch(
      patchSocial({
        [path]: value,
      }),
    );
    dispatch(setSocialError({ [path]: undefined }));
  };

  const onBlur = (path: string) => async (e: FocusEvent<HTMLInputElement>) => {
    try {
      await socialFormSchema.validateAt(path, { [path]: e.target.value });
      dispatch(setSocialError({ [path]: undefined }));
    } catch (validationError: unknown) {
      const { message } = validationError as { message: string };
      dispatch(setSocialError({ [path]: message }));
    }
  };

  return (
    <form className={css.form}>
      <div className={css.twoInRowContainer}>
        <Input
          disabled={isDisabled}
          className={css.input}
          type="text"
          onChange={(e) => onChange(FIELDS.instagram.name)(e.target.value)}
          onBlur={onBlur(FIELDS.instagram.name)}
          value={values[FIELDS.instagram.name as keyof ISocial]}
          errors={errors[FIELDS.instagram.name as keyof ISocial]}
          label={FIELDS.instagram.label}
          name={FIELDS.instagram.name}
          placeholder={FIELDS.instagram.placeholder}
          required={isRequired(requiredFields[FIELDS.instagram.name])}
        />
        <Input
          disabled={isDisabled}
          type="text"
          className={css.input}
          onChange={(e) => onChange(FIELDS.facebook.name)(e.target.value)}
          value={values[FIELDS.facebook.name as keyof ISocial]}
          errors={errors[FIELDS.facebook.name as keyof ISocial]}
          onBlur={onBlur(FIELDS.facebook.name)}
          label={FIELDS.facebook.label}
          name={FIELDS.facebook.name}
          placeholder={FIELDS.facebook.placeholder}
          required={isRequired(requiredFields[FIELDS.facebook.name])}
        />
      </div>
      <div className={css.twoInRowContainer}>
        <Input
          disabled={isDisabled}
          className={css.input}
          type="text"
          onChange={(e) => onChange(FIELDS.tiktok.name)(e.target.value)}
          value={values[FIELDS.tiktok.name as keyof ISocial]}
          errors={errors[FIELDS.tiktok.name as keyof ISocial]}
          onBlur={onBlur(FIELDS.tiktok.name)}
          label={FIELDS.tiktok.label}
          name={FIELDS.tiktok.name}
          placeholder={FIELDS.tiktok.placeholder}
          required={isRequired(requiredFields[FIELDS.tiktok.name])}
        />
        <Input
          disabled={isDisabled}
          type="text"
          className={css.input}
          onChange={(e) => onChange(FIELDS.twitter.name)(e.target.value)}
          value={values[FIELDS.twitter.name as keyof ISocial]}
          errors={errors[FIELDS.twitter.name as keyof ISocial]}
          onBlur={onBlur(FIELDS.twitter.name)}
          label={FIELDS.twitter.label}
          name={FIELDS.twitter.name}
          placeholder={FIELDS.twitter.placeholder}
          required={isRequired(requiredFields[FIELDS.twitter.name])}
        />
      </div>
      <div className={css.twoInRowContainer}>
        <Input
          disabled={isDisabled}
          type="text"
          className={css.input}
          onChange={(e) => onChange(FIELDS.linkedIn.name)(e.target.value)}
          value={values[FIELDS.linkedIn.name as keyof ISocial]}
          errors={errors[FIELDS.linkedIn.name as keyof ISocial]}
          onBlur={onBlur(FIELDS.linkedIn.name)}
          label={FIELDS.linkedIn.label}
          name={FIELDS.linkedIn.name}
          placeholder={FIELDS.linkedIn.placeholder}
          required={isRequired(requiredFields[FIELDS.linkedIn.name])}
        />
        <div className={classnames(css.input, css.mobileHidden)} />
      </div>
    </form>
  );
};
