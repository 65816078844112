import { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { ROUTES } from 'constants/router';
import { useAuth } from 'hooks/auth.hook';
import { useGetWithdrawMutation } from 'redux/api/withdraw.api';
import { getPublicSid } from 'utils/auth.util';
import { useAppDispatch } from '../../redux/store';
import { setLoginRedirectUrl } from '../../redux/slices/user.slice';
import { can, SUBJECTS } from '../../boot/ability';

export function PrivateRoute({ children }: React.PropsWithChildren): JSX.Element {
  const { isAuthorized } = useAuth();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [getWithdraw] = useGetWithdrawMutation();

  useEffect(() => {
    if (
      getPublicSid() &&
      can(SUBJECTS.WITHDRAW_VIEW.actions.WITHDRAW_VIEW, SUBJECTS.WITHDRAW_VIEW.value)
    ) {
      getWithdraw();
    }
  }, [location.pathname]);

  if (!isAuthorized) {
    dispatch(setLoginRedirectUrl(location.pathname));
    return <Navigate to={ROUTES.LOGIN} replace />;
  }

  return children ? <>{children}</> : <Outlet />;
}
