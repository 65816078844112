import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ValidationError } from 'yup';
import { formatErrors } from 'utils/customer/error';
import {
  IAddressErrors,
  ICompanyErrors,
  ICustomerDetails,
  IUpdateFieldPayload,
  IUpdateFieldResultPayload,
  IUserErrors,
  ICustomerOrdersWithStatistic,
  IUserAndNotesDetails,
  TAX_METHODS,
} from '../../interfaces';
import { customerSchema } from '../../validations/customer.validation';

interface IState extends ICustomerDetails {
  initialUser: ICustomerDetails['user'];
  editedUser: ICustomerDetails['user'];
  userErrors: IUserErrors;
  companyErrors: ICompanyErrors;
  addressErrors: IAddressErrors;
}

const initialState: IState = {
  orders: [],
  notes: [],
  user: null,
  initialUser: null,
  editedUser: null,
  statistic: {
    lastOrderAt: null,
    avgOrderValue: 0,
    totalSpent: '$0.00',
    totalOrders: '$0.00',
  },
  userErrors: {},
  companyErrors: {},
  addressErrors: {},
};

export const updateUser = createAsyncThunk(
  'customerDetails/updateUser',
  async (payload: IUpdateFieldPayload, thunkAPI) => {
    try {
      await customerSchema.validateAt(payload.key, { [payload.key]: payload.value });
      return {
        ...payload,
        error: undefined,
      };
    } catch (e: unknown) {
      const { message } = e as { message: string };
      return {
        ...payload,
        error: message,
      };
    }
  },
);

export const submitCustomer = createAsyncThunk(
  'customerDetails/submit',
  async (data: ICustomerDetails['user']) => {
    try {
      await customerSchema.validate(data, { stripUnknown: true, abortEarly: false });
      return {
        closePopup: true,
        hasError: false,
      };
    } catch (e: unknown) {
      const { inner } = e as ValidationError;
      return {
        closePopup: false,
        hasError: true,
        errors: formatErrors(inner),
      };
    }
  },
);

export const updateAddress = createAsyncThunk(
  'customerDetails/updateAddress',
  async (payload: IUpdateFieldPayload, thunkAPI) => {
    try {
      await customerSchema.validateAt(`address.${payload.key}`, {
        address: { [payload.key]: payload.value },
      });
      return {
        ...payload,
        error: undefined,
      };
    } catch (e: unknown) {
      const { message } = e as { message: string };
      return {
        ...payload,
        error: message,
      };
    }
  },
);
export const customerDetailsSlice = createSlice({
  initialState,
  name: 'customerDetailsSlice',
  reducers: {
    reset: () => initialState,
    restore: (state) => ({
      ...state,
      user: state.initialUser,
      editedUser: state.initialUser,
      userErrors: {},
      companyErrors: {},
      addressErrors: {},
    }),
    setUserId: (state, action: PayloadAction<{ userId: string }>) => {
      return {
        ...state,
        userId: action.payload.userId,
      };
    },
    setDetails: (state, action: PayloadAction<IUserAndNotesDetails>) => {
      if (!action.payload) {
        return state;
      }

      return {
        ...state,
        ...action.payload,
        initialUser: action.payload.user,
        editedUser: action.payload.user,
        userErrors: {},
        companyErrors: {},
        addressErrors: {},
      };
    },
    setPaymentMethod: (state, action: PayloadAction<{ paymentMethod: string }>) => {
      return {
        ...state,
        editedUser: state.editedUser
          ? {
              ...state.editedUser,
              paymentMethod: action.payload.paymentMethod,
            }
          : null,
      };
    },
    setEditedUser: (state) => {
      return {
        ...state,
        editedUser: state.user,
        initialUser: state.user,
      };
    },
    setOrders: (state, action: PayloadAction<ICustomerOrdersWithStatistic>) => {
      return { ...state, ...action.payload };
    },
    setTaxExemptData: (
      state,
      action: PayloadAction<{ isTaxExemptPending: boolean; tax: TAX_METHODS }>,
    ) => {
      return {
        ...state,
        editedUser: state.editedUser
          ? {
              ...state.editedUser,
              isTaxExemptPending: action.payload.isTaxExemptPending,
              tax: action.payload.tax,
            }
          : null,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      updateUser.fulfilled,
      (state, action: PayloadAction<IUpdateFieldResultPayload>) => {
        const { key, value, error } = action.payload;
        if (state.editedUser) {
          state.editedUser = {
            ...state.editedUser,
            [key]: value,
          };
        }
        state.userErrors = {
          ...state.userErrors,
          [key]: error,
        };
      },
    );
    builder.addCase(
      updateAddress.fulfilled,
      (state, action: PayloadAction<IUpdateFieldResultPayload>) => {
        const { key, value, error } = action.payload;
        if (state.editedUser && state.editedUser.company && state.editedUser.address) {
          state.editedUser = {
            ...state.editedUser,
            company: {
              ...state.editedUser.company,
            },
            address: {
              ...state.editedUser.address,
              [key]: value,
              ...(key === 'country'
                ? {
                    state: null,
                  }
                : {}),
            },
          };
        }
        state.addressErrors = {
          ...state.addressErrors,
          [key]: error,
        };
      },
    );
    builder.addCase(submitCustomer.fulfilled, (state, action) => {
      const { hasError, errors } = action.payload;
      if (hasError && errors) {
        state.userErrors = errors.userErrors;
        state.companyErrors = errors.companyErrors;
        state.addressErrors = errors.addressErrors;
      }
    });
  },
});

export const customerDetailsReducer = customerDetailsSlice.reducer;
export const {
  reset,
  setPaymentMethod,
  restore,
  setDetails,
  setEditedUser,
  setOrders,
  setTaxExemptData,
} = customerDetailsSlice.actions;
