import { LoaderFunctionArgs } from 'react-router-dom';
import { customerCompanyApi } from 'redux/api/customer-company.api';
import { pushNotification } from 'redux/slices/notifications.slice';
import { makeToastNotification } from 'utils/query.util';
import { fetchUserData } from 'utils/user.util';
import { fetchStoreSettingsData } from 'utils/store-settings.util';

import { store } from '../../redux/store';

export async function customerCompanyLoader({
  params,
}: LoaderFunctionArgs): Promise<null> {
  try {
    await fetchUserData();
    await fetchStoreSettingsData();

    if (!params.id) {
      throw new Error('No ID provided');
    }

    const id = params.id;
    const requests = [
      store
        .dispatch(
          customerCompanyApi.endpoints.getCustomerCompanyProfile.initiate(
            { id },
            { forceRefetch: true },
          ),
        )
        .unwrap(),
    ];

    await Promise.all(requests);
  } catch (e) {
    store.dispatch(pushNotification(makeToastNotification(e)));
  }
  return null;
}
