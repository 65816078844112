import { IBillingAddress } from 'interfaces/settings.interface';
import {
  CANADA_STATES_LIST,
  COUNTRIES_LIST_ALL,
  CountriesStatesSelect,
  USA_STATES_LIST,
} from 'constants/settings.constants';

export const formatAddress = (address: IBillingAddress) => {
  if (!address.billingCountry) {
    return '';
  }
  const country = COUNTRIES_LIST_ALL.find((c) => {
    return c.cca2 === address.billingCountry;
  })?.common;
  let state: string | undefined = address.billingState;
  if (address.billingCountry === CountriesStatesSelect.US) {
    state = USA_STATES_LIST.find((s) => {
      return s.code === address.billingState;
    })?.name;
  }
  if (address.billingCountry === CountriesStatesSelect.CA) {
    state = CANADA_STATES_LIST.find((s) => {
      return s.code === address.billingState;
    })?.name;
  }
  state = state ? `${state},` : '';
  return `${address.billingAddress}, ${address.billingCity}, ${country}, ${state} ${address.billingZip}`;
};
