import { FC } from 'react';
import { Typography } from '@ezetech/swag-space-x';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import css from './order-total-prices-row.module.scss';

export const OrderTotalPricesRow: FC<{ label: string; value: string }> = ({
  label,
  value,
}) => {
  return (
    <div className={css.row}>
      <Typography color={COLORS.colorText900} fontType="bodyMd" lineHeight="162.5%">
        {label}
      </Typography>
      <Typography
        lineHeight="162.5%"
        fontType="bodyMdBold"
        color={COLORS.colorPrimaryText}
      >
        {value}
      </Typography>
    </div>
  );
};
