import { createSelector } from 'reselect';
import { RootState } from '../store';

export const referralsSelector = (state: RootState) => state.referrals;

export const pageReferralsSelector = createSelector(
  referralsSelector,
  (referrals) => referrals.page,
);

export const perPageReferralsSelector = createSelector(
  referralsSelector,
  (referrals) => referrals.perPage,
);

export const isLoadingReferralsSelector = createSelector(
  referralsSelector,
  (referrals) => referrals.isLoading,
);

export const totalReferralsSelector = createSelector(
  referralsSelector,
  (referrals) => referrals.total,
);

export const hasNextPageSelector = createSelector(
  referralsSelector,
  ({ perPage, total, page }) => Math.ceil(total / perPage) >= page,
);

export const getReferralsActionsListSelector = createSelector(
  referralsSelector,
  (referrals) => referrals.list.map((item) => ({ ...item, key: item.id })),
);
