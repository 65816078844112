import { Fragment } from 'react';
import {
  Typography,
  WhiteBox,
  List,
  ListHeader,
  ListItem,
  Divider,
} from '@ezetech/swag-space-x';
import { useAppSelector } from 'redux/store';
import { customerCompanyStatisticSelector } from 'redux/selectors/customer-company.selectors';
import {
  customerCompanyStatisticLabelsMobile,
  customerCompanyStatisticLabels,
} from 'constants/customer-company.constant';
import COLORS from 'constants/styles/colors-js.constant.module.scss';

import css from './statistic.module.scss';

export function Statistic() {
  const statistic = useAppSelector(customerCompanyStatisticSelector);
  const labels = customerCompanyStatisticLabels;
  const labelsMobile = customerCompanyStatisticLabelsMobile;
  return (
    <WhiteBox>
      <List className={css.list}>
        <ListHeader className={css.items}>
          {labels.map((item) => (
            <Typography
              key={item.id}
              data-label={item.label}
              lineHeight="162.5%"
              fontType="bodyMdBold"
              color={COLORS.colorText600}
              className={css.item}
            >
              {item.label}
            </Typography>
          ))}
        </ListHeader>
        <Divider color={COLORS.colorNeural200} />
        <ListItem className={css.items}>
          {labels.map((item) => (
            <Typography
              key={item.id}
              data-label={item.label}
              lineHeight="100%"
              fontType="HEADING5XL"
              color={COLORS.colorText900}
              className={css.itemValue}
            >
              {statistic[item.id]}
            </Typography>
          ))}
        </ListItem>
      </List>
      <List className={css.listMobile}>
        {labelsMobile.map((items, itemsIndex) => {
          return (
            <Fragment key={itemsIndex}>
              <ListItem className={css.itemsMobile}>
                {items.map((item, itemIndex) => {
                  return (
                    <Fragment key={item.id}>
                      <div className={css.itemMobile}>
                        <Typography
                          lineHeight="162.5%"
                          fontType="bodyMdBold"
                          color={COLORS.colorText600}
                        >
                          {item.label}
                        </Typography>
                        <Typography
                          lineHeight="100%"
                          fontType="HEADING5XL"
                          color={COLORS.colorText900}
                          className={css.itemValue}
                        >
                          {statistic[item.id]}
                        </Typography>
                      </div>
                      {items.length - 1 > itemIndex ? (
                        <div className={css.verticalDivider} />
                      ) : null}
                    </Fragment>
                  );
                })}
              </ListItem>
              {labelsMobile.length - 1 > itemsIndex ? (
                <Divider color={COLORS.colorNeural200} />
              ) : null}
            </Fragment>
          );
        })}
      </List>
    </WhiteBox>
  );
}
