import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { IconType } from './type';

export const CopyIcon = ({
  className,
  fillColor = COLORS.colorTextIcon,
  width = '15px',
  height = '18px',
}: IconType): JSX.Element => {
  const style = { width, height };
  return (
    <div style={style} className={className}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 15 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.82315 2.72656C4.82315 2.58849 4.93508 2.47656 5.07315 2.47656H6.76676H10.1541C10.2158 2.47656 10.2754 2.49942 10.3213 2.54074L12.9374 4.89519C12.99 4.9426 13.0201 5.01014 13.0201 5.08102V12.3629C13.0201 12.501 12.9082 12.6129 12.7701 12.6129H5.07315C4.93508 12.6129 4.82315 12.501 4.82315 12.3629V2.72656ZM5.07315 0.976562C4.10665 0.976562 3.32315 1.76007 3.32315 2.72656V3.88565H2.16406C1.19756 3.88565 0.414062 4.66916 0.414062 5.63565V15.272C0.414062 16.2385 1.19757 17.022 2.16406 17.022H8.89134C9.85783 17.022 10.6413 16.2385 10.6413 15.272V14.1129H12.7701C13.7366 14.1129 14.5201 13.3294 14.5201 12.3629V5.08102C14.5201 4.58491 14.3096 4.11213 13.9408 3.78025L11.3248 1.4258C11.0034 1.13659 10.5864 0.976562 10.1541 0.976562H6.76676H5.07315ZM9.14134 14.1129H5.07315C4.10666 14.1129 3.32315 13.3294 3.32315 12.3629V5.38565H2.16406C2.02599 5.38565 1.91406 5.49758 1.91406 5.63565V15.272C1.91406 15.4101 2.02599 15.522 2.16406 15.522H8.89134C9.02941 15.522 9.14134 15.4101 9.14134 15.272V14.1129Z"
          fill={fillColor}
        />
      </svg>
    </div>
  );
};
