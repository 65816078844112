import { IHoursOperations } from 'interfaces/settings.interface';

export const hoursOperationComplete = (hoursOperations: IHoursOperations[]) => {
  return hoursOperations.reduce<boolean>((complete, entity) => {
    if (!complete) {
      return complete;
    }
    if (entity.from && entity.from !== 'reset') {
      return Boolean(entity.to && entity.to !== 'reset');
    } else if (entity.to && entity.to !== 'reset') {
      return Boolean(entity.from && entity.from !== 'reset');
    }
    return true;
  }, true);
};
