import { IconType } from './type';

export const StarsIcon = ({
  className,
  fillColor = '#E73AF6',
  width = '88px',
  height = '89px',
  opacity = '0.3',
}: IconType): JSX.Element => {
  const style = { width, height };
  return (
    <div style={style} className={className}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 88 89"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity={opacity}>
          <path
            d="M52.9249 70.7525C42.8202 67.8475 40.6998 65.7265 37.7962 55.6219C37.6518 55.1169 37.1904 54.7695 36.665 54.7695C36.1405 54.7695 35.6791 55.1169 35.5338 55.6219C32.6295 65.7273 30.509 67.8492 20.4068 70.7525C19.9019 70.897 19.5547 71.3585 19.5547 71.884C19.5547 72.4087 19.9019 72.8702 20.4068 73.0155C30.5098 75.9206 32.6311 78.0416 35.5338 88.1479C35.6783 88.6529 36.1396 89.0002 36.665 89.0002C37.1896 89.0002 37.6509 88.6529 37.7962 88.1479C40.7006 78.0407 42.8211 75.9197 52.9249 73.0155C53.4298 72.871 53.777 72.4095 53.777 71.884C53.777 71.3585 53.429 70.897 52.9249 70.7525Z"
            fill={fillColor}
          />
          <path
            d="M87.1629 38.0099C78.0159 35.3805 76.096 33.4583 73.4662 24.3099C73.3243 23.8139 72.8711 23.4727 72.355 23.4727C71.8397 23.4727 71.3866 23.8138 71.2438 24.3099C68.6151 33.4598 66.6933 35.3795 57.548 38.0099C57.0521 38.1519 56.7109 38.6052 56.7109 39.1214C56.7109 39.6368 57.052 40.0901 57.548 40.2329C66.695 42.8623 68.6134 44.7828 71.2438 53.932C71.3857 54.4281 71.8389 54.7693 72.355 54.7693C72.8703 54.7693 73.3235 54.4281 73.4662 53.932C76.095 44.7844 78.0151 42.8639 87.1629 40.2329C87.6588 40.0901 87.9999 39.6376 87.9999 39.1206C87.9999 38.6044 87.6588 38.1519 87.1629 38.0099Z"
            fill={fillColor}
          />
          <path
            d="M39.1112 19.5607C39.1112 19.0399 38.7665 18.5818 38.2654 18.4376C26.5202 15.0594 24.0552 12.5938 20.6781 0.846008C20.5347 0.344771 20.0768 0 19.5552 0C19.0345 0 18.5765 0.34475 18.4323 0.846008C15.0546 12.5954 12.5903 15.061 0.84424 18.4376C0.344714 18.581 0 19.0391 0 19.5607C0 20.0815 0.344692 20.5396 0.845867 20.6838C12.5911 24.0597 15.056 26.5251 18.4339 38.2748C18.5773 38.776 19.0353 39.1208 19.5568 39.1208C20.0776 39.1208 20.5355 38.776 20.6798 38.2748C24.0574 26.526 26.5218 24.0606 38.2678 20.6838C38.7674 20.5396 39.1113 20.0815 39.1113 19.5607H39.1112Z"
            fill={fillColor}
          />
        </g>
      </svg>
    </div>
  );
};
