import { store } from 'redux/store';
import { storeSettingsApi } from 'redux/api/store-creation.api';

export const fetchStoreSettingsData = async () => {
  try {
    const storeSettingsRequest = store.dispatch(
      storeSettingsApi.endpoints.getStoreSettings.initiate(),
    );
    await storeSettingsRequest.unwrap();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
};
