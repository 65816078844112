import { useEffect } from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { ROUTES } from 'constants/router';
import { useAuth } from 'hooks/auth.hook';
import { getPublicSid } from 'utils/auth.util';
import { useGetCompanySettingsMutation } from 'redux/api/settings.api';
import { useAppSelector } from 'redux/store';
import { isContractSignedSelector } from 'redux/selectors/settings.selectors';
import { isOwner } from 'boot/ability';

export function SkippedContractRoute({ children }: React.PropsWithChildren): JSX.Element {
  const { isAuthorized } = useAuth();
  const location = useLocation();
  const [getSettings] = useGetCompanySettingsMutation();
  const isContractSigned = useAppSelector(isContractSignedSelector);

  useEffect(() => {
    if (getPublicSid()) {
      getSettings();
    }
  }, [location.pathname]);

  if (!isAuthorized || !isOwner() || isContractSigned) {
    return <Navigate to={ROUTES.DASHBOARD} replace />;
  }

  return children ? <>{children}</> : <Outlet />;
}
