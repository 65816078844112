import { useState } from 'react';
import { TabsNavigation } from '@ezetech/swag-space-x';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { customersLeadsToggleSelector } from 'redux/selectors/customers-leads.selectors';
import { resetFilters } from 'redux/slices/filter.slice';

import classes from './tabs.module.scss';

export function Tabs(): JSX.Element {
  const [selectedTab, setTab] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const toggles = useAppSelector(customersLeadsToggleSelector);

  const handleClick = (id: string) => {
    navigate(id);
    dispatch(resetFilters());
    setTab(id);
  };

  return (
    <TabsNavigation
      sideOffset={30}
      className={classes.container}
      tabClassName={classes.tabs}
      mobileClassName={classes.mobileContainer}
      mobileDropdownItemClassName={classes.mobileDropdownItem}
      mobileArrowClassName={classes.arrow}
      tabs={toggles}
      selectedTab={selectedTab || location.pathname}
      onChangeTab={handleClick}
    />
  );
}
