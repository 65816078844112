import cn from 'classnames';
import { Toggle, TogglePlacement, ToggleWidth } from '@ezetech/swag-space-x';

import css from './product-selection.module.scss';

export function CustomItemTimelineTogglesWidget({
  onCustomersReviewMockupsToggleClick,
  isReviewMockupsByCustomerAllowed,
  onCustomersReviewPantonesToggleClick,
  isReviewPantonesByCustomerAllowed,
  disableLine2ClassName,
  toggleClassName,
}: {
  toggleClassName?: string;
  isReviewMockupsByCustomerAllowed: boolean;
  isReviewPantonesByCustomerAllowed: boolean;
  disableLine2ClassName?: string;
  onCustomersReviewMockupsToggleClick: () => void;
  onCustomersReviewPantonesToggleClick: () => void;
}): JSX.Element {
  return (
    <div className={cn(css.disableLine2Toggles, disableLine2ClassName)}>
      <Toggle
        className={toggleClassName}
        onChange={onCustomersReviewPantonesToggleClick}
        value={isReviewPantonesByCustomerAllowed}
        label="Customers review Pantones"
        name="Customers review Pantones"
        width={ToggleWidth.short}
        placement={TogglePlacement.right}
      />
      <div className={css.verticalDividerToggles} />
      <Toggle
        className={toggleClassName}
        onChange={onCustomersReviewMockupsToggleClick}
        value={isReviewMockupsByCustomerAllowed}
        label="Customers review Mockups"
        name="Customers review Mockups"
        width={ToggleWidth.short}
        placement={TogglePlacement.right}
      />
    </div>
  );
}
