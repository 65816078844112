import {
  Dialog,
  DialogContentWrapper,
  DialogTitle,
  DialogDescription,
  DialogFooter,
  SwagButton,
  Typography,
} from '@ezetech/swag-space-x';
import { useAppDispatch } from 'redux/store';
import { closePopup } from 'redux/slices/modals.slice';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { ReviewColorsSuccessPopupProps } from 'interfaces/popup.interface';
import { useRefetchCustomerOrders } from 'hooks/use-refetch-orders.hook';
import css from './review-colors-success.popup.module.scss';

export const ReviewColorsSuccessPopup = ({
  isOpen,
  isReviewPantonesByCustomerAllowed,
}: ReviewColorsSuccessPopupProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const refetchOrders = useRefetchCustomerOrders();

  const onBtnClick = () => {
    refetchOrders();
    dispatch(closePopup());
  };

  return (
    <Dialog open={isOpen} onOpenChange={onBtnClick}>
      <DialogContentWrapper
        className={css.dialogContentWrapperClassName}
        overlayClassName={css.dialogOverlayClassName}
      >
        <DialogTitle>Review Colors Completed</DialogTitle>
        <DialogDescription className={css.textWrapper}>
          <Typography color={COLORS.colorTextSubdued} fontType="bodyMd">
            Amazing, your Pantones have now been approved
            {isReviewPantonesByCustomerAllowed
              ? ` and we will send these to your
            customer to approve.`
              : '.'}
          </Typography>
        </DialogDescription>

        <DialogFooter>
          <SwagButton type="primary" onClick={onBtnClick} className={css.btn}>
            Close
          </SwagButton>
        </DialogFooter>
      </DialogContentWrapper>
    </Dialog>
  );
};
