import React, { useCallback, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import {
  SwagButton,
  Loader,
  Divider,
  InfinityScroll,
  InfinityListType,
  InfinityRowProps,
} from '@ezetech/swag-space-x';

import { useAppSelector, useAppDispatch } from 'redux/store';
import { closePopup, openPopup } from 'redux/slices/modals.slice';
import { IResellerCertificatesResponse } from 'interfaces/reseller-certificate.interfaces';
import { resellerCertificatesSelector } from 'redux/selectors/reseller-certificates.selectors';
import {
  useDeleteCertificateMutation,
  useGetCertificatesMutation,
} from 'redux/api/reseller-certificates.api';
import {
  RESELLER_CERTIFICATE_UPLOAD_POPUP,
  CONFIRMATION_POPUP,
} from 'components/popups/_logic/popups-list';
import { useIsMobileView } from 'hooks/is-mobile-view.hook';
import { cannot, SUBJECTS } from 'boot/ability';

import {
  ResellerCertificatesItem,
  ResellerCertificatesItemType,
  ResellerCertificatesItemProps,
} from './reseller-certificates-item';
import css from './reseller-certificates.module.scss';

const MAX_VISIBLE_ITEMS_ON_PAGE = 3;

type itemRenderProps = InfinityRowProps<{
  items: ResellerCertificatesItemType[];
  isDeleteHidden: ResellerCertificatesItemProps['isDeleteHidden'];
  onDeleteCertificate: (id: string, index: number) => void;
}>;

export const ResellerCertificatesForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const certificates = useAppSelector(resellerCertificatesSelector);
  const [deleteCertificate, { isLoading: isLoadingDelete }] =
    useDeleteCertificateMutation();
  const [getCertificates] = useGetCertificatesMutation();
  const contentRef = useRef<HTMLDivElement>(null);
  const contentListRef = useRef<HTMLDivElement>(null);
  const isFixedHeight = useRef(false);
  const listRef = useRef<InfinityListType>(null);
  const [certificatesList, setCertificates] = useState<ResellerCertificatesItemType[]>(
    [],
  );
  const hasNextPage = certificatesList.length < certificates.total;
  const [isNextPageLoading, setIsNextPageLoading] = useState(false);
  const isMobile = useIsMobileView(768);
  const ITEM_HEIGHT = isMobile ? 177 : 116;
  const isLoading = certificates.isLoading;
  const isNoItems = !isLoading && !certificates.list?.length;

  isFixedHeight.current = certificates.total >= MAX_VISIBLE_ITEMS_ON_PAGE;

  const isUploadHidden = cannot(
    SUBJECTS.RESELLER_CERTIFICATES.actions.RESELLER_CERTIFICATES_UPLOAD,
    SUBJECTS.RESELLER_CERTIFICATES.value,
  );

  const isDeleteHidden = cannot(
    SUBJECTS.RESELLER_CERTIFICATES.actions.RESELLER_CERTIFICATES_DELETE,
    SUBJECTS.RESELLER_CERTIFICATES.value,
  );

  const setScrollToIndex = useCallback(
    (index: number) => {
      if (!listRef?.current) {
        return;
      }
      listRef.current.scrollToItem(index, 'start');
    },
    [listRef?.current],
  );

  const fetchCertificates = async (page: number) => {
    if (isNextPageLoading) {
      return;
    }
    setIsNextPageLoading(true);
    const result = await getCertificates({
      page: page,
    });

    if ('data' in result) {
      const data = result?.data as unknown as IResellerCertificatesResponse;
      const listWithKey = data.list.map((item) => ({ ...item, key: item.id }));
      const newList = page === 1 ? listWithKey : [...certificatesList, ...listWithKey];

      setCertificates(newList);
      setIsNextPageLoading(false);
    }
  };

  useEffect(() => {
    fetchCertificates(1);
  }, []);

  const handleNewPageLoad = async () => {
    fetchCertificates(certificates.page + 1);
  };

  const onDeleteCertificate = (id: string, index: number) => {
    dispatch(
      openPopup({
        popupName: CONFIRMATION_POPUP,
        popupProps: {
          title: 'Delete certificate confirmation',
          descriptionComponent: 'Are you sure you want to delete the certificate?',
          onCancel: () => {
            dispatch(closePopup());
          },
          onConfirm: async () => {
            await deleteCertificate({ id });
            await fetchCertificates(1);
            setScrollToIndex(index || 0);
          },
          isConfirmLoading: isLoadingDelete,
        },
      }),
    );
  };

  const onClickUploadCertificate = () => {
    dispatch(
      openPopup({
        popupName: RESELLER_CERTIFICATE_UPLOAD_POPUP,
        popupProps: {
          onSubmit: async () => {
            await fetchCertificates(1);
            setScrollToIndex(0);
          },
        },
      }),
    );
  };

  useEffect(() => {
    if (!contentRef.current?.style) {
      return;
    }
    const length =
      certificatesList.length > MAX_VISIBLE_ITEMS_ON_PAGE
        ? MAX_VISIBLE_ITEMS_ON_PAGE
        : certificatesList.length;

    if (isFixedHeight.current || !length) {
      contentRef.current.style.height = '';
    } else {
      contentRef.current.style.height = length * ITEM_HEIGHT + 'px';
    }
  }, [certificatesList.length]);

  const rowRenderer = useCallback(
    ({ index, data, style }: itemRenderProps) => {
      const certificate = data.items[index];
      if (!certificate) {
        return <div>Loading...</div>;
      }
      return (
        <div style={style} className={css.item}>
          <ResellerCertificatesItem
            {...certificate}
            isDeleteHidden={data.isDeleteHidden}
            onDelete={(id) => data.onDeleteCertificate(id, index)}
          />
          <Divider className={css.divider} />
        </div>
      );
    },
    [css.item],
  );

  const itemProps = {
    isDeleteHidden,
    onDeleteCertificate,
  };

  return (
    <div className={css.certificates}>
      {!isNoItems && <Divider />}
      <div
        className={cn(css.content, {
          [css.fixedHeightContent]: isFixedHeight.current,
          [css.contentWithBottomBorder]: !isNoItems,
        })}
        ref={contentRef}
      >
        {isLoading ? (
          <div className={css.loader}>
            <Loader />
          </div>
        ) : null}
        <div className={css.contentList} ref={contentListRef}>
          {isNoItems && (
            <div className={css.noCertificates}>No Certificates Uploaded</div>
          )}
          <InfinityScroll
            itemHeight={ITEM_HEIGHT}
            items={certificatesList}
            loadPage={handleNewPageLoad}
            row={rowRenderer}
            hasNextPage={hasNextPage}
            isLoading={isLoading}
            itemProps={itemProps}
            listRef={listRef}
          />
        </div>
      </div>
      <div className={css.footer}>
        {!isUploadHidden && (
          <SwagButton
            className={css.button}
            onClick={onClickUploadCertificate}
            type="primary"
            size={'large'}
          >
            Upload Certificate
          </SwagButton>
        )}
      </div>
    </div>
  );
};
