import { createSelector } from 'reselect';

import { featureFlagNames } from 'constants/feature-flag.constant';
import { RootState } from '../store';

export const technicalDataSelector = (state: RootState) => state.technicalData;

export const featureFlagsSelector = createSelector(
  technicalDataSelector,
  (user) => user.featureFlagList,
);

export const isResellerCertificatesFeatureEnabledSelector = createSelector(
  featureFlagsSelector,
  (featureFlagList) => !!featureFlagList[featureFlagNames.RESELLER_CERTIFICATES],
);

export const isHomepageEditorFeatureEnabledSelector = createSelector(
  featureFlagsSelector,
  (featureFlagList) => !!featureFlagList[featureFlagNames.HOMEPAGE_EDITOR],
);

export const isResellerInvitationFeatureEnabledSelector = createSelector(
  featureFlagsSelector,
  (featureFlagList) => !!featureFlagList[featureFlagNames.RESELLER_INVITATION],
);
