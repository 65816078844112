import { useForm, SubmitHandler, FieldValues } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';
import { SwagButton } from '@ezetech/swag-space-x';
import { UserCreatePasswordType } from 'interfaces';
import { InputForm } from 'components/input-form';
import {
  PasswordRequirementsBlock,
  usePasswordRequirementsHook,
} from 'components/password-requirements-block';
import { CreatePasswordFormProps } from '../create-password.interface';
import { FIELDS, createPasswordFormSchema } from './create-password-form.constants';
import css from './create-password-form.module.scss';

export function CreatePasswordForm({
  onSubmit: onFormSubmit,
  loading,
}: CreatePasswordFormProps<UserCreatePasswordType>) {
  const { handleSubmit, control, formState } = useForm<FieldValues>({
    mode: 'all',
    resolver: yupResolver(createPasswordFormSchema),
  });
  const { token } = useParams();
  const { isValid } = formState;

  const {
    onFieldChange,
    onFieldFocus,
    completedRequirements,
    isFocused,
    isAllRequirementsCompleted,
    isLengthError,
  } = usePasswordRequirementsHook();

  const passwordRepeatRules = {
    onChange: onFieldChange,
  };

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    onFormSubmit({ ...data, token } as UserCreatePasswordType);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={css.form}>
      <div className={css.formFields}>
        <InputForm
          type="password"
          label={FIELDS.password.label}
          name={FIELDS.password.name}
          placeholder={FIELDS.password.placeholder}
          rules={passwordRepeatRules}
          onFocus={onFieldFocus}
          control={control}
        />

        <InputForm
          type="password"
          label={FIELDS.passwordRepeat.label}
          name={FIELDS.passwordRepeat.name}
          placeholder={FIELDS.passwordRepeat.placeholder}
          control={control}
        />
        <div>
          <PasswordRequirementsBlock
            isLengthMoreThanMaxAllowedCharacters={isLengthError}
            completedRequirements={completedRequirements}
            isAllRequirementsCompleted={isValid && isAllRequirementsCompleted}
            isInputFocused={isFocused}
          />
        </div>
      </div>
      <SwagButton
        type="primary"
        size="xl"
        htmlType="submit"
        fullWidth
        disabled={!isValid || !isAllRequirementsCompleted}
        loading={loading}
      >
        Login
      </SwagButton>
    </form>
  );
}
