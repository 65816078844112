import { FC } from 'react';
import { PageContent } from 'components/page-content';
import { Profile } from './components/profile';
import { ChangePassword } from './components/change-password';

import css from './profile-settings.module.scss';

export const ProfileSettings: FC = () => {
  return (
    <PageContent childrenClassName={css.container}>
      <div className={css.root}>
        <Profile />
        <ChangePassword />
      </div>
    </PageContent>
  );
};
