import { SwagButton } from '@ezetech/swag-space-x';

import css from './header.module.scss';

type EditHomepageHeaderProps = {
  onSave?: () => void;
  onClose?: () => void;
  disabledSaveButton?: boolean;
  isEditMode: boolean;
};

export const EditHomepageHeader = (props: EditHomepageHeaderProps): JSX.Element => {
  const { disabledSaveButton, onClose, onSave, isEditMode } = props;
  return (
    <div className={css.header}>
      <SwagButton type="outlined" onClick={onClose} className={css.discardButton}>
        Exit
      </SwagButton>
      {isEditMode && (
        <SwagButton disabled={disabledSaveButton} type="primary" onClick={onSave}>
          Save
        </SwagButton>
      )}
    </div>
  );
};
