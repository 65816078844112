import { useCallback, useEffect, useState } from 'react';
import { RainbowBox } from '@ezetech/swag-space-x';
import { BoxWidgetEnum } from 'enums/box-widget-enum';
import { BoxWidget } from 'components/box-widget';
import { FadeInOut } from 'components/fade-inout';
import { ColoredContainerHeader } from 'components/colored-container-header';
import { getErrorMessages } from 'utils/query.util';
import { UserResetPasswordType } from 'interfaces/auth-api.interface';
import { useResetPasswordMutation as resetPassword } from 'redux/api/auth.api';
import { ResetPasswordForm } from './form/reset-password-form';

import { Submitted } from './submited';

import css from './reset-password.module.scss';

export function ResetPassword() {
  const [step, setStep] = useState('resetPassword');
  const [error, setError] = useState('');
  const [resetPass, { isLoading, isSuccess, isError, error: errorResult }] =
    resetPassword();

  useEffect(() => {
    if (isSuccess) {
      setStep('submitted');
    }
    if (isError) {
      const msg = getErrorMessages(errorResult);
      setError(msg);
    }
  }, [isLoading]);

  const onSubmit = useCallback((data: UserResetPasswordType) => {
    resetPass(data);
  }, []);

  const resetPasswordState = step === 'submitted' ? 'fadeOut' : 'show';
  const submittedState = step === 'submitted' ? 'fadeIn' : 'hide';

  return (
    <>
      <FadeInOut state={resetPasswordState}>
        <RainbowBox borderWidth={8} innerClassName={css.rainbowBlock}>
          <div className={css.resetPassword}>
            <ColoredContainerHeader
              className={css.textClassName}
              title="Uh oh! forgot your password?"
              description="Don't worry, it happens to the best of us. Enter your email and we will email you a reset link."
            />
            <BoxWidget type={BoxWidgetEnum.REGULAR} className={css.formContainer}>
              <ResetPasswordForm
                onSubmit={onSubmit}
                loading={isLoading}
                error={error}
                setStep={setStep}
              />
            </BoxWidget>
          </div>
        </RainbowBox>
      </FadeInOut>
      <FadeInOut state={submittedState} delay={1}>
        <RainbowBox borderWidth={8} innerClassName={css.rainbowBlock}>
          <Submitted />
        </RainbowBox>
      </FadeInOut>
    </>
  );
}
