import { aPMS, aRGB } from '../constants/colors';

const pantoneNumberRegexp = /^(.+[^\s])(\s?[Cc]{1})$/;

function normalizePantoneName(pms: string): string {
  const lowerCasedPms = pms.toLowerCase();

  const unformattedPantoneFound = aPMS.includes(lowerCasedPms);
  if (unformattedPantoneFound) {
    return lowerCasedPms;
  }
  const pantoneNumberExtracted = pantoneNumberRegexp.exec(lowerCasedPms);
  const pantoneName = pantoneNumberExtracted && pantoneNumberExtracted[1];

  return pantoneName || '';
}

function findPantoneIndex(pms: string) {
  const normalizedPms = normalizePantoneName(pms);

  return aPMS.indexOf(normalizedPms);
}

export function PMS2HEX(pms = ''): string {
  const pantoneIndex = findPantoneIndex(pms);
  if (pantoneIndex >= 0) {
    return aRGB[pantoneIndex];
  }

  return '';
}
