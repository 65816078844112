import {
  Typography,
  Tooltip,
  TooltipPlacement,
  SwagButton,
  XL_BTN,
} from '@ezetech/swag-space-x';
import classnames from 'classnames';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { orderCardFooterComponentSelector } from 'redux/selectors/orders.selectors';
import { API_URL } from 'redux/api/helpers';
import { useRetryPaymentMutation } from 'redux/api/orders.api';
import { openPopup } from 'redux/slices/modals.slice';
import { SUBJECTS } from 'constants/ability.constant';
import { can } from 'boot/ability';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import infoIcon from 'assets/svg/information.svg';
import { SEND_AN_INVOICE_POPUP } from 'components/popups/_logic/popups-list';
import css from './order-card-footer.module.scss';

interface IProps {
  orderId: string;
}

export const OrderCardFooterComponent = ({ orderId }: IProps) => {
  const {
    showInvoiceLink,
    isAllItemsRejected,
    isAdjustmentPriceTooltipShown,
    showSampleDeductionDiscount,
    sampleDeductionDiscount,
    showDiscount,
    discount,
    showShipmentDiscount,
    shipmentDiscount,
    showSetupFeeDiscount,
    setupFeeDiscount,
    showTaxesPrice,
    taxesPrice,
    showShipping,
    shippingPrice,
    orderTotal,
    showRetryWidget,
    showGenerateInvoiceLink,
    resellerProfit,
    isResellerProfitNegative,
  } = useAppSelector(orderCardFooterComponentSelector({ orderId }));
  const dispatch = useAppDispatch();

  const [retryPayment, { isLoading }] = useRetryPaymentMutation();

  const handleSendClick = () => {
    dispatch(openPopup({ popupName: SEND_AN_INVOICE_POPUP, popupProps: { orderId } }));
  };
  const downloadInvoiceAccess = can(
    SUBJECTS.DOWNLOAD_INVOICE.actions.DOWNLOAD_ORDER_INVOICE,
    SUBJECTS.DOWNLOAD_INVOICE.value,
  );

  const sendInvoiceAccess = can(
    SUBJECTS.SEND_INVOICE.actions.SEND_INVOICE,
    SUBJECTS.SEND_INVOICE.value,
  );

  const renderButtons = () => {
    if (showInvoiceLink && downloadInvoiceAccess) {
      return (
        <div className={css.buttonWrapper}>
          <a className={css.button} href={`${API_URL}/order/invoice/${orderId}`}>
            <Typography
              lineHeight="180%"
              fontType="bodyMdBold"
              color={COLORS.colorPrimaryText}
            >
              Download Invoice
            </Typography>
          </a>
        </div>
      );
    }

    if (showGenerateInvoiceLink && sendInvoiceAccess) {
      return (
        <SwagButton
          className={css.swagButton}
          onClick={handleSendClick}
          type="primary"
          size={XL_BTN}
        >
          <Typography lineHeight="180%" fontType="bodyMdBold" color={COLORS.colorWhite}>
            Send an invoice
          </Typography>
        </SwagButton>
      );
    }

    return <div />;
  };

  return (
    <div className={css.footer}>
      {renderButtons()}
      <div className={css.paymentAndPriceWrapper}>
        {showRetryWidget && (
          <div className={css.paymentFailedWrapper}>
            <Typography
              lineHeight="162.5%"
              fontType="bodyMd"
              className={css.paymentRetryText}
              color={COLORS.colorErrorText}
            >
              Payment failed. Please check your payment details or try again.
            </Typography>
            <a onClick={() => !isLoading && retryPayment(orderId)}>Re-Try Payment</a>
          </div>
        )}
        <div className={css.priceWrapper}>
          {!isAllItemsRejected && (
            <>
              {showSampleDeductionDiscount && (
                <div
                  className={classnames(css.price, {
                    [css.pricePaymentFailed]: showRetryWidget,
                  })}
                >
                  <Typography
                    lineHeight="180%"
                    fontType="bodyMd"
                    color={COLORS.colorSecondaryText}
                  >
                    Discount (Printed sample cost):
                  </Typography>
                  <Typography
                    data-order-field="sample-discount"
                    lineHeight="180%"
                    fontType="bodyMdBold"
                    className={css.priceTag}
                    color={COLORS.colorPrimaryText}
                  >
                    {sampleDeductionDiscount}
                  </Typography>
                </div>
              )}
              {showDiscount && (
                <div
                  className={classnames(css.price, {
                    [css.pricePaymentFailed]: showRetryWidget,
                  })}
                >
                  <Typography
                    lineHeight="180%"
                    fontType="bodyMd"
                    color={COLORS.colorSecondaryText}
                  >
                    Discount:
                  </Typography>
                  <Typography
                    data-order-field="discount"
                    lineHeight="180%"
                    fontType="bodyMdBold"
                    className={css.priceTag}
                    color={COLORS.colorPrimaryText}
                  >
                    {discount}
                  </Typography>
                </div>
              )}
              {showShipmentDiscount && (
                <div
                  className={classnames(css.price, {
                    [css.pricePaymentFailed]: showRetryWidget,
                  })}
                >
                  <Typography
                    lineHeight="180%"
                    fontType="bodyMd"
                    color={COLORS.colorSecondaryText}
                  >
                    Free shipping:
                  </Typography>
                  <Typography
                    data-order-field="free-shipping"
                    lineHeight="180%"
                    fontType="bodyMdBold"
                    className={css.priceTag}
                    color={COLORS.colorPrimaryText}
                  >
                    {shipmentDiscount}
                  </Typography>
                </div>
              )}
              {showSetupFeeDiscount && (
                <div
                  className={classnames(css.price, {
                    [css.pricePaymentFailed]: showRetryWidget,
                  })}
                >
                  <Typography
                    lineHeight="180%"
                    fontType="bodyMd"
                    color={COLORS.colorSecondaryText}
                  >
                    Free setup fee:
                  </Typography>
                  <Typography
                    data-order-field="free-setup-fee"
                    lineHeight="180%"
                    fontType="bodyMdBold"
                    className={css.priceTag}
                    color={COLORS.colorPrimaryText}
                  >
                    {setupFeeDiscount}
                  </Typography>
                </div>
              )}
              {showTaxesPrice && (
                <div
                  className={classnames(css.price, {
                    [css.pricePaymentFailed]: showRetryWidget,
                  })}
                >
                  <Typography
                    lineHeight="180%"
                    fontType="bodyMd"
                    color={COLORS.colorSecondaryText}
                  >
                    Taxes:
                  </Typography>
                  <Typography
                    data-order-field="taxes"
                    lineHeight="180%"
                    fontType="bodyMdBold"
                    className={css.priceTag}
                    color={COLORS.colorPrimaryText}
                  >
                    {taxesPrice}
                  </Typography>
                </div>
              )}
              {showShipping && (
                <div
                  className={classnames(css.price, {
                    [css.pricePaymentFailed]: showRetryWidget,
                  })}
                >
                  <Typography
                    lineHeight="180%"
                    fontType="bodyMd"
                    color={COLORS.colorSecondaryText}
                  >
                    Shipping:
                  </Typography>
                  <Typography
                    data-order-field="shipping"
                    lineHeight="180%"
                    fontType="bodyMdBold"
                    className={css.priceTag}
                    color={COLORS.colorPrimaryText}
                  >
                    {shippingPrice}
                  </Typography>
                </div>
              )}
            </>
          )}
          <div
            className={classnames(css.price, {
              [css.pricePaymentFailed]: showRetryWidget,
            })}
          >
            <div className={css.totalLabel}>
              <Typography
                lineHeight="180%"
                fontType="bodyMd"
                color={COLORS.colorSecondaryText}
              >
                Order Total:
              </Typography>
              {isAdjustmentPriceTooltipShown && (
                <Tooltip
                  placement={TooltipPlacement.left}
                  content={<img src={infoIcon} alt="infoIcon" className={css.infoIcon} />}
                >
                  <div className={css.priceAdjustmentTooltipContent}>
                    <Typography
                      lineHeight="150%"
                      fontType="bodySm"
                      color={COLORS.colorPrimaryText}
                    >
                      Due to the item adjustment in your order the order total may be
                      different from the actual amount paid. No further action required.
                    </Typography>
                  </div>
                </Tooltip>
              )}
            </div>
            <Typography
              data-order-field="total"
              lineHeight="180%"
              fontType="bodyMdBold"
              color={COLORS.colorPrimaryText}
              className={classnames(css.priceTag, {
                [css.cancelled]: isAllItemsRejected,
              })}
            >
              {orderTotal}
            </Typography>
          </div>
          <div
            className={classnames(css.price, {
              [css.pricePaymentFailed]: showRetryWidget,
            })}
          >
            <Typography
              lineHeight="180%"
              fontType="bodyMd"
              color={COLORS.colorSecondaryText}
            >
              You made:
            </Typography>
            <Typography
              data-order-field="youMade"
              lineHeight="180%"
              fontType="bodyMdBold"
              className={css.priceTag}
              color={
                isResellerProfitNegative ? COLORS.colorErrorText : COLORS.colorSuccess700
              }
            >
              {resellerProfit}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};
