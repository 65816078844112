import { Typography, TierTrophyIcon } from '@ezetech/swag-space-x';
import COLORS from 'constants/styles/colors-js.constant.module.scss';

import { useAppSelector } from '../../../redux/store';
import { dashboardSelector } from '../../../redux/selectors/dashboard.selectors';
import { getCalculatedDataForTier, getColorByTierName } from './tier-card.utils';

import css from './tier-card-progress-highest.module.scss';

export function TierCardProgressHighest(): JSX.Element {
  const currencySymbol = '$';
  const data = useAppSelector(dashboardSelector);
  const { bottomThresholdFormatted } = getCalculatedDataForTier(data);
  const tierColor = getColorByTierName(data.tierPromotion.name);

  return (
    <div className={css.root}>
      <Typography lineHeight="162%" fontType="bodyMd" color={COLORS.colorPrimaryText}>
        <div className={css.textBlock}>
          <div className={css.icon}>
            <TierTrophyIcon backgroundColor={tierColor} />
          </div>
          You are currently on the highest tier. Make above {currencySymbol}
          {bottomThresholdFormatted} in revenue per year to stay in tier 3.
        </div>
      </Typography>
    </div>
  );
}
