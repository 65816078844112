import { useAppSelector } from 'redux/store';
import { popupNameSelector, popupPropsSelector } from 'redux/selectors/modals.selectors';
import { POPUP_LIST_NAMES, POPUPS_LIST } from './popups-list';

type PopupNames = keyof typeof POPUP_LIST_NAMES;

export const Popup = () => {
  const popupName = useAppSelector(popupNameSelector);
  const popupProps = useAppSelector(popupPropsSelector);

  if (!popupName) {
    return null;
  }

  return (
    <>
      {Object.keys(POPUPS_LIST).map((name) => {
        const RenderPopup = POPUPS_LIST[name as PopupNames];

        if (!RenderPopup) {
          return null;
        }

        return <RenderPopup key={name} {...popupProps} isOpen={name === popupName} />;
      })}
    </>
  );
};
