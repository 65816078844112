import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { IconType } from './type';

export const QuestionMarkIcon = ({
  className,
  fillColor = COLORS.colorTextIcon,
  width = '16px',
  height = '16px',
}: IconType): JSX.Element => {
  const style = { width, height, lineHeight: 0 };

  return (
    <div style={style} className={className}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.5 8C14.5 11.5899 11.5899 14.5 8 14.5C4.41015 14.5 1.5 11.5899 1.5 8C1.5 4.41015 4.41015 1.5 8 1.5C11.5899 1.5 14.5 4.41015 14.5 8ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM8.79882 9.71122C8.80276 9.42467 8.8284 9.21334 8.87574 9.07723C8.95069 8.86232 9.10256 8.67428 9.33136 8.5131L9.75148 8.2176C10.1775 7.91672 10.4655 7.66958 10.6154 7.47616C10.8718 7.15738 11 6.76517 11 6.29953C11 5.54018 10.7041 4.96709 10.1124 4.58026C9.52465 4.19342 8.78501 4 7.89349 4C7.21499 4 6.643 4.13611 6.17752 4.40833C5.43984 4.83456 5.04734 5.55809 5 6.57891H6.71006C6.71006 6.28162 6.80473 5.99508 6.99408 5.71927C7.18738 5.44347 7.51282 5.30557 7.97041 5.30557C8.4359 5.30557 8.75542 5.4184 8.92899 5.64406C9.10651 5.86613 9.19527 6.11328 9.19527 6.38549C9.19527 6.62189 9.11637 6.83859 8.95858 7.03559C8.87179 7.15021 8.7574 7.25588 8.61539 7.35259L8.18343 7.65883C7.7574 7.9597 7.4931 8.22655 7.39053 8.45937C7.28797 8.68861 7.22485 9.10589 7.20118 9.71122H8.79882ZM8.14672 12C8.57549 12 8.92308 11.6524 8.92308 11.2236C8.92308 10.7949 8.57549 10.4473 8.14672 10.4473H7.93612C7.50735 10.4473 7.15976 10.7949 7.15976 11.2236C7.15976 11.6524 7.50735 12 7.93612 12H8.14672Z"
          fill={fillColor}
        />
      </svg>
    </div>
  );
};
