import { FC, useState, ChangeEvent, useEffect } from 'react';
import cn from 'classnames';
import { Typography, Input, Tooltip, TooltipPlacement } from '@ezetech/swag-space-x';
import { Price } from 'utils/price.util';
import { FREE } from 'constants/common';
import { isBox } from 'utils/orders.util';
import { capitalize } from 'utils/string.util';
import { IItem } from 'interfaces/orders.interface';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import css from './order-items-table.module.scss';

export const OrderItemsTableRow: FC<{
  item: IItem;
  isLast: boolean;
  onTaxChange(itemId: string, value: string): void;
  isTaxExempt: boolean;
}> = ({ item, onTaxChange, isLast, isTaxExempt }) => {
  const [tax, setTax] = useState((item.tax / 100).toFixed(2));
  const [total, setTotal] = useState(item.itemTotalPrice + item.tax);
  const hideShipping = !isBox(item.productType) && item.boxItemsId;

  useEffect(() => {
    const taxValue = isTaxExempt ? '0' : (item.tax / 100).toFixed(2);
    onTaxChange(item.id, taxValue);
  }, []);

  const handleTaxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    if (!value) {
      setTax('');
      setTotal(item.itemTotalPrice);
      onTaxChange(item.id, value);
      return;
    }

    const isValid = /^(?!0{2,})\d*\.?\d{0,2}$/.test(value);

    if (!isValid) {
      return;
    }

    const itemTotalPrice = item.itemTotalPrice + parseFloat(value) * 100;

    setTax(value);
    setTotal(itemTotalPrice);
    onTaxChange(item.id, value);
  };

  return (
    <>
      <div className={css.row}>
        <div className={css.name}>
          <Typography
            className={css.title}
            color={COLORS.colorText500}
            fontType="bodyMd"
            lineHeight="162.5%"
          >
            {capitalize(item.name)}
          </Typography>
        </div>
        <div className={css.quantity}>
          <Typography
            className={css.title}
            color={COLORS.colorText500}
            fontType="bodyMd"
            lineHeight="162.5%"
          >
            {item.quantity}
          </Typography>
        </div>
        <div className={css.unitPrice}>
          <Typography
            className={css.title}
            color={COLORS.colorText500}
            fontType="bodyMd"
            lineHeight="162.5%"
          >
            {Price.formatPrice(item.pricePerItem + (item.kittingFee || 0))}
          </Typography>
        </div>
        <div className={css.setupFee}>
          <Typography
            className={css.title}
            color={COLORS.colorText500}
            fontType="bodyMd"
            lineHeight="162.5%"
          >
            {Price.formatPrice(item.setupFee)}
          </Typography>
        </div>
        <div className={css.shipping}>
          <Typography
            className={css.title}
            color={COLORS.colorText500}
            fontType="bodyMd"
            lineHeight="162.5%"
          >
            {hideShipping ? '-' : Price.formatPrice(item.shipping)}
          </Typography>
        </div>
        <div className={css.tax}>
          <Tooltip
            placement={TooltipPlacement.top}
            disabled={!isTaxExempt}
            content={
              <Input
                value={tax}
                step="any"
                lang="en"
                pattern="^(\d*\.)?\d+$"
                adornmentStart="$"
                onChange={handleTaxChange}
                inputClassName={css.pink}
                errorClassName={css.error}
                adornmentStartClassName={isTaxExempt ? css.colorDisabled : css.pink}
                disabled={isTaxExempt}
              />
            }
          >
            <Typography color={COLORS.colorPrimaryText}>
              This is tax exempt order
            </Typography>
          </Tooltip>
        </div>
        <div className={css.amount}>
          <Typography
            className={css.title}
            color={COLORS.colorText500}
            fontType="bodyMd"
            lineHeight="162.5%"
          >
            {total > 0 ? Price.formatPrice(total) : FREE}
          </Typography>
        </div>
      </div>
      <div className={cn(css.column, { [css.reset]: isLast })}>
        <div className={css.block}>
          <div>
            <Typography
              className={css.name}
              color={COLORS.colorText900}
              fontType="bodyMd"
              lineHeight="162.5%"
            >
              Product:
            </Typography>
          </div>
          <div>
            <Typography
              className={css.title}
              color={COLORS.colorText500}
              fontType="bodyMd"
              lineHeight="162.5%"
            >
              {capitalize(item.name)}
            </Typography>
          </div>
        </div>
        <div className={css.block}>
          <div>
            <Typography
              className={css.name}
              color={COLORS.colorText900}
              fontType="bodyMd"
              lineHeight="162.5%"
            >
              Quantity:
            </Typography>
          </div>
          <div>
            <Typography
              className={css.title}
              color={COLORS.colorText500}
              fontType="bodyMd"
              lineHeight="162.5%"
            >
              {item.quantity}
            </Typography>
          </div>
        </div>
        <div className={css.block}>
          <div>
            <Typography
              className={css.name}
              color={COLORS.colorText900}
              fontType="bodyMd"
              lineHeight="162.5%"
            >
              Unit price:
            </Typography>
          </div>
          <div>
            <Typography
              className={css.title}
              color={COLORS.colorText500}
              fontType="bodyMd"
              lineHeight="162.5%"
            >
              {Price.formatPrice(item.pricePerItem)}
            </Typography>
          </div>
        </div>
        <div className={css.block}>
          <div>
            <Typography
              className={css.name}
              color={COLORS.colorText900}
              fontType="bodyMd"
              lineHeight="162.5%"
            >
              Setup fee:
            </Typography>
          </div>
          <div>
            <Typography
              className={css.title}
              color={COLORS.colorText500}
              fontType="bodyMd"
              lineHeight="162.5%"
            >
              {Price.formatPrice(item.setupFee)}
            </Typography>
          </div>
        </div>
        <div className={css.block}>
          <div>
            <Typography
              className={css.name}
              color={COLORS.colorText900}
              fontType="bodyMd"
              lineHeight="162.5%"
            >
              Shipping:
            </Typography>
          </div>
          <div>
            <Typography
              className={css.title}
              color={COLORS.colorText500}
              fontType="bodyMd"
              lineHeight="162.5%"
            >
              {hideShipping ? '-' : Price.formatPrice(item.shipping)}
            </Typography>
          </div>
        </div>
        <div className={css.block}>
          <div>
            <Typography
              className={css.name}
              color={COLORS.colorText900}
              fontType="bodyMd"
              lineHeight="162.5%"
            >
              Tax:
            </Typography>
          </div>
          <div>
            <Input
              value={tax}
              step="any"
              lang="en"
              pattern="^(\d*\.)?\d+$"
              adornmentStart="$"
              onChange={handleTaxChange}
              errorClassName={css.error}
              adornmentStartClassName={css.pink}
              inputClassName={css.pink}
              className={css.input}
            />
          </div>
        </div>
        <div className={css.block}>
          <div>
            <Typography
              className={css.name}
              color={COLORS.colorText900}
              fontType="bodyMd"
              lineHeight="162.5%"
            >
              Amount USD:
            </Typography>
          </div>
          <div>
            <Typography
              className={css.title}
              color={COLORS.colorText500}
              fontType="bodyMd"
              lineHeight="162.5%"
            >
              {total > 0 ? Price.formatPrice(total) : FREE}
            </Typography>
          </div>
        </div>
      </div>
    </>
  );
};
