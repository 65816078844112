import { EffectCallback, useEffect, useRef } from 'react';

export function useOnceCall(cb: EffectCallback) {
  const isCalledRef = useRef(false);
  useEffect(() => {
    if (!isCalledRef.current) {
      isCalledRef.current = true;
      cb();
    }
  }, []);
}
