import React, { Ref } from 'react';
import { Typography } from '@ezetech/swag-space-x';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { homepageProductsListSelector } from 'redux/selectors/settings.selectors';
import {
  checkHomepageProducts,
  removeHomepageProductSelection,
} from 'redux/slices/settings.slice';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { IHomepageSelectedProduct } from 'interfaces/products.interface';
import { Product } from './product';
import css from './search-products.module.scss';

interface IProductsList {
  isLoading: boolean;
  isOpen: boolean;
  listRef: Ref<HTMLInputElement>;
}

export const SearchList: React.FC<IProductsList> = ({ isLoading, isOpen, listRef }) => {
  const dispatch = useAppDispatch();
  const products = useAppSelector(homepageProductsListSelector);

  if (!isOpen) {
    return null;
  }

  if (!isLoading && products.length === 0) {
    return (
      <div ref={listRef} className={css.emptyList}>
        <Typography
          lineHeight="142.857%"
          fontType="bodySm"
          color={COLORS.colorPrimaryText}
        >
          No products
        </Typography>
      </div>
    );
  }

  if (isLoading && products.length === 0) {
    return null;
  }

  const handleProductClick = (publicId: string, value: IHomepageSelectedProduct) => {
    dispatch(checkHomepageProducts({ publicId, value }));
  };

  const handleRemoveClick = (publicId: string) => {
    dispatch(removeHomepageProductSelection({ publicId }));
  };

  return (
    <div className={css.searchList} ref={listRef}>
      {products.map((product) => (
        <Product
          key={product.publicId}
          product={product}
          onProductClick={handleProductClick}
          onRemoveClick={handleRemoveClick}
        />
      ))}
    </div>
  );
};
