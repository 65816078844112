import { FC } from 'react';
import cn from 'classnames';
import {
  Input,
  OptionType,
  Select,
  Toggle,
  TogglePlacement,
} from '@ezetech/swag-space-x';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { ValidationError } from 'yup';
import {
  AVAILABLE_COUNTRIES_LIST,
  EUROPE_COUNTRY_CODES,
} from 'constants/settings.constants';
import { IInvoice } from 'interfaces/settings.interface';
import {
  invoiceErrorsSelector,
  invoiceSelector,
  invoiceVisibleSelector,
} from 'redux/selectors/settings.selectors';
import { useAppDispatch } from 'redux/store';
import {
  patchInvoice,
  setInvoiceError,
  clearInvoiceError,
} from 'redux/slices/settings.slice';
import { cannot, SUBJECTS } from 'boot/ability';

import css from '../forms.module.scss';
import { SAVING_OPTIONS } from '../payout/payout.contants';
import { FIELDS } from './invoice.contants';
import { invoiceFormSchema } from './invoice.schema';

interface InvoiceFormProps {
  withToggle?: boolean;
  isPopupForm?: boolean;
  classForm?: string;
}

export const InvoiceForm: FC<InvoiceFormProps> = ({
  isPopupForm = false,
  withToggle = true,
  classForm,
}) => {
  const realValues = useSelector(invoiceSelector);
  const visibleValues = useSelector(invoiceVisibleSelector);
  const form = realValues.invoiceAsInPayout ? visibleValues : realValues;
  const realErrors = useSelector(invoiceErrorsSelector);
  const dispatch = useAppDispatch();
  const errors = realValues.invoiceAsInPayout ? {} : realErrors;
  const isNotEditable = cannot(
    SUBJECTS.INVOICE_BANK_DETAILS.actions.INVOICE_BANK_DETAILS_EDIT,
    SUBJECTS.INVOICE_BANK_DETAILS.value,
  );
  const isDisabled =
    isNotEditable || (isPopupForm ? false : realValues.invoiceAsInPayout);

  const onChange = async (path: string, value: unknown) => {
    dispatch(
      patchInvoice({
        [path]: value,
      }),
    );
    if (path === FIELDS.invoiceBankCountry.name && value !== 'US') {
      dispatch(setInvoiceError({ [FIELDS.invoiceRoutingNumber.name]: undefined }));
    }
    if (path === FIELDS.invoiceBankCountry.name && value === 'US') {
      dispatch(setInvoiceError({ [FIELDS.invoiceBIC.name]: undefined }));
    }
    if (!isPopupForm && path === FIELDS.invoiceAsInPayout.name && value) {
      dispatch(clearInvoiceError());
    }
    dispatch(setInvoiceError({ [path]: undefined }));
  };

  const onBlur = (path: string) => async () => {
    try {
      await invoiceFormSchema.validateAt(
        path,
        {
          [FIELDS.invoiceAsInPayout.name]: form.invoiceAsInPayout,
          [FIELDS.invoiceBankCountry.name]: form.invoiceBankCountry,
          [path]: form[path as keyof IInvoice],
        },
        { abortEarly: false },
      );
      dispatch(setInvoiceError({ [path]: undefined }));
    } catch (validationError: unknown) {
      const { inner } = validationError as ValidationError;
      const message = inner.find(({ path: p }) => p === path)?.message ?? '';
      if (message) {
        dispatch(setInvoiceError({ [path]: message }));
      }
    }
  };

  const bankCountryValue =
    AVAILABLE_COUNTRIES_LIST.find(({ value }) => value === form.invoiceBankCountry) ??
    null;
  const accountTypeValue =
    SAVING_OPTIONS.find(({ value }) => value === form.invoiceAccountType) ?? null;
  const isEurope = EUROPE_COUNTRY_CODES.includes(form.invoiceBankCountry);
  const isUSA = form.invoiceBankCountry === 'US';

  return (
    <form
      className={classNames(css.form, classForm, {
        [css.scrollable]: isPopupForm,
      })}
    >
      {withToggle && (
        <div className={css.invoiceToggleContainer}>
          <div>Make same as payout bank details</div>
          <Toggle
            className={cn(css.invoiceToggle, {
              [css.toggleDisabled]: isNotEditable,
            })}
            label="mock"
            placement={TogglePlacement.right}
            labelClassName={css.toggleLabel}
            name={FIELDS.invoiceAsInPayout.name}
            value={form.invoiceAsInPayout}
            onChange={(e) => onChange(FIELDS.invoiceAsInPayout.name, e.target.checked)}
          />
        </div>
      )}
      <Select
        options={AVAILABLE_COUNTRIES_LIST}
        className={css.input}
        label={FIELDS.invoiceBankCountry.label}
        name={FIELDS.invoiceBankCountry.name}
        value={bankCountryValue}
        errors={errors.invoiceBankCountry}
        onChange={(value) =>
          onChange(FIELDS.invoiceBankCountry.name, (value as OptionType).value)
        }
        onBlur={onBlur(FIELDS.invoiceBankCountry.name)}
        placeholder={FIELDS.invoiceBankCountry.placeholder}
        isDisabled={isDisabled}
      />
      <div className={css.twoInRowContainer}>
        <Input
          inputWrapperClassName={css.inputWrapper}
          className={css.input}
          label={FIELDS.invoiceBankName.label}
          name={FIELDS.invoiceBankName.name}
          errors={errors.invoiceBankName}
          onBlur={onBlur(FIELDS.invoiceBankName.name)}
          value={form.invoiceBankName}
          onChange={(e) => onChange(FIELDS.invoiceBankName.name, e.target.value)}
          placeholder={FIELDS.invoiceBankName.placeholder}
          disabled={isDisabled}
        />
        <Select
          options={SAVING_OPTIONS}
          className={css.input}
          label={FIELDS.invoiceAccountType.label}
          name={FIELDS.invoiceAccountType.name}
          onBlur={onBlur(FIELDS.invoiceAccountType.name)}
          value={accountTypeValue}
          errors={errors.invoiceAccountType}
          onChange={(value) =>
            onChange(FIELDS.invoiceAccountType.name, (value as OptionType).value)
          }
          placeholder={FIELDS.invoiceAccountType.placeholder}
          isDisabled={isDisabled}
        />
      </div>
      <Input
        inputWrapperClassName={css.inputWrapper}
        className={css.input}
        label={FIELDS.invoiceAccountBeneficiaryName.label}
        name={FIELDS.invoiceAccountBeneficiaryName.name}
        errors={errors.invoiceAccountBeneficiaryName}
        value={form.invoiceAccountBeneficiaryName}
        onBlur={onBlur(FIELDS.invoiceAccountBeneficiaryName.name)}
        onChange={(e) =>
          onChange(FIELDS.invoiceAccountBeneficiaryName.name, e.target.value)
        }
        placeholder={FIELDS.invoiceAccountBeneficiaryName.placeholder}
        disabled={isDisabled}
      />
      <div className={css.twoInRowContainer}>
        <Input
          inputWrapperClassName={css.inputWrapper}
          className={css.input}
          label={FIELDS.invoiceBankAccountNumber.label(isEurope)}
          name={FIELDS.invoiceBankAccountNumber.name}
          onBlur={onBlur(FIELDS.invoiceBankAccountNumber.name)}
          errors={errors.invoiceBankAccountNumber}
          value={form.invoiceBankAccountNumber}
          onChange={(e) => onChange(FIELDS.invoiceBankAccountNumber.name, e.target.value)}
          placeholder={FIELDS.invoiceBankAccountNumber.placeholder(isEurope)}
          disabled={isDisabled}
        />
        <Input
          inputWrapperClassName={css.inputWrapper}
          className={css.input}
          label={FIELDS.invoiceBIC.label(isUSA)}
          name={FIELDS.invoiceBIC.name}
          onBlur={onBlur(FIELDS.invoiceBIC.name)}
          errors={errors.invoiceBIC}
          value={form.invoiceBIC || ''}
          onChange={(e) => onChange(FIELDS.invoiceBIC.name, e.target.value)}
          placeholder={FIELDS.invoiceBIC.placeholder}
          disabled={isDisabled}
        />
      </div>
      {isUSA && (
        <div className={css.twoInRowContainer}>
          <Input
            inputWrapperClassName={css.inputWrapper}
            className={css.input}
            label={FIELDS.invoiceRoutingNumber.label}
            name={FIELDS.invoiceRoutingNumber.name}
            onBlur={onBlur(FIELDS.invoiceRoutingNumber.name)}
            errors={errors.invoiceRoutingNumber}
            value={form.invoiceRoutingNumber}
            onChange={(e) => onChange(FIELDS.invoiceRoutingNumber.name, e.target.value)}
            placeholder={FIELDS.invoiceRoutingNumber.placeholder}
            disabled={isDisabled}
          />
          <div className={css.fieldSpacer} />
        </div>
      )}
    </form>
  );
};
