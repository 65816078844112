import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openPopup } from 'redux/slices/modals.slice';
import {
  EDIT_PDF_PRESENTATION_LAYOUT_POPUP,
  EDIT_WHAT_WE_OFFER_PAGE,
  EDIT_HOME_PAGE,
} from 'components/popups/_logic/popups-list';
import { useAppSelector } from 'redux/store';
import { setHomepageToggle, setWhatWeOfferToggle } from 'redux/slices/settings.slice';
import { isHomepageEditorFeatureEnabledSelector } from 'redux/selectors/technical-data.selectors';
import {
  isHomepageActiveSelector,
  isWhatWeOfferActiveSelector,
} from 'redux/selectors/settings.selectors';
import { HeatingEditPdfPresentation } from 'components/popups/edit-pdf-presentation-layout-popup/heating-edit-pdf-presentation';
import { cannot, can, SUBJECTS } from 'boot/ability';

import whatWeOffer from '../../../../../assets/images/what-we-offer.png';
import pdfPresentation from '../../../../../assets/images/pdf-presentation.png';
import homepageEditor from '../../../../../assets/images/homepage-editor.png';

import { PagesCard } from './pages-card';

import classes from './pages.module.scss';
import { PageVisibilityToggle } from './page-visibility-toggle';

export const PagesForm: FC = () => {
  const dispatch = useDispatch();
  const isHomepageEditorFeatureEnabled: boolean = useAppSelector(
    isHomepageEditorFeatureEnabledSelector,
  );
  const isHomepageActive = useSelector(isHomepageActiveSelector);
  const isWhatWeOfferActive = useSelector(isWhatWeOfferActiveSelector);

  const isPdfPresentationHidden = cannot(
    SUBJECTS.PAGES_POPUPS_PDF_PRESENTATIONS.actions.PDF_PRESENTATION_VIEW,
    SUBJECTS.PAGES_POPUPS_PDF_PRESENTATIONS.value,
  );

  const isHomepageHidden = cannot(
    SUBJECTS.PAGES_POPUPS_PDF_PRESENTATIONS.actions.HOMEPAGE_VIEW,
    SUBJECTS.PAGES_POPUPS_PDF_PRESENTATIONS.value,
  );

  const isWhatWeOfferHidden = cannot(
    SUBJECTS.PAGES_POPUPS_PDF_PRESENTATIONS.actions.WHAT_WE_OFFER_PAGE_VIEW,
    SUBJECTS.PAGES_POPUPS_PDF_PRESENTATIONS.value,
  );

  const isPdfPresentationEditable = can(
    SUBJECTS.PAGES_POPUPS_PDF_PRESENTATIONS.actions.PDF_PRESENTATION_EDIT,
    SUBJECTS.PAGES_POPUPS_PDF_PRESENTATIONS.value,
  );

  const isWhatWeOfferEditable = can(
    SUBJECTS.PAGES_POPUPS_PDF_PRESENTATIONS.actions.WHAT_WE_OFFER_PAGE_EDIT,
    SUBJECTS.PAGES_POPUPS_PDF_PRESENTATIONS.value,
  );

  const isHomepageEditable = can(
    SUBJECTS.PAGES_POPUPS_PDF_PRESENTATIONS.actions.HOMEPAGE_EDIT,
    SUBJECTS.PAGES_POPUPS_PDF_PRESENTATIONS.value,
  );

  const whatWeOfferLabel = isWhatWeOfferEditable ? 'Edit Page' : 'View Page';
  const pdfPresentationLabel = isPdfPresentationEditable ? 'Edit PDF' : 'View PDF';
  const homePageLabel = isHomepageEditable ? 'Edit Page' : 'View Page';

  const isHomepageActiveToggle = () => {
    dispatch(setHomepageToggle(!isHomepageActive));
  };
  const isWhatWeOfferActiveToggle = () => {
    dispatch(setWhatWeOfferToggle(!isWhatWeOfferActive));
  };

  return (
    <div className={classes.container}>
      {!isHomepageHidden && isHomepageEditorFeatureEnabled ? (
        <PagesCard
          title="Homepage"
          imgAlt="Homepage editor preview"
          imgSrc={homepageEditor}
          buttonLabel={homePageLabel}
          onButtonClick={() =>
            dispatch(
              openPopup({
                popupName: EDIT_HOME_PAGE,
                popupProps: {},
              }),
            )
          }
          toggle={
            <PageVisibilityToggle
              name="homepage-toggle"
              label="Activate homepage"
              value={isHomepageActive}
              onChange={isHomepageActiveToggle}
            />
          }
        />
      ) : null}
      {!isWhatWeOfferHidden && (
        <PagesCard
          title="What We Offer Page"
          imgAlt="What we offer preview"
          imgSrc={whatWeOffer}
          buttonLabel={whatWeOfferLabel}
          onButtonClick={() =>
            dispatch(
              openPopup({
                popupName: EDIT_WHAT_WE_OFFER_PAGE,
                popupProps: {},
              }),
            )
          }
          toggle={
            <PageVisibilityToggle
              name="what-we-offer-toggle"
              label="Activate what we offer"
              value={isWhatWeOfferActive}
              onChange={isWhatWeOfferActiveToggle}
            />
          }
        />
      )}
      {!isPdfPresentationHidden && (
        <>
          <HeatingEditPdfPresentation />
          <PagesCard
            title="PDF Presentation"
            imgAlt="PDF Presentation preview"
            imgSrc={pdfPresentation}
            buttonLabel={pdfPresentationLabel}
            onButtonClick={() => {
              dispatch(
                openPopup({
                  popupName: EDIT_PDF_PRESENTATION_LAYOUT_POPUP,
                  popupProps: { suppressLoading: false },
                }),
              );
            }}
          />
        </>
      )}
    </div>
  );
};
