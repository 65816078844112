import { Tooltip, TooltipPlacement, Typography } from '@ezetech/swag-space-x';
import COLORS from 'constants/styles/colors-js.constant.module.scss';

import { ILead } from 'interfaces/user-api.interface';

interface IProps {
  item: ILead;
}
export function InterestedIn({ item }: IProps): JSX.Element {
  const text = item.interestedIn.join(', ');
  const interestedInCount = item.interestedIn.length;
  if (interestedInCount <= 1) {
    return <>{text}</>;
  }
  const postfix = interestedInCount === 2 ? 'more' : 'others';
  return (
    <Tooltip
      placement={TooltipPlacement.bottom}
      content={
        <Typography color={COLORS.colorPrimaryText}>
          {item.interestedIn[0]},
          <Typography color={COLORS.colorAccent500}>
            &nbsp;+&nbsp;{interestedInCount - 1} {postfix}
          </Typography>
        </Typography>
      }
    >
      <Typography color={COLORS.colorPrimaryText}>{text}</Typography>
    </Tooltip>
  );
}
