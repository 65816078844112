import { FC } from 'react';
import { Typography } from '@ezetech/swag-space-x';
import COLORS from 'constants/styles/colors-js.constant.module.scss';

export const CustomerConfidentiality: FC = () => {
  return (
    <>
      <Typography color={COLORS.colorPrimaryText} fontType="bodyMdBold">
        4. Customer Confidentiality
      </Typography>
      <br />
      <br />
      Reseller’s customer names and contact information are considered Confidential
      Information and, without limiting the foregoing, Swag Space will not, without
      Reseller’s prior written consent, use any customer lists for any purpose other than
      for performing its obligations under the Agreement. Notwithstanding the foregoing,
      it is understood and agreed that Swag Space may have some of the same or overlapping
      customers as Reseller.
      <br />
      <br />
    </>
  );
};
