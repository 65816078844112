import { Typography, WhiteBox } from '@ezetech/swag-space-x';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { can, SUBJECTS } from 'boot/ability';

import css from './plan-and-payment.module.scss';
import { PayoutForm } from './forms';
import { Ach } from './forms/ach';
import { InvoiceForm } from './forms/invoice';

export function PlanAndPayment(): JSX.Element {
  return (
    <div className={css.root}>
      {can(
        SUBJECTS.PAYOUT_BANK_DETAILS.actions.PAYOUT_BANK_DETAILS_VIEW,
        SUBJECTS.PAYOUT_BANK_DETAILS.value,
      ) && (
        <WhiteBox className={css.section}>
          <Typography
            className={css.header}
            color={COLORS.colorDarkBlue}
            fontType={'HEADING5XL'}
          >
            Payout bank details
          </Typography>
          <div className={css.underline} />
          <div className={css.content}>
            <PayoutForm />
          </div>
        </WhiteBox>
      )}
      {can(
        SUBJECTS.INVOICE_BANK_DETAILS.actions.INVOICE_BANK_DETAILS_VIEW,
        SUBJECTS.INVOICE_BANK_DETAILS.value,
      ) && (
        <WhiteBox className={css.section}>
          <Typography
            className={css.header}
            color={COLORS.colorDarkBlue}
            fontType={'HEADING5XL'}
          >
            Invoice bank details
          </Typography>
          <div className={css.underline} />
          <div className={css.content}>
            <InvoiceForm />
          </div>
        </WhiteBox>
      )}
      {can(
        SUBJECTS.ACH_NET_PAYMENTS_MAJOR_DISCOUNT_PROMO_CODES.actions
          .ACH_NET_PAYMENTS_MAJOR_DISCOUNT_PROMO_CODES_VIEW,
        SUBJECTS.ACH_NET_PAYMENTS_MAJOR_DISCOUNT_PROMO_CODES.value,
      ) && (
        <WhiteBox className={css.section}>
          <Typography
            className={css.header}
            color={COLORS.colorDarkBlue}
            fontType={'HEADING5XL'}
          >
            ACH, Net Payments, Major discount Promo Codes
          </Typography>
          <div className={css.underline} />
          <div className={css.content}>
            <Ach />
          </div>
        </WhiteBox>
      )}
    </div>
  );
}
