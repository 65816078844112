import { ChangeEvent, createRef, useCallback, useState } from 'react';
import { RainbowBox, SwagButton, Typography } from '@ezetech/swag-space-x';
import { CSSTransition } from 'react-transition-group';
import cn from 'classnames';

import { useAppDispatch, useAppSelector } from 'redux/store';
import { Price } from 'utils/price.util';
import { LowMarginText } from 'components/low-margin';
import {
  marginCustomerPriceSelector,
  marginPercentErrorSelector,
  marginPercentSelector,
  marginResellerIncomeSelector,
} from 'redux/selectors/store-creation.selectors';
import { setResellerMargin } from 'redux/slices/store-creation.slice';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import {
  MARGIN_MAX_PERCENT,
  MARGIN_MIN_PERCENT,
} from 'constants/store-creation.constants';
import { useIsMobileView } from 'hooks/is-mobile-view.hook';
import { DealComparisonWidget } from 'components/deal-comparison-widget';
import xMark from 'assets/svg/x-mark.svg';

import css from './margin.module.scss';

const animationClasses = {
  enter: css.enter,
  enterActive: css.enterActive,
  exit: css.exit,
  exitActive: css.exitActive,
};

export function MarginComponent(): JSX.Element {
  const percent = useAppSelector(marginPercentSelector);
  const customerPrice = useAppSelector(marginCustomerPriceSelector);
  const resellerIncome = useAppSelector(marginResellerIncomeSelector);
  const error = useAppSelector(marginPercentErrorSelector);
  const dispatch = useAppDispatch();
  const errorRef = createRef<HTMLSpanElement>();
  const [isComparingPopupShown, setIsComparingPopupShown] = useState(false);
  const isMobile = useIsMobileView(1024);

  const commissionText = isMobile ? 'Commission' : 'The commission you want to make';

  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    let value = e.target.value;

    if (value.length > 1 && value.startsWith('0')) {
      value = value.slice(1, value.length);
    }

    if (value.length > 2) {
      value = value.slice(0, 2);
    }

    const numberValue = parseFloat(value);

    dispatch(setResellerMargin({ resellerMargin: numberValue }));
  };

  const closeComparingPopup = useCallback(() => {
    setIsComparingPopupShown(false);
  }, []);

  return (
    <section className={css.section}>
      <div className={css.textBlock}>
        <Typography lineHeight="100%" fontType="HEADING6XL" color={COLORS.colorBlack}>
          COMMISSIONS
        </Typography>

        <Typography
          className={css.mainHeading}
          lineHeight="100%"
          fontType="HEADING3XL"
          color={COLORS.colorTypographyHeadings}
        >
          WHAT COMMISSION WOULD YOU LIKE TO MAKE?
        </Typography>

        <Typography
          lineHeight="162%"
          className={css.text}
          fontType="bodyMd"
          color={COLORS.colorTypographyBody}
        >
          Set your commission and choose how much you want to make on your sales. The
          minimum amount is {MARGIN_MIN_PERCENT * 100}%. Keep in mind, if you increase the
          commission more than {MARGIN_MIN_PERCENT * 100}%, your prices will be more
          expensive than what is recommended based on industry standard.
        </Typography>
      </div>

      {isComparingPopupShown ? (
        <RainbowBox
          borderWidth={5}
          outerClassName={css.outerBoxClassName}
          innerClassName={cn(css.innerBoxClassName, css.dealComparisonWidget)}
        >
          <div className={css.header}>
            <div className={css.closeIconWrapper} onClick={closeComparingPopup}>
              <img src={xMark} className={css.closeIcon} />
            </div>
          </div>

          <DealComparisonWidget />
        </RainbowBox>
      ) : (
        <RainbowBox
          borderWidth={5}
          outerClassName={css.outerBoxClassName}
          innerClassName={css.innerBoxClassName}
        >
          <div className={cn(css.widget, css.widgetMargin)}>
            <Typography
              lineHeight="162%"
              fontType="bodyMdBold"
              color={COLORS.colorTypographyHeadings}
              className={css.headingWidget}
            >
              {commissionText}
            </Typography>
            <div className={css.fakeInput}>
              <Typography
                className={css.percentSign}
                lineHeight="162%"
                color={COLORS.colorText500}
              >
                %
              </Typography>
              <Typography
                className={css.percentWrapper}
                lineHeight="162%"
                color={COLORS.colorPrimaryText}
              >
                <input
                  min={MARGIN_MIN_PERCENT * 100}
                  max={MARGIN_MAX_PERCENT * 100}
                  className={css.percent}
                  onChange={onChange}
                  value={percent}
                />
              </Typography>
            </div>

            <CSSTransition
              in={!!error}
              classNames={animationClasses}
              timeout={500}
              nodeRef={errorRef}
              unmountOnExit
            >
              <Typography
                className={css.errorText}
                lineHeight="100%"
                fontType="bodySm"
                color={COLORS.colorErrorText}
              >
                <span ref={errorRef}>{error}</span>
              </Typography>
            </CSSTransition>
          </div>
          <div className={cn(css.widget, css.widgetCalculator)}>
            <Typography
              lineHeight="162%"
              className={css.headingWidget}
              fontType="bodyMdBold"
              color={COLORS.colorTypographyHeadings}
            >
              Based on a $10 Tshirt
            </Typography>

            <div className={css.list}>
              <Typography
                lineHeight="100%"
                fontType="bodyMd"
                className={css.key}
                color={COLORS.colorTypographyBodyGreyed}
              >
                Customer pays:
              </Typography>
              <Typography
                lineHeight="100%"
                fontType="bodyMd"
                className={css.value}
                color={COLORS.colorTypographyHeadings}
              >
                {Price.formatPrice(customerPrice)}
              </Typography>
              <Typography
                lineHeight="100%"
                fontType="bodyMd"
                className={css.key}
                color={COLORS.colorTypographyBodyGreyed}
              >
                You make:
              </Typography>
              <Typography
                lineHeight="100%"
                fontType="bodyMd"
                className={css.value}
                color={COLORS.colorSuccess700}
              >
                {Price.formatPrice(resellerIncome)}
              </Typography>
            </div>
          </div>

          <SwagButton
            size="large"
            type="outlined"
            onClick={() => setIsComparingPopupShown(true)}
            className={css.btn}
          >
            <Typography
              lineHeight="162.5%"
              fontType="bodyMdBold"
              color={COLORS.colorTypographyHeadings}
            >
              See how it compares
            </Typography>
          </SwagButton>

          <LowMarginText
            className={css.lowMarginText}
            currentMargin={percent}
            disabled={!!error}
          />
        </RainbowBox>
      )}
    </section>
  );
}
