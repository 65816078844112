import { useCallback, useEffect, useState } from 'react';

import {
  Accordion,
  AccordionItem,
  Dropzone,
  IFile,
  TextField,
} from '@ezetech/swag-space-x';
import { IUpdateWhatWeOfferSettings } from 'interfaces/settings.interface';
import { debounce } from 'utils/common.util';
import { useProcessFile } from 'hooks/use-process-file';

import {
  WHAT_WE_OFFER_IMAGE_ACCEPT_RESTRICTION,
  WHAT_WE_OFFER_IMAGE_MB_RESTRICTION,
} from 'constants/limits';

import { getHeroBodyPlaceholder, getRowsForBodyCopy } from '../utils';

import css from './sidebar.module.scss';

type EditWhatWeOfferSidebarProps = {
  settings: IUpdateWhatWeOfferSettings;
  onSettingsChanged: (data: IUpdateWhatWeOfferSettings) => void;
};

export const EditWhatWeOfferSidebar = (
  props: EditWhatWeOfferSidebarProps,
): JSX.Element => {
  const { settings, onSettingsChanged } = props;

  const [heroImage, setHeroImage] = useState<Array<IFile>>(
    settings.heroImage?.url ? [settings.heroImage] : [],
  );
  const [brandLogos, setBrandLogos] = useState<Array<IFile | null>>(
    settings?.brandLogos || Array.from({ length: 8 }, () => null),
  );
  const [imagesRaw, setImagesRaw] = useState<Array<IFile | null>>(
    settings?.imagesRaw || Array.from({ length: 3 }, () => null),
  );

  const [heroText, setHeroText] = useState<string>(settings?.heroText || '');
  const [formText, setFormText] = useState<string>(settings?.formText || '');

  const { handleProcessFile } = useProcessFile();

  const onHeroImageChanged = (files: IFile[]) => {
    if (files[0]) {
      setHeroImage([files[0]]);
    } else {
      setHeroImage([]);
    }
  };
  const onBrandLogosChanged = (files: IFile[], index: number) => {
    const newArray = brandLogos.map((item, currentIndex) =>
      index === currentIndex ? files[0] : item,
    );
    setBrandLogos(newArray);
  };

  const onImageRawChanged = (files: IFile[], index: number) => {
    const newArray = imagesRaw.map((item, currentIndex) =>
      index === currentIndex ? files[0] : item,
    );
    setImagesRaw(newArray);
  };

  const handleUpdates = (settingsUpdated: IUpdateWhatWeOfferSettings) => {
    onSettingsChanged(settingsUpdated);
  };

  const debouncedHandleUpdates = useCallback(debounce(handleUpdates, 800), []);

  useEffect(() => {
    debouncedHandleUpdates({
      formText: formText ?? '',
      heroImage: heroImage[0] ?? [],
      brandLogos: brandLogos,
      imagesRaw: imagesRaw,
      heroText: heroText ?? '',
    } as unknown as never);
  }, [formText, heroImage, brandLogos, imagesRaw, heroText]);

  return (
    <Accordion className={css.accordion}>
      <p className={css.editorTitle}>
        If you don’t want to customize your page, you don’t have to. Each section that you
        leave blank, will not be included on your "What We Offer" page.
      </p>
      <AccordionItem
        headerComponent="Hero Body Copy"
        value="Hero Body Copy"
        className={css.accordionItem}
        triggerClassName={css.accordionItemContent}
        contentClassName={css.accordionItemContent}
      >
        <div className={css.inputWrapper}>
          <TextField
            value={heroText}
            maxLength={200}
            onChange={(e) => setHeroText(e.target.value)}
            name="hero-text"
            placeholder={getHeroBodyPlaceholder()}
            label="Body Copy"
            className={css.textarea}
            labelClassName={css.textareaLabel}
            {...{ rows: getRowsForBodyCopy(), cols: 50 }}
          />
          <span className={css.maxLengthText}>Max 200 characters</span>
        </div>
      </AccordionItem>
      <AccordionItem
        headerComponent="Hero Image"
        value="Hero Image"
        className={css.accordionItem}
        triggerClassName={css.accordionItemContent}
        contentClassName={css.accordionItemContent}
      >
        <Dropzone
          buttonClassName={css.dropzoneButton}
          accept={WHAT_WE_OFFER_IMAGE_ACCEPT_RESTRICTION}
          className={css.imageLoader}
          files={heroImage}
          maxSize={WHAT_WE_OFFER_IMAGE_MB_RESTRICTION}
          processFile={(file) => handleProcessFile(file)}
          uploadTextWrapperClassName={css.uploadTextWrapper}
          onFilesChanged={onHeroImageChanged}
          additionalText="JPG or PNG, no more than 10MB"
          previewClassName={css.previewImageWrapper}
          helperText="Recommended size is 1824 x 1212 pixels"
          helperTextClassName={css.dropzoneHelperText}
        />
      </AccordionItem>
      <AccordionItem
        headerComponent="Brand Logos"
        value="Brand Logos"
        className={css.accordionItem}
        triggerClassName={css.accordionItemContent}
        contentClassName={css.accordionItemContent}
      >
        <p className={css.editorText}>
          Upload logos of your best customers here. This will appear below the header. For
          best results, we recommend uploading 8 logos. Recommended file type, PNG or JPG
          with a white or transparent background.
        </p>
        {brandLogos.map((selectedFile, index) => (
          <div key={index}>
            <p className={css.editorText}>{`Logo ${index + 1}`}</p>
            <Dropzone
              buttonClassName={css.dropzoneButton}
              accept={WHAT_WE_OFFER_IMAGE_ACCEPT_RESTRICTION}
              className={css.imageLoader}
              files={selectedFile ? [selectedFile] : []}
              processFile={(file) => handleProcessFile(file)}
              onFilesChanged={(files) => onBrandLogosChanged(files, index)}
              maxSize={WHAT_WE_OFFER_IMAGE_MB_RESTRICTION}
              additionalText="JPG or PNG, no more than 10MB"
              uploadTextWrapperClassName={css.uploadTextWrapper}
              previewClassName={css.previewImageWrapper}
              helperText="Recommended size is 140 x 140 pixels"
              helperTextClassName={css.dropzoneHelperText}
            />
          </div>
        ))}
      </AccordionItem>
      <AccordionItem
        headerComponent="3 Image Row"
        value="3 Image Row"
        className={css.accordionItem}
        triggerClassName={css.accordionItemContent}
        contentClassName={css.accordionItemContent}
      >
        <p className={css.editorText}>
          In between the "Brands We Offer" and the "Ready To Find Out More?" sections, you
          can add 3 images to give your page more life. We recommend uploading 3 past work
          images that you've done.
        </p>
        {imagesRaw.map((selectedFile, index) => (
          <div key={index}>
            <p className={css.editorText}>{`Upload Image ${index + 1}`}</p>
            <Dropzone
              buttonClassName={css.dropzoneButton}
              accept={WHAT_WE_OFFER_IMAGE_ACCEPT_RESTRICTION}
              className={css.imageLoader}
              maxSize={WHAT_WE_OFFER_IMAGE_MB_RESTRICTION}
              files={selectedFile ? [selectedFile] : []}
              processFile={(file) => handleProcessFile(file)}
              onFilesChanged={(files) => onImageRawChanged(files, index)}
              additionalText="JPG or PNG, no more than 10MB"
              uploadTextWrapperClassName={css.uploadTextWrapper}
              previewClassName={css.previewImageWrapper}
              helperText="Recommended size is 1194 x 874 pixels"
              helperTextClassName={css.dropzoneHelperText}
            />
          </div>
        ))}
      </AccordionItem>
      <AccordionItem
        headerComponent="Contact Form"
        value="Contact Form"
        className={css.accordionItem}
        triggerClassName={css.accordionItemContent}
        contentClassName={css.accordionItemContent}
      >
        <p className={css.editorText}>
          Below the "Ready to Find Out More?" header you’re able to add some additional
          text.
        </p>
        <div className={css.inputWrapper}>
          <TextField
            name="contact-form"
            maxLength={200}
            onChange={(e) => setFormText(e.target.value)}
            value={formText}
            placeholder="Write some text here... e.g. Get in touch with us so that we can help you with some amazing Swag."
            label="Body Copy"
            className={css.textarea}
            labelClassName={css.textareaLabel}
            {...{ rows: getRowsForBodyCopy(), cols: 50 }}
          />
          <span className={css.maxLengthText}>Max 200 characters</span>
        </div>
      </AccordionItem>
    </Accordion>
  );
};
