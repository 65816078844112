import * as yup from 'yup';

import { ERROR_MSG } from 'constants/validation';
import { CountriesStatesSelect } from 'constants/settings.constants';
import { FIELDS } from './address.constants';

export const addressFormSchema = yup
  .object({
    [FIELDS.address.name]: yup
      .string()
      .strict(true)
      .trim(ERROR_MSG.trim)
      .min(1, ERROR_MSG.min(1))
      .max(255, ERROR_MSG.max(255))
      .required(ERROR_MSG.required),
    [FIELDS.city.name]: yup
      .string()
      .strict(true)
      .trim(ERROR_MSG.trim)
      .min(1, ERROR_MSG.min(1))
      .max(255, ERROR_MSG.max(255))
      .required(ERROR_MSG.required),
    [FIELDS.zip.name]: yup
      .string()
      .strict(true)
      .trim(ERROR_MSG.trim)
      .min(4, ERROR_MSG.min(4))
      .max(6, ERROR_MSG.max(6))
      .required(ERROR_MSG.required),
    [FIELDS.country.name]: yup
      .string()
      .strict(true)
      .trim(ERROR_MSG.trim)
      .min(2, ERROR_MSG.min(2))
      .max(2, ERROR_MSG.max(2))
      .required(ERROR_MSG.required),
    [FIELDS.state.name]: yup
      .string()
      .strict(true)
      .trim(ERROR_MSG.trim)
      .max(255, ERROR_MSG.max(255))
      .optional()
      .transform((value) => (value ? value : undefined))
      .when(FIELDS.country.name, (country, field) => {
        return [CountriesStatesSelect.CA, CountriesStatesSelect.US].includes(
          country[0]?.toString(),
        )
          ? field.required(ERROR_MSG.required)
          : field.optional();
      }),
  })
  .test('is-optional', '', (value) => {
    return Object.values(value).some((v) => !!v);
  });
