import { FC } from 'react';
import { Typography } from '@ezetech/swag-space-x';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { InvitationStatus } from 'interfaces/invitation.interface';
import { getTimeMmmDdYyyy } from 'utils/date/date-formatter.util';
import classes from './user-card.module.scss';

interface IProps {
  status?: InvitationStatus;
  lastActivityAt?: string;
}

function getTextColor(status?: InvitationStatus): string {
  if (status === InvitationStatus.PENDING) {
    return COLORS.colorStatePending;
  }
  if (status === InvitationStatus.EXPIRED) {
    return COLORS.colorDestructive700;
  }

  return COLORS.colorText900;
}

function getText({ status, lastActivityAt }: IProps): string {
  if (status === InvitationStatus.PENDING) {
    return 'Pending';
  }
  if (status === InvitationStatus.EXPIRED) {
    return 'Expired';
  }
  return lastActivityAt
    ? `Last activity was on ${getTimeMmmDdYyyy(new Date(lastActivityAt), {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      })}`
    : '';
}

export const ActivityBlock: FC<IProps> = (props) => {
  const lastActivityColor = getTextColor(props.status);
  const lastActivity = getText(props);

  return (
    <Typography
      fontType="bodyMd"
      color={lastActivityColor}
      lineHeight="162.5%"
      className={classes.lastActivity}
    >
      {lastActivity}
    </Typography>
  );
};
