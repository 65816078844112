import {
  Dialog,
  DialogContentWrapper,
  DialogTitle,
  DialogDescription,
  DialogFooter,
  SwagButton,
  Typography,
  Loader,
} from '@ezetech/swag-space-x';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { CartTypeEnum } from 'interfaces/cart.interfaces';
import { closePopup, openPopup, patchProps } from 'redux/slices/modals.slice';
import { useCreateCartLinkMutation } from 'redux/api/presentation.api';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { CreateACartTypePopupProps } from 'interfaces/popup.interface';
import { API_URL } from 'redux/api/helpers';
import { usePreCreateACartMutation } from 'redux/api/cart.api';
import { BUTTONS, DESCRIPTIONS, HEADERS } from 'constants/assignment';
import { popupNameSelector } from 'redux/selectors/modals.selectors';
import { CONFIRMATION_POPUP, CREATE_A_CART_EMAIL_POPUP } from '../_logic/popups-list';
import css from './create-a-cart-type.popup.module.scss';

export const CreateACartTypePopup = ({
  isOpen,
  email = '',
  presentationId = '',
}: CreateACartTypePopupProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const [createCartLink, { isLoading }] = useCreateCartLinkMutation();
  const [preCreateCartLink, { isLoading: isPreCreateCartLinkLoading }] =
    usePreCreateACartMutation();
  const currentPopupName = useAppSelector(popupNameSelector);
  const [forcedLoading, setForcedLoading] = useState(false);

  const onCloseButtonClick = () => {
    if (isLoading || isPreCreateCartLinkLoading) {
      return;
    }
    dispatch(closePopup());
  };

  const proceedWithRedirect = (type: CartTypeEnum, reassign = false) => {
    const queryParams = new URLSearchParams({
      email: email || '',
      type,
      reassign: reassign.toString(),
    }).toString();
    document.location.replace(`${API_URL}/cart/create?${queryParams}`);
  };

  const onLimitedHandler = (reassign = false) => {
    if (presentationId) {
      return createCartLink({
        email,
        presentationId,
        type: CartTypeEnum.PRE_BUILT_LIMITED,
        reassign,
      });
    }

    proceedWithRedirect(CartTypeEnum.PRE_BUILT_LIMITED, reassign);
  };

  const onRegularHandler = (reassign = false) => {
    if (presentationId) {
      return createCartLink({
        email,
        presentationId,
        type: CartTypeEnum.PRE_BUILT_REGULAR,
        reassign,
      });
    }

    proceedWithRedirect(CartTypeEnum.PRE_BUILT_REGULAR, reassign);
  };

  const onButtonClick =
    (type: CartTypeEnum, handler: (reassign?: boolean) => void) => async () => {
      const result = await preCreateCartLink({ email, type });
      if ('data' in result) {
        const hasReassignProposals = result.data?.length;
        const hasAssignment = result.data?.every((assignment) =>
          Boolean(assignment.currentAssignmentResellerId),
        );

        const descriptionComponent = hasAssignment
          ? DESCRIPTIONS.HAS_ASSIGNMENT
          : DESCRIPTIONS.HAS_NOT_ASSIGNMENT;
        const headerComponent = hasAssignment
          ? HEADERS.HAS_ASSIGNMENT
          : HEADERS.HAS_NOT_ASSIGNMENT;
        const buttonComponent = hasAssignment
          ? BUTTONS.HAS_ASSIGNMENT
          : BUTTONS.HAS_NOT_ASSIGNMENT;
        if (hasReassignProposals) {
          dispatch(
            openPopup({
              popupName: CONFIRMATION_POPUP,
              popupProps: {
                title: headerComponent,
                descriptionComponent,
                onXClick: () => {
                  dispatch(closePopup());
                },
                onCancel: () => {
                  setForcedLoading(true);
                  handler();
                },
                onConfirm: () => {
                  setForcedLoading(true);
                  handler(true);
                },
                confirmText: buttonComponent,
                cancelText: 'Keep it as it is',
              },
            }),
          );
          return;
        }
      }
      handler();
    };

  const onBackButtonClick = () => {
    dispatch(closePopup());

    if (presentationId) {
      return;
    }

    dispatch(
      openPopup({
        popupName: CREATE_A_CART_EMAIL_POPUP,
        popupProps: { email },
      }),
    );
  };

  useEffect(() => {
    if (currentPopupName === CONFIRMATION_POPUP) {
      const loadingInProcess = isLoading || isPreCreateCartLinkLoading || forcedLoading;
      dispatch(
        patchProps({
          isPopupLoading: loadingInProcess,
          onXClick: () => !loadingInProcess && dispatch(closePopup()),
        }),
      );
    }
  }, [isLoading, isPreCreateCartLinkLoading, forcedLoading]);

  return (
    <Dialog open={isOpen} onOpenChange={onCloseButtonClick}>
      <DialogContentWrapper
        className={css.dialogContentWrapperClassName}
        overlayClassName={css.dialogOverlayClassName}
      >
        <DialogTitle closeIconClassName={css.closeIconClassName}>
          Regular Cart or Limited Cart?
        </DialogTitle>
        <DialogDescription className={css.contentWrapper}>
          <Typography
            color={COLORS.colorTextSubdued}
            fontType="bodyMd"
            lineHeight="162.5%"
            className={css.text}
          >
            There are two different types of carts that you can create for your customers
            so that they can easily checkout. The first type is called a Regular Cart.
            This is a typical cart experience where your customers can easily add
            additional products from our catalog to the cart. This is the most popular
            option.
            <br />
            <br />
            The second option is called a Limited Cart. This will show your customers the
            products that you have created for them, but they will not be able to add any
            additional products to their cart. There won&apos;t be any "Shop more", or
            "Continue shopping" buttons from the cart. People who use the Limited Cart
            tend to want to own the entire experience for their customers.
          </Typography>
        </DialogDescription>

        <DialogFooter className={css.footer}>
          {!presentationId && (
            <span onClick={onBackButtonClick} className={css.goBack}>
              <Typography
                fontType="bodyMdBold"
                lineHeight="162.5%"
                color={COLORS.colorPink}
                link
              >
                Go Back
              </Typography>
            </span>
          )}

          <SwagButton
            type="outlined"
            onClick={onButtonClick(CartTypeEnum.PRE_BUILT_LIMITED, onLimitedHandler)}
          >
            Limited Cart
          </SwagButton>
          <SwagButton
            type="primary"
            onClick={onButtonClick(CartTypeEnum.PRE_BUILT_REGULAR, onRegularHandler)}
          >
            Regular Cart
          </SwagButton>
        </DialogFooter>
        {(isLoading || isPreCreateCartLinkLoading) && (
          <div className={css.overlay}>
            <Loader />
          </div>
        )}
      </DialogContentWrapper>
    </Dialog>
  );
};
