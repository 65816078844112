import { FC, useMemo } from 'react';
import classNames from 'classnames';

import { SwagButton, Typography } from '@ezetech/swag-space-x';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { CreditCardCreationFlow } from 'interfaces/flow.inteface';
import { CreditCardType } from 'interfaces';
import VisaIcon from 'assets/svg/visa.svg';
import MasterCardIcon from 'assets/svg/master-card.svg';
import DiscoverIcon from 'assets/svg/discover.svg';
import AmericanExpressIcon from 'assets/svg/american-express.svg';
import { isCreditCardExpired } from 'utils/is-credit-card-expired';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { closePopup, openPopup } from 'redux/slices/modals.slice';
import { cardSelector } from 'redux/selectors/settings.selectors';
import {
  CONFIRMATION_POPUP,
  CREATE_CREDIT_CARD_POPUP,
} from 'components/popups/_logic/popups-list';
import { cannot, SUBJECTS } from 'boot/ability';

import { useDeleteCreditCardMutation } from '../../../../../redux/api/settings.api';
import classes from './ach.module.scss';

export const Ach: FC = () => {
  const dispatch = useAppDispatch();
  const card = useAppSelector(cardSelector);
  const [deleteCreditCard, { isLoading }] = useDeleteCreditCardMutation();

  const isAddHidden = cannot(
    SUBJECTS.ACH_NET_PAYMENTS_MAJOR_DISCOUNT_PROMO_CODES.actions
      .ACH_NET_PAYMENTS_MAJOR_DISCOUNT_PROMO_CODES_ADD,
    SUBJECTS.ACH_NET_PAYMENTS_MAJOR_DISCOUNT_PROMO_CODES.value,
  );

  const isRemoveHidden = cannot(
    SUBJECTS.ACH_NET_PAYMENTS_MAJOR_DISCOUNT_PROMO_CODES.actions
      .ACH_NET_PAYMENTS_MAJOR_DISCOUNT_PROMO_CODES_REMOVE,
    SUBJECTS.ACH_NET_PAYMENTS_MAJOR_DISCOUNT_PROMO_CODES.value,
  );

  const handleAddPaymentMethod = () => {
    dispatch(
      openPopup({
        popupName: CREATE_CREDIT_CARD_POPUP,
        popupProps: { flow: CreditCardCreationFlow.Promocode },
      }),
    );
  };

  const handleDeletePaymentMethod = () => {
    dispatch(
      openPopup({
        popupName: CONFIRMATION_POPUP,
        popupProps: {
          isConfirmLoading: isLoading,
          onConfirm: async () => {
            const paymentMethodId = card?.paymentMethodId;
            if (paymentMethodId) {
              const result = await deleteCreditCard(paymentMethodId);
              if (!('error' in result)) {
                dispatch(closePopup());
              }
            }
          },
          onCancel: () => {
            dispatch(closePopup());
          },
          descriptionComponent:
            'Are you sure you want to remove the credit card on file?',
        },
      }),
    );
  };

  const cardImage = useMemo(() => {
    switch (card?.brand) {
      case CreditCardType.MASTERCARD:
        return MasterCardIcon;
      case CreditCardType.VISA:
        return VisaIcon;
      case CreditCardType.DISCOVER:
        return DiscoverIcon;
      case CreditCardType.AMEX:
        return AmericanExpressIcon;
      default:
        return undefined;
    }
  }, [card?.brand]);

  return (
    <div className={classes.root}>
      <Typography fontType="bodyMd" lineHeight="162.5%" color={COLORS.colorPrimaryText}>
        If you offer your customers ACH or Net 15/30/45/60, we will charge this payment
        method when your customer's checkout. We will only charge you our portion of the
        order (Not including your commission). For example, if the order is for $1,000 and
        you have 30% commission, we will charge you $700. You will then be responsible for
        collecting the full amount from your customers.
        <br />
        <br />
        If you are offering a large discount promo code or a dollar off promo code that is
        more than your commission, for example, if you offer a 40% off promo code and your
        commission is only 30%, we will need to charge this credit card on file the
        difference (10%).
      </Typography>
      {card ? (
        <div>
          <div
            className={classNames(classes.cardWrapper, {
              [classes.cardWrapperError]: isCreditCardExpired(
                card.expireMonth,
                card.expireYear,
              ),
            })}
          >
            <div className={classes.column}>
              <Typography
                fontType="bodyMdBold"
                lineHeight={'162.5%'}
                color={COLORS.colorPrimaryText}
              >
                Current payment method
              </Typography>
              <div className={classes.numberWrapper}>
                {cardImage && <img src={cardImage} alt="Credit card system" />}{' '}
                <Typography
                  fontType="bodyMd"
                  lineHeight={'162.5%'}
                  color={COLORS.colorPrimaryText}
                >
                  **** **** **** {card.last4}
                </Typography>
              </div>
            </div>
            <div className={classes.column}>
              <Typography
                fontType="bodyMdBold"
                lineHeight={'162.5%'}
                color={COLORS.colorPrimaryText}
              >
                Expires (MM/YY)
              </Typography>
              <Typography
                fontType="bodyMd"
                lineHeight={'162.5%'}
                color={COLORS.colorPrimaryText}
              >
                {card.expireMonth}/{card.expireYear.toString().slice(-2)}
              </Typography>
            </div>
          </div>
          {isCreditCardExpired(card.expireMonth, card.expireYear) && (
            <Typography
              fontType="bodyMd"
              lineHeight={'162.5%'}
              color={COLORS.colorErrorText}
            >
              This card has been expired{' '}
            </Typography>
          )}
        </div>
      ) : (
        <div className={classes.noPaymentMethods}>
          <Typography fontType="bodyMdBold" color={COLORS.colorSecondaryText}>
            No Payment Methods
          </Typography>
        </div>
      )}
      <div className={classes.buttonWrapper}>
        {card && !isRemoveHidden && (
          <SwagButton
            onClick={handleDeletePaymentMethod}
            className={classNames(classes.buttonWrapper)}
            size="large"
            type="destructivePrimary"
          >
            Remove
          </SwagButton>
        )}
        {!card && !isAddHidden && (
          <SwagButton
            className={classes.buttonWrapper}
            size="large"
            type="primary"
            onClick={handleAddPaymentMethod}
          >
            Add Payment Method
          </SwagButton>
        )}
      </div>
    </div>
  );
};
