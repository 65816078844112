import * as yup from 'yup';
import { PdfPresentationEditorPageId } from 'interfaces/pdf-presentation';

interface GetErrorMessageProps {
  page: PdfPresentationEditorPageId;
  fieldName: string;
  message: string;
  type?: 'mandatory' | 'optional';
}

const getErrorMessage = ({
  page,
  fieldName,
  message,
  type = 'optional',
}: GetErrorMessageProps) => {
  return `${page}:::${fieldName}:::${message}:::${type}`;
};

export const EditorValidationSchema = yup.object({
  homepageHeading: yup
    .string()
    .trim()
    .required(
      getErrorMessage({
        page: PdfPresentationEditorPageId.COVER,
        fieldName: 'Heading',
        message: 'Missing Heading text.',
        type: 'mandatory',
      }),
    ),
  homepageBody: yup
    .string()
    .trim()
    .required(
      getErrorMessage({
        page: PdfPresentationEditorPageId.COVER,
        fieldName: 'Heading',
        message: 'Missing Body Copy text.',
        type: 'mandatory',
      }),
    ),

  whatWeDoHeading: yup
    .string()
    .trim()
    .required(
      getErrorMessage({
        page: PdfPresentationEditorPageId.WHAT_WE_DO,
        fieldName: 'Heading',
        message: 'Missing Heading text.',
        type: 'mandatory',
      }),
    ),
  whatWeDoBody: yup
    .string()
    .trim()
    .required(
      getErrorMessage({
        page: PdfPresentationEditorPageId.WHAT_WE_DO,
        fieldName: 'Heading',
        message: 'Missing Body Copy text.',
        type: 'mandatory',
      }),
    ),
  brandLogos: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          name: yup.string().optional().nullable(),
          size: yup.number().optional().nullable(),
          type: yup.string().optional().nullable(),
          url: yup.string().optional().nullable(),
        })
        .nullable()
        .optional(),
    )
    .test('array-length-validation', (value, context) => {
      const { path, createError } = context;
      if (value?.every((el) => !el)) {
        return createError({
          path,
          message: getErrorMessage({
            page: PdfPresentationEditorPageId.WHO_WE_WORK_WITH,
            fieldName: 'Brand Logos',
            type: 'mandatory',
            message: 'For best results, we recommend adding 4, 8, 12 or 16 logos.',
          }),
        });
      }
      if (![0, 4, 8, 12, 16].includes(value?.filter((el) => !el).length ?? 0)) {
        return createError({
          path,
          message: getErrorMessage({
            page: PdfPresentationEditorPageId.WHO_WE_WORK_WITH,
            fieldName: 'Brand Logos',
            type: 'optional',
            message: 'For best results, we recommend adding 4, 8, 12 or 16 logos.',
          }),
        });
      }
    }),
  portfolioImage: yup.object().required(
    getErrorMessage({
      page: PdfPresentationEditorPageId.PAST_WORKS,
      fieldName: 'Portfolio Image',
      type: 'mandatory',
      message: 'Missing past works image.',
    }),
  ),
  boxOverviewImage: yup.object().required(
    getErrorMessage({
      page: PdfPresentationEditorPageId.BOX_OVERVIEW,
      fieldName: 'Box Overview Image',
      type: 'mandatory',
      message: 'Missing box overview image.',
    }),
  ),
  reviews: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          logo: yup
            .object()
            .shape({
              name: yup.string().optional().nullable(),
              size: yup.number().optional().nullable(),
              type: yup.string().optional().nullable(),
              url: yup.string().optional().nullable(),
            })
            .optional()
            .nullable(),
          text: yup.string().optional().nullable(),
        })
        .nullable()
        .optional(),
    )
    .test('array-length-validation', (value, context) => {
      const { path, createError } = context;
      if (value?.every((el) => !el?.logo?.url && !el?.text)) {
        return createError({
          path,
          message: getErrorMessage({
            page: PdfPresentationEditorPageId.REVIEWS,
            fieldName: 'Reviews',
            type: 'mandatory',
            message: 'For best results, we recommend adding 3, 6 filled reviews.',
          }),
        });
      }
      if (
        ![0, 3, 6].includes(value?.filter((el) => el?.logo?.url || el?.text).length ?? 0)
      ) {
        return createError({
          path,
          message: getErrorMessage({
            page: PdfPresentationEditorPageId.REVIEWS,
            fieldName: 'Reviews',
            type: 'optional',
            message: 'For best results, we recommend adding 3, 6 filled reviews.',
          }),
        });
      }
    }),
  productShowcaseImage: yup.object().required(
    getErrorMessage({
      page: PdfPresentationEditorPageId.SHOWCASE,
      fieldName: 'Product Showcase Image',
      type: 'mandatory',
      message: 'Missing product showcase image.',
    }),
  ),
  contactPhone: yup
    .string()
    .trim()
    .required(
      getErrorMessage({
        page: PdfPresentationEditorPageId.THANK_YOU,
        fieldName: 'Contact Phone',
        type: 'optional',
        message: 'Missing phone.',
      }),
    ),
  contactEmail: yup
    .string()
    .trim()
    .required(
      getErrorMessage({
        page: PdfPresentationEditorPageId.THANK_YOU,
        fieldName: 'Contact Email',
        type: 'optional',
        message: 'Missing email.',
      }),
    ),
  contactAddress: yup
    .string()
    .trim()
    .required(
      getErrorMessage({
        page: PdfPresentationEditorPageId.THANK_YOU,
        fieldName: 'Contact Address',
        type: 'optional',
        message: 'Missing address.',
      }),
    ),
});
