import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICustomersLeadsData, IUpdateLeadStatus } from 'interfaces';
import { IAssignedForReseller } from 'interfaces/assignee.interface';

const initialState: ICustomersLeadsData = {
  list: [],
  leadsTotal: 0,
  customersTotal: 0,
};

export const customersLeadsSlice = createSlice({
  initialState,
  name: 'customersLeadsSlice',
  reducers: {
    reset: () => initialState,
    setCLState: (state, action: PayloadAction<ICustomersLeadsData | null>) => {
      if (!action.payload) {
        return state;
      }

      return { ...state, ...action.payload };
    },
    setCustomersAndLeadsCounts: (
      state,
      action: PayloadAction<{ leadsTotal: number; customersTotal: number }>,
    ) => {
      const { leadsTotal, customersTotal } = action.payload;

      return { ...state, leadsTotal, customersTotal };
    },
    setStatusToUser: (state, action: PayloadAction<IUpdateLeadStatus>) => {
      const { list } = state;
      const {
        payload: { id, status },
      } = action;

      const userIndex = list.findIndex((user) => user.id === id);

      if (userIndex === -1) {
        return state;
      }

      const user = list[userIndex];

      return {
        ...state,
        list: [
          ...list.slice(0, userIndex),
          { ...user, status: status },
          ...list.slice(userIndex + 1),
        ],
      };
    },
    setAssigneeToCustomerLeadById: (
      state,
      action: PayloadAction<{ entityId: string; assignee: IAssignedForReseller | null }>,
    ) => {
      const { entityId, assignee } = action.payload;

      const index = state.list.findIndex((item) => item.id === entityId);

      if (index !== -1) {
        state.list[index].assignee = assignee;
      }
    },
  },
});

export const customersLeadsReducer = customersLeadsSlice.reducer;
export const {
  reset,
  setCLState,
  setCustomersAndLeadsCounts,
  setStatusToUser,
  setAssigneeToCustomerLeadById,
} = customersLeadsSlice.actions;
