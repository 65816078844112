import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/store';

import { PdfPresentationEditorUpdateBody } from 'interfaces/pdf-presentation';
import { useOnceCall } from 'hooks/use-effect-once.hook';
import { debounce } from 'utils/common.util';
import { popupNameSelector } from 'redux/selectors/modals.selectors';
import { formatAddress } from 'utils/address-format.util';

import {
  changePdfRendering,
  changeDraftAndMainValue,
  setPdfImagesOfPages,
} from '../../../redux/slices/pdf-presentation-editor.slice';

import {
  editableDraftSelector,
  editableLastChangesSelector,
  pagesToRenderSelector,
  paginationSelector,
  pdfImagesOfPagesSelector,
} from '../../../redux/selectors/pdf-presentation-editor.selectors';

import { useGetPdfPresentationEditorSettingsMutation } from '../../../redux/api/settings.api';
import {
  companyDetailsSelector,
  brandColorsSelector,
  brandLogoSelector,
  billingAddressSelector,
  detailsFormEditedSelector,
} from '../../../redux/selectors/settings.selectors';
import { usePdfViewer } from '../../pdf-viewer';
import {
  CONFIRMATION_POPUP,
  EDIT_PDF_PRESENTATION_LAYOUT_POPUP,
  SAVE_PDF_PRESENTATION_CHANGES_CONFIRMATION_POPUP,
} from '../_logic/popups-list';

const NO_DEBOUNCE_USE_FOR_FIELDS = [
  'brandLogos',
  'portfolioImage',
  'boxOverviewImage',
  'productShowcaseImage',
];

export const HeatingEditPdfPresentation = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const [getPdfPresentationSettings, presentationSettings] =
    useGetPdfPresentationEditorSettingsMutation();

  const pagination = useAppSelector(paginationSelector);
  const editableDraft = useAppSelector(editableDraftSelector);
  const pagesToRender = useAppSelector(pagesToRenderSelector);
  const editableLastChanges = useAppSelector(editableLastChangesSelector);
  const { primaryColor, secondaryColor } = useAppSelector(brandColorsSelector);
  const { email, phone } = useAppSelector(companyDetailsSelector);
  const billingAddress = useAppSelector(billingAddressSelector);
  const pdfImagesOfPages = useAppSelector(pdfImagesOfPagesSelector);
  const popupName = useAppSelector(popupNameSelector);
  const isSettingsChanged = useAppSelector(detailsFormEditedSelector);
  const logoUrl = `${useAppSelector(brandLogoSelector)}?source=edit-pdf`;
  const [updatedValues, setUpdatedValues] =
    useState<PdfPresentationEditorUpdateBody | null>(null);
  const [updatedStoreSettings, setUpdatedStoreSettings] = useState({
    logoUrl,
    primaryColor,
    secondaryColor,
  });

  const [isInitiated, setIsInitiated] = useState(false);
  const reRenderAllPages =
    !pdfImagesOfPages.length ||
    (popupName !== CONFIRMATION_POPUP &&
      popupName !== SAVE_PDF_PRESENTATION_CHANGES_CONFIRMATION_POPUP &&
      popupName !== EDIT_PDF_PRESENTATION_LAYOUT_POPUP);

  useOnceCall(() => {
    getPdfPresentationSettings();
  });

  const debouncedHandleUpdates = useCallback(
    debounce((data) => {
      setUpdatedValues(data);
    }, 800),
    [],
  );

  const debouncedHandleStoreUpdates = useCallback(
    debounce((data) => {
      setUpdatedStoreSettings(data);
    }, 800),
    [],
  );

  const editableList = Object.entries(editableDraft).filter(
    ([key]) => !key.endsWith('Hidden'),
  ) as Iterable<readonly unknown[]>;

  useEffect(() => {
    if (
      !isInitiated ||
      (editableLastChanges?.keyName &&
        NO_DEBOUNCE_USE_FOR_FIELDS.includes(editableLastChanges?.keyName))
    ) {
      setUpdatedValues(
        Object.fromEntries(editableList) as PdfPresentationEditorUpdateBody,
      );
    } else {
      debouncedHandleUpdates(
        Object.fromEntries(editableList) as PdfPresentationEditorUpdateBody,
      );
    }
  }, [JSON.stringify(editableList)]);

  useEffect(() => {
    if (!reRenderAllPages || !primaryColor || !secondaryColor || !logoUrl) {
      return;
    }

    debouncedHandleStoreUpdates({
      primaryColor,
      secondaryColor,
      logoUrl,
    });
  }, [primaryColor, secondaryColor, logoUrl]);

  useEffect(() => {
    if (!reRenderAllPages || editableDraft.id || isSettingsChanged) {
      return;
    }
    if (editableDraft.contactPhone !== phone) {
      dispatch(changeDraftAndMainValue({ keyName: 'contactPhone', value: phone }));
    }
    if (editableDraft.contactEmail !== email) {
      dispatch(changeDraftAndMainValue({ keyName: 'contactEmail', value: email }));
    }
    const contactAddress = formatAddress(billingAddress);
    if (editableDraft.contactAddress !== contactAddress) {
      dispatch(
        changeDraftAndMainValue({
          keyName: 'contactAddress',
          value: contactAddress,
        }),
      );
    }
  }, [isSettingsChanged]);

  useEffect(() => {
    if (
      !isInitiated &&
      !presentationSettings.isLoading &&
      updatedValues &&
      typeof primaryColor !== undefined &&
      typeof secondaryColor !== undefined &&
      typeof logoUrl !== undefined
    ) {
      setIsInitiated(true);
    }
  }, [
    presentationSettings.isLoading,
    updatedValues,
    logoUrl,
    primaryColor,
    secondaryColor,
    logoUrl,
  ]);

  const pdfSettings =
    isInitiated && updatedValues
      ? {
          pagesToRender: pagesToRender.map((page) => page.id),
          values: updatedValues,
          ...updatedStoreSettings,
        }
      : null;

  const { HiddenDocument, isRendering } = usePdfViewer({
    pdfSettings,
    currentPageIndex: pagination.currentPage - 1,
    reRenderAllPages,
    onPagesRendered: (pdfImages) => {
      dispatch(setPdfImagesOfPages(pdfImages));
    },
  });

  useEffect(() => {
    dispatch(changePdfRendering(isRendering));
  }, [isRendering]);

  return HiddenDocument;
};
