import { EditorState, ContentState, convertToRaw, RawDraftContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

export const htmlToEditorState = (html: string): EditorState | null => {
  const draft = htmlToDraft(html);

  if (draft) {
    const contentState = ContentState.createFromBlockArray(draft.contentBlocks);

    return EditorState.createWithContent(contentState);
  }

  return null;
};

export const htmlToContentState = (html: string): RawDraftContentState => {
  const draft = htmlToDraft(html);

  const contentState = ContentState.createFromBlockArray(draft.contentBlocks);
  return convertToRaw(contentState);
};

export const editorStateToHtml = (state: EditorState): string =>
  draftToHtml(convertToRaw(state.getCurrentContent()));
