import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { ReducerPath } from 'interfaces';
import {
  CategoriesStructure,
  IHoursOperationsBack,
  IPatchStoreSettings,
  IStoreSettingsBack,
} from 'interfaces/store-creation.interfaces';
import { pushNotification } from 'redux/slices/notifications.slice';
import {
  setOnboardingFlowStatus,
  setStoreSettings,
  setLogoUrl,
  setFaviconUrl,
  updateStoreSettingsIndex,
  setCategories,
} from 'redux/slices/store-creation.slice';
import {
  setBrand,
  setCompanySettings,
  setMargin,
  setSocial,
} from 'redux/slices/settings.slice';
import { mapBrand, mapHours, mapMargin, mapSocial } from 'utils/settings/mappers';
import { mapFieldsToDB, mapFieldsToStore } from 'utils/map-fields.utils';
import { makeToastNotification } from 'utils/query.util';

import { baseQueryParams } from './helpers';

export const storeSettingsApi = createApi({
  reducerPath: ReducerPath.storeSettings,
  baseQuery: fetchBaseQuery({
    ...baseQueryParams,
  }),
  endpoints: (builder) => ({
    getStoreSettings: builder.mutation<IStoreSettingsBack, void>({
      query: () => ({ url: '/company-settings', method: 'GET' }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(setStoreSettings(mapFieldsToStore(data)));
            dispatch(
              setCompanySettings({
                ...data,
                hoursOperations: mapHours(data.hoursOperations) as IHoursOperationsBack[],
              }),
            );
            dispatch(setSocial(mapSocial(data)));
            dispatch(setBrand(mapBrand(data)));
            dispatch(setMargin(mapMargin(data)));
          }
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),

    patchStoreSettings: builder.mutation<IStoreSettingsBack, IPatchStoreSettings>({
      query: ({ currentStepIndex, isOnboardingFlow, ...payload }) => ({
        url: '/company-settings',
        method: 'PATCH',
        body: mapFieldsToDB(payload),
      }),
      onQueryStarted: async (
        { currentStepIndex, isOnboardingFlow = true },
        { dispatch, queryFulfilled },
      ) => {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(setStoreSettings(mapFieldsToStore(data)));
            if (isOnboardingFlow) {
              dispatch(updateStoreSettingsIndex({ currentStepIndex }));
            }
          }
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),

    getIsOnboardingCompleted: builder.query<{ completed: boolean }, void>({
      query: () => ({ url: '/company-settings/completed', method: 'GET' }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(setOnboardingFlowStatus({ isCompleted: data.completed }));
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log('e');
        }
      },
    }),

    uploadLogo: builder.mutation<Record<string, Record<string, string>>, FormData>({
      query: (data) => ({
        url: '/company-settings/logo',
        method: 'POST',
        body: data,
      }),

      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data && data.logo) {
            dispatch(setLogoUrl({ logo: data.logo }));
          }
          if (data && data.favicon) {
            dispatch(setFaviconUrl({ favicon: data.favicon }));
          }
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),

    getCategories: builder.query<CategoriesStructure, void>({
      query: () => ({ url: '/categories', method: 'GET' }),

      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(setCategories({ categories: data }));
          }
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),
  }),
});

export const {
  usePatchStoreSettingsMutation,
  useGetStoreSettingsMutation,
  useUploadLogoMutation,
  useGetIsOnboardingCompletedQuery,
  useGetCategoriesQuery,
} = storeSettingsApi;
