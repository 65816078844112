import '@ezetech/swag-space-x/dist/styles/error-message.css';
import '@ezetech/swag-space-x/dist/styles/tooltip.css';
import '@ezetech/swag-space-x/dist/styles/white-box.css';
import '@ezetech/swag-space-x/dist/styles/input.css';
import '@ezetech/swag-space-x/dist/styles/input-wrapper.css';
import '@ezetech/swag-space-x/dist/styles/adornment-button.css';
import '@ezetech/swag-space-x/dist/styles/colors-selector.css';
import '@ezetech/swag-space-x/dist/styles/color-picker.css';
import '@ezetech/swag-space-x/dist/styles/text-field.css';
import '@ezetech/swag-space-x/dist/styles/divider.css';
import '@ezetech/swag-space-x/dist/styles/typography.css';
import '@ezetech/swag-space-x/dist/styles/swag-button.css';
import '@ezetech/swag-space-x/dist/styles/announcement-bar.css';
import '@ezetech/swag-space-x/dist/styles/button-color-picker.css';
import '@ezetech/swag-space-x/dist/styles/pagination-button.css';
import '@ezetech/swag-space-x/dist/styles/accordion.css';
import '@ezetech/swag-space-x/dist/styles/animation-wrapper.css';
import '@ezetech/swag-space-x/dist/styles/checkbox.css';
import '@ezetech/swag-space-x/dist/styles/control.css';
import '@ezetech/swag-space-x/dist/styles/date-range-inputs.css';
import '@ezetech/swag-space-x/dist/styles/dialog.css';
import '@ezetech/swag-space-x/dist/styles/dropdown-menu.css';
import '@ezetech/swag-space-x/dist/styles/download-csv.css';
import '@ezetech/swag-space-x/dist/styles/dropzone.css';
import '@ezetech/swag-space-x/dist/styles/dropzone-preview-image.css';
import '@ezetech/swag-space-x/dist/styles/dropzone-file-area.css';
import '@ezetech/swag-space-x/dist/styles/dropzone-active-file-area.css';
import '@ezetech/swag-space-x/dist/styles/image.css';
import '@ezetech/swag-space-x/dist/styles/image-upload.css';
import '@ezetech/swag-space-x/dist/styles/linear-progress-bar.css';
import '@ezetech/swag-space-x/dist/styles/link.css';
import '@ezetech/swag-space-x/dist/styles/list-item.css';
import '@ezetech/swag-space-x/dist/styles/list-header.css';
import '@ezetech/swag-space-x/dist/styles/list.css';
import '@ezetech/swag-space-x/dist/styles/loader.css';
import '@ezetech/swag-space-x/dist/styles/radio.css';
import '@ezetech/swag-space-x/dist/styles/radio-group.css';
import '@ezetech/swag-space-x/dist/styles/rainbow-box.css';
import '@ezetech/swag-space-x/dist/styles/rainbow-input.css';
import '@ezetech/swag-space-x/dist/styles/rounded-select.css';
import '@ezetech/swag-space-x/dist/styles/search-input.css';
import '@ezetech/swag-space-x/dist/styles/select.css';
import '@ezetech/swag-space-x/dist/styles/skeleton.css';
import '@ezetech/swag-space-x/dist/styles/space-footer.css';
import '@ezetech/swag-space-x/dist/styles/step.css';
import '@ezetech/swag-space-x/dist/styles/stepper.css';
import '@ezetech/swag-space-x/dist/styles/stripe.css';
import '@ezetech/swag-space-x/dist/styles/stripe-payment-form.css';
import '@ezetech/swag-space-x/dist/styles/table-header-cell.css';
import '@ezetech/swag-space-x/dist/styles/table-header.css';
import '@ezetech/swag-space-x/dist/styles/table-body.css';
import '@ezetech/swag-space-x/dist/styles/table-row-cell.css';
import '@ezetech/swag-space-x/dist/styles/table-row.css';
import '@ezetech/swag-space-x/dist/styles/table.css';
import '@ezetech/swag-space-x/dist/styles/tabs.css';
import '@ezetech/swag-space-x/dist/styles/tabs-navigation.css';
import '@ezetech/swag-space-x/dist/styles/text-toggle.css';
import '@ezetech/swag-space-x/dist/styles/tier-progress-bar.css';
import '@ezetech/swag-space-x/dist/styles/tier-trophy-icon.css';
import '@ezetech/swag-space-x/dist/styles/toasts.css';
import '@ezetech/swag-space-x/dist/styles/toasts.viewport.css';
import '@ezetech/swag-space-x/dist/styles/toggle.css';
import '@ezetech/swag-space-x/dist/styles/colored-frame.css';
import '@ezetech/swag-space-x/dist/styles/slider.css';
import '@ezetech/swag-space-x/dist/styles/image-crop.css';
import '@ezetech/swag-space-x/dist/styles/image-crop-tools.css';
import '@ezetech/swag-space-x/dist/styles/image-crop-drag-and-drop.css';

import { RouterProvider } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { store } from './redux/store';
import { router } from './boot/router';
import { Notification } from './components/notifications';

import './index.module.scss';

export function App() {
  return (
    <ReduxProvider store={store}>
      <RouterProvider router={router} />
      <Notification />
    </ReduxProvider>
  );
}
