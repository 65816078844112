import { createSelector } from 'reselect';
import { CellSize, ICell } from '@ezetech/swag-space-x';

import {
  PROMOCODES_TABLE_HEADER,
  FIELDS,
  DB_FIELDS,
  IS_FREE_SHIPPING,
  IS_FREE_SETUP_FEE,
  PROMOCODE_TYPES_LABELS,
} from 'constants/promocodes.constants';
import { Price } from '../../utils/price.util';
import { RootState } from '../store';

export const promocodesSelector = (state: RootState) => state.promocodes;

export const promocodesListSelector = createSelector(
  promocodesSelector,
  (promocodes) => promocodes.list,
);

const getDiscountField = (value: number, isAbsolute: boolean) => {
  return isAbsolute ? Price.formatPrice(value) : `${value}%`;
};

export const promocodesListTableRowsSelector = createSelector(
  promocodesListSelector,
  (list) => {
    const rows: { cells: ICell[]; index: number; id: string }[] = [];

    (list || []).forEach((item, position) => {
      const cells = PROMOCODES_TABLE_HEADER.map((cell) => {
        if (cell.id === FIELDS.name.name) {
          return {
            text: item.code,
            id: cell.id,
          };
        }
        if (cell.id === FIELDS.discountPercent.name) {
          return {
            text: getDiscountField(item.value, item.isAbsolute),
            id: cell.id,
          };
        }
        if (cell.id === DB_FIELDS.timesUsed) {
          return {
            id: cell.id,
            text: String(item.timesUsed),
            size: CellSize.compact,
          };
        }
        if (cell.id === FIELDS.type.name) {
          return {
            id: cell.id,
            text: PROMOCODE_TYPES_LABELS[item.type],
          };
        }
        if (cell.id === IS_FREE_SETUP_FEE) {
          return {
            id: cell.id,
            text: item[IS_FREE_SETUP_FEE] ? ' Yes' : 'No',
          };
        }
        if (cell.id === IS_FREE_SHIPPING) {
          return {
            id: cell.id,
            text: item[IS_FREE_SHIPPING] ? ' Yes' : 'No',
          };
        }
        return {
          id: cell.id,
          text: item.id,
        };
      });
      rows.push({
        cells,
        id: item.id,
        index: position + 1,
      });
    });

    return rows;
  },
);
