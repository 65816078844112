import cn from 'classnames';
import { useState } from 'react';
import {
  Divider,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Typography,
} from '@ezetech/swag-space-x';

import { SUBJECTS, can } from 'boot/ability';
import { useAppSelector } from 'redux/store';
import {
  firstNameSelector,
  lastNameSelector,
  userIdSelector,
} from 'redux/selectors/user.selectors';
import { orderCardHeaderSelector } from 'redux/selectors/orders.selectors';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { ChevronUp, Profile } from 'components/svg';
import { usersAllowedForOrdersAssignmentSelector } from 'redux/selectors/assignee.selector';
import { useAssignOrdersToUserMutation } from 'redux/api/assignee.api';

import { OrderCardHeaderHolder } from '../order-card-header-holder';

import css from './order-card-header.module.scss';

interface IProps {
  orderId: string;
  isOrderAssignmentEnabled: boolean;
}

export const OrderCardHeaderComponent = ({
  orderId,
  isOrderAssignmentEnabled,
}: IProps) => {
  const [isOpen, toggleIsOpen] = useState(false);
  const { num, confirmedAt, email, fullName, hasOrderHolder, userId, assignee } =
    useAppSelector(orderCardHeaderSelector({ orderId }));
  const currentResellerId = useAppSelector(userIdSelector);
  const currentResellerFirstName = useAppSelector(firstNameSelector);
  const currentResellerLastName = useAppSelector(lastNameSelector);
  const usersAllowedForAssignment = useAppSelector(
    usersAllowedForOrdersAssignmentSelector,
  );
  const [assignResellerToOrderRequest] = useAssignOrdersToUserMutation();

  const assignResellerToOrder = async (id: string | null) => {
    await assignResellerToOrderRequest({ entityId: orderId, userId: id });
  };

  const isAbleToAssignOrders = can(
    SUBJECTS.TEAM_MEMBER_ORDER_ASSIGNMENT.actions.ASSIGN_TEAM_MEMBER_TO_ORDER,
    SUBJECTS.TEAM_MEMBER_ORDER_ASSIGNMENT.value,
  );

  const isAbleToBeAssigned = can(
    SUBJECTS.ORDER_ASSIGNMENT.actions.ASSIGN_TO_ORDERS,
    SUBJECTS.ORDER_ASSIGNMENT.value,
  );

  const currentReseller = {
    id: currentResellerId,
    firstName: currentResellerFirstName,
    lastName: currentResellerLastName,
  };

  const isOrderAssignedToMe = assignee && assignee.id === currentResellerId;
  const isOrderAssignedToSomebody = assignee && assignee.id !== currentResellerId;

  const isAssignedToMe = isOrderAssignmentEnabled && isOrderAssignedToMe;
  const isAssignedToSomebody = isOrderAssignmentEnabled && isOrderAssignedToSomebody;
  const canAssignToMyself =
    isOrderAssignmentEnabled &&
    isAbleToBeAssigned &&
    !isOrderAssignedToMe &&
    !isOrderAssignedToSomebody;
  const canAssignToSomebodyExceptMe =
    isOrderAssignmentEnabled &&
    isAbleToAssignOrders &&
    !!usersAllowedForAssignment.length;

  const canAssignOnlyToMyself = canAssignToMyself && !canAssignToSomebodyExceptMe;
  const canAssignToSomebody = canAssignToMyself || canAssignToSomebodyExceptMe;

  const shouldRenderUnassignOption =
    (isOrderAssignedToSomebody || isOrderAssignedToMe) && isAbleToAssignOrders;

  const shouldRenderAssignmentWidget =
    isAssignedToMe ||
    isAssignedToSomebody ||
    canAssignToMyself ||
    canAssignToSomebodyExceptMe;

  const getTriggerComponent = () => {
    if (isAssignedToMe) {
      return (
        <div className={css.oneLineText}>
          <Typography
            className={cn(css.assignToMeOrderText, {
              [css.isNotClickable]: !isAbleToAssignOrders,
            })}
            fontType="bodySmBold"
            lineHeight="142.857%"
            color={isAbleToAssignOrders ? COLORS.colorPink : COLORS.colorPrimaryText}
          >
            <Profile fillColor={COLORS.colorTextIcon} className={css.profileIcon} />
            Assigned to me
          </Typography>
          {isAbleToAssignOrders && (
            <ChevronUp
              fillColor={COLORS.colorPink}
              className={cn(css.chevronDown, { [css.chevronUp]: isOpen })}
            />
          )}
        </div>
      );
    }

    if (isAssignedToSomebody) {
      return (
        <div className={css.oneLineText}>
          <Profile fillColor={COLORS.colorTextIcon} className={css.profileIcon} />

          <Typography
            className={cn(css.assignOrderText, {
              [css.isNotClickable]: !isAbleToAssignOrders,
            })}
            fontType="bodySmBold"
            lineHeight="142.857%"
            color={isAbleToAssignOrders ? COLORS.colorPink : COLORS.colorPrimaryText}
          >
            {assignee.firstName} {assignee.lastName}
          </Typography>
          {isAbleToAssignOrders && (
            <ChevronUp
              fillColor={isAbleToAssignOrders ? COLORS.colorPink : COLORS.colorTextIcon}
              className={cn(css.chevronDown, { [css.chevronUp]: isOpen })}
            />
          )}
        </div>
      );
    }

    return (
      <div className={css.oneLineText}>
        <Typography
          className={css.assignOrderText}
          fontType="bodySmBold"
          lineHeight="142.857%"
          color={COLORS.colorPink}
        >
          Assign Order
        </Typography>
        <ChevronUp
          fillColor={COLORS.colorPink}
          className={cn(css.chevronDown, { [css.chevronUp]: isOpen })}
        />
      </div>
    );
  };

  return (
    <div className={css.header}>
      <div className={css.headerRow}>
        <span className={css.orderNumberWrapper}>
          <Typography
            lineHeight="100%"
            fontType="bodySm"
            color={COLORS.colorPrimaryText}
            className={css.orderNumber}
          >
            Order #{num}
          </Typography>
        </span>
        <Divider className={css.divider} color={COLORS.colorNeural200} />
        {hasOrderHolder && (
          <OrderCardHeaderHolder
            userId={userId}
            email={email}
            fullName={fullName}
            className={css.orderHolder}
          />
        )}

        {shouldRenderAssignmentWidget ? (
          <DropdownMenu
            className={cn(css.dropdownAssignmentMenu, {
              [css.isDisabledDropdownAssignmentMenu]: !isAbleToAssignOrders,
            })}
            onOpenChange={toggleIsOpen}
          >
            <DropdownMenuTrigger className={css.triggerAssignment}>
              {getTriggerComponent()}
            </DropdownMenuTrigger>

            {canAssignToSomebody && (
              <DropdownMenuContent
                align="end"
                sideOffset={10}
                className={css.dropdownMenuContent}
              >
                {shouldRenderUnassignOption && (
                  <DropdownMenuItem
                    onSelect={() => assignResellerToOrder(null)}
                    className={css.dropdownMenuItem}
                    itemInnerClassName={css.dropdownMenuItemInner}
                  >
                    <Typography
                      lineHeight="100%"
                      fontType="bodySm"
                      color={COLORS.colorPrimaryText}
                    >
                      Unassign
                    </Typography>
                  </DropdownMenuItem>
                )}
                {(canAssignOnlyToMyself
                  ? [currentReseller]
                  : usersAllowedForAssignment
                ).map((resellerAdmin) => (
                  <DropdownMenuItem
                    key={resellerAdmin.id}
                    onSelect={() => assignResellerToOrder(resellerAdmin.id)}
                    className={cn(css.dropdownMenuItem, {
                      [css.isAssigneeDisabled]: resellerAdmin.id === assignee?.id,
                    })}
                    itemInnerClassName={css.dropdownMenuItemInner}
                  >
                    <Typography
                      lineHeight="100%"
                      fontType="bodySm"
                      color={COLORS.colorPrimaryText}
                    >
                      {resellerAdmin.firstName} {resellerAdmin.lastName}
                    </Typography>
                  </DropdownMenuItem>
                ))}
              </DropdownMenuContent>
            )}
          </DropdownMenu>
        ) : (
          <div />
        )}

        <Typography
          lineHeight="142%"
          fontType="bodySm"
          color={COLORS.colorText500}
          className={css.date}
        >
          {confirmedAt}
        </Typography>

        <Divider className={css.divider2} color={COLORS.colorNeural200} />
      </div>
    </div>
  );
};
