import { useState } from 'react';
import { IFile } from '@ezetech/swag-space-x';
import { useUploadLogoPreviewMutation } from '../redux/api/settings.api';

export const useProcessFile = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [uploadLogoPreview] = useUploadLogoPreviewMutation();
  const handleProcessFile = async (file: File): Promise<IFile> => {
    const formData = new FormData();
    formData.set('file', file);
    setIsLoading(true);
    const response = await uploadLogoPreview(formData);

    if ('data' in response) {
      const { data } = response;
      setIsLoading(false);
      return {
        name: file.name,
        size: file.size,
        type: file.type,
        url: data.url as unknown as string,
      };
    } else {
      setIsLoading(false);
      throw new Error('Upload failed');
    }
  };

  return {
    handleProcessFile,
    isLoading,
  };
};
