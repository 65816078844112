import { PageContent } from 'components/page-content';
import css from './company-profile.module.scss';

export function CompanyProfileComponent(): JSX.Element {
  return (
    <PageContent heading="Company" pageContentClassName={css.wrapper}>
      <div className={css.container}>company profile page</div>
    </PageContent>
  );
}
