import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Input, OptionType, Select } from '@ezetech/swag-space-x';
import classNames from 'classnames';
import {
  AVAILABLE_COUNTRIES_LIST,
  CountriesStatesSelect,
  getStates,
} from 'constants/settings.constants';
import { IBillingAddress } from 'interfaces';
import { useAppDispatch } from 'redux/store';
import {
  billingAddressErrorsSelector,
  billingAddressSelector,
} from 'redux/selectors/settings.selectors';
import { patchBillingAddress, setBillingAddressError } from 'redux/slices/settings.slice';
import { cannot, SUBJECTS } from 'boot/ability';

import css from './address.module.scss';
import { addressFormSchema } from './address.schema';
import { FIELDS } from './address.constants';

export const AddressForm: FC<{ isPopupForm?: boolean }> = ({ isPopupForm = false }) => {
  const values = useSelector(billingAddressSelector);
  const dispatch = useAppDispatch();
  const errors = useSelector(billingAddressErrorsSelector);
  const isDisabled = cannot(
    SUBJECTS.BILLING_ADDRESS.actions.BILLING_ADDRESS_EDIT,
    SUBJECTS.BILLING_ADDRESS.value,
  );

  const onChange = (path: string) => async (value: unknown) => {
    const stateClear =
      path === FIELDS.country.name
        ? {
            [FIELDS.state.name as keyof IBillingAddress]: '',
          }
        : {};
    dispatch(
      patchBillingAddress({
        [path]: value,
        ...stateClear,
      }),
    );
    dispatch(setBillingAddressError({ [path]: undefined }));
  };

  const onBlur = (path: string) => async () => {
    try {
      await addressFormSchema.validateAt(path, {
        [path]: values[path as keyof IBillingAddress],
      });
      dispatch(setBillingAddressError({ [path]: undefined }));
    } catch (validationError: unknown) {
      const { message } = validationError as { message: string };
      dispatch(setBillingAddressError({ [path]: message }));
    }
  };

  return (
    <form
      className={classNames(css.form, {
        [css.scrollable]: isPopupForm,
      })}
    >
      <Input
        disabled={isDisabled}
        className={css.input}
        type="text"
        label={FIELDS.address.label}
        name={FIELDS.address.name}
        value={values[FIELDS.address.name as keyof IBillingAddress]}
        errors={errors[FIELDS.address.name as keyof IBillingAddress]}
        onChange={(e) => onChange(FIELDS.address.name)(e.target.value)}
        onBlur={onBlur(FIELDS.address.name)}
        placeholder={FIELDS.address.placeholder}
      />
      <div className={css.twoInRowContainer}>
        <Select
          isDisabled={isDisabled}
          options={AVAILABLE_COUNTRIES_LIST}
          className={css.input}
          label={FIELDS.country.label}
          name={FIELDS.country.name}
          value={
            AVAILABLE_COUNTRIES_LIST.find(
              ({ value }) =>
                value === values[FIELDS.country.name as keyof IBillingAddress],
            ) ?? null
          }
          errors={errors[FIELDS.country.name as keyof IBillingAddress]}
          onChange={(value) =>
            onChange(FIELDS.country.name)(value ? (value as OptionType).value : undefined)
          }
          onBlur={onBlur(FIELDS.country.name)}
          placeholder={FIELDS.country.placeholder}
        />
        <Input
          disabled={isDisabled}
          className={css.input}
          type="text"
          label={FIELDS.city.label}
          name={FIELDS.city.name}
          value={values[FIELDS.city.name as keyof IBillingAddress]}
          errors={errors[FIELDS.city.name as keyof IBillingAddress]}
          onChange={(e) => onChange(FIELDS.city.name)(e.target.value)}
          onBlur={onBlur(FIELDS.city.name)}
          placeholder={FIELDS.city.placeholder}
        />
      </div>
      <div className={css.twoInRowContainer}>
        {[CountriesStatesSelect.CA, CountriesStatesSelect.US].includes(
          values[FIELDS.country.name as keyof IBillingAddress] as CountriesStatesSelect,
        ) ? (
          <Select
            isDisabled={isDisabled}
            options={getStates(values[FIELDS.country.name as keyof IBillingAddress])}
            className={css.input}
            label={FIELDS.state.label}
            name={FIELDS.state.name}
            value={
              getStates(values[FIELDS.country.name as keyof IBillingAddress]).find(
                ({ value }) =>
                  value === values[FIELDS.state.name as keyof IBillingAddress],
              ) ?? null
            }
            errors={errors[FIELDS.state.name as keyof IBillingAddress]}
            onChange={(value) =>
              onChange(FIELDS.state.name)(value ? (value as OptionType).value : undefined)
            }
            onBlur={onBlur(FIELDS.state.name)}
            placeholder={FIELDS.state.placeholder}
          />
        ) : (
          <Input
            disabled={isDisabled}
            type="text"
            className={css.input}
            value={values[FIELDS.state.name as keyof IBillingAddress]}
            errors={errors[FIELDS.state.name as keyof IBillingAddress]}
            onChange={(e) => onChange(FIELDS.state.name)(e.target.value)}
            onBlur={onBlur(FIELDS.state.name)}
            label={FIELDS.state.label}
            name={FIELDS.state.name}
            placeholder={FIELDS.state.placeholder}
          />
        )}
        <Input
          disabled={isDisabled}
          className={css.input}
          type="text"
          value={values[FIELDS.zip.name as keyof IBillingAddress]}
          errors={errors[FIELDS.zip.name as keyof IBillingAddress]}
          onChange={(e) => onChange(FIELDS.zip.name)(e.target.value)}
          onBlur={() => onBlur(FIELDS.zip.name)}
          label={FIELDS.zip.label}
          name={FIELDS.zip.name}
          placeholder={FIELDS.zip.placeholder}
        />
      </div>
    </form>
  );
};
