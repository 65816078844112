import {
  Dialog,
  DialogContentWrapper,
  DialogTitle,
  DialogContent,
  DialogFooter,
  SwagButton,
} from '@ezetech/swag-space-x';
import { EditCustomerPopupProps } from 'interfaces/popup.interface';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { closePopup } from 'redux/slices/modals.slice';
import { restore, submitCustomer } from 'redux/slices/customer-details.slice';
import {
  editedCustomerCompanyDetailsSelector,
  customerEditedProfileDetailsSelector,
} from 'redux/selectors/customer-details.selector';
import { useUpdateUserMutation } from 'redux/api/user.api';
import { EditCustomerForm, CONTENT_ID } from './edit-customer.form';
import css from './edit-customer.popup.module.scss';

export const EditCustomerPopup = ({ isOpen }: EditCustomerPopupProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(customerEditedProfileDetailsSelector);
  const data = useAppSelector(editedCustomerCompanyDetailsSelector);
  const [updateUser] = useUpdateUserMutation();
  const handleOnOpenChange = () => {
    dispatch(closePopup());
    dispatch(restore());
  };

  const handleSubmit = async () => {
    const { payload } = await dispatch(submitCustomer(user));
    const { hasError } = payload as { hasError: boolean };
    if (!hasError && data) {
      updateUser({
        id: data.userId,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phone: data.phone,
        companyName: data.companyName,
        address: data.address,
        city: data.city,
        zip: data.zip,
        country: data.country,
        state: data.state,
      });
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={handleOnOpenChange}>
      <DialogContentWrapper
        className={css.contentWrapper}
        overlayClassName={css.overlay}
        id={CONTENT_ID}
      >
        <DialogTitle>Edit customer details</DialogTitle>
        <DialogContent className={css.content}>
          <EditCustomerForm isOpen={isOpen} />
        </DialogContent>
        <DialogFooter className={css.footer}>
          <SwagButton size="large" type="outlined" onClick={handleOnOpenChange} fullWidth>
            Cancel
          </SwagButton>
          <SwagButton fullWidth size="large" onClick={handleSubmit} type="primary">
            Continue
          </SwagButton>
        </DialogFooter>
      </DialogContentWrapper>
    </Dialog>
  );
};
