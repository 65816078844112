import { RainbowInformation } from 'components/rainbow-information';

export function InvitationInactive() {
  return (
    <RainbowInformation
      tagline="Uh oh!"
      title="This invitation link is no longer active. Please contact the admin to send a new one."
      description=""
    />
  );
}
