export enum StorageKeys {
  csrf = 'csrf',
}

declare global {
  interface Window {
    csrf?: string;
  }
}

export const getItem = (name: StorageKeys): string => {
  return window[name] || '';
};

export const setItem = (name: StorageKeys, value: string): void => {
  window[name] = value;
};
