import { createSelector } from 'reselect';
import dateFormat from 'dateformat';

import {
  checkIsItemCancelled,
  getRelatedItems,
  isBox,
  isInventoryOrder,
} from 'utils/orders.util';
import {
  IItem,
  IOrder,
  IProductionMockup,
  ORDER_PAYMENT_STATUS_CODE,
} from 'interfaces/orders.interface';
import { getTrackingNumberFromLink } from 'utils/tracking-number.utils';
import { PAYMENT_METHODS as PaymentMethods } from 'interfaces/customer-details.interface';
import { ORDER_ITEMS_TYPES, PAYMENT_METHODS } from 'enums/orders.enum';
import { Price } from 'utils/price.util';
import { PRODUCTION_MOCKUP_STATUSES } from 'constants/item.constants';
import { FREE } from 'constants/common';
import { API_URL } from 'redux/api/helpers';
import { PopupListNames } from 'interfaces/popup.interface';
import { POPUP_LIST_NAMES } from 'components/popups/_logic/popups-list';
import { ITEM_STATUS_CODE_LABEL } from 'constants/item.constants';
import { can, SUBJECTS } from 'boot/ability';
import { RootState } from '../store';
import {
  isReviewPantonesByCustomerAllowedSelector,
  isReviewMockupsByCustomerAllowedSelector,
} from './settings.selectors';

export const ordersSelector = (state: RootState) => state.orders;

export const ordersListSelector = createSelector(ordersSelector, (state) => state.list);

export const ordersIdsListSelector = createSelector(ordersListSelector, (orders) => {
  return orders.map(({ id }) => id);
});

export const ordersPublicIdsListSelector = createSelector(
  ordersListSelector,
  (orders) => {
    return orders.map(({ publicId }) => publicId);
  },
);

export const orderByIdSelector = createSelector(
  ordersListSelector,
  (_: RootState, { orderId }: { orderId: string }) => orderId,
  (orders, orderId) => {
    return orders.find(({ id }) => id === orderId) as unknown as IOrder;
  },
);

export const orderItemsSelector = createSelector(orderByIdSelector, (order) => {
  return order?.items || [];
});

export const orderItemsByOrderIdSelector = (orderId: string) => {
  return (state: RootState) => {
    const { items } = orderByIdSelector(state, {
      orderId,
    });

    return items.filter((item) => !checkIsItemCancelled(item));
  };
};

export const itemByIdSelector = createSelector(
  orderItemsSelector,
  (_: RootState, { itemId }: { itemId: string }) => itemId,
  (items, itemId) => {
    return items.find((item) => item.id === itemId) as unknown as IItem;
  },
);

export const boxProductsSelector = createSelector(
  orderItemsSelector,
  (_: RootState, { boxItemsId }: { boxItemsId: string }) => boxItemsId,
  (items, boxItemsId) => {
    const boxItems = items.filter(
      (item) =>
        item.productType !== ORDER_ITEMS_TYPES.BOX && item.boxItemsId === boxItemsId,
    );

    return [
      ...boxItems.filter((i) => i.productType === ORDER_ITEMS_TYPES.REGULAR),
      ...boxItems.filter((i) => i.productType === ORDER_ITEMS_TYPES.SPECIAL_BOX_PRODUCT),
    ];
  },
);

export const boxItemsSelector = createSelector(
  orderItemsSelector,
  (_: RootState, { boxItemsId }: { boxItemsId: string }) => boxItemsId,
  (items, boxItemsId) => {
    return items.filter((i) => i.boxItemsId === boxItemsId);
  },
);

export const boxItemsShippingSelector = createSelector(boxItemsSelector, (items) => {
  return items.find((i) => i.productType === ORDER_ITEMS_TYPES.BOX)?.shipping ?? 0;
});

export const boxItemsTotalSelector = createSelector(boxItemsSelector, (items) => {
  return items.reduce((acc, i) => acc + i.itemTotalPrice, 0);
});

export const nonInventoryOrderItemsIdsSelector = createSelector(
  orderByIdSelector,
  (order) => {
    if (isInventoryOrder(order)) {
      return { defaultItems: [], boxItems: [], relatedItems: [] };
    }

    const items = order.items;

    const relatedItemsIdsArray = getRelatedItems(items);
    const boxItemsIds = items.reduce((acc: string[], item) => {
      if (isBox(item.productType)) {
        acc.push(item.boxItemsId);
      }
      return acc;
    }, []);

    return items.reduce(
      (
        acc: { defaultItems: string[]; boxItems: string[]; relatedItems: string[] },
        item,
      ) => {
        const isBoxItself = isBox(item.productType);
        const isInBox =
          !isBoxItself &&
          boxItemsIds.length &&
          boxItemsIds.some((boxItemsId) => boxItemsId === item.boxItemsId);

        const isRelatedItem =
          relatedItemsIdsArray &&
          relatedItemsIdsArray.some((relItemId: string) => relItemId === item.id);

        const isDefaultItem = !isBoxItself && !isInBox && !isRelatedItem;

        if (isBoxItself) {
          acc.boxItems.push(item.id);
        }

        if (isRelatedItem) {
          acc.relatedItems.push(item.id);
        }

        if (isDefaultItem) {
          acc.defaultItems.push(item.id);
        }

        return acc;
      },
      { defaultItems: [], boxItems: [], relatedItems: [] },
    );
  },
);

export const inventoryOrderItemsIdsSelector = createSelector(
  orderByIdSelector,
  (order) => {
    if (!isInventoryOrder(order)) {
      return {};
    }

    const items = order.items;

    const relatedItemsIdsArray = getRelatedItems(items);
    const boxItemsIds = items.reduce((acc: string[], item) => {
      if (isBox(item.productType)) {
        acc.push(item.boxItemsId);
      }
      return acc;
    }, []);

    return items.reduce(
      (
        acc: {
          [key: string]: {
            defaultItems: string[];
            boxItems: string[];
            relatedItems: string[];
          };
        },
        item,
      ) => {
        const inventoryName = item.inventoryName;

        const isBoxItself = isBox(item.productType);
        const isInBox =
          !isBoxItself &&
          boxItemsIds.length &&
          boxItemsIds.some((boxItemsId) => boxItemsId === item.boxItemsId);

        const isRelatedItem =
          relatedItemsIdsArray &&
          relatedItemsIdsArray.some((relItemId: string) => relItemId === item.id);

        const isDefaultItem = !isBoxItself && !isInBox && !isRelatedItem;

        if (!acc[inventoryName]) {
          acc[inventoryName] = { boxItems: [], relatedItems: [], defaultItems: [] };
        }

        if (isBoxItself) {
          acc[inventoryName].boxItems.push(item.id);
        }

        if (isRelatedItem) {
          acc[inventoryName].relatedItems.push(item.id);
        }

        if (isDefaultItem) {
          acc[inventoryName].defaultItems.push(item.id);
        }

        return acc;
      },
      {},
    );
  },
);

export const relatedItemsSelector = createSelector(
  orderItemsSelector,
  (_: RootState, { relatedItemsIds }: { relatedItemsIds: string[] }) => relatedItemsIds,
  (items, relatedItemsIds) => {
    return items.filter((i) => relatedItemsIds.includes(i.id));
  },
);

export const relatedItemsTotalSelector = createSelector(
  relatedItemsSelector,
  (relatedItems) => {
    return relatedItems.reduce((acc, i) => acc + i.itemTotalPrice, 0);
  },
);

export const relatedItemsSetupFeeSelector = createSelector(
  relatedItemsSelector,
  (relatedItems) => {
    return Math.max(...relatedItems.map((i) => i.setupFee));
  },
);

export const isRelatedItemSelector = createSelector(
  orderItemsSelector,
  (
    _: RootState,
    { asRelatedItemId, itemId }: { asRelatedItemId: string; itemId: string },
  ) => ({ asRelatedItemId, itemId }),
  (items, { asRelatedItemId, itemId }) => {
    return items.some(
      (item) => item.id !== itemId && item.asRelatedItemId === asRelatedItemId,
    );
  },
);

export const isAllItemsRejectedSelector = createSelector(orderItemsSelector, (items) => {
  return items.every(checkIsItemCancelled);
});

export const isDummyPaymentSelector = createSelector(orderByIdSelector, (order) => {
  return order.paymentMethod === PAYMENT_METHODS.DUMMY;
});

export const isAdjustmentPriceTooltipShownSelector = createSelector(
  orderByIdSelector,
  isDummyPaymentSelector,
  (order, isDummy) => {
    return isDummy && order.items.some(({ num }) => num !== order.num);
  },
);

export const orderCardBoxItemWrapperSelector =
  ({ orderId, itemId }: { orderId: string; itemId: string }) =>
  (state: RootState) => {
    const box = itemByIdSelector(state, { orderId, itemId });
    const boxProducts = boxProductsSelector(state, {
      orderId,
      boxItemsId: box.boxItemsId,
    });
    const boxShipping = boxItemsShippingSelector(state, {
      orderId,
      boxItemsId: box.boxItemsId,
    });
    const boxTotal = boxItemsTotalSelector(state, {
      orderId,
      boxItemsId: box.boxItemsId,
    });

    return {
      boxName: box.name,
      boxProducts,
      boxShipping,
      boxTotal,
    };
  };

export const orderCardHeaderSelector =
  ({ orderId }: { orderId: string }) =>
  (state: RootState) => {
    const { firstName, lastName, num, paidAt, email, userId, assignee } =
      orderByIdSelector(state, {
        orderId,
      });

    const hasOrderHolder = firstName && lastName;
    const fullName = `${firstName} ${lastName}`;
    const confirmedAt = dateFormat(paidAt, 'mmm d, yyyy');

    return {
      num,
      confirmedAt,
      email,
      hasOrderHolder,
      fullName,
      userId,
      assignee,
    };
  };

export const orderCardItemComponentSelector =
  ({ orderId, itemId }: { orderId: string; itemId: string }) =>
  (state: RootState) => {
    const isDummy = isDummyPaymentSelector(state, { orderId });
    const { imageUrl, name, quantity, publicId, productType } = itemByIdSelector(state, {
      orderId,
      itemId,
    });

    return {
      productType,
      isDummy,
      imageUrl,
      name,
      publicId,
      showQuantity: Boolean(quantity),
    };
  };

export const quantityComponentSelector =
  ({ orderId, itemId }: { orderId: string; itemId: string }) =>
  (state: RootState) => {
    const item = itemByIdSelector(state, { orderId, itemId });

    const qty = item.quantity;
    const sizes = JSON.parse(item.sizes ?? '[]');
    const sizesLength = sizes.length;
    const showSize = sizesLength > 0;
    const hasMultipleSizes = sizesLength > 1;
    const sizeValue = sizes[0];
    const tooltipData = item.variants.map(({ color, quantities }) => ({
      color,
      quantitiesData: quantities.map((quantity, i) => ({
        size: sizes[i],
        quantity,
      })),
    }));

    return {
      qty,
      sizesLength,
      showSize,
      hasMultipleSizes,
      sizeValue,
      tooltipData,
    };
  };

export const priceBlockComponentSelector =
  ({ orderId, itemId }: { orderId: string; itemId: string }) =>
  (state: RootState) => {
    const item = itemByIdSelector(state, { orderId, itemId });
    const isRelatedItem = isRelatedItemSelector(state, {
      orderId,
      itemId,
      asRelatedItemId: item.asRelatedItemId,
    });

    const pricePerItem = item.pricePerItem ? Price.formatPrice(item.pricePerItem) : FREE;
    const showSetupFee = Boolean(item.setupFee);
    const setupFee = Price.formatPrice(item.setupFee);
    const shipping = Price.formatPrice(item.shipping);
    const kittingFee = item.kittingFee ? Price.formatPrice(item.kittingFee) : FREE;
    const showShipping = Boolean(item.shipping) && !item.boxItemsId;

    return {
      pricePerItem,
      setupFee,
      shipping,
      kittingFee,
      showShipping,
      showSetupFee,
      isRelatedItem,
    };
  };

export const statusComponentSelector =
  ({ orderId, itemId }: { orderId: string; itemId: string }) =>
  (state: RootState) => {
    const { status, statusCode, trackingNumberLink, productionMockupLink } =
      itemByIdSelector(state, {
        orderId,
        itemId,
      });

    const trackingNumber = getTrackingNumberFromLink(trackingNumberLink);

    return {
      status,
      statusCode,
      trackingNumber,
      trackingNumberLink,
      productionMockupLink,
    };
  };

export const sentToComponentSelector =
  ({ orderId, itemId }: { orderId: string; itemId: string }) =>
  (state: RootState) => {
    const {
      email,
      shippingAddress: {
        firstName,
        lastName,
        address,
        country,
        state: countryState,
        city,
        zip,
      },
    } = orderByIdSelector(state, { orderId });
    const { inventoryName } = itemByIdSelector(state, {
      orderId,
      itemId,
    });

    const fullName = `${firstName} ${lastName}`;
    const fullShippingAddress = `${address}, ${country}, ${countryState}, ${city}, ${zip}`;

    return { inventoryName, email, fullName, fullShippingAddress };
  };

export const inHandDateComponentSelector =
  ({ orderId, itemId }: { orderId: string; itemId: string }) =>
  (state: RootState) => {
    const {
      inventoryPublicId,
      estInHand: { from, to },
    } = itemByIdSelector(state, {
      orderId,
      itemId,
    });
    const estInHand =
      from === to
        ? `${dateFormat(from, 'dd mmm')}`
        : `${dateFormat(from, 'dd mmm')} - ${dateFormat(to, 'dd mmm')}`;

    const label = inventoryPublicId ? 'Available for distribution:' : 'Est in hand:';

    return { estInHand, label };
  };

export const paymentComponentSelector =
  ({ orderId }: { orderId: string }) =>
  (state: RootState) => {
    const order = orderByIdSelector(state, { orderId });

    return { paymentMethod: order.paymentMethod || '' };
  };

export const totalPriceComponentSelector =
  ({ orderId, itemId }: { orderId: string; itemId: string }) =>
  (state: RootState) => {
    const { itemTotalPrice, productType, boxItemsId, shipping } = itemByIdSelector(
      state,
      {
        orderId,
        itemId,
      },
    );
    if (boxItemsId && productType === ORDER_ITEMS_TYPES.BOX) {
      const boxTotalPriceWithoutShipping = itemTotalPrice - shipping;
      const totalPrice = Price.formatPrice(boxTotalPriceWithoutShipping) ?? FREE;
      return { totalPrice };
    }
    const totalPrice = itemTotalPrice ? Price.formatPrice(itemTotalPrice) : FREE;

    return { totalPrice };
  };

export const orderCardFooterComponentSelector =
  ({ orderId }: { orderId: string }) =>
  (state: RootState) => {
    const order = orderByIdSelector(state, {
      orderId,
    });

    const {
      invoiceUrl,
      paymentPrices: { shipment, taxes, grandTotal },
      discounts: { sampleDeduction, promocode, shipment: shippingDiscount, setupFee },
      paymentStatusCode,
      paymentMethod,
    } = order;

    const isAllItemsRejected = isAllItemsRejectedSelector(state, { orderId });
    const isAdjustmentPriceTooltipShown = isAdjustmentPriceTooltipShownSelector(state, {
      orderId,
    });

    const isAbleToRetryPayment: boolean = can(
      SUBJECTS.VIEW_ORDERS.actions.ACH_NET_RETRY_PAYMENT,
      SUBJECTS.VIEW_ORDERS.value,
    );

    const isOrderCanceled = order.items.every((item) => checkIsItemCancelled(item));
    const showInvoiceLink = Boolean(invoiceUrl);
    const invoiceLink = `${API_URL}/order/invoice/${orderId}`;
    const showSampleDeductionDiscount = Boolean(sampleDeduction);
    const sampleDeductionDiscount = `-${Price.formatPrice(sampleDeduction)}`;
    const showDiscount = Boolean(promocode);
    const discount = `-${Price.formatPrice(promocode)}`;
    const showShipmentDiscount = Boolean(shippingDiscount);
    const shipmentDiscount = `-${Price.formatPrice(shippingDiscount)}`;
    const showSetupFeeDiscount = Boolean(setupFee);
    const setupFeeDiscount = `-${Price.formatPrice(setupFee)}`;
    const showTaxesPrice = Boolean(taxes);
    const taxesPrice = Price.formatPrice(taxes);
    const showShipping = Boolean(shipment);
    const shippingPrice = shipment ? Price.formatPrice(shipment) : FREE;
    const orderTotal = grandTotal ? Price.formatPrice(grandTotal) : FREE;
    const showRetryWidget =
      paymentStatusCode === ORDER_PAYMENT_STATUS_CODE.FAILED &&
      !isOrderCanceled &&
      isAbleToRetryPayment;
    const isAchNetTerms = paymentMethod !== PaymentMethods.CREDIT_CARD;
    const showGenerateInvoiceLink = isAchNetTerms && !invoiceUrl;
    const isResellerProfitNegative = (order.resellerProfit || 0) < 0;

    const resellerProfit = `${isResellerProfitNegative ? '-' : ''}${Price.formatPrice(
      Math.abs(order.resellerProfit || 0),
    )}`;

    return {
      showInvoiceLink,
      isAllItemsRejected,
      invoiceLink,
      isAdjustmentPriceTooltipShown,
      showSampleDeductionDiscount,
      sampleDeductionDiscount,
      showDiscount,
      discount,
      showShipmentDiscount,
      shipmentDiscount,
      showSetupFeeDiscount,
      setupFeeDiscount,
      showTaxesPrice,
      taxesPrice,
      showShipping,
      shippingPrice,
      orderTotal,
      showRetryWidget,
      showGenerateInvoiceLink,
      resellerProfit,
      isResellerProfitNegative,
    };
  };

export const orderCardRelatedItemsWrapperComponentSelector =
  ({ orderId, relatedItemsIds }: { orderId: string; relatedItemsIds: string[] }) =>
  (state: RootState) => {
    const relatedItemsTotal = relatedItemsTotalSelector(state, {
      orderId,
      relatedItemsIds,
    });
    const relatedItemsSetupFee = relatedItemsSetupFeeSelector(state, {
      orderId,
      relatedItemsIds,
    });

    return { relatedItemsTotal, relatedItemsSetupFee };
  };

export const orderCardItemsWrapperComponentSelector =
  ({ orderId }: { orderId: string }) =>
  (state: RootState) => {
    const order = orderByIdSelector(state, {
      orderId,
    });
    const itemsIds = nonInventoryOrderItemsIdsSelector(state, { orderId });
    const inventoryItemsIdsGroupByName = inventoryOrderItemsIdsSelector(state, {
      orderId,
    });
    const showInventoryItems = isInventoryOrder(order);

    return { itemsIds, inventoryItemsIdsGroupByName, showInventoryItems };
  };

export const itemDataForProductionMockup =
  ({ orderId, itemId }: { orderId: string; itemId: string }) =>
  (state: RootState) => {
    const item = itemByIdSelector(state, { orderId, itemId });
    const { num } =
      orderByIdSelector(state, {
        orderId,
      }) || '';
    const customerMockups = (item?.pantones || []).map(
      ({ id, location, mockupPreview }) => ({
        id,
        location,
        mockupPreview,
      }),
    );

    if (!customerMockups.length) {
      customerMockups.push({
        mockupPreview: item?.imageUrl,
        location: 'full-design',
        id: 'full-design',
      });
    }

    const pendingProductionMockup = item?.productionMockups?.reduce<{
      id: string;
      status: string;
      fileName: string;
    }>(
      (res, mockup: IProductionMockup) => {
        if (res.id) {
          return res;
        }
        if (mockup.status === PRODUCTION_MOCKUP_STATUSES.PENDING_RESELLER_APPROVAL) {
          return {
            id: mockup.id,
            status: mockup.status,
            fileName: mockup.fileName,
          };
        }
        return res;
      },
      { id: '', status: '', fileName: '' },
    );
    return {
      productName: item?.name,
      orderNum: num,
      productionMockup: pendingProductionMockup ?? [],
      customerMockups,
    };
  };

export const getPopupFromStatus = (status: string): PopupListNames | null => {
  switch (status) {
    case ITEM_STATUS_CODE_LABEL.COLORS_REVIEW_BY_RESELLER:
      return POPUP_LIST_NAMES.REVIEW_COLORS_POPUP as PopupListNames;
    case ITEM_STATUS_CODE_LABEL.MOCKUP_APPROVAL_BY_RESELLER:
      return POPUP_LIST_NAMES.RESELLER_MOCKUP_PRE_APPROVAL as PopupListNames;
    default:
      return null;
  }
};

export const getInfoForReviewColors =
  ({ orderId, itemId }: { orderId: string; itemId: string }) =>
  (state: RootState) => {
    const item = itemByIdSelector(state, {
      orderId,
      itemId,
    });
    return item;
  };

export const orderSendInvoicePopupSelector = (orderId: string) => {
  return (state: RootState) => {
    const order = orderByIdSelector(state, {
      orderId,
    });

    if (!order) {
      return {
        orderTotal: 0,
        taxesTotal: 0,
        discountTotal: 0,
        isTaxExempt: false,
      };
    }

    const {
      paymentPrices: { grandTotal },
      discounts: { shipment, setupFee, promocode, sampleDeduction },
      items,
      isTaxExempt,
    } = order;
    const orderTotal = grandTotal;
    const taxesTotal = items.reduce((total, item) => {
      if (checkIsItemCancelled(item)) {
        return total;
      }

      return total + item.tax;
    }, 0);

    const discountTotal =
      (shipment || 0) + (setupFee || 0) + (promocode || 0) + (sampleDeduction || 0);

    return {
      orderTotal,
      taxesTotal,
      discountTotal,
      isTaxExempt,
    };
  };
};

export const isSpecificReviewPantonesByCustomerAllowedSelector = (
  orderId: string = '',
) => {
  return (state: RootState) => {
    const isReviewPantonesByCustomerAllowed =
      isReviewPantonesByCustomerAllowedSelector(state);
    const { isSpecificReviewPantonesByCustomerAllowed } = orderByIdSelector(state, {
      orderId,
    });

    if (
      isSpecificReviewPantonesByCustomerAllowed === null ||
      isSpecificReviewPantonesByCustomerAllowed === undefined
    ) {
      return isReviewPantonesByCustomerAllowed;
    }

    return isSpecificReviewPantonesByCustomerAllowed;
  };
};

export const isSpecificReviewMockupsByCustomerAllowedSelector = (
  orderId: string = '',
) => {
  return (state: RootState) => {
    const isReviewMockupsByCustomerAllowed =
      isReviewMockupsByCustomerAllowedSelector(state);
    const { isSpecificReviewMockupsByCustomerAllowed } = orderByIdSelector(state, {
      orderId,
    });

    if (
      isSpecificReviewMockupsByCustomerAllowed === null ||
      isSpecificReviewMockupsByCustomerAllowed === undefined
    ) {
      return isReviewMockupsByCustomerAllowed;
    }

    return isSpecificReviewMockupsByCustomerAllowed;
  };
};
