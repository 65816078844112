import { createSelector } from 'reselect';
import { can } from 'boot/ability';
import { SUBJECTS } from 'constants/ability.constant';
import { RootState } from '../store';
import { userIdSelector } from './user.selectors';

export const rootSelector = (state: RootState) => state.assignee;

export const usersAllowedForOrdersAssignmentSelector = createSelector(
  rootSelector,
  (state) => state.listForOrders ?? [],
);

export const assigneeForOrdersByIdSelector = createSelector(
  usersAllowedForOrdersAssignmentSelector,
  (_: RootState, { userId }: { userId: string | null }) => userId,
  (users, userId) => {
    const userProfile = users.find((user) => user.id === userId);
    return userProfile
      ? {
          id: userProfile.id,
          firstName: userProfile.firstName,
          lastName: userProfile.lastName,
          email: userProfile.email,
        }
      : null;
  },
);

export const usersAllowedForInvoicesAssignmentSelector = createSelector(
  rootSelector,
  (state) => state.listForInvoices ?? [],
);

export const invoicesAssignToOptionsSelector = createSelector(
  usersAllowedForInvoicesAssignmentSelector,
  userIdSelector,
  (usersForAssignment, userId) => {
    const isAbleToAssignInvoices = can(
      SUBJECTS.TEAM_MEMBER_INVOICE_ASSIGNMENT.actions.ASSIGN_TEAM_MEMBER_TO_INVOICE,
      SUBJECTS.TEAM_MEMBER_INVOICE_ASSIGNMENT.value,
    );

    const isAbleToBeAssignedForInvoice = can(
      SUBJECTS.CREATE_EDIT_INVOICE.actions.ASSIGN_INVOICE,
      SUBJECTS.CREATE_EDIT_INVOICE.value,
    );

    const fullOptionsList = usersForAssignment.map(({ id, firstName, lastName }) => ({
      value: id,
      label: `${firstName} ${lastName}`,
    }));

    if (isAbleToAssignInvoices) {
      // can assign any user or unassign
      return [{ value: '', label: 'Unassign' }, ...fullOptionsList];
    }

    if (isAbleToBeAssignedForInvoice) {
      // can assign only myself
      return fullOptionsList.filter((item) => item.value === userId);
    }
    // can not assign anybody
    return [];
  },
);

export const assigneeForInvoiceByIdSelector = createSelector(
  usersAllowedForInvoicesAssignmentSelector,
  (_: RootState, { userId }: { userId: string | null }) => userId,
  (users, userId) => {
    const userProfile = users.find((user) => user.id === userId);
    return userProfile
      ? {
          id: userProfile.id,
          firstName: userProfile.firstName,
          lastName: userProfile.lastName,
          email: userProfile.email,
        }
      : null;
  },
);

export const usersAllowedForPresentationsAssignmentSelector = createSelector(
  rootSelector,
  (state) => state.listForPresentations ?? [],
);

export const presentationsAssignToOptionsSelector = createSelector(
  usersAllowedForPresentationsAssignmentSelector,
  userIdSelector,
  (usersForAssignment, userId) => {
    const isAbleToAssignPresentation = can(
      SUBJECTS.TEAM_MEMBER_PDF_PRESENTATION_ASSIGNMENT.actions
        .ASSIGN_TEAM_MEMBER_TO_PDF_PRESENTATION,
      SUBJECTS.TEAM_MEMBER_PDF_PRESENTATION_ASSIGNMENT.value,
    );

    const isAbleToBeAssignedForPresentation = can(
      SUBJECTS.CREATE_EDIT_PDF_PRESENTATION.actions.ASSIGN_PDF_PRESENTATION,
      SUBJECTS.CREATE_EDIT_PDF_PRESENTATION.value,
    );

    const fullOptionsList = usersForAssignment.map(({ id, firstName, lastName }) => ({
      value: id,
      label: `${firstName} ${lastName}`,
    }));

    if (isAbleToAssignPresentation) {
      // can assign any user or unassign
      return [{ value: '', label: 'Unassign' }, ...fullOptionsList];
    }

    if (isAbleToBeAssignedForPresentation) {
      // can assign only myself
      return fullOptionsList.filter((item) => item.value === userId);
    }
    // can not assign anybody
    return [];
  },
);

export const assigneeForPresentationByIdSelector = createSelector(
  usersAllowedForPresentationsAssignmentSelector,
  (_: RootState, { userId }: { userId: string | null }) => userId,
  (users, userId) => {
    const userProfile = users.find((user) => user.id === userId);
    return userProfile
      ? {
          id: userProfile.id,
          firstName: userProfile.firstName,
          lastName: userProfile.lastName,
          email: userProfile.email,
        }
      : null;
  },
);

export const usersAllowedForCustomersAssignmentSelector = createSelector(
  rootSelector,
  (state) => state.listForCustomers ?? [],
);

export const customersAssignToOptionsSelector = createSelector(
  usersAllowedForCustomersAssignmentSelector,
  userIdSelector,
  (usersForAssignment, userId) => {
    const isAbleToAssignCustomers = can(
      SUBJECTS.TEAM_MEMBER_CUSTOMER_ASSIGNMENT.actions.ASSIGN_TEAM_MEMBER_TO_CUSTOMER,
      SUBJECTS.TEAM_MEMBER_CUSTOMER_ASSIGNMENT.value,
    );

    const isAbleToBeAssignedForCustomer = can(
      SUBJECTS.EDIT_CUSTOMERS_DETAILS.actions.ASSIGN_CUSTOMERS,
      SUBJECTS.EDIT_CUSTOMERS_DETAILS.value,
    );

    const fullOptionsList = usersForAssignment.map(({ id, firstName, lastName }) => ({
      value: id,
      label: `${firstName} ${lastName}`,
    }));

    if (isAbleToAssignCustomers) {
      return [{ value: '', label: 'Unassign' }, ...fullOptionsList];
    }

    if (isAbleToBeAssignedForCustomer) {
      return fullOptionsList.filter((item) => item.value === userId);
    }

    return [];
  },
);

export const assigneeForCustomerByIdSelector = createSelector(
  usersAllowedForCustomersAssignmentSelector,
  (_: RootState, { userId }: { userId: string | null }) => userId,
  (users, userId) => {
    const userProfile = users.find((user) => user.id === userId);
    return userProfile
      ? {
          id: userProfile.id,
          firstName: userProfile.firstName,
          lastName: userProfile.lastName,
          email: userProfile.email,
        }
      : null;
  },
);

export const usersAllowedForLeadsAssignmentSelector = createSelector(
  rootSelector,
  (state) => state.listForLeads ?? [],
);

export const leadsAssignToOptionsSelector = createSelector(
  usersAllowedForLeadsAssignmentSelector,
  userIdSelector,
  (usersForAssignment, userId) => {
    const isAbleToAssignLeads = can(
      SUBJECTS.TEAM_MEMBER_LEAD_ASSIGNMENT.actions.ASSIGN_TEAM_MEMBER_TO_LEAD,
      SUBJECTS.TEAM_MEMBER_LEAD_ASSIGNMENT.value,
    );

    const isAbleToBeAssignedForLeads = can(
      SUBJECTS.LEADS.actions.ASSIGN_LEADS,
      SUBJECTS.LEADS.value,
    );

    const fullOptionsList = usersForAssignment.map(({ id, firstName, lastName }) => ({
      value: id,
      label: `${firstName} ${lastName}`,
    }));

    if (isAbleToAssignLeads) {
      return [{ value: '', label: 'Unassign' }, ...fullOptionsList];
    }

    if (isAbleToBeAssignedForLeads) {
      return fullOptionsList.filter((item) => item.value === userId);
    }

    return [];
  },
);

export const assigneeForLeadByIdSelector = createSelector(
  usersAllowedForLeadsAssignmentSelector,
  (_: RootState, { userId }: { userId: string | null }) => userId,
  (users, userId) => {
    const userProfile = users.find((user) => user.id === userId);
    return userProfile
      ? {
          id: userProfile.id,
          firstName: userProfile.firstName,
          lastName: userProfile.lastName,
          email: userProfile.email,
        }
      : null;
  },
);

export const usersAllowedForCartsAssignmentSelector = createSelector(
  rootSelector,
  (state) => state.listForCarts ?? [],
);

export const cartsAssignToOptionsSelector = createSelector(
  usersAllowedForCartsAssignmentSelector,
  userIdSelector,
  (usersForAssignment, userId) => {
    const isAbleToAssignCart = can(
      SUBJECTS.TEAM_MEMBER_BUILD_A_CART_ASSIGNMENT.actions
        .ASSIGN_TEAM_MEMBER_TO_BUILD_A_CART,
      SUBJECTS.TEAM_MEMBER_BUILD_A_CART_ASSIGNMENT.value,
    );

    const isAbleToBeAssignedForCart = can(
      SUBJECTS.CREATE_EDIT_BUILD_A_CART.actions.ASSIGN_BUILD_A_CART,
      SUBJECTS.CREATE_EDIT_BUILD_A_CART.value,
    );

    const fullOptionsList = usersForAssignment.map(({ id, firstName, lastName }) => ({
      value: id,
      label: `${firstName} ${lastName}`,
    }));

    if (isAbleToAssignCart) {
      // can assign any user or unassign
      return [{ value: '', label: 'Unassign' }, ...fullOptionsList];
    }

    if (isAbleToBeAssignedForCart) {
      // can assign only myself
      return fullOptionsList.filter((item) => item.value === userId);
    }
    // can not assign anybody
    return [];
  },
);

export const assigneeForCartByIdSelector = createSelector(
  usersAllowedForCartsAssignmentSelector,
  (_: RootState, { userId }: { userId: string | null }) => userId,
  (users, userId) => {
    const userProfile = users.find((user) => user.id === userId);
    return userProfile
      ? {
          id: userProfile.id,
          firstName: userProfile.firstName,
          lastName: userProfile.lastName,
          email: userProfile.email,
        }
      : null;
  },
);

export const usersAllowedForAbandonedCheckoutsAssignmentSelector = createSelector(
  rootSelector,
  (state) => state.listForAbandonedCheckouts ?? [],
);

export const abandonedCheckoutsAssignToOptionsSelector = createSelector(
  usersAllowedForAbandonedCheckoutsAssignmentSelector,
  userIdSelector,
  (usersForAssignment, userId) => {
    const isAbleToAssignAbandonedOrder = can(
      SUBJECTS.TEAM_MEMBER_ABANDONED_ORDER_ASSIGNMENT.actions
        .ASSIGN_TEAM_MEMBER_TO_ABANDONED_ORDER,
      SUBJECTS.TEAM_MEMBER_ABANDONED_ORDER_ASSIGNMENT.value,
    );

    const isAbleToBeAssignedForAbandonedOrder = can(
      SUBJECTS.ABANDONED_CHECKOUTS.actions.ASSIGN_ABANDONED_ORDERS,
      SUBJECTS.ABANDONED_CHECKOUTS.value,
    );

    const fullOptionsList = usersForAssignment.map(({ id, firstName, lastName }) => ({
      value: id,
      label: `${firstName} ${lastName}`,
    }));

    if (isAbleToAssignAbandonedOrder) {
      // can assign any user or unassign
      return [{ value: '', label: 'Unassign' }, ...fullOptionsList];
    }

    if (isAbleToBeAssignedForAbandonedOrder) {
      // can assign only myself
      return fullOptionsList.filter((item) => item.value === userId);
    }
    // can not assign anybody
    return [];
  },
);

export const assigneeForAbandonedCheckoutByIdSelector = createSelector(
  usersAllowedForAbandonedCheckoutsAssignmentSelector,
  (_: RootState, { userId }: { userId: string | null }) => userId,
  (users, userId) => {
    const userProfile = users.find((user) => user.id === userId);
    return userProfile
      ? {
          id: userProfile.id,
          firstName: userProfile.firstName,
          lastName: userProfile.lastName,
          email: userProfile.email,
        }
      : null;
  },
);
