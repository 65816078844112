import { FC } from 'react';
import { Typography } from '@ezetech/swag-space-x';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import css from '../sign-contract.module.scss';
import { makeBold } from '../sign-contract.util';

export const Fees: FC = () => {
  return (
    <>
      <Typography color={COLORS.colorPrimaryText} fontType="bodyMdBold">
        7. Fees, Payments and Taxes
      </Typography>
      <br />
      <br />
      <ol className={css.listLatin}>
        <li className={css.listItem}>
          {makeBold('Payments; Retail Prices; Promotional Discounts.')} You will be paid a
          markup (each, a {makeBold('“Payment”')} and collectively,{' '}
          {makeBold('“Payments”')}) for all Customized Products you sell through your
          Branded Storefront or using the Services. We provide the base price for each
          Customized Product ({makeBold('“Base Price”')}), and you will choose a markup
          and whether to offer any promotional discount. The {makeBold('“Retail Price”')}{' '}
          is the price at which each Customized Product is listed or offered for sale to
          your customers, and the Retail Price equals the sum of the Base Price plus your
          chosen markup less any discounts applied (as offered by you, in your sole
          discretion). The amount or percentage off for any promotional discount is
          entirely in your discretion, however, should any promotional discount, once
          applied, result in the markup or Payment being less than such promotional
          discount, you will owe Swag Space the difference. Such difference may be
          deducted by Swag Space from Payments otherwise due to you or charged to any
          credit card you may have on file with Swag Space.
        </li>
        <br />
        <li className={css.listItem}>
          {makeBold('Branded Storefront Orders – Payments.')} With respect to Branded
          Storefront Orders, after Swag Space collects the amounts due (based on the
          Retail Prices) for purchased Customized Products, Swag Space provides Payments
          (if any) to you after an order goes to production.
        </li>
        <br />
        <li className={css.listItem}>
          {makeBold('Offline Orders Using the Services – Payments.')} With respect to
          Offline Orders, you will collect the amounts due (based on the Retail Prices)
          for purchased Customized Products directly from your customers, and Swag Space
          will bill you the aggregate Base Prices due to Swag Space. Note that Swag Space
          will bill and receive payment from you prior to an order going to print. Your
          payment terms with your customers will be your responsibility in this scenario.
        </li>
        <br />
        <li className={css.listItem}>
          {makeBold('Credits.')} Through your Branded Storefront, you may offer your
          customers the ability to purchase credits redeemable for Customized Products (
          {makeBold('“Credits”')}). Credits may only be redeemed via your Branded
          Storefront. All Credits are valid for so long as your customer retains an active
          account. Credits expire immediately upon the cancellation or termination of your
          customer’s account unless used prior to cancellation or termination. Your
          customer may request a cash out of your customer’s Credits balance at any time,
          and after receiving such request, Swag Space will remit a cash amount to your
          customer equal to the amount of your customer’s Credits being cashed out.
        </li>
        <br />
        <li className={css.listItem}>
          {makeBold('Changes to Fees.')} Changes to fees are effective after we post
          notice of the changes on the Site. Any standard credit card or other third-party
          processing fees are included in the fees charged by Swag Space. Swag Space is
          not responsible for the performance of any third-party credit card processing or
          third-party payment services.
        </li>
        <br />
        <li className={css.listItem}>
          {makeBold('Your Taxes.')} You are responsible for paying all taxes applicable to
          you as a reseller (i.e., income taxes) associated with your use of the Services.
          It is your obligation to maintain any and all records that may be required for
          your tax purposes. We are not responsible for, and may delete, information
          associated with your account that is more than three years old. We do not and
          cannot give any tax advice. You agree to consult your own tax advisor and to
          take all steps necessary to comply with your own tax obligations as a Reseller
          using the Services, including with respect to Offline Orders.
        </li>
      </ol>
      <br />
    </>
  );
};
