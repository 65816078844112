import { SwagButton } from '@ezetech/swag-space-x';
import { ColoredContainerHeader } from 'components/colored-container-header';
import { LANDING_URL } from 'utils/urls.util';

import css from './review.module.scss';

type ReviewProps = {
  onClickBack?: () => void;
};

export function Review({ onClickBack }: ReviewProps) {
  const onClick = () => {
    if (onClickBack) {
      onClickBack();
    } else if (LANDING_URL) {
      window.location.assign(LANDING_URL);
    }
  };
  return (
    <div className={css.review}>
      <div>
        <ColoredContainerHeader
          tagline="We are not quite finished yet"
          title="Your application is still under review"
          description="Our team is still reviewing your application."
        />
      </div>
      <div>
        <SwagButton type="primary" size="xl" fullWidth onClick={onClick}>
          Back to Swag Space
        </SwagButton>
      </div>
    </div>
  );
}
