import { IUser } from './user-api.interface';

export type IGetAssigneeListForOrdersResponse = IUser[];
export type IGetAssigneeListForCustomersResponse = IUser[];
export type IGetAssigneeListForInvoicesResponse = IUser[];
export type IGetAssigneeListForLeadsResponse = IUser[];
export type IGetAssigneeListForCartsResponse = IUser[];
export type IGetAssigneeListForAbandonedOrdersResponse = IUser[];

export interface IAssignedForReseller
  extends Pick<IUser, 'id' | 'firstName' | 'lastName' | 'email'> {}

export interface IAssignedFor {
  assignee?:
    | {
        reseller: IAssignedForReseller;
      }[]
    | null;
}

export const AssignmentEntity = {
  CUSTOMER: 'CUSTOMER',
  LEAD: 'LEAD',
  PDF: 'PDF',
  CART_LINK: 'CART_LINK',
  INVOICE: 'INVOICE',
  ORDER: 'ORDER',
  ABANDONED_CHECKOUT: 'ABANDONED_CHECKOUT',
};

export type AssignmentEntityType =
  (typeof AssignmentEntity)[keyof typeof AssignmentEntity];

export interface IAssignEntityToUserRequest {
  entityId: string;
  userId: string | null;
}

export enum ASSIGNMENT_FLOW_PERMISSIONS {
  ORDER_ASSIGNMENT = 'ORDER_ASSIGNMENT',
  CREATE_EDIT_INVOICE = 'CREATE_EDIT_INVOICE',
  CREATE_EDIT_PDF_PRESENTATION = 'CREATE_EDIT_PDF_PRESENTATION',
  CREATE_EDIT_BUILD_A_CART = 'CREATE_EDIT_BUILD_A_CART',
  EDIT_CUSTOMERS_DETAILS = 'EDIT_CUSTOMERS_DETAILS',
  ABANDONED_CHECKOUTS = 'ABANDONED_CHECKOUTS',
}
