import { Typography, WhiteBox } from '@ezetech/swag-space-x';

import COLORS from 'constants/styles/colors-js.constant.module.scss';

import { Separator } from '../separator';
import { DetailsCompany } from '../details-company';
import { DetailsPaymentTerms } from '../details-payment-terms';
import { ItemTimelineSettings } from '../item-timeline-settings';
import { DetailsType } from '../details-type';
import css from './details.module.scss';

export function Details(): JSX.Element {
  return (
    <WhiteBox className={css.container}>
      <div className={css.header}>
        <Typography
          className={css.title}
          lineHeight="162%"
          fontType="bodyMdBold"
          color={COLORS.colorTextSubdued}
        >
          Customer details
        </Typography>
      </div>
      <Separator />
      <DetailsCompany />
      <Separator />
      <ItemTimelineSettings />
      <Separator />
      <DetailsPaymentTerms />
      <Separator />
      <DetailsType />
    </WhiteBox>
  );
}
