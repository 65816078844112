import * as yup from 'yup';
import { ERROR_MSG, alphanumericRegexp } from 'constants/validation';
import {
  FIELDS,
  PromocodeMeasurementEnum,
  PromocodeTypeEnum,
  IS_FREE_SETUP_FEE,
  IS_FREE_SHIPPING,
} from 'constants/promocodes.constants';

const MAX_PROMOCODE_NAME_LENGTH = 20;

export const promoCodeFormSchema = yup.object({
  [FIELDS.name.name]: yup
    .string()
    .trim()
    .matches(alphanumericRegexp, { message: 'Invalid format.' })
    .max(MAX_PROMOCODE_NAME_LENGTH, `${MAX_PROMOCODE_NAME_LENGTH} characters maximum`)
    .required(ERROR_MSG.required),
  [FIELDS.measurement.name]: yup
    .string()
    .oneOf(Object.values(PromocodeMeasurementEnum))
    .required(ERROR_MSG.required),
  [FIELDS.type.name]: yup
    .string()
    .oneOf(Object.values(PromocodeTypeEnum))
    .required(ERROR_MSG.required),
  [FIELDS.discountAbsolute.name]: yup
    .number()
    .min(0)
    .typeError(ERROR_MSG.number)
    .when(FIELDS.measurement.name, (measurement, field) => {
      return measurement[0] === PromocodeMeasurementEnum.PERCENTAGES
        ? field.max(100)
        : field;
    })
    .required(ERROR_MSG.required),

  [IS_FREE_SETUP_FEE]: yup.boolean().default(false),
  [IS_FREE_SHIPPING]: yup.boolean().default(false),
});
