import {
  PASSWORD_REQUIREMENTS,
  MIN_PASSWORD_LENGTH,
  ONE_LOWERCASE_CHARACTER,
  ONE_UPPERCASE_CHARACTER,
  ONE_NUMERIC_CHARACTER,
  ONE_SPECIAL_CHARACTER,
  MIN_PASSWORD_LENGTH_CHARACTERS,
  AT_LEAST_ONE_LOWERCASE_CHARACTER_REGEXP,
  AT_LEAST_ONE_UPPERCASE_CHARACTER_REGEXP,
  AT_LEAST_ONE_NUMERIC_CHARACTER_REGEXP,
  AT_LEAST_ONE_SPECIAL_CHARACTER_REGEXP,
  MAX_PASSWORD_LENGTH,
} from './password-requirements-constants';

const getRuleIndex = (ruleStr: string): number => {
  return PASSWORD_REQUIREMENTS.findIndex((rule) => rule === ruleStr);
};

export const getCompletedPasswordRequirements = (password: string = ''): number[] => {
  const completedPasswordRequirements: number[] = [];

  if (AT_LEAST_ONE_LOWERCASE_CHARACTER_REGEXP.test(password)) {
    completedPasswordRequirements.push(getRuleIndex(ONE_LOWERCASE_CHARACTER));
  }

  if (AT_LEAST_ONE_UPPERCASE_CHARACTER_REGEXP.test(password)) {
    completedPasswordRequirements.push(getRuleIndex(ONE_UPPERCASE_CHARACTER));
  }

  if (AT_LEAST_ONE_NUMERIC_CHARACTER_REGEXP.test(password)) {
    completedPasswordRequirements.push(getRuleIndex(ONE_NUMERIC_CHARACTER));
  }

  if (AT_LEAST_ONE_SPECIAL_CHARACTER_REGEXP.test(password)) {
    completedPasswordRequirements.push(getRuleIndex(ONE_SPECIAL_CHARACTER));
  }

  if (password.length >= MIN_PASSWORD_LENGTH) {
    completedPasswordRequirements.push(getRuleIndex(MIN_PASSWORD_LENGTH_CHARACTERS));
  }

  return completedPasswordRequirements;
};

export const isPasswordLengthMoreThanMaxAllowedCharacters = (
  password: string = '',
): boolean => {
  return password.length > MAX_PASSWORD_LENGTH;
};

export const isAllPasswordRequirementsCompleted = (
  password: string = '',
  indexes: number[],
): boolean => {
  const isMax = isPasswordLengthMoreThanMaxAllowedCharacters(password);
  return !isMax && indexes.length === PASSWORD_REQUIREMENTS.length;
};
