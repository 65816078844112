import { Typography } from '@ezetech/swag-space-x';

import { useAppSelector } from 'redux/store';
import { totalPriceComponentSelector } from 'redux/selectors/orders.selectors';
import COLORS from 'constants/styles/colors-js.constant.module.scss';

import css from '../../order-card-item.module.scss';

interface IProps {
  orderId: string;
  itemId: string;
}

export const TotalCostComponent = ({ orderId, itemId }: IProps): JSX.Element => {
  const { totalPrice } = useAppSelector(totalPriceComponentSelector({ orderId, itemId }));

  return (
    <div className={css.totalCost}>
      <Typography lineHeight="170%" fontType="bodyMd" color={COLORS.colorSecondaryText}>
        Total Cost:
      </Typography>
      <div className={css.column}>
        <Typography
          lineHeight="170%"
          fontType="bodyMdBold"
          color={COLORS.colorPrimaryText}
        >
          {totalPrice}
        </Typography>
      </div>
    </div>
  );
};
