const carriers = {
  UPS: 'ups.com',
  USPS: 'usps.com',
  FedEx: 'fedex.com',
  DHL: 'dhl.com',
};

export const getTrackingNumberFromLink = (link: string): string => {
  if (!link) {
    return '';
  }

  if (link.includes(carriers.UPS)) {
    return link.split('trackNums=').pop() ?? '';
  }

  if (link.includes(carriers.USPS)) {
    return link.split('tLabels=').pop()?.split('%2C').shift() ?? '';
  }

  if (link.includes(carriers.FedEx)) {
    return link.split('trackingnumber=').pop()?.split('&cntry_code=in').shift() ?? '';
  }

  if (link.includes(carriers.DHL)) {
    return link.split('AWB=').pop() ?? '';
  }

  return '';
};
