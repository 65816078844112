import {
  Dialog,
  DialogContentWrapper,
  DialogTitle,
  DialogDescription,
  DialogFooter,
  SwagButton,
} from '@ezetech/swag-space-x';
import { FieldValues, useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { useAppDispatch } from 'redux/store';
import { closePopup, openPopup } from 'redux/slices/modals.slice';
import { CreateACartEmailPopupProps } from 'interfaces/popup.interface';
import { InputForm } from 'components/input-form';

import { CREATE_A_CART_TYPE_POPUP } from '../_logic/popups-list';
import css from './create-a-cart-email.popup.module.scss';
import { FIELDS } from './create-a-cart-email.constants';

export const CreateACartEmailPopup = ({
  email,
  isOpen,
}: CreateACartEmailPopupProps): JSX.Element => {
  const { handleSubmit, control, formState, getValues, setValue, setFocus } =
    useForm<FieldValues>({
      mode: 'all',
    });

  const dispatch = useAppDispatch();

  const { isValid } = formState;
  const emailEntered = Boolean(getValues(FIELDS.email.name)?.length);

  useEffect(() => {
    setFocus(FIELDS.email.name);
  }, [setFocus]);

  const isDisabled = !isValid || !emailEntered;

  useEffect(() => {
    if (email?.length) {
      setValue(FIELDS.email.name, email, { shouldDirty: true, shouldValidate: true });
    }
  }, [email]);

  const onBtnCloseClick = () => {
    dispatch(closePopup());
  };

  const onBtnContinueClick = () => {
    handleSubmit((data) => {
      dispatch(closePopup());
      dispatch(
        openPopup({
          popupName: CREATE_A_CART_TYPE_POPUP,
          popupProps: { email: data[FIELDS.email.name] },
        }),
      );
    })();
  };

  return (
    <Dialog open={isOpen} onOpenChange={onBtnCloseClick}>
      <DialogContentWrapper
        className={css.dialogContentWrapperClassName}
        overlayClassName={css.dialogOverlayClassName}
      >
        <DialogTitle closeIconClassName={css.closeIconClassName}>
          What customer is this for?
        </DialogTitle>
        <DialogDescription className={css.contentWrapper}>
          <InputForm
            autoComplete="off"
            type="text"
            label={FIELDS.email.label}
            errorClassName={css.errorClassName}
            labelClassName={css.labelClassName}
            name={FIELDS.email.name}
            shouldTrimInputValue
            placeholder={FIELDS.email.placeholder}
            rules={FIELDS.email.rules}
            control={control}
          />
        </DialogDescription>

        <DialogFooter className={css.footer}>
          <SwagButton type="outlined" onClick={onBtnCloseClick}>
            Cancel
          </SwagButton>
          <SwagButton disabled={isDisabled} type="primary" onClick={onBtnContinueClick}>
            Continue
          </SwagButton>
        </DialogFooter>
      </DialogContentWrapper>
    </Dialog>
  );
};
