import {
  Dialog,
  DialogContentWrapper,
  DialogTitle,
  DialogContent,
  DialogFooter,
  SwagButton,
  DialogDescription,
  Typography,
} from '@ezetech/swag-space-x';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { closePopup, openPopup } from 'redux/slices/modals.slice';
import {
  useCheckCompanyDetailsMutation,
  usePatchBillingAddressMutation,
} from 'redux/api/settings.api';
import { setBillingAddressErrors } from 'redux/slices/settings.slice';
import COLORS from 'constants/styles/colors-js.constant.module.scss';

import {
  billingAddressSelector,
  isBillingAddressValidSelector,
} from 'redux/selectors/settings.selectors';
import { AddressForm } from 'pages/settings/company-and-brand/forms';
import { InvoiceCreationBillingAddressProps } from 'interfaces/popup.interface';
import { INVOICE_ACH_NET_TERMS_INFORMATION_POPUP } from '../_logic/popups-list';
import css from './invoice-creation-billing-address-popup.module.scss';

export const InvoiceCreationBillingAddressPopup = ({
  isOpen,
  presentationEmail,
}: InvoiceCreationBillingAddressProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const values = useAppSelector(billingAddressSelector);
  const isFormValid = useAppSelector(isBillingAddressValidSelector);
  const [checkCompanyDetails] = useCheckCompanyDetailsMutation();
  const [patchBillingAddress, { isLoading: patchLoading }] =
    usePatchBillingAddressMutation();

  const onCloseClick = (isGoBack: boolean) => {
    dispatch(closePopup());
    dispatch(setBillingAddressErrors({}));
    if (isGoBack) {
      dispatch(
        openPopup({
          popupName: INVOICE_ACH_NET_TERMS_INFORMATION_POPUP,
          popupProps: { presentationEmail },
        }),
      );
    }
  };
  const onSaveClick = async () => {
    if (!isFormValid || patchLoading) {
      return;
    }

    await patchBillingAddress(values);
    await checkCompanyDetails({ email: presentationEmail });
  };

  return (
    <Dialog open={isOpen} onOpenChange={onCloseClick}>
      <DialogContentWrapper
        className={css.dialogContent}
        overlayClassName={css.dialogOverlay}
      >
        <DialogTitle closeIconClassName={css.closeIconClassName}>
          ADD YOUR BILLING ADDRESS
        </DialogTitle>
        <DialogContent className={css.dialogBody}>
          <DialogDescription className={css.description}>
            <Typography color={COLORS.colorTextSubdued} fontType="bodyMd">
              Before creating your first invoice, we need you to add your billing address
              so that we can display this on your invoice. Please enter your billing
              address to continue.
            </Typography>
          </DialogDescription>
          <AddressForm isPopupForm={true} />
        </DialogContent>
        <DialogFooter showDivider={false} className={css.footer}>
          <SwagButton
            className={css.button}
            type="outlined"
            onClick={() => onCloseClick(true)}
          >
            Go Back
          </SwagButton>
          <SwagButton
            className={css.button}
            loading={patchLoading}
            disabled={!isFormValid || patchLoading}
            type="primary"
            onClick={onSaveClick}
          >
            Add Address
          </SwagButton>
        </DialogFooter>
      </DialogContentWrapper>
    </Dialog>
  );
};
