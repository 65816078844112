import { useState, useRef, useEffect, IframeHTMLAttributes } from 'react';
import { CSSTransition } from 'react-transition-group';
import cn from 'classnames';

import css from './iframe.module.scss';

interface PageEditorPreviewProps extends IframeHTMLAttributes<HTMLIFrameElement> {
  onLoaded?: () => void;
}

const animationClasses = {
  enter: css.enter,
  enterActive: css.enterActive,
  enterDone: css.enterDone,
  exit: css.exit,
  exitActive: css.exitActive,
  exitDone: css.exitDone,
};

export function PageEditorIframe(props: PageEditorPreviewProps): JSX.Element {
  const { src, onLoaded, className } = props;
  const containerRef = useRef<HTMLDivElement | null>(null);
  const iframeOneRef = useRef<HTMLIFrameElement | null>(null);
  const iframeTwoRef = useRef<HTMLIFrameElement | null>(null);
  const iframeScrollPosition = useRef(0);
  const [activeIframe, setActiveIframe] = useState<number>(0);
  const [iFrameStyles, setStyles] = useState<Record<string, number | string>>({});
  let timeoutId: NodeJS.Timeout;

  const setIFrameStyles = (iFramePageWidth: number, rect: DOMRect) => {
    const { width, height } = rect;
    const aspectRatio = width / iFramePageWidth;
    setStyles({
      width: iFramePageWidth,
      height: height / aspectRatio,
      transform: `scale(${aspectRatio})`,
    });
  };

  const updateScrollPosition = (iframeNumber: number) => {
    const iframe =
      iframeNumber === 1 || iframeNumber === 0
        ? iframeOneRef?.current
        : iframeTwoRef?.current;

    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(
        { action: 'scrollToPosition', position: iframeScrollPosition.current },
        '*',
      );
    }
  };
  const handleOnIframeLoaded = (iframeNumber: number) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      const srcOne = iframeOneRef.current?.src || '';
      const srcTwo = iframeTwoRef.current?.src || '';
      if (
        !src ||
        (iframeNumber === 0 && srcOne !== src) ||
        (iframeNumber === 1 && srcOne !== src) ||
        (iframeNumber === 2 && srcTwo !== src)
      ) {
        return;
      }
      updateScrollPosition(1);
      updateScrollPosition(2);
      setActiveIframe(iframeNumber);
      onLoaded && onLoaded();
    }, 800);
  };

  useEffect(() => {
    if (!src) {
      return;
    }
    if (activeIframe === 1 && iframeTwoRef.current) {
      iframeTwoRef.current.src = src;
    } else if ((activeIframe === 2 || activeIframe === 0) && iframeOneRef.current) {
      iframeOneRef.current.src = src;
    }
  }, [src]);
  const handleResize = () => {
    const rect = containerRef?.current?.getBoundingClientRect();

    if (!rect) {
      return;
    }

    if (window.innerWidth >= 1920) {
      setIFrameStyles(1920, rect);
      return;
    }

    if (window.innerWidth >= 1600) {
      setIFrameStyles(1600, rect);
      return;
    }

    if (window.innerWidth >= 1440) {
      setIFrameStyles(1440, rect);
      return;
    }

    if (window.innerWidth >= 1220) {
      setIFrameStyles(1220, rect);
      return;
    }

    if (window.innerWidth >= 1024) {
      setIFrameStyles(1024, rect);
      return;
    }

    if (window.innerWidth >= 768) {
      setIFrameStyles(768, rect);
      return;
    }

    setStyles({});
  };

  useEffect(() => {
    handleResize();
  }, [containerRef?.current, activeIframe]);

  useEffect(() => {
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleMessageFromIframe = (event: {
    data: { type: string; position: number };
  }) => {
    if (event.data.type === 'scrollPosition') {
      iframeScrollPosition.current = event.data.position;
    }
  };

  useEffect(() => {
    window.addEventListener('message', handleMessageFromIframe);
    return () => {
      window.removeEventListener('message', handleMessageFromIframe);
    };
  }, []);

  return (
    <div ref={containerRef} className={css.root}>
      <CSSTransition
        key="iframeOne"
        in={activeIframe === 1 || activeIframe === 0}
        nodeRef={iframeOneRef}
        classNames={animationClasses}
        timeout={300}
      >
        <iframe
          style={iFrameStyles}
          ref={iframeOneRef}
          className={cn(css.iframe, className, {
            [css.upper]: activeIframe === 1 || activeIframe === 0,
          })}
          onLoad={() => handleOnIframeLoaded(1)}
        ></iframe>
      </CSSTransition>
      <CSSTransition
        key="iframeTwo"
        in={activeIframe === 2}
        nodeRef={iframeTwoRef}
        classNames={animationClasses}
        timeout={300}
      >
        <iframe
          style={iFrameStyles}
          ref={iframeTwoRef}
          className={cn(css.iframe, className, { [css.upper]: activeIframe === 2 })}
          onLoad={() => handleOnIframeLoaded(2)}
        ></iframe>
      </CSSTransition>
    </div>
  );
}
