import { CellSize, MultiOptionType, Pagination } from '@ezetech/swag-space-x';
import { PageContent } from 'components/page-content';
import { TableToolbar } from 'components/table-toolbar';
import { useAppDispatch, useAppSelector } from 'redux/store';
import {
  filtersRequestSelector,
  lastPageFiltersSelector,
  pageFiltersSelector,
} from 'redux/selectors/filters.selectors';
import { useGetAbandonedCheckoutCartsMutation } from 'redux/api/abandoned-checkouts-carts.api';
import { abandonedCheckoutFilterOptionsSelector } from 'redux/selectors/abandoned-checkout-carts.selector';
import { setFilter } from 'redux/slices/filter.slice';

import { ABANDONED_CHECKOUTS_CARTS_TABLE_HEADER } from 'interfaces/abandoned-checkouts-cart.interfaces';
import { AbandonedCheckoutCart } from './abandoned-checkout-cart';
import classes from './activity.module.scss';

export function Activity() {
  const filters = useAppSelector(filtersRequestSelector);
  const page = useAppSelector(pageFiltersSelector);
  const lastPage = useAppSelector(lastPageFiltersSelector);
  const abandonedCheckoutFilterOptions = useAppSelector(
    abandonedCheckoutFilterOptionsSelector,
  );
  const dispatch = useAppDispatch();
  const [getAbandonedCheckoutCarts] = useGetAbandonedCheckoutCartsMutation();

  const handlePageClick = (value: number) => {
    getAbandonedCheckoutCarts({
      ...filters,
      page: value,
    });
  };
  const handleSearchChange = (search: string) => {
    getAbandonedCheckoutCarts({
      ...filters,
      search,
      page: 1,
    });
  };

  const handleFilterClick = (selected: MultiOptionType) => {
    getAbandonedCheckoutCarts({
      ...filters,
      dropdown: selected,
      page: 1,
    });
  };

  const handleSortingClick = (field: string) => {
    const { sorting, sortingOrder } = filters;
    const isSortingNew = sorting !== field;
    const oppositeSortingForAppliedField = sortingOrder === 'ASC' ? 'DESC' : 'ASC';
    const newSortingOrder = isSortingNew ? 'ASC' : oppositeSortingForAppliedField;

    dispatch(setFilter({ sortingOrder: newSortingOrder, sorting: field }));
    getAbandonedCheckoutCarts({
      ...filters,
      page: 1,
      sorting: field,
      sortingOrder: newSortingOrder,
    });
  };

  const getSortingIconDirection = (id: string): 'up' | 'down' => {
    const list: { text: string; id: string; size?: CellSize; withSorting?: boolean }[] =
      ABANDONED_CHECKOUTS_CARTS_TABLE_HEADER;
    const cell = list.find((c) => c.id === id);

    if (!cell?.withSorting || cell.id !== filters.sorting) {
      return 'down';
    }

    return filters.sortingOrder === 'DESC' ? 'down' : 'up';
  };

  return (
    <PageContent pageContentClassName={classes.wrapper}>
      <TableToolbar
        onNextPageClick={handlePageClick}
        onSearchChange={handleSearchChange}
        onFilterClick={handleFilterClick}
        className={classes.toolbar}
        exportTooltipText={`Export ${screen} list`}
        isFilterMultiSelect
        filterOptions={abandonedCheckoutFilterOptions}
      />

      <AbandonedCheckoutCart
        getSortingDirection={getSortingIconDirection}
        onHeaderCellClick={handleSortingClick}
      />

      <Pagination
        paginationClassName={classes.pagination}
        goToPage={handlePageClick}
        page={page}
        lastPage={lastPage}
      />
    </PageContent>
  );
}
