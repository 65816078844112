import {
  IHoursOperationsBack,
  ISocialBack,
  IStoreSettingsBack,
} from 'interfaces/store-creation.interfaces';
import {
  ISocial,
  IBrand,
  IBillingAddress,
  ICompany,
  IPayout,
  IInvoice,
  IHoursOperations,
  Nullable,
} from 'interfaces';
import { DEFAULT_OPERATION_HOURS_STATE } from 'constants/settings.constants';

const baseSocial: ISocial = {
  instagram: '',
  facebook: '',
  tiktok: '',
  twitter: '',
  linkedIn: '',
};

export const mapSocial = (companySettings: IStoreSettingsBack): ISocial => {
  return {
    ...baseSocial,
    ...companySettings.socials?.reduce<ISocial>((acc, social) => {
      if (social.link && social.name) {
        return {
          ...acc,
          [social.name]: social.link ?? '',
        };
      }
      return acc;
    }, {}),
  };
};

export const mapBilling = (address: IBillingAddress): IBillingAddress => {
  return {
    billingAddress: address.billingAddress ?? '',
    billingCity: address.billingCity ?? '',
    billingState: address.billingState ?? '',
    billingZip: address.billingZip ?? '',
    billingCountry: address.billingCountry ?? null,
  };
};

export const mapPayout = (payout: IPayout): IPayout => {
  return {
    payoutBankCountry: payout.payoutBankCountry ?? null,
    payoutBankName: payout.payoutBankName ?? '',
    payoutAccountType: payout.payoutAccountType ?? null,
    payoutAccountBeneficiaryName: payout.payoutAccountBeneficiaryName ?? '',
    payoutBankAccountNumber: payout.payoutBankAccountNumber ?? '',
    payoutBIC: payout.payoutBIC ?? '',
    payoutRoutingNumber: payout.payoutRoutingNumber ?? '',
    payoutComments: payout.payoutComments ?? '',
  };
};

export const mapInvoice = (invoice: IInvoice): IInvoice => {
  return {
    invoiceBankCountry: invoice.invoiceBankCountry ?? null,
    invoiceBankName: invoice.invoiceBankName ?? '',
    invoiceAccountType: invoice.invoiceAccountType ?? null,
    invoiceAccountBeneficiaryName: invoice.invoiceAccountBeneficiaryName ?? '',
    invoiceBankAccountNumber: invoice.invoiceBankAccountNumber ?? '',
    invoiceBIC: invoice.invoiceBIC ?? '',
    invoiceRoutingNumber: invoice.invoiceRoutingNumber ?? '',
    invoiceAsInPayout: invoice.invoiceAsInPayout ?? false,
  };
};

export const mapPayoutToDB = (payout: IPayout): Nullable<Partial<IPayout>> => {
  const result: Nullable<Partial<IPayout>> = {
    payoutBankCountry: payout.payoutBankCountry ?? null,
    payoutBankName: payout.payoutBankName ?? '',
    payoutAccountType: payout.payoutAccountType ?? null,
    payoutAccountBeneficiaryName: payout.payoutAccountBeneficiaryName ?? '',
    payoutBankAccountNumber: payout.payoutBankAccountNumber ?? '',
    payoutBIC: payout.payoutBIC || null,
    payoutRoutingNumber: payout.payoutRoutingNumber ?? '',
    payoutComments: payout.payoutComments ?? '',
  };

  if (result.payoutBankCountry !== null && result.payoutBankCountry !== 'US') {
    result.payoutRoutingNumber = null;
  }

  return result;
};

export const mapInvoiceToDB = (invoice: IInvoice): Nullable<Partial<IInvoice>> => {
  let result: Nullable<Partial<IInvoice>>;
  if (invoice.invoiceAsInPayout) {
    result = {
      invoiceAsInPayout: invoice.invoiceAsInPayout,
      invoiceBankCountry: null,
      invoiceBankName: null,
      invoiceAccountType: null,
      invoiceAccountBeneficiaryName: null,
      invoiceBankAccountNumber: null,
      invoiceRoutingNumber: null,
      invoiceBIC: null,
    };
  } else {
    result = {
      invoiceBankCountry: invoice.invoiceBankCountry ?? null,
      invoiceBankName: invoice.invoiceBankName ?? '',
      invoiceAccountType: invoice.invoiceAccountType ?? null,
      invoiceAccountBeneficiaryName: invoice.invoiceAccountBeneficiaryName ?? '',
      invoiceBankAccountNumber: invoice.invoiceBankAccountNumber ?? '',
      invoiceBIC: invoice.invoiceBIC || null,
      invoiceRoutingNumber: invoice.invoiceRoutingNumber ?? '',
      invoiceAsInPayout: invoice.invoiceAsInPayout ?? false,
    };
  }

  if (result.invoiceBankCountry !== null && result.invoiceBankCountry !== 'US') {
    result.invoiceRoutingNumber = null;
  }

  return result;
};

export const mapPayoutToInvoice = (payout: IPayout): IInvoice => {
  return {
    invoiceBIC: payout.payoutBIC,
    invoiceAccountType: payout.payoutAccountType,
    invoiceBankName: payout.payoutBankName,
    invoiceBankCountry: payout.payoutBankCountry,
    invoiceBankAccountNumber: payout.payoutBankAccountNumber,
    invoiceAccountBeneficiaryName: payout.payoutAccountBeneficiaryName,
    invoiceRoutingNumber: payout.payoutRoutingNumber,
    invoiceAsInPayout: true,
  };
};

export const mapCompanyToDB = (company: ICompany): ICompany => {
  const mapped: ICompany = {
    name: company.name,
    email: company.email,
    phone: company.phone,
    showEmail: company.showEmail,
    showPhone: company.showPhone,
  };

  return mapped;
};

export const mapSocialToDB = (social: ISocial): ISocialBack[] => {
  return Object.entries(social)
    .filter((value) => value[1])
    .map((value) => {
      return {
        name: value[0],
        link: value[1],
      };
    });
};

export const mapBrand = (companySettings: IStoreSettingsBack): IBrand => {
  return {
    primaryColor: companySettings.primaryColor ?? '',
    secondaryColor: companySettings.secondaryColor ?? '',
    logo: companySettings.logo ?? undefined,
    favicon: companySettings.favicon ?? undefined,
  };
};

export const mapMargin = (companySettings: IStoreSettingsBack): string => {
  return ((companySettings.margin ?? 0.3) * 100).toFixed(0);
};
export const mapHours = (hours?: IHoursOperationsBack[]): IHoursOperations[] => {
  if (!hours) {
    return DEFAULT_OPERATION_HOURS_STATE;
  }
  return DEFAULT_OPERATION_HOURS_STATE.map((hour) => {
    const find = hours.find((h) => h.day === hour.day);
    if (find) {
      return {
        ...find,
        from: find.from?.toString() ?? null,
        to: find.to?.toString() ?? null,
      };
    }
    return {
      ...hour,
      from: hour.from?.toString() ?? null,
      to: hour.to?.toString() ?? null,
    };
  });
};

export const mapHoursToDB = (hours: IHoursOperations[]): IHoursOperationsBack[] => {
  return hours
    .filter((el) => {
      return Boolean(el.from && el.from !== 'reset' && el.to && el.to !== 'reset');
    })
    .map((el) => {
      return {
        ...el,
        from: Number(el.from),
        to: Number(el.to),
      };
    });
};

export const mapMarginToDB = (margin: string): IStoreSettingsBack => {
  return {
    margin: parseFloat(margin) / 100,
  };
};
