import { OrderCardHeader } from '../order-card-header';
import { OrderCardItemsWrapper } from '../order-card-items-wrapper';
import { OrderCardFooter } from '../order-card-footer';

import css from './order-card.module.scss';

interface IProps {
  orderId: string;
  orderPublicId: string;
  isOrderAssignmentEnabled: boolean;
}

export const OrderCardComponent = ({
  orderId,
  orderPublicId,
  isOrderAssignmentEnabled,
}: IProps) => {
  return (
    <div
      data-order-publicId={orderPublicId}
      data-order-id={orderId}
      className={css.orderCard}
    >
      <OrderCardHeader
        orderId={orderId}
        isOrderAssignmentEnabled={isOrderAssignmentEnabled}
      />
      <OrderCardItemsWrapper orderId={orderId} />
      <OrderCardFooter orderId={orderId} />
    </div>
  );
};
