import { createSelector } from 'reselect';
import { PRODUCTION_MOCKUP_STATUSES } from 'constants/item.constants';
import { IItem, IOrder, IProductionMockup } from 'interfaces/orders.interface';
import { RootState } from '../store';

export const dashboardSelector = (state: RootState) => state.dashboard;

export const referralLinkSelector = createSelector(
  dashboardSelector,
  (state) => state.referralLink,
);

export const totalEarnedSelector = createSelector(
  dashboardSelector,
  (state) => state.totalEarned,
);

export const pendingReferralsSelector = createSelector(
  dashboardSelector,
  (state) => state.pendingReferrals,
);

export const referralsCountSelector = createSelector(
  dashboardSelector,
  (state) => state.referralsCount,
);

export const itemDataForProductionMockupForDashboard = (item: IItem, order: IOrder) => {
  const customerMockups = (item?.pantones || []).map(
    ({ id, location, mockupPreview }) => ({
      id,
      location,
      mockupPreview,
    }),
  );

  if (!customerMockups.length) {
    customerMockups.push({
      mockupPreview: item?.imageUrl,
      location: 'full-design',
      id: 'full-design',
    });
  }

  const pendingProductionMockup = item?.productionMockups?.reduce<{
    id: string;
    status: string;
    fileName: string;
  }>(
    (res, mockup: IProductionMockup) => {
      if (res.id) {
        return res;
      }
      if (mockup.status === PRODUCTION_MOCKUP_STATUSES.PENDING_RESELLER_APPROVAL) {
        return {
          id: mockup.id,
          status: mockup.status,
          fileName: mockup.fileName,
        };
      }
      return res;
    },
    { id: '', status: '', fileName: '' },
  );

  return {
    productName: item?.name,
    orderNum: order.num,
    productionMockup: pendingProductionMockup ?? [],
    customerMockups,
  };
};
