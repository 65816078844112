import { pushNotification } from 'redux/slices/notifications.slice';
import { getPublicSid } from 'utils/auth.util';
import { makeToastNotification } from 'utils/query.util';
import { fetchStoreSettingsData } from 'utils/store-settings.util';
import { store } from '../../redux/store';

export async function storeCreationLoader(): Promise<null> {
  if (!getPublicSid()) {
    return null;
  }

  try {
    await fetchStoreSettingsData();
  } catch (e) {
    store.dispatch(pushNotification(makeToastNotification(e)));
  }

  return null;
}
