import { Tooltip, TooltipPlacement, Typography } from '@ezetech/swag-space-x';

import { useAppSelector } from 'redux/store';
import { quantityComponentSelector } from 'redux/selectors/orders.selectors';
import { pluralize } from 'utils/string.util';
import COLORS from 'constants/styles/colors-js.constant.module.scss';

import css from '../../order-card-item.module.scss';

interface IProps {
  orderId: string;
  itemId: string;
}

export const QuantityComponent = ({ orderId, itemId }: IProps): JSX.Element => {
  const { qty, sizesLength, showSize, hasMultipleSizes, sizeValue, tooltipData } =
    useAppSelector(quantityComponentSelector({ orderId, itemId }));

  return (
    <>
      <div className={css.quantity}>
        <div className={css.label}>
          <Typography
            lineHeight="170%"
            fontType="bodyMd"
            color={COLORS.colorSecondaryText}
          >
            Quantity:
          </Typography>
        </div>
        <Typography
          lineHeight="170%"
          fontType="bodyMdBold"
          color={COLORS.colorPrimaryText}
        >
          {qty}
        </Typography>
      </div>
      {showSize && (
        <div className={css.size}>
          <Typography
            lineHeight="170%"
            fontType="bodyMd"
            color={COLORS.colorSecondaryText}
          >
            {pluralize(sizesLength, 'Size', 's', true)}:
          </Typography>
          {hasMultipleSizes ? (
            <Tooltip
              placement={TooltipPlacement.right}
              content={
                <Typography
                  lineHeight="170%"
                  fontType="bodyMdBold"
                  color={COLORS.colorPrimaryText}
                  className={css.multipleSizes}
                >
                  Multiple
                </Typography>
              }
            >
              {tooltipData.map(({ color, quantitiesData }, i) => (
                <div className={css.sizesTooltipContent} key={color + i}>
                  <div className={css.sizeColor} style={{ backgroundColor: color }} />
                  <div className={css.sizeQuantity}>
                    {quantitiesData.map(({ size, quantity }, index) => (
                      <Typography
                        lineHeight="150%"
                        fontType="bodySm"
                        color={COLORS.colorPrimaryText}
                        key={index + size}
                      >
                        {size ? (
                          <>
                            {size}&nbsp;-&nbsp;{quantity}
                            {quantitiesData[index + 1] && ', '}
                          </>
                        ) : (
                          quantity
                        )}
                        &nbsp;
                      </Typography>
                    ))}
                  </div>
                </div>
              ))}
            </Tooltip>
          ) : (
            <Typography
              lineHeight="170%"
              fontType="bodyMdBold"
              color={COLORS.colorPrimaryText}
            >
              {sizeValue}
            </Typography>
          )}
        </div>
      )}
    </>
  );
};
