import { Document, Page } from 'react-pdf';
import { Loader } from '@ezetech/swag-space-x';

export const ProductionMockupViewer = ({
  url,
  page,
  setLastPage,
  isPDF,
  imgClassName,
  productionMockupViewerClassName,
  pagePreviewClassName,
  loaderClassName,
}: {
  url: string;
  isPDF: boolean;
  page: number;
  setLastPage: (page: number) => void;
  imgClassName?: string;
  productionMockupViewerClassName?: string;
  pagePreviewClassName?: string;
  loaderClassName?: string;
}) => {
  const onLoadSuccess = ({ numPages }: { numPages: number }) => {
    setLastPage(numPages);
  };

  if (!isPDF) {
    return <img src={url} alt="production mockup" className={imgClassName} />;
  }

  return (
    <Document
      file={url}
      renderMode="canvas"
      onLoadSuccess={onLoadSuccess}
      className={productionMockupViewerClassName}
      loading={<Loader className={loaderClassName} />}
    >
      <Page
        pageNumber={page}
        renderAnnotationLayer={false}
        renderTextLayer={false}
        className={pagePreviewClassName}
      />
    </Document>
  );
};
