interface IFormatPriceOptions {
  addComma?: boolean;
  onlyInteger?: boolean;
}

export class Price {
  static pad(int: number) {
    return `${int < 10 ? '0' : ''}${int}`;
  }

  static getInteger(value: number): number {
    if (value < 0) {
      return Math.ceil(value / 100);
    }

    return Math.floor(value / 100);
  }

  static priceIntegerAndFract(int: number, addDecimal?: boolean) {
    let value = int || 0;

    if (addDecimal) {
      value *= 100;
    }

    const integer = this.getInteger(value);
    const valueToFract = value < 0 ? value * -1 : value;

    const fract = this.pad(Math.floor(valueToFract) % 100);

    return { integer, fract };
  }

  /**
   * This function accepts any number in any format (floating point number or integer)
   * that this regular expression converts number to string that
   * contains this number with hundreds separated by comma
   * Example 1000 -> 1,000; 1000000 -> 1,000,000, 1453.54321 -> 1,453.54321
   * @param number
   */
  static addCommaToNumber(number: number | string): string {
    return Number(number).toLocaleString('en-US');
  }

  static formatPrice(
    int?: number,
    { addComma = true, onlyInteger = false }: IFormatPriceOptions = {},
  ): string {
    const { integer, fract } = this.priceIntegerAndFract(Number(int));
    const number = addComma ? this.addCommaToNumber(integer) : integer;

    return onlyInteger ? `$${number}` : `$${number}.${fract}`;
  }
}
