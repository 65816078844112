import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { ReducerPath } from 'interfaces';
import {
  ICustomerCompanyOrdersList,
  ICustomerCompanyUsersList,
  ICustomerCompanyProfileResponse,
  ICustomerCompanyOrdersRequest,
  ICustomerCompanyUsersRequest,
} from 'interfaces/customer-company.interface';
import {
  setCustomerCompanyOrders,
  setCustomerCompanyProfiles,
  setCustomerCompanyUsers,
} from 'redux/slices/customer-company.slice';
import { makeToastNotification } from 'utils/query.util';

import { pushNotification } from '../slices/notifications.slice';

import { baseQueryParams } from './helpers';

export const customerCompanyApi = createApi({
  reducerPath: ReducerPath.customerCompany,
  baseQuery: fetchBaseQuery(baseQueryParams),
  endpoints: (builder) => ({
    getCustomerCompanyProfile: builder.query<
      ICustomerCompanyProfileResponse,
      { id: string }
    >({
      query: (params) => ({
        url: `/customer-company/${params.id}`,
        method: 'GET',
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(setCustomerCompanyProfiles(data));
          }
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),

    getCustomerCompanyOrders: builder.query<
      ICustomerCompanyOrdersList,
      ICustomerCompanyOrdersRequest
    >({
      query: ({ id, ...params }) => {
        return {
          url: `/customer-company/${id}/orders`,
          method: 'GET',
          params,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setCustomerCompanyOrders(data));
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),

    getCustomerCompanyUsers: builder.query<
      ICustomerCompanyUsersList,
      ICustomerCompanyUsersRequest
    >({
      query: ({ id, ...params }) => {
        return {
          url: `/customer-company/${id}/users`,
          method: 'GET',
          params,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setCustomerCompanyUsers(data));
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),
  }),
});

export const { useGetCustomerCompanyProfileQuery } = customerCompanyApi;
