import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { ROUTES } from 'constants/router';
import { canRoute } from 'boot/ability';

export function AbilityRoute({ children }: React.PropsWithChildren): JSX.Element {
  const location = useLocation();
  const permissions = canRoute(location.pathname);

  if (!permissions.isAllowed) {
    return <Navigate to={ROUTES.INDEX} replace />;
  }
  if (permissions.redirect) {
    return <Navigate to={permissions.redirect} replace />;
  }

  return children ? <>{children}</> : <Outlet />;
}
