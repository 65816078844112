export const FIELDS = {
  email: {
    label: 'Email Address',
    name: 'email',
    placeholder: 'Enter Email',
  },
  companyName: {
    label: 'Company Name',
    name: 'name',
    placeholder: 'Enter Company Name',
  },
  cellPhone: {
    label: 'Cell Phone',
    name: 'phone',
    placeholder: 'Enter Cell Phone',
  },
  showEmail: {
    label: 'Display on my website',
    name: 'showEmail',
  },
  showPhone: {
    label: 'Display on my website',
    name: 'showPhone',
  },
};
