export const FIELDS = {
  payoutBankCountry: {
    label: 'Bank Country',
    name: 'payoutBankCountry',
    placeholder: 'Enter Bank Country',
  },
  payoutBankName: {
    label: 'Bank Name',
    name: 'payoutBankName',
    placeholder: 'Enter Bank Name',
  },
  payoutAccountType: {
    label: 'Account Type',
    name: 'payoutAccountType',
    placeholder: 'Enter Account Type',
  },
  payoutAccountBeneficiaryName: {
    label: 'Account Beneficiary Name',
    name: 'payoutAccountBeneficiaryName',
    placeholder: 'Enter Account Beneficiary Name',
  },
  payoutBankAccountNumber: {
    label: (isEurope: boolean) => (isEurope ? 'IBAN' : 'Bank Account Number'),
    name: 'payoutBankAccountNumber',
    placeholder: (isEurope: boolean) =>
      isEurope ? 'Enter IBAN' : 'Enter Bank Account Number',
  },
  payoutBIC: {
    label: (isUSA: boolean) => (isUSA ? 'BIC/SWIFT Code (Optional)' : 'BIC/SWIFT Code'),
    name: 'payoutBIC',
    placeholder: 'Enter BIC/SWIFT Code',
  },
  payoutRoutingNumber: {
    label: 'Routing Number (Optional)',
    name: 'payoutRoutingNumber',
    placeholder: 'Enter Routing Number',
  },
  payoutComments: {
    label: 'Comments (Optional)',
    name: 'payoutComments',
    placeholder: 'Enter Comments',
  },
};

export enum Saving {
  CHECKING = 'CHECKING',
  SAVINGS = 'SAVINGS',
}

export const SAVING_OPTIONS = [
  {
    label: 'Checking',
    value: Saving.CHECKING,
  },
  {
    label: 'Savings',
    value: Saving.SAVINGS,
  },
];
