import { Typography } from '@ezetech/swag-space-x';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { useAppSelector } from 'redux/store';
import { customerTypeDetailsSelector } from 'redux/selectors/customer-details.selector';
import css from './details-type.module.scss';
export function DetailsType(): JSX.Element {
  const type = useAppSelector(customerTypeDetailsSelector);

  return (
    <div className={css.block}>
      <div className={css.wrapper}>
        <Typography
          className={css.title}
          lineHeight="162%"
          fontType="bodyMd"
          color={COLORS.colorTextSubdued}
        >
          Customer type
        </Typography>
      </div>
      <div className={css.wrapper}>
        <Typography
          className={css.title}
          lineHeight="162%"
          fontType="bodyMd"
          color={COLORS.colorPrimaryText}
        >
          {type}
        </Typography>
      </div>
    </div>
  );
}
