import { SwagButton } from '@ezetech/swag-space-x';
import { ColoredContainerHeader } from 'components/colored-container-header';
import { LANDING_URL } from 'utils/urls.util';

import css from './submitted.module.scss';

export function Submitted() {
  const onClick = () => {
    window.location.assign(LANDING_URL);
  };
  return (
    <div className={css.submitted}>
      <div>
        <ColoredContainerHeader
          tagline="Wohoo!"
          title="Thank you for application"
          description="Our team will review your application and get back to you as soon as possible."
        />
      </div>
      <div>
        <SwagButton type="primary" size="xl" fullWidth onClick={onClick}>
          Back to Swag Space
        </SwagButton>
      </div>
    </div>
  );
}
