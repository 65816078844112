import { FC, useEffect, useRef } from 'react';
import { Typography, Divider, Loader } from '@ezetech/swag-space-x';

import COLORS from 'constants/styles/colors-js.constant.module.scss';
import {
  PdfPresentationEditorPage,
  PdfPresentationEditorPageId,
} from 'interfaces/pdf-presentation';
import classes from '../edit-pdf-presentation-layout-popup.module.scss';

import { PagesSidebarProps } from '../interfaces/components';
import { Filters } from './filters';
import { PagePreviewFilters } from './page-preview.filters';
import '@ezetech/swag-presentation-templates/dist/utils/register-fonts';

export const PagesSidebar: FC<PagesSidebarProps> = ({
  filter: { value, onChangeValue },
  pagesToRender,
  selectedPageTemplateId,
  handlePageChange,
  values,
  imagesOfPdfPages,
  isPdfRendering,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const getPageIsHidden = (pageTemplate: PdfPresentationEditorPage) => {
    return pageTemplate?.isCanBeHidden && pageTemplate?.isHiddenKeyName
      ? Boolean(values[pageTemplate?.isHiddenKeyName])
      : false;
  };

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = 0;
    }
  }, [value]);

  return (
    <div className={classes.pagesSidebar}>
      <div className={classes.pagesSidebarHeader}>
        <Typography
          className={classes.pagesSidebarHeaderText}
          fontType="HEADING6XL"
          color={COLORS.colorPrimaryText}
        >
          Pages
        </Typography>
        <Divider withMargin={false} color={COLORS.colorNeural200} />
        <Filters value={value} onChangeValue={onChangeValue} />
      </div>

      <div className={classes.pagesSidebarPagesContainer} ref={containerRef}>
        {isPdfRendering && (
          <div className={classes.pagesSidebarLoader}>
            <Loader />
          </div>
        )}
        {imagesOfPdfPages?.length ? (
          <div className={classes.pagesViewer}>
            <div className={classes.pagesSidebarPages}>
              {imagesOfPdfPages.map((image, index) => {
                return (
                  <PagePreviewFilters
                    key={image.pageId}
                    pageTemplateId={image.pageId as PdfPresentationEditorPageId}
                    pageIndex={index}
                    selectedPageTemplateId={selectedPageTemplateId}
                    handlePageChange={handlePageChange}
                    isHidden={getPageIsHidden(pagesToRender[image.pageNumber - 1])}
                    page={
                      <div className={classes.previewSectionPage}>
                        <img src={image.dataUrl} />
                      </div>
                    }
                  />
                );
              })}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
