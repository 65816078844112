import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ICustomerCompanyProfileState,
  ICustomerCompanyOrder,
} from 'interfaces/customer-company.interface';
import { IOrder } from 'interfaces/orders.interface';

const initialState: ICustomerCompanyProfileState = {
  statistic: {
    avgOrderValue: '',
    lastOrderAt: '',
    totalSpent: '',
    totalOrders: '',
  },
  name: '',
  users: {
    list: [],
    listInfinity: [],
    perPage: 0,
    page: 0,
    total: 0,
  },
  orders: {
    list: [],
    listInfinity: [],
    perPage: 0,
    page: 0,
    total: 0,
  },
};

export const customerCompanySlice = createSlice({
  initialState,
  name: 'customerCompanySlice',
  reducers: {
    reset: () => initialState,
    setCustomerCompanyProfiles: (
      state,
      action: PayloadAction<{
        statistic: ICustomerCompanyProfileState['statistic'];
        name: ICustomerCompanyProfileState['name'];
      }>,
    ) => {
      if (!action.payload) {
        return state;
      }

      return {
        ...state,
        statistic: action.payload.statistic,
        name: action.payload.name,
      };
    },
    setCustomerCompanyUsers: (
      state,
      action: PayloadAction<ICustomerCompanyProfileState['users']>,
    ) => {
      if (!action.payload) {
        return state;
      }

      const listInfinity = [
        ...(action.payload.page === 1 ? [] : state.users.listInfinity),
        ...action.payload.list,
      ];
      return {
        ...state,
        users: {
          ...action.payload,
          listInfinity,
        },
      };
    },
    setCustomerCompanyOrders: (
      state,
      action: PayloadAction<ICustomerCompanyProfileState['orders']>,
    ) => {
      if (!action.payload) {
        return state;
      }
      const listInfinity = [
        ...(action.payload.page === 1 ? [] : state.orders.listInfinity),
        ...action.payload.list,
      ];
      return {
        ...state,
        orders: {
          ...action.payload,
          listInfinity,
        },
      };
    },
    updateCustomerCompanyItemStatus: (state, action: PayloadAction<IOrder>) => {
      const { listInfinity, list } = state.orders;
      const { payload } = action;
      const mapToUpdateItemStatus = (order: ICustomerCompanyOrder) => {
        if (order.id === payload.id) {
          const itemToUpdate = payload.items.find((i) => i.id === order.item.id);
          if (!itemToUpdate) {
            return order;
          }
          return {
            ...order,
            item: {
              ...order.item,
              status: itemToUpdate.status,
              statusCode: itemToUpdate.statusCode,
            },
          };
        }
        return order;
      };
      const data = {
        ...state,
        orders: {
          ...state.orders,
          listInfinity: listInfinity.map(mapToUpdateItemStatus),
          list: list.map(mapToUpdateItemStatus),
        },
      };
      return data;
    },
  },
});

export const customerCompanyReducer = customerCompanySlice.reducer;
export const {
  reset,
  setCustomerCompanyOrders,
  setCustomerCompanyProfiles,
  setCustomerCompanyUsers,
  updateCustomerCompanyItemStatus,
} = customerCompanySlice.actions;
