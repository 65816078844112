import { pushNotification } from 'redux/slices/notifications.slice';
import { makeToastNotification } from 'utils/query.util';
import { can, SUBJECTS } from 'boot/ability';
import { fetchUserData } from 'utils/user.util';
import { fetchStoreSettingsData } from 'utils/store-settings.util';
import { store } from '../../redux/store';
import { promocodesApi } from '../../redux/api/promocode.api';

export async function promocodesLoader(): Promise<null> {
  await fetchUserData();
  await fetchStoreSettingsData();

  try {
    if (
      can(
        SUBJECTS.PROMOCODES_VIEW.actions.ACTIVE_PROMOCODES_VIEW,
        SUBJECTS.PROMOCODES_VIEW.value,
      )
    ) {
      const request = store.dispatch(promocodesApi.endpoints.getPromoCodes.initiate({}));
      const result = await request.unwrap();

      if ('error' in result) {
        return null;
      }
    }
  } catch (e) {
    store.dispatch(pushNotification(makeToastNotification(e)));
  }

  return null;
}
