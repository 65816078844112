import { FC } from 'react';
import { Typography } from '@ezetech/swag-space-x';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import css from '../sign-contract.module.scss';
import { makeBold } from '../sign-contract.util';

export const Disclaimer: FC = () => {
  return (
    <>
      <Typography color={COLORS.colorPrimaryText} fontType="bodyMdBold">
        12. Disclaimer; Indemnity; Limitation of Liability
      </Typography>
      <br />
      <br />
      <ol className={css.listLatin}>
        <li className={css.listItem}>
          {makeBold('DISCLAIMER.')} YOUR USE OF THE SERVICES IS AT YOUR SOLE RISK. SWAG
          SPACE PROVIDES THE SERVICES "AS IS" AND “AS AVAILABLE.” SWAG SPACE HEREBY
          DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO ANY
          IMPLIED WARRANTIES THAT ARISE FROM COURSE OF DEALING OR USAGE OF TRADE OR
          BUSINESS AND THE IMPLIED WARRANTIES OF MERCHANTABILITY, TITLE, NONINFRINGEMENT
          OR FITNESS FOR A PARTICULAR PURPOSE. SWAG SPACE MAKES NO WARRANTY THAT (A) THE
          SERVICES OR THE CUSTOMIZED PRODUCTS WILL MEET YOUR REQUIREMENTS; (B) THE
          SERVICES ARE ERROR FREE, WILL OPERATE WITHOUT INTERRUPTION OR WITHOUT DEFECT, OR
          (C) DATA STORED WITHIN THE SITE AND/OR BY SWAG SPACE PURSUANT TO THESE TERMS
          WILL NOT BE LOST. SWAG SPACE DISCLAIMS ANY WARRANTY, COVENANT OR REPRESENTATION
          UNDER THESE TERMS TO ANY PERSON OTHER THAN YOU.
        </li>
        <br />
        <li className={css.listItem}>
          {makeBold('Indemnification.')} You agree to indemnify, defend and hold harmless
          Swag Space, its affiliates, parent entity, officers, directors, employees,
          consultants, and agents (collectively, the {makeBold('“Swag Space Parties”')}),
          from and against any claims, obligations, losses, costs, liabilities, expenses
          or debt (including without limitation reasonable attorneys' fees both for
          defending such claim and enforcing the terms of this section) or damages
          asserted against or suffered by Swag Space as a result of or related to: (i) a
          breach of these Terms by you; (ii) your use of the Services; (iii) your failure
          to comply with any applicable law, regulation, statute, or rule; or (iv) your
          violation of any third party right, including without limitation any
          intellectual property right, or violation by any of your customers of any third
          party right, including without limitation any intellectual property right. Swag
          Space reserves the right to assume the exclusive defense and control of any
          matter which is subject to indemnification under this section, and you agree to
          cooperate with any reasonable requests assisting Swag Space’s defense of such
          matter. You may not settle or compromise any claim against the Swag Space
          Parties without Swag Space’s written consent. These terms will inure to the
          benefit of successors, assignees, and licensees. These obligations will survive
          these Terms and your use of the Services.
        </li>
        <br />
        <li className={css.listItem}>
          {makeBold('LIMITATION OF LIABILITY.')} USE OF THE SERVICES (AND SWAG SPACE
          CONTENT) IS AT YOUR OWN RISK. REGARDLESS OF THE THEORY OF LAW AND TO THE MAXIMUM
          EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL SWAG SPACE, ITS EMPLOYEES,
          CONTRACTORS, AGENTS, OR AFFILIATES BE LIABLE TO YOU FOR ANY INDIRECT,
          INCIDENTAL, PUNITIVE, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES (INCLUDING
          DAMAGES FOR LOSS OF PROFITS, GOODWILL, DATA, OR USE), EVEN IF SWAG SPACE HAS
          BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. FURTHER, SWAG SPACE, ITS
          EMPLOYEES, CONTRACTORS, AGENTS, OR AFFILIATES WILL NOT BE RESPONSIBLE FOR ANY
          COMPENSATION, REIMBURSEMENT, OR DAMAGES ARISING IN CONNECTION WITH: (I) THE
          INABILITY TO USE THE SITE; (II) THE COST OF PROCUREMENT OF SUBSTITUTE SERVICES;
          (III) ANY INVESTMENTS, EXPENDITURES, OR COMMITMENTS BY EITHER PARTY IN
          CONNECTION WITH THESE TERMS OR YOUR USE OF OR ACCESS TO THE SITE; OR (IV) ANY
          UNAUTHORIZED ACCESS TO, ALTERATION OF, OR THE DELETION, DESTRUCTION, DAMAGE,
          LOSS OR FAILURE TO STORE ANY OF YOUR CONTENT OR OTHER DATA. SWAG SPACE’S
          AGGREGATE LIABILITY UNDER THESE TERMS WILL BE LIMITED TO THE LESSER OF (A) THE
          AGGREGATE BASE PRICE AMOUNTS (PRIOR TO YOUR PAYMENT BEING ADDED) PAID BY YOU OR
          YOUR CUSTOMERS TO SWAG SPACE FOR CUSTOMIZED PRODUCTS ORDERED FROM SWAG SPACE IN
          THE LAST SIX (6) MONTHS, OR (B) ONE THOUSAND DOLLARS ($1,000.00).
          <br />
          <br />
          SOME JURISDICTIONS DO NOT ALLOW THE DISCLAIMER OR EXCLUSION OF CERTAIN
          WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR
          CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE LIMITATIONS SET FORTH ABOVE MAY
          NOT APPLY TO YOU OR BE ENFORCEABLE WITH RESPECT TO YOU. THE FOREGOING SECTIONS
          TITLED “DISCLAIMER” AND “LIMITATION OF LIABILITY” ARE INTENDED TO BE ONLY AS
          BROAD AS IS PERMITTED UNDER APPLICABLE LAWS. IF ANY PORTION OF THESE SECTIONS IS
          HELD TO BE INVALID UNDER THE LAWS OF THE JURISDICTION APPLICABLE TO YOU, THE
          INVALIDITY OF SUCH PORTION WILL NOT AFFECT THE VALIDITY OF THE REMAINING
          PORTIONS OF THE APPLICABLE SECTIONS.
        </li>
      </ol>
      <br />
    </>
  );
};
