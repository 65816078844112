export enum TeamMemberMode {
  CREATE = 'create',
  EDIT = 'edit',
}

export interface TeamMemberDetailsProps {
  mode?: TeamMemberMode;
}

export { TeamMemberDetails } from './team-member-details';
