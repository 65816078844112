import { createApi, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IFile } from '@ezetech/swag-space-x';
import {
  IBillingAddress,
  ICreditCard,
  ICompany,
  IIntercomValidation,
  IInvoice,
  IPayout,
  ReducerPath,
  IUpdateWhatWeOfferSettings,
  Nullable,
  PdfPresentationEditor,
  PdfPresentationEditorUpdateBody,
  PdfPresentationEditorTemplate,
  PdfPresentationEditorReview,
  IHomepageSettings,
  IHomepageSettingsDb,
  PaymentMethodValidationErrors,
} from 'interfaces';
import {
  IHoursOperationsBack,
  IStoreSettingsBack,
} from 'interfaces/store-creation.interfaces';
import {
  makeSuccessToastNotification,
  makeToastNotification,
  downloadFileInBrowser,
} from 'utils/query.util';
import {
  mapBilling,
  mapBrand,
  mapHours,
  mapInvoice,
  mapMargin,
  mapPayout,
  mapSocial,
} from 'utils/settings/mappers';
import { mapDBToStore, mapEditableToDB } from 'utils/pdf-presentation-editor/mappers';
import { formatAddress } from 'utils/address-format.util';
import { BRAND_LOGOS_LENGTH, REVIEWS_LENGTH } from 'constants/pdf-presentation.constants';
import { TOS_PP_TYPE } from 'constants/settings.constants';
import { closePopup, openPopup } from 'redux/slices/modals.slice';
import {
  BILLING_ADDRESS_POPUP,
  INVOICE_BANK_DETAILS_POPUP,
  INVOICE_CREATION_CUSTOMER_INFO_POPUP,
} from 'components/popups/_logic/popups-list';
import { pushNotification } from '../slices/notifications.slice';
import {
  setBillingAddress,
  setBrand,
  setBrandLogo,
  setBrandFavicon,
  setCompany,
  setCompanySettings,
  patchCompanySettings,
  setInvoice,
  setMargin,
  setPayout,
  setSocial,
  setIntercomAppIdError,
  setCard,
  setWhatWeOfferSettings,
  setHomepageSettings,
  ISettingsSlice,
} from '../slices/settings.slice';
import { setUser } from '../slices/user.slice';
import { changeLoading, setEditorState } from '../slices/pdf-presentation-editor.slice';
import { profileSelector } from '../selectors/user.selectors';
import { settingsSelector } from '../selectors/settings.selectors';
import { RootState } from '../store';
import { baseQueryParams } from './helpers';

export const settingsApi = createApi({
  reducerPath: ReducerPath.settings,
  baseQuery: fetchBaseQuery({
    ...baseQueryParams,
  }),
  endpoints: (builder) => ({
    getCompanySettings: builder.mutation<IStoreSettingsBack, void>({
      query: () => ({ url: '/company-settings', method: 'GET' }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(
              setCompanySettings({
                ...data,
                hoursOperations: mapHours(data.hoursOperations) as IHoursOperationsBack[],
              }),
            );
            dispatch(setSocial(mapSocial(data)));
            dispatch(setBrand(mapBrand(data)));
            dispatch(setMargin(mapMargin(data)));
          }
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),
    patchCompanySettings: builder.mutation<
      IStoreSettingsBack,
      Partial<IStoreSettingsBack>
    >({
      query: (payload) => ({
        url: '/company-settings',
        method: 'PATCH',
        body: payload,
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(
              patchCompanySettings({
                ...data,
                hoursOperations: mapHours(data.hoursOperations) as IHoursOperationsBack[],
              }),
            );
            dispatch(setSocial(mapSocial(data)));
            dispatch(setBrand(mapBrand(data)));
            dispatch(setMargin(mapMargin(data)));
          }
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),
    getCompany: builder.mutation<ICompany, void>({
      query: () => ({ url: '/company', method: 'GET' }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(setCompany(data));
          }
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),
    patchCompany: builder.mutation<ICompany, Partial<ICompany>>({
      query: (payload) => ({
        url: '/company',
        method: 'PATCH',
        body: payload,
      }),
      onQueryStarted: async (_, { getState, dispatch, queryFulfilled }) => {
        try {
          const state = getState();
          const profile = profileSelector(state as unknown as RootState);
          const settings = settingsSelector(state as unknown as RootState);
          const isSameEmail = profile?.email === settings?.initialCompany?.email;

          const { data } = await queryFulfilled;
          if (data) {
            dispatch(setCompany(data));

            if (isSameEmail) {
              dispatch(
                setUser({
                  ...profile,
                  email: data.email,
                }),
              );
            }
          }
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),
    getBillingAddress: builder.mutation<IBillingAddress, void>({
      query: () => ({ url: '/company-payment/billing', method: 'GET' }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(setBillingAddress(mapBilling(data)));
          }
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),
    patchBillingAddress: builder.mutation<IBillingAddress, Partial<IBillingAddress>>({
      query: (payload) => ({
        url: '/company-payment/billing',
        method: 'PATCH',
        body: payload,
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(setBillingAddress(data));
          }
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),
    getPayout: builder.mutation<IPayout, void>({
      query: () => ({ url: '/company-payment/payout', method: 'GET' }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(setPayout(mapPayout(data)));
          }
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),
    patchPayout: builder.mutation<IPayout, Partial<Nullable<IPayout>>>({
      query: (payload) => ({
        url: '/company-payment/payout',
        method: 'PATCH',
        body: payload,
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(setPayout(data));
          }
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),
    getInvoice: builder.mutation<IInvoice, void>({
      query: () => ({ url: '/company-payment/invoice', method: 'GET' }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(setInvoice(mapInvoice(data)));
          }
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),
    patchInvoice: builder.mutation<IInvoice, Partial<Nullable<IInvoice>>>({
      query: (payload) => ({
        url: '/company-payment/invoice',
        method: 'PATCH',
        body: payload,
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(setInvoice(data));
          }
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),
    uploadLogo: builder.mutation<Record<string, Record<string, string>>, FormData>({
      query: (data) => ({
        url: '/company-settings/logo',
        method: 'POST',
        body: data,
      }),

      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data && data.logo) {
            dispatch(setBrandLogo(data.logo));
          }
          if (data && data.favicon) {
            dispatch(setBrandFavicon(data.favicon));
          }
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),
    validateIntercomAppId: builder.mutation<IIntercomValidation, string>({
      query: (appId: string): FetchArgs => ({
        url: `/company-settings/intercom/${appId}/validate`,
        method: 'GET',
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          if (!data?.valid) {
            dispatch(setIntercomAppIdError('Invalid Intercom App ID'));
          }
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),
    addCreditCard: builder.mutation<ICreditCard, ICreditCard['paymentMethodId']>({
      query: (paymentMethodId: string): FetchArgs => ({
        url: '/credit-card/',
        method: 'POST',
        body: {
          paymentMethodId,
        },
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          dispatch(setCard(data));
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),
    deleteCreditCard: builder.mutation<void, ICreditCard['paymentMethodId']>({
      query: (paymentMethodId: string): FetchArgs => ({
        url: `/credit-card/${paymentMethodId}`,
        method: 'DELETE',
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(setCard(null));
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),
    getCreditCards: builder.mutation<ICreditCard[], void>({
      query: (): FetchArgs => ({
        url: '/credit-card/',
        method: 'GET',
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          dispatch(setCard(data[0]));
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),
    uploadLogoPreview: builder.mutation<Record<string, Record<string, string>>, FormData>(
      {
        query: (data) => ({
          url: '/company-settings/file',
          method: 'POST',
          body: data,
        }),

        async onQueryStarted(_, { dispatch, queryFulfilled }) {
          try {
            await queryFulfilled;
          } catch (e) {
            dispatch(pushNotification(makeToastNotification(e)));
          }
        },
      },
    ),
    getWhatWeOfferData: builder.mutation<IUpdateWhatWeOfferSettings, void>({
      query: () => ({ url: '/company-settings/what-we-offer', method: 'GET' }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(setWhatWeOfferSettings(data));
          }
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),

    updateWhatWeOfferData: builder.mutation<
      IUpdateWhatWeOfferSettings,
      IUpdateWhatWeOfferSettings
    >({
      query: (data) => ({
        url: '/company-settings/what-we-offer',
        method: 'PUT',
        body: data,
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(setWhatWeOfferSettings(data));
          }
          dispatch(pushNotification(makeSuccessToastNotification('Settings updated!')));
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),

    getPdfPresentationEditorSettings: builder.mutation<PdfPresentationEditor, void>({
      query: (): FetchArgs => ({
        url: 'company-settings/presentation',
        method: 'GET',
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled, getState }) => {
        dispatch(changeLoading(true));
        const { settings } = getState() as unknown as { settings: ISettingsSlice };
        try {
          const { data } = await queryFulfilled;
          dispatch(
            setEditorState(
              mapDBToStore({
                ...data,
              }),
            ),
          );
        } catch (e) {
          dispatch(
            setEditorState(
              mapDBToStore({
                id: null,
                template: PdfPresentationEditorTemplate.box,
                brandLogos: Array.from<IFile | null>({ length: BRAND_LOGOS_LENGTH }).fill(
                  null,
                ),
                portfolioImage: null,
                productShowcaseImage: null,
                boxOverviewImage: null,
                reviews: Array.from<PdfPresentationEditorReview | null>({
                  length: REVIEWS_LENGTH,
                }).fill(null),
                createdAt: null,
                updatedAt: null,
                contactEmail: settings.company.email,
                contactPhone: settings.company.phone ?? '',
                contactAddress: formatAddress(settings.billingAddress),
              }),
            ),
          );
        } finally {
          dispatch(changeLoading(false));
        }
      },
    }),

    updatePdfPresentationEditorSettings: builder.mutation<
      PdfPresentationEditor,
      PdfPresentationEditorUpdateBody
    >({
      query: (body): FetchArgs => ({
        url: 'company-settings/presentation',
        method: 'PUT',
        body: mapEditableToDB(body),
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        dispatch(changeLoading(true));
        try {
          const { data } = await queryFulfilled;
          dispatch(
            setEditorState(
              mapDBToStore({
                ...data,
              }),
            ),
          );
          dispatch(
            pushNotification(
              makeSuccessToastNotification('Presentation settings updated!'),
            ),
          );
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        } finally {
          dispatch(changeLoading(false));
        }
      },
    }),

    downloadToSOrPPPdf: builder.mutation<void, { type: TOS_PP_TYPE; draftHtml?: string }>(
      {
        query: ({ type, draftHtml }) => ({
          url: `/company-settings/${type}/download`,
          method: 'POST',
          body: { draftHtml },
          responseHandler: async (response) => {
            await downloadFileInBrowser(response, type);
          },
        }),
        async onQueryStarted(_, { dispatch, queryFulfilled }) {
          try {
            await queryFulfilled;
          } catch (error) {
            dispatch(pushNotification(makeToastNotification(error)));
          }
        },
      },
    ),

    uploadToSOrPPPdf: builder.mutation<void, { type: TOS_PP_TYPE; data: FormData }>({
      query: ({ type, data }) => ({
        url: `/company-settings/${type}/upload`,
        method: 'POST',
        body: data,
      }),

      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(settingsApi.endpoints.getCompanySettings.initiate());
        } catch (error) {
          dispatch(pushNotification(makeToastNotification(error)));
        }
      },
    }),

    addDomain: builder.mutation<void, { domain: string }>({
      query: ({ domain }) => ({
        url: '/custom-domain/',
        method: 'POST',
        body: { domain },
      }),

      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(settingsApi.endpoints.getCompanySettings.initiate());
        } catch (error) {
          dispatch(pushNotification(makeToastNotification(error)));
        }
      },
    }),
    getHomepageData: builder.mutation<IHomepageSettingsDb, void>({
      query: () => ({ url: '/company-settings/homepage', method: 'GET' }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(setHomepageSettings(data));
          }
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),

    editDomain: builder.mutation<void, { id: string; domain: string }>({
      query: ({ id, domain }) => ({
        url: `/custom-domain/${id}`,
        method: 'PUT',
        body: { domain },
      }),

      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(settingsApi.endpoints.getCompanySettings.initiate());
        } catch (error) {
          dispatch(pushNotification(makeToastNotification(error)));
        }
      },
    }),

    verifyDomain: builder.mutation<void, { domainId: string }>({
      query: ({ domainId }) => ({
        url: `/custom-domain/validate/${domainId}`,
        method: 'POST',
      }),

      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(settingsApi.endpoints.getCompanySettings.initiate());
        } catch (error) {
          return;
        }
      },
    }),

    deleteDomain: builder.mutation<void, { domainId: string }>({
      query: ({ domainId }) => ({
        url: `/custom-domain/${domainId}`,
        method: 'DELETE',
      }),

      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(closePopup());
          dispatch(
            pushNotification(
              makeSuccessToastNotification('Custom domain has been successfully deleted'),
            ),
          );
          dispatch(settingsApi.endpoints.getCompanySettings.initiate());
        } catch (error) {
          dispatch(pushNotification(makeToastNotification(error)));
        }
      },
    }),
    updateHomepageData: builder.mutation<IHomepageSettingsDb, IHomepageSettings>({
      query: (data) => ({
        url: '/company-settings/homepage',
        method: 'PUT',
        body: data,
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(setHomepageSettings(data));
          }
          dispatch(pushNotification(makeSuccessToastNotification('Settings updated!')));
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),

    checkCompanyDetails: builder.mutation<
      { error: PaymentMethodValidationErrors | null },
      { email?: string }
    >({
      query: () => ({ url: '/company-payment/check', method: 'GET' }),
      onQueryStarted: async (params, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;

          if (data) {
            switch (data.error) {
              case PaymentMethodValidationErrors.InvoiceBankDetailsIsNotSet: {
                dispatch(
                  openPopup({
                    popupName: INVOICE_BANK_DETAILS_POPUP,
                    popupProps: {
                      email: params.email,
                      checkPaymentDetails: true,
                    },
                  }),
                );

                return;
              }
              case PaymentMethodValidationErrors.BillingAddressIsNotSet: {
                dispatch(
                  openPopup({
                    popupName: BILLING_ADDRESS_POPUP,
                    popupProps: {
                      email: params.email,
                      checkPaymentDetails: true,
                    },
                  }),
                );

                return;
              }

              default: {
                dispatch(
                  openPopup({
                    popupName: INVOICE_CREATION_CUSTOMER_INFO_POPUP,
                    popupProps: { presentationEmail: params.email },
                  }),
                );
              }
            }
          }
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),
  }),
});

export const {
  useGetCompanyMutation,
  useGetCompanySettingsMutation,
  usePatchCompanyMutation,
  usePatchCompanySettingsMutation,
  useGetBillingAddressMutation,
  usePatchBillingAddressMutation,
  useUploadLogoMutation,
  useGetPayoutMutation,
  usePatchPayoutMutation,
  useGetInvoiceMutation,
  usePatchInvoiceMutation,
  useValidateIntercomAppIdMutation,
  useAddCreditCardMutation,
  useDeleteCreditCardMutation,
  useGetCreditCardsMutation,
  useUploadLogoPreviewMutation,
  useGetWhatWeOfferDataMutation,
  useUpdateWhatWeOfferDataMutation,
  useGetPdfPresentationEditorSettingsMutation,
  useUpdatePdfPresentationEditorSettingsMutation,
  useDownloadToSOrPPPdfMutation,
  useUploadToSOrPPPdfMutation,
  useAddDomainMutation,
  useEditDomainMutation,
  useVerifyDomainMutation,
  useDeleteDomainMutation,
  useGetHomepageDataMutation,
  useUpdateHomepageDataMutation,
  useCheckCompanyDetailsMutation,
} = settingsApi;
