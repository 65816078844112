import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ReducerPath } from 'interfaces/common-api.interface';
import {
  IInvitationBody,
  ITeamMember,
  InvitationStatus,
} from 'interfaces/invitation.interface';
import { makeSuccessToastNotification, makeToastNotification } from 'utils/query.util';
import { setLoading, setInvitation } from 'redux/slices/invitations.slice';
import { pushNotification } from '../slices/notifications.slice';
import { baseQueryParams } from './helpers';

export const invitationApi = createApi({
  reducerPath: ReducerPath.invitations,
  baseQuery: fetchBaseQuery({
    ...baseQueryParams,
  }),
  endpoints: (builder) => ({
    createInvitation: builder.mutation<void, IInvitationBody>({
      query: (payload) => ({
        url: '/invitation',
        method: 'POST',
        body: payload,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          dispatch(setLoading(true));
          await queryFulfilled;
          dispatch(pushNotification(makeSuccessToastNotification('Invitation sent')));
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
          dispatch(setLoading(false));
        }
      },
    }),
    deleteInvitation: builder.mutation<void, ITeamMember>({
      query: (invitation) => ({
        url: `/invitation/${invitation.id}`,
        method: 'DELETE',
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          dispatch(setLoading(true));
          await queryFulfilled;
          if (args.status === InvitationStatus.ACCEPTED) {
            dispatch(pushNotification(makeSuccessToastNotification('User removed.')));
          } else {
            dispatch(
              pushNotification(
                makeSuccessToastNotification('Invitation has been revoked.'),
              ),
            );
          }
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
          dispatch(setLoading(false));
        }
      },
    }),
    resendInvitation: builder.mutation<{ id: string; expiresAt: string }, ITeamMember>({
      query: (invitation) => ({
        url: `/invitation/resend/${invitation.id}`,
        method: 'PUT',
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          dispatch(setLoading(true));
          const { data } = await queryFulfilled;
          dispatch(setInvitation({ ...args, ...data }));
          dispatch(
            pushNotification(makeSuccessToastNotification('Invitation has been resent.')),
          );
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
          dispatch(setLoading(false));
        }
      },
    }),
  }),
});
export const {
  useCreateInvitationMutation,
  useDeleteInvitationMutation,
  useResendInvitationMutation,
} = invitationApi;
