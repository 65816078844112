import { WhiteBox, Typography, Tooltip } from '@ezetech/swag-space-x';
import { useAppSelector } from 'redux/store';
import { can, SUBJECTS } from 'boot/ability';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { isResellerCertificatesFeatureEnabledSelector } from 'redux/selectors/technical-data.selectors';

import {
  AddressForm,
  DetailsForm,
  SocialForm,
  BrandForm,
  PagesForm,
  CustomCode,
  StorePreferencesForm,
  PrivacyPolicy,
  TermsOfService,
  ResellerCertificatesForm,
} from './forms';

import css from './company-and-settings.module.scss';
import { IntercomForm } from './forms/intercom';
import { OrderAssignment } from './forms/order-assignment';

export function CompanyAndBrand(): JSX.Element {
  const isResellerCertificatesFeatureEnabled = useAppSelector(
    isResellerCertificatesFeatureEnabledSelector,
  );

  return (
    <div className={css.root}>
      {can(
        SUBJECTS.COMPANY_DETAILS.actions.COMPANY_DETAILS_VIEW,
        SUBJECTS.COMPANY_DETAILS.value,
      ) && (
        <WhiteBox className={css.section}>
          <Typography
            className={css.header}
            color={COLORS.colorDarkBlue}
            fontType={'HEADING5XL'}
          >
            Your company details
          </Typography>
          <div className={css.underline} />
          <div className={css.content}>
            <DetailsForm />
          </div>
        </WhiteBox>
      )}
      {can(
        SUBJECTS.BILLING_ADDRESS.actions.BILLING_ADDRESS_VIEW,
        SUBJECTS.BILLING_ADDRESS.value,
      ) && (
        <WhiteBox className={css.section}>
          <Typography
            className={css.header}
            color={COLORS.colorDarkBlue}
            fontType={'HEADING5XL'}
          >
            Billing address
          </Typography>
          <div className={css.underline} />
          <div className={css.content}>
            <AddressForm />
          </div>
        </WhiteBox>
      )}
      {can(
        SUBJECTS.SOCIAL_HANDLE.actions.SOCIAL_HANDLE_VIEW,
        SUBJECTS.SOCIAL_HANDLE.value,
      ) && (
        <WhiteBox className={css.section}>
          <Typography
            className={css.header}
            color={COLORS.colorDarkBlue}
            fontType={'HEADING5XL'}
          >
            Social Handle
          </Typography>
          <div className={css.underline} />
          <div className={css.content}>
            <SocialForm />
          </div>
        </WhiteBox>
      )}
      {(can(
        SUBJECTS.STORE_PREFERENCES.actions.HOMEPAGE_TITLE_VIEW,
        SUBJECTS.STORE_PREFERENCES.value,
      ) ||
        can(
          SUBJECTS.STORE_PREFERENCES.actions.LOGO_URL_VIEW,
          SUBJECTS.STORE_PREFERENCES.value,
        ) ||
        can(
          SUBJECTS.STORE_PREFERENCES.actions.STORE_LINK_VIEW,
          SUBJECTS.STORE_PREFERENCES.value,
        )) && (
        <WhiteBox className={css.section}>
          <Typography
            className={css.header}
            color={COLORS.colorDarkBlue}
            fontType={'HEADING5XL'}
          >
            STORE Preferences
          </Typography>
          <div className={css.underline} />
          <div className={css.content}>
            <StorePreferencesForm />
          </div>
        </WhiteBox>
      )}
      {(can(SUBJECTS.BRAND.actions.BRAND_LOGO_VIEW, SUBJECTS.BRAND.value) ||
        can(SUBJECTS.BRAND.actions.STORE_COLORS_VIEW, SUBJECTS.BRAND.value)) && (
        <WhiteBox className={css.section}>
          <Typography
            className={css.header}
            color={COLORS.colorDarkBlue}
            fontType={'HEADING5XL'}
          >
            Brand
          </Typography>
          <div className={css.underline} />
          <div className={css.content}>
            <BrandForm />
          </div>
        </WhiteBox>
      )}
      {(can(
        SUBJECTS.PAGES_POPUPS_PDF_PRESENTATIONS.actions.HOMEPAGE_VIEW,
        SUBJECTS.PAGES_POPUPS_PDF_PRESENTATIONS.value,
      ) ||
        can(
          SUBJECTS.PAGES_POPUPS_PDF_PRESENTATIONS.actions.PDF_PRESENTATION_VIEW,
          SUBJECTS.PAGES_POPUPS_PDF_PRESENTATIONS.value,
        ) ||
        can(
          SUBJECTS.PAGES_POPUPS_PDF_PRESENTATIONS.actions.WHAT_WE_OFFER_PAGE_VIEW,
          SUBJECTS.PAGES_POPUPS_PDF_PRESENTATIONS.value,
        )) && (
        <WhiteBox className={css.section}>
          <Typography
            className={css.header}
            color={COLORS.colorDarkBlue}
            fontType={'HEADING5XL'}
          >
            Pages, Popups & PDF Presentations
            <div>
              <Tooltip>
                <Typography
                  fontType="bodySm"
                  lineHeight="143%"
                  color={COLORS.colorTypographyHeadings}
                >
                  There are various places where we recommend that you add your brand and
                  messaging. We want your brand to shine throughout.
                </Typography>
              </Tooltip>
            </div>
          </Typography>
          <div className={css.underline} />
          <div className={css.content}>
            <PagesForm />
          </div>
        </WhiteBox>
      )}
      {can(SUBJECTS.INTERCOM.actions.INTERCOM_VIEW, SUBJECTS.INTERCOM.value) && (
        <WhiteBox className={css.section}>
          <Typography
            className={css.header}
            color={COLORS.colorDarkBlue}
            fontType={'HEADING5XL'}
          >
            Intercom
          </Typography>
          <div className={css.underline} />
          <div className={css.content}>
            <IntercomForm />
          </div>
        </WhiteBox>
      )}
      {can(
        SUBJECTS.ADD_3RD_PARTY_CODE.actions.ADD_3RD_PARTY_CODE_VIEW,
        SUBJECTS.ADD_3RD_PARTY_CODE.value,
      ) && (
        <WhiteBox className={css.section}>
          <Typography
            className={css.header}
            color={COLORS.colorDarkBlue}
            fontType={'HEADING5XL'}
          >
            Add 3rd party code
          </Typography>
          <div className={css.underline} />
          <div className={css.content}>
            <CustomCode />
          </div>
        </WhiteBox>
      )}
      {can(
        SUBJECTS.RESELLER_CERTIFICATES.actions.RESELLER_CERTIFICATES_VIEW,
        SUBJECTS.RESELLER_CERTIFICATES.value,
      ) && isResellerCertificatesFeatureEnabled ? (
        <WhiteBox className={css.section}>
          <Typography
            className={css.header}
            color={COLORS.colorDarkBlue}
            fontType={'HEADING5XL'}
          >
            Reseller Certificates
          </Typography>
          <div className={css.contentNoMargin}>
            <ResellerCertificatesForm />
          </div>
        </WhiteBox>
      ) : null}
      {can(
        SUBJECTS.ASSIGNMENT_FLOW.actions.EDIT_ASSIGNMENT_FLOW,
        SUBJECTS.ASSIGNMENT_FLOW.value,
      ) && (
        <WhiteBox className={css.section}>
          <Typography
            className={css.header}
            color={COLORS.colorDarkBlue}
            fontType="HEADING5XL"
          >
            Order Assignment
          </Typography>
          <div className={css.underline} />
          <div className={css.content}>
            <Typography
              fontType="bodyMd"
              lineHeight="162.5%"
              color={COLORS.colorPrimaryText}
            >
              Will you be assigning each order that comes in to a different account rep or
              sales associate? If yes - please make sure to enable assignment. When you
              add a team member there will be a permission settings that will allow them
              to assign orders to themselves. If you do not want them to have that ability
              and only you want to assign orders please make sure not to give them that
              level of permissions.
            </Typography>
            <OrderAssignment />
          </div>
        </WhiteBox>
      )}
      {can(
        SUBJECTS.TERMS_OF_SERVICE.actions.TERMS_OF_SERVICE_VIEW,
        SUBJECTS.TERMS_OF_SERVICE.value,
      ) && (
        <WhiteBox className={css.section}>
          <Typography
            className={css.header}
            color={COLORS.colorDarkBlue}
            fontType={'HEADING5XL'}
          >
            Terms of Service
          </Typography>
          <div className={css.underline} />
          <div className={css.content}>
            <TermsOfService />
          </div>
        </WhiteBox>
      )}
      {can(
        SUBJECTS.PRIVACY_POLICY.actions.PRIVACY_POLICY_VIEW,
        SUBJECTS.PRIVACY_POLICY.value,
      ) && (
        <WhiteBox className={css.section}>
          <Typography
            className={css.header}
            color={COLORS.colorDarkBlue}
            fontType={'HEADING5XL'}
          >
            Privacy Policy
          </Typography>
          <div className={css.underline} />
          <div className={css.content}>
            <PrivacyPolicy />
          </div>
        </WhiteBox>
      )}
    </div>
  );
}
