import { FieldValues } from 'react-hook-form';
import { CountriesStatesSelect } from 'constants/settings.constants';

interface IFields {
  [key: string]: {
    name: string;
    placeholder: string;
    label: string;
    component: 'input' | 'select' | 'conditional';
    condition?: (values: FieldValues) => 'input' | 'select';
  };
}

export const FIELDS: IFields = {
  firstName: {
    name: 'firstName',
    placeholder: 'Enter First Name',
    label: 'First Name',
    component: 'input',
  },
  lastName: {
    name: 'lastName',
    placeholder: 'Enter Last Name',
    label: 'Last Name',
    component: 'input',
  },
  street: {
    name: 'street',
    placeholder: 'Enter Street',
    label: 'Street',
    component: 'input',
  },
  unit: {
    name: 'unit',
    placeholder: 'Enter Unit',
    label: 'Unit',
    component: 'input',
  },
  city: {
    name: 'city',
    placeholder: 'Enter City',
    label: 'City',
    component: 'input',
  },
  state: {
    name: 'state',
    placeholder: 'Enter State',
    label: 'State',
    component: 'conditional',
    condition: (values: FieldValues) => {
      const country = values.country;
      if (
        country &&
        (country === CountriesStatesSelect.US || country === CountriesStatesSelect.CA)
      ) {
        return 'select';
      }
      return 'input';
    },
  },
  zip: {
    name: 'zip',
    placeholder: 'Enter ZIP/Post Code',
    label: 'ZIP/Post Code',
    component: 'input',
  },
  country: {
    name: 'country',
    placeholder: 'Enter Country',
    label: 'Country',
    component: 'select',
  },
};
