import {
  CategoriesStructure,
  CategoriesStructureWithSelections,
} from 'interfaces/store-creation.interfaces';

export function transformCategories(
  categories: CategoriesStructure,
): CategoriesStructureWithSelections {
  return categories.map((category) => {
    return {
      isEnabled: true,
      id: category.id,
      name: category.name,
      items: category.items.map((item) => ({
        id: item.id,
        name: item.name,
        isSelected: true,
      })),
    };
  });
}

function getSelectedCategorySlugsMap(categories: CategoriesStructureWithSelections): {
  [key: string]: boolean;
} {
  return (
    categories?.reduce<{ [key: string]: boolean }>((acc, category) => {
      acc[category.id] = !!category?.isEnabled;
      category?.items?.forEach((item) => {
        if (item?.isSelected) {
          acc[item.id] = true;
        }
      });
      return acc;
    }, {}) ?? {}
  );
}

export function transformCategoriesWithSelection(
  categoriesStructure: CategoriesStructure,
  categories: CategoriesStructureWithSelections,
): CategoriesStructureWithSelections {
  return categoriesStructure.map((category) => {
    const selectedCategoriesMap = getSelectedCategorySlugsMap(categories);

    return {
      isEnabled: !!selectedCategoriesMap?.[category.id],
      id: category.id,
      name: category.name,
      items: category.items.map((item) => ({
        id: item.id,
        name: item.name,
        isSelected: !!selectedCategoriesMap?.[item.id],
      })),
    };
  });
}
