import { Input, SwagButton } from '@ezetech/swag-space-x';
import classes from '../review-colors.popup.module.scss';

interface ColorPickerProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleAddColor: () => void;
  value: string;
}

export const ColorPicker = ({ onChange, handleAddColor, value }: ColorPickerProps) => (
  <div className={classes.addColorsBlock}>
    <Input
      label="Add colors"
      placeholder="Input exact pantone color"
      className={classes.input}
      onChange={onChange}
      value={value}
    />
    <SwagButton type="outlined" className={classes.button} onClick={handleAddColor}>
      Add
    </SwagButton>
  </div>
);
