import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFile } from '@ezetech/swag-space-x';
import {
  PdfPresentationEditor,
  PdfPresentationEditorFilter,
  PdfPresentationEditorPageId,
  PdfPresentationEditorReview,
  PdfPresentationEditorTemplate,
  PdfPresentationEditorUpdateBody,
  PdfPresentationImagesOfPages,
} from 'interfaces/pdf-presentation';
import { BRAND_LOGOS_LENGTH, REVIEWS_LENGTH } from 'constants/pdf-presentation.constants';

export interface IPresentationEditorState {
  loading: boolean;
  selectedPage: PdfPresentationEditorPageId;
  filter: PdfPresentationEditorFilter;
  loadedConfig: PdfPresentationEditor;
  editable: PdfPresentationEditorUpdateBody;
  editableDraft: PdfPresentationEditorUpdateBody;
  lastChanges: {
    keyName: string;
    value: unknown;
  };
  pdf: {
    rendering: boolean;
    imagesOfPages: PdfPresentationImagesOfPages[];
  };
}

const initialEditableState: PdfPresentationEditorUpdateBody = {
  id: null,
  template: PdfPresentationEditorTemplate.box,
  brandLogos: Array.from<IFile | null>({ length: BRAND_LOGOS_LENGTH }).fill(null),
  portfolioImage: null,
  productShowcaseImage: null,
  boxOverviewImage: null,
  reviews: Array.from<PdfPresentationEditorReview | null>({
    length: REVIEWS_LENGTH,
  }).fill(null),
};

const initialState: IPresentationEditorState = {
  loading: false,
  selectedPage: PdfPresentationEditorPageId.COVER,
  filter: PdfPresentationEditorFilter.ALL,
  loadedConfig: {
    id: null,
    template: PdfPresentationEditorTemplate.box,
    brandLogos: Array.from<IFile | null>({ length: 16 }).fill(null),
    portfolioImage: null,
    productShowcaseImage: null,
    boxOverviewImage: null,
    reviews: Array.from<PdfPresentationEditorReview | null>({
      length: REVIEWS_LENGTH,
    }).fill(null),
    createdAt: null,
    updatedAt: null,
  },
  editable: { ...initialEditableState },
  editableDraft: { ...initialEditableState },
  lastChanges: {
    keyName: '',
    value: '',
  },
  pdf: {
    rendering: false,
    imagesOfPages: [],
  },
};

export const pdfPresentationEditorSlice = createSlice({
  initialState,
  name: 'pdfPresentationsEditorSlice',
  reducers: {
    reset: (state) => {
      return {
        ...initialState,
        editableDraft: { ...state.editable },
        editable: state.editable,
        pdf: state.pdf,
        lastChanges: state.lastChanges,
      };
    },
    setEditorState: (
      state,
      action: PayloadAction<{
        loadedConfig: PdfPresentationEditor;
        editable: PdfPresentationEditorUpdateBody;
      }>,
    ) => {
      const { loadedConfig, editable } = action.payload;
      state.loadedConfig = loadedConfig;
      state.editable = editable;
      state.editableDraft = { ...editable };
    },
    changeFilter: (state, action: PayloadAction<PdfPresentationEditorFilter>) => {
      state.filter = action.payload;
      switch (action.payload) {
        case PdfPresentationEditorFilter.NON_EDITABLE:
          state.selectedPage = PdfPresentationEditorPageId.BOX_PAGE;
          break;
        default:
          state.selectedPage = PdfPresentationEditorPageId.COVER;
          break;
      }
    },
    changePage: (state, action: PayloadAction<PdfPresentationEditorPageId>) => {
      state.selectedPage = action.payload;
    },
    changeValue: (
      state,
      action: PayloadAction<{
        keyName: keyof PdfPresentationEditorUpdateBody;
        value: unknown;
      }>,
    ) => {
      const { keyName, value } = action.payload;
      state.editableDraft[keyName] = value as never;
    },
    changeDraftAndMainValue: (
      state,
      action: PayloadAction<{
        keyName: keyof PdfPresentationEditorUpdateBody;
        value: unknown;
      }>,
    ) => {
      const { keyName, value } = action.payload;
      state.editable[keyName] = value as never;
      state.editableDraft[keyName] = value as never;
    },
    changeLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setLastChanges: (
      state,
      action: PayloadAction<{
        keyName: string;
        value: unknown;
      }>,
    ) => {
      state.lastChanges = action.payload;
    },
    setPdfImagesOfPages: (
      state,
      action: PayloadAction<PdfPresentationImagesOfPages[]>,
    ) => {
      state.pdf.imagesOfPages = action.payload;
    },
    changePdfRendering: (state, action: PayloadAction<boolean>) => {
      state.pdf.rendering = action.payload;
    },
  },
});

export const pdfPresentationsEditorReducer = pdfPresentationEditorSlice.reducer;
export const {
  reset,
  setEditorState,
  changeFilter,
  changePage,
  changeValue,
  changeDraftAndMainValue,
  changeLoading,
  setPdfImagesOfPages,
  changePdfRendering,
  setLastChanges,
} = pdfPresentationEditorSlice.actions;
