import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  ICustomerOrdersList,
  ReducerPath,
  ISendInvoiceResponse,
  ISendInvoiceRequest,
  ICustomerOrdersRequest,
} from 'interfaces';
import { makeSuccessToastNotification, makeToastNotification } from 'utils/query.util';
import { setFilter } from '../slices/filter.slice';
import { updateOrder } from '../slices/orders.slice';
import { setOrders } from '../slices/customer-details.slice';
import { pushNotification } from '../slices/notifications.slice';
import { closePopup } from '../slices/modals.slice';
import { orderByIdSelector } from '../selectors/orders.selectors';
import { RootState } from '../store';
import { baseQueryParams } from './helpers';

export const orderApi = createApi({
  reducerPath: ReducerPath.order,
  baseQuery: fetchBaseQuery({
    ...baseQueryParams,
  }),
  endpoints: (builder) => ({
    getOrders: builder.mutation<ICustomerOrdersList, ICustomerOrdersRequest>({
      query: (params) => {
        return {
          url: '/order/list/customer',
          method: 'GET',
          params,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const { list, page, perPage, total, statistic } = data;
          dispatch(setOrders({ orders: list, statistic }));
          dispatch(
            setFilter({
              page,
              perPage,
              totalCount: total,
            }),
          );
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),
    sendInvoice: builder.mutation<ISendInvoiceResponse, ISendInvoiceRequest>({
      query: (args) => {
        return {
          url: '/order/send-invoice',
          method: 'POST',
          body: {
            orderId: args.orderId,
            list: args.list,
          },
        };
      },
      async onQueryStarted(args, { dispatch, getState, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const { url, grandTotal, taxes } = data;
          const state = getState();
          const order = orderByIdSelector(state as RootState, { orderId: args.orderId });

          dispatch(
            updateOrder({
              ...order,
              invoiceUrl: url,
              paymentPrices: { ...order.paymentPrices, grandTotal, taxes },
            }),
          );
          dispatch(closePopup());
          dispatch(
            pushNotification(makeSuccessToastNotification('Invoice successfully sent')),
          );
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),
  }),
});

export const { useGetOrdersMutation, useSendInvoiceMutation } = orderApi;
