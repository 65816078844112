import React from 'react';
import {
  Dialog,
  DialogContentWrapper,
  DialogTitle,
  DialogDescription,
  DialogFooter,
  SwagButton,
  Typography,
  Dropzone,
  IFile,
  DialogContent,
} from '@ezetech/swag-space-x';
import { IProofOfTaxExemptUploadPopupProps } from 'interfaces/popup.interface';
import { closePopup } from 'redux/slices/modals.slice';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import {
  MAX_FILE_SIZE_10MB_RESTRICTION,
  RESELLER_TAX_EXEMPT_DOCUMENT_RESTRICTION,
} from 'constants/limits';
import { IS3Document } from 'interfaces/reseller-certificate.interfaces';
import { useAppDispatch } from '../../../redux/store';
import {
  useApplyUserTaxExemptRequestMutation,
  useUploadUserTaxExemptDocumentMutation,
} from '../../../redux/api/user.api';
import css from '../common/popup-with-upload-common.module.scss';

type UploadedDocumentType = Omit<IS3Document, 'size'>;

export const proofOfTaxExemptUploadPopup = ({
  isOpen,
  userId,
  setTaxOption,
}: IProofOfTaxExemptUploadPopupProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const [uploadedFile, setUploadedFile] = React.useState<IFile | null>(null);

  const [uploadUserTaxExemptDocument, { isLoading: isDocumentUploadActive }] =
    useUploadUserTaxExemptDocumentMutation();
  const [applyUserTaxExemptRequest, { isLoading }] =
    useApplyUserTaxExemptRequestMutation();

  const handleProcessFile = async (file: File): Promise<IFile> => {
    const formData = new FormData();
    formData.set('file', file);

    const response = await uploadUserTaxExemptDocument(formData);

    if ('data' in response && response.data) {
      return response.data;
    } else {
      throw new Error('Upload failed');
    }
  };

  const handleChange = (files: IFile[]) => {
    const file = files[0] ?? null;

    setUploadedFile(file as UploadedDocumentType | null);
  };

  const onCloseClick = () => {
    if (isDocumentUploadActive || isLoading) {
      return;
    }
    dispatch(closePopup());
  };

  const onSubmit = async (): Promise<void> => {
    if (!uploadedFile?.url || !userId) {
      return;
    }

    await applyUserTaxExemptRequest({
      userId,
      documentUrl: uploadedFile.url,
      setTaxOption,
    });
  };

  return (
    <Dialog open={isOpen} onOpenChange={onCloseClick}>
      <DialogContentWrapper
        className={css.dialogContentWrapperClassName}
        overlayClassName={css.dialogOverlayClassName}
      >
        <DialogTitle>Proof of Tax Exception</DialogTitle>
        <DialogDescription>
          <Typography
            color={COLORS.colorTextSubdued}
            fontType="bodyMd"
            lineHeight="162.5%"
          >
            Please submit evidence that this customer is tax exempt. This process takes up
            to 3 business days to review.
          </Typography>
        </DialogDescription>
        <DialogContent>
          <Dropzone
            accept={RESELLER_TAX_EXEMPT_DOCUMENT_RESTRICTION}
            maxSize={MAX_FILE_SIZE_10MB_RESTRICTION}
            files={uploadedFile ? [uploadedFile] : []}
            processFile={handleProcessFile}
            onFilesChanged={handleChange}
            additionalText="JPG, PNG or PDF, no more than 10MB"
          />
        </DialogContent>
        <DialogFooter className={css.footerClassName}>
          <SwagButton
            type="outlined"
            onClick={onCloseClick}
            className={css.button}
            dataLabel="closeTaxExemptRequestPopup"
          >
            Cancel
          </SwagButton>
          <SwagButton
            disabled={!uploadedFile}
            type="primary"
            onClick={onSubmit}
            className={css.button}
            dataLabel="submitTaxExemptRequest"
          >
            Submit
          </SwagButton>
        </DialogFooter>
      </DialogContentWrapper>
    </Dialog>
  );
};
