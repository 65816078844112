import {
  Dialog,
  DialogContentWrapper,
  DialogTitle,
  DialogContent,
  DialogFooter,
  SwagButton,
} from '@ezetech/swag-space-x';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { closePopup } from 'redux/slices/modals.slice';
import { clearInvoiceError, clearInvoice } from 'redux/slices/settings.slice';
import {
  useCheckCompanyDetailsMutation,
  usePatchInvoiceMutation,
} from 'redux/api/settings.api';
import {
  isInvoiceValidValidSelector,
  invoiceSelector,
} from 'redux/selectors/settings.selectors';
import { InvoiceForm } from 'pages/settings/plan-and-payment/forms/invoice';
import { InvoiceBankDetailsPopupProps } from 'interfaces/popup.interface';
import { mapInvoiceToDB } from 'utils/settings/mappers';
import { editedCustomerCompanyDetailsSelector } from 'redux/selectors/customer-details.selector';
import { useUpdateUserMutation } from 'redux/api/user.api';
import css from './invoice-bank-details-popup.module.scss';

export const InvoiceBankDetailsPopup = ({
  isOpen,
  paymentMethod,
  setPaymentMethod,
  checkPaymentDetails,
  email,
}: InvoiceBankDetailsPopupProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const values = useAppSelector(invoiceSelector);
  const isFormValid = useAppSelector(isInvoiceValidValidSelector);
  const user = useAppSelector(editedCustomerCompanyDetailsSelector);
  const [updateUser, { isLoading: updateLoading }] = useUpdateUserMutation();
  const [patchInvoice, { isLoading: patchLoading }] = usePatchInvoiceMutation();
  const [checkCompanyDetails] = useCheckCompanyDetailsMutation();
  const isLoading = updateLoading || patchLoading;

  const onCloseClick = () => {
    dispatch(closePopup());
    dispatch(clearInvoiceError());
    dispatch(clearInvoice());
  };
  const onSaveClick = async () => {
    if (!isFormValid || isLoading) {
      return;
    }
    const {
      invoiceBankCountry,
      invoiceBankName,
      invoiceBankAccountNumber,
      invoiceAccountType,
      invoiceAccountBeneficiaryName,
      invoiceBIC,
      invoiceRoutingNumber,
    } = values;
    await patchInvoice(
      mapInvoiceToDB({
        invoiceBankCountry,
        invoiceBankName,
        invoiceAsInPayout: false,
        invoiceBankAccountNumber,
        invoiceAccountType,
        invoiceAccountBeneficiaryName,
        invoiceBIC,
        invoiceRoutingNumber,
      }),
    );
    if (paymentMethod) {
      await updateUser({
        id: user.userId,
        paymentMethod,
        setPaymentMethod,
      });
    }
    if (checkPaymentDetails) {
      checkCompanyDetails({ email });
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onCloseClick}>
      <DialogContentWrapper
        className={css.dialogContent}
        overlayClassName={css.dialogOverlay}
      >
        <DialogTitle>Invoice bank details</DialogTitle>
        <DialogContent className={css.dialogBody}>
          <InvoiceForm withToggle={false} isPopupForm={true} classForm={css.form} />
        </DialogContent>
        <DialogFooter showDivider={false} className={css.footer}>
          <SwagButton className={css.button} type="outlined" onClick={onCloseClick}>
            Cancel
          </SwagButton>
          <SwagButton
            className={css.button}
            loading={isLoading}
            disabled={!isFormValid || isLoading}
            type="primary"
            onClick={onSaveClick}
          >
            Save changes
          </SwagButton>
        </DialogFooter>
      </DialogContentWrapper>
    </Dialog>
  );
};
