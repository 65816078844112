import { canCombined, CAN_COMBINED_NAMES } from 'boot/ability';

export const isShowReferLink = () => canCombined(CAN_COMBINED_NAMES.REFER_A_MEMBER_PAGE);

export const isShowSettingsLink = () =>
  canCombined(CAN_COMBINED_NAMES.SETTINGS_COMPANY_AND_BRAND_PAGE) ||
  canCombined(CAN_COMBINED_NAMES.SETTINGS_BUSINESS_HOURS_PAGE) ||
  canCombined(CAN_COMBINED_NAMES.SETTINGS_PAYMENT_PAGE) ||
  canCombined(CAN_COMBINED_NAMES.SETTINGS_PRODUCTS_AND_COMMISSION_PAGE) ||
  canCombined(CAN_COMBINED_NAMES.SETTINGS_USER_AND_PERMISSIONS_PAGE);
