import { FC } from 'react';
import classNames from 'classnames';
import { SwagButton, Tooltip, Typography } from '@ezetech/swag-space-x';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { useAppSelector } from '../../redux/store';
import { adminLoggedInAsResellerSelector } from '../../redux/selectors/user.selectors';
import css from './save-tooltip.module.scss';
import { ISaveTooltipProps } from './save-tooltip.interface';

export const SaveTooltip: FC<ISaveTooltipProps> = ({
  isShown,
  isDisabled,
  isLoading,
  onSave,
  onDiscard,
  disabledTooltipText = 'There are validation errors on the page',
}) => {
  const adminLoggedInAsReseller = useAppSelector(adminLoggedInAsResellerSelector);
  const handleSave = () => {
    onSave();
  };

  return (
    <div
      className={classNames(css.root, {
        [css.rootShown]: isShown,
        [css.frameExists]: adminLoggedInAsReseller,
      })}
    >
      <Typography color={COLORS.colorWhite} fontType="HEADING6XL">
        Unsaved changes
      </Typography>
      <div className={classNames(css.buttons)}>
        <SwagButton
          textColor={COLORS.colorWhite}
          className={css.discardButton}
          type="outlined"
          size="medium"
          onClick={onDiscard}
        >
          Discard
        </SwagButton>

        <Tooltip
          disabled={!isDisabled}
          bodyClassName={css.titleClassName}
          content={
            <SwagButton
              loading={isLoading}
              onClick={handleSave}
              type="primary"
              size="medium"
              disabled={isDisabled}
            >
              Save Changes
            </SwagButton>
          }
        >
          <Typography
            lineHeight="162%"
            fontType="bodyMd"
            color={COLORS.colorTypographyBody}
          >
            {disabledTooltipText}
          </Typography>
        </Tooltip>
      </div>
    </div>
  );
};
