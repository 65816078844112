import { useEffect, useState } from 'react';

import { isMobile } from 'utils/ui.util';

export const useIsMobileView = (breakPoint: number): boolean => {
  const [isMobileView, setIsMobileView] = useState<boolean>(isMobile(breakPoint - 1));

  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [isMobileView]);

  const onResize = () => {
    if (isMobileView && window.innerWidth > breakPoint - 1) {
      return setIsMobileView(false);
    }

    if (!isMobileView && window.innerWidth < breakPoint) {
      return setIsMobileView(true);
    }
  };

  return isMobileView;
};
