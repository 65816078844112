import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IPresentation } from 'interfaces/pdf-presentation';
import { IAssignedForReseller } from 'interfaces/assignee.interface';

interface IPresentationState {
  presentations: IPresentation[];
  total: number;
}
const initialState: IPresentationState = {
  presentations: [],
  total: 0,
};

export const cartsSlice = createSlice({
  initialState,
  name: 'cartsSlice',
  reducers: {
    reset: () => initialState,
    setPresentations: (
      state,
      action: PayloadAction<{
        presentations: IPresentationState['presentations'];
        total: IPresentationState['total'];
      }>,
    ) => {
      if (!action.payload) {
        return state;
      }

      state.presentations = action.payload.presentations;
      state.total = action.payload.total;
    },

    updatePresentation: (
      state,
      action: PayloadAction<{
        prebuiltCartId: string;
        id: string;
      }>,
    ) => {
      if (!action.payload) {
        return state;
      }

      state.presentations = state.presentations.map((presentation) => {
        if (presentation.id === action.payload.id) {
          return {
            ...presentation,
            prebuiltCartId: action.payload.prebuiltCartId,
          };
        }

        return presentation;
      });

      return state;
    },
    setAssigneeToPresentationById: (
      state,
      action: PayloadAction<{ entityId: string; assignee: IAssignedForReseller | null }>,
    ) => {
      const { entityId, assignee } = action.payload;

      const index = state.presentations.findIndex(
        (presentation) => presentation.id === entityId,
      );

      if (index !== -1) {
        state.presentations[index].assignee = assignee;
      }
    },
  },
});

export const presentationsReducer = cartsSlice.reducer;
export const {
  reset,
  setPresentations,
  updatePresentation,
  setAssigneeToPresentationById,
} = cartsSlice.actions;
