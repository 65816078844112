import { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContentWrapper,
  DialogTitle,
  DialogDescription,
  DialogContent,
  DialogFooter,
  SwagButton,
  Typography,
} from '@ezetech/swag-space-x';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { reset } from 'redux/slices/products.slice';
import { useHideProductsMutation } from 'redux/api/products.api';
import {
  hideDataRequestSelector,
  isLoadingProductsSelector,
} from 'redux/selectors/products.selectors';
import { closePopup } from 'redux/slices/modals.slice';
import { cannot, SUBJECTS } from 'boot/ability';

import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { ProductsFilter } from './products-filter';
import { ProductsList } from './products-list';
import css from './hide-products-popup.module.scss';

export const HideProductsPopup = ({ isOpen }: { isOpen?: boolean }): JSX.Element => {
  const dispatch = useAppDispatch();
  const [hideProducts] = useHideProductsMutation();
  const hideDataRequest = useAppSelector(hideDataRequestSelector);
  const isLoading = useAppSelector(isLoadingProductsSelector);
  const [forceScrollTop, setForceScrollTop] = useState<number | undefined>();

  const isSaveButtonHidden = cannot(
    SUBJECTS.COMMISSION.actions.HIDE_PRODUCTS_FROM_THE_SITE_EDIT,
    SUBJECTS.COMMISSION.value,
  );

  useEffect(() => {
    if (forceScrollTop !== undefined) {
      setForceScrollTop(undefined);
    }
  }, [forceScrollTop]);

  const onCloseClick = () => {
    if (isLoading) {
      return;
    }

    dispatch(closePopup());
    dispatch(reset());
  };
  const onSaveClick = () => {
    hideProducts(hideDataRequest);
  };

  return (
    <Dialog open={isOpen} onOpenChange={onCloseClick}>
      <DialogContentWrapper
        className={css.dialogContent}
        overlayClassName={css.dialogOverlay}
      >
        <DialogTitle>Hide products from site</DialogTitle>
        <DialogDescription className={css.dialogDescription}>
          <Typography
            lineHeight="162.5%"
            color={COLORS.colorTextSubdued}
            fontType="bodyMd"
          >
            Please select the products you would like to hide on your website.
          </Typography>
        </DialogDescription>
        <DialogContent className={css.dialogBody}>
          <ProductsFilter resetListPosition={setForceScrollTop} />
          <ProductsList scrollTop={forceScrollTop} />
        </DialogContent>
        <DialogFooter showDivider={false} className={css.footer}>
          <SwagButton type="outlined" onClick={onCloseClick}>
            Cancel
          </SwagButton>
          {!isSaveButtonHidden && (
            <SwagButton type="primary" onClick={onSaveClick}>
              Save changes
            </SwagButton>
          )}
        </DialogFooter>
      </DialogContentWrapper>
    </Dialog>
  );
};
