import { useAppSelector } from 'redux/store';
import { PageContent } from 'components/page-content';
import { customerCompanyNameSelector } from 'redux/selectors/customer-company.selectors';
import { SUBJECTS } from 'constants/ability.constant';
import { can } from 'boot/ability';
import { Statistic } from './components/statistic';
import { Users } from './components/users';
import { Orders } from './components/orders';

import css from './customer-company.module.scss';

export const CustomerCompany = () => {
  const name = useAppSelector(customerCompanyNameSelector);
  const viewOrdersAccess = can(
    SUBJECTS.VIEW_ORDERS.actions.VIEW_ALL_ORDERS,
    SUBJECTS.VIEW_ORDERS.value,
  );

  return (
    <PageContent childrenClassName={css.container} heading={name}>
      <Statistic />
      <Users />
      {viewOrdersAccess && <Orders />}
    </PageContent>
  );
};
