import { CellSize } from '@ezetech/swag-space-x';

export enum PromocodeTypeEnum {
  DISPOSABLE = 'DISPOSABLE',
  REUSABLE = 'REUSABLE',
  ONE_TIME_PER_ACCOUNT = 'ONE_TIME_PER_ACCOUNT',
}

export enum PromocodeMeasurementEnum {
  ABSOLUTE = 'absolute',
  PERCENTAGES = 'percentages',
}

export const PROMOCODE_TYPES = [
  { value: PromocodeTypeEnum.DISPOSABLE, label: 'Type: Disposable' },
  { value: PromocodeTypeEnum.REUSABLE, label: 'Type: Reusable' },
  { value: PromocodeTypeEnum.ONE_TIME_PER_ACCOUNT, label: 'Type: One Time Per Account' },
];

export const PROMOCODE_TYPES_LABELS = {
  [PromocodeTypeEnum.DISPOSABLE]: 'Disposable',
  [PromocodeTypeEnum.REUSABLE]: 'Reusable',
  [PromocodeTypeEnum.ONE_TIME_PER_ACCOUNT]: 'One Time Per Account',
};

export const MEASUREMENTS = [
  { value: PromocodeMeasurementEnum.PERCENTAGES, label: '%' },
  { value: PromocodeMeasurementEnum.ABSOLUTE, label: '$' },
];

const ADDITIONAL_DISCOUNT_SHIPPING = 'Free Shipping';
const ADDITIONAL_DISCOUNT_SETUP_FEE = 'Free Setup Fee';

export const IS_FREE_SHIPPING = 'freeShipping';
export const IS_FREE_SETUP_FEE = 'freeSetupFee';

export const ADDITIONAL_DISCOUNTS = [
  {
    value: ADDITIONAL_DISCOUNT_SHIPPING,
    isSelected: false,
    type: IS_FREE_SHIPPING,
  },
  {
    value: ADDITIONAL_DISCOUNT_SETUP_FEE,
    isSelected: false,
    type: IS_FREE_SETUP_FEE,
  },
];

export const ADDITIONAL_DISCOUNT_TYPES = {
  [IS_FREE_SHIPPING]: IS_FREE_SHIPPING,
  [IS_FREE_SETUP_FEE]: IS_FREE_SETUP_FEE,
};

export const FIELDS = {
  name: {
    label: 'Promocode Name',
    name: 'code',
    placeholder: 'Enter your promocode name',
  },
  measurement: {
    label: 'Percentage or Dollar Amount',
    name: 'measurement',
    placeholder: 'Select',
    options: MEASUREMENTS,
  },
  discountAbsolute: {
    label: 'Discount Amount',
    name: 'Value',
    placeholder: 'Discount Amount',
  },
  discountPercent: {
    label: 'Discount Percent',
    name: 'Value',
    placeholder: 'Discount Percent',
  },
  type: {
    label: 'Type',
    name: 'type',
    placeholder: 'Select',
    options: PROMOCODE_TYPES,
  },
  addition: {
    label: 'Enable Additional Discounts (optional)',
    name: 'additionDiscounts',
    placeholder: 'Enable Additional Discounts (optional)',
    items: ADDITIONAL_DISCOUNTS,
  },
};

export enum DB_FIELDS {
  timesUsed = 'timesUsed',
}

export const PROMOCODES_TABLE_HEADER = [
  { text: 'Promocode', id: FIELDS.name.name, size: CellSize.unset },
  { text: 'Discount', id: FIELDS.discountPercent.name, size: CellSize.unset },
  { text: 'Used', id: DB_FIELDS.timesUsed, size: CellSize.compact },
  { text: 'Type', id: FIELDS.type.name, size: CellSize.unset },
  { text: 'Free Shipping', id: IS_FREE_SHIPPING, size: CellSize.small },
  { text: 'Free Setup', id: IS_FREE_SETUP_FEE, size: CellSize.small },
];

export const CREDIT_CARD_ABSENT = 'CREDIT_CARD_ABSENT';
