import {
  CONFIRM_ACTION_EXPIRED,
  CONFIRM_ACTION_USED,
  EXPIRED_BY_TIME_ERROR_DESCRIPTION,
  EXPIRED_BY_TIME_ERROR_TITLE,
  ONE_TIME_LINK_ERROR_DESCRIPTION,
  ONE_TIME_LINK_ERROR_TITLE,
} from './password-recovery.constants';

export interface ITitles {
  title: string;
  description: string;
}

export const getTitleAndDescription = (msg: string): ITitles => {
  if (msg === CONFIRM_ACTION_EXPIRED) {
    return {
      title: EXPIRED_BY_TIME_ERROR_TITLE,
      description: EXPIRED_BY_TIME_ERROR_DESCRIPTION,
    };
  }
  if (msg === CONFIRM_ACTION_USED) {
    return {
      title: ONE_TIME_LINK_ERROR_TITLE,
      description: ONE_TIME_LINK_ERROR_DESCRIPTION,
    };
  }
  return {
    title: '',
    description: '',
  };
};
