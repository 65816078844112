import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPromocodeDb } from 'interfaces';

interface IPromocodeState {
  list: IPromocodeDb[];
}

const initialState: IPromocodeState = {
  list: [],
};

export const promocodesSlice = createSlice({
  initialState,
  name: 'promocodesSlice',
  reducers: {
    setPromocodes: (state, action: PayloadAction<IPromocodeDb[]>) => {
      state.list = action.payload;
    },
  },
});

export const promocodesReducer = promocodesSlice.reducer;
export const { setPromocodes } = promocodesSlice.actions;
