import { SwagButton } from '@ezetech/swag-space-x';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import cn from 'classnames';
import { Header } from 'components/header';
import { StarsIcon } from 'components/svg';
import { IntercomChat } from 'components/intercom-chat';
import { useAppSelector } from 'redux/store';
import { adminLoggedInAsResellerSelector } from 'redux/selectors/user.selectors';
import { resellerSignContractSelector } from 'redux/selectors/store-creation.selectors';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { SignContract } from 'pages/store-creation/sign-contract';
import { ROUTES } from 'constants/router';
import ring1 from '../../assets/images/rectangle-54.png';
import ring2 from '../../assets/images/rectangle-55.png';
import ring3 from '../../assets/images/rectangle-58.png';
import css from './sign-agreement.module.scss';

export const SignSkippedContract = () => {
  const navigate = useNavigate();
  const isSignedContract = useAppSelector(resellerSignContractSelector);
  const adminLoggedInAsReseller = useAppSelector(adminLoggedInAsResellerSelector);
  const [redirectUrl, setRedirectUrl] = useState('');

  const handleFinishClick = () => {
    if (!redirectUrl) {
      navigate(-1);
    } else {
      window.location.href = redirectUrl;
    }
  };

  useEffect(() => {
    if (location?.search?.includes('originUrl')) {
      navigate(ROUTES.SIGN_AGREEMENT);
      setRedirectUrl(location.search.replace('?originUrl=', ''));
    }
  }, []);

  return (
    <div className={css.root}>
      <StarsIcon className={css.stars1} />
      <StarsIcon
        className={css.stars2}
        fillColor={COLORS.colorPrimaryBlue}
        opacity="0.2"
      />
      <img src={ring1} className={css.ring1} alt="ring 1" />
      <img src={ring2} className={css.ring2} alt="ring 2" />
      <img src={ring3} className={css.ring3} alt="ring 3" />
      <Header
        headerWrapperClassName={css.headerWrapperClassName}
        headerClassName={css.headerClassName}
      />
      <div className={css.onboardContentWrapper}>
        <div className={css.onboardContent}>
          <div className={css.outletWrapper}>
            <SignContract isOnboardingFlow={false} redirectUrl={redirectUrl} />
          </div>
        </div>
      </div>

      <footer className={cn(css.footer, { [css.footerIndent]: adminLoggedInAsReseller })}>
        <div className={css.footerContent}>
          <SwagButton
            onClick={handleFinishClick}
            type="primary"
            className={css.finishBtn}
            disabled={!isSignedContract}
          >
            Finish
          </SwagButton>
        </div>
      </footer>
      <IntercomChat />
    </div>
  );
};
