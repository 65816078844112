import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'redux/store';
import { ROUTES } from 'constants/router';
import { STORE_CREATION_ROUTES } from 'enums/store-creation.enum';
import { getStepIndex } from 'utils/get-current-step-index.util';
import { setCurrentIndex } from 'redux/slices/store-creation.slice';
import { ACTION_TOKEN_TYPES } from 'constants/action-tokens.constant';
import {
  isOnboardingFlowCompletedSelector,
  uncompletedStepIndexSelector,
  uncompletedStepSelector,
} from '../redux/selectors/store-creation.selectors';
import { useAppSelector } from '../redux/store';

import {
  loginRedirectUrlSelector,
  logoutExecutedSelector,
} from '../redux/selectors/user.selectors';
import { useAuth } from './auth.hook';

export function useCheckRedirects() {
  const navigate = useNavigate();
  const location = useLocation();
  const isOnboardingFlowCompleted = useAppSelector(isOnboardingFlowCompletedSelector);
  const uncompletedStep = useAppSelector(uncompletedStepSelector);
  const uncompletedStepIndex = useAppSelector(uncompletedStepIndexSelector);
  const loginRedirectUrl = useAppSelector(loginRedirectUrlSelector);
  const logoutExecuted = useAppSelector(logoutExecutedSelector);

  const currentRoute = location.pathname;
  const currentStepRouteIndex = getStepIndex(currentRoute);

  const { isAuthorized } = useAuth();
  const dispatch = useAppDispatch();

  const isOnboardingRoute = () =>
    Object.values(STORE_CREATION_ROUTES).includes(currentRoute as STORE_CREATION_ROUTES);

  const isUncompletedOnboarding = () =>
    isAuthorized &&
    !isOnboardingFlowCompleted &&
    (uncompletedStepIndex <= currentStepRouteIndex || !isOnboardingRoute());

  const isCompletedOnboarding = () =>
    isAuthorized && isOnboardingFlowCompleted && isOnboardingRoute();

  const isLoginSignupPages = () =>
    isAuthorized && (currentRoute === ROUTES.LOGIN || currentRoute === ROUTES.SIGNUP);

  const isLoginByActionToken = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const action = urlParams.get('action')!;

    return [
      ACTION_TOKEN_TYPES.COLORS_REVIEW_BY_RESELLER,
      ACTION_TOKEN_TYPES.MOCKUP_APPROVAL_BY_RESELLER,
    ].includes(action);
  };

  useEffect(() => {
    if (isUncompletedOnboarding() && uncompletedStep) {
      dispatch(setCurrentIndex({ currentStepIndex: uncompletedStepIndex }));
      navigate(uncompletedStep);
    } else if (
      (isLoginSignupPages() || isCompletedOnboarding()) &&
      !isLoginByActionToken()
    ) {
      const useRedirect = !logoutExecuted && loginRedirectUrl;
      navigate(useRedirect ? loginRedirectUrl : ROUTES.INDEX);
    } else if (isAuthorized && isLoginByActionToken()) {
      navigate(ROUTES.MY_ORDERS);
    }
  }, [
    isOnboardingFlowCompleted,
    uncompletedStep,
    uncompletedStepIndex,
    isAuthorized,
    currentRoute,
    currentStepRouteIndex,
  ]);
}
