type GetOperationTimezoneResult = {
  label: string;
  value: string;
}[];

type TimezoneItem = {
  time: number;
  area: string;
  place: string;
  GMT: string;
  isGMT: boolean;
  timeZone: string;
};

export const getCurrentTimezone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

export function getTimezoneOptions(): GetOperationTimezoneResult {
  const currentTimezone = getCurrentTimezone();
  const sortedTimezoneList = getSortedTimezoneList(currentTimezone);
  return sortedTimezoneList.map((item) => ({
    label: `(${item.GMT}) ${item.place}`,
    value: item.timeZone,
  }));
}

const getTimezoneInfo = (timeZone: string): TimezoneItem => {
  const date = new Date().toLocaleString('en-US', {
    timeZone,
    timeZoneName: 'longOffset',
  });
  const splitDate = date.split(' ');
  const GMT = splitDate[3] === 'GMT' ? 'GMT+00:00' : splitDate[3];
  const [area, placeRaw, placePlus] = timeZone.split('/');
  let place = '';

  if (typeof placeRaw === 'string' && !placeRaw.startsWith('GMT')) {
    place =
      placeRaw.replace(/_/g, ' ') +
      (placePlus?.replace ? `/${placePlus.replace(/_/g, ' ')}` : '');
  }
  return {
    time: new Date(date).getTime(),
    area,
    place,
    GMT,
    isGMT: GMT.includes('GMT'),
    timeZone,
  };
};

const getSortedTimezoneList = (currentTimezone: string): TimezoneItem[] => {
  const timeZones = Intl.supportedValuesOf('timeZone');
  const toTop: TimezoneItem[] = [];
  const result = timeZones.reduce((acc: TimezoneItem[], timeZone: string) => {
    const item = getTimezoneInfo(timeZone);
    if (!item.isGMT || !item.place) {
      return acc;
    }
    if (currentTimezone === timeZone) {
      toTop.push({
        ...item,
        timeZone,
      });
      return acc;
    }
    acc.push({
      ...item,
      timeZone,
    });
    return acc;
  }, []);

  result.sort((a: TimezoneItem, b: TimezoneItem) => {
    if (a.place < b.place) {
      return -1;
    }
    if (a.place > b.place) {
      return 1;
    }
    return 0;
  });
  return [...toTop, ...result];
};
