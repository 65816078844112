import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  storePrivacyPolicyHTMLSelector,
  storeInitialPrivacyPolicyHTMLSelector,
} from 'redux/selectors/settings.selectors';
import { TOS_PP_TYPE } from 'constants/settings.constants';
import { setPrivacyPolicyHTML } from 'redux/slices/settings.slice';
import { useAppDispatch } from 'redux/store';
import { cannot, SUBJECTS } from 'boot/ability';

import { ToSPP } from '../tos-pp';

export function PrivacyPolicy(): JSX.Element {
  const dispatch = useAppDispatch();
  const html = useSelector(storePrivacyPolicyHTMLSelector);
  const initHtml = useSelector(storeInitialPrivacyPolicyHTMLSelector);
  const isEditDisabled = cannot(
    SUBJECTS.PRIVACY_POLICY.actions.PRIVACY_POLICY_EDIT,
    SUBJECTS.PRIVACY_POLICY.value,
  );
  const isDownloadHidden = cannot(
    SUBJECTS.PRIVACY_POLICY.actions.PRIVACY_POLICY_DOWNLOAD,
    SUBJECTS.PRIVACY_POLICY.value,
  );
  const isUploadHidden = cannot(
    SUBJECTS.PRIVACY_POLICY.actions.PRIVACY_POLICY_UPLOAD,
    SUBJECTS.PRIVACY_POLICY.value,
  );

  const onChange = useCallback((updatedHtml: string) => {
    dispatch(setPrivacyPolicyHTML(updatedHtml));
  }, []);

  return (
    <ToSPP
      isEditDisabled={isEditDisabled}
      isDownloadHidden={isDownloadHidden}
      isUploadHidden={isUploadHidden}
      title="Privacy Policy"
      type={TOS_PP_TYPE.PrivacyPolicy}
      onChange={onChange}
      html={html}
      initHtml={initHtml}
    >
      We are not lawyers and we recommend you have your legal team review this. Here is
      your Privacy Policy. Feel free to update this to your liking at any time. This is
      the Privacy Policy that will display in the footer of your site.
    </ToSPP>
  );
}
