import dateFormat from 'dateformat';
import {
  LeadFields,
  LEADS_TABLE_ROW,
  LEADS_INSTANT_QUOTE_TABLE_ROW,
  ILead,
  ICustomer,
  LeadsTableHeaderType,
} from 'interfaces/user-api.interface';

export const STATUS = {
  NEW: 'NEW',
  COMPLETED: 'COMPLETED',
};

const STATUS_LABELS = {
  [STATUS.NEW]: 'New',
  [STATUS.COMPLETED]: 'Completed',
};

export const statusOptions = [
  { value: STATUS.NEW, label: STATUS_LABELS[STATUS.NEW] },
  { value: STATUS.COMPLETED, label: STATUS_LABELS[STATUS.COMPLETED] },
];

export const createUserName = (
  item: Pick<ILead, 'firstName' | 'lastName'> | Pick<ICustomer, 'firstName' | 'lastName'>,
): string => {
  return item.firstName || item.lastName
    ? `${item.firstName || ''} ${item.lastName || ''}`.trim()
    : 'n/a';
};

export const makeWhatWeOfferCells = (
  item: ILead,
  position: number,
  startIndexingFrom = 0,
  header: LeadsTableHeaderType[],
) => {
  const cells = header.map((cell) => {
    const assignedToUser = item?.assignee
      ? {
          value: item.assignee.id,
          label: `${item?.assignee.firstName} ${item?.assignee.lastName}`,
        }
      : null;

    if (cell.id === LeadFields.customerName) {
      return mergeCellParams(
        {
          text: createUserName(item),
          id: item.id,
        },
        cell,
      );
    }
    if (cell.id === LeadFields.companyName) {
      return mergeCellParams(
        {
          text: item.companyName || 'n/a',
          id: item.id,
        },
        cell,
      );
    }
    if (cell.id === LeadFields.id) {
      return mergeCellParams(
        {
          text: `#${position + startIndexingFrom + 1}`,
          id: item.id,
        },
        cell,
      );
    }
    if (cell.id === LeadFields.date) {
      const date = dateFormat(item.createdAt, 'mmm d');
      return mergeCellParams(
        {
          text: date,
          id: item.id,
        },
        cell,
      );
    }
    if (cell.id === LeadFields.assignTo) {
      return { text: '', id: cell.id, rowId: item.id, assignedToUser };
    }
    if (cell.id === LeadFields.status) {
      const value = String(item[cell.id]);

      return {
        text: value,
        id: cell.id,
        rowId: item.id,
        status: { value, label: STATUS_LABELS[value] },
      };
    }
    return mergeCellParams(
      {
        text: item[cell.id] ? String(item[cell.id]) : 'n/a',
        id: item.id,
      },
      cell,
    );
  });
  return cells;
};

export const mergeCellParams = (
  param: { id: string; text: string },
  cell: LeadsTableHeaderType,
  tableRow:
    | typeof LEADS_TABLE_ROW
    | typeof LEADS_INSTANT_QUOTE_TABLE_ROW = LEADS_TABLE_ROW,
) => {
  const common = tableRow[cell.id] || {};
  const cellParams = { ...common, rowId: param.id };
  return {
    ...cellParams,
    text: param.text,
    id: cell.id,
  };
};
