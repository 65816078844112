import React from 'react';
import cn from 'classnames';
import { Typography, SwagButton } from '@ezetech/swag-space-x';
import COLORS from 'constants/styles/colors-js.constant.module.scss';

import css from './pages-card.module.scss';

type PagesCardProps = {
  title: string;
  imgSrc: string;
  imgAlt: string;
  buttonLabel: string;
  onButtonClick: () => void;
  className?: string;
  toggle?: React.ReactNode;
};

export const PagesCard = (props: PagesCardProps) => {
  const { title, imgSrc, imgAlt, buttonLabel, onButtonClick, className, toggle } = props;
  return (
    <div className={cn(css.card, className)}>
      <Typography color={COLORS.colorPrimaryText} fontType={'bodyMd'}>
        {title}
      </Typography>
      <img className={css.image} src={imgSrc} alt={imgAlt} />
      <SwagButton dataLabel={title} size="large" type="outlined" onClick={onButtonClick}>
        {buttonLabel}
      </SwagButton>

      {toggle}
    </div>
  );
};
