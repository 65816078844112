import { OptionType } from '@ezetech/swag-space-x';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { MultiValue } from 'react-select';
import { ReducerPath } from 'interfaces';
import {
  IGetAbandonedCheckoutsCartsRequest,
  IGetAbandonedCheckoutsCartsResponse,
  IUpdateAbandonedStatus,
  AbandonedCheckoutsCartStatusEnum,
} from 'interfaces/abandoned-checkouts-cart.interfaces';
import { makeToastNotification } from 'utils/query.util';
import { setFilter } from 'redux/slices/filter.slice';
import {
  setAbandonedCheckoutsCarts,
  setStatusToCart,
} from 'redux/slices/abandoned-checkouts-carts.slice';
import { ASSIGNED_TO_ME_ENTITY } from 'constants/common';

import { pushNotification } from '../slices/notifications.slice';
import { baseQueryParams } from './helpers';

export const abandonedCheckoutsCartsApi = createApi({
  reducerPath: ReducerPath.abandonedCheckoutsCart,
  baseQuery: fetchBaseQuery({ ...baseQueryParams }),
  endpoints: (builder) => ({
    getAbandonedCheckoutCarts: builder.mutation<
      IGetAbandonedCheckoutsCartsResponse,
      IGetAbandonedCheckoutsCartsRequest
    >({
      query: ({ dropdown, ...rest }) => {
        const filter: Record<string, string[]> = {};

        (dropdown as MultiValue<OptionType>).forEach(({ value }) => {
          if (
            value === AbandonedCheckoutsCartStatusEnum.NEW ||
            value === AbandonedCheckoutsCartStatusEnum.COMPLETED
          ) {
            filter.status = [...(filter.status || []), value] || [];
          }

          if (value === ASSIGNED_TO_ME_ENTITY) {
            filter.assignedToMeEntity = [ASSIGNED_TO_ME_ENTITY];
          }
        });

        return {
          url: '/cart/abandoned-checkouts',
          method: 'GET',
          params: {
            ...rest,
            filter: JSON.stringify(filter),
          },
        };
      },

      async onQueryStarted(
        { dropdown, search, sorting, sortingOrder },
        { dispatch, queryFulfilled },
      ) {
        try {
          const { data } = await queryFulfilled;

          if (data) {
            const { carts, total, page, perPage } = data;

            dispatch(setAbandonedCheckoutsCarts({ carts, total }));
            dispatch(
              setFilter({
                page,
                perPage,
                dropdown,
                search: search || '',
                sorting,
                sortingOrder,
                totalCount: total,
              }),
            );
          }
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),

    updateAbandonedStatus: builder.mutation<void, IUpdateAbandonedStatus>({
      query: ({ id, ...rest }) => ({
        url: `/cart/abandoned-checkouts/${id}/status`,
        method: 'PATCH',
        body: { ...rest },
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(setStatusToCart(args));
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),
  }),
});

export const { useGetAbandonedCheckoutCartsMutation, useUpdateAbandonedStatusMutation } =
  abandonedCheckoutsCartsApi;
