import classNames from 'classnames';
import { Checkbox, Divider, Typography } from '@ezetech/swag-space-x';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { checkShouldShowConfirmationOnChange } from 'utils/permission.util';

import { useAppSelector, useAppDispatch } from 'redux/store';
import {
  permissionsInvitationsSelector,
  assignedEntitiesSelector,
} from 'redux/selectors/invitations.selector';
import { assignmentFlowEnabledSelector } from 'redux/selectors/settings.selectors';
import { setPermissionValue } from 'redux/slices/invitations.slice';
import { openPopup, closePopup } from 'redux/slices/modals.slice';

import { CONFIRMATION_POPUP } from '../../../../components/popups/_logic/popups-list';
import classes from '../../team-member-details.module.scss';

const popupProps = {
  title:
    'Hey, you are going to remove a permission from a team member who has some assignments.',
  descriptionComponent:
    'After saving permissions updates for this team member their assignments will become unassigned.',
  confirmText: 'Ok',
  confirmButtonStyle: 'danger',
};

export const Permissions = ({ isEditable }: { isEditable: boolean }) => {
  const dispatch = useAppDispatch();
  const permissions = useAppSelector(permissionsInvitationsSelector);
  const assignedEntities = useAppSelector(assignedEntitiesSelector);
  const assignmentFlowEnabled = useAppSelector(assignmentFlowEnabledSelector);

  const handlePermissionChange = ({
    group,
    permission,
    value,
  }: {
    group: string;
    permission: string;
    value: boolean;
  }) => {
    if (
      assignmentFlowEnabled &&
      checkShouldShowConfirmationOnChange({
        value,
        permission,
        assignedEntities,
      })
    ) {
      dispatch(
        openPopup({
          popupName: CONFIRMATION_POPUP,
          popupProps: {
            ...popupProps,
            onConfirm: () => {
              dispatch(setPermissionValue({ group, permission, value }));
              dispatch(closePopup());
            },
            onCancel: () => dispatch(closePopup()),
          },
        }),
      );
    } else {
      dispatch(setPermissionValue({ group, permission, value }));
    }
  };

  return (
    <>
      {Object.entries(permissions).map(([key, value], index) => (
        <div key={key} className={classes.permissionContainer}>
          <Typography
            fontType="bodyMdBold"
            color={COLORS.colorText900}
            lineHeight="162.5%"
          >
            {value.title}
          </Typography>
          <div
            className={classNames(classes.permissions, {
              [classes.permissionsLast]: index === Object.keys(permissions).length - 1,
            })}
          >
            {Object.entries(value.permissions).map(([permissionKey, permissionValue]) => (
              <Checkbox
                key={permissionKey}
                name={permissionKey}
                label={permissionValue.title}
                checked={permissionValue.value}
                disabled={!isEditable}
                onCheckedChange={(checked: boolean) =>
                  handlePermissionChange({
                    group: key,
                    permission: permissionKey,
                    value: checked,
                  })
                }
              />
            ))}
          </div>
          {index !== Object.keys(permissions).length - 1 && (
            <Divider color={COLORS.colorDivider} />
          )}
        </div>
      ))}
    </>
  );
};
