import { PropsWithChildren } from 'react';
import { Typography } from '@ezetech/swag-space-x';
import COLORS from 'constants/styles/colors-js.constant.module.scss';

import css from './header.module.scss';

type PageEditorHeaderProps = {
  title?: string;
};

export const PageEditorHeader = (
  props: PropsWithChildren<PageEditorHeaderProps>,
): JSX.Element => {
  const { children, title } = props;
  return (
    <div className={css.header}>
      <Typography lineHeight="100%" fontType="HEADING5XL" color={COLORS.colorGreyDark}>
        {title}
      </Typography>
      {children}
    </div>
  );
};
