import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { ROUTES } from 'constants/router';
import { RootState, useAppSelector } from '../../redux/store';
import { featureFlagsSelector } from '../../redux/selectors/technical-data.selectors';
import { FeatureToggleProtectedRouteProps } from './index';

export const FeatureToggleProtectedRoute: FC<FeatureToggleProtectedRouteProps> = ({
  featureFlag,
  children,
}) => {
  const isFeatureEnabled = useAppSelector((state: RootState) =>
    Boolean(featureFlagsSelector(state)[featureFlag]),
  );

  if (!isFeatureEnabled) {
    return <Navigate to={ROUTES.DASHBOARD} replace />;
  }

  return children ? <>{children}</> : <Outlet />;
};
