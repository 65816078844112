import { Typography } from '@ezetech/swag-space-x';

import { useAppSelector } from 'redux/store';
import { priceBlockComponentSelector } from 'redux/selectors/orders.selectors';
import COLORS from 'constants/styles/colors-js.constant.module.scss';

import css from '../../order-card-item.module.scss';

interface IProps {
  isBox?: boolean;
  orderId: string;
  itemId: string;
}

export const PriceBlockComponent = ({ orderId, itemId, isBox }: IProps): JSX.Element => {
  const {
    pricePerItem,
    setupFee,
    shipping,
    kittingFee,
    showShipping,
    showSetupFee,
    isRelatedItem,
  } = useAppSelector(priceBlockComponentSelector({ orderId, itemId }));

  return (
    <div className={css.priceBlock}>
      <div className={css.price}>
        <Typography lineHeight="170%" fontType="bodyMd" color={COLORS.colorSecondaryText}>
          Item price:
        </Typography>
        <Typography
          lineHeight="170%"
          fontType="bodyMdBold"
          color={COLORS.colorPrimaryText}
        >
          {pricePerItem}
        </Typography>
      </div>
      {showSetupFee && (
        <div className={css.setupFee}>
          <Typography
            lineHeight="170%"
            fontType="bodyMd"
            color={COLORS.colorSecondaryText}
          >
            {isRelatedItem ? 'Shared Setup Fee' : 'Setup Fee'}:
          </Typography>
          <Typography
            lineHeight="170%"
            fontType="bodyMdBold"
            color={COLORS.colorPrimaryText}
          >
            {setupFee}
          </Typography>
        </div>
      )}
      {showShipping && (
        <div className={css.shipping}>
          <Typography
            lineHeight="170%"
            fontType="bodyMd"
            color={COLORS.colorSecondaryText}
          >
            Shipping:
          </Typography>
          <Typography
            lineHeight="170%"
            fontType="bodyMdBold"
            color={COLORS.colorPrimaryText}
          >
            {shipping}
          </Typography>
        </div>
      )}
      {isBox && (
        <div className={css.kitting}>
          <Typography
            lineHeight="170%"
            fontType="bodyMd"
            color={COLORS.colorSecondaryText}
          >
            Kitting Fee:
          </Typography>
          <Typography
            lineHeight="170%"
            fontType="bodyMdBold"
            color={COLORS.colorPrimaryText}
          >
            {kittingFee}
          </Typography>
        </div>
      )}
    </div>
  );
};
