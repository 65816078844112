import React, { useEffect } from 'react';
import cn from 'classnames';
import {
  WhiteBox,
  Input,
  Pagination,
  DownloadCsv,
  RoundedSelect,
  Tooltip,
  MultiOptionType,
  OptionType,
  OptionsGroupedType,
  TooltipPlacement,
  Typography,
  DateRange,
} from '@ezetech/swag-space-x';

import { SearchIcon } from '@ezetech/swag-space-x/dist/components/icons/search';
import { useAppDispatch, useAppSelector } from 'redux/store';
import {
  dropdownFiltersSelector,
  lastPageFiltersSelector,
  pageFiltersSelector,
  searchFiltersSelector,
} from 'redux/selectors/filters.selectors';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { ROUNDED_SELECT } from 'components/rounded-select';
import { setFilter } from 'redux/slices/filter.slice';
import { useDebounce } from 'hooks/use-debounce.hook';
import classes from './table-toolbar.module.scss';

interface IProps {
  className?: string;
  isFilterMultiSelect?: boolean;
  children?: React.ReactNode;
  filterOptions?: OptionType[] | OptionsGroupedType[];
  exportTooltipText?: string;
  showExportButton?: boolean;
  searchClassName?: string;
  toolbarClassName?: string;
  searchMobileClassName?: string;
  toolbarMobileClassName?: string;
  onNextPageClick?(page: number): void;
  onSearchChange?(search: string): void;
  onFilterClick?(options: MultiOptionType): void;
  onExportClick?(dateRange: DateRange): void;
}

export function TableToolbar({
  className,
  children = null,
  filterOptions = [],
  isFilterMultiSelect = false,
  exportTooltipText = 'Export data',
  showExportButton,
  onNextPageClick,
  toolbarClassName,
  searchClassName,
  searchMobileClassName,
  toolbarMobileClassName,
  onSearchChange,
  onFilterClick,
  onExportClick,
}: IProps): JSX.Element {
  const search = useAppSelector(searchFiltersSelector);
  const page = useAppSelector(pageFiltersSelector);
  const lastPage = useAppSelector(lastPageFiltersSelector);
  const selectedOptions = useAppSelector(dropdownFiltersSelector);
  const debouncedSearchTerm = useDebounce(search, 400);
  const dispatch = useAppDispatch();

  let timeout: NodeJS.Timeout;

  useEffect(() => {
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    if (onSearchChange) {
      onSearchChange(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm]);

  const handleSearchChange = (
    element: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    dispatch(setFilter({ search: element.target.value }));
  };

  const handleNextPageClick = (value: number) => {
    dispatch(setFilter({ page: value }));

    if (onNextPageClick) {
      onNextPageClick(value);
    }
  };

  const handleFilterClick = (options: MultiOptionType) => {
    dispatch(setFilter({ dropdown: options }));

    if (onFilterClick) {
      onFilterClick(options);
    }
  };

  const handleClear = () => {
    dispatch(setFilter({ search: '' }));
  };

  const handleCSVDownload = (dateRange: DateRange) => {
    if (onExportClick) {
      onExportClick(dateRange);
    }
  };

  return (
    <WhiteBox className={cn(classes.container, className)}>
      {children}
      <div className={cn(classes.toolbar, toolbarClassName)}>
        <Input
          skipError={true}
          placeholder="Search"
          className={cn(classes.searchInput, searchClassName)}
          adornmentStart={<SearchIcon className={classes.searchAdornmentIcon} />}
          onClear={handleClear}
          value={search}
          onChange={handleSearchChange}
        />
        <div className={classes.filter}>
          <div />
          {showExportButton && (
            <Tooltip
              content={<DownloadCsv onSubmit={handleCSVDownload} />}
              placement={TooltipPlacement.top}
            >
              <Typography lineHeight="162%" fontType="bodyMd" color={COLORS.colorBlack}>
                {exportTooltipText}
              </Typography>
            </Tooltip>
          )}
          <Pagination goToPage={handleNextPageClick} page={page} lastPage={lastPage} />
          <RoundedSelect
            styles={ROUNDED_SELECT}
            name="filter"
            onChange={handleFilterClick}
            options={filterOptions}
            selectedOptions={selectedOptions}
            menuPortalTarget={document.body}
            menuPosition="fixed"
            errorClassName={classes.error}
            selectWrapperClassName={classes.select}
            isMultiSelect={isFilterMultiSelect}
            menuShouldBlockScroll
            isCheckboxHidden
            showCounter
          />
        </div>
      </div>
      <div className={cn(classes.mobileToolbar, toolbarMobileClassName)}>
        <div className={classes.row}>
          <Input
            skipError={true}
            placeholder="Search"
            className={cn(classes.searchInput, searchMobileClassName)}
            adornmentStart={<SearchIcon className={classes.searchAdornmentIcon} />}
            onClear={handleClear}
            value={search}
            onChange={handleSearchChange}
          />
        </div>
        <div
          className={cn(classes.row, {
            [classes.spaceBetween]: showExportButton,
          })}
        >
          <div className={classes.flex1}>
            <Pagination
              paginationClassName={cn(classes.mobilePagination)}
              goToPage={handleNextPageClick}
              page={page}
              lastPage={lastPage}
            />
          </div>
          {showExportButton && (
            <div className={cn(classes.flex1, classes.downloadCsvContainer)}>
              <DownloadCsv onSubmit={handleCSVDownload} />
            </div>
          )}
        </div>
        <div className={classes.row}>
          <RoundedSelect
            name="filter"
            styles={ROUNDED_SELECT}
            onChange={handleFilterClick}
            options={filterOptions}
            selectedOptions={selectedOptions}
            menuPortalTarget={document.body}
            menuPosition="fixed"
            isMultiSelect={isFilterMultiSelect}
            errorClassName={classes.error}
            selectWrapperClassName={classes.mobileSelect}
            menuShouldBlockScroll
            isCheckboxHidden
            showCounter
          />
        </div>
      </div>
    </WhiteBox>
  );
}
