import { userApi } from 'redux/api/user.api';
import { store } from 'redux/store';

export const fetchUserData = async () => {
  try {
    const userRequest = store.dispatch(userApi.endpoints.getUser.initiate(null));
    await userRequest.unwrap();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
};
