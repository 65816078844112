import { OPEN_NOTES_TEXT } from 'constants/carts.constant';
import {
  CartTypeEnum,
  ICart,
  ICartItemVariant,
  ICartProductDetails,
  ICartProductDetailsVariant,
  IPrebuiltItem,
} from 'interfaces/cart.interfaces';
import { Price } from 'utils/price.util';
import { getDayAndMonth } from 'utils/date/date-formatter.util';

export const checkIsFullDesign = (color: string): boolean => {
  return color === 'full-design';
};

export const checkIsCrinklePaper = (name: string): boolean => {
  return name?.toLowerCase() === 'box crinkle paper';
};

export const checkIsBoxItem = (boxItemsId: string): boolean => {
  return !!boxItemsId;
};

const sizeBreakdownMapper =
  (quantities: number[], ignoreZero?: boolean) =>
  (breakdown: string[], size: string, sizeIndex: number): string[] => {
    const value = quantities[sizeIndex];

    if (ignoreZero) {
      if (value === 0) {
        return breakdown;
      }

      return [...breakdown, size];
    }

    return [...breakdown, `${size} - ${value}`];
  };

export const formatProductsItemsIntoDetailedView = ({
  variants,
  boxItemsId,
  sizes,
  productName,
}: {
  variants: ICartItemVariant[];
  boxItemsId: string;
  productName: string;
  sizes?: string[];
}): ICartProductDetailsVariant[] => {
  const isCrinklePaper = checkIsCrinklePaper(productName);

  if (checkIsBoxItem(boxItemsId) && !isCrinklePaper) {
    return [];
  }

  return variants.map((variant) => {
    const { color, name, quantities } = variant;
    const quantity = quantities.reduce((total, next) => total + next, 0);
    const breakdown = sizes
      ? sizes.reduce<string[]>(sizeBreakdownMapper(quantities, quantity === 1), [])
      : [];
    const isFullDesign = checkIsFullDesign(name);
    const isColorHidden = isFullDesign && !isCrinklePaper;
    const colorValue = isColorHidden ? null : color;
    const colorName = isColorHidden ? null : name;

    return {
      colorValue,
      colorName,
      quantity,
      size: breakdown,
    };
  });
};

export const mapItemIntoDetailedViewProduct = (
  item: Pick<
    IPrebuiltItem,
    | 'name'
    | 'quantity'
    | 'boxItemsId'
    | 'productPublicId'
    | 'variants'
    | 'sizes'
    | 'productionTime'
    | 'pricePerItem'
    | 'estInHand'
  >,
): ICartProductDetails => {
  const {
    name: productName,
    quantity: productQuantity,
    boxItemsId,
    productPublicId,
    variants,
    sizes,
    productionTime,
  } = item;

  const productVariants = formatProductsItemsIntoDetailedView({
    variants,
    sizes,
    boxItemsId,
    productName,
  });
  const pricePerItem = item.pricePerItem ? Price.formatPrice(item.pricePerItem) : '';
  const estInHand =
    item.estInHand?.from && item.estInHand?.to
      ? `${getDayAndMonth(new Date(item.estInHand.from))} - ${getDayAndMonth(new Date(item.estInHand.to))}`
      : '';

  return {
    name: productName,
    id: productPublicId,
    quantity: productQuantity,
    variants: productVariants,
    pricePerItem,
    productionTime,
    estInHand,
  };
};

export const getNotesText = (cart: ICart): string => {
  if (!cart.hasNotes || cart.type !== CartTypeEnum.PRE_BUILT_LIMITED) {
    return 'n/a';
  }

  return OPEN_NOTES_TEXT;
};
