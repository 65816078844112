export function deepEqual(obj1: unknown, obj2: unknown): boolean {
  if (obj1 === obj2) {
    return true;
  }

  if (typeof obj1 !== typeof obj2) {
    return false;
  }

  if (isObject(obj1) && isObject(obj2)) {
    const objKeys1 = Object.keys(obj1);
    const objKeys2 = Object.keys(obj2);

    if (objKeys1.length !== objKeys2.length) {
      return false;
    }

    for (const key of objKeys1) {
      if (!objKeys2.includes(key)) {
        return false;
      }

      const value1 = (obj1 as Record<string, unknown>)[key];
      const value2 = (obj2 as Record<string, unknown>)[key];

      if (!deepEqual(value1, value2)) {
        return false;
      }
    }

    return true;
  }

  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    if (obj1.length !== obj2.length) {
      return false;
    }

    for (let i = 0; i < obj1.length; i++) {
      if (!deepEqual(obj1[i], obj2[i])) {
        return false;
      }
    }

    return true;
  }

  return false;
}

function isObject(obj: unknown): obj is Record<string, unknown> {
  return obj !== null && typeof obj === 'object';
}
