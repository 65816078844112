import { useAppSelector } from 'redux/store';
import { orderCardBoxItemWrapperSelector } from 'redux/selectors/orders.selectors';
import { OrderCardItem } from '../order-card-item';
import { OrderCardItemFooter } from '../order-card-item-footer';

import css from './order-card-box-item-wrapper.module.scss';

interface IProps {
  itemId: string;
  orderId: string;
}

export const OrderCardBoxItemWrapperComponent = ({ orderId, itemId }: IProps) => {
  const { boxName, boxProducts, boxShipping, boxTotal } = useAppSelector(
    orderCardBoxItemWrapperSelector({ orderId, itemId }),
  );

  return (
    <>
      <div className={css.boxHeader}>Box {boxName}</div>
      <OrderCardItem orderId={orderId} itemId={itemId} isBox />
      {boxProducts.map(({ id }) => (
        <OrderCardItem orderId={orderId} itemId={id} parentBoxItemId={itemId} key={id} />
      ))}
      <OrderCardItemFooter shipping={boxShipping} total={boxTotal} />
    </>
  );
};
