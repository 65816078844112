import React, { useCallback, useState, useRef, useEffect, ChangeEvent } from 'react';
import { Input, Typography, Loader } from '@ezetech/swag-space-x';
import { useGetHomepageProductsMutation } from 'redux/api/products.api';
import { setHomepageProducts } from 'redux/slices/products.slice';
import { useAppDispatch } from 'redux/store';
import { debounce } from 'utils/common.util';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { SearchList } from './search-list';
import { SelectionList } from './selection-list';
import css from './search-products.module.scss';

const FIELD = {
  label: 'Add Product',
  name: 'search',
  placeholder: 'Search...',
};

const MAX_SEARCH_LENGTH = 50;

export const SearchProducts: React.FC = () => {
  const dispatch = useAppDispatch();
  const inputRef = useRef<HTMLInputElement>(null);
  const listRef = useRef<HTMLInputElement>(null);

  const [search, setSearch] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [getProducts, { isLoading }] = useGetHomepageProductsMutation();

  useEffect(() => {
    function handleClickOutside(e: MouseEvent) {
      const areRefsExists = listRef.current && inputRef.current;
      if (
        areRefsExists &&
        e.target &&
        !listRef.current.contains(e.target as Node) &&
        !inputRef.current.contains(e.target as Node)
      ) {
        setIsOpen(false);
        dispatch(setHomepageProducts({ list: [] }));
      }
    }
    if (inputRef && listRef) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [listRef, inputRef]);

  const debouncedHandleGetProducts = useCallback(
    debounce(async (value) => {
      if (!value) {
        dispatch(setHomepageProducts({ list: [] }));
        return;
      }

      await getProducts({ search: value });

      setIsOpen(true);
    }, 1000),
    [],
  );

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;

    if (value.length > MAX_SEARCH_LENGTH) {
      return;
    }

    if (isOpen) {
      setIsOpen(false);
    }
    setSearch(value);
    debouncedHandleGetProducts(value);
  };

  return (
    <div className={css.wrapper}>
      <Input
        autoComplete="off"
        type="text"
        name={FIELD.name}
        label={FIELD.label}
        value={search}
        placeholder={FIELD.placeholder}
        errorClassName={css.error}
        onChange={handleInputChange}
        inputWrapperRef={inputRef}
        className={css.inputWrapper}
      />
      {isLoading && (
        <div className={css.loaderOverlay}>
          <Loader className={css.searchLoader} />
        </div>
      )}
      <Typography
        color={COLORS.colorText500}
        fontType="bodySm"
        className={css.hint}
        lineHeight="142.857%"
      >
        Select up to 9 products
      </Typography>
      <SearchList listRef={listRef} isLoading={isLoading} isOpen={isOpen} />
      <SelectionList />
    </div>
  );
};
