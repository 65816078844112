import { FC, useMemo, FocusEvent } from 'react';
import * as yup from 'yup';
import { Input, Checkbox } from '@ezetech/swag-space-x';
import { useSelector } from 'react-redux';
import { isRequired } from 'utils/yup.util';
import { useAppDispatch } from 'redux/store';
import { patchCompany, setCompanyError } from 'redux/slices/settings.slice';
import { ICompany } from 'interfaces';
import { cannot, SUBJECTS } from 'boot/ability';

import { FormProps } from '../forms.interface';
import css from '../forms.module.scss';
import {
  companyDetailsErrorsSelector,
  companyDetailsSelector,
} from '../../../../../redux/selectors/settings.selectors';
import { detailsFormSchema } from './details.schema';
import { FIELDS } from './details.constants';

type KeyOfICompany = keyof Omit<ICompany, 'showEmail' | 'showPhone'>;

export const DetailsForm: FC<FormProps> = ({ loading, success, error }) => {
  const requiredFields = useMemo<Record<string, yup.SchemaFieldDescription>>(() => {
    return detailsFormSchema.describe().fields;
  }, []);

  const dispatch = useAppDispatch();
  const values = useSelector(companyDetailsSelector);
  const errors = useSelector(companyDetailsErrorsSelector);
  const isDisabled = cannot(
    SUBJECTS.COMPANY_DETAILS.actions.COMPANY_DETAILS_EDIT,
    SUBJECTS.COMPANY_DETAILS.value,
  );

  const onChange = (path: string) => async (value: unknown) => {
    dispatch(
      patchCompany({
        [path]: value,
      }),
    );
    dispatch(setCompanyError({ [path]: undefined }));
  };

  const onBlur = (path: string) => async (e: FocusEvent<HTMLInputElement>) => {
    try {
      await detailsFormSchema.validateAt(path, { [path]: e.target.value });
      dispatch(setCompanyError({ [path]: undefined }));
    } catch (validationError: unknown) {
      const { message } = validationError as { message: string };
      dispatch(setCompanyError({ [path]: message }));
    }
  };

  return (
    <form className={css.form}>
      <div className={css.twoInRowContainer}>
        <div className={css.containerWithCheckbox}>
          <Input
            disabled={isDisabled}
            className={css.input}
            errorClassName={css.inputErrorWithCheckbox}
            type="email"
            value={values[FIELDS.email.name as KeyOfICompany]}
            onChange={(e) => onChange(FIELDS.email.name)(e.target.value)}
            label={FIELDS.email.label}
            name={FIELDS.email.name}
            onBlur={onBlur(FIELDS.email.name)}
            placeholder={FIELDS.email.placeholder}
            required={isRequired(requiredFields[FIELDS.email.name])}
            errors={errors[FIELDS.email.name as KeyOfICompany]}
          />
          <Checkbox
            disabled={isDisabled}
            label={FIELDS.showEmail.label}
            name={FIELDS.showEmail.name}
            checked={!!values[FIELDS.showEmail.name as keyof ICompany['showEmail']]}
            onCheckedChange={(show) => onChange(FIELDS.showEmail.name)(show)}
          />
        </div>
        <div className={css.containerWithCheckbox}>
          <Input
            disabled={isDisabled}
            errorClassName={css.inputErrorWithCheckbox}
            className={css.input}
            type="tel"
            value={values[FIELDS.cellPhone.name as KeyOfICompany]}
            onChange={(e) => onChange(FIELDS.cellPhone.name)(e.target.value)}
            label={FIELDS.cellPhone.label}
            name={FIELDS.cellPhone.name}
            placeholder={FIELDS.cellPhone.placeholder}
            required={isRequired(requiredFields[FIELDS.cellPhone.name])}
            errors={errors[FIELDS.cellPhone.name as KeyOfICompany]}
            onBlur={onBlur(FIELDS.cellPhone.name)}
          />
          <Checkbox
            disabled={isDisabled}
            label={FIELDS.showPhone.label}
            name={FIELDS.showPhone.name}
            checked={!!values[FIELDS.showPhone.name as keyof ICompany['showPhone']]}
            onCheckedChange={(show) => onChange(FIELDS.showPhone.name)(show)}
          />
        </div>
      </div>
      <Input
        disabled={isDisabled}
        type="text"
        value={values[FIELDS.companyName.name as KeyOfICompany]}
        onChange={(e) => onChange(FIELDS.companyName.name)(e.target.value)}
        onBlur={onBlur(FIELDS.companyName.name)}
        label={FIELDS.companyName.label}
        name={FIELDS.companyName.name}
        placeholder={FIELDS.companyName.placeholder}
        required={isRequired(requiredFields[FIELDS.companyName.name])}
        errors={errors[FIELDS.companyName.name as KeyOfICompany]}
      />
    </form>
  );
};
