export const FIELDS = {
  customerEmail: {
    label: 'Customer Email',
    name: 'customerEmail',
    placeholder: 'Start typing email address',
  },
  companyName: {
    label: 'Company Name',
    name: 'companyName',
    placeholder: 'Company name',
  },
  country: {
    label: 'Country',
    name: 'country',
    placeholder: 'Select country',
  },
  address: {
    label: 'Address',
    name: 'address',
    placeholder: 'Address',
  },
  city: {
    label: 'City',
    name: 'city',
    placeholder: 'City',
  },
  state: {
    label: 'State',
    name: 'state',
    placeholder: 'Select state',
  },
  zip: {
    label: 'Zip',
    name: 'billingZip',
    placeholder: 'Enter zip code',
  },
};
