import { createSelector } from 'reselect';
import { CellTextColor, OptionType } from '@ezetech/swag-space-x';
import { CLEntity } from 'interfaces/customers-leads.interface.api';
import {
  CustomerFields,
  CUSTOMERS_TABLE_HEADER,
  CUSTOMERS_TABLE_ROW,
  ITransformCell,
  ITransformRow,
  LeadSource,
  ILead,
  CUSTOMERS_FILTERS,
  LEADS_FILTERS,
  LEADS_TABLE_HEADER,
  LeadFields,
} from 'interfaces/user-api.interface';
import { getTimeMmmDdYyyy } from 'utils/date/date-formatter.util';
import { makeWhatWeOfferCells, createUserName } from 'utils/customer/users-table.util';
import {
  searchFiltersSelector,
  dropdownFiltersSelector,
  pageFiltersSelector,
} from 'redux/selectors/filters.selectors';
import { ASSIGNED_TO_ME_ENTITY, PER_PAGE_RECORDS } from 'constants/common';
import { CUSTOMER_TYPES } from 'interfaces/customer-details.interface';
import { can, canRoute, SUBJECTS } from 'boot/ability';
import { RootState } from '../store';
import { assignmentFlowEnabledSelector } from './settings.selectors';

export const customersLeadsSelector = (state: RootState) => state.customersLeads;

export const customersLeadsListSelector = createSelector(
  customersLeadsSelector,
  (state) => state.list,
);

export const leadsListSelector = createSelector(
  customersLeadsSelector,
  (state) => state.list.filter((item) => 'source' in item) as ILead[],
);

export const customersLeadsTotalsSelector = createSelector(
  customersLeadsSelector,
  (state) => ({
    [CLEntity.leads]: state.leadsTotal,
    [CLEntity.customers]: state.customersTotal,
  }),
);

export const customersLeadsToggleSelector = createSelector(
  customersLeadsSelector,
  customersLeadsTotalsSelector,
  (state, counters) => {
    return [
      {
        id: `/${CLEntity.customers}`,
        name: `Customers (${counters[CLEntity.customers]})`,
      },
      { id: `/${CLEntity.leads}`, name: `Leads (${counters[CLEntity.leads]})` },
    ].filter((item) => {
      const result = canRoute(item.id);
      return result.isAllowed && !result.redirect;
    });
  },
);

export const customersHeaderSelector = createSelector(
  assignmentFlowEnabledSelector,
  (isOrderAssignmentEnabled) => {
    if (!isOrderAssignmentEnabled) {
      return CUSTOMERS_TABLE_HEADER.filter(
        (field) => field.id !== CustomerFields.assignTo,
      );
    }
    return CUSTOMERS_TABLE_HEADER;
  },
);

export const customersTableRowsSelector = createSelector(
  customersLeadsListSelector,
  pageFiltersSelector,
  customersHeaderSelector,
  (list, page, header) => {
    const startIndexingFrom = (page - 1) * PER_PAGE_RECORDS;
    const rows: { cells: ITransformCell[]; index: number }[] = [];

    list.forEach((item, position) => {
      if ('orders' in item) {
        const cells = header.map((cell) => {
          const common = CUSTOMERS_TABLE_ROW[cell.id] || {};
          const cellParams = { ...common, rowId: item.id };
          const assignedToUser = item?.assignee
            ? {
                value: item.assignee.id,
                label: `${item?.assignee.firstName} ${item?.assignee.lastName}`,
              }
            : null;

          if (cell.id === CustomerFields.customerName) {
            return {
              ...cellParams,
              text: createUserName(item),
              id: CustomerFields.customerName,
            };
          }
          if (cell.id === CustomerFields.type) {
            return {
              ...cellParams,
              text: CUSTOMER_TYPES[item.type] || 'n/a',
              id: CustomerFields.type,
            };
          }
          if (cell.id === CustomerFields.companyName) {
            return {
              ...cellParams,
              text: item.companyName || 'n/a',
              customerCompanyId: item.customerCompanyId,
              actionable: !!item.customerCompanyId,
              id: CustomerFields.companyName,
              textColor: item.customerCompanyId
                ? CellTextColor.pink
                : CellTextColor.primary,
            };
          }
          if (cell.id === CustomerFields.id) {
            return {
              ...cellParams,
              id: cell.id,
              text: `#${position + startIndexingFrom + 1}`,
            };
          }
          if (cell.id === CustomerFields.lastOrderDate) {
            const value = item[cell.id];

            return {
              ...cellParams,
              id: cell.id,
              text: value
                ? getTimeMmmDdYyyy(new Date(value), {
                    day: 'numeric',
                    month: 'short',
                  })
                : 'n/a',
            };
          }

          if (cell.id === CustomerFields.orders) {
            return {
              ...cellParams,
              id: cell.id,
              text: String(item[cell.id]),
            };
          }

          if (cell.id === CustomerFields.assignTo) {
            return {
              ...cellParams,
              id: cell.id,
              text: '',
              assignedToUser,
            };
          }

          return {
            ...cellParams,
            id: cell.id,
            text: item[cell.id] ? String(item[cell.id]) : 'n/a',
          };
        });

        rows.push({
          cells,
          index: position + 1,
        });
      }
    });

    return rows;
  },
);

export const initialCustomersFiltersSelector = createSelector(
  assignmentFlowEnabledSelector,
  (isOrderAssignmentEnabled) => {
    const isAbleToBeAssignedForCustomer = can(
      SUBJECTS.EDIT_CUSTOMERS_DETAILS.actions.ASSIGN_CUSTOMERS,
      SUBJECTS.EDIT_CUSTOMERS_DETAILS.value,
    );

    if (isOrderAssignmentEnabled && isAbleToBeAssignedForCustomer) {
      return (CUSTOMERS_FILTERS as OptionType[]).filter(
        (option) => option.value === ASSIGNED_TO_ME_ENTITY,
      );
    }
    return [];
  },
);

export const customersFilterOptionsSelector = createSelector(
  assignmentFlowEnabledSelector,
  (isOrderAssignmentEnabled) => {
    const isAbleToBeAssignedForCustomer = can(
      SUBJECTS.EDIT_CUSTOMERS_DETAILS.actions.ASSIGN_CUSTOMERS,
      SUBJECTS.EDIT_CUSTOMERS_DETAILS.value,
    );

    if (!isOrderAssignmentEnabled && isAbleToBeAssignedForCustomer) {
      return (CUSTOMERS_FILTERS as OptionType[]).filter(
        (option) => option.value !== ASSIGNED_TO_ME_ENTITY,
      );
    }
    return CUSTOMERS_FILTERS;
  },
);

export const initialLeadsFiltersSelector = createSelector(
  assignmentFlowEnabledSelector,
  (isOrderAssignmentEnabled) => {
    const isAbleToBeAssignedForLeads = can(
      SUBJECTS.LEADS.actions.ASSIGN_LEADS,
      SUBJECTS.LEADS.value,
    );

    if (isOrderAssignmentEnabled && isAbleToBeAssignedForLeads) {
      return (LEADS_FILTERS as OptionType[]).filter(
        (option) => option.value === ASSIGNED_TO_ME_ENTITY,
      );
    }
    return [];
  },
);

export const leadsFilterOptionsSelector = createSelector(
  assignmentFlowEnabledSelector,
  (isOrderAssignmentEnabled) => {
    const isAbleToBeAssignedForLeads = can(
      SUBJECTS.LEADS.actions.ASSIGN_LEADS,
      SUBJECTS.LEADS.value,
    );

    if (!isOrderAssignmentEnabled && isAbleToBeAssignedForLeads) {
      return (LEADS_FILTERS as OptionType[]).filter(
        (option) => option.value !== ASSIGNED_TO_ME_ENTITY,
      );
    }
    return LEADS_FILTERS;
  },
);

export const leadsHeaderSelector = createSelector(
  assignmentFlowEnabledSelector,
  (isOrderAssignmentEnabled) => {
    if (!isOrderAssignmentEnabled) {
      return LEADS_TABLE_HEADER.filter((field) => field.id !== LeadFields.assignTo);
    }
    return LEADS_TABLE_HEADER;
  },
);

export const leadsTableRowsSelector = createSelector(
  leadsListSelector,
  pageFiltersSelector,
  leadsHeaderSelector,
  (list, page, header) => {
    const startIndexingFrom = (page - 1) * PER_PAGE_RECORDS;
    const rows: ITransformRow[] = [];
    list.forEach((item, position) => {
      const cells = makeWhatWeOfferCells(item, position, startIndexingFrom, header);

      rows.push({
        cells,
        index: position + 1,
        isInstantQuote: item.source === LeadSource.INSTANT_QUOTE,
        item,
      });
    });

    return rows;
  },
);

export const emptyStateSelector = createSelector(
  leadsTableRowsSelector,
  searchFiltersSelector,
  dropdownFiltersSelector,
  (list, search, dropdown) => {
    const areNoFiltersApplied = !search && Array.isArray(dropdown) && !dropdown?.length;

    if (list.length === 0 && areNoFiltersApplied) {
      return {
        title: 'Woah, it’s quiet in here',
        description: 'Get your first record and then come back here',
        button: 'Back to Dashboard',
      };
    }

    if (list.length === 0 && !areNoFiltersApplied) {
      return {
        title: 'Woah, it’s quiet in here',
        description: 'No records matching the filter',
        button: 'Back to Dashboard',
      };
    }

    return {
      title: '',
      description: '',
      button: '',
    };
  },
);

export const isPaginationVisibleSelector = createSelector(
  customersLeadsListSelector,
  (list) => list.length > 0,
);
