import { OrderCardBoxItemWrapper } from '../order-card-box-item-wrapper';
import { OrderCardRelatedItemsWrapper } from '../order-card-related-items-wrapper';
import { OrderCardItem } from '../order-card-item';

import css from './order-card-items.module.scss';

interface IProps {
  itemsIds: {
    defaultItems: string[];
    relatedItems: string[];
    boxItems: string[];
  };
  orderId: string;
  inventoryName?: string;
}

export const OrderCardItemsComponent = ({ itemsIds, orderId, inventoryName }: IProps) => {
  return (
    <div className={css.container}>
      <div className={css.itemsCardsWrapper}>
        {inventoryName && (
          <div className={css.inventoryHeader}>Sent to {inventoryName}</div>
        )}
        {itemsIds.defaultItems.map((itemId, i) => {
          const isFirstItem = i === 0 && !inventoryName;
          const isLastItem =
            itemsIds.defaultItems.length - 1 === i &&
            !itemsIds.relatedItems.length &&
            !itemsIds.boxItems.length;

          return (
            <OrderCardItem
              orderId={orderId}
              itemId={itemId}
              isFirstItem={isFirstItem}
              isLastItem={isLastItem}
              key={itemId}
            />
          );
        })}
        {Boolean(itemsIds.relatedItems.length) && (
          <OrderCardRelatedItemsWrapper
            orderId={orderId}
            relatedItemsIds={itemsIds.relatedItems}
          />
        )}
        {itemsIds.boxItems.map((itemId) => (
          <OrderCardBoxItemWrapper orderId={orderId} itemId={itemId} key={itemId} />
        ))}
      </div>
    </div>
  );
};
