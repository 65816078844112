import { Link, Tooltip, TooltipPlacement, Typography } from '@ezetech/swag-space-x';
import COLORS from 'constants/styles/colors-js.constant.module.scss';

import { ILead } from 'interfaces/user-api.interface';
import { Price } from 'utils/price.util';
import { COUNTRIES_LIST_ALL, ICountryListItem } from 'constants/settings.constants';

import css from './instant-quote.module.scss';

interface IProps {
  item: ILead;
}

export function InstantQuote({ item }: IProps): JSX.Element {
  const country: string | null =
    COUNTRIES_LIST_ALL.find(({ cca2 }: ICountryListItem) => cca2 === item.country)
      ?.common || null;

  const fields: [string, JSX.Element | string | number | null][] = [
    [
      'Product URL',
      <Link to={item.productUrl} target="_blank">
        {item.productUrl}
      </Link>,
    ],
    ['Email', item.email],
    ['Quantity', item.itemsQuantity],
    ['Print locations', item.printLocationsQuantity],
    ['Colors', item.firstPrintLocationColorsQuantity],
    ['ZIP', item.zip],
    ['Country', country],
    ['Total Amount', Price.formatPrice((item.totalCost ?? 0) * 100)],
  ];

  return (
    <Tooltip
      placement={TooltipPlacement.bottom}
      content={<Typography color={COLORS.colorAccent500}>Instant quote</Typography>}
      interactive={true}
    >
      <Typography
        fontType="bodySmBold"
        lineHeight="143%"
        color={COLORS.colorTypographyHeadings}
        className={css.name}
      >
        {item.productName}
      </Typography>

      {fields.map(([label, value], index) =>
        value ? (
          <div className={css.row} key={index + label}>
            <Typography
              color={COLORS.colorTypographyHeadings}
              fontType="bodySm"
              lineHeight="143%"
            >
              {label ? `${label}: ` : ''}
              {value}
            </Typography>
          </div>
        ) : null,
      )}
    </Tooltip>
  );
}
