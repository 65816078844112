import { ValidationError } from 'yup';
import {
  IAddressErrors,
  ICompanyErrors,
  IUserErrors,
} from 'interfaces/customer-details.interface';

export const formatErrors = (errors: ValidationError[]) => {
  return errors.reduce<{
    userErrors: IUserErrors;
    companyErrors: ICompanyErrors;
    addressErrors: IAddressErrors;
  }>(
    (acc, error) => {
      if (error.path) {
        if (!error.path?.includes('.')) {
          return {
            ...acc,
            userErrors: {
              ...acc.userErrors,
              [error.path]: error.errors[0],
            },
          };
        }
        if (error.path?.includes('company') && !error.path?.includes('companyPayment')) {
          return {
            ...acc,
            companyErrors: {
              ...acc.companyErrors,
              [error.path.split('.').pop() as string]: error.errors[0],
            },
          };
        }
        return {
          ...acc,
          addressErrors: {
            ...acc.addressErrors,
            [error.path.split('.').pop() as string]: error.errors[0],
          },
        };
      }
      return acc;
    },
    {
      userErrors: {},
      companyErrors: {},
      addressErrors: {},
    },
  );
};
