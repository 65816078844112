export const FIELDS = {
  invoiceBankCountry: {
    label: 'Bank Country',
    name: 'invoiceBankCountry',
    placeholder: 'Enter Bank Country',
  },
  invoiceBankName: {
    label: 'Bank Name',
    name: 'invoiceBankName',
    placeholder: 'Enter Bank Name',
  },
  invoiceAccountType: {
    label: 'Account Type',
    name: 'invoiceAccountType',
    placeholder: 'Enter Account Type',
  },
  invoiceAccountBeneficiaryName: {
    label: 'Account Beneficiary Name',
    name: 'invoiceAccountBeneficiaryName',
    placeholder: 'Enter Account Beneficiary Name',
  },
  invoiceBankAccountNumber: {
    label: (isEurope: boolean) => (isEurope ? 'IBAN' : 'Bank Account Number'),
    name: 'invoiceBankAccountNumber',
    placeholder: (isEurope: boolean) =>
      isEurope ? 'Enter IBAN' : 'Enter Bank Account Number',
  },
  invoiceBIC: {
    label: (isUSA: boolean) => (isUSA ? 'BIC/SWIFT Code (Optional)' : 'BIC/SWIFT Code'),
    name: 'invoiceBIC',
    placeholder: 'Enter BIC/SWIFT Code',
  },
  invoiceRoutingNumber: {
    label: 'Routing Number (Optional)',
    name: 'invoiceRoutingNumber',
    placeholder: 'Enter Routing Number',
  },
  invoiceAsInPayout: {
    label: '--empty--',
    name: 'invoiceAsInPayout',
    placeholder: '--empty--',
  },
};
