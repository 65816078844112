import { EditorState } from 'draft-js';
import {
  useCallback,
  ChangeEvent,
  useRef,
  ReactNode,
  PropsWithChildren,
  useEffect,
} from 'react';
import { SwagButton, Divider, Typography } from '@ezetech/swag-space-x';

import { RichTextEditor, useRichTextEditor } from 'components/rich-text-editor';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { TOS_PP_TYPE } from 'constants/settings.constants';

import {
  useUploadToSOrPPPdfMutation,
  useDownloadToSOrPPPdfMutation,
} from 'redux/api/settings.api';
import { NotificationType } from 'redux/slices/notifications.slice';
import { useSave } from 'hooks/settings/save.hook';
import { useNotifications } from 'hooks/notifications.hook';

import css from './tos-pp.module.scss';

export interface ToSPPProps extends PropsWithChildren {
  type: TOS_PP_TYPE;
  title: string;
  children: ReactNode;
  onChange: (string: string) => void;
  html?: string | null;
  initHtml?: string | null;
  isEditDisabled?: boolean;
  isDownloadHidden?: boolean;
  isUploadHidden?: boolean;
}

export function ToSPP({
  children,
  title,
  type,
  html,
  initHtml,
  isEditDisabled = false,
  isDownloadHidden = false,
  isUploadHidden = false,
  onChange,
}: ToSPPProps): JSX.Element {
  const { isShown } = useSave();
  const { pushNotification } = useNotifications();
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const { editorState, setEditorState, setHtmlToEditorState } =
    useRichTextEditor(initHtml);

  // Calls when html reset to initial state.
  useEffect(() => {
    if (html === initHtml) {
      setHtmlToEditorState(html);
    }
  }, [html]);

  const [uploadPDF, { isLoading: isUploadLoading }] = useUploadToSOrPPPdfMutation();
  const [downloadPDF, { isLoading: isDownloadLoading }] = useDownloadToSOrPPPdfMutation();
  const isLoading = isUploadLoading || isDownloadLoading;

  const onEditorChange = useCallback(
    (updatedHtml: string, updatedEditorState: EditorState) => {
      onChange && onChange(updatedHtml);
      setEditorState(updatedEditorState);
    },
    [],
  );

  const resetInput = () => {
    if (!hiddenFileInput.current) {
      return;
    }

    hiddenFileInput.current.value = '';
  };

  const upload = useCallback((e: ChangeEvent<HTMLInputElement>): void => {
    const file: File | undefined = e.target.files?.[0];
    if (!file) {
      return;
    }

    const extension = file.name.split('.').pop();

    if (extension && !['docx', 'pdf'].includes(extension)) {
      pushNotification({
        status: 'negative',
        message: 'Only .docx and .pdf files are allowed.',
        type: NotificationType.toasts,
      });
      resetInput();
      return;
    }

    const fileReader = new FileReader();

    fileReader.onload = async () => {
      const formData = new FormData();
      formData.set('file', file);
      const data = await uploadPDF({ data: formData, type });

      if ('error' in data) {
        resetInput();
      }
    };
    fileReader.readAsDataURL(file);
    e.preventDefault();
  }, []);

  const downloadHTML = () => {
    downloadPDF({ type, draftHtml: isShown && html ? html : '' });
  };

  return (
    <>
      {isLoading && <div className={css.loaderWrapper} />}
      <div className={css.root}>
        <Typography color={COLORS.colorPrimaryText} lineHeight="162.5%">
          {children}
        </Typography>
        <div className={css.editor}>
          <Typography color={COLORS.colorText900} fontType="bodySm" lineHeight="142.85%">
            {title}
          </Typography>
          <RichTextEditor
            editorState={editorState}
            onChange={isEditDisabled ? () => null : onEditorChange}
          />
        </div>
        <Divider />
        <div className={css.buttons}>
          {!isDownloadHidden && (
            <SwagButton
              loading={isDownloadLoading}
              className={css.button}
              onClick={downloadHTML}
              type="outlined"
            >
              Download PDF
            </SwagButton>
          )}
          {!isUploadHidden && (
            <>
              <SwagButton
                loading={isUploadLoading}
                className={css.button}
                onClick={() => hiddenFileInput?.current?.click()}
                type="primary"
              >
                Upload
              </SwagButton>
              <input
                type="file"
                accept=".docx, .pdf"
                onChange={upload}
                ref={hiddenFileInput}
                className={css.hidden}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
}
