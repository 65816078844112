import cn from 'classnames';
import React from 'react';
import { MultiValue } from 'react-select';
import {
  Table,
  TableBody,
  TableHeader,
  TableRow,
  TableHeaderCell,
  TableRowCell,
  WhiteBox,
  OptionType,
  Select,
  Typography,
} from '@ezetech/swag-space-x';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/store';
import {
  CustomerFields,
  LeadFields,
  ITransformCell,
  ITransformRow,
} from 'interfaces/user-api.interface';
import {
  emptyStateSelector,
  leadsHeaderSelector,
  leadsTableRowsSelector,
} from 'redux/selectors/customers-leads.selectors';
import { assignmentFlowEnabledSelector } from 'redux/selectors/settings.selectors';
import { leadsAssignToOptionsSelector } from 'redux/selectors/assignee.selector';
import { STATUS, statusOptions } from 'utils/customer/users-table.util';
import { useAssignLeadToUserMutation } from 'redux/api/assignee.api';
import { NotificationType } from 'redux/slices/notifications.slice';
import { EmptyState } from 'components/empty-state';
import { ROUTES } from 'constants/router';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { useNotifications } from 'hooks/notifications.hook';
import { useClipboard } from 'hooks/use-clipboard.hook';
import { SUBJECTS } from 'constants/ability.constant';
import { can } from 'boot/ability';
import { InstantQuote } from '../instant-quote';
import { InterestedIn } from '../interested-in';
import { useUpdateLeadStatusMutation } from '../../../redux/api/user.api';
import classes from './leads-table.module.scss';

export function LeadsTable({
  onHeaderCellClick,
  getSortingDirection,
}: {
  onHeaderCellClick(id: string): void;
  getSortingDirection(id: string): 'up' | 'down';
}): JSX.Element {
  const navigate = useNavigate();
  const { copy } = useClipboard();
  const { pushNotification } = useNotifications();
  const [updateLeadStatus] = useUpdateLeadStatusMutation();
  const header = useAppSelector(leadsHeaderSelector);
  const rows = useAppSelector(leadsTableRowsSelector);
  const leadsAssignToOptions = useAppSelector(leadsAssignToOptionsSelector);
  const assignmentFlowEnabled = useAppSelector(assignmentFlowEnabledSelector);
  const emptyState = useAppSelector(emptyStateSelector);
  const [assignLeadToUserRequest] = useAssignLeadToUserMutation();

  const isAbleToAssignLeads = can(
    SUBJECTS.TEAM_MEMBER_LEAD_ASSIGNMENT.actions.ASSIGN_TEAM_MEMBER_TO_LEAD,
    SUBJECTS.TEAM_MEMBER_LEAD_ASSIGNMENT.value,
  );

  const isAbleToBeAssignedForLead = can(
    SUBJECTS.LEADS.actions.ASSIGN_LEADS,
    SUBJECTS.LEADS.value,
  );

  const isAssignmentFlow =
    assignmentFlowEnabled && (isAbleToAssignLeads || isAbleToBeAssignedForLead);

  const handleClick = () => {
    navigate(ROUTES.DASHBOARD);
  };

  const copyText = async (value?: string, field?: string) => {
    const text = field ? `${field}` : 'Text';
    const isSuccess = await copy(value);

    if (isSuccess) {
      pushNotification({
        status: 'positive',
        message: `${text} has been copied.`,
        type: NotificationType.toasts,
      });
    }
  };

  const handleHeaderCellClick = (id: string) => {
    onHeaderCellClick(id);
  };

  const handleCellClick = (e?: React.SyntheticEvent<HTMLSpanElement>, id?: string) => {
    if (id === CustomerFields.email) {
      copyText(e?.currentTarget.innerHTML, 'Email');
    }
  };

  const getCellContent = (cell: ITransformCell, row: ITransformRow) => {
    switch (true) {
      case cell.id === LeadFields.interestedIn && row.isInstantQuote:
        return <InstantQuote item={row.item} />;
      case cell.id === LeadFields.interestedIn:
        return <InterestedIn item={row.item} />;
      default:
        return cell.text;
    }
  };

  const onAssignmentSelect =
    (cell: ITransformCell) =>
    (option: OptionType | MultiValue<OptionType | null> | null) => {
      if ((option as OptionType).value === cell.assignedToUser?.value) {
        return;
      }

      assignLeadToUserRequest({
        entityId: cell.rowId || '',
        userId: (option as OptionType).value || '',
      });
    };

  const onStatusSelect =
    (cell: ITransformCell, id: string) =>
    (option: OptionType | MultiValue<OptionType | null> | null) => {
      if ((option as OptionType).value === cell.text) {
        return;
      }

      updateLeadStatus({
        id,
        status: (option as OptionType).value.toUpperCase(),
      });
    };

  if (!rows.length) {
    const { title, description, button } = emptyState;

    return (
      <EmptyState
        title={title}
        button={button}
        description={description.replace('record', 'lead')}
        onClick={handleClick}
      />
    );
  }

  return (
    <WhiteBox className={classes.root}>
      <Table className={classes.tableWrapper}>
        <TableHeader>
          {header.map((cell, index) => (
            <TableHeaderCell
              id={cell.id}
              className={cn({
                [classes.headerCell]: !isAssignmentFlow,
                [classes.fixedWidthCell]:
                  cell.id === LeadFields.status || cell.id === LeadFields.assignTo,
                [classes.number]: index === 0,
                [classes.wide]: cell.id === LeadFields.interestedIn,
              })}
              size={cell.size}
              iconDirection={getSortingDirection(cell.id)}
              withSorting={cell.withSorting}
              onClick={cell.withSorting ? handleHeaderCellClick : undefined}
              key={`leads-header-${cell.id}`}
            >
              {cell.text}
            </TableHeaderCell>
          ))}
        </TableHeader>
        <TableBody>
          {rows.map((row, position) => (
            <TableRow primaryDataLabel={row.cells[0].rowId} key={`leads-${row.index}`}>
              {row.cells.map((cell, index) => {
                if (cell.id === LeadFields.assignTo) {
                  if (
                    isAbleToAssignLeads ||
                    (isAbleToBeAssignedForLead && !cell.assignedToUser)
                  ) {
                    return (
                      <Select
                        dataLabel={`${cell.id + '-' + cell.rowId}`}
                        value={cell.assignedToUser}
                        options={leadsAssignToOptions}
                        onChange={onAssignmentSelect(cell)}
                        className={cn(classes.fixedWidthCell, classes.assignToCell)}
                        isSearchable
                        name="assignTo"
                        skipError
                        menuPlacement="auto"
                        size="small"
                        type="outlined"
                        menuPosition="fixed"
                        menuShouldBlockScroll
                      />
                    );
                  }

                  return (
                    <div
                      data-label={`${cell.id + '-' + cell.rowId}`}
                      className={classes.assignToCell}
                    >
                      <Typography color={COLORS.colorPrimaryText} fontType="bodyMd">
                        {cell.assignedToUser?.label}
                      </Typography>
                    </div>
                  );
                }

                if (cell.id === LeadFields.status) {
                  return (
                    <Select
                      dataLabel={`${cell.id + '-' + cell.rowId}`}
                      value={cell.status}
                      options={statusOptions}
                      onChange={onStatusSelect(cell, row.item.id)}
                      className={cn(classes.fixedWidthCell, classes.statusCell, {
                        [classes.green]: cell.text === STATUS.COMPLETED,
                      })}
                      isSearchable
                      name="status"
                      skipError
                      menuPlacement="auto"
                      size="small"
                      type="outlined"
                      menuPosition="fixed"
                      menuShouldBlockScroll
                    />
                  );
                }

                return (
                  <TableRowCell
                    className={cn({
                      [classes.cell]: !isAssignmentFlow,
                      [classes.number]: index === 0,
                      [classes.naStyle]: cell.text === 'n/a' && row.isInstantQuote,
                      [classes.wide]: cell.id === LeadFields.interestedIn,
                    })}
                    dataLabel={`${cell.id + '-' + cell.rowId}`}
                    key={`leads-${position}-${cell.id}-${index}`}
                    textColor={cell.textColor}
                    textWeight={cell.textWeight}
                    size={cell.size}
                    skipTooltip={cell.id === LeadFields.interestedIn}
                    id={cell.id}
                    rowId={cell.rowId}
                    actionable={cell.actionable}
                    onClick={handleCellClick}
                  >
                    {getCellContent(cell, row)}
                  </TableRowCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </WhiteBox>
  );
}
