import { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { store, useAppSelector } from 'redux/store';
import { customerCompanyApi } from 'redux/api/customer-company.api';
import {
  ICustomerCompanyUsersRequest,
  ICustomerCompanyUsersList,
} from 'interfaces/customer-company.interface';

import { getTimeMmmDdYyyy } from 'utils/date/date-formatter.util';
import { UsersFields, IUsersTransformRow } from 'interfaces/customer-company.interface';

import { USERS_TABLE_HEADER, USERS_TABLE_ROW } from 'constants/customer-company.constant';
import { SUBJECTS } from 'constants/ability.constant';
import { can } from 'boot/ability';
import { createUserName } from 'utils/customer/users-table.util';
import { customerCompanyUsersSelector } from 'redux/selectors/customer-company.selectors';
import { pluralize } from 'utils/string.util';
import { Price } from 'utils/price.util';

const fetchUsers = async (queryParams: ICustomerCompanyUsersRequest) => {
  try {
    const request = store.dispatch(
      customerCompanyApi.endpoints.getCustomerCompanyUsers.initiate(queryParams, {
        forceRefetch: true,
      }),
    );
    await request.unwrap();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn(e);
  }
};

const getRows = (users: ICustomerCompanyUsersList['list']) => {
  const viewOrdersAccess = can(
    SUBJECTS.VIEW_ORDERS.actions.VIEW_ALL_ORDERS,
    SUBJECTS.VIEW_ORDERS.value,
  );

  const rows: IUsersTransformRow[] = [];

  users.forEach((user, position) => {
    const cells = USERS_TABLE_HEADER.map((cell) => {
      const common = USERS_TABLE_ROW[cell.id] || {};
      const cellParams = { ...common, rowId: user.id, id: cell.id };

      if (cell.id === UsersFields.userName) {
        return {
          ...cellParams,
          text: createUserName(user),
        };
      }
      if (cell.id === UsersFields.lastOrderAt) {
        const value = user[cell.id];
        return {
          ...cellParams,
          text:
            viewOrdersAccess && value
              ? getTimeMmmDdYyyy(new Date(value), {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric',
                })
              : 'n/a',
        };
      }
      if (cell.id === UsersFields.orders) {
        return {
          ...cellParams,
          text: viewOrdersAccess ? `${pluralize(user.orders, 'Order')}` : 'n/a',
        };
      }

      if (cell.id === UsersFields.totalSpent) {
        if (!viewOrdersAccess) {
          return {
            ...cellParams,
            text: 'n/a',
          };
        }

        return {
          ...cellParams,
          text: Price.formatPrice(user[cell.id]),
        };
      }

      return {
        ...cellParams,
        text: user[cell.id] ? String(user[cell.id]) : 'n/a',
      };
    });

    rows.push({
      cells,
      index: position,
      key: user.id,
    });
  });

  return rows;
};

export const useCustomerCompanyUsers = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState<IUsersTransformRow[]>([]);
  const { id } = useParams();
  const customerCompanyUsers = useAppSelector(customerCompanyUsersSelector);
  const usersList = customerCompanyUsers.listInfinity;
  const total = customerCompanyUsers.total;
  const currentPage = customerCompanyUsers.page;
  const hasNextPage = usersList.length < total;

  const getUsers = useCallback(
    async (queryParams: ICustomerCompanyUsersRequest) => {
      setIsLoading(true);
      await fetchUsers(queryParams);
      setIsLoading(false);
    },
    [setIsLoading, fetchUsers, id],
  );

  useEffect(() => {
    const result = getRows(usersList);
    setRows(result);
  }, [usersList.length]);

  return {
    total,
    getUsers,
    usersList,
    isLoading,
    hasNextPage,
    currentPage,
    rows,
  };
};
