import FONTS from 'constants/styles/fonts-js.constant.module.scss';
import COLORS from 'constants/styles/colors-js.constant.module.scss';

const option = {
  fontFamily: FONTS.fontFamilyRegular,
  color: COLORS.colorMain,
  padding: '10px 8px',
  borderRadius: '8px',
  margin: '2px 0',
  minWidth: '150px',
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: COLORS.colorSelectHover,
  },
  '&:active': {
    backgroundColor: COLORS.colorSelectHover,
  },
};
const menuList = {
  zIndex: 901,
};
const menuPortal = {
  pointerEvents: 'auto' as const,
  zIndex: 9999,
};
const menu = {
  backgroundColor: COLORS.colorWhite,
  padding: '8px',
  zIndex: 9999,
};

const getMenuStylesBasedOnScreen = () => {
  const isMobile = window.innerWidth < 768;

  return {
    ...(!isMobile && { right: '-20px' }),
    width: isMobile ? '100%' : 'max-content',
  };
};

export const ROUNDED_SELECT = {
  menu: (styles: Record<string, unknown>) => ({
    ...styles,
    ...menu,
    ...getMenuStylesBasedOnScreen(),
  }),
  menuPortal: (styles: Record<string, unknown>) => ({
    ...styles,
    ...menuPortal,
  }),
  menuList: (styles: Record<string, unknown>) => ({
    ...styles,
    ...menuList,
  }),
  option: (styles: Record<string, unknown>, { isSelected }: { isSelected: boolean }) => ({
    ...styles,
    ...option,
    backgroundColor: isSelected ? COLORS.colorSelectHover : COLORS.colorWhite,
  }),
};

export const getCustomRoundedSelectStyles = ({
  menuStyles = {},
}: {
  menuStyles?: Record<string, unknown>;
}) => {
  return {
    ...ROUNDED_SELECT,
    menu: (styles: Record<string, unknown>) => ({
      ...styles,
      ...menu,
      ...getMenuStylesBasedOnScreen(),
      ...menuStyles,
    }),
  };
};
