import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';

import { SwagButton } from '@ezetech/swag-space-x';
import { yupResolver } from '@hookform/resolvers/yup';
import { InputForm } from 'components/input-form';
import { UserResetPasswordType } from 'interfaces/auth-api.interface';
import { resetPasswordSchema } from '../reset-password.schema';
import { FIELDS } from './reset-password.constants';

import css from './reset-password-form.module.scss';

export type ResetPasswordFormProps<dataType> = {
  onSubmit: (data: dataType) => void;
  loading?: boolean;
  error?: string;
  success?: boolean;
  setStep: (value: string) => void;
};

export const ResetPasswordForm = (
  props: ResetPasswordFormProps<UserResetPasswordType>,
) => {
  const { onSubmit: onFormSubmit, loading, setStep } = props;

  const { handleSubmit, control, formState } = useForm<FieldValues>({
    mode: 'onTouched',
    resolver: yupResolver(resetPasswordSchema),
  });
  const { isValid } = formState;

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    onFormSubmit(data as UserResetPasswordType);
    setStep('submitted');
  };
  return (
    <form className={css.form} onSubmit={handleSubmit(onSubmit)}>
      <div className={css.formFields}>
        <InputForm
          type="email"
          label={FIELDS.email.label}
          name={FIELDS.email.name}
          placeholder={FIELDS.email.placeholder}
          rules={FIELDS.email.rules}
          control={control}
        />
      </div>
      <SwagButton
        type="primary"
        size="xl"
        htmlType="submit"
        fullWidth
        disabled={!isValid}
        loading={loading}
      >
        Request reset link
      </SwagButton>
    </form>
  );
};
