export const MAX_FILE_SIZE_10MB_RESTRICTION = 1024 * 1024 * 10;

export const JPEG_ACCEPT_RESTRICTION = {
  'image/jpeg': ['.jpg', '.jpeg'],
};

export const PNG_ACCEPT_RESTRICTION = {
  'image/png': ['.png'],
};

export const PDF_ACCEPT_RESTRICTION = {
  'application/pdf': ['.pdf'],
};

export const JPEG_PNG_ACCEPT_RESTRICTION = {
  ...JPEG_ACCEPT_RESTRICTION,
  ...PNG_ACCEPT_RESTRICTION,
};

export const WHAT_WE_OFFER_IMAGE_MB_RESTRICTION = MAX_FILE_SIZE_10MB_RESTRICTION;

export const WHAT_WE_OFFER_IMAGE_ACCEPT_RESTRICTION = JPEG_PNG_ACCEPT_RESTRICTION;

export const RESELLER_CERTIFICATE_DOCUMENT_MB_RESTRICTION =
  MAX_FILE_SIZE_10MB_RESTRICTION;

export const RESELLER_CERTIFICATE_DOCUMENT_ACCEPT_RESTRICTION = {
  ...JPEG_PNG_ACCEPT_RESTRICTION,
  ...PDF_ACCEPT_RESTRICTION,
};

export const RESELLER_TAX_EXEMPT_DOCUMENT_RESTRICTION = {
  ...JPEG_PNG_ACCEPT_RESTRICTION,
  ...PDF_ACCEPT_RESTRICTION,
};
