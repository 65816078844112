import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { MultiValue } from 'react-select';
import { OptionType } from '@ezetech/swag-space-x';
import {
  IFilterRequest,
  IErrorResponse,
  IUser,
  IUsersList,
  ReducerPath,
  IUserAndNotesDetails,
  IUpdateUser,
  IValidatePaymentMethodResponse,
  PaymentMethodValidationErrors,
  IUpdateUserPayment,
  IUpdateLeadStatus,
  ITeamMembersList,
  IPaginationRequest,
  TAX_METHODS,
  CLEntity,
} from 'interfaces';
import { mapUserDetails } from 'utils/customer/mappers';
import { CreditCardCreationFlow } from 'interfaces/flow.inteface';
import { IInvoice } from 'interfaces/invoice.interface';
import { setClipboardLink } from 'redux/slices/invoices.slice';
import { makeSuccessToastNotification, makeToastNotification } from 'utils/query.util';
import { ITeamMember } from 'interfaces/invitation.interface';
import { IS3Document } from 'interfaces/reseller-certificate.interfaces';
import { ASSIGNED_TO_ME_ENTITY } from 'constants/common';
import { setUser } from '../slices/user.slice';
import { setFilter } from '../slices/filter.slice';
import {
  setCLState,
  setCustomersAndLeadsCounts,
  setStatusToUser,
} from '../slices/customers-leads.slice';
import {
  setDetails,
  setPaymentMethod,
  setTaxExemptData,
} from '../slices/customer-details.slice';
import { pushNotification } from '../slices/notifications.slice';
import { closePopup, openPopup } from '../slices/modals.slice';
import {
  BILLING_ADDRESS_POPUP,
  CREATE_CREDIT_CARD_POPUP,
  INVOICE_BANK_DETAILS_POPUP,
} from '../../components/popups/_logic/popups-list';
import {
  setInvitations,
  setLoading,
  setTeamMemberDetails,
} from '../slices/invitations.slice';
import { baseQueryParams } from './helpers';

export const userApi = createApi({
  reducerPath: ReducerPath.user,
  baseQuery: fetchBaseQuery({
    ...baseQueryParams,
  }),
  endpoints: (builder) => ({
    ping: builder.mutation<{ userId: string }, null>({
      query: () => ({
        url: '/user/me',
        method: 'GET',
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
          if ((e as { error: IErrorResponse })?.error?.data?.error === 'Unauthorized') {
            dispatch(setUser(null));
          }
        }
      },
    }),

    getUser: builder.query<IUser | null, null>({
      query: () => ({
        url: '/user/',
        method: 'GET',
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setUser(data));
        } catch (e) {
          dispatch(setUser(null));
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),

    getUserNoCache: builder.mutation<IUser | null, null>({
      query: () => ({
        url: '/user/',
        method: 'GET',
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setUser(data));
        } catch (e) {
          dispatch(setUser(null));
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),

    getUsers: builder.mutation<IUsersList | null, IFilterRequest>({
      query: ({ dropdown, customersOrLeads, ...rest }) => {
        const filter: Record<string, string[]> = {};

        (dropdown as MultiValue<OptionType | null>).forEach((option) => {
          if (option?.value === ASSIGNED_TO_ME_ENTITY) {
            filter.assignedToMeEntity = [ASSIGNED_TO_ME_ENTITY];
            return;
          }

          if (option) {
            const [key, value] = option.value.split(':');
            const selectedOptions = value
              .split('_')
              .filter(Boolean)
              .map((item) => item.replaceAll('-', '_'));

            if (value) {
              filter[key] = [...(filter[key] || []), ...selectedOptions];
            }
          }
        });

        return {
          url: `/user/${customersOrLeads}`,
          method: 'GET',
          params: {
            ...rest,
            filter: JSON.stringify(filter),
          },
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          const isCustomer = args.customersOrLeads === CLEntity.customers;

          if (data) {
            const { list, page, total, selectionTotal, perPage } = data;

            dispatch(
              setCLState({
                list,
                ...(isCustomer ? { customersTotal: total } : { leadsTotal: total }),
              }),
            );
            dispatch(setFilter({ page, perPage, totalCount: selectionTotal }));
          }
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),
    getCustomersAndLeadsCounts: builder.query<
      {
        leadsTotal: number;
        customersTotal: number;
      },
      void
    >({
      query: () => ({
        url: '/user/customers-leads-counts',
        method: 'GET',
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setCustomersAndLeadsCounts(data));
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),
    getUserDetails: builder.mutation<IUserAndNotesDetails, { id: string }>({
      query: ({ id }) => ({
        url: `/user/${id}/details`,
        method: 'GET',
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setDetails(mapUserDetails(data)));
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),
    updateUser: builder.mutation<
      IUserAndNotesDetails | IValidatePaymentMethodResponse,
      IUpdateUser | IUpdateUserPayment
    >({
      query: ({ id, ...rest }) => {
        if ('setPaymentMethod' in rest) {
          delete rest.setPaymentMethod;
        }

        return {
          url: `/user/${id}`,
          method: 'PATCH',
          body: { ...rest },
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          if ('error' in data) {
            dispatch(closePopup());

            if ('paymentMethod' in args) {
              switch (data.error) {
                case PaymentMethodValidationErrors.CreditCardIsExpired:
                case PaymentMethodValidationErrors.CreditCardIsNotSet: {
                  dispatch(
                    openPopup({
                      popupName: CREATE_CREDIT_CARD_POPUP,
                      popupProps: {
                        flow: CreditCardCreationFlow.PaymentMethod,
                        paymentMethod: args.paymentMethod,
                        setPaymentMethod: args.setPaymentMethod,
                      },
                    }),
                  );
                  return;
                }

                case PaymentMethodValidationErrors.InvoiceBankDetailsIsNotSet: {
                  dispatch(
                    openPopup({
                      popupName: INVOICE_BANK_DETAILS_POPUP,
                      popupProps: {
                        flow: CreditCardCreationFlow.PaymentMethod,
                        paymentMethod: args.paymentMethod,
                        setPaymentMethod: args.setPaymentMethod,
                      },
                    }),
                  );

                  return;
                }
                case PaymentMethodValidationErrors.BillingAddressIsNotSet: {
                  dispatch(
                    openPopup({
                      popupName: BILLING_ADDRESS_POPUP,
                      popupProps: {
                        flow: CreditCardCreationFlow.PaymentMethod,
                        paymentMethod: args.paymentMethod,
                        setPaymentMethod: args.setPaymentMethod,
                      },
                    }),
                  );

                  return;
                }

                default: {
                  if (args.paymentMethod) {
                    dispatch(setPaymentMethod({ paymentMethod: args.paymentMethod }));

                    if (args.setPaymentMethod) {
                      args.setPaymentMethod(args.paymentMethod);
                    }
                  }

                  return;
                }
              }
            }
          }

          if ('user' in data) {
            dispatch(setDetails(mapUserDetails(data)));

            if ('paymentMethod' in args && args.setPaymentMethod) {
              data.user?.paymentMethod && args.setPaymentMethod(data.user.paymentMethod);
            }
            dispatch(closePopup());
          }
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),
    updateLeadStatus: builder.mutation<void, IUpdateLeadStatus>({
      query: ({ id, ...rest }) => ({
        url: `/user/${id}/lead/status`,
        method: 'PATCH',
        body: { ...rest },
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(setStatusToUser(args));
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),

    generateInventorySignContractLink: builder.mutation<
      { link: string },
      { invoiceId: IInvoice['id'] }
    >({
      query: ({ invoiceId }) => ({
        url: `/user/inventory-contract/${invoiceId}`,
        method: 'GET',
      }),
      onQueryStarted: async ({ invoiceId }, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;

          dispatch(setClipboardLink({ invoiceId, link: data.link || null }));
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),

    getTeamMembers: builder.mutation<ITeamMembersList | null, IPaginationRequest>({
      query: ({ page, perPage }) => ({
        url: '/user/team-members',
        method: 'GET',
        params: { page, perPage },
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          dispatch(setLoading(true));
          const { data } = await queryFulfilled;

          if (data) {
            const { teamMembers, total, page, perPage } = data;
            dispatch(
              setInvitations({
                team: teamMembers,
                page,
                perPage,
                total,
                isLoading: false,
              }),
            );
          }
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
          dispatch(setLoading(false));
        }
      },
    }),
    getTeamMemberById: builder.mutation<ITeamMember, { id: string }>({
      query: ({ id }) => ({
        url: `/user/team-members/${id}`,
        method: 'GET',
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data: teamMember } = await queryFulfilled;
          if (teamMember) {
            dispatch(setLoading(false));
            dispatch(setTeamMemberDetails(teamMember));
          }
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
          dispatch(setLoading(false));
        }
      },
    }),
    uploadUserTaxExemptDocument: builder.mutation<
      Omit<IS3Document, 'size'> | null,
      FormData
    >({
      query: (data) => ({
        url: '/user/tax-exempt-document',
        method: 'POST',
        body: data,
      }),

      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),
    applyUserTaxExemptRequest: builder.mutation<
      {
        isTaxExemptPending: boolean;
        tax: TAX_METHODS;
      },
      {
        userId: string;
        documentUrl: string;
        setTaxOption?: React.Dispatch<React.SetStateAction<string>>;
      }
    >({
      query: ({ userId, documentUrl }) => ({
        url: `/user/${userId}/tax-exempt`,
        method: 'POST',
        body: { documentUrl },
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(
            setTaxExemptData({
              isTaxExemptPending: data.isTaxExemptPending,
              tax: data.tax,
            }),
          );
          if (args?.setTaxOption) {
            args.setTaxOption(data.tax);
          }
          dispatch(closePopup());
          dispatch(
            pushNotification(
              makeSuccessToastNotification('Tax exempt request was sent successfully'),
            ),
          );
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),
    updateTeamMemberPermissions: builder.mutation<
      void,
      { userId?: string; invitationId?: string; permissions: unknown }
    >({
      query: ({ userId, invitationId, permissions }) => {
        return {
          url: '/user/team-members/permissions',
          method: 'PUT',
          body: { userId, invitationId, permissions },
        };
      },
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(
            pushNotification(makeSuccessToastNotification('Permissions updated.')),
          );
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),
  }),
});

export const {
  usePingMutation,
  useGetUserQuery,
  useGetUsersMutation,
  useGetUserDetailsMutation,
  useUpdateUserMutation,
  useUpdateLeadStatusMutation,
  useGenerateInventorySignContractLinkMutation,
  useGetTeamMembersMutation,
  useGetTeamMemberByIdMutation,
  useUploadUserTaxExemptDocumentMutation,
  useApplyUserTaxExemptRequestMutation,
  useUpdateTeamMemberPermissionsMutation,
} = userApi;
