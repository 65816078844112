import {
  Dialog,
  DialogContentWrapper,
  DialogTitle,
  DialogDescription,
  DialogFooter,
  SwagButton,
  Typography,
} from '@ezetech/swag-space-x';

import { useAppDispatch } from 'redux/store';
import { closePopup, openPopup } from 'redux/slices/modals.slice';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import {
  ResellerMockupApprovalPopupProps,
  ResellerMockupPreApprovalPopupProps,
} from 'interfaces/popup.interface';

import { RESELLER_MOCKUP_APPROVAL } from '../_logic/popups-list';
import css from './reseller-mockup-pre-approval-popup.module.scss';

export const ResellerMockupPreApprovalPopup = ({
  orderId,
  itemId,
  orderNum,
  productName,
  productionMockup,
  customerMockups,
  isReviewMockupsByCustomerAllowed,
  isOpen,
}: ResellerMockupPreApprovalPopupProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const onBtnClick = () => {
    dispatch(closePopup());
  };
  const openNextPopup = async () => {
    onBtnClick();
    setTimeout(() => {
      dispatch(
        openPopup({
          popupName: RESELLER_MOCKUP_APPROVAL,
          popupProps: {
            orderId,
            itemId,
            orderNum,
            productName,
            productionMockup,
            customerMockups,
            isReviewMockupsByCustomerAllowed,
          } as ResellerMockupApprovalPopupProps,
        }),
      );
    }, 200);
  };

  return (
    <Dialog open={isOpen} onOpenChange={onBtnClick}>
      <DialogContentWrapper
        className={css.dialogContentWrapperClassName}
        overlayClassName={css.dialogOverlayClassName}
      >
        <DialogTitle closeIconClassName={css.closeIconClassName}>
          Before Approving, Pay attention
        </DialogTitle>
        <DialogDescription className={css.contentWrapper}>
          <Typography
            className={css.text}
            color={COLORS.colorTextSubdued}
            fontType="bodyMd"
            lineHeight="162.5%"
          >
            Before approving your mockup, please pay close attention and confirm the
            following:
          </Typography>

          <br />
          <br />

          <Typography
            className={css.text}
            color={COLORS.colorPrimaryText}
            fontType="bodyMd"
            lineHeight="162.5%"
          >
            1. The Pantone colors are correct.
          </Typography>
          <br />

          <Typography
            className={css.text}
            color={COLORS.colorPrimaryText}
            fontType="bodyMd"
            lineHeight="162.5%"
          >
            2. The color of the item(s) you ordered is correct.
          </Typography>
          <br />

          <Typography
            className={css.text}
            color={COLORS.colorPrimaryText}
            fontType="bodyMd"
            lineHeight="162.5%"
          >
            3. The artwork is the right size and in the right place.
          </Typography>
          <br />

          <Typography
            className={css.text}
            color={COLORS.colorPrimaryText}
            fontType="bodyMd"
            lineHeight="162.5%"
          >
            4. The decoration method is correct.
          </Typography>

          <br />
          <br />

          <Typography
            className={css.text}
            color={COLORS.colorTextSubdued}
            fontType="bodyMd"
            lineHeight="162.5%"
          >
            As soon as you approve your mockup, we will be moving your items(s) to
            production. That means we will no longer be able to make any changes to your
            order. Please take this opportunity to confirm the quantity of item(s) and the
            sizes (where applicable) are also correct!
          </Typography>
        </DialogDescription>

        <DialogFooter className={css.footerClassName}>
          <SwagButton
            type="primary"
            size="large"
            onClick={openNextPopup}
            className={css.btn}
          >
            Continue to Approval
          </SwagButton>
        </DialogFooter>
      </DialogContentWrapper>
    </Dialog>
  );
};
